import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { FeaturesBaseServices } from './../../features/services/features-base.services';

import { ConfirmationService, SelectItem } from 'primeng/api';

import { ToasterComponent } from './../../../_directives/toaster.component';
import { FeatureComponent } from 'src/app/kuba/application/components/feature.component';
import { BaseServices } from './../../kuba.services';
import { CacheHandlerService } from './../../cached.services';
import { TranslateService } from '@ngx-translate/core';
import { BusinessList } from 'src/app/kuba/businesses/models';
import { BusinessServices } from 'src/app/kuba/businesses/services/business.services';
import { HelperService } from 'src/app/_services/helper.service';
import { LicenseAgreementServices } from './../services/license-agreement.service';
import {
  RowStatusPipe,
  MonthPipe,
  LicenseCategoryPipe
} from 'src/app/_helpers/pipes';
import { FeatureBase } from 'src/app/kuba/features/models/features-base.model';
import { Feature, FeatureKey } from 'src/app/_models';
import { Subscription } from 'rxjs';
import { Table } from 'primeng/table';

@Component({
  selector: 'business-license-agreement',
  templateUrl: 'business-license-agreement-list.html'
})
export class BuinessLicenseAgreementListComponent implements OnInit, OnDestroy {
  //#region variables
  @ViewChild(FeatureComponent, { static: false })
  featuresComponent: FeatureComponent;
  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  @ViewChild('dt') dt: Table;
  IsAdmin: boolean;
  businesses: any[];
  statuses: SelectItem[];
  months: SelectItem[];
  licenseCategory: SelectItem[];
  remoteWindow: Window;
  isChecked = false;
  slider: FormGroup;
  isPortal: boolean;
  additionalData: any;
  rowsPerPageOptions = [];
  filteredValue: any;
  totalPrice = 0;
  show = false;
  featureBase: FeatureBase;
  Showloader = false;
  exportFileName = '';
  private subscriptions: Subscription[] = [];
  //#endregion

  //#region constructors
  /**
   * constructor
   * @param router {Router}
   * @param route {ActivatedRoute}
   * @param businessServices {BusinessServices}
   * @param confirmationService {ConfirmationService}
   * @param featuresBaseServices {FeaturesBaseServices}
   */
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private businessServices: BusinessServices,
    private confirmationService: ConfirmationService,
    public featuresBaseServices: FeaturesBaseServices,
    private _fb: FormBuilder,
    private cacheHandlerService: CacheHandlerService,
    private translate: TranslateService,
    private LicenseAgreementServices: LicenseAgreementServices,
    private rowStatusPipe: RowStatusPipe,
    private monthPipe: MonthPipe,
    private licenseCategoryPipe: LicenseCategoryPipe
  ) {
    this.subscriptions.push(
      this.translate.stream('SELECT_STATUS_DROP').subscribe(val => {
        this.statuses = [];
        this.statuses.push(
          { label: val.ACTIVE, value: 1 },
          { label: val.INACTIVE, value: 2 }
        );
      })
    );
    this.subscriptions.push(
      this.translate.stream('SELECT_MONTH').subscribe(val => {
        this.months = [];
        this.months.push(
          { label: val.SELECT, value: null },
          { label: val.JANUARY, value: '01' },
          { label: val.FEBRUARY, value: '02' },
          { label: val.MARCH, value: '03' },
          { label: val.APRIL, value: '04' },
          { label: val.MAY, value: '05' },
          { label: val.JUNE, value: '06' },
          { label: val.JULY, value: '07' },
          { label: val.AUGUST, value: '08' },
          { label: val.SEPTEMBER, value: '09' },
          { label: val.OCTOBER, value: '10' },
          { label: val.NOVEMBER, value: '11' },
          { label: val.DECEMBER, value: '12' }
        );
      })
    );
    this.subscriptions.push(
      this.translate.stream('SELECT_LICENSE_CATEGORY').subscribe(val => {
        this.licenseCategory = [];
        this.licenseCategory.push(
          { label: val.SELECT, value: null },
          { label: val.DEMO_INTERNAL_USE, value: 1 },
          { label: val.MANUAL_WITHOUT_FOLLOWUP, value: 2 },
          { label: val.KUBA_BASIC, value: 3 },
          { label: val.KUBA_STANDARD, value: 4 },
          { label: val.FOOD_SAFETY_LICENSE, value: 5 },
          { label: val.KUBA_TOTAL, value: 6 },
          { label: val.ENTERPRISE, value: 7 },
          { label: val.OCCUPATIONAL_HEALTH_SERVICE, value: 8 }
        );
      })
    );
    this.slider = this._fb.group({
      isChecked: ['']
    });

    this.additionalData = {
      fileName: this.exportFileName,
      header: 'License agreement list',
      businessId: BaseServices.BusinessId,
      cultureInfo: BaseServices.userCultureInfo(),
      showFormat: false,
      columnNames: [
        { title: 'Title', dataKey: 'CompanyName' },
        { title: 'Connected to', dataKey: 'Connectedto' },
        { title: 'Telephone', dataKey: 'Telephone' },
        { title: 'Email', dataKey: 'Email' },
        { title: 'PRICE_PER_MONTH', dataKey: 'PricePerMonth' },
        { title: 'Category', dataKey: 'LicenseCategoryName' },
        { title: 'Month', dataKey: 'MonthName' },
        { title: 'USERS', dataKey: 'UsersCount' },
        { title: 'ACTIVITIES', dataKey: 'IsActivity' },
        { title: 'DEVIATION', dataKey: 'IsDeviation' },
        { title: 'RISK_ANALYSIS', dataKey: 'IsRiskAnalysis' },
        { title: 'PROJECTS', dataKey: 'IsProject' },
        { title: 'FDV', dataKey: 'IsFdv' },
        { title: 'INTERNAL_CONTROL', dataKey: 'IsInternalControl' },
        { title: 'FOOD_SAFETY', dataKey: 'IsFoodSafety' },
        { title: 'VEHICLES', dataKey: 'IsVehicle' },
        { title: 'Created On', dataKey: 'CreatedOn' },
        { title: 'Modified On', dataKey: 'ModifiedOn' },
        { title: 'Login Date', dataKey: 'LoginDate1' },
        { title: 'Login Count', dataKey: 'LoginCount' },
        { title: 'Status', dataKey: 'StatusName' },
        { title: 'Expiry Date', dataKey: 'ExpiryDate' }
      ]
    };
  }
  //#endregion

  //#region page-events
  /**
   * page load event
   */
  ngOnInit(): void {
    document.querySelector('body').classList.remove('opened');
    this.businesses = this.route.snapshot.data['list'];
    this.subscriptions.push(
      this.translate.stream('REPORT').subscribe(val => {
        this.exportFileName = val.BUSINESS_LIST;
        this.additionalData.fileName = val.LICENSE_AGREEMENTS;
      })
    );
    this.subscriptions.push(
      this.translate.stream('SELECT_LICENSE_CATEGORY').subscribe(val => {
        if (this.businesses) {
          this.businesses.forEach(element => {
            if (element.Status) {
              let datas = this.rowStatusPipe.transform(+element.Status);
              if (datas != null && datas != undefined && datas != '') {
                element.StatusName = this.translate.instant(datas);
              }
            }
            if (element.LicenseMonth) {
              let datas = this.monthPipe.transform(element.LicenseMonth);
              if (datas != null && datas != undefined && datas != '') {
                element.MonthName = this.translate.instant(datas);
              }
            }
            if (element.LicenseCategoryId) {
              let datas = this.licenseCategoryPipe.transform(
                element.LicenseCategoryId
              );
              if (datas != null && datas != undefined && datas != '') {
                element.LicenseCategoryName = this.translate.instant(datas);
              }
            }
          });
        }
      })
    );
    this.bindTotalPriceAmountWithPagenagion(this.businesses);
    this.isPortal = BaseServices.roleId === '2' ? true : false;
    // To hide side menu bar
    document.querySelector('body').classList.remove('opened');
    let businessFeature = this.route.snapshot.data['featureList'];
    this.businesses.forEach(element => {
      let data = businessFeature.find(x => x.Id == element.Id);
      if (this.businesses && data != null) {
        if (data.Feature != null && data.Feature != undefined) {
          let features = <any>JSON.parse(data.Feature);
          let Project = features.filter(x => x.id === FeatureKey.PROJECT);
          element.IsProject =
            Project.length > 0 && Project[0].checked
              ? this.translate.instant('YES')
              : this.translate.instant('NO');
          let FDV = features.filter(x => x.id === FeatureKey.FDV);
          element.IsFdv =
            FDV.length > 0 && FDV[0].checked
              ? this.translate.instant('YES')
              : this.translate.instant('NO');
          let InternalControl = features.filter(
            x => x.id === FeatureKey.INTERNAL_CONTROL
          );
          element.IsInternalControl =
            InternalControl.length > 0 && InternalControl[0].checked
              ? this.translate.instant('YES')
              : this.translate.instant('NO');
          let FoodSafety = features.filter(
            x => x.id === FeatureKey.FOOD_SAFETY
          );
          element.IsFoodSafety =
            FoodSafety.length > 0 && FoodSafety[0].checked
              ? this.translate.instant('YES')
              : this.translate.instant('NO');
          let Vehicle = features.filter(x => x.id === FeatureKey.VEHICLES);
          element.IsVehicle =
            Vehicle.length > 0 && Vehicle[0].checked
              ? this.translate.instant('YES')
              : this.translate.instant('NO');
          let OtherSystems = features.filter(
            x => x.id === FeatureKey.OTHER_SYSTEMS
          );
          element.OtherSystems =
            OtherSystems.length > 0 && OtherSystems[0].checked
              ? this.translate.instant('YES')
              : this.translate.instant('NO');
          let QualitySystem = features.filter(
            x => x.id === FeatureKey.QUALITY_SYSTEMS
          );
          element.QualitySystem =
            QualitySystem.length > 0 && QualitySystem[0].checked
              ? this.translate.instant('YES')
              : this.translate.instant('NO');
          let followup = features.filter(x => x.id === FeatureKey.FOLLOW_UP);
          element.IsActivity = this.translate.instant('NO');
          element.IsDeviation = this.translate.instant('NO');
          element.IsRiskAnalysis = this.translate.instant('NO');
          let checkFollowup =
            followup.length != 0 && followup.length > 0
              ? followup[0].children != null
              : false;
          if (checkFollowup) {
            let activity = followup[0].children.filter(
              x => x.id == FeatureKey.ACTIVITIES
            );
            if (
              followup[0].children != null &&
              activity != null &&
              activity.length > 0
            ) {
              element.IsActivity = activity[0].checked
                ? this.translate.instant('YES')
                : this.translate.instant('NO');
            }
            let deviation = followup[0].children.filter(
              x => x.id == FeatureKey.DEVIATIONS
            );
            if (
              followup[0].children != null &&
              deviation != null &&
              deviation.length > 0
            ) {
              element.IsDeviation = deviation[0].checked
                ? this.translate.instant('YES')
                : this.translate.instant('NO');
            }
            let riskAnalysis = followup[0].children.filter(
              x => x.id == FeatureKey.RISK_ANALYSIS
            );
            if (
              followup[0].children != null &&
              riskAnalysis != null &&
              riskAnalysis.length > 0
            ) {
              element.IsRiskAnalysis = riskAnalysis[0].checked
                ? this.translate.instant('YES')
                : this.translate.instant('NO');
            }
          }
        }
      }
    });
  }

  /**
   * page destroy event
   */
  ngOnDestroy() {
    document.querySelector('body').classList.add('opened');
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
  //#endregion

  //#region methods
  /**
   * create new business
   */
  createNewBusiness() {
    this.router.navigate(['./wizard']);
  }

  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  /**
   * delete event
   * @param id {number}
   * @param data {any}
   */
  confirm(id: number, data: any) {
    this.confirmationService.confirm({
      message: this.translate.instant('DELETE_THIS_RECORD'),
      accept: () => {
        this.subscriptions.push(
          this.businessServices.deleteById(id, data).subscribe(() => {
            this.toasterComponent.callToastDlt();
            this.businessServices.clearCachedBusinessService();
            this.fetchData();
          })
        );
      }
    });
  }

  /**
   * status event
   * @param id {number}
   * @param status {any}
   */
  editStatus(id: number, status: string) {
    this.confirmationService.confirm({
      message: this.translate.instant('DELETE_THIS_RECORD'),
      accept: () => {
        this.subscriptions.push(
          this.businessServices.statusUpdate(id, status).subscribe(() => {
            this.businessServices.clearCachedBusinessService();
            this.fetchData();
          })
        );
      }
    });
  }
  /**
   * R mode chage
   * @param event {any}
   * @param business {any}
   */
  rModeCheckFeature(business: any) {
    if (this.remoteWindow) {
      this.remoteWindow.close();
    }
    this.cacheHandlerService.clearCachedData();
    let businessSetting = {
      BusinessName: business.CompanyName,
      BusinessLogo: business.BusinessLogo
    };
    sessionStorage.setItem('RModeBusiness', JSON.stringify(businessSetting));
    sessionStorage.setItem('RModeBusinessId', business.Id);
    let windowOrigin = window.location.origin;
    let windowControl =
      'location=yes,width=' +
      screen.availWidth +
      ', height = ' +
      screen.availHeight +
      ', scrollbars = yes, status = yes';
    let baseURL = BaseServices.userBase.BaseUrl;
    this.remoteWindow = window.open(
      windowOrigin +
        '/' +
        baseURL +
        '/app/business/' +
        business.Id +
        '/dashboard',
      'Remote',
      windowControl
    );
  }

  /**
   * refresh list
   */
  fetchData() {
    this.subscriptions.push(
      this.LicenseAgreementServices.getBusinessLicenseAgreementList().subscribe(
        result => {
          if (result) {
            this.businesses = result;
            this.bindTotalPriceAmountWithPagenagion(this.businesses);
          }
        }
      )
    );
    this.subscriptions.push(
      this.businessServices.list().subscribe(businessList => {
        if (businessList) {
          this.businesses = businessList;
        }
      })
    );
  }

  bindTotalPriceAmountWithPagenagion(businessList: any[]) {
    if (businessList) {
      this.totalPrice = HelperService.addTotalPrice(businessList, '');
      this.rowsPerPageOptions = [10, 20, 50, 100];
      if (businessList.length > 100) {
        this.rowsPerPageOptions.push(businessList.length);
      }
    }
  }

  onBusinessFilterChange(event: any) {
    this.filteredValue = event.filteredValue;
    if (this.filteredValue.length > 0) {
      this.totalPrice = HelperService.addTotalPrice(this.filteredValue, '');
    } else {
      this.totalPrice = 0;
    }
  }
  /**
   * pagination change
   * @param first {number}
   * @param end {number}
   */
  onBusinessPageChange(event) {
    let list = this.businesses.slice(event.first, event.first + event.rows);
    if (list.length > 0) {
      this.totalPrice = HelperService.addTotalPrice(list, '');
    } else {
      this.totalPrice = 0;
    }
  }
  //#endregion

  loader(value: any) {
    this.Showloader = value;
  }

  FilterNameAsc() {
    this.businesses.sort((a, b) => (a.CompanyName > b.CompanyName ? 1 : -1));
  }
  FilterConnectedtoAsc() {
    this.businesses.sort((a, b) => (a.Connectedto > b.Connectedto ? 1 : -1));
  }
}
