import { BusinessServices } from './../../businesses/services/business.services';
import { PropertyObject } from './../../projects/models/projectEdit';
import { User } from './../../projects/models/user';
import { FdvEdit } from './../models/fdvedit';
import { BaseServices } from './../../kuba.services';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { FDVServices } from './fdv.service';
import { FDV } from '../models/fdvlist';
import { TypeOfObject, ChooseObject, Contact } from '../models/fdv';
@Injectable()
export class FdvListResolver implements Resolve<FDV[]> {
  constructor(private backend: FDVServices) {}
  resolve(route: ActivatedRouteSnapshot): Observable<FDV[]> {
    return this.backend.list(BaseServices.BusinessId);
  }
}
@Injectable()
export class FdvEditResolver implements Resolve<FdvEdit[]> {
  constructor(private backend: FDVServices) {}
  resolve(route: ActivatedRouteSnapshot): Observable<FdvEdit[]> {
    return this.backend.getByFDVId(+route.params['id']);
  }
}
@Injectable()
export class FdvMyListResolver implements Resolve<FDV[]> {
  constructor(private backend: FDVServices) {}
  resolve(route: ActivatedRouteSnapshot): Observable<FDV[]> {
    return this.backend.myList(BaseServices.BusinessId, BaseServices.UserId);
  }
}

@Injectable()
export class TypeOfObjectDropDownResolver implements Resolve<TypeOfObject[]> {
  constructor(private backend: FDVServices) {}
  resolve(route: ActivatedRouteSnapshot): Observable<FDV[]> {
    return this.backend.getTypeOfObject();
  }
}

@Injectable()
export class ChooseObjectDropDownResolver implements Resolve<ChooseObject[]> {
  constructor(private backend: FDVServices) {}
  resolve(route: ActivatedRouteSnapshot): Observable<FDV[]> {
    return this.backend.list(BaseServices.BusinessId);
  }
}
@Injectable()
export class ContactDropDownResolver implements Resolve<Contact[]> {
  constructor(private backend: FDVServices) {}
  resolve(route: ActivatedRouteSnapshot): Observable<FDV[]> {
    return this.backend.getUser(BaseServices.BusinessId);
  }
}
@Injectable()
export class UserListResolver implements Resolve<any[]> {
  constructor(private backend: BusinessServices) {}
  resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
    return this.backend.getUsersByBusiness(BaseServices.BusinessId);
  }
}

@Injectable()
export class FdvFeaturesResolver implements Resolve<any[]> {
  constructor(private backend: FDVServices) {}
  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this.backend.getFdvFeatures(BaseServices.BusinessId);
  }
}
