import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { ContactType } from 'src/app/kuba/contact/models/contactView';
import { Observable } from 'rxjs';

import { Client } from './../models/client';
import { ClientContact } from './../models/clientContact';
import { ModuleList } from './../models/module-list';
import { ClientServices } from './client.services';

@Injectable()
export class ClientListResolver implements Resolve<Client[]> {
    constructor(private backend: ClientServices) { }
    resolve(): Observable<Client[]> {
        return this.backend.getAllClients();
    }
}
@Injectable()
export class ClientListByBusinessResolver implements Resolve<Client[]> {
    constructor(private backend: ClientServices) { }
    resolve(): Observable<Client[]> {
        return this.backend.getAllClientsByBusiness();
    }
}

@Injectable()
export class ClientEditResolver implements Resolve<Client[]> {
    constructor(private backend: ClientServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<Client[]> {
        return this.backend.getClientById(+route.params['cid'])
    }
}
@Injectable()
export class ModuleListResolver implements Resolve<ModuleList[]> {
    constructor(private backend: ClientServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<ModuleList[]> {
        return this.backend.getModuleList(+route.params['cid'])
    }
}

@Injectable()
export class ClientContactListResolver implements Resolve<ClientContact[]> {
    constructor(private backend: ClientServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<ClientContact[]> {
        return this.backend.getClientContactByContactId(+route.params['cid']);
    }

}

@Injectable()
export class ContactTypeResolver implements Resolve<ContactType[]> {
    constructor(private backend: ClientServices) { }
    resolve(): Observable<ContactType[]> {
        return this.backend.getClientContact();
    }
}
@Injectable()
export class CountryCodeResolver implements Resolve<any[]> {
    constructor(private backend: ClientServices) { }
    resolve(): Observable<any[]> {
        return this.backend.getCountryCode();
    }
}

