import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Route, RouterModule } from '@angular/router';
import { NgForm } from '@angular/forms';
import { FormBuilder, FormGroup, ReactiveFormsModule, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router, Params } from '@angular/router';
import 'rxjs/Rx';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { BusinessDepartmentService } from './../../../business-department.services';
import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { ToasterComponent } from './../../../../_directives/toaster.component';
import { BaseServices } from './../../../kuba.services';
import { FoodSafety, FoodSafetyUser } from './../../models/foodsafety';
import { Zone } from './../../models/zone';
import { Department } from './../../../deviation/models/deviationDepartment';
import { FoodSafetyServices } from './../../services/foodsafety.services';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { foodsafetyRoutes } from '../../foodsafety.routing';
import { Subscription } from 'rxjs';

@Component({
    templateUrl: 'new-foodsafety.component.html',
})

export class CreateFoodSafetyComponent implements OnInit {
    @ViewChild(ToasterComponent, { static: false }) toasterComponent: ToasterComponent;
    foodSafetyId: number;
    foodsafety: any;
    foodSafetyForm: FormGroup;
    departmentPopup: FormGroup;
    zonePopup: FormGroup;
    Zone: SelectItem[];
    zoneList: Zone[];
    Department: SelectItem[];
    selectedDepartment = new Department();
    selectedZone = new Zone();
    departmentList: Department[];
    delete: any;
    deleteButton = false;
    zoneData: any;
    deptData: any;
    departmentId: number;
    zoneId: number;
    deleteZoneID: number;
    isDepartmentNameExists = false;
    isDepartmentNameAllocated = false;
    addZone = false;
    listZone = false;
    addDepartment = false;
    deleteDialog = false;
    listDepartment = false;
    isZoneNameAllocated = false;
    isZoneNameExists = false;
    createdBy: number;
    createdOn: any;
    userId: number;
    selectedUsers: any = [];
    userSubscribers: SelectItem[];
    selectedSubscribers: SelectItem[];
    selectedEmailSubscriber = [];
    selectAllEmailSubscriber = [];
    emailUsers: any[];
    bindAllUsers = false;
    bindSelectedUsers = true;
    foodUsers: any[];
    emailSubscriber = [];
    emailAllSubscriber = [];
    selectedEmailUsers: any = [];
    fsZoneNames: SelectItem[];
    filesTemplateId: number;
    hazardTemplateId: number;
    sJATemplateId: number;
    filesTemplateName: string;
    hazardTemplateName: string;
    sJATemplateName: string;
    checkedAll: boolean;
    private subscriptions: Subscription[] = [];
    copyDropDown:boolean=false;
    showCopyFiles: boolean;
    selectedZoneId: number;
    constructor(
        private foodSafetyService: FoodSafetyServices,
        private fb: FormBuilder,
        private location: Location,
        private route: ActivatedRoute,
        private confirmationService: ConfirmationService,
        private router: Router,
        private businessDepartmentService: BusinessDepartmentService,
        private translate: TranslateService
    ) {
        this.foodSafetyId = +this.route.snapshot.params['id'];
        this.foodSafetyForm = this.fb.group({
            ZoneName: ['', Validators.required],
            Description: [''],
            ChooseZone: [''],
            CopyFiles: [''],
            CopyHazard: [''],
            CopySJA: [''],
            IsShowDeviationChecked: [false],
            selectedEmailSubscriber: [''],
            selectAllEmailSubscriber: ['']
        });
        this.departmentPopup = this.fb.group({
            Name: ['', [Validators.required, ValidationService.noWhitespaceValidator]]
        })
        this.zonePopup = this.fb.group({
            Name: ['', [Validators.required, ValidationService.noWhitespaceValidator]]
        })
        this.userId = +BaseServices.UserId;
    }

    ngOnInit() {
        this.bindDropDowns();
        this.selectedSubscribers = [];
        this.userSubscribers = [];
        let userSubscription = this.route.snapshot.data[
            'users_subscription'
        ];
        this.emailUsers = userSubscription;
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.userSubscribers = [];
            if (userSubscription) {
                userSubscription.forEach((element: any) => {
                    this.selectedSubscribers.push({
                        label: element.Name,
                        value: { Id: element.Id }
                    });
                    if (element.Id == BaseServices.UserId) {
                        this.userSubscribers.push({
                            label: element.Name,
                            value: { Id: element.Id }
                        });
                    }
                });
            }
        }));
        /**
        * get user list by business id
        */
        let users = this.route.snapshot.data['contact'];
        if (users) {
            this.foodUsers = users;
        }
        if (this.foodSafetyId > 0) {
            let result = this.route.snapshot.data['edit'];
            this.createdBy = result.CreatedBy;
            this.createdOn = result.CreatedOn;
            this.filesTemplateId = result.FilesTemplateId;
            this.hazardTemplateId = result.HazardTemplateId;
            this.sJATemplateId = result.SJATemplateId;
            this.filesTemplateName = result.FilesTemplateName;
            this.hazardTemplateName = result.HazardTemplateName;
            this.sJATemplateName = result.SJATemplateName;
            if (result) {
                this.foodsafety = {
                    ZoneName: result.FoodSafetyZoneId,
                    Description: result.Description,
                    ChooseZone: result.FilesTemplateId ? result.FilesTemplateId : null,
                    CopyFiles: result.FilesTemplateId>0 ? true : false,
                    CopyHazard: result.HazardTemplateId>0 ? true: false,
                    CopySJA: result.sJATemplateId>0 ? true : false,
                    IsShowDeviationChecked: !result.ShowDeviation,
                    selectedEmailSubscriber: result.FoodSafetyUserDto,
                    selectAllEmailSubscriber: result.FoodSafetyUserDto
                };
                (<FormGroup>this.foodSafetyForm)
                    .setValue(this.foodsafety, { onlySelf: true });
                if (result.FoodSafetyUserDto) {
                    this.selectedEmailSubscriber = [];
                    result.FoodSafetyUserDto.forEach((element: any) => {
                        let selectedUsers = this.foodUsers.find(x => x.Id === element.UserId);
                        if (selectedUsers) {
                            selectedUsers.status = true;
                            selectedUsers.isEmail = false;
                            this.checked(selectedUsers);
                            if (element.IsEmail) {
                                this.selectedEmailSubscriber.push({ Id: element.UserId });
                            }
                        }
                    });
                }
                if (result.FoodSafetyUserDto) {
                    this.foodSafetyForm.patchValue({
                        selectedEmailSubscriber: this.selectedEmailSubscriber
                    });
                }
            }
        }
        this.disableCopyDropdown();  
    }

    disableCopyDropdown(){
    if(this.filesTemplateId != null || this.hazardTemplateId || this.sJATemplateId){
        this.copyDropDown=true;
    }
    }
    checked(value) {
        if (value.status) {
            this.selectedUsers.push(value);
            if (this.emailUsers) {
                let selectedUsers = this.emailUsers.find(x => x.Id === value.Id);
                this.emailUsers.forEach((element: any) => {
                    if (element.Id === value.Id && value.Id !== BaseServices.UserId && (!value.isEmail)) {
                        this.userSubscribers.push({
                            label: element.Name,
                            value: { Id: element.Id }
                        });
                    }
                });
            }
        } else {
            _.remove(this.selectedUsers, (val) => {
                if (this.userSubscribers) {
                    _.forEach(this.userSubscribers, (item: any) => {
                        if (item.value.Id == value.Id) {
                            this.userSubscribers.pop();
                        }
                    });
                }
                return val === value;
            })

        }
    }

    checkAll(event) {
        _.forEach(this.foodUsers, (item: any) => {
            if (event) {
                item.status = true;
            } else {
                item.status =
                    // to remain checked state for current user
                    (this.userId === +item.Id) ? true : false;
            }
        });

        this.selectedUsers = this.foodUsers;
        if (!event) {
            let userId = BaseServices.UserId;
            let name = BaseServices.Name;
            let checkUserId = this.selectedUsers.filter(x => x.Id == userId);
            if (checkUserId.length > 0) {
                this.userSubscribers = [];
                this.userSubscribers.push({ label: name, value: { Id: userId } });
            }
            this.selectedUsers = [];
            this.bindAllUsers = false;
            this.bindSelectedUsers = true;
        }
        else {
            this.bindAllUsers = true;
            this.bindSelectedUsers = false;
        }
    }

    bindDropDowns() {
        this.Zone = [];
        this.zoneData = this.route.snapshot.data['zonedetails'];
        if (this.zoneData) {
            this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
                this.Zone = [];
                this.Zone.push(
                    { label: val.SELECT, value: null }
                );
                this.zoneData.forEach(element => {
                    this.Zone.push({ label: element.Name, value: element.Id })
                });
            }));
        }

        this.fsZoneNames = [];
        let zones = this.route.snapshot.data['zones'];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.fsZoneNames = [];
            this.fsZoneNames.push(
                { label: val.SELECT, value: null }
            );
            if (zones) {
                zones.forEach(element => {
                    if (this.foodSafetyId != element.Id)
                        this.fsZoneNames.push({ label: element.ZoneName, value: element.Id })
                });
            }
        }));
        this.Department = [];
        this.deptData = this.route.snapshot.data['deptdetails'];
        if (this.deptData) {
            this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
                this.Department = [];
                this.Department.push(
                    { label: val.SELECT, value: null }
                );
                this.deptData.forEach(element => {
                    this.Department.push({ label: element.Name, value: element.Id })
                });
            }));
        }
    }
    viewDepartment() {
        this.subscriptions.push(this.businessDepartmentService.getCachedBusinessDepartment().subscribe(department => {
            this.departmentList = department
            if (department) {
                this.Department = [];
                this.departmentList.forEach((departmentList) => {
                    this.Department.push({ label: departmentList.Name, value: departmentList.Id })
                })
            }
        }));
    }

    editDepartment(deptdetails: Department) {
        this.departmentId = deptdetails.Id;
        let result = {
            Name: deptdetails.Name
        };
        (<FormGroup>this.departmentPopup)
            .setValue(result, { onlySelf: true });
    }

    saveDepartment() {
        let deptdetails = new Department();
        deptdetails.Id = (this.departmentId) ? this.departmentId : 0;
        deptdetails.Name = this.departmentPopup.value.Name;
        deptdetails.BusinessId = BaseServices.BusinessId;
        deptdetails.ApplicationId = BaseServices.ApplicationId;
        deptdetails.Status = '1';
        if (deptdetails.Id > 0) {
            deptdetails.ModifiedBy = BaseServices.UserId;
            this.clearCachedDepartment();
            this.subscriptions.push(this.foodSafetyService.updateDepartment(deptdetails)
                .subscribe((departmentEdited: any) => {
                    this.departmentId = null;
                    if (departmentEdited) {

                        this.viewDepartment();
                        this.toasterComponent.callToast();
                        this.foodSafetyForm.get('Department').patchValue(departmentEdited.Id)
                    } else {
                        this.isDepartmentNameExists = true;
                        setTimeout(
                            function () {
                                this.isDepartmentNameExists = false;
                            }.bind(this),
                            3000
                        );
                    }
                }));
        } else {
            deptdetails.CreatedBy = BaseServices.UserId;
            this.clearCachedDepartment();
            deptdetails.Id = 0;
            this.subscriptions.push(this.foodSafetyService.addDepartment(deptdetails)
                .subscribe((departmentAdded: any) => {
                    if (departmentAdded) {
                        this.viewDepartment();
                        this.toasterComponent.callToast();
                        this.foodSafetyForm.get('Department').patchValue(departmentAdded.Id)
                    } else {
                        this.isDepartmentNameExists = true;
                        setTimeout(
                            function () {
                                this.isDepartmentNameExists = false;
                            }.bind(this),
                            3000
                        );
                    }
                }))
        }
        this.clearDepartment();
    }
    onDepartmentPopupHide() {
        this.departmentPopup.reset();
    }
    onZonePopupHide() {
        this.zonePopup.reset();
    }
    /**
     * clear department popup
     */
    clearDepartment() {
        this.departmentPopup = this.fb.group({
            Name: ['']
        })
    }

    deleteDepartment(selectedDepartment) {
        this.subscriptions.push(this.foodSafetyService
            .checkAllocatedDepartment(selectedDepartment)
            .subscribe((result: any) => {
                // if (result) {
                //     this.isDepartmentNameAllocated = true;
                //     setTimeout(
                //         function () {
                //             this.isDepartmentNameAllocated = false;
                //         }.bind(this),
                //         3000
                //     );
                // } else {
                    this.confirmationService.confirm({
                        message: this.translate.instant('DELETE_THIS_RECORD'),
                        accept: () => {
                            this.subscriptions.push(this.foodSafetyService
                                .deleteDepartment(selectedDepartment)
                                .subscribe(result => {
                                    this.viewDepartment();
                                    this.bindDropDowns();
                                    this.clearCachedDepartment();
                                    this.toasterComponent.callToastDlt();
                                }));
                        }
                    });
                
            }))
    }

    clearCachedDepartment() {
        this.businessDepartmentService.clearCachedAllDepartment();
    }
    viewZone() {
        this.subscriptions.push(this.foodSafetyService
            .getZone(BaseServices.BusinessId)
            .subscribe((zone: any) => {
                this.zoneList = zone
                this.Zone = [];
                if (zone) {
                    zone.forEach((zoneList: any) => {
                        this.Zone.push({ label: zoneList.Name, value: zoneList.Id })
                    });
                }
            }));
    }


    editZone(zone: Zone) {
        this.zoneId = zone.Id;
        let result = {
            Name: zone.Name
        };
        (<FormGroup>this.zonePopup)
            .setValue(result, { onlySelf: true });
    }

    saveZone() {
        let zone = new Zone();
        zone.Id = (this.zoneId) ? this.zoneId : 0;
        zone.Name = this.zonePopup.value.Name;
        zone.ApplicationId = BaseServices.ApplicationId;
        zone.BusinessId = BaseServices.BusinessId;
        zone.Status = '1';
        if (zone.Id > 0) {
            this.subscriptions.push(this.foodSafetyService.updateZone(zone).subscribe((zoneEdited: any) => {
                if (zoneEdited) {
                    this.zoneId = null;
                    this.viewZone();
                    this.toasterComponent.callToast();
                    this.foodSafetyForm.get('ZoneName').patchValue(zoneEdited.Id)
                } else {
                    this.isZoneNameExists = true;
                    setTimeout(
                        function () {
                            this.isZoneNameExists = false;
                        }.bind(this),
                        3000
                    );
                }

            }))
        } else {
            zone.Id = 0;
            this.subscriptions.push(this.foodSafetyService.addZone(zone).subscribe((zoneAdded: any) => {
                if (zoneAdded) {
                    this.viewZone();
                    this.toasterComponent.callToast();
                    this.foodSafetyForm.get('ZoneName').patchValue(zoneAdded.Id)
                } else {
                    this.isZoneNameExists = true;
                    setTimeout(
                        function () {
                            this.isZoneNameExists = false;
                        }.bind(this),
                        3000
                    );
                }
            }))
        }
        this.clearZone();
    }
    clearZone() {
        this.zonePopup = this.fb.group({
            Name: ['']
        })
    }
    deleteZone(selectedZone) {
        this.subscriptions.push(this.foodSafetyService
            .checkAllocatedZone(selectedZone)
            .subscribe((result: any) => {
                if (result) {
                    this.isZoneNameAllocated = true;
                    setTimeout(
                        function () {
                            this.isZoneNameAllocated = false;
                        }.bind(this),
                        3000
                    );
                } else {
                    this.confirmationService.confirm({
                        message: this.translate.instant('DELETE_THIS_RECORD'),
                        accept: () => {
                            this.subscriptions.push(this.foodSafetyService
                                .deleteZone(selectedZone)
                                .subscribe(result => {
                                    this.viewZone();
                                    this.bindDropDowns();
                                    this.toasterComponent.callToastDlt();
                                }));
                        }
                    });
                }
            }));
    }
    saveFoodSafety() {
        if (this.foodSafetyForm.valid) {
            let foodSafety = new FoodSafety();
            let userId = BaseServices.UserId;
            let name = BaseServices.userName;
            let checkUserId = this.selectedUsers.filter(x => x.Id == userId);
            if (checkUserId.length === 0) {
                this.selectedUsers.push({ Id: userId, Name: name });
            }
            foodSafety.Id = (this.foodSafetyId) ? this.foodSafetyId : 0;
            foodSafety.FoodSafetyZoneId = this.foodSafetyForm.value.ZoneName;
            foodSafety.BusinessId = BaseServices.BusinessId
            foodSafety.Description = this.foodSafetyForm.value.Description;
            foodSafety.Status = '1';
            foodSafety.ShowDeviation = !this.foodSafetyForm.value.IsShowDeviationChecked;
            foodSafety.CopyZone = this.foodSafetyForm.value.ChooseZone;
            foodSafety.IsCopyFiles = this.foodSafetyForm.value.CopyFiles;
            foodSafety.IsCopyHazard = this.foodSafetyForm.value.CopyHazard;
            foodSafety.IsCopySJA = this.foodSafetyForm.value.CopySJA;
            foodSafety.FilesTemplateId = this.filesTemplateId;
            foodSafety.HazardTemplateId = this.hazardTemplateId;
            foodSafety.SJATemplateId = this.sJATemplateId;
            if ((this.bindSelectedUsers) && this.foodSafetyForm.value.selectedEmailSubscriber) {
                foodSafety.FoodEmailSubscribers = [];
                this.emailSubscriber = this.foodSafetyForm.value
                    .selectedEmailSubscriber;
            }
            if ((this.bindAllUsers) && this.foodSafetyForm.value.selectAllEmailSubscriber) {
                foodSafety.FoodEmailSubscribers = [];
                this.emailSubscriber = this.foodSafetyForm.value.selectAllEmailSubscriber;
            }
            let FoodUsers: FoodSafetyUser = new FoodSafetyUser();
            let Ids: any = ([] = []);

            this.selectedUsers.forEach((element: any) => {
                Ids.push({
                    UserId: element.Id,
                    foodSafetyId: foodSafety.Id,
                    Name: element.Name,
                    IsEmail: this.emailSubscriber.find(x => x.Id == element.Id) ? true : false
                });
            });
            foodSafety.FoodSafetyUserDto = Ids;
            if (foodSafety.Id > 0) {
                foodSafety.CreatedOn = this.createdOn;
                foodSafety.CreatedBy = this.createdBy;
                foodSafety.ModifiedBy = BaseServices.UserId;
                this.subscriptions.push(this.foodSafetyService.updateFoodsafety(foodSafety).subscribe((result: any) => {
                    this.toasterComponent.callToast();
                    this.filesTemplateId = result.FilesTemplateId;
                    this.hazardTemplateId = result.HazardTemplateId;
                    this.sJATemplateId = result.SJATemplateId;
                    this.gotoBack();
                }))
            } else {
                foodSafety.CreatedBy = BaseServices.UserId;
                this.subscriptions.push(this.foodSafetyService.createFoodsafety(foodSafety).subscribe((result: any) => {
                    this.toasterComponent.callToast();
                    this.filesTemplateId = result.FilesTemplateId;
                    this.hazardTemplateId = result.HazardTemplateId;
                    this.sJATemplateId = result.SJATemplateId;
                    this.createdBy = result.CreatedBy;
                    this.createdOn = result.CreatedOn;
                    this.gotoBack();
                }))
            }
        }
    }
    saveAndCreateNew() {
        if (this.foodSafetyForm.valid) {
            let foodSafety = new FoodSafety();
            foodSafety.Id = (this.foodSafetyId) ? this.foodSafetyId : 0;
            foodSafety.FoodSafetyZoneId = this.foodSafetyForm.value.ZoneName;
            foodSafety.BusinessId = BaseServices.BusinessId
            foodSafety.Description = this.foodSafetyForm.value.Description;
            foodSafety.CopyZone = this.foodSafetyForm.value.ChooseZone;
            foodSafety.IsCopyFiles = this.foodSafetyForm.value.CopyFiles;
            foodSafety.IsCopyHazard = this.foodSafetyForm.value.CopyHazard;
            foodSafety.IsCopySJA = this.foodSafetyForm.value.CopySJA;
            foodSafety.FilesTemplateId = this.filesTemplateId;
            foodSafety.HazardTemplateId = this.hazardTemplateId;
            foodSafety.SJATemplateId = this.sJATemplateId;
            foodSafety.Status = '1';
            foodSafety.CreatedOn = new Date();
            if ((this.bindSelectedUsers) && this.foodSafetyForm.value.selectedEmailSubscriber) {
                foodSafety.FoodEmailSubscribers = [];
                this.emailSubscriber = this.foodSafetyForm.value
                    .selectedEmailSubscriber;
            }
            if ((this.bindAllUsers) && this.foodSafetyForm.value.selectAllEmailSubscriber) {
                foodSafety.FoodEmailSubscribers = [];
                this.emailSubscriber = this.foodSafetyForm.value.selectAllEmailSubscriber;
            }
            let FoodUsers: FoodSafetyUser = new FoodSafetyUser();
            let Ids: any = ([] = []);

            this.selectedUsers.forEach((element: any) => {
                Ids.push({
                    UserId: element.Id,
                    foodSafetyId: foodSafety.Id,
                    Name: element.Name,
                    IsEmail: this.emailSubscriber.find(x => x.Id == element.Id) ? true : false
                });
            });
            foodSafety.FoodSafetyUserDto = Ids;
            if (foodSafety.Id > 0) {
                foodSafety.CreatedBy = this.createdBy;
                foodSafety.CreatedOn = this.createdOn;
                foodSafety.ModifiedBy = BaseServices.UserId;
                this.subscriptions.push(this.foodSafetyService.updateFoodsafety(foodSafety).subscribe((result: any) => {
                    this.toasterComponent.callToast();
                    this.filesTemplateId = result.FilesTemplateId;
                    this.hazardTemplateId = result.HazardTemplateId;
                    this.sJATemplateId = result.SJATemplateId;
                }))
            } else {
                foodSafety.CreatedBy = BaseServices.UserId;
                this.subscriptions.push(this.foodSafetyService.createFoodsafety(foodSafety).subscribe((result: any) => {
                    this.toasterComponent.callToast();
                    this.filesTemplateId = result.FilesTemplateId;
                    this.hazardTemplateId = result.HazardTemplateId;
                    this.sJATemplateId = result.SJATemplateId;
                    this.createdBy = result.CreatedBy;
                    this.createdOn = result.CreatedOn;
                }))
            }
        }
        this.foodSafetyId = 0;
        this.foodSafetyForm.reset();

    }
    gotoBack() {
        this.location.back();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
    
    CopyFiles(selectedValue){
        this.showCopyFiles = selectedValue.value > 0 ? true : false; 
        this.selectedZoneId = selectedValue.value; 
        if(selectedValue != this.selectedZoneId && (this.selectedZoneId > 0 || this.selectedZoneId != undefined)) {
            this.foodSafetyForm.controls['CopyFiles'].setValue(false);
            this.foodSafetyForm.controls['CopyHazard'].setValue(false);
            this.foodSafetyForm.controls['CopySJA'].setValue(false);
        } 
    }

}
