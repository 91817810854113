<div class="main-content">
    <div class="card">
        <div class="card-body">
            <div class="page-title">
                <span translate>RECEPTION_CONTROL</span>
            </div>
            <form [formGroup]="receptionForm">
                <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="col-form-label">
                          <span translate>STATUS</span>
                        </label>
                        <div>
                          <div class="radio-inline" *ngFor="let option of options">
                            <label>
                              <input
                                type="radio"
                                formControlName="Status"
                                [value]="option.id"
                                [checked]="option.id === receptionForm.get('Status').value"
                              >
                              {{ option.name | translate }}
                            </label>
                          </div>
                        </div>
                      </div>
                        <div class="form-group">
                            <label for="Supplier" class="col-form-label" translate>SUPPLIER
                                <span class="required">*</span>
                            </label>
                            <div class="input-btn-group">
                                <p-dropdown [style]="{'width':'100%'}" formControlName="Supplier"
                                  [options]="supplierList">
                                </p-dropdown>
                                <a href="#" [routerLink]="['../../../../../../supplier/new']"
                                  [queryParams]="{ reception : receptionId,foodsafety:foodSafetyId }"
                                  class="btn btn-outline-primary" type="button" (click)="addSupplier = !addSupplier">
                                    <i class="pi pi-plus" aria-hidden="true"></i>
                                </a>
                            </div>
                        </div>
                        <div class="form-group-75_25">
                            <div class="form-group">
                                <label for="quantityleft" class="col-form-label">
                                    <span translate>QUANTITY</span>
                                </label>
                                <div>
                                    <input type="text" pInputText class="form-control" formControlName="QuantityLeft"
                                      [attr.disabled]="(receptionId !== 0 && roleId !==3 ) ? true : null"
                                      (keypress)="onNumberOfDays($event,6)" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="quantityleft" class="col-form-label">
                                    <span>&nbsp;</span>
                                </label>
                                <div>
                                    <p-dropdown [style]="{'width':'100%'}" formControlName="QuantityMeasurement"
                                      [options]="measurementUnits"
                                      [disabled]="(receptionId !== 0 && roleId !==3 ) ? true : false">
                                    </p-dropdown>
                                </div>
                            </div>
                        </div>
                      <!-- Inside the form -->
                      <div class="form-group">
                        <label for="description" class="col-form-label" translate>KINDS_OF_GOODS
                          <span class="required">*</span>
                        </label>
                        <div class="input-btn-group">
                          <p-dropdown
                            [style]="{'width':'100%'}"
                            formControlName="KindsOfGoods"
                            [options]="goodsList"
                            [filter]="true"
                            placeholder="Select Goods Type"
                          ></p-dropdown>
                          <button class="btn btn-outline-primary" type="button" (click)="addGoodsType = !addGoodsType">
                            <i class="pi pi-plus" aria-hidden="true"></i>
                          </button>
                        </div>
                      </div>
                        <div class="form-group">
                            <label for="description" class="col-form-label" translate>DESCRIPTION
                            </label>
                            <div>
                                <textarea type="text" pInputText class="form-control"
                                  formControlName="Description"></textarea>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="information" class="col-form-label" translate>ADDITIONAL_INFO
                            </label>
                            <div>
                                <textarea type="text" pInputText class="form-control"
                                  formControlName="AdditionalInformation"></textarea>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="description" class="col-form-label" translate>TEMPERATURE_RC2
                            </label>
                            <div>
                                <p-spinner size="30" formControlName="Temperature" placeholder="-80°C to 300°C"
                                  [min]="-80" [max]="300"></p-spinner>

                            </div>
                        </div>
                        <div class="form-group">
                            <div class="tab-content">
                                <ecpl-document-explorer [uploaderConfig]="upConfig" [uploadedFiles]="uploadedFiles"
                                  (saveChanges)="saveUploaded($event)">
                                </ecpl-document-explorer>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div formArrayName="receptionArray">
                            <div
                              *ngFor="let Properties of receptionForm.controls['receptionArray']['controls']; let i=index"
                              class="form-group">
                                <div [formGroupName]="i">
                                    <h5 class="col-form-label" *ngIf="sectionNames[i] == 'TEMPERATURE'" translate>
                                        TEMPERATURE_RC</h5>
                                    <h5 class="col-form-label" *ngIf="sectionNames[i] != 'TEMPERATURE'" translate>
                                        {{sectionNames[i]}}</h5>
                                    <input type="textbox" formControlName="Id" hidden="true">
                                    <input type="textbox" formControlName="ReceptionControlCheckListId" hidden="true">
                                    <input type="checkbox" formControlName="IsOk" value="1"
                                      (change)="checkboxChange($event, i, 'OK')">
                                    <span translate>
                                        OK
                                    </span> &nbsp;
                                    <input type="checkbox" formControlName="check2" value="2"
                                      (change)="checkboxChange($event, i, 'D')">
                                    <span translate>
                                        DEVIATION
                                    </span> &nbsp;
                                    <div *ngIf="Properties.get('check2').value">
                                        <div class="form-group">
                                            <label class="" for="Comments">
                                                <span translate>COMMENTS</span>
                                            </label>
                                            <div class="">
                                                <input type="text" *ngIf="Properties.get('Isdisabled').value == true"
                                                  disabled pInputText formControlName="Comment"
                                                  placeholder="{{'COMMENTS'|translate}}" id="comments" />
                                                <input type="text" *ngIf="Properties.get('Isdisabled').value == false"
                                                  pInputText formControlName="Comment"
                                                  placeholder="{{'COMMENTS'|translate}}" id="comments" />
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="tab-content">
                                                <ecpl-document-explorer [uploaderConfig]="upDeviationConfig[i]"
                                                  [uploadedFiles]="receptionId != 0 ? uploadedDeviationFiles[i].DeviationAttachment : uploadedFiles"
                                                  (saveChanges)="saveUploadDeviationDoc($event,i)">
                                                </ecpl-document-explorer>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="action-btns-wrapper">
                    <button class="btn btn-outline-secondary" type="submit" (click)="gotoList()">
                        <span class="icon ic-xs icon-back">
                        </span>
                        <span translate>BACK_LIST</span>
                    </button>
                    <button class="btn btn-primary" type="submit" (click)="saveReception();"
                      [disabled]="receptionForm.invalid">
                        <span translate>SAVE</span>
                    </button>
                    <button class="btn btn-primary" type="submit" (click)="saveReception(); this.clearform();"
                      [disabled]="receptionForm.invalid">
                        <span translate>SAVE_CREATE_NEW</span>
                    </button>
                </div>
            </form>
            <div class="table-view">
                <!-- Table starts -->
                <p-table #dt [value]="receptionLogData" dataKey="Quantity" [paginator]="" [pageLinks]="3">
                    <!-- Header -->
                    <ng-template pTemplate="header">
                        <tr>
                            <th>{{'DATE_TIME'|translate}}</th>
                            <th>{{'QUANTITY_RECEIVED'|translate}}</th>
                            <th>{{'QNTY_USED'|translate}}</th>
                            <th>{{'QUANTITY_LEFT'|translate}}</th>
                            <th>{{'MODIFIED_BY' | translate}}</th>
                        </tr>
                    </ng-template>
                    <!-- Body Content -->
                    <ng-template pTemplate="body" let-reception>
                        <tr>
                            <td>
                                <span translate>{{ reception.CreatedOn | date : 'MM/dd/yyyy'}}</span>
                            </td>
                            <td>
                                <span>{{reception.QuantityRest}}</span>
                            </td>
                            <td>
                                <span>{{reception.Quantity}}</span>
                            </td>
                            <td>
                                <span>{{reception.QuantityLeft}}</span>
                            </td>
                            <td>
                                <span translate>{{reception.CreatedByName}}</span>
                            </td>
                        </tr>
                    </ng-template>
                    <!-- Empty message -->
                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td colspan="5">
                                {{'NO_RECORDS_FOUND'|translate}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <!-- Table Ends -->
            </div>
        </div>
    </div>
</div>
<p-dialog header="{{'GOODS_TYPE' | translate}}" (onHide)="onGoodsTypeFormHide()" [(visible)]="addGoodsType"
  [modal]="true" [style]="{width: '350px'}" [resizable]="false" [draggable]="false">
    <form [formGroup]="goodsTypeForm">
        <div class="form-group">
            <label for="title" class="col-form-label">
                <span translate>GOODS_TYPE</span>
                <span class="required">*</span>
            </label>
            <div>
                <input type="text" class="label-input form-control" formControlName="Name"
                  [(ngModel)]="selectedType.Name">
            </div>
        </div>
    </form>
    <!-- Footer -->
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
        <button type="button" class="btn btn-outline-primary" (click)="viewGoodsType = !viewGoodsType">
            <span translate>VIEW</span>
        </button>
        <button type="button" class="btn btn-primary" (click)="saveGoodsType()" [disabled]="goodsTypeForm.invalid">
            <span translate>SAVE</span>
        </button>
    </div>
    <div *ngIf="viewGoodsType">
        <div class="table-view">
            <!-- Table starts -->
            <p-table [value]="goodsTypeData" [rows]="5" [paginator]="true" [pageLinks]="3"
              [rowsPerPageOptions]="[5,10,20]">
                <!-- Header -->
                <ng-template pTemplate="header">
                    <tr>
                        <th>{{'GOODS_TYPE' | translate}}</th>
                        <th></th>
                    </tr>
                </ng-template>
                <!-- Body Content -->
                <ng-template pTemplate="body" let-reception>
                    <tr>
                        <td>
                            <span>{{reception.Name}}</span>
                        </td>
                        <td class="col-button">
                            <button type="button" class="btn btn-icon" title="{{'EDIT'|translate}}"
                              (click)="editGoodsType(reception)">
                                <i class="icon ic-sm icon-pencil-tip"></i>
                            </button>
                            <button type="button" class="btn btn-icon" title="{{'DELETE'|translate}}"
                              (click)="deleteGoodsType(reception)">
                                <i class="icon ic-sm icon-trash"></i>
                            </button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <!-- Table Ends -->
        </div>
    </div>
    <!-- </ng-template> -->
</p-dialog>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
  acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
<router-outlet></router-outlet>
<toaster-component></toaster-component>
