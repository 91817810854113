<div class="tab-content">
  <div class="page-title">
    <span translate>FIRST_OF_KIN</span>
  </div>
  <form [formGroup]="firstOfKinkForm">
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group" *ngIf="firstOfKinkForm.controls['ContactName']">
          <label for="ContactName">
            <span translate>CONTACT_PERSON</span>
            <span class="required">*</span>
          </label>
          <div>
            <input
              type="text"
              pInputText
              formControlName="ContactName"
              id="ContactName"
              maxlength="100"
            />
          </div>
          <control-messages
            [control]="firstOfKinkForm.controls['ContactName']"
          ></control-messages>
        </div>
        <div class="form-group" *ngIf="firstOfKinkForm.controls['Mobile']">
          <label for="Mobile">
            <span translate>MOBILE</span>
            <span class="required">*</span>
          </label>
          <div class="phone-field">
            <p-dropdown
              [options]="mobileCodes"
              formControlName="MobileCodes"
              [filter]="true"
              [style]="{ width: '110px' }"
            >
            </p-dropdown>
            <input
              type="number"
              pInputText
              formControlName="Mobile"
              id="Mobile"
              (keypress)="onMobileNumber($event, 20)"
            />
          </div>
          <control-messages
            [control]="firstOfKinkForm.controls['Mobile']"
          ></control-messages>
        </div>
        <div class="form-group" *ngIf="firstOfKinkForm.controls['Email']">
          <label for="Email">
            <span translate>EMAIL</span>
          </label>
          <div>
            <input type="text" pInputText formControlName="Email" id="Email" />
          </div>
          <control-messages
            [control]="firstOfKinkForm.controls['Email']"
          ></control-messages>
        </div>
        <div class="form-group">
          <label for="Relation">
            <span translate>RELATION</span>
          </label>
          <div>
            <input
              type="text"
              pInputText
              formControlName="Relation"
              id="Relation"
              maxlength="100"
            />
          </div>
          <control-messages
            [control]="firstOfKinkForm.controls['Relation']"
          ></control-messages>
        </div>
        <div class="form-group">
          <label for="Address">
            <span translate>ADDRESS</span>
          </label>
          <div>
            <textarea
              id="Address"
              name="Address"
              pInputTextarea
              formControlName="Address"
              [autoResize]="true"
            ></textarea>
          </div>
          <control-messages
            [control]="firstOfKinkForm.controls['Address']"
          ></control-messages>
        </div>
        <div class="form-group">
          <label for="ZipOrCity">
            <span translate>CITY_ZIP</span>
          </label>
          <div>
            <input
              type="text"
              pInputText
              formControlName="ZipCode"
              id="Zip"
              maxlength="20"
            />
          </div>
          <control-messages
            [control]="firstOfKinkForm.controls['ZipCode']"
          ></control-messages>
        </div>
      </div>
    </div>
    <div class="action-btns-wrapper text-left">
      <div class="row">
        <div class="col-sm-6">
          <a
            class="btn btn-outline-secondary"
            (click)="onClear()"
            role="button"
          >
            <span translate>CLEAR</span>
          </a>
          <button
            class="btn btn-primary"
            [ngClass]="{ loading: loading }"
            *ngIf="isShow"
            type="submit"
            [disabled]="!firstOfKinkForm.valid || loading"
            (click)="saveChanges()"
          >
            <span *ngIf="!loading" translate>SAVE</span>
            <span *ngIf="loading" translate>SAVE_PROCESS</span>
            <span *ngIf="loading" class="loader-icon"></span>
          </button>
        </div>
      </div>
    </div>
  </form>
  <div class="table-view">
    <!-- Table starts -->
    <p-table
      [value]="employeefok"
      [rows]="10"
      #dt
      [paginator]="true"
      [pageLinks]="3"
      [rowsPerPageOptions]="[5, 10, 20]"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{{ 'SUMMARY_TOTALRECORDS' | translate }}"
    >
      <!-- Caption -->
      <ng-template pTemplate="caption">
        <div class="ui-helper-clearfix">
          <div class="table-options">
            <div class="pull-left"></div>
            <div class="pull-right">
              <div class="action-btns-group">
                <kuba-export
                  [reportData]="dt.filteredValue || dt.value"
                  [additionalData]="additionalData"
                ></kuba-export>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <!-- Header -->
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="ContactName">
            {{ "NAME" | translate }}
            <p-sortIcon field="ContactName"></p-sortIcon>
          </th>
          <th pSortableColumn="Mobile">
            {{ "MOBILE" | translate }} <p-sortIcon field="Mobile"></p-sortIcon>
          </th>
          <th pSortableColumn="Email">
            {{ "EMAIL" | translate }} <p-sortIcon field="Email"></p-sortIcon>
          </th>
          <th pSortableColumn="Relation">
            {{ "RELATION" | translate }}
            <p-sortIcon field="Relation"></p-sortIcon>
          </th>
          <th pSortableColumn="Address">
            {{ "ADDRESS" | translate }}
            <p-sortIcon field="Address"></p-sortIcon>
          </th>
          <th pSortableColumn="ZipCode">
            {{ "CITY_ZIP" | translate }}
            <p-sortIcon field="ZipCode"></p-sortIcon>
          </th>
          <th>{{ "OPTIONS" | translate }}</th>
        </tr>
      </ng-template>
      <!-- Body Content -->
      <ng-template pTemplate="body" let-firstofkin>
        <tr>
          <td>
            <span>{{ firstofkin.ContactName }}</span>
          </td>
          <td>
            <span>{{ firstofkin.Mobile }}</span>
          </td>
          <td>
            <span>{{ firstofkin.Email }}</span>
          </td>
          <td>
            <span>{{ firstofkin.Relation }}</span>
          </td>
          <td>
            <span>{{ firstofkin.Address }}</span>
          </td>
          <td>
            <span>{{ firstofkin.ZipCode }}</span>
          </td>
          <td>
            <button
              type="button"
              class="btn btn-icon"
              title="{{ 'EDIT' | translate }}"
              (click)="editEmployee(firstofkin['Id'])"
            >
              <i class="icon ic-sm icon-pencil-tip"></i>
            </button>
            <button
              type="button"
              class="btn btn-icon"              
              title="{{ 'DELETE' | translate }}"
              (click)="delete(firstofkin['Id'])"
            >
              <i class="icon ic-sm icon-trash"></i>
            </button>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <!-- Table ends -->
  </div>
</div>
<p-confirmDialog
  header="{{ 'CONFIRMATION' | translate }}"
  icon="pi pi-question-circle"
  width="425"
  acceptLabel="{{ 'SWITCH_YES' | translate }}"
  rejectLabel="{{ 'SWITCH_NO' | translate }}"
></p-confirmDialog>
<toaster-component></toaster-component>
