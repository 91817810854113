import { Directive, OnDestroy, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Calendar } from 'primeng/calendar';
import { Subscription } from 'rxjs';

@Directive({
  selector: 'p-calendar[dateRangeEnd]',
  exportAs: 'dateRangeEnd',
})
export class DateRangeEndDirective implements OnDestroy {
  private subscriptions: Subscription[] = [];

  constructor(
    @Self() private calendar: Calendar,
    @Optional() @Self() private control: NgControl | null
  ) {}

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public setMinDate(date: Date | null): void {
    this.calendar.minDate = date;
  }

  public initializeRange(startCalendar: Calendar): void {
    // Set initial max date on start calendar if end date is selected
    if (this.calendar.value) {
      startCalendar.maxDate = this.calendar.value;
    }

    // Setup listeners for end date changes
    if (this.control?.control) {
      this.setupControlListeners(startCalendar);
    } else {
      this.setupCalendarListeners(startCalendar);
    }
  }

  private setupControlListeners(startCalendar: Calendar) {
    this.subscriptions.push(
      this.control.control.valueChanges.subscribe((date) => {
        startCalendar.maxDate = date;
      })
    );
  }

  private setupCalendarListeners(startCalendar: Calendar) {
    this.subscriptions.push(
      this.calendar.onSelect.subscribe((date) => {
        startCalendar.maxDate = date;
      }),
      this.calendar.onClear.subscribe(() => {
        startCalendar.maxDate = null;
      }),
      this.calendar.onInput.subscribe(() => {
        startCalendar.maxDate = this.calendar.value;
      })
    );
  }
}
