import { Route } from '@angular/router';
import { AuthGuard } from './../../_guards/index';
import { ClientListByBusinessResolver } from './../client/services/client.resolver.service';
import {
  EmployeeContactUserResolver,
  EmployeeContactUserSubscriptionResolver,
} from './../users/services/user.resolver.service';
import { ProjectComponent } from './project.component';
import {
  ClientUserListResolver,
  ProjectEditResolver,
  ProjectFdvStatusResolver,
  ProjectFeaturesResolver,
  ProjectListByUserResolver,
  ProjectListResolver,
  ProjectNameListResolver,
  ResponsibiltyListResolver,
  SubContractorListResolver,
} from './services/project.resolver';

import {
  ProjectEditComponent,
  ProjectListAllComponent,
  ProjectListComponent,
  ProjectListOwnComponent,
  ProjectNewComponent,
} from './components/index';

import { FeatureDocumentRoutes } from 'src/app/kuba/feature-documents/feature-document.routing';
import { ProjectViewComponent } from 'src/app/kuba/projects/components/project-view.component';
import { SjaFormRoutingModule } from '../sja-form/sja-form-routing.module';
import { taskmanagerRoutes } from '../task-manager/task-manager.routing';
import { crewroutes } from './../crew-list/crew-list.routing';
import { declarationOfConformityRoutes } from './../declaration-of-conformity/declaration-of-conformity.routing';
import { deviationRoutes } from './../deviation/deviation.routing';

import { fdvDocumentRoutes } from './../fdv-Document/fdv-doc.routing';
import { registrationRoutes } from './../Registration/registration.routing';
import { safetyjobRoutes } from './../safety-job-analysis/jobanalysis.routing';
import { timetableRoutes } from './../time-table/timetable.routing';

export const projectRoutes: Route[] = [
  {
    path: 'project',
    component: ProjectComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'list',
        component: ProjectListComponent,
        canActivate: [AuthGuard],
        children: [
          { path: '', redirectTo: 'own', pathMatch: 'full' },
          {
            path: 'own',
            component: ProjectListOwnComponent,
            resolve: {
              myList: ProjectListByUserResolver,
              reponsibilities: ResponsibiltyListResolver,
            },
          },
          {
            path: 'all',
            component: ProjectListAllComponent,
            resolve: {
              List: ProjectListResolver,
              reponsibilities: ResponsibiltyListResolver,
            },
          },
        ],
      },
      {
        path: 'edit/:id',
        component: ProjectEditComponent,
        canActivate: [AuthGuard],
        resolve: {
          edit: ProjectEditResolver,
          users: EmployeeContactUserResolver,
          clientUsers: ClientUserListResolver,
          subcontractors: SubContractorListResolver,
          clients: ClientListByBusinessResolver,
          reponsibilities: ResponsibiltyListResolver,
          projectName: ProjectNameListResolver,
          users_subscription: EmployeeContactUserSubscriptionResolver,
          projectFdvStatus: ProjectFdvStatusResolver,
        },
      },
      {
        path: 'new',
        component: ProjectNewComponent,
        canActivate: [AuthGuard],
        resolve: {
          users: EmployeeContactUserResolver,
          clientUsers: ClientUserListResolver,
          subcontractors: SubContractorListResolver,
          clients: ClientListByBusinessResolver,
          reponsibilities: ResponsibiltyListResolver,
          projectName: ProjectNameListResolver,
          users_subscription: EmployeeContactUserSubscriptionResolver,
        },
      },
      {
        path: 'details/:id',
        component: ProjectViewComponent,
        resolve: {
          additionalFeatures: ProjectFeaturesResolver,
          project: ProjectEditResolver,
          projectFdvStatus: ProjectFdvStatusResolver,
        },
        data: { parent: 'PROJECT' },
        canActivate: [AuthGuard],
        children: [
          ...FeatureDocumentRoutes,
          ...deviationRoutes,
          ...taskmanagerRoutes,
          ...timetableRoutes,
          ...safetyjobRoutes,
          ...registrationRoutes,
          ...declarationOfConformityRoutes,
          ...crewroutes,
          ...SjaFormRoutingModule,
        ],
      },
    ],
  },
];
