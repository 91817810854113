<div class="tab-content">
  <div class="page-title">
    <span translate>NEW_DEVIATION</span>
  </div>
  <form class="form" [formGroup]="deviationForm">
    <div class="row">
      <div class="col-sm-4 section">
        <!-- <div class="form-group">
          <label class="col-form-label">
            <span translate>STATUS</span>
          </label>
          <div>
            <input
              translate
              type="text"
              readonly="readonly"
              formControlName="Status"
              class="form-control"
            />
          </div>
        </div> -->
        <h4 translate>DEVIATION_INFORMATION</h4>
        <div class="form-group" *ngIf="deviationForm.controls['Department']">
          <label for="title" class="col-form-label">
            <span translate>DEPARTMENT</span>
            <span class="required">*</span>
          </label>
          <div class="input-btn-group">
            <p-multiSelect
              [filter]="true"
              formControlName="Department"
              [options]="departments"
              #departmentDropdown
              (onChange)="onDepartmentDropDownChanged(departmentDropdown)"
              [style]="{ width: '100%' }"
              [(ngModel)]="selectedDepartments"
              [defaultLabel]="'SELECT' | translate"
            >
              <ng-template let-status pTemplate="item">
                {{ status.label | translate }}
              </ng-template>
            </p-multiSelect>
            <button
              *ngIf="isHideGuest"
              class="btn btn-outline-primary"
              type="button"
              (click)="addDept = !addDept"
            >
              <i class="pi pi-plus" aria-hidden="true"></i>
            </button>
          </div>
          <control-messages
            [control]="deviationForm.controls['Department']"
          ></control-messages>
        </div>
        <div class="form-group" [hidden]="IsHidden">
          <label for="Position">
            <span translate>SUB_DEPARTMENT_NAME</span>
          </label>
          <div class="input-btn-group">
            <p-dropdown
              [filter]="true"
              [options]="subDepartmentsList"
              formControlName="SubDepartment"
              [style]="{ width: '100%' }"
              [disabled]="hideSaveUserButton"
            ></p-dropdown>
            <button
              class="btn btn-outline-primary"
              type="button"
              *ngIf="isAddNewEmployee"
              (click)="addSubDept = !addSubDept"
            >
              <i class="pi pi-plus" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        <div class="form-group" *ngIf="deviationForm.controls['Topic']">
          <label for="title" class="col-form-label">
            <span translate>TOPICS</span>
            <span class="required">*</span>
          </label>
          <div class="input-btn-group">
            <p-dropdown
              [filter]="true"
              formControlName="Topic"
              [options]="Topics"
              [style]="{ width: '100%' }"
            >
              <ng-template let-status pTemplate="item">
                {{ status.label | translate }}
              </ng-template>
            </p-dropdown>
            <button
              *ngIf="isHideGuest"
              type="button"
              class="btn btn-outline-primary"
              (click)="addTopic = !addTopic"
            >
              <i class="pi pi-plus" aria-hidden="true"></i>
            </button>
          </div>
          <control-messages
            [control]="deviationForm.controls['Topic']"
          ></control-messages>
        </div>
        <div class="form-group" *ngIf="deviationForm.controls['TypeCase']">
          <label class="col-form-label">
            <span translate>TYPE_OF_CASE</span>
            <span class="required">*</span>
          </label>
          <div class="input-btn-group">
            <p-dropdown
              (dataKey)="('Id')"
              [filter]="true"
              formControlName="TypeCase"
              [options]="TypeOfCases"
              [style]="{ width: '100%' }"
            >
              <ng-template let-status pTemplate="item">
                {{ status.label | translate }}
              </ng-template>
            </p-dropdown>
            <button
              *ngIf="isHideGuest"
              type="button"
              class="btn btn-outline-primary"
              (click)="addtypeOfCase = !addtypeOfCase"
            >
              <i class="pi pi-plus" aria-hidden="true"></i>
            </button>
          </div>
          <control-messages
            [control]="deviationForm.controls['TypeCase']"
          ></control-messages>
        </div>
        <div class="form-group" *ngIf="deviationForm.controls['Title']">
          <label for="title" class="col-form-label">
            <span translate>TITLE</span>
            <span class="required">*</span>
          </label>
          <div>
            <input
              type="text"
              class="label-input form-control"
              formControlName="Title"
              name="title"
            />
          </div>
          <control-messages
            [control]="deviationForm.controls['Title']"
          ></control-messages>
        </div>
        <div class="form-group">
          <label for="title" class="col-form-label">
            <span translate>DESCRIPTION</span>
          </label>
          <div>
            <textarea
              class="form-control"
              formControlName="Description"
              name="Description"
            ></textarea>
          </div>
        </div>
        <div class="form-group">
          <label for="title" class="col-form-label">
            <span translate>TIME_OF_EVENT</span>
          </label>
          <div style="display: flex; justify-content: space-between">
            <span style="width: 140px">
              <my-date-picker
                name="EventDate"
                [options]="eventDateOptions"
                formControlName="EventDate"
                (dateChanged)="enableClear()"
                placeholder="{{ 'SELECT_EVENT_DATE' | translate }}"
              >
              </my-date-picker>
            </span>
            <span style="width: 100px">
              <p-calendar
                [timeOnly]="true"
                readonlyInput="true"
                [style]="{ width: '100%' }"
                (onSelect)="enableClear()"
                formControlName="EventTime"
                styleClass="dateTimeField"
              >
              </p-calendar>
            </span>
            <span>
              <button
                type="button"
                class="btn btn-primary"
                (click)="bindEventDate()"
              >
                <span translate>NOW</span>
              </button>
              <button
                type="button"
                class="btn btn-outline-secondary"
                [disabled]="!IsClear"
                (click)="clearEventDate()"
              >
                <span translate>CLEAR</span>
              </button>
            </span>
          </div>
        </div>
        <div class="form-group">
          <label for="title" class="col-form-label">
            <span translate>EVENT_LOCATION</span>
          </label>
          <div>
            <input
              type="text"
              class="label-input form-control"
              formControlName="EventLocation"
              name="EventLocation"
            />
          </div>
        </div>

        <div class="form-group">
          <label for="immediate Action" class="col-form-label">
            <span translate>IMMEDIATE_ACTION</span>
          </label>
          <div>
            <textarea
              pInputTextarea
              autoResize="autoResize"
              formControlName="ImmediateAction"
            ></textarea>
          </div>
        </div>
        <div class="form-group">
          <div class="tab-content">
            <ecpl-document-explorer
              [uploaderConfig]="upConfig"
              [uploadedFiles]="uploadedFiles"
              (saveChanges)="saveUploaded($event)"
            >
            </ecpl-document-explorer>
          </div>
        </div>
        <!-- Send only to Department -->
        <!-- <div class="form-group" hidden="true">
          <input
            type="checkbox"
            formControlName="SendOnlyDepartment"
            name="checkbox"
            [checked]="false"
          />
          <label for="title" class="col-form-label">
            <span translate>SEND_ONLY_TO_DEPARTMENT</span>
          </label>
          <label for="title" class="col-form-label">
            <span translate>IF_UNCHECKED</span>
          </label>
        </div> -->
        <!-- Send only to Editors -->
        <!-- <div class="form-group checkbox">
          <label>
            <input
              type="checkbox"
              formControlName="SendonlytoEditors"
              name="checkbox"
            />
            <span translate>SEND_ONLY_TO_EDITORS</span>
          </label>
        </div> -->
      </div>
      <div class="col-sm-4 section" *ngIf="showMore">
        <h4 translate>MANAGE_DEVIATION</h4>

        <div [hidden]="!showMore" class="form-group">
          <div class="form-group" *ngIf="deviationForm.controls['Deadline']">
            <label for="deadline" class="control-label">
              <span translate>DEADLINE</span>
              <span class="required">*</span>
            </label>
            <div>
              <my-date-picker
                name="startdate"
                [options]="startDateOptions"
                formControlName="Deadline"
                placeholder="{{ 'SELECT_DEADLINE_DATE' | translate }}"
                (dateChanged)="onDeadLineChanged($event)"
              ></my-date-picker>
            </div>
            <control-messages
              [control]="deviationForm.controls['Deadline']"
            ></control-messages>
          </div>
          <div class="form-row form-group">
            <div
              class="form-group flex-item"
              *ngIf="deviationForm.controls['Importance']"
            >
              <label for="importance" class="control-label">
                <span translate>IMPORTANCE_FULL</span>
                <span class="required">*</span>
              </label>
              <div>
                <p-dropdown
                  formControlName="Importance"
                  [style]="{ width: '100%' }"
                  #importance
                  [options]="importances"
                ></p-dropdown>
              </div>
              <control-messages
                [control]="deviationForm.controls['Importance']"
              ></control-messages>
            </div>
            <div class="form-group flex-item">
              <label for="cost" class="control-label">
                <span translate>COST</span>
              </label>
              <div>
                <input
                  type="number"
                  formControlName="Cost"
                  pInputText
                  (keypress)="onNumber($event, 15)"
                />
              </div>
              <!-- <control-messages [control]="deviationEditForm['controls'].Cost"></control-messages> -->
            </div>
          </div>

          <div class="form-group">
            <label for="cause Analysis" class="control-label">
              <span translate>CAUSE_ANALYSIS</span>
            </label>
            <div>
              <textarea
                pInputTextarea
                autoResize="autoResize"
                formControlName="CauseAnalysis"
              ></textarea>
            </div>
          </div>
          <div class="form-group">
            <label for="corrective Action" class="control-label">
              <span translate>CORRECTIVE_ACTION</span>
            </label>
            <div>
              <textarea
                pInputTextarea
                autoResize="autoResize"
                formControlName="CorrectiveAction"
              ></textarea>
            </div>
          </div>
          <div class="form-group">
            <label for="effect of Action" class="control-label">
              <span translate>EFFECT_OF_ACTION</span>
            </label>
            <div>
              <textarea
                pInputTextarea
                autoResize="autoResize"
                formControlName="EffectOfAction"
              ></textarea>
            </div>
          </div>
          <div class="form-group">
            <label for="comment" class="control-label">
              <span translate>COMMENT</span>
            </label>
            <div>
              <textarea
                pInputTextarea
                autoResize="autoResize"
                formControlName="Comment"
              ></textarea>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-4 section" *ngIf="showMore">
        <h4 translate>RESPONSIBILITY_AND_NOTIFICATIONS</h4>
        <div class="form-group" *ngIf="deviationForm.controls['Execution']">
          <label for="execution" class="control-label">
            <span translate>RESPONSIBLE_FOR_EXECUTION</span>
            <span class="required">*</span>
          </label>
          <div>
            <p-dropdown
              [options]="executions"
              formControlName="Execution"
              [style]="{ width: '100%' }"
              [filter]="true"
              #exec
              (onChange)="onExecChanged($event, exec)"
            ></p-dropdown>
          </div>
          <control-messages
            [control]="deviationForm.controls['Execution']"
          ></control-messages>
        </div>
        <div class="form-group">
          <label for="notification" class="control-label">
            <span translate>NOTIFICATION_BEFORE_DEADLINE_DEVIATION</span>
          </label>
          <div>
            <p-dropdown
              [options]="followUpNotify"
              formControlName="Notification"
              [style]="{ width: '100%' }"
            ></p-dropdown>
          </div>
        </div>
        <div class="form-group" *ngIf="deviationForm.controls['Followup']">
          <label for="follow-up" class="control-label">
            <span translate>RESPONSIBLE_FOR_FOLLOW_UP</span>
            <span class="required">*</span>
          </label>
          <div>
            <p-dropdown
              [options]="followUps"
              [filter]="true"
              formControlName="Followup"
              [style]="{ width: '100%' }"
              #follow
              (onChange)="onFollowChanged($event, follow)"
            >
            </p-dropdown>
          </div>
          <control-messages
            [control]="deviationForm.controls['Followup']"
          ></control-messages>
        </div>
        <div class="form-group">
          <label for="notification" class="control-label">
            <span translate>NOTIFICATION_FOR_FOLLOW_UP_DAYS</span>
          </label>
          <div>
            <p-dropdown
              [options]="followUpNotify"
              formControlName="FollowupNotification"
              placeholder="1"
              [style]="{ width: '100%' }"
            ></p-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div class="action-btns-wrapper">
      <button
        type="button"
        class="btn btn-outline-secondary"
        (click)="gotoList()"
      >
        <span class="icon ic-xs icon-back"></span>
        <span translate>BACK_LIST</span>
      </button>
      <button
        class="btn btn-outline-primary"
        (click)="ShowMore(true)"
        *ngIf="!showMore"
      >
        <span translate>MANAGE_DEVIATION_NOW</span>
      </button>
      <button
        class="btn btn-outline-secondary"
        (click)="ShowMore(false)"
        *ngIf="showMore"
      >
        <span translate>CLOSE_MANAGING</span>
      </button>
      <button
        type="submit"
        class="btn btn-primary"
        [ngClass]="{ loading: loading }"
        [disabled]="!deviationForm.valid || loading"
        (click)="saveDeviation()"
      >
        <span *ngIf="!loading" translate>SAVE</span>
        <span *ngIf="loading" translate>SAVE_PROCESS</span>
        <span *ngIf="loading" class="loader-icon"></span>
      </button>
    </div>
  </form>
</div>
<p-dialog
  header="{{ 'ADD_DEPARTMENT' | translate }}"
  [(visible)]="addDept"
  [style]="{ width: '300px' }"
  [resizable]="false"
  [draggable]="false"
  (onHide)="onBeforeDeptDialogHide()"
>
  <div class="form-group" [formGroup]="departmentForm">
    <label for="title" class="col-form-label">
      <span translate>NAME</span>
    </label>
    <div>
      <input
        type="text"
        disabled="{{ deptName }}"
        #Title
        class="label-input form-control"
        formControlName="Name"
      />
      <span style="color: red" *ngIf="isDepartmentNameExists">{{
        existsDepartmentAlerttext
      }}</span>
    </div>
    <a
      *ngIf="addSubDept"
      type="text"
      href="javascript:void(0);"
      (click)="addSubDepartment()"
      translate
      >ADD_SUB_DEPARTMENT</a
    >
    <div *ngIf="showSubDept" [formGroup]="SubDepartmentForm">
      <label for="title" class="col-form-label">
        <span translate>SUB_DEPARTMENT</span>
      </label>
      <div>
        <input
          type="text"
          formControlName="SubDepartment"
          #Title
          class="label-input form-control"
        />
      </div>
    </div>
  </div>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
      <button
        type="button"
        class="btn btn-outline-primary"
        (click)="viewDept = !viewDept"
      >
        <span translate>VIEW</span>
      </button>
      <button
        type="button"
        class="btn btn-primary"
        [disabled]="checkDeptValid()"
        (click)="saveDepartment()"
      >
        <span translate>SAVE</span>
      </button>
    </div>
    <div *ngIf="viewDept">
      <div class="table-view">
        <!-- Table starts -->
        <p-table
          #deviation
          [value]="editDepts"
          [rows]="10"
          [paginator]="true"
          [pageLinks]="3"
          [rowsPerPageOptions]="[5, 10, 20]"
          [responsive]="true"
        >
          <!-- Header -->
          <ng-template pTemplate="header">
            <tr>
              <th>{{ "Id" | translate }}</th>
              <th>{{ "TYPE_NAME" | translate }}</th>
              <th></th>
            </tr>
            <tr>
              <th></th>
              <th>
                <input
                  pInputText
                  type="text"
                  (input)="
                    deviation.filter(
                      $any($event.target)?.value,
                      'Name',
                      'contains'
                    )
                  "
                  placeholder=""
                  class="p-column-filter"
                />
              </th>
              <th></th>
            </tr>
          </ng-template>
          <!-- Body Content -->
          <ng-template pTemplate="body" let-Type>
            <tr>
              <td>
                <span>{{ Type.Id }}</span>
              </td>
              <td>
                <span>{{ Type.Name }}</span>
              </td>
              <td class="col-button">
                <button
                  type="button"
                  class="btn btn-icon"
                  title="{{ 'EDIT' | translate }}"
                  (click)="editDept(Type)"
                >
                  <i class="icon ic-sm icon-pencil-tip"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-icon"
                  title="{{ 'DELETE' | translate }}"
                  (click)="deleteDept(Type)"
                >
                  <i class="icon ic-sm icon-trash"></i>
                </button>
              </td>
            </tr>
          </ng-template>
          <!-- Empty message -->
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td colspan="3">
                {{ "NO_RECORDS_FOUND" | translate }}
              </td>
            </tr>
          </ng-template>
        </p-table>
        <!-- Table Ends -->
      </div>
    </div>
  </p-footer>
</p-dialog>
<p-dialog
  header="{{ 'ADD_CASE' | translate }}"
  [(visible)]="addtypeOfCase"
  [style]="{ width: '300px' }"
  [resizable]="false"
  [draggable]="false"
  (onHide)="onBeforeCaseDialogHide()"
>
  <div class="form-group" [formGroup]="caseForm">
    <label for="title" class="col-form-label">
      <span translate>NAME</span>
    </label>
    <div>
      <input
        type="text"
        #Title
        class="label-input form-control"
        formControlName="Name"
      />
      <span style="color: red" *ngIf="isTypeCaseNameExists">{{
        existsTypeCaseAlerttext
      }}</span>
    </div>
  </div>
  <p-footer>
    <div
      class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix"
      [formGroup]="deviationForm"
    >
      <button
        type="button"
        class="btn btn-outline-primary"
        (click)="viewTypeOfCase = !viewTypeOfCase"
      >
        <span translate>VIEW</span>
      </button>
      <button
        type="button"
        class="btn btn-primary"
        [disabled]="!caseForm.valid"
        (click)="saveTypeOfCase()"
      >
        <span translate>SAVE</span>
      </button>
    </div>
    <div *ngIf="viewTypeOfCase">
      <div class="table-view">
        <!-- Table starts -->
        <p-table
          [value]="editTypeOfCases"
          [rows]="10"
          [paginator]="true"
          [pageLinks]="3"
          [rowsPerPageOptions]="[5, 10, 20]"
          [responsive]="true"
        >
          <!-- Header -->
          <ng-template pTemplate="header">
            <tr>
              <th>{{ "TYPE_NAME" | translate }}</th>
              <th></th>
            </tr>
          </ng-template>
          <!-- Body Content -->
          <ng-template pTemplate="body" let-Type>
            <tr>
              <td>
                <span>{{ Type.Name }}</span>
              </td>
              <td class="col-button">
                <button
                  type="button"
                  class="btn btn-icon"
                  title="{{ 'EDIT' | translate }}"
                  (click)="editTypeOfCase(Type)"
                >
                  <i class="icon ic-sm icon-pencil-tip"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-icon"
                  title="{{ 'DELETE' | translate }}"
                  (click)="deleteTypeOfCase(Type.Id)"
                >
                  <i class="icon ic-sm icon-trash"></i>
                </button>
              </td>
            </tr>
          </ng-template>
          <!-- Empty message -->
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td colspan="2">
                {{ "NO_RECORDS_FOUND" | translate }}
              </td>
            </tr>
          </ng-template>
        </p-table>
        <!-- Table Ends -->
      </div>
    </div>
  </p-footer>
</p-dialog>
<p-dialog
header="{{ 'ADD_SUBDEPARTMENT' | translate }}"
[(visible)]="addSubDept"
*ngIf="addSubDept"
[style]="{ width: '300px' }"
[resizable]="false"
[draggable]="false"
[dismissableMask]="true"
[modal]="true"
(onHide)="onBeforeSubDepartmentDialogHide()"
>
<form [formGroup]="subDepartmentPopup">
  <div class="form-group">
    <label for="selectedDept" class="col-form-label">
      <span translate>DEPARTMENT</span>
    </label>
    <div>
      <p-dropdown
        [filter]="true"
        appendTo="body"
        [options]="departments"
        [(ngModel)]="selectedSubDept"
        [style]="{ width: '100%' }"
        formControlName="BusinessDepartment"
      ></p-dropdown>
    </div>
  </div>

  <div class="form-group">
    <label for="title" class="col-form-label">
      <span translate>NAME</span>
      <span class="required">*</span>
    </label>
    <div>
      <input
        type="text"
        #title
        class="label-input form-control"
        formControlName="SubDepartment"
        maxlength="100"
      />
      <span style="color: red" *ngIf="isNameExists">{{
        existsAlerttext
      }}</span>
    </div>
  </div>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
      <button
        type="button"
        class="btn btn-outline-primary"
        (click)="viewSubDept = !viewSubDept"
      >
        <span translate>VIEW</span>
      </button>
      <button
        type="button"
        class="btn btn-primary"
        [ngClass]="{ AddSubDepartmentloading: AddSubDepartmentloading }"
        [disabled]="!subDepartmentPopup.valid || AddSubDepartmentloading"
        (click)="saveSubDept()"
      >
        <span *ngIf="!AddSubDepartmentloading" translate>SAVE</span>
        <span *ngIf="AddSubDepartmentloading" translate>SAVE_PROCESS</span>
        <span *ngIf="AddSubDepartmentloading" class="loader-icon"></span>
      </button>
    </div>
    <div *ngIf="viewSubDept">
      <div class="table-view">
        <!-- Table starts -->
        <p-table
          #subDeptTable
          [value]="editSubDepartments"
          [rows]="10"
          [paginator]="true"
          [pageLinks]="3"
          [rowsPerPageOptions]="[5, 10, 20]"
          [responsive]="true"
        >
          <!-- Header -->
          <ng-template pTemplate="header">
            <tr>
              <th>{{ "SUB_DEPARTMENT_NAME" | translate }}</th>
              <th></th>
            </tr>
            <tr>
              <th>
                <input
                  pInputText
                  type="text"
                  (input)="
                    subDeptTable.filter(
                      $any($event.target)?.value,
                      'Name',
                      'contains'
                    )
                  "
                  placeholder=""
                  class="p-column-filter"
                />
              </th>
              <th></th>
            </tr>
          </ng-template>
          <!-- Body Content -->
          <ng-template pTemplate="body" let-categorySubDept>
            <tr>
              <td>
                <span>{{ categorySubDept.Name }}</span>
              </td>
              <td>
                <button
                  type="button"
                  title="{{ 'EDIT' | translate }}"
                  class="btn btn-icon"
                  (click)="editSubDept(categorySubDept)"
                >
                  <i class="icon ic-sm icon-pencil-tip"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-icon"
                  title="{{ 'DELETE' | translate }}"
                  (click)="deleteSubDept(categorySubDept)"
                >
                  <i class="icon ic-sm icon-trash"></i>
                </button>
              </td>
            </tr>
          </ng-template>

          <!-- Empty message -->
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td colspan="2">
                {{ "NO_RECORDS_FOUND" | translate }}
              </td>
            </tr>
          </ng-template>
        </p-table>
        <!-- Table ends -->
      </div>
    </div>
  </p-footer>
</form>
</p-dialog>
<p-dialog
  header="{{ 'ADD_TOPIC' | translate }}"
  [(visible)]="addTopic"
  [style]="{ width: '300px' }"
  [resizable]="false"
  [draggable]="false"
  (onHide)="onBeforeTopicDialogHide()"
>
  <div class="form-group" [formGroup]="topicForm">
    <label for="title" class="col-form-label">
      <span translate>NAME</span>
    </label>
    <div>
      <input
        type="text"
        formControlName="Name"
        #Title
        class="label-input form-control"
      />
      <span style="color: red" *ngIf="isTopicNameExists">{{
        existsTopicAlerttext
      }}</span>
    </div>
  </div>
  <p-footer>
    <div
      class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix"
      [formGroup]="deviationForm"
    >
      <button
        type="button"
        class="btn btn-outline-primary"
        (click)="viewTopic = !viewTopic"
      >
        <span translate>VIEW</span>
      </button>
      <button
        type="button"
        class="btn btn-primary"
        [disabled]="!topicForm.valid"
        (click)="saveTopic()"
      >
        <span translate>SAVE</span>
      </button>
    </div>
    <div *ngIf="viewTopic">
      <div class="table-view">
        <!-- Table starts -->
        <p-table
          #Types
          [value]="editTopics"
          [rows]="10"
          [paginator]="true"
          [pageLinks]="3"
          [rowsPerPageOptions]="[5, 10, 20]"
          [responsive]="true"
        >
          <!-- Header -->
          <ng-template pTemplate="header">
            <tr>
              <th>{{ "TYPE_NAME" | translate }}</th>
              <th></th>
            </tr>
            <tr>
              <th>
                <input
                  pInputText
                  type="text"
                  (input)="
                    Types.filter($any($event.target)?.value, 'Name', 'contains')
                  "
                  placeholder=""
                  class="p-column-filter"
                />
              </th>
              <th></th>
            </tr>
          </ng-template>
          <!-- Body Content -->
          <ng-template pTemplate="body" let-Type>
            <tr>
              <td>
                <span>{{ Type.Name }}</span>
              </td>
              <td class="col-button">
                <button
                  type="button"
                  class="btn btn-icon"
                  title="{{ 'EDIT' | translate }}"
                  (click)="editTopic(Type)"
                >
                  <i class="icon ic-sm icon-pencil-tip"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-icon"
                  title="{{ 'DELETE' | translate }}"
                  (click)="deleteTopic(Type.Id)"
                >
                  <i class="icon ic-sm icon-trash"></i>
                </button>
              </td>
            </tr>
          </ng-template>
          <!-- Empty message -->
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td colspan="2">
                {{ "NO_RECORDS_FOUND" | translate }}
              </td>
            </tr>
          </ng-template>
        </p-table>
        <!-- Table Ends -->
      </div>
    </div>
  </p-footer>
</p-dialog>
<p-confirmDialog
  header="{{ 'CONFIRMATION' | translate }}"
  icon="pi pi-question-circle"
  width="425"
  acceptLabel="{{ 'SWITCH_YES' | translate }}"
  rejectLabel="{{ 'SWITCH_NO' | translate }}"
></p-confirmDialog>
<toaster-component></toaster-component>
<div class="panel-loader" *ngIf="loading"></div>
