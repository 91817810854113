import { DashBoardOwnPageComponent } from './components/dashboard-ownpage.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { DashboardComponent } from './dashboard.component';
import { DashboardService } from './Services/dashboard.services';
import {
  DashboardActivityResolver,
  DashboardDeviationResolver,
  DashboardRiskAnalysisResolver,
  DashboardNewsResolver,
  DashboardMeasureResolver,
  DashboardBusinessResolver,
  DashboardLoggedUserResolver,
  DashboardAboutKubaResolver,
  DashboardAppLoggedUserResolver,
  DashboardCalendarActivityResolver,
  DashboardLinksListResolver,
  DashboardBusinessFunctionResolver,
  DashboardMessageResolver,
  AboutKubaIKResolver,
  DashboardInternalTvListResolver,
  DashboardAdminNewsResolver,
  DashboardDeviationCountForUsers,
  DashboardLinksPortalBusinessResolver,
  DashboardLinksForPortalBusinessResolver,
} from './Services/dashboard.resolver.service';
import { TooltipModule } from 'primeng/tooltip';
import { MobxAngularModule } from 'mobx-angular';
import { TreeModule } from '@circlon/angular-tree-component';
import { AppSharedModule } from './../shared/appShared.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FullCalendarModule } from '@fullcalendar/angular';
import { DashboardDeviationChartComponent } from './components/deviation-widget/deviation-widget.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { RiskAnalysisWidgetComponent } from './components/risk-analysis-business-widget/risk-analysis-business-widget.component';
import { RiskAnalysisAdminAndPortalWidgetComponent } from './components/risk-analysis-portal-widget/risk-analysis-portal-widget.component';
import { ActivitiesWidgetComponent } from './components/activities-business-widget/activities-business-widget.component';
import { ActivitiesPortalWidgetComponent } from './components/activities-portal-widget/activities-portal-widget.component';
@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    TreeModule,
    AppSharedModule,
    MobxAngularModule,
    RouterModule,
    BsDatepickerModule.forRoot(),
    /* Commented for KUBA 3.0 initial version check */
    FullCalendarModule,
    TranslateModule,
    TooltipModule,
    NgxChartsModule,
  ],
  exports: [DashboardComponent],
  declarations: [
    DashboardComponent,
    DashBoardOwnPageComponent,
    DashboardDeviationChartComponent,
    RiskAnalysisWidgetComponent,
    RiskAnalysisAdminAndPortalWidgetComponent,
    ActivitiesWidgetComponent,
    ActivitiesPortalWidgetComponent,
  ],
  providers: [
    DashboardActivityResolver,
    DashboardService,
    DashboardDeviationResolver,
    DashboardRiskAnalysisResolver,
    DashboardNewsResolver,
    DashboardMeasureResolver,
    DashboardBusinessResolver,
    DashboardLoggedUserResolver,
    DashboardAboutKubaResolver,
    DashboardAppLoggedUserResolver,
    DashboardCalendarActivityResolver,
    DashboardLinksListResolver,
    DashboardBusinessFunctionResolver,
    DashboardMessageResolver,
    AboutKubaIKResolver,
    DashboardInternalTvListResolver,
    DashboardAdminNewsResolver,
    DashboardDeviationCountForUsers,
    DashboardLinksPortalBusinessResolver,
    DashboardLinksForPortalBusinessResolver,
  ],
})
export class DashboardModule {}
