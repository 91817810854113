<form [formGroup]="newProjectForm">
  <div class="row">
    <div class="col-sm-6">
      <div class="form-group">
        <label for="Number">
          <span>{{ "PROJ_NUMBER" | translate }}</span>
          <span class="required">*</span>
        </label>
        <div>
          <input
            type="number"
            pInputText
            formControlName="Number"
            (keypress)="onNumberPress($event, 15)"
            id="Number"
            class="ex-full-width"
          />
        </div>
        <control-messages
          [control]="newProjectForm.controls['Number']"
        ></control-messages>
      </div>
      <div class="form-group">
        <label for="Title">
          <span translate>PROJ_NAME</span>
          <span class="required">*</span>
        </label>
        <div>
          <input
            type="text"
            pInputText
            formControlName="Title"
            id="Title"
            class="ex-full-width"
          />
        </div>
        <control-messages
          [control]="newProjectForm.controls['Title']"
        ></control-messages>
      </div>
      <div class="form-group-50_50">
        <div class="form-group">
          <label for="StartDate">
            <span translate>START_DATE</span>
            <span class="required">*</span>
          </label>
          <div>
            <my-date-picker
              name="startdate"
              [options]="startDateOptions"
              formControlName="StartDate"
              placeholder="{{ 'SELECT_START_DATE' | translate }}"
              required
              (dateChanged)="onStartDateChanged($event)"
            ></my-date-picker>
          </div>
          <control-messages
            [control]="newProjectForm.controls['StartDate']"
          ></control-messages>
        </div>
        <div class="form-group">
          <label for="ExpectedEndDate">
            <span translate>EXPECTED_END</span>
            <span class="required">*</span>
          </label>
          <div>
            <my-date-picker
              name="startdate"
              [options]="endDateOptions"
              formControlName="ExpectedEndDate"
              placeholder="{{ 'SELECT_END_DATE' | translate }}"
              required
              (dateChanged)="onEndDateChanged($event)"
            ></my-date-picker>
          </div>
          <control-messages
            [control]="newProjectForm.controls['ExpectedEndDate']"
          ></control-messages>
        </div>
      </div>

      <div class="form-group-50_50">
        <div class="form-group">
          <label for="OrderNumber">
            <span translate>ORDER_NUMBER</span>
          </label>
          <div>
            <input
              type="number"
              pInputText
              formControlName="OrderNumber"
              (keypress)="onNumberPress($event, 15)"
              id="OrderNumber"
              class="ex-full-width"
            />
          </div>
          <control-messages
            [control]="newProjectForm.controls['OrderNumber']"
          ></control-messages>
        </div>
        <div class="form-group">
          <label for="Ssbnumber">
            <span translate>SSB_NUMBER</span>
          </label>
          <div>
            <input
              type="number"
              pInputText
              formControlName="SSBNumber"
              (keypress)="onNumberPress($event, 15)"
              id="Ssbnumber"
              class="ex-full-width"
            />
          </div>
          <control-messages
            [control]="newProjectForm.controls['SSBNumber']"
          ></control-messages>
        </div>
      </div>
      <div class="form-group">
        <label for="ProjectCost">
          <span translate>PROJ_COST</span>
        </label>
        <div>
          <input
            type="number"
            pInputText
            id="ProjectCost"
            formControlName="ProjectCost"
            (keypress)="onKeyPress($event)"
            class="ex-full-width"
          />
        </div>
      </div>
      <div class="page-title">
        <span translate>ORGANIZED</span>
      </div>

      <div *ngIf="subContract == true">
        <div class="form-group">
          <label for="ContractorId">
            <span translate>SUB_CONTRACTOR</span>
          </label>
          <div class="input-btn-group">
            <p-multiSelect
              defaultLabel="{{ 'SELECT' | translate }}"
              [options]="SubContractorData"
              #subcontractorDropdown
              (onChange)="onSubContractorChanged($event)"
              formControlName="ContractorValues"
              [style]="{ width: '100%' }"
            >
              <ng-template let-subcontractor let-i="index" pTemplate="item">
                {{ i }}
                <span>{{ subcontractor.label }}</span>
              </ng-template>
            </p-multiSelect>
            <a
              *ngIf="editContact"
              class="btn btn-outline-primary"
              routerLink="create"
              [routerLink]="['./../../../contractor/create', 0]"
              routerLinkActive="active"
            >
              <i class="pi pi-plus" aria-hidden="true"></i>
            </a>
            <a
              *ngIf="!editContact"
              class="btn btn-outline-primary"
              routerLink="create"
              [routerLink]="['./../../contractor/create', 0]"
              routerLinkActive="active"
            >
              <i class="pi pi-plus" aria-hidden="true"></i>
            </a>
          </div>
        </div>
      </div>
      <div *ngIf="client == true">
        <div class="form-group">
          <label for="Client">
            <span translate>CLIENT</span>
          </label>
          <div class="input-btn-group">
            <p-dropdown
              [options]="Client"
              #clientDropdown
              (onChange)="onClientChanged($event)"
              formControlName="Client"
              [style]="{ width: '100%' }"
            >
            </p-dropdown>
            <a
              *ngIf="!editClient"
              class="btn btn-outline-primary"
              routerLink="create"
              [routerLink]="['./../../client/new']"
              routerLinkActive="active"
            >
              <i class="pi pi-plus" aria-hidden="true"></i>
            </a>
            <a
              *ngIf="editClient"
              class="btn btn-outline-primary"
              routerLink="create"
              [routerLink]="['./../../../client/new']"
              routerLinkActive="active"
            >
              <i class="pi pi-plus" aria-hidden="true"></i>
            </a>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="ContactPerson">
          <span translate>CONTACT_PERSON</span>
        </label>
        <div>
          <p-multiSelect
            defaultLabel="{{ 'SELECT' | translate }}"
            [options]="ContactPerson"
            formControlName="SelectedContactPerson"
            [style]="{ width: '100%' }"
          >
            <ng-template let-contactperson let-i="index" pTemplate="item">
              {{ i }}
              <span>{{ contactperson.label }}</span>
            </ng-template>
          </p-multiSelect>
        </div>
      </div>
      <div class="form-group">
        <label for="ProfessionalManager">
          <span translate>PROF_MANAGER</span>
        </label>
        <div>
          <p-multiSelect
            defaultLabel="{{ 'SELECT' | translate }}"
            [options]="ProfessionalManager"
            formControlName="selectedManager"
            [style]="{ width: '100%' }"
          >
            <ng-template let-professionalmanager let-i="index" pTemplate="item">
              {{ i }}
              <span>{{ professionalmanager.label }}</span>
            </ng-template>
          </p-multiSelect>
        </div>
      </div>
      <div class="form-group">
        <label for="ProjectManager">
          <span translate>PROJ_MANAGER</span>
        </label>
        <div>
          <p-multiSelect
            defaultLabel="{{ 'SELECT' | translate }}"
            [options]="ProjectManager"
            id="ProjectManager"
            formControlName="selectedProjectManager"
            [style]="{ width: '100%' }"
          >
            <ng-template let-projectmanager let-i="index" pTemplate="item">
              {{ i }}
              <span>{{ projectmanager.label }}</span>
            </ng-template>
          </p-multiSelect>
        </div>
      </div>

      <div class="form-group">
        <label for="ResponsibleforApplication" translate>RESPONSE_APPLN</label>
        <div>
          <p-multiSelect
            defaultLabel="{{ 'SELECT' | translate }}"
            [options]="ResponsibleforApplication"
            id="ResponsibleforApplication"
            formControlName="selectedResponsibleForApp"
            [style]="{ width: '100%' }"
          >
            <ng-template let-respapplication let-i="index" pTemplate="item">
              {{ i }}
              <span>{{ respapplication.label }}</span>
            </ng-template>
          </p-multiSelect>
        </div>
      </div>
      <div class="form-group">
        <label for="ResponsibleforProjectPlanning">
          <span translate>RESPONSE_PROJ_PLAN</span>
        </label>
        <div>
          <textarea
            type="text"
            pInputText
            id="ProjectPlan"
            formControlName="ProjectPlan"
            class="ex-full-width"
          >
          </textarea>
        </div>
      </div>
      <div class="form-group">
        <label for="ResponsibleforExecution">
          <span translate>RESPONSIBLE_FOR_EXECUTION</span>
        </label>
        <div>
          <p-multiSelect
            defaultLabel="{{ 'SELECT' | translate }}"
            [options]="ResponsibleforExecution"
            id="ResponsibleforExecution"
            formControlName="selectedResponsibleForExecution"
            [style]="{ width: '100%' }"
          >
            <ng-template let-respexecution let-i="index" pTemplate="item">
              {{ i }}
              <span>{{ respexecution.label }}</span>
            </ng-template>
          </p-multiSelect>
        </div>
      </div>
      <div class="form-group">
        <label for="ResponsibleforIndependentControl">
          <span translate>RESPONSE_INPNT_CTRL</span>
        </label>
        <div>
          <textarea
            type="text"
            pInputText
            id="IndependentControl"
            formControlName="IndependentControl"
            class="ex-full-width"
          >
          </textarea>
        </div>
      </div>
      <div class="form-group">
        <label for="Safetyrepresentatives">
          <span translate>SAFETY_REP</span>
        </label>
        <div>
          <p-multiSelect
            defaultLabel="{{ 'SELECT' | translate }}"
            [options]="Safetyrepresentatives"
            id="Safetyrepresentatives"
            formControlName="selectedSafetyRepresentative"
            [style]="{ width: '100%' }"
          >
            <ng-template let-safetyrep let-i="index" pTemplate="item">
              {{ i }}
              <span>{{ safetyrep.label }}</span>
            </ng-template>
          </p-multiSelect>
        </div>
      </div>
      <div class="form-group">
        <label for="Constructionmanager">
          <span translate>CONSTRUCTION_MGR</span>
        </label>
        <div>
          <p-multiSelect
            defaultLabel="{{ 'SELECT' | translate }}"
            [options]="Constructionmanager"
            id="Constructionmanager"
            formControlName="selectedConstructionManager"
            [style]="{ width: '100%' }"
          >
            <ng-template let-constructionmanager let-i="index" pTemplate="item">
              {{ i }}
              <span>{{ constructionmanager.label }}</span>
            </ng-template>
          </p-multiSelect>
        </div>
      </div>
      <div class="form-group">
        <label for="Coordinatorprojecting">
          <span translate>COORDINATOR_PROJECTING</span>
        </label>
        <div>
          <textarea
            type="text"
            pInputText
            id="CoordinatorProjecting"
            formControlName="CoordinatorProjecting"
            class="ex-full-width"
          >
          </textarea>
        </div>
      </div>
      <div class="form-group">
        <label for="Coordinatorexecution">
          <span translate>COORDINATOR_EXECUTION</span>
        </label>
        <div>
          <p-multiSelect
            defaultLabel="{{ 'SELECT' | translate }}"
            [options]="Coordinatorexecution"
            id="Coordinatorexecution"
            formControlName="selectedCordinatorExecution"
            [style]="{ width: '100%' }"
          >
            <ng-template let-coordinatorexec let-i="index" pTemplate="item">
              {{ i }}
              <span>{{ coordinatorexec.label }}</span>
            </ng-template>
          </p-multiSelect>
        </div>
      </div>
      <div class="form-group">
        <label for="BuilderSelect">
          <span translate>BUILDER</span>
        </label>
        <div>
          <textarea
            type="text"
            pInputText
            id="ProjectBuilder"
            formControlName="ProjectBuilder"
            class="ex-full-width"
          >
          </textarea>
        </div>
      </div>
      <div class="form-group">
        <label for="EmailDevaition">
          <span translate>EMAIL_NEWDEVIATION_LABEL</span>
        </label>
        <div *ngIf="bindSelectedUsers">
          <p-multiSelect
            defaultLabel="{{ 'SELECT' | translate }}"
            [dataKey]="'Id'"
            [options]="userSubscribers"
            formControlName="selectedEmailSubscriber"
            [style]="{ width: '100%' }"
          >
            <ng-template let-emailDeviation let-i="index" pTemplate="item">
              {{ i }}
              <span>{{ emailDeviation.label }}</span>
            </ng-template>
          </p-multiSelect>
        </div>
        <div *ngIf="bindAllUsers">
          <p-multiSelect
            defaultLabel="{{ 'SELECT' | translate }}"
            [dataKey]="'Id'"
            [options]="selectedSubscribers"
            formControlName="selectedEmailSMSSubscriber"
            [style]="{ width: '100%' }"
          >
            <ng-template let-emailDeviations let-i="index" pTemplate="item">
              {{ i }}
              <span>{{ emailDeviations.label }}</span>
            </ng-template>
          </p-multiSelect>
        </div>
      </div>
      <div class="form-group">
        <label for="SMSDevaition">
          <span translate>SMS_NEWDEVIATION_LABEL</span>
        </label>
        <div *ngIf="bindSelectedUsers">
          <p-multiSelect
            defaultLabel="{{ 'SELECT' | translate }}"
            dataKey="Id"
            [options]="userSubscribers"
            formControlName="selectedSMSSubscriber"
            [style]="{ width: '100%' }"
          >
            <ng-template let-smsDeviation let-i="index" pTemplate="item">
              {{ i }}
              <span>{{ smsDeviation.label }}</span>
            </ng-template>
          </p-multiSelect>
        </div>
        <div *ngIf="bindAllUsers">
          <p-multiSelect
            defaultLabel="{{ 'SELECT' | translate }}"
            dataKey="Id"
            [options]="selectedSubscribers"
            formControlName="smsSubscriber"
            [style]="{ width: '100%' }"
          >
            <ng-template let-smsDeviations let-i="index" pTemplate="item">
              {{ i }}
              <span>{{ smsDeviations.label }}</span>
            </ng-template>
          </p-multiSelect>
        </div>
      </div>
      <div class="form-group">
        <p-checkbox
          value="isShowDeviationChecked"
          label="{{ 'DO_NOT_DISPLAY_DEVIATION' | translate }}"
          formControlName="isShowDeviationChecked"
          binary="true"
        ></p-checkbox>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="form-group">
        <div class="page-title">
          <span translate>COPYFOLDERS_CONTENT</span>
        </div>
        <label for="ChooseProject" translate>CHOOSE_PROJECT</label>
        <div>
          <p-dropdown
            [options]="ChooseProject"
            [filter]="true"
            formControlName="ChooseProject"
            [style]="{ width: '500px' }"
            [readonly]="IsHidden"
          ></p-dropdown>
        </div>
      </div>
      <div class="form-group">
        <p-checkbox
          value="CopySja"
          label="{{ 'COPY_SJA' | translate }}"
          formControlName="CopySJA"
          binary="true"
          [readonly]="IsHidden"
        >
        </p-checkbox>
        <p-checkbox
          value="CopySJAForm"
          label="{{ 'Copy_SJA_Form' | translate }}"
          formControlName="CopySJAForm"
          binary="true"
          [readonly]="IsHidden"
        ></p-checkbox>
      </div>
      <div class="form-group">
        <div class="page-title">
          <span translate>STATUS</span>
        </div>
        <div>
          <p-radioButton
            name="group1"
            [formControl]="newProjectForm.controls['Status']"
            value="{{ option.id }}"
            *ngFor="let option of options"
            label="{{ option.name | translate }}"
            (checked)="(option.id === newProjectForm.controls['Status'].value)"
          ></p-radioButton>
        </div>
      </div>
      <div class="form-group">
        <label for="Description">
          <span translate>DESCRIPTION</span>
        </label>
        <div>
          <textarea
            pInputTextarea
            id="Description"
            formControlName="Description"
            class="ex-description"
          ></textarea>
        </div>
      </div>
      <div class="page-title">
        <span translate>AREA_OF_RESPONSIBILITY</span>
      </div>
      <div formArrayName="Responsibility">
        <div class="row">
          <div
            class="col-sm-4 mb-2"
            *ngFor="let responsibility of Responsibility; let i = index"
          >
            <span formGroupName="{{ i }}" class="checkbox">
              <p-checkbox
                value="{{ responsibility.Name | translate }}"
                label="{{ responsibility.Name | translate }}"
                formControlName="{{ responsibility.Id }}"
                binary="true"
              ></p-checkbox>
            </span>
          </div>
        </div>
      </div>
      <div class="upload-file-container">
        <label>
          <span translate>PROJECT_IMAGE</span>
        </label>
        <div class="form-group">
          <div class="upload-file-container">
            <div class="upload-actions">
              <input
                type="file"
                id="files"
                class="hidden"
                (change)="filesSelect($event)"
              />
              <label for="files" class="btn btn-outline-primary" translate
                >UPLOAD_IMAGE</label
              >
            </div>
            <div *ngIf="uploadStart">
              <span translate>IMAGE_IS_UPLOADING</span>
            </div>
            <div *ngIf="projectImage != null">
              <img
                src="{{ projectImage }}"
                alt=""
                width="200"
                class="thumbnail"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="page-title">
        <span translate>ASSIGN_ACCESS</span>
      </div>
      <div class="page-title">
        <!-- Table starts -->
        <p-table
          #dt
          [value]="projectUsers"
          dataKey="Id"
          scrollable="true"
          scrollHeight="300px"
        >
          <!-- Header -->
          <ng-template pTemplate="header">
            <tr>
              <th>
                <p-checkbox
                  [ngModel]="checkedAll"
                  (ngModelChange)="checkAll($event)"
                  [ngModelOptions]="{ standalone: true }"
                  binary="true"
                ></p-checkbox>
              </th>
              <th>{{ "NAME" | translate }}</th>
            </tr>
          </ng-template>
          <!-- Body Content -->
          <ng-template pTemplate="body" let-internal>
            <tr>
              <td>
                <p-checkbox
                  [(ngModel)]="internal.Id === userId || internal.status"
                  [disabled]="internal.Id === userId"
                  (ngModelChange)="checked(internal)"
                  [ngModelOptions]="{ standalone: true }"
                  binary="true"
                ></p-checkbox>
              </td>
              <td>
                <span>{{ internal.Name }}</span>
              </td>
            </tr>
          </ng-template>
          <!-- Empty message -->
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td colspan="2">
                {{ "NO_RECORDS_FOUND" | translate }}
              </td>
            </tr>
          </ng-template>
        </p-table>
        <!-- Table Ends -->
      </div>
      <div *ngIf="isSubContractorSelected">
        <div class="page-title">
          <span translate>ASSIGN_SUBCONTRACTOR</span>
        </div>
        <!-- Table starts -->
        <p-table
          #subContractor
          [value]="projectSubContractor"
          dataKey="ContractorContactId"
          [(selection)]="selectedContractor"
          scrollable="true"
          scrollHeight="300px"
        >
          <!-- Header -->
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 38px">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
              </th>
              <th>{{ "NAME" | translate }}</th>
            </tr>
            <tr>
              <th style="width: 38px"></th>
              <th>
                <input
                  pInputText
                  type="text"
                  (input)="
                    subContractor.filter(
                      $any($event.target)?.value,
                      'Name',
                      'contains'
                    )
                  "
                  placeholder=""
                  class="p-column-filter"
                />
              </th>
            </tr>
          </ng-template>
          <!-- Body Content -->
          <ng-template pTemplate="body" let-project>
            <tr>
              <td style="width: 38px">
                <p-tableCheckbox id="selectedOrders" [value]="project">
                </p-tableCheckbox>
              </td>
              <td>
                <span>{{ project.Name }}</span>
              </td>
            </tr>
          </ng-template>
          <!-- Empty message -->
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td colspan="2">
                {{ "NO_RECORDS_FOUND" | translate }}
              </td>
            </tr>
          </ng-template>
        </p-table>
        <!-- Table Ends -->
      </div>
      <div *ngIf="isClientSelected">
        <div class="page-title">
          <span translate>ASSIGN_CLIENT</span>
        </div>
        <!-- Table starts -->
        <p-table
          #client
          [value]="clientUsers"
          dataKey="ClientUserId"
          [(selection)]="selectedClient"
          scrollable="true"
          scrollHeight="300px"
        >
          <!-- Header -->
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 38px">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
              </th>
              <th>{{ "NAME" | translate }}</th>
            </tr>
            <tr>
              <th style="width: 38px"></th>
              <th>
                <input
                  pInputText
                  type="text"
                  (input)="
                    client.filter(
                      $any($event.target)?.value,
                      'Name',
                      'contains'
                    )
                  "
                  placeholder=""
                  class="p-column-filter"
                />
              </th>
            </tr>
          </ng-template>
          <!-- Body Content -->
          <ng-template pTemplate="body" let-project>
            <tr>
              <td style="width: 38px">
                <p-tableCheckbox id="selectedOrders" [value]="project">
                </p-tableCheckbox>
              </td>
              <td>
                <span>{{ project.Name }}</span>
              </td>
            </tr>
          </ng-template>
          <!-- Empty message -->
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td colspan="3">
                {{ "NO_RECORDS_FOUND" | translate }}
              </td>
            </tr>
          </ng-template>
        </p-table>
        <!-- Table Ends -->
      </div>
    </div>
  </div>
  <div class="action-btns-wrapper">
    <button class="btn btn-outline-secondary" (click)="gotoBack()">
      <span class="icon ic-xs icon-back"></span>
      <span translate>BACK_LIST</span>
    </button>
    <button
      *ngIf="isAdministrateProject"
      type="submit"
      class="btn btn-primary"
      (click)="saveChanges('NOTNEW')"
      [disabled]="!newProjectForm.valid || loading"
    >
      <span translate>SAVE_AND_BACK</span>
    </button>
    <button
      *ngIf="isHideProject"
      class="btn btn-primary"
      type="submit"
      (click)="saveChanges('NEWPROJECT')"
      [disabled]="!newProjectForm.valid || loading"
    >
      <span translate>SAVE_CREATE_NEW</span>
    </button>
  </div>
</form>
<toaster-component></toaster-component>
<p-progressBar
  mode="indeterminate"
  [style]="{ height: '6px' }"
  *ngIf="loading"
></p-progressBar>
