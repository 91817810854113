<div class="main-content">
  <!-- Search -->

  <div class="table-view">
    <!-- Table starts -->
    <p-table
      #dt
      [value]="fireDeviation"
      [rows]="10"
      [paginator]="true"
      [pageLinks]="3"
      [rowsPerPageOptions]="[5, 10, 20]"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{{ 'SUMMARY_TOTALRECORDS' | translate }}"
    >
      <!-- Caption -->
      <ng-template pTemplate="caption">
        <div class="ui-helper-clearfix">
          <div class="table-options">
            <div class="pull-right">
              <kuba-export
                [reportData]="dt.filteredValue || dt.value"
                [additionalData]="additionalData"
              ></kuba-export>
            </div>
          </div>
        </div>
      </ng-template>
      <!-- Header -->
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="Name">
            {{ "NAME_OF_THE_OBJECT" | translate }}
            <p-sortIcon field="Name"></p-sortIcon>
          </th>

          <th pSortableColumn="Email">
            {{ "CONTACT_EMAIL" | translate }}
            <p-sortIcon field="Email"></p-sortIcon>
          </th>
          <th [hidden]="isHidden">
            {{ "CONTACT_PHONE_NUMBER" | translate }}
          </th>
          <th [hidden]="isHidden" [width]="80" *ngIf="isAdministrateFDV">{{ "OPTIONS" | translate }}</th>
        </tr>
        <tr>
          <th>
            <input
              pInputText
              type="text"
              (input)="
                dt.filter($any($event.target)?.value, 'Name', 'contains')
              "
              placeholder=""
              class="p-column-filter"
            />
          </th>

          <th></th>
          <th [hidden]="isHidden"></th>
          <th [hidden]="isHidden" *ngIf="isAdministrateFDV"></th>
        </tr>
      </ng-template>
      <!-- Body Content -->
      <ng-template pTemplate="body" let-fdv>
        <tr>
          <td>
            <a [routerLink]="['./../../details', fdv.Id]">{{ fdv.Name }}</a>
          </td>

          <td>
            <span>{{ fdv.Email }}</span>
          </td>
          <td [hidden]="isHidden">
            <span>{{ fdv.Mobile }}</span>
          </td>
          <td class="col-button" [hidden]="isHidden" *ngIf="isAdministrateFDV">
            <a
              routerLink="create"
              [routerLink]="['./../../edit', fdv.Id]"
              class="btn btn-icon"
              title="{{ 'EDIT' | translate }}"
              routerLinkActive="active"
            >
              <i class="icon ic-sm icon-pencil-tip"></i>
            </a>
            <button
              type="button"
              class="btn btn-icon"
              title="{{ 'DELETE' | translate }}"
              (click)="deleteFdv(fdv['Id'])"
            >
              <i class="icon ic-sm icon-trash"></i>
            </button>
          </td>
        </tr>
      </ng-template>
      <!-- Empty message -->
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td colspan="9">
            {{ "NO_RECORDS_FOUND" | translate }}
          </td>
        </tr>
      </ng-template>
      <!-- Table ends -->
    </p-table>
  </div>
</div>

<toaster-component></toaster-component>

<p-confirmDialog
  header="{{ 'CONFIRMATION' | translate }}"
  icon="pi pi-question-circle"
  width="425"
  acceptLabel="{{ 'SWITCH_YES' | translate }}"
  rejectLabel="{{ 'SWITCH_NO' | translate }}"
></p-confirmDialog>
