import { Links } from './../../businesses/models/businessLink';
import { BusinessLinksService } from './../../businesses/services/businessLinks.service';
import { BusinessInternalTVService } from './../../businesses/services/businessInternalTV.serivce';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Resolve } from '@angular/router';
import {
  AboutKuba,
  Activity,
  DeviationCount,
  Riskanalysis,
} from './../models/dashboard';
import { DashboardService } from './dashboard.services';
import { Observable, of } from 'rxjs';
import { BaseServices } from '../../kuba.services';
import { News } from '../../news/models/news';

@Injectable()
export class DashboardActivityResolver implements Resolve<Activity[]> {
  constructor(private backend: DashboardService) {}
  resolve(): Observable<Activity[]> {
    if (BaseServices.roleId === '1') {
      return this.backend.getFutureAdminActivityByBusiness();
    } else {
      return this.backend.getFutureActivityByBusiness(BaseServices.BusinessId);
    }
  }
}
@Injectable()
export class DashboardDeviationResolver implements Resolve<DeviationCount> {
  constructor(private backend: DashboardService) {}
  resolve(): Observable<DeviationCount> {
    if (BaseServices.roleId === '1') {
      return this.backend.getAdminDeviation(BaseServices.BusinessId);
    } else {
      return this.backend.getDeviationByBusiness(
        BaseServices.BusinessId,
        BaseServices.roleId
      );
    }
  }
}
@Injectable()
export class DashboardDeviationCountForUsers implements Resolve<any[]> {
  constructor(private backend: DashboardService) {}
  resolve(): Observable<any[]> {
    return this.backend.getDeviationByUser(BaseServices.roleId);
  }
}

@Injectable()
export class DashboardRiskAnalysisResolver implements Resolve<Riskanalysis[]> {
  constructor(private backend: DashboardService) {}
  resolve(): Observable<Riskanalysis[]> {
    return this.backend.getRiskAnalysis(BaseServices.BusinessId);
  }
}
@Injectable()
export class DashboardNewsResolver implements Resolve<News[]> {
  constructor(private backend: DashboardService) {}
  resolve(): Observable<News[]> {
    if (BaseServices.roleId === '1' || BaseServices.roleId === '2') {
      return null!;
    }
    return this.backend.getInternalNews();
  }
}
@Injectable()
export class DashboardAdminNewsResolver implements Resolve<News[]> {
  constructor(private backend: DashboardService) {}
  resolve(): Observable<News[]> {
    return this.backend.getAdminNews();
  }
}
@Injectable()
export class DashboardMeasureResolver implements Resolve<any[]> {
  constructor(private backend: DashboardService) {}
  resolve(): Observable<any[]> {
    return this.backend.getMeasure(BaseServices.BusinessId);
  }
}
@Injectable()
export class DashboardBusinessResolver implements Resolve<any[]> {
  constructor(private backend: DashboardService) {}
  resolve(): Observable<any[]> {
    return this.backend.getBusiness(BaseServices.BusinessId);
  }
}
@Injectable()
export class DashboardBusinessFunctionResolver implements Resolve<any[]> {
  constructor(private backend: DashboardService) {}
  resolve(): Observable<any[]> {
    return this.backend.getBusinessFunction(BaseServices.BusinessId);
  }
}
@Injectable()
export class DashboardLoggedUserResolver implements Resolve<any[]> {
  constructor(private backend: DashboardService) {}
  resolve(): Observable<any[]> {
    return this.backend.getUserHits('Web');
  }
}
@Injectable()
export class DashboardAppLoggedUserResolver implements Resolve<any[]> {
  constructor(private backend: DashboardService) {}
  resolve(): Observable<any[]> {
    return this.backend.getUserHits('App');
  }
}
@Injectable()
export class DashboardAboutKubaResolver implements Resolve<AboutKuba[]> {
  constructor(private backend: DashboardService) {}
  resolve(): Observable<AboutKuba[]> {
    return this.backend.getAboutKuba(BaseServices.BusinessId);
  }
}
@Injectable()
export class DashboardCalendarActivityResolver implements Resolve<any[]> {
  constructor(private backend: DashboardService) {}
  resolve(): Observable<any[]> {
    return this.backend.getcalendarActivity(BaseServices.BusinessId);
  }
}
@Injectable()
export class DashboardLinksListResolver implements Resolve<Links[]> {
  constructor(private backend: DashboardService) {}
  resolve(): Observable<Links[]> {
    if (BaseServices.roleId === '2') {
      return this.backend.getLinksByAppAndBusiness(0);
    } else {
      return this.backend.getLinksByAppAndBusiness(BaseServices.BusinessId);
    }
  }
}
@Injectable()
export class DashboardMessageResolver implements Resolve<any[]> {
  constructor(private backend: DashboardService) {}
  resolve(): Observable<any[]> {
    return this.backend.getUserMessage(BaseServices.UserId);
  }
}

@Injectable()
export class AboutKubaIKResolver implements Resolve<AboutKuba> {
  constructor(private backend: DashboardService) {}
  resolve(): Observable<AboutKuba> {
    return this.backend.getAboutKubaIk(BaseServices.BusinessId);
  }
}
@Injectable()
export class DashboardInternalTvListResolver implements Resolve<any[]> {
  constructor(private backend: BusinessInternalTVService) {}
  resolve() {
    return this.backend.getVideo(BaseServices.BusinessId);
  }
}
@Injectable()
export class DashboardLinksPortalBusinessResolver implements Resolve<any[]> {
  constructor(private backend: DashboardService) {}
  resolve(): Observable<any> {
    // if (BaseServices.roleId === '2') {
    //   return this.backend.getLinksByAppAndBusiness(0);
    // } else {
    return this.backend.getIsPortalBusiness(BaseServices.BusinessId);
    // }
  }
}
@Injectable()
export class DashboardLinksForPortalBusinessResolver
  implements Resolve<Links[]>
{
  constructor(private backend: DashboardService) {}
  resolve(): Observable<Links[]> {
    return this.backend.getPortalLinks(BaseServices.BusinessId);
  }
}
