<div class="tab-content">
    <div class="table-view">
        <!-- Table starts -->
        <p-table  [value]="ellist" #elTable [rows]="10" dataKey="ContractNumber" [paginator]="true"
        [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}"
            [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]"  exportFilename="AllELList" sortField="ContractNumber" sortOrder="1">
            <!-- Caption -->
            <ng-template pTemplate="caption">
                <div class="table-options">
                    <div class="pull-right">
                        <kuba-export [reportData]="elTable.filteredValue || elTable.value" [additionalData]="additionalData"></kuba-export>
                    </div>
                </div>
            </ng-template>
            <!-- Header -->
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="ContractNumber">{{'CONTRACT_NUMBER' | translate}} <p-sortIcon field="ContractNumber"></p-sortIcon></th>
                    <th pSortableColumn="ContractName">{{'CONTRACT_NAME'| translate}} <p-sortIcon field="ContractName"></p-sortIcon></th>
                    <th pSortableColumn="Client">{{'CLIENT' | translate }} <p-sortIcon field="Client"></p-sortIcon></th>
                    <th>{{'STATUS'| translate}}</th>
                    <th>{{'IMAGE'| translate}}</th>
                    <th pSortableColumn="CreatedName">{{'CREATED_BY'| translate}} <p-sortIcon field="CreatedName"></p-sortIcon></th>
                    <th pSortableColumn="ModifierName">{{'EDITED_BY'| translate}} <p-sortIcon field="ModifierName"></p-sortIcon></th>
                    <th>{{'OPTIONS'| translate}}</th>
                </tr>
                <tr>
                    <th>
                        <input pInputText type="text"
                            (input)="elTable.filter($event.target.value, 'ContractNumber', 'contains')" placeholder=""
                            class="p-column-filter">
                    </th>
                    <th>
                        <input pInputText type="text"
                            (input)="elTable.filter($event.target.value, 'ContractName', 'contains')" placeholder=""
                            class="p-column-filter" >
                    </th>
                    <th>
                        <input pInputText type="text"
                            (input)="elTable.filter($event.target.value, 'Client', 'contains')" placeholder=""
                            class="p-column-filter" >
                    </th>
                    <th>
                        <p-dropdown [options]="statuses" (onChange)="onStatusChange($event, ed)" #ed
                            styleClass="p-column-filter" placeholder="Select" [filterBy]="null" [showClear]="true">
                            <ng-template let-status pTemplate="item"> {{ status.label | translate }}
                            </ng-template>
                        </p-dropdown>
                    </th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-kundeEL>
                <tr>
                    <td>
                        <span>{{kundeEL.ContractNumber}}</span>
                    </td>
                    <td>
                        <a [routerLink]="['./../../details',kundeEL.Id]">{{kundeEL.ContractName}}</a>
                    </td>
                    <td>
                        <span>{{kundeEL.Client}}</span>
                    </td>
                    <td>
                        <span>{{kundeEL.StatusText | translate}}</span>
                    </td>
                    <td *ngIf="kundeEL.ContractImage != null">
                        <img src="{{kundeEL.ContractImage}}" alt="" width="100" height="100" class="thumbnail">
                    </td>
                    <td *ngIf="kundeEL.ContractImage == null">
                    </td>
                    <td>
                        <span>{{kundeEL.CreatedName}}</span>
                    </td>
                    <td>
                        <span>{{kundeEL.ModifierName}}</span>
                    </td>
                    <td class="col-button">
                        <button type="button" *ngIf="hideEditIcon" class="btn btn-icon" [routerLink]="['./../../edit',kundeEL.Id]" title="{{'EDIT' | translate}}">
                            <i class="icon ic-sm icon-pencil-tip"></i>
                        </button>
                        <button type="button" class="btn btn-icon" (click)="deleteEL(kundeEL['Id'])" title="{{'DELETE' | translate}}">
                            <i class="icon ic-sm icon-trash"></i>
                        </button>
                    </td>
                </tr>
            </ng-template>
            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td colspan="8">
                        {{'NO_RECORDS_FOUND'|translate}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <!-- Table Ends-->
    </div>
</div>
<toaster-component></toaster-component>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425" acceptLabel="{{'SWITCH_YES'|translate}}"
    rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>