import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelperV2Service {

  private mimeTypeMap: { [key: string]: string } = {
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
    'vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
    'application/msword': 'doc',
    'doc': 'doc',
    'application/pdf': 'pdf',
    'pdf': 'pdf',
    'application/vnd.ms-powerpoint': 'ppt',
    'vnd.ms-powerpoint': 'ppt',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
    'vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
    'image/jpeg': 'jpg',
    'jpg': 'jpg',
    'image/png': 'png',
    'png': 'png',
    'text/csv': 'csv',
    'csv': 'csv',
    'text/plain': 'txt',
    'txt': 'txt',
    'plain': 'txt',
    'application/vnd.ms-excel': 'xls',
    'vnd.ms-excel': 'xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
    'vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
  };

  getFileExtension(mimeType: string): string | null {
    const result: string | null =  this.mimeTypeMap[mimeType.toLowerCase()] || null;
    return result;
  }
}
