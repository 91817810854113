<div class="tab-content">
  <div class="page-title">
    <span translate>EMPLOYEE_SICKNESS_LIST</span>

    <span class="page-actions pull-right" *ngIf="!isPortal"> </span>
    <span class="page-actions pull-right" *ngIf="isPortal"> </span>
  </div>
  <div class="table-view">
    <!-- Table starts -->
    <p-table
      #employeesickness
      [value]="employees"
      [rows]="10"
      [paginator]="true"
      [pageLinks]="3"
      [rowsPerPageOptions]="[5, 10, 20]"
      [responsive]="true"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{{ 'SUMMARY_TOTALRECORDS' | translate }}"
    >
      <!-- Caption -->
      <ng-template pTemplate="caption">
        <div class="ui-helper-clearfix">
          <div class="filter-controls-wrapper" style="display: none">
            <form [formGroup]="sicknessForm">
              <div class="row">
                <div class="col-sm-3">
                  <div class="form-group">
                    <label for="department">
                      <span translate>FROM_DATE</span>
                    </label>
                    <div>
                      <my-date-picker
                        name="startdate"
                        [options]="dateOption"
                        (dateChanged)="onStartDateChanged($event)"
                        formControlName="FromDate"
                        placeholder="{{ 'ENTER_THE_START_DATE' | translate }}"
                        [(ngModel)]="fromDateFilter"
                      ></my-date-picker>
                    </div>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group">
                    <label for="department">
                      <span translate>END_DATE</span>
                    </label>
                    <div>
                      <my-date-picker
                        name="enddate"
                        [options]="dateOption"
                        (dateChanged)="onEndDateChanged($event)"
                        formControlName="EndDate"
                        placeholder="{{ 'ENTER_STOP_DATE' | translate }}"
                        [(ngModel)]="endDateFilter"
                      ></my-date-picker>
                    </div>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group">
                    <label for="department">
                      <span translate>REASON</span>
                    </label>
                    <div>
                      <p-dropdown
                        [options]="reasonList"
                        [style]="{ width: '100%' }"
                        (onChange)="onReasonChanged($event, reasonDropdown)"
                        #reasonDropdown
                        [(ngModel)]="reasonFilter"
                        [ngModelOptions]="{ standalone: true }"
                      >
                      </p-dropdown>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12">
                  <button
                    class="btn btn-outline-primary"
                    type="submit"
                    (click)="refreshTable()"
                  >
                    <span class="icon ic-sm icon-reset"></span>
                    <span translate>REFRESH</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div class="table-options">
            <div class="pull-left"></div>
            <div class="pull-right">
              <div class="action-btns-group">
                <button class="btn p-3 filter-toggle">
                  <span class="icon ic-sm icon-filter"></span>
                  <span translate>FILTER</span>
                </button>
              </div>
              <div class="action-btns-group">
                <div>
                  <kuba-export
                    [reportData]="
                      employeesickness.filteredValue || employeesickness.value
                    "
                    [additionalData]="additionalData"
                  ></kuba-export>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <!-- Header -->
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="EmployeeName">
            {{ "EMPLOYEE_NAME" | translate }}
          </th>
          <th *ngIf="!isPortal" pSortableColumn="BusinessDepartment">
            {{ "DEPARTMENT_NAME" | translate }}
          </th>
          <th pSortableColumn="BusinessName" [hidden]="!isPortal">
            {{ "COMPANY_NAME" | translate }}
            <p-sortIcon field="BusinessName"></p-sortIcon>
          </th>
          <th (click)="sort('StartDate')">
            {{ "FROM_DATE" | translate }}
            <p-sortIcon field="StartDate"></p-sortIcon>
          </th>
          <th (click)="sort('EndDate')">
            {{ "END_DATE" | translate }}
            <p-sortIcon field="EndDate"></p-sortIcon>
          </th>
          <th pSortableColumn="TotalAbsenceHours">
            {{ "HOURS" | translate }}
            <p-sortIcon field="TotalAbsenceHours"></p-sortIcon>
          </th>
          <th pSortableColumn="NoOfDays">
            {{ "NO_OF_DAYS" | translate }}
            <p-sortIcon field="NoOfDays"></p-sortIcon>
          </th>
          <th pSortableColumn="NofDaysWithPercentageAbsence" width="140px">
            {{ "PERCENT_ABSENCE" | translate }}
            <p-sortIcon field="NofDaysWithPercentageAbsence"></p-sortIcon>
          </th>
          <th>{{ "STATUS" | translate }}</th>
          <th>{{ "REASON" | translate }}</th>
          <th pSortableColumn="ApprovalPersonNameList">
            {{ "APPROVAL_PERSON" | translate }}
            <p-sortIcon field="ApprovalPersonNameList"></p-sortIcon>
          </th>
          <th [hidden]="isPortal" pSortableColumn="AprDate" width="120px">
            {{ "DATE_APPROVAL" | translate }}
            <p-sortIcon field="AprDate"></p-sortIcon>
          </th>
          <th width="95px"><span translate>DESCRIPTION</span></th>
          <th width="84px">{{ "OPTIONS" | translate }}</th>
        </tr>
        <tr>
          <th>
            <p-dropdown
              [options]="empName"
              (onChange)="onNameChanged($event, employeeNameDropdown)"
              #employeeNameDropdown
              styleClass="p-column-filter"
              placeholder="Select"
              [showClear]="true"
              appendTo="body"
            >
              <ng-template let-empName pTemplate="item">
                {{ empName.label | translate }}
              </ng-template>
            </p-dropdown>
          </th>
          <th *ngIf="!isPortal">
            <input
              pInputText
              type="text"
              (input)="onDepartmentFilterChanged($event)"
              placeholder=""
              class="p-column-filter"
            />
          </th>
          <th [hidden]="!isPortal">
            <input
              pInputText
              type="text"
              (input)="onBusinessNameFilterChanged($event)"
              placeholder=""
              class="p-column-filter"
            />
          </th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th>
            <p-dropdown
              width="118px"
              [options]="statuses"
              [(ngModel)]="statusFilter"
              (onChange)="onStatusChanged($event, ed)"
              #ed
              styleClass="p-column-filter"
              placeholder="Select"
              [showClear]="true"
              appendTo="body"
            >
              <ng-template let-status pTemplate="item">
                {{ status.label | translate }}
              </ng-template>
            </p-dropdown>
          </th>
          <th>
            <p-dropdown
              [options]="reasonList"
              (onChange)="onReasonChanged($event, employeeNamesDropdown)"
              #employeeNamesDropdown
              placeholder="Select"
              [filterBy]="null"
              appendTo="body"
              [showClear]="true"
            >
              <ng-template let-reason pTemplate="item">
                {{ reason.label | translate }}
              </ng-template>
            </p-dropdown>
          </th>
          <th>
            <input
              pInputText
              type="text"
              (input)="onApprovalPersonFilterChanged($event)"
              placeholder=""
              class="p-column-filter"
            />
          </th>
          <th [hidden]="isPortal"></th>
          <th></th>
          <th></th>
        </tr>
      </ng-template>
      <!-- Body Content -->
      <ng-template pTemplate="body" let-sickness>
        <tr>
          <td>
            <span
              class="text-overflow"
              pTooltip="{{ sickness.EmployeeName }}"
              >{{ sickness.EmployeeName }}</span
            >
          </td>
          <td *ngIf="!isPortal">
            <span
              class="text-overflow"
              pTooltip="{{ sickness.BusinessDepartmentName }}"
              >{{ sickness.BusinessDepartmentName }}</span
            >
          </td>
          <td [hidden]="!isPortal">
            <span>{{ sickness.BusinessName }}</span>
          </td>
          <td>
            <span>{{ sickness.StartDate | date : "dd/MM/yyyy" }}</span>
          </td>
          <td>
            <span>{{ sickness.EndDate | date : "dd/MM/yyyy" }}</span>
          </td>
          <td>
            <span>{{ sickness.TotalHours }}</span>
          </td>
          <td>
            <span>{{ sickness.NoOfDays }}</span>
          </td>
          <td>
            <span>{{ sickness.NofDaysWithPercentageAbsence }}</span>
          </td>
          <td>
            <span>{{ sickness.StatusText.toUpperCase() | translate }}</span>
          </td>
          <td>
            <span
              pTooltip="{{ sickness.Reason | sicknessReason }}"
              tooltipPosition="bottom"
              class="text-overflow"
              >{{ sickness.Reason | sicknessReason }}</span
            >
          </td>
          <td>
            <span
              class="text-overflow"
              pTooltip="{{ sickness.ApprovalPersonNameList.join(', ') }}"
              >{{ sickness.ApprovalPersonNameList.join(", ") }}</span
            >
          </td>
          <td [hidden]="isPortal">
            <span>{{ sickness.AprDate }}</span>
          </td>

          <td class="col-button">
            <span
              *ngIf="sickness.Description != ''"
              pTooltip="{{ sickness.Description }}"
              tooltipPosition="top"
              class="btn btn-icon"
            >
              <i class="pi pi-comment" aria-hidden="true"></i>
            </span>
          </td>
          <td class="col-button">
            <button
              type="button"
              [routerLink]="['./../editSickness', sickness.Id]"
              class="btn btn-icon"
              title="{{ 'EDIT' | translate }}"
            >
              <i class="icon ic-sm icon-pencil-tip"></i>
            </button>
          </td>
        </tr>
      </ng-template>
      <!-- Footer -->
      <ng-template pTemplate="summary">
        <div class="footer-data">
          <div class="text-left col-md-2" *ngIf="!isPortal">
            <strong translate>TOTAL_DAYS</strong>
          </div>
          <div class="text-left col-md-3" *ngIf="!isPortal">
            <strong translate>OPEN</strong> : {{ OpenNoOfDay }}
          </div>
          <div class="text-left col-md-3" *ngIf="!isPortal">
            <strong translate>APPROVED</strong> : {{ ApprovedNoOfDay }}
          </div>
          <div class="text-left col-md-2" *ngIf="!isPortal">
            <strong translate>REJECTED</strong> : {{ RejectedNoOfDay }}
          </div>
        </div>
        <div class="footer-data">
          <div class="text-left col-md-2" *ngIf="!isPortal">
            <strong translate>TOTAL_HOURS</strong>
          </div>
          <div class="text-left col-md-3" *ngIf="!isPortal">
            <strong translate>OPEN</strong> : {{ TotalOpenHours }}
          </div>
          <div class="text-left col-md-3" *ngIf="!isPortal">
            <strong translate>APPROVED</strong> : {{ TotalApprovedHours }}
          </div>
          <div class="text-left col-md-2" *ngIf="!isPortal">
            <strong translate>REJECTED</strong> : {{ TotalRejectedHours }}
          </div>
        </div>
      </ng-template>
      <!-- Empty message -->
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td colspan="12">
            {{ "NO_RECORDS_FOUND" | translate }}
          </td>
        </tr>
      </ng-template>
    </p-table>
    <!-- Table ends -->
  </div>
</div>
<toaster-component></toaster-component>
<p-confirmDialog
  header="{{ 'CONFIRMATION' | translate }}"
  icon="pi pi-question-circle"
  width="425"
  acceptLabel="{{ 'SWITCH_YES' | translate }}"
  rejectLabel="{{ 'SWITCH_NO' | translate }}"
></p-confirmDialog>
