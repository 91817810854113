import { Route } from '@angular/router';
import { FeatureDocumentRoutes } from 'src/app/kuba/feature-documents/feature-document.routing';
import { VehicleViewComponent } from 'src/app/kuba/vehicle/components/vehicle-view.component';
import { AuthGuard } from '../../_guards/index';
import {
  EmployeeContactUserResolver,
  EmployeeContactUserSubscriptionResolver,
} from '../users/services/user.resolver.service';
import { deviationRoutes } from './../deviation/deviation.routing';
import { DepartmentDropDownResolver } from './../employees/services/employee.resolver.service';
import { taskmanagerRoutes } from './../task-manager/task-manager.routing';
import { timetableRoutes } from './../time-table/timetable.routing';
import { vehicleChecklistRoutes } from './../vehicle-checklist/vehicleChecklist.routing';
import { vehicleConsumptionRoutes } from './../vehicle-consumption/vehicle-consumption.routing';
import { vehicleControlRoutes } from './../vehicle-control/vehicle-control.routing';
import { VehicleEditComponent } from './components/vehicle-edit.component';
import { VehicleHeaderListComponent } from './components/vehicle-header.component';
import { VehicleFormComponent } from './components/vehicleForm.component';
import { VehicleListComponent } from './components/vehicleList.component';
import {
  TypeOfVehicleVehicleResolver,
  VehicleBrandResolver,
  VehicleEditResolver,
  VehicleFeaturesResolver,
  VehicleListResolver,
  VehicleListResolverByUserResolver,
  VehicleModelResolver,
  VehicleSizeResolver,
} from './services/vehicle.resolver.service';
import { VehicleComponent } from './vehicle.component';
export const vehicleRoutes: Route[] = [
  {
    path: 'vehicle',
    component: VehicleComponent,
    children: [
      {
        path: 'list',
        component: VehicleHeaderListComponent,
        children: [
          {
            path: 'own',
            component: VehicleListComponent,
            resolve: {
              list: VehicleListResolverByUserResolver,
            },
          },
          {
            path: 'all',
            component: VehicleListComponent,
            resolve: {
              list: VehicleListResolver,
            },
          },
        ],
      },
      {
        path: 'new',
        component: VehicleFormComponent,
        resolve: {
          department: DepartmentDropDownResolver,
          vehiclesize: VehicleSizeResolver,
          brand: VehicleBrandResolver,
          typeofvehicle: TypeOfVehicleVehicleResolver,
          model: VehicleModelResolver,
          contact: EmployeeContactUserResolver,
          users_subscription: EmployeeContactUserSubscriptionResolver,
        },
      },
      {
        path: 'edit/:id',
        component: VehicleEditComponent,
        resolve: {
          details: VehicleEditResolver,
          department: DepartmentDropDownResolver,
          vehiclesize: VehicleSizeResolver,
          contact: EmployeeContactUserResolver,
          users_subscription: EmployeeContactUserSubscriptionResolver,
        },
      },
      {
        path: 'details/:id',
        component: VehicleViewComponent,
        resolve: {
          vehicledata: VehicleEditResolver,
          additionalFeatures: VehicleFeaturesResolver,
        },
        data: { parent: 'VEHICLE' },
        canActivate: [AuthGuard],
        children: [
          ...FeatureDocumentRoutes,
          ...deviationRoutes,
          ...timetableRoutes,
          ...taskmanagerRoutes,
          ...vehicleConsumptionRoutes,
          ...vehicleControlRoutes,
          ...vehicleChecklistRoutes,
        ],
      },
    ],
  },
];
