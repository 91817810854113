
export class EmployeeSicknessAbscence {
  Id: number;
  BusinessId: number;
  EmployeeId: number;
  StartDate: string;
  EndDate: string;
  StartDateForFilter: string;
  EndDateForFilter: string;
  NoOfDays: number;
  ApprovalPerson: string;
  ApprovalPersonList:any[];
  ApprovalPersonName: string;
  ApprovalPersonMail: string;
  ApprovalPersonNameList:string[];
  ApprovalPersonMailList: string[] = [];
  Status: string;
  StatusText: string;
  Description: string;
  PercentageAbsence: string;
  CreatedBy: string;
  ModifiedBy: string;
  CreatedOn: string;
  UserView: UserView;
  EmployeeName: string;
  DateOfApproval: Date;
  StopAbsencePayment: string;
  LanguageId: number;
  SicknessAbsenceBusinessAbsenceCodeDto: SicknessAbsenceBusinessAbsenceCodeDto[];
  SicknessAbsenceDocumentDto: SicknessAbsenceDocumentDto[];
  IsShowWeekend: string;
  ChildsAge: number;
  OtherReason : string;
  Hours: number;
  TotalHours: number;
  TotalAbsenceHours: string;
  BusinessDepartmentId: number;

}
export class UserView {
  Id: number;
  Name: string;
}

export class SicknessAbsenceBusinessAbsenceCodeDto {
  EmployeeSicknessAbsenceId: number;
  BusinessAbsenceCodeId: string
}

export class SicknessAbsenceFollowUp {
  Deadline: string;
  EmployeeSicknessAbsenceId: number;
  ResponsibleforExecution: number;
  ResponsibleforFollowup: number;
  FollowUpTypeId: number;
}

export class SicknessAbsenceDocumentDto {
  EmployeeId: number;
  SicknessAbsenceId: number;
  FilePath: string;
  FileTypeId: string;
  IsPrivateDocument: Boolean;
  Status: string;
  OriginalFileName: string;
  MimeType: string;
  Size: string;
  Path: string
}

export class SicknessAbsenceCode {
  Code: string;
  Description: string;
}
export class Sickness {
  SicknessAbsenceFollowUp: SicknessAbsenceFollowUp[];
  SicknessAbsenceDocumentDto: SicknessAbsenceDocumentDto[];
}