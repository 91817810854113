import { ActivityAttachment } from './activity-list';
export class Tasklist {
  Id: number;
  ActivityAttachment: ActivityAttachment[];
  BusinessDepartmentId: number;
  Title: string;
  Description: string;
  EndDate: Date;
  Status: string;
  ExecutionUserId: number;
  ExecutionUserIdList: number[];
  FollowupUserId: number;
  FollowupUserIdList: number[];
  RepetitionId: number;
  ActivityScheduleId: number;
  ChecklistId: number;
  LinkUrl: string;
  CompletedDate: string;
  BusinessId: number;
  CreatedBy: number;
  CreatedOn: string;
  ModifiedBy: number;
  ModifiedOn: string;
  ImageUrl: string;
  Deadline: string;
  StatusIcon: string;
  RecurrDays: number[];
}
export class Repetition {
  Id: number;
  TypeName: string;
  Value: string;
}
export class ActivityDelete {
  AdditionalFeatureId: number;
  AdditionalFeatureKey: number;
  FeatureId: number;
  FeatureKey: number;
  isMappedActivity: boolean;
}
