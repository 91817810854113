import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IMyDateModel, IMyDpOptions } from 'mydatepicker';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { Observable, Subscription } from 'rxjs';
import 'rxjs/add/operator/toPromise';
import { map, startWith } from 'rxjs/operators';
import 'rxjs/Rx';
import { FeatureKey } from 'src/app/_models';
import { Timetable } from '../../models/timetable';
import { TimetableServices } from '../../services/timetable.service';
import { ToasterComponent } from './../../../../_directives/toaster.component';
import { HelperService } from './../../../../_services/helper.service';
import { BaseServices } from './../../../kuba.services';
import { ProjectServices } from './../../../projects/services/project.service';

@Component({
  selector: 'timetablelist',
  templateUrl: 'timetable-list.component.html',
})
export class TimetableListComponent implements OnInit {
  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  @ViewChild('timetableTable', { static: false }) timetableTable: Table;
  pdf: any;
  EmployeeName: any;
  projectName: string;
  employees: any[];
  startDate: any;
  endDate: any;
  Year: any;
  Month: any;
  showTypeofWork = false;
  totalHoursSpent: any;
  months: any;
  timetables: Timetable[] | any;
  filteredTimeTable: Observable<Timetable[]>;
  TimetableForm: FormGroup;
  bTimetable: any = {};
  bodyString: any;
  isHidden = false;
  val: any;
  statuses: SelectItem[];
  sources: SelectItem[];
  showNewTimeTable = false;
  showEditTimeTable = false;
  years: any;
  articles: Array<any>;
  parentKey: any;
  parentId: any;
  Timetables: any;
  projectFields: boolean;
  fdvFields: boolean;
  isNotGuestLogin = true;
  isNotGuestAndUserLogin = true;
  additionalData: any;
  isFoodsafetyOrProject = false;
  isSubContractorAndContacts = false;
  isNotSubContractorAndContacts = false;
  name: string;
  filterForm: FormGroup;
  private subscriptions: Subscription[] = [];

  /**
   * getter for MyDatePicker options
   */
  private get datePickerOptionsBase(): IMyDpOptions {
    return {
      dateFormat: 'dd/mm/yyyy',
      editableDateField: false,
      openSelectorOnInputClick: true,
      firstDayOfWeek: 'su',
      satHighlight: true,
      height: '26px',
      selectionTxtFontSize: '14px',
      todayBtnTxt: this.translate.instant('TODAY'),
      dayLabels: {
        su: this.translate.instant('SUN'),
        mo: this.translate.instant('MON'),
        tu: this.translate.instant('TUE'),
        we: this.translate.instant('WED'),
        th: this.translate.instant('THU'),
        fr: this.translate.instant('FRI'),
        sa: this.translate.instant('SAT'),
      },
      monthLabels: {
        1: this.translate.instant('JANUARY'),
        2: this.translate.instant('FEBRUARY'),
        3: this.translate.instant('MARCH'),
        4: this.translate.instant('APRIL'),
        5: this.translate.instant('MAY'),
        6: this.translate.instant('JUNE'),
        7: this.translate.instant('JULY'),
        8: this.translate.instant('AUGUST'),
        9: this.translate.instant('SEPTEMBER'),
        10: this.translate.instant('OCTOBER'),
        11: this.translate.instant('NOVEMBER'),
        12: this.translate.instant('DECEMBER'),
      },
    };
  }

  protected fromDatePickerOption: IMyDpOptions = this.datePickerOptionsBase;
  protected endDatePickerOption: IMyDpOptions = this.datePickerOptionsBase;

  /**
   * constructor
   * @param timetableServices {TimetableServices}
   * @param route {ActivatedRoute}
   * @param confirmationService {ConfirmationService}
   * @param projectServices {ProjectServices}sources
   */
  constructor(
    private timetableServices: TimetableServices,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private projectServices: ProjectServices,
    private translate: TranslateService
  ) {
    this.initializeForm();

    this.subscriptions.push(
      this.translate.stream('SELECT_STATUSES').subscribe((val) => {
        this.statuses = [];
        this.statuses.push(
          { label: val.SELECT, value: null },
          { label: val.OPEN, value: 8 },
          { label: val.DONE, value: 6 },
          { label: val.REJECTED, value: 10 }
        );
      })
    );
    this.subscriptions.push(
      this.translate.stream('SOURCE_DROPDOWN').subscribe((val) => {
        this.sources = [];
        this.sources.push(
          { label: val.SELECT, value: null },
          { label: val.MANUAL, value: 1 },
          { label: val.TIME_LOG, value: 2 }
        );
      })
    );

    this.bindMonths();
    this.bindYears();
    this.parentId = route.snapshot.parent.parent.params['id'];
    this.parentKey = route.snapshot.parent.parent.data['parent'];
    this.hiddenFieldHandler();
  }

  onFromDateChanged(date: IMyDateModel) {
    const updatedOptions: IMyDpOptions = {
      ...this.datePickerOptionsBase,
      disableUntil: { day: 0, month: 0, year: 0 },
    };

    if (date && date.jsdate) {
      const selectedDate = date.jsdate;

      updatedOptions.disableUntil = {
        year: selectedDate.getFullYear(),
        month: selectedDate.getMonth() + 1,
        day: selectedDate.getDate() - 1,
      };
    }

    this.endDatePickerOption = updatedOptions;
  }

  onEndDateChanged(date: IMyDateModel) {
    const updatedOptions: IMyDpOptions = {
      ...this.datePickerOptionsBase,
      disableSince: { day: 0, month: 0, year: 0 },
    };

    if (date && date.jsdate) {
      const selectedDate = date.jsdate;

      updatedOptions.disableSince = {
        year: selectedDate.getFullYear(),
        month: selectedDate.getMonth() + 1,
        day: selectedDate.getDate() + 1,
      };
    }

    this.fromDatePickerOption = updatedOptions;
  }

  /**
   * initialize filter form
   */
  private initializeForm() {
    this.filterForm = new FormGroup({
      fromDate: new FormControl<IMyDateModel | null>(null),
      endDate: new FormControl<IMyDateModel | null>(null),
    });

    // Initialize filtered data observable
    this.filteredTimeTable = this.filterForm.valueChanges.pipe(
      startWith(this.filterForm.value),
      map((formValue) => this.handleFilterChange(formValue))
    );
  }

  /**
   * Filter timetables when filter dates changes
   * @param formValue form value
   * @returns filtered data
   */
  private handleFilterChange(formValue: any) {
    let filteredData: Timetable[] = [...this.route.snapshot.data['list']];

    if (formValue.fromDate) {
      const fromDate = new Date(formValue.fromDate.jsdate);

      filteredData = filteredData.filter((item) => {
        const itemDate = new Date(item.StartDate);

        return itemDate >= fromDate;
      });
    }

    if (formValue.endDate) {
      const endDate = new Date(formValue.endDate.jsdate);

      filteredData = filteredData.filter((item) => {
        const itemDate = new Date(item.StartDate);

        return itemDate <= endDate;
      });
    }

    return filteredData;
  }

  /**
   * binding Years Dropdown
   */
  bindYears() {
    let currentYear = new Date().getFullYear();
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.years = [];
        this.years.push({ label: val.SELECT, value: null });
      })
    );
    for (let n = currentYear - 10; n <= currentYear + 10; n++) {
      this.years.push({ label: n, value: n });
    }
  }
  /**
   * to filter status in list
   * @param e
   * @param statusDropdown
   */
  onStatusChanged(e: any, statusDropdown: any) {
    if (e.value) {
      // custom filter datatable
      this.timetableTable.filter(
        statusDropdown.selectedOption.label,
        'Status',
        'equals'
      );
    } else {
      this.timetableTable.reset();
    }
  }
  /**
   * binding Month dropdown
   */
  bindMonths() {
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.months = [];
        this.months.push({ label: val.SELECT, value: null });
      })
    );
    for (let n = 1; n <= 12; n++) {
      this.months.push({ label: n, value: n < 10 ? '0' + n : n });
    }
  }

  totalSpentHours = 0;

  /**
   *
   * binding grid and dropdowns using resolver
   */
  bindDetails() {
    this.subscriptions.push(
      this.projectServices.getProjectById(this.parentId).subscribe((result) => {
        if (result) {
          this.projectName = result.Title;
        }
        this.timetables = [];
        let list = <any>this.route.snapshot.data['list'];
        if (list) {
          list = list.filter((x) => x.EndDate !== null);
          this.totalHoursSpent = HelperService.addTimes(list, 'HoursSpent');
          list.forEach((timetableList: any) => {
            timetableList.Month = `${
              new Date(timetableList.StartDate).getMonth() + 1
            }`;
            timetableList.Year = `${new Date(
              timetableList.StartDate
            ).getFullYear()}`;
            timetableList.ProjectName = this.projectName;
            this.timetables.push(timetableList);
          });
        }
      })
    );
    this.employees = [];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.employees = [];
        this.employees.push({ label: val.SELECT, value: null });
      })
    );
    let employees = this.route.snapshot.data['employee'];
    if (employees) {
      employees.forEach((employee: any) => {
        this.employees.push({ label: employee.Name, value: employee.Id });
      });
    }
  }
  /**
   * binding grid
   */
  bindGrid() {
    this.subscriptions.push(
      this.timetableServices
        .getAllByEach(this.parentId, this.parentKey)
        .subscribe((result) => {
          this.timetables = result;
          this.totalHoursSpent = HelperService.addTimes(
            this.timetables,
            'HoursSpent'
          );
        })
    );
  }
  /**
   * to filter employee in list
   * @param e
   * @param empDropdown
   */
  onEmpChanged(e: any, empDropdown: any) {
    if (e.value) {
      // custom filter datatable
      this.timetableTable.filter(
        empDropdown.selectedOption.label,
        'EmployeeName',
        ''
      );
      this.EmployeeName = e.value;
    } else {
      this.timetableTable.reset();
    }
  }
  /**
   * calling bindDetails function in page init
   */
  ngOnInit() {
    // To hide side menu bar
    document.querySelector('body').classList.remove('opened');
    document.querySelector('body').classList.remove('opened');
    this.bindDetails();
    if (BaseServices.UserRole === 'Guest') {
      this.isNotGuestLogin = false;
      this.isNotGuestAndUserLogin = false;
    }
    if (BaseServices.UserRole === 'User') {
      this.isNotGuestAndUserLogin = false;
    }
    this.exportPdf();
    if (BaseServices.FeatureId === FeatureKey.SUB_CONTRACTOR) {
      this.isSubContractorAndContacts = true;
      this.isNotGuestLogin = true;
      this.name = BaseServices.Name;
    } else if (BaseServices.FeatureId === FeatureKey.CONTRACTOR_CONTACT) {
      this.isSubContractorAndContacts = true;
      this.isNotGuestLogin = true;
      this.name = BaseServices.Name;
    } else if (BaseServices.FeatureId === FeatureKey.CLIENT) {
      this.isSubContractorAndContacts = true;
      this.isNotGuestLogin = true;
      this.name = BaseServices.Name;
    } else if (BaseServices.FeatureId == FeatureKey.CLIENT_CONTACT) {
      this.isSubContractorAndContacts = true;
      this.isNotGuestLogin = true;
      this.name = BaseServices.Name;
    } else {
      this.isNotSubContractorAndContacts = true;
    }
  }

  /**
   * filtering list based on month
   */
  onMonthChanged(e: any, monthDropdown: any) {
    if (e.value) {
      // custom filter datatable
      this.timetableTable.filter(
        monthDropdown.selectedOption.label,
        'Month',
        'equals'
      );
    } else {
      this.timetableTable.reset();
    }
  }
  /**
   * filtering grid based on year
   * @param e
   * @param yearDropdown
   */
  onYearChanged(e: any, yearDropdown: any) {
    if (e.value) {
      // custom filter datatable
      this.timetableTable.filter(
        yearDropdown.selectedOption.label,
        'Year',
        'equals'
      );
    } else {
      this.timetableTable.reset();
    }
  }

  /**
   * delete time table based on Id
   */
  deleteTimetable(id: number) {
    this.confirmationService.confirm({
      message: this.translate.instant('DELETE_THIS_RECORD'),
      accept: () => {
        this.subscriptions.push(
          this.timetableServices
            .DeleteTimetable(id)
            .subscribe((deleteResponse) => {
              if (deleteResponse) {
                this.toasterComponent.callToastDlt();
                this.bindGrid();
              }
            })
        );
      },
    });
  }

  exportPdf() {
    this.parentKey = this.route.snapshot.parent.parent.data['parent'];
    this.subscriptions.push(
      this.translate.stream('FILENAME').subscribe((val) => {
        if (this.parentKey === 'PROJECT') {
          this.additionalData = {
            fileName: val.PROJECT_TIMETABLE_LIST,
            header: 'Project timetable list',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
              { title: 'Employee Name', dataKey: 'EmployeeName' },
              { title: 'Project Name', dataKey: 'ProjectName' },
              { title: 'Type of Work', dataKey: 'ProjectWorkTypeName' },
              { title: 'Start Date', dataKey: 'StartDate' },
              { title: 'End Date', dataKey: 'EndDate' },
              { title: 'Start Time', dataKey: 'StartTime' },
              { title: 'End Time', dataKey: 'EndTime' },
              { title: 'Hours Spent', dataKey: 'HoursSpent' },
              { title: 'Comments', datakey: 'Comments' },
              { title: 'Edited By', dataKey: 'ModifierName' },
              { title: 'Source', dataKey: 'Source' },
            ],
          };
        } else if (this.parentKey === 'FDV') {
          this.additionalData = {
            fileName: val.FDV_TIMETABLE_LIST,
            header: 'FDV timetable list',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
              { title: 'Employee Name', dataKey: 'EmployeeName' },
              { title: 'FDV Name', dataKey: 'ProjectName' },
              { title: 'Type of Work', dataKey: 'ProjectWorkTypeName' },
              { title: 'Start Date', dataKey: 'StartDate' },
              { title: 'Start Time', dataKey: 'StartTime' },
              { title: 'Hours Spent', dataKey: 'HoursSpent' },
              { title: 'Comments', datakey: 'Comments' },
              { title: 'Edited By', dataKey: 'ModifierName' },
            ],
          };
        } else if (this.parentKey === 'FS') {
          this.additionalData = {
            fileName: val.FS_TIMETABLE_LIST,
            header: 'FS timetable list',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
              { title: 'Employee Name', dataKey: 'EmployeeName' },
              { title: 'Type of Work', dataKey: 'ProjectWorkTypeName' },
              { title: 'Start Date', dataKey: 'StartDate' },
              { title: 'End Date', dataKey: 'EndDate' },
              { title: 'Start Time', dataKey: 'StartTime' },
              { title: 'End Time', dataKey: 'EndTime' },
              { title: 'Hours Spent', dataKey: 'HoursSpent' },
              { title: 'Status', dataKey: 'StatusText' },
              { title: 'Comments', datakey: 'Comments' },
              { title: 'Edited By', dataKey: 'ModifierName' },
            ],
          };
        } else if (this.parentKey === 'IC') {
          this.additionalData = {
            fileName: val.INTERNAL_CONTROL_TIMETABLE_LIST,
            header: 'Internal control timetable list',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
              { title: 'Employee Name', dataKey: 'EmployeeName' },
              { title: 'Internal control name', dataKey: 'ProjectName' },
              { title: 'Type of Work', dataKey: 'ProjectWorkTypeName' },
              { title: 'Start Date', dataKey: 'StartDate' },
              { title: 'Start Time', dataKey: 'StartTime' },
              { title: 'End Time', dataKey: 'EndTime' },
              { title: 'Hours Spent', dataKey: 'HoursSpent' },
              { title: 'Comments', datakey: 'Comments' },
              { title: 'Edited By', dataKey: 'ModifierName' },
            ],
          };
        } else if (this.parentKey === 'OS') {
          this.additionalData = {
            fileName: val.INTERNAL_CONTROL_TIMETABLE_LIST,
            header: 'Other Systems timetable list',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
              { title: 'Employee Name', dataKey: 'EmployeeName' },
              { title: 'Other System name', dataKey: 'ProjectName' },
              { title: 'Type of Work', dataKey: 'ProjectWorkTypeName' },
              { title: 'Start Date', dataKey: 'StartDate' },
              { title: 'Start Time', dataKey: 'StartTime' },
              { title: 'End Time', dataKey: 'EndTime' },
              { title: 'Hours Spent', dataKey: 'HoursSpent' },
              { title: 'Comments', datakey: 'Comments' },
              { title: 'Edited By', dataKey: 'ModifierName' },
            ],
          };
        } else if (this.parentKey === 'QS') {
          this.additionalData = {
            fileName: val.INTERNAL_CONTROL_TIMETABLE_LIST,
            header: 'Quality System timetable list',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
              { title: 'Employee Name', dataKey: 'EmployeeName' },
              { title: 'Quality System name', dataKey: 'ProjectName' },
              { title: 'Type of Work', dataKey: 'ProjectWorkTypeName' },
              { title: 'Start Date', dataKey: 'StartDate' },
              { title: 'Start Time', dataKey: 'StartTime' },
              { title: 'End Time', dataKey: 'EndTime' },
              { title: 'Hours Spent', dataKey: 'HoursSpent' },
              { title: 'Comments', datakey: 'Comments' },
              { title: 'Edited By', dataKey: 'ModifierName' },
            ],
          };
        } else if (this.parentKey === 'VEHICLE') {
          this.additionalData = {
            fileName: val.VEHICLE_TIMETABLE_LIST,
            header: 'Vehicle timetable list',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
              { title: 'Employee Name', dataKey: 'EmployeeName' },
              { title: 'Vehicle Name', dataKey: 'ProjectName' },
              { title: 'Type of Work', dataKey: 'ProjectWorkTypeName' },
              { title: 'Start Date', dataKey: 'StartDate' },
              { title: 'Start Time', dataKey: 'StartTime' },
              { title: 'Hours Spent', dataKey: 'HoursSpent' },
              { title: 'Comments', datakey: 'Comments' },
              { title: 'Edited By', dataKey: 'ModifierName' },
            ],
          };
        } else if (this.parentKey === 'FRAMEWORK') {
          this.additionalData = {
            fileName: val.FRAMEWORK_TIMETABLE_LIST,
            header: 'Framework timetable list',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
              { title: 'Employee Name', dataKey: 'EmployeeName' },
              { title: 'Contract Name', dataKey: 'ProjectName' },
              { title: 'Type of Work', dataKey: 'ProjectWorkTypeName' },
              { title: 'Start Date', dataKey: 'StartDate' },
              { title: 'Start Time', dataKey: 'StartTime' },
              { title: 'Hours Spent', dataKey: 'HoursSpent' },
              { title: 'Comments', datakey: 'Comments' },
              { title: 'Edited By', dataKey: 'ModifierName' },
              { title: 'Status', dataKey: 'StatusText' },
            ],
          };
        } else if (this.parentKey === 'SERVICE') {
          this.additionalData = {
            fileName: val.SERVICE_AGREEMENT_TIMETABLE_LIST,
            header: 'Service agreement timetable list',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
              { title: 'Employee Name', dataKey: 'EmployeeName' },
              { title: 'Contract Name', dataKey: 'ProjectName' },
              { title: 'Type of Work', dataKey: 'ProjectWorkTypeName' },
              { title: 'Start Date', dataKey: 'StartDate' },
              { title: 'Start Time', dataKey: 'StartTime' },
              { title: 'Hours Spent', dataKey: 'HoursSpent' },
              { title: 'Comments', datakey: 'Comments' },
              { title: 'Edited By', dataKey: 'ModifierName' },
              { title: 'Status', dataKey: 'StatusText' },
            ],
          };
        } else if (this.parentKey === 'KUNDE_EL') {
          this.additionalData = {
            fileName: val.IK_KUNDE_EL_TIMETABLE_LIST,
            header: 'IK-Kunde EL timetable list',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
              { title: 'Employee Name', dataKey: 'EmployeeName' },
              { title: 'Contract Name', dataKey: 'ProjectName' },
              { title: 'Type of Work', dataKey: 'ProjectWorkTypeName' },
              { title: 'Start Date', dataKey: 'StartDate' },
              { title: 'Start Time', dataKey: 'StartTime' },
              { title: 'Hours Spent', dataKey: 'HoursSpent' },
              { title: 'Comments', datakey: 'Comments' },
              { title: 'Edited By', dataKey: 'ModifierName' },
              { title: 'Status', dataKey: 'StatusText' },
            ],
          };
        } else {
          this.additionalData = {
            fileName: val.TIMETABLE_LIST,
            header: 'Timetable list',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
              { title: 'Employee Name', dataKey: 'EmployeeName' },
              { title: 'Project Name', dataKey: 'ProjectName' },
              { title: 'Type of Work', dataKey: 'ProjectWorkTypeName' },
              { title: 'Work Date', datakey: 'StartDate' },
              { title: 'Start Date', dataKey: 'StartDate' },
              { title: 'End Date', dataKey: 'EndDate' },
              { title: 'Start Time', dataKey: 'StartTime' },
              { title: 'End Time', dataKey: 'EndTime' },
              { title: 'Hours Spent', dataKey: 'HoursSpent' },
              { title: 'Comments', datakey: 'Comments' },
              { title: 'Edited By', dataKey: 'ModifierName' },
            ],
          };
        }
      })
    );
  }

  hiddenFieldHandler() {
    switch (this.parentKey) {
      case 'PROJECT':
        this.projectFields = true;
        this.fdvFields = false;
        this.isFoodsafetyOrProject = true;
        break;
      case 'FDV':
        break;
      case 'IC':
        break;
      case 'FS':
      case 'KUBA_CONTROL':
        this.fdvFields = false;
        this.isFoodsafetyOrProject = true;
        break;
      case 'VEHICLE':
        break;
      default:
        break;
    }
  }
  onSourceChanged(e: any, sourceDropdown: any) {
    if (e.value) {
      // custom filter datatable
      this.timetableTable.filter(
        sourceDropdown.selectedOption.label,
        'Source',
        'equals'
      );
    } else {
      this.timetableTable.reset();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}
