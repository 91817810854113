import { CountryCodeResolver } from './../client/services/client.resolver.service';

import { ELListResolver } from './../kunde-EL/services/el.resolver';
import { FdvListResolver } from './../FDV/services/fdv.resolver.service';
import { Route } from '@angular/router';

import { AuthGuard, AdminPortalTabGuard } from '../../_guards/index';
import { BusinessesComponent } from './businesses.component';
import { BusinessSignComponent } from './components/business-sign.component';
import { ApprovalPersonResolver } from './../employees/services/employeeleave.resolver.service';
import { UserRoleResolver } from './../users/services/user.resolver.service';
import {
  DepartmentDropDownResolver,
  BusinessCourseResolver,
  EmployeeListResolver,
} from './../employees/services/employee.resolver.service';
import { CourseDetailComponent } from './../employees/components/employee-course-detail.component';
import { BusinessLinksListResolver } from './services/businessLinks.resolver.service';
import {
  BusinessListComponent,
  BusinessWizardComponent,
  BusinessDetailsComponent,
  BusinessUsersComponent,
  BusinessFeaturesComponent,
  BusinessHomepageComponent,
  BusinessLicenseComponent,
  BusinessSettingsComponent,
  BusinessAbsenceCodesComponent,
  BusinessEquipmentsComponent,
  BusinessUsersEditComponent,
  BusinessLinksComponent,
  BusinessCourseListComponent,
  BusinessLogComponent,
} from './components/';
import {
  BusinessEditResolver,
  BusinessAgreementResolver,
  BusinessEquipmentResolver,
  BusinessCourseEditResolver,
  BusinessCourseListResolver,
  EmployeeContactUserResolver,
  EmployeeCountResolver,
  BusinessCourseResolverByBusiness,
  BusinessAbsenceCodeByBusinessResolver,
  LanguageResolver,
  SettingByBusinessResolver,
  HseManagerResolver,
  UserByBusinessResolver,
  BusinessUserEditResolver,
  BusinessCategoryResolver,
  PortalBusinessResolver,
  BusinessLogListResolver,
  EmployeeOnlyResolver,
  PortalContactListResolver,
  BusinessCacheResolver,
  BusinessDepartmentResolver,
  BusinessDataResolver,
  LicenseCategoryResolver,
  BusinessDataFeatureResolver,
  BusinessUsersCountResolver,
  BusinessEmployeesCountResolver,
  HavePersonalDelegateResolver,
  HseAdviserResolver,
} from './services/business.resolver.service';
import { BusinessSignedUsersListResolver } from 'src/app/kuba/businesses/services/businessSigned.resolver.service';
import { ProjectListResolver } from 'src/app/kuba/projects/services/project.resolver';
import { ApplicationDefaultVideoResolver } from '../customize-homepage/customize-homepage.resolver';

export const businessRoutes: Route[] = [
  {
    path: 'business',
    component: BusinessesComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: BusinessListComponent,
        canActivate: [AdminPortalTabGuard],
        resolve: {
          list: BusinessCacheResolver,
        },
      },
      {
        path: 'new',
        component: BusinessWizardComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'details',
            component: BusinessDetailsComponent,
            resolve: {
              category: BusinessCategoryResolver,
            },
          },
          {
            path: 'license',
            component: BusinessLicenseComponent,
            resolve: {
              list: BusinessCacheResolver,
              licenseCategoryList: LicenseCategoryResolver,
            },
          },
        ],
      },

      {
        path: 'edit/:bid',
        component: BusinessWizardComponent,
        canActivate: [AuthGuard],
        resolve: {
          detail: BusinessEditResolver,
          category: BusinessCategoryResolver,
          employeeCount: EmployeeCountResolver,
          businessdata: BusinessDataFeatureResolver,
        },
        children: [
          {
            path: 'details',
            component: BusinessDetailsComponent,
          },
          {
            path: 'user/list',
            component: BusinessUsersComponent,
            resolve: {
              userList: UserByBusinessResolver,
            },
          },
          {
            path: 'user/edit/:uid',
            component: BusinessUsersEditComponent,
            resolve: {
              userData: BusinessUserEditResolver,
              userrole: UserRoleResolver,
              projectList: ProjectListResolver,
              list: ELListResolver,
              fdvList: FdvListResolver,
              countryCode: CountryCodeResolver,
              department: BusinessDepartmentResolver,
              businessdata: BusinessDataResolver,
            },
          },
          {
            path: 'home-page',
            component: BusinessHomepageComponent,
            resolve: {
              list: BusinessCacheResolver,
            },
          },
          {
            path: 'license',
            component: BusinessLicenseComponent,
            resolve: {
              agreement: BusinessAgreementResolver,
              list: BusinessCacheResolver,
              licenseCategoryList: LicenseCategoryResolver,
              usersCount: BusinessUsersCountResolver,
              employeesCount: BusinessEmployeesCountResolver,
            },
          },
          {
            path: 'settings',
            component: BusinessSettingsComponent,
            resolve: {
              user: EmployeeContactUserResolver,
              EmployeeOnly: EmployeeOnlyResolver,
              language: LanguageResolver,
              setting: SettingByBusinessResolver,
              portalbusiness: PortalBusinessResolver,
              portalcontact: PortalContactListResolver,
              HseAdviser: HseAdviserResolver,
              employeeContact: HseManagerResolver,
              havePersonalDelegateOptions: HavePersonalDelegateResolver,
            },
          },
          {
            path: 'equipments',
            component: BusinessEquipmentsComponent,
            resolve: {
              equipment: BusinessEquipmentResolver,
            },
          },
          {
            path: 'absence-codes',
            component: BusinessAbsenceCodesComponent,
            resolve: {
              absencecode: BusinessAbsenceCodeByBusinessResolver,
            },
          },
          {
            path: 'user/list',
            component: BusinessUsersComponent,
          },
          {
            path: 'user/edit/:uid',
            component: BusinessUsersEditComponent,
          },
          {
            path: 'features',
            component: BusinessFeaturesComponent,
          },
          {
            path: 'home-page',
            component: BusinessHomepageComponent,
          },
          {
            path: 'license',
            component: BusinessLicenseComponent,
          },
          {
            path: 'settings',
            component: BusinessSettingsComponent,
          },

          {
            path: 'equipments',
            component: BusinessEquipmentsComponent,
          },
          {
            path: 'absence-codes',
            component: BusinessAbsenceCodesComponent,
          },
          {
            path: 'courses',
            component: BusinessCourseListComponent,
            resolve: {
              list: BusinessCourseResolverByBusiness,
              department: DepartmentDropDownResolver,
              employee: EmployeeListResolver,
              course: BusinessCourseResolver,
              courselist: BusinessCourseListResolver,
            },
          },
          {
            path: 'signed',
            component: BusinessSignComponent,
            resolve: {
              users: BusinessSignedUsersListResolver,
            },
          },
          {
            path: 'courses/edit/:eid',
            component: CourseDetailComponent,
            resolve: {
              course: BusinessCourseResolver,
              responsible: ApprovalPersonResolver,
              businesseditcourse: BusinessCourseEditResolver,
            },
          },
          {
            path: 'links',
            component: BusinessLinksComponent,
            resolve: {
              list: BusinessLinksListResolver,
            },
          },
          {
            path: 'log',
            component: BusinessLogComponent,
            resolve: {
              loglist: BusinessLogListResolver,
            },
          },
        ],
      },
    ],
  },
];
