<div class="main-content">
    <div class="main-heading">
        <span translate>ASSIGN_MANUALS</span>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="mbtm-30">
                <div class="form-group" *ngIf="userRole!=2">
                    <p-radioButton label="{{'PORTAL'|translate}}" class="radio-marg"
                      (onClick)="onRadioTypeChange('Portal')" name="Portal" value="Portal" [(ngModel)]="selectedType">
                    </p-radioButton>
                    <p-radioButton label="{{'BUSINESS'|translate}}" class="radio-marg"
                      (onClick)="onRadioTypeChange('Business')" name="Business" value="Business"
                      [(ngModel)]="selectedType">
                    </p-radioButton>
                </div>
                <div class="input-btn-group">
                    <p-dropdown [options]="typeList" [(ngModel)]="levelKey"
                      (onChange)="onChangeType($event,optionName)" id="typeList" [filter]="true"
                      [style]="{'width':'100%'}"></p-dropdown>
                </div>
            </div>
            <div *ngIf="levelKey!=null" class="mbtm-30">
                <div class="page-title" translate>{{selectedType}} {{'MANUALS' |translate }}</div>
                <div class="table-view">
                    <assign-manual-list [CommonManuals]="assignManuals" [isAllCommonManual]="isAllCommonManual"
                      (onAssignManualEdit)="onBusinessOrPortalManualsEdit($event)" [ActiveList]="activeList"
                      [isBindCommonManual]="setbooleanFalse" [manualVersions]="manualVersionDropDown"
                      [userList]="userList" (onDeleteAssignedManual)="onDeleteAssignedManual($event)">
                    </assign-manual-list>
                </div>
            </div>
            <div>
                <span class="page-title" translate>COMMON_MANUAL</span>
                <assign-manual-list [CommonManuals]="CommonManuals" [roleId]="roleId"
                  [isAllCommonManual]="isAllCommonManual" [isBindCommonManual]="setbooleanTrue"
                  (onAssignManualEdit)="onSelectedCommonManuals($event)"
                  (onPortalCommonManualEdit)="onPortalCommonManualsEdit($event)"
                  [manualVersions]="manualVersionDropDown">
                </assign-manual-list>
            </div>
        </div>
    </div>
</div>
<toaster-component></toaster-component>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
  acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>