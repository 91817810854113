<form [formGroup]="newProjectForm">
  <div class="row">
    <!-- Project information section -->
    <div class="col-sm-4 section">
      <div class="form-group">
        <div class="page-title">
          <h4 translate>PROJECT_DETAILS</h4>
        </div>
        <h6 translate>STATUS</h6>
        <div>
          <p-radioButton
            name="group1"
            [formControl]="newProjectForm.controls['Status']"
            value="{{ option.id }}"
            *ngFor="let option of options"
            label="{{ option.name | translate }}"
            (checked)="(option.id === newProjectForm.controls['Status'].value)"
          ></p-radioButton>
        </div>
      </div>
      <!-- Project numnber + name section -->
      <div class="row">
        <div class="col-sm-6 p-0">
          <div class="form-group">
            <label for="Number">
              <span>{{ "PROJ_NUMBER" | translate }}</span>
              <span class="required">*</span>
            </label>
            <div>
              <input
                type="number"
                pInputText
                formControlName="Number"
                (keypress)="onNumberPress($event, 15)"
                id="Number"
                class="ex-full-width"
              />
            </div>
            <control-messages
              [control]="newProjectForm.controls['Number']"
            ></control-messages>
          </div>
        </div>
        <div class="col-sm-6 p-0">
          <div class="form-group">
            <label for="Title">
              <span translate>PROJ_NAME</span>
              <span class="required">*</span>
            </label>
            <div>
              <input
                type="text"
                pInputText
                formControlName="Title"
                id="Title"
                class="ex-full-width"
              />
            </div>

            <control-messages
              [control]="newProjectForm.controls['Title']"
            ></control-messages>
          </div>
        </div>
      </div>

      <!-- Project date section -->
      <div class="row">
        <div class="col-sm-6 p-0">
          <div class="form-group">
            <label for="StartDate">
              <span translate>START_DATE</span>
              <span class="required">*</span>
            </label>
            <div>
              <my-date-picker
                name="startdate"
                [options]="startDateOptions"
                formControlName="StartDate"
                placeholder="{{ 'SELECT_START_DATE' | translate }}"
                required
                (dateChanged)="onStartDateChanged($event)"
              ></my-date-picker>
            </div>
            <control-messages
              [control]="newProjectForm.controls['StartDate']"
            ></control-messages>
          </div>
        </div>
        <div class="col-sm-6 p-0">
          <div class="form-group">
            <label for="ExpectedEndDate">
              <span translate>EXPECTED_END</span>
              <span class="required">*</span>
            </label>
            <div>
              <my-date-picker
                name="startdate"
                [options]="endDateOptions"
                formControlName="ExpectedEndDate"
                placeholder="{{ 'SELECT_END_DATE' | translate }}"
                required
                (dateChanged)="onEndDateChanged($event)"
              ></my-date-picker>
            </div>
            <control-messages
              [control]="newProjectForm.controls['ExpectedEndDate']"
            ></control-messages>
          </div>
        </div>
      </div>
      <!-- Show copy project button if new project -->
      <div *ngIf="projectId == null" class="pb-1">
        <h6 translate>COPY_PROJECT</h6>
        <button class="btn btn-primary" (click)="openDialog('NEWPROJECT')">
          <span translate>SELECT_A_PROJECT</span>
        </button>
      </div>
      <!-- Order and SSB Section -->
      <div class="row">
        <div class="col-sm-6 p-0">
          <div class="form-group">
            <label for="OrderNumber">
              <span translate>ORDER_NUMBER</span>
            </label>
            <div>
              <input
                type="number"
                pInputText
                formControlName="OrderNumber"
                (keypress)="onNumberPress($event, 15)"
                id="OrderNumber"
                class="ex-full-width"
              />
            </div>
            <control-messages
              [control]="newProjectForm.controls['OrderNumber']"
            ></control-messages>
          </div>
        </div>
        <div class="col-sm-6 p-0">
          <div class="form-group">
            <label for="Ssbnumber">
              <span translate>SSB_NUMBER</span>
            </label>
            <div>
              <input
                type="number"
                pInputText
                formControlName="SSBNumber"
                (keypress)="onNumberPress($event, 15)"
                id="Ssbnumber"
                class="ex-full-width"
              />
            </div>
            <control-messages
              [control]="newProjectForm.controls['SSBNumber']"
            ></control-messages>
          </div>
        </div>
      </div>
      <div class="sm-12 p-0">
        <label for="Description">
          <span translate>DESCRIPTION</span>
        </label>
        <div>
          <textarea
            pInputTextarea
            id="Description"
            formControlName="Description"
            class="ex-description"
          ></textarea>
        </div>
      </div>
      <!-- <label for="ProjectCost">
        <span translate>PROJ_COST</span>
      </label>
      <div>
        <input
          type="number"
          pInputText
          id="ProjectCost"
          formControlName="ProjectCost"
          (keypress)="onKeyPress($event)"
          class="ex-full-width"
        />
      </div> -->
      <!-- Contact person -->
      <div class="Contact person">
        <label for="ContactPerson">
          <span translate>CONTACT_PERSON</span>
        </label>
        <div>
          <p-multiSelect
            defaultLabel="{{ 'SELECT' | translate }}"
            [options]="ContactPerson"
            formControlName="SelectedContactPerson"
            [style]="{ width: '100%' }"
          >
            <ng-template let-contactperson let-i="index" pTemplate="item">
              {{ i }}
              <span>{{ contactperson.label }}</span>
            </ng-template>
          </p-multiSelect>
        </div>
      </div>
      <!-- Project image upload -->
      <div class="upload-file-container">
        <label>
          <span translate>PROJECT_IMAGE</span>
        </label>
        <div class="form-group">
          <div class="upload-file-container">
            <div class="upload-actions">
              <input
                type="file"
                id="files"
                class="hidden"
                (change)="filesSelect($event)"
              />
              <label for="files" class="btn btn-outline-primary" translate
                >UPLOAD_IMAGE</label
              >
            </div>
            <div *ngIf="uploadStart">
              <span translate>IMAGE_IS_UPLOADING</span>
            </div>
            <div *ngIf="projectImage != null" class="image-container">
              <img
                src="{{ projectImage }}"
                alt=""
                width="200"
                class="thumbnail"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- Deviation section -->
      <div class="Deviation">
        <label for="EmailDevaition">
          <span translate>EMAIL_NEWDEVIATION_LABEL</span>
        </label>
        <div *ngIf="bindSelectedUsers">
          <p-multiSelect
            defaultLabel="{{ 'SELECT' | translate }}"
            [dataKey]="'Id'"
            [options]="userSubscribers"
            formControlName="selectedEmailSubscriber"
            [style]="{ width: '100%' }"
          >
            <ng-template let-emailDeviation let-i="index" pTemplate="item">
              {{ i }}
              <span>{{ emailDeviation.label }}</span>
            </ng-template>
          </p-multiSelect>
        </div>
        <div *ngIf="bindAllUsers">
          <p-multiSelect
            defaultLabel="{{ 'SELECT' | translate }}"
            [dataKey]="'Id'"
            [options]="selectedSubscribers"
            formControlName="selectedEmailSMSSubscriber"
            [style]="{ width: '100%' }"
          >
            <ng-template let-emailDeviations let-i="index" pTemplate="item">
              {{ i }}
              <span>{{ emailDeviations.label }}</span>
            </ng-template>
          </p-multiSelect>
        </div>

        <label for="SMSDevaition">
          <span translate>SMS_NEWDEVIATION_LABEL</span>
        </label>
        <div *ngIf="bindSelectedUsers">
          <p-multiSelect
            defaultLabel="{{ 'SELECT' | translate }}"
            dataKey="Id"
            [options]="userSubscribers"
            formControlName="selectedSMSSubscriber"
            [style]="{ width: '100%' }"
          >
            <ng-template let-smsDeviation let-i="index" pTemplate="item">
              {{ i }}
              <span>{{ smsDeviation.label }}</span>
            </ng-template>
          </p-multiSelect>
        </div>
        <div *ngIf="bindAllUsers">
          <p-multiSelect
            defaultLabel="{{ 'SELECT' | translate }}"
            dataKey="Id"
            [options]="selectedSubscribers"
            formControlName="smsSubscriber"
            [style]="{ width: '100%' }"
          >
            <ng-template let-smsDeviations let-i="index" pTemplate="item">
              {{ i }}
              <span>{{ smsDeviations.label }}</span>
            </ng-template>
          </p-multiSelect>
        </div>
        <div class="checkbox pt-2">
          <p-checkbox
            value="isShowDeviationChecked"
            label="{{ 'DO_NOT_DISPLAY_DEVIATION' | translate }}"
            formControlName="isShowDeviationChecked"
            binary="true"
          ></p-checkbox>
        </div>
      </div>
    </div>
    <!-- Middle section -->
    <div class="col-sm-4 section">
      <!-- Responsibility section -->
      <div class="page-title">
        <h4 translate>ORGANIZED</h4>
      </div>

      <h6 translate>AREA_OF_RESPONSIBILITY</h6>
      <div class="container-fluid px-0 pb-3">
        <div class="row" formArrayName="Responsibility">
          <!-- First Row: 3 items -->
          <div
            class="col-12 col-md-4 p-0"
            *ngFor="
              let responsibility of Responsibility.slice(0, 3);
              let i = index
            "
          >
            <div formGroupName="{{ i }}" class="d-flex align-items-start mb-2">
              <p-checkbox
                [value]="responsibility.Name | translate"
                [label]="responsibility.Name | translate"
                formControlName="{{ responsibility.Id }}"
                binary="true"
              ></p-checkbox>
            </div>
          </div>
        </div>
        <div class="row" formArrayName="Responsibility">
          <!-- Second Row: 2 items -->
          <div
            class="col-12 col-md-4 p-0"
            *ngFor="
              let responsibility of Responsibility.slice(3);
              let i = index
            "
          >
            <div
              formGroupName="{{ i + 3 }}"
              class="d-flex align-items-start mb-2"
            >
              <p-checkbox
                [value]="responsibility.Name | translate"
                [label]="responsibility.Name | translate"
                formControlName="{{ responsibility.Id }}"
                binary="true"
              ></p-checkbox>
            </div>
          </div>
        </div>
      </div>

      <!-- Organization section -->

      <div class="row pb-1">
        <div class="col-sm-6 p-0">
          <label for="ProfessionalManager">
            <span translate>PROF_MANAGER</span>
          </label>
          <div>
            <p-multiSelect
              defaultLabel="{{ 'SELECT' | translate }}"
              [options]="ProfessionalManager"
              formControlName="selectedManager"
              [style]="{ width: '100%' }"
            >
              <ng-template
                let-professionalmanager
                let-i="index"
                pTemplate="item"
              >
                {{ i }}
                <span>{{ professionalmanager.label }}</span>
              </ng-template>
            </p-multiSelect>
          </div>
        </div>
        <div class="col-sm-6 p-0">
          <label for="ProjectManager">
            <span translate>PROJ_MANAGER</span>
          </label>
          <div>
            <p-multiSelect
              defaultLabel="{{ 'SELECT' | translate }}"
              [options]="ProjectManager"
              id="ProjectManager"
              formControlName="selectedProjectManager"
              [style]="{ width: '100%' }"
            >
              <ng-template let-projectmanager let-i="index" pTemplate="item">
                {{ i }}
                <span>{{ projectmanager.label }}</span>
              </ng-template>
            </p-multiSelect>
          </div>
        </div>
      </div>
      <div class="row py-1">
        <div class="col-sm-6 p-0">
          <label for="ResponsibleforApplication" translate
            >RESPONSE_APPLN</label
          >
          <div>
            <p-multiSelect
              defaultLabel="{{ 'SELECT' | translate }}"
              [options]="ResponsibleforApplication"
              id="ResponsibleforApplication"
              formControlName="selectedResponsibleForApp"
              [style]="{ width: '100%' }"
            >
              <ng-template let-respapplication let-i="index" pTemplate="item">
                {{ i }}
                <span>{{ respapplication.label }}</span>
              </ng-template>
            </p-multiSelect>
          </div>
        </div>
        <div class="col-sm-6 p-0">
          <label for="ResponsibleforProjectPlanning">
            <span translate>RESPONSE_PROJ_PLAN</span>
          </label>
          <div>
            <input
              type="text"
              pInputText
              id="ProjectPlan"
              formControlName="ProjectPlan"
              class="ex-full-width"
            />
          </div>
        </div>
      </div>
      <div class="row py-1">
        <div class="col-sm-6 p-0">
          <label for="ResponsibleforExecution">
            <span translate>RESPONSIBLE_FOR_EXECUTION</span>
          </label>
          <div>
            <p-multiSelect
              defaultLabel="{{ 'SELECT' | translate }}"
              [options]="ResponsibleforExecution"
              id="ResponsibleforExecution"
              formControlName="selectedResponsibleForExecution"
              [style]="{ width: '100%' }"
            >
              <ng-template let-respexecution let-i="index" pTemplate="item">
                {{ i }}
                <span>{{ respexecution.label }}</span>
              </ng-template>
            </p-multiSelect>
          </div>
        </div>
        <div class="col-sm-6 p-0">
          <label for="Coordinatorexecution">
            <span translate>COORDINATOR_EXECUTION</span>
          </label>
          <div>
            <p-multiSelect
              defaultLabel="{{ 'SELECT' | translate }}"
              [options]="Coordinatorexecution"
              id="Coordinatorexecution"
              formControlName="selectedCordinatorExecution"
              [style]="{ width: '100%' }"
            >
              <ng-template let-coordinatorexec let-i="index" pTemplate="item">
                {{ i }}
                <span>{{ coordinatorexec.label }}</span>
              </ng-template>
            </p-multiSelect>
          </div>
        </div>
      </div>
      <div class="row py-1">
        <div class="col-sm-6 p-0">
          <label for="Safetyrepresentatives">
            <span translate>SAFETY_REP</span>
          </label>
          <div>
            <p-multiSelect
              defaultLabel="{{ 'SELECT' | translate }}"
              [options]="Safetyrepresentatives"
              id="Safetyrepresentatives"
              formControlName="selectedSafetyRepresentative"
              [style]="{ width: '100%' }"
            >
              <ng-template let-safetyrep let-i="index" pTemplate="item">
                {{ i }}
                <span>{{ safetyrep.label }}</span>
              </ng-template>
            </p-multiSelect>
          </div>
        </div>
        <div class="col-sm-6 p-0">
          <label for="Constructionmanager">
            <span translate>CONSTRUCTION_MGR</span>
          </label>
          <div>
            <p-multiSelect
              defaultLabel="{{ 'SELECT' | translate }}"
              [options]="Constructionmanager"
              id="Constructionmanager"
              formControlName="selectedConstructionManager"
              [style]="{ width: '100%' }"
            >
              <ng-template
                let-constructionmanager
                let-i="index"
                pTemplate="item"
              >
                {{ i }}
                <span>{{ constructionmanager.label }}</span>
              </ng-template>
            </p-multiSelect>
          </div>
        </div>
      </div>
      <div class="row py-1">
        <div class="col-sm-6 p-0">
          <label for="Coordinatorprojecting">
            <span translate>COORDINATOR_PROJECTING</span>
          </label>
          <div>
            <input
              type="text"
              pInputText
              id="CoordinatorProjecting"
              formControlName="CoordinatorProjecting"
              class="ex-full-width"
            />
          </div>
        </div>

        <div class="col-sm-6 p-0">
          <label for="ResponsibleforIndependentControl">
            <span translate>RESPONSE_INPNT_CTRL</span>
          </label>
          <div>
            <input
              type="text"
              pInputText
              id="IndependentControl"
              formControlName="IndependentControl"
              class="ex-full-width"
            />
          </div>
        </div>
      </div>
      <div class="builder py-1">
        <label for="BuilderSelect">
          <span translate>BUILDER</span>
        </label>
        <div>
          <input
            type="text"
            pInputText
            id="ProjectBuilder"
            formControlName="ProjectBuilder"
            class="ex-full-width"
          />
        </div>
      </div>
    </div>
    <!-- Assign access section -->
    <div class="col-sm-4 section">
      <div class="page-title">
        <h4 translate>ASSIGN_ACCESS</h4>
      </div>
      <h6 translate>EMPLOYEES</h6>
      <!-- Employee table -->
      <div class="Employees">
        <!-- Table starts -->
        <p-table
          #dt
          [value]="projectUsers"
          dataKey="Id"
          scrollable="true"
          scrollHeight="300px"
        >
          <!-- Header -->
          <ng-template pTemplate="header">
            <tr>
              <th>
                <p-checkbox
                  [ngModel]="checkedAll"
                  (ngModelChange)="checkAll($event)"
                  [ngModelOptions]="{ standalone: true }"
                  binary="true"
                ></p-checkbox>
              </th>
              <th>{{ "NAME" | translate }}</th>
            </tr>
          </ng-template>
          <!-- Body Content -->
          <ng-template pTemplate="body" let-internal>
            <tr>
              <td>
                <p-checkbox
                  [(ngModel)]="internal.Id === userId || internal.status"
                  [disabled]="internal.Id === userId"
                  (ngModelChange)="checked(internal)"
                  [ngModelOptions]="{ standalone: true }"
                  binary="true"
                ></p-checkbox>
              </td>
              <td>
                <span>{{ internal.Name }}</span>
              </td>
            </tr>
          </ng-template>
          <!-- Empty message -->
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td colspan="2">
                {{ "NO_RECORDS_FOUND" | translate }}
              </td>
            </tr>
          </ng-template>
        </p-table>
        <!-- Table Ends -->
      </div>
      <!-- Client section -->
      <div *ngIf="client == true" class="pt-2 sm-12">
        <h6 translate>CLIENT_KUNDE</h6>
        <div class="input-btn-group">
          <p-dropdown
            [options]="Client"
            #clientDropdown
            (onChange)="onClientChanged($event)"
            formControlName="Client"
            [style]="{ width: '100%' }"
          >
          </p-dropdown>
          <a
            *ngIf="!editClient"
            class="btn btn-outline-primary"
            routerLink="create"
            [routerLink]="['./../../client/new']"
            routerLinkActive="active"
          >
            <i class="pi pi-plus" aria-hidden="true"></i>
          </a>
          <a
            *ngIf="editClient"
            class="btn btn-outline-primary"
            routerLink="create"
            [routerLink]="['./../../../client/new']"
            routerLinkActive="active"
          >
            <i class="pi pi-plus" aria-hidden="true"></i>
          </a>
        </div>
      </div>
      <!-- Assign client table -->
      <div *ngIf="isClientSelected" class="pt-2 sm-12">
        <h6 translate>ASSIGN_CLIENT</h6>

        <!-- Table starts -->
        <p-table
          #client
          [value]="clientUsers"
          dataKey="ClientUserId"
          [(selection)]="selectedClient"
          scrollable="true"
          scrollHeight="300px"
        >
          <!-- Header -->
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 38px">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
              </th>
              <th>{{ "NAME" | translate }}</th>
            </tr>
            <tr>
              <th style="width: 38px"></th>
              <th>
                <input
                  pInputText
                  type="text"
                  (input)="
                    client.filter(
                      $any($event.target)?.value,
                      'Name',
                      'contains'
                    )
                  "
                  placeholder=""
                  class="p-column-filter"
                />
              </th>
            </tr>
          </ng-template>
          <!-- Body Content -->
          <ng-template pTemplate="body" let-project>
            <tr>
              <td style="width: 38px">
                <p-tableCheckbox id="selectedOrders" [value]="project">
                </p-tableCheckbox>
              </td>
              <td>
                <span>{{ project.Name }}</span>
              </td>
            </tr>
          </ng-template>
          <!-- Empty message -->
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td colspan="3">
                {{ "NO_RECORDS_FOUND" | translate }}
              </td>
            </tr>
          </ng-template>
        </p-table>
        <!-- Table Ends -->
      </div>
      <!-- Sub contractor section -->
      <div *ngIf="subContract == true" class="pt-2 sm-12">
        <h6 translate>SUB_CONTRACTOR</h6>
        <div class="input-btn-group">
          <p-multiSelect
            defaultLabel="{{ 'SELECT' | translate }}"
            [options]="SubContractorData"
            #subcontractorDropdown
            (onChange)="onSubContractorChanged($event)"
            formControlName="ContractorValues"
            [style]="{ width: '100%' }"
          >
            <ng-template let-subcontractor let-i="index" pTemplate="item">
              {{ i }}
              <span>{{ subcontractor.label }}</span>
            </ng-template>
          </p-multiSelect>
          <a
            *ngIf="editContact"
            class="btn btn-outline-primary"
            routerLink="create"
            [routerLink]="['./../../../contractor/create', 0]"
            routerLinkActive="active"
          >
            <i class="pi pi-plus" aria-hidden="true"></i>
          </a>
          <a
            *ngIf="!editContact"
            class="btn btn-outline-primary"
            routerLink="create"
            [routerLink]="['./../../contractor/create', 0]"
            routerLinkActive="active"
          >
            <i class="pi pi-plus" aria-hidden="true"></i>
          </a>
        </div>
      </div>
      <!-- Assign Sub contractor table -->
      <div *ngIf="isSubContractorSelected" class="pt-2 sm-12">
        <h6 translate>ASSIGN_SUBCONTRACTOR</h6>

        <!-- Table starts -->
        <p-table
          #subContractor
          [value]="projectSubContractor"
          dataKey="ContractorContactId"
          [(selection)]="selectedContractor"
          scrollable="true"
          scrollHeight="300px"
        >
          <!-- Header -->
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 38px">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
              </th>
              <th>{{ "NAME" | translate }}</th>
            </tr>
            <tr>
              <th style="width: 38px"></th>
              <th>
                <input
                  pInputText
                  type="text"
                  (input)="
                    subContractor.filter(
                      $any($event.target)?.value,
                      'Name',
                      'contains'
                    )
                  "
                  placeholder=""
                  class="p-column-filter"
                />
              </th>
            </tr>
          </ng-template>
          <!-- Body Content -->
          <ng-template pTemplate="body" let-project>
            <tr>
              <td style="width: 38px">
                <p-tableCheckbox id="selectedOrders" [value]="project">
                </p-tableCheckbox>
              </td>
              <td>
                <span>{{ project.Name }}</span>
              </td>
            </tr>
          </ng-template>
          <!-- Empty message -->
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td colspan="2">
                {{ "NO_RECORDS_FOUND" | translate }}
              </td>
            </tr>
          </ng-template>
        </p-table>
        <!-- Table Ends -->
      </div>
    </div>
  </div>
  <!-- Button section -->
  <div class="action-btns-wrapper">
    <button class="btn btn-outline-secondary" (click)="gotoBack()">
      <span class="icon ic-xs icon-back"></span>
      <span translate>BACK_LIST</span>
    </button>
    <button
      *ngIf="isAdministrateProject"
      type="submit"
      class="btn btn-primary"
      (click)="saveChanges('NOTNEW')"
      [disabled]="!newProjectForm.valid || loading"
    >
      <span translate>SAVE_AND_BACK</span>
    </button>
    <button
      *ngIf="isHideProject"
      class="btn btn-primary"
      type="submit"
      (click)="saveChanges('NEWPROJECT')"
      [disabled]="!newProjectForm.valid || loading"
    >
      <span translate>SAVE_CREATE_NEW</span>
    </button>
  </div>
  <!-- Copy content section Only show if new project-->
  <p-dialog
    header="{{ 'COPY_PROJECT' | translate }}"
    [(visible)]="displayDialog"
    [style]="{ width: '500px', height: '400px' }"
    [draggable]="false"
    showEffect="fade"
    [modal]="true"
    (onHide)="onCloseEvent()"
  >
    <div class="form-group">
      <label for="ChooseProject" translate>CHOOSE_PROJECT</label>
      <div>
        <p-dropdown
          [options]="ChooseProject"
          [filter]="true"
          formControlName="ChooseProject"
          [style]="{ width: '100%' }"
          [readonly]="IsHidden"
        ></p-dropdown>
      </div>
    </div>
    <div class="form-group">
      <p-checkbox
        value="CopySja"
        label="{{ 'COPY_SJA' | translate }}"
        formControlName="CopySJA"
        binary="true"
        [readonly]="IsHidden"
      >
      </p-checkbox>
      <p-checkbox
        value="CopySJAForm"
        label="{{ 'Copy_SJA_Form' | translate }}"
        formControlName="CopySJAForm"
        binary="true"
        [readonly]="IsHidden"
      ></p-checkbox>
    </div>
    <button class="btn btn-primary" (click)="onCloseEvent()">
      <span translate>CLOSE</span>
    </button>
  </p-dialog>
</form>
<toaster-component></toaster-component>
<p-progressBar
  mode="indeterminate"
  [style]="{ height: '6px' }"
  *ngIf="loading"
></p-progressBar>
