import {
  Component,
  OnInit,
  Input,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from '@angular/forms';
import {
  ActivatedRoute,
  Router,
  Params,
  NavigationStart,
} from '@angular/router';
import { Location } from '@angular/common';
import { SelectItem } from 'primeng/api';
import { UploadInput, UploaderOptions, UploadStatus } from 'ngx-uploader';
import { UploadFile, UploadOutput } from 'ngx-uploader';

import { BusinessProperty } from './../../businesses/models/businessProperty';
import { AdminUserService } from './../services/adminuser.service';
import { AlertService } from './../../../_services/alert.service';
import { TypeOfVehicleVehicleResolver } from './../../vehicle/services/vehicle.resolver.service';
import { CountryCode } from './../../Suppliers/Models/countryCode';
import { EmployeeServices } from './../../employees/services/employee.services';
import { ValidationService } from '../../shared/services/validation.service';
import { BusinessServices } from './../../businesses/services/business.services';
import { UserCredential } from './../../users/models/user';
import {
  AdminSaveUsers,
  AdminUserSignatureFile,
  AdminUserAvatarFile,
  AdminUser,
  UserAddressPropertyDto,
} from './../models/adminuser';
import { ToasterComponent } from '../../../_directives/toaster.component';
import { BaseServices } from '../../kuba.services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'adminuser-edit',
  templateUrl: 'adminuser-edit.component.html',
})
export class AdminUserEditComponent implements OnInit {
  //#region variable
  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  adminUserEditForm: FormGroup;
  updatePasswordForm: FormGroup;
  userCredentials = new UserCredential();
  id = 0;
  userId: number;
  adminUser: AdminUser;
  uploadRequest: any;
  users: any;
  businessPropId: any;
  isFileChoosed = false;
  files: UploadFile[] = [];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;
  options: UploaderOptions;
  userImage: string;
  showPasswordResetDialog = false;
  passwordValidation = false;
  cachedUserName: string;
  isNewUser = false;
  addressPropertyId: number;
  private subscriptions: Subscription[] = [];
  //#endregion

  //#region constructor
  constructor(
    private fb: FormBuilder,
    private userService: AdminUserService,
    private route: ActivatedRoute,
    public businessServices: BusinessServices,
    public employeeServices: EmployeeServices
  ) {
    this.id = this.route.snapshot.params['uid'];
  }
  //#endregion

  //#region region page-event
  ngOnInit() {
    document.querySelector('body').classList.remove('opened');
    this.uploadInput = new EventEmitter<UploadInput>();
    this.adminUserEditForm = this.fb.group({
      Name: [
        '',
        [Validators.required, ValidationService.noWhitespaceValidator],
      ],
      Username: [
        '',
        [Validators.required, ValidationService.noWhitespaceValidator],
        this.isUserNameUnique.bind(this),
      ],
      Email: ['', [Validators.required, Validators.email]],
      Mobile: ['', Validators.required],
      Address: [''],
      ZipCode: [''],
      PostalAddress: [''],
      PhoneCode: [2],
      UserType: [''],
      NewsLetter: [''],
      newPasswordValidation: this.fb.group({
        Password: [''],
        ConfirmPassword: [''],
      }),
    });
    this.updatePasswordForm = this.fb.group({
      passwordValidationGroup: this.fb.group(
        {
          Password: ['', [Validators.required]],
          ConfirmPassword: ['', [Validators.required]],
        },
        { validator: ValidationService.matchPassword }
      ),
    });

    this.userService.checkNewUser(false);
    let userData = this.route.snapshot.data['userData'];
    if (this.id) {
      if (userData) {
        this.addressPropertyId = userData ? userData.AddressId : 0;
        this.cachedUserName = userData.User.Username;
        this.userImage = userData.User.Avatar ? userData.User.Avatar : null;
        this.userService.getExistingImage(this.userImage);
        this.adminUserEditForm = this.fb.group({
          Name: userData.User.Name,
          Username: userData.User.Username,
          Email: userData.User.Email,
          NewsLetter: userData.User.IsNewsLetter,
          Mobile: userData.User.Mobile,
          PhoneCode: userData.User ? parseInt(userData.User.CountryCode) : 47,
          UserType: userData.User.RoleId,
          Address: userData.User.Address,
          ZipCode: userData.User.Zip,
          IsTOCApproved: userData.User.IsTOCApproved,
          PostalAddress: userData.User.PostalAddress
            ? userData.User.PostalAddress
            : '',
        });
      }
      this.businessPropId = userData ? userData.AddressId : 0;
    }
    this.userService.getImage$.subscribe((imagePath) => {
      this.userImage = imagePath ? imagePath : '';
    });
  }
  //#endregion

  //#region methods
  saveChanges() {
    this.subscriptions.push(
      this.route.params.subscribe((params: Params) => {
        let uid = params['uid'];
        this.id = +uid;
      })
    );
    let adminUser = new UserAddressPropertyDto();
    adminUser.UserId = this.id > 0 ? this.id : 0;
    adminUser.User.CreatedBy = BaseServices.UserId;
    adminUser.User.Name = this.adminUserEditForm.value.Name;
    adminUser.User.Email = this.adminUserEditForm.value.Email;
    adminUser.User.Username = this.adminUserEditForm.value.Username;
    adminUser.User.LanguageId = this.adminUserEditForm.value.LanguageId;
    adminUser.User.RoleId = 1;
    adminUser.User.ApplicationId = BaseServices.ApplicationId;
    adminUser.User.PostalAddress = this.adminUserEditForm.value.PostalAddress;
    adminUser.User.BusinessId = BaseServices.BusinessId;
    adminUser.User.IsNewsLetter = this.adminUserEditForm.value.NewsLetter;
    adminUser.User.Status = '1';
    adminUser.User.FeatureId = 4;
    adminUser.User.Mobile = this.adminUserEditForm.value.Mobile;
    adminUser.User.CountryCode = this.adminUserEditForm.value.PhoneCode
      ? this.adminUserEditForm.value.PhoneCode
      : 2;
    adminUser.User.Id = this.id > 0 ? this.id : 0;
    adminUser.User.Address = this.adminUserEditForm.value.Address;
    adminUser.User.Zip = this.adminUserEditForm.value.ZipCode;
    adminUser.AddressId = this.addressPropertyId ? this.addressPropertyId : 0;
    adminUser.User.Avatar = this.userImage;
    adminUser.User.IsTOCApproved = this.adminUserEditForm.value.IsTOCApproved;
    if (this.userImage) {
      this.userService.getUserProfileImagePath(this.userImage);
    }
    this.subscriptions.push(
      this.userService
        .updateAdminUser(adminUser.UserId, adminUser)
        .subscribe((isAdminUpdated) => {
          if (isAdminUpdated) {
            this.toasterComponent.callToast();
          }
        })
    );
  }
  /**
   * reset the form
   */
  resetForm() {
    this.id = 0;
    this.adminUserEditForm = this.fb.group({
      Name: '',
      Email: '',
      Avatar: '',
      newPasswordValidation: this.fb.group({
        Password: '',
        ConfirmPassword: '',
      }),
      Username: '',
      LanguageId: '',
      Status: '',
      Address: '',
      Mobile: '',
      PhoneCode: [47],
      PostalAddress: '',
      ZipCode: '',
      NewsLetter: '',
    });
  }
  /**
   * show the reset password feild
   */
  showResetPassword() {
    this.showPasswordResetDialog = true;
  }
  /**
   * user name validation
   */
  isUserNameUnique(control: FormControl) {
    const q = new Promise((resolve) => {
      setTimeout(() => {
        if (control.value === this.cachedUserName) {
          resolve(null);
        } else {
          this.subscriptions.push(
            this.employeeServices.getUserName(control.value).subscribe(
              (x) => {
                if (!x) {
                  resolve(null);
                } else {
                  resolve({ invalidUserName: true });
                }
              },
              () => {
                resolve({ invalidUserName: true });
              }
            )
          );
        }
      }, 1000);
    });
    return q;
  }
  /**
   * user name validation
   */
  isPasswordMatch() {
    const q = new Promise((resolve) => {
      setTimeout(() => {
        {
          resolve({ invalidPassWord: true });
        }
      }, 1000);
    });
    return q;
  }
  /**
   * reset the password
   */
  resetPassWord() {
    this.userCredentials.Id = this.id;
    this.userCredentials.Password =
      this.updatePasswordForm.value.passwordValidationGroup.Password;
    this.userCredentials.Password =
      this.updatePasswordForm.value.passwordValidationGroup.ConfirmPassword;
    this.subscriptions.push(
      this.businessServices
        .updatePassword(this.userCredentials)
        .subscribe((result) => {
          if (result === false) {
            this.passwordValidation = true;
          } else {
            this.toasterComponent.callToast();
            this.clear();
          }
        })
    );
  }
  /**
   * clear the password
   */
  clear() {
    this.showPasswordResetDialog = false;
    this.updatePasswordForm.reset();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
  //#endregion
}
