import { HelperService } from 'src/app/_services/helper.service';
import { UploaderConfig } from 'src/app/shared/ecpl-document-explorer/uploader-config';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';

import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { BaseServices } from './../../kuba.services';
import { ConfirmationService } from 'primeng/api';
import { SelectItem } from 'primeng/api';
import { ManualControlServices } from './../services/manual-control-services';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import {
  FoodControl,
  ObjectDto,
  Temperature,
  FoodControlDocumentDto
} from './../models/food-control';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { FoodControlList } from '../models/food-control-list';
import { Subscription } from 'rxjs';
@Component({
  selector: 'manual-control-form',
  templateUrl: 'manual-control-form.component.html'
})
export class ManualControlFormComponent implements OnInit {
  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  manualControlForm: FormGroup;
  temperatureForm: FormGroup;
  manualObjects: SelectItem[];
  manualModels: SelectItem[];
  brands: SelectItem[];
  TemperatureMin: SelectItem[];
  TemperatureMax: SelectItem[];
  minTemperature: any;
  maxTemperature: any;
  ObjectName: any;
  typeOfObject: SelectItem[];
  foodSafetyBrand: SelectItem[];
  model: SelectItem[];
  typeOfObj = new ObjectDto();
  objId: number;
  temperatureList: Temperature[];
  manualId: number;
  createdOn: any;
  parentId: number;
  manual: any;
  addTypeOfObject = false;
  addBrand = false;
  addModel = false;
  addTemperature = false;
  hidingElement = false;
  upConfig: UploaderConfig;
  uploadedFiles: any[] = [];
  additionalData: any;
  newDocumentAdded: any = [];
  isTemperatureAllocated = false;
  isTemperatureExists = false;
  isModelAllocated = false;
  isModelExists = false;
  isBrandAllocated = false;
  isBrandExists = false;
  isObjectAllocated = false;
  isObjectExists = false;
  parentKey: string;
  foodControlList: FoodControlList[];
  newUploadedFiles: any | FoodControlDocumentDto[] = [];
  orderNumber = 1;
  value: any;
  loading = false;
  private subscriptions: Subscription[] = [];
  Description: any;
  constructor(
    private location: Location,
    private fb: FormBuilder,
    private manualControlService: ManualControlServices,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private manualControlServices: ManualControlServices
  ) {
    this.parentId = route.snapshot.parent.parent.params['id'];
    this.parentKey = route.snapshot.parent.parent.data['parent'];

    this.manualControlForm = this.fb.group({
      OrderNumber: [this.orderNumber, [Validators.required]],
      objectName: ['', [Validators.required]],
      minTemp: ['', [Validators.required]],
      maxTemp: ['', [Validators.required]],
      Description: ['']
    });
  }

  ngOnInit() {
    document.querySelector('body').classList.remove('opened');
    this.additionalData = {
      ApplicationId: BaseServices.ApplicationId,
      BusinessId: BaseServices.BusinessId,
      ParentId: this.parentId,
      ChildId: 0,
      Status: '1',
      CreatedOn: new Date(),
      ModifiedBy: BaseServices.UserId,
      ModifiedOn: new Date(),
      ModuleName: 'Deviation'
    };
    this.upConfig = {
      title: 'MORE_ATTACHMENTS',
      titleAsLabel: true,
      editMode: true,
      windowedHeight: false,
      viewSwitch: false,
      showUserMeta: false,
      showSearchFilter: false,
      showUploadButton: true,
      showDeleteButton: true,
      uploaderUri: `${environment.BASE_URL}/${BaseServices.apiRoute(
        this.parentId
      )}foodsafety/file-upload`,
      addtionalData: this.additionalData
    };
    this.temperatureForm = this.fb.group({
      Name: [
        '',
        [Validators.required, ValidationService.checkAverageTemperature]
      ]
    });
    let list = this.route.snapshot.data['temperature'];
    if (list) {
      list.forEach(element => {
        element.Name = element.Name + '\xB0C';
      });
      this.temperatureList = list;
    }
    this.parentId = this.route.snapshot.parent.parent.params['id'];
    this.subscriptions.push(
      this.route.params.subscribe((params: Params) => {
        let mId = params['mId'];
        this.manualId = +mId;

        if (mId) {
          let result = this.route.snapshot.data['edit'];

          if (result) {
            if (result.FoodControlDocument) {
              result.FoodControlDocument.forEach(element => {
                this.uploadedFiles.push(
                  HelperService.switchKeysCase(element, null, 'L')
                );
              });
              this.uploadedFiles = result.FoodControlDocument.filter(
                x => x.status !== 0
              );
            }
            this.createdOn = result.CreatedOn;
            this.minTemperature = result.MinTemp;
            this.maxTemperature = result.MaxTemp;
            this.ObjectName = result.ObjectName;
            this.Description = result.Description;
            this.manual = {
              objectName: result.ObjectName,
              Description: result.Description,
              OrderNumber: result.OrderNumber,
              minTemp: +result.MinTemp,
              maxTemp: +result.MaxTemp
            };
            (<FormGroup>this.manualControlForm).setValue(this.manual, {
              onlySelf: true
            });
          }
        } else {
          this.subscriptions.push(
            this.manualControlServices
              .getManualControlByParent(this.parentId)
              .subscribe(result => {
                if (result) {
                  this.orderNumber += result.length;
                  this.manualControlForm.controls['OrderNumber'].setValue(
                    this.orderNumber
                  );
                }
              })
          );
        }
      })
    );

    this.TemperatureMin = [];
    this.TemperatureMin.push({
      label: this.minTemperature.toString() + '\xB0C',
      value: this.minTemperature
    });
    this.TemperatureMax = [];
    this.TemperatureMax.push({
      label: this.maxTemperature.toString() + '\xB0C',
      value: this.maxTemperature
    });
  }
  changemaxTemp() {
    this.TemperatureMax = [];
    let tempValue = -30;
    let maxtempValue = 100;
    if (tempValue != null) {
      this.subscriptions.push(
        this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
          for (let i = tempValue; i <= maxtempValue; i++) {
            this.TemperatureMax.push({
              label: i.toString() + '\xB0C',
              value: i
            });
          }
        })
      );
    }
  }
  changeminTemp() {
    this.TemperatureMin = [];
    let tempValue = -30;
    let maxtempValue = 100;
    if (tempValue != null) {
      this.subscriptions.push(
        this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
          for (let i = tempValue; i <= maxtempValue; i++) {
            this.TemperatureMin.push({
              label: i.toString() + '\xB0C',
              value: i
            });
          }
        })
      );
    }
  }
  /**
   * mobile number validation
   * @param e {any}
   * @param limitNumber {any}
   */
  onNumberChange(e: any) {
    HelperService.numberFieldValidationWithoutLimit(e);
  }
  Save() {
    this.loading == true;
    let foodControlObject = new FoodControl();
    foodControlObject.Id = this.manualId ? this.manualId : 0;
    foodControlObject.BusinessId = BaseServices.BusinessId;
    foodControlObject.Description = this.manualControlForm.value.Description;
    foodControlObject.FoodSafetyId = this.parentId;
    foodControlObject.ControlId = this.parentId;
    foodControlObject.Status = '1';
    foodControlObject.ObjectName = this.manualControlForm.value.objectName;
    foodControlObject.FoodControlDocument = this.newUploadedFiles;
    foodControlObject.OrderNumber = this.manualControlForm.value.OrderNumber;
    foodControlObject.MinTemp =
      this.manualControlForm.value.minTemp == ''
        ? 0
        : this.manualControlForm.value.minTemp;
    foodControlObject.MaxTemp =
      this.manualControlForm.value.maxTemp == ''
        ? 0
        : this.manualControlForm.value.maxTemp;
    foodControlObject.CreatedBy = BaseServices.UserId;
    foodControlObject;
    if (this.manualId > 0) {
      foodControlObject.CreatedOn = this.createdOn;
      this.subscriptions.push(
        this.manualControlService
          .updateManualControl(foodControlObject)
          .subscribe(result => {
            this.toasterComponent.callToast();
            this.loading = false;
            this.saveAndClear();
          })
      );
    } else {
      this.subscriptions.push(
        this.manualControlService
          .createManualControl(foodControlObject)
          .subscribe((res: any) => {
            this.manualId = res.Id;
            this.toasterComponent.callToast();
            this.loading = false;
            this.saveAndClear();
          })
      );
    }
  }

  /**
   * file upload events
   * @param event {any}
   */
  saveUploaded(event: any) {
    if (event) {
      let eventType = event.eventName;
      switch (eventType) {
        case 'DeleteDefaultFile':
          if (event.item.data.id === 0) {
            // remove unsaved attachment
            this.newUploadedFiles = this.newUploadedFiles.filter(
              x => x.Path !== event.item.data.path
            );
            this.uploadedFiles = this.uploadedFiles.filter(
              x => x.path !== event.item.data.path
            );
            this.toasterComponent.callToastDlt();
          } else {
            //  remove existing attachement
            this.subscriptions.push(
              this.manualControlService
                .deleteFoodControlAttachment(event.item.data.id)
                .subscribe((result: any) => {
                  if (result) {
                    // remove from display list
                    this.uploadedFiles = this.uploadedFiles.filter(
                      x => x.path !== event.item.data.path
                    );
                    this.toasterComponent.callToastDlt();
                  }
                })
            );
          }
          break;
        default:
          let keyBindings = {
            Originalname: 'OriginalFileName',
            FileTypeId: 'FileType'
          };
          let deviationDoc = Object.assign({}, event, this.additionalData);
          deviationDoc.id = 0;
          deviationDoc.createdBy = BaseServices.UserId;
          deviationDoc.status = 1;
          this.newUploadedFiles.push(
            HelperService.switchKeysCase(deviationDoc, keyBindings)
          );
          break;
      }
    }
  }
  saveFoodSafety() {
    this.Save();
  }

  saveAndClear() {
    setTimeout(() => {
      this.clear();
    }, 1000);
  }
  clear() {
    this.manualId = 0;
    this.manualControlForm = this.fb.group({
      OrderNumber: this.manualControlServices
        .getManualControlByParent(this.parentId)
        .subscribe(result => {
          if (result) {
            this.orderNumber = 1;
            this.orderNumber += result.length;
            this.manualControlForm.controls['OrderNumber'].setValue(
              this.orderNumber
            );
          }
        }),
      objectName: '',
      minTemp: '',
      maxTemp: '',
      Description: ''
    });
  }

  clearform() {
    this.uploadedFiles = [];
  }

  clearTemp() {
    this.objId = 0;
    this.temperatureForm = this.fb.group({
      Name: ''
    });
  }

  editTemperature(typeOfObj: ObjectDto) {
    this.objId = typeOfObj.Id;
    let name = typeOfObj.Name.replace('\xB0C', '');
    let result = {
      Name: name
    };
    (<FormGroup>this.temperatureForm).setValue(result, { onlySelf: true });
  }

  gotoBack() {
    setTimeout(() => {
      this.location.back();
    }, 1000);
  }
  onTemperatureFormHide() {
    this.temperatureForm.reset();
  }

  onOrderNumber(e: any, limitNumber: any) {
    const limit = limitNumber;
    let charCode = e.which ? e.which : e.keyCode;
    if (
      e.target.value.length === limit ||
      (charCode > 31 && (charCode < 48 || charCode > 57))
    ) {
      e.preventDefault();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}
