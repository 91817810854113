<div class="tab-content" id="courseDetailPrint">
  <div class="page-title">
    <ng-container
      *ngIf="isEmployeecourses === true; then first; else second"
    ></ng-container>
    <ng-template #first><span translate>COURSE_DETAILS</span></ng-template>
    <ng-template #second><span translate>CONTRACT</span></ng-template>
  </div>
  <div [formGroup]="courseDetailForm" novalidate>
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label for="CName">
            <ng-container
              *ngIf="
                isEmployeecourses === true;
                then coursename;
                else contractname
              "
            >
            </ng-container>
            <ng-template #coursename
              ><span translate>COURSE_NAME</span></ng-template
            >
            <ng-template #contractname
              ><span translate>CONTRACT_NAME</span></ng-template
            >
            <span class="required">*</span>
          </label>
          <div class="input-btn-group">
            <!-- COURSE & CONTRACT NAME DROPDOWN -->
            <p-dropdown
              [formControl]="courseDetailForm.controls['BusinessCourseId']"
              [filter]="true"
              class="ex-full-width"
              [options]="courseNames"
              [style]="{ width: '100%' }"
              placeholder="{{ 'SELECT' | translate }}"
            ></p-dropdown>
            <button
              *ngIf="isPortalBusiness"
              class="btn btn-link"
              type="button"
              (click)="showDialog()"
            >
              <span class="icon ic-md icon-info"></span>
            </button>
            <button
              *ngIf="
                !isPortalBusiness &&
                !IsCommonPortalCourseNames &&
                isNewCourseNameRights
              "
              class="btn btn-outline-primary"
              type="button"
              (click)="addCourse = !addCourse"
            >
              <i class="pi pi-plus" aria-hidden="true"></i>
            </button>
          </div>
          <control-messages
            [control]="courseDetailForm.controls['BusinessCourseId']"
          ></control-messages>
        </div>
        <div class="form-group">
          <label for="Institute">
            <span *ngIf="isEmployeecourses == true" translate>INSTITUTE</span>
            <span *ngIf="isEmployeecourses == false" translate>INSTITUTES</span>
          </label>
          <div>
            <input
              type="text"
              pInputText
              formControlName="Institute"
              id="Institute"
            />
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label class="form-col-form-label" for="startDate">
                  <span *ngIf="isEmployeecourses == true" translate
                    >START_DATE</span
                  >
                  <span *ngIf="isEmployeecourses == false" translate
                    >START_DATE1</span
                  >
                </label>
                <span *ngIf="hasChildren">
                  <i
                    class="pi pi-info-circle"
                    aria-hidden="true"
                    tooltip="This is driven by sub-activities"
                  ></i>
                </span>
                <div>
                  <my-date-picker
                    name="startdate"
                    [options]="startDateOptions"
                    formControlName="StartDate"
                    placeholder="{{ 'SELECT_START_DATE' | translate }}"
                    (dateChanged)="onStartDateChanged($event)"
                    [selDate]="selDate"
                  >
                  </my-date-picker>
                </div>
                <control-messages
                  [control]="courseDetailForm.controls['StartDate']"
                ></control-messages>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label class="form-col-form-label" for="endDate">
                  <span *ngIf="isEmployeecourses == true" translate
                    >END_DATE</span
                  >
                  <span *ngIf="isEmployeecourses == false" translate
                    >END_DATE1</span
                  >
                </label>
                <div>
                  <my-date-picker
                    name="enddate"
                    [style]="{ width: '160px' }"
                    [options]="endDateOptions"
                    placeholder="{{ 'SELECT_END_DATE' | translate }}"
                    formControlName="EndDate"
                    (dateChanged)="onEndDateChanged($any($event))"
                    [selDate]="selDate"
                  >
                  </my-date-picker>
                </div>
                <control-messages
                  [control]="courseDetailForm.controls['EndDate']"
                ></control-messages>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="Renewaldate">
            <span translate>RENEWAL_DATE</span>
          </label>
          <div>
            <my-date-picker
              name="Renewaldate"
              [options]="renewalDateOptions"
              placeholder="{{ 'RENEWAL_DATE' | translate }}"
              formControlName="Renewaldate"
            >
            </my-date-picker>
          </div>
          <control-messages
            [control]="courseDetailForm.controls['Renewaldate']"
          ></control-messages>
        </div>
        <div class="form-group">
          <label for="ResponsibleId">
            <span *ngIf="isEmployeecourses == true" translate
              >RESPONSIBLE_FOR_EXECUTION</span
            >
            <span *ngIf="isEmployeecourses == false" translate
              >RESPONSIBLE_FOR_EXECUTIONS</span
            >
          </label>
          <div>
            <p-dropdown
              [options]="responsibleExcecution"
              [filter]="true"
              [formControl]="courseDetailForm.controls['ResponsibleId']"
              id="ResponsibleId"
              [style]="{ width: '100%' }"
            ></p-dropdown>
          </div>
          <control-messages
            [control]="courseDetailForm.controls['ResponsibleId']"
          ></control-messages>
        </div>
        <div class="form-group">
          <label for="DeadlineNotification">
            <span *ngIf="isEmployeecourses == true" translate
              >NOTIFICATION_BEFORE_DEADLINE_COURSE</span
            >
            <span *ngIf="isEmployeecourses != true" translate
              >NOTIFICATION_BEFORE_DEADLINE_CONTRACT</span
            >
          </label>
          <div>
            <input
              type="number"
              pInputText
              formControlName="DeadlineNotification"
              id="Deadline"
            />
          </div>
          <control-messages
            [control]="courseDetailForm.controls['DeadlineNotification']"
          ></control-messages>
        </div>
        <div class="form-group">
          <label for="Description">
            <span translate>DESCRIPTION</span>
          </label>
          <div>
            <textarea
              pInputTextarea
              formControlName="Description"
              id="Description"
            ></textarea>
          </div>
          <control-messages
            [control]="courseDetailForm.controls['Description']"
          ></control-messages>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <div>
            <ecpl-document-explorer
              [uploaderConfig]="config"
              [uploadedFiles]="uploadedFiles"
              (saveChanges)="saveUploaded($event)"
            >
            </ecpl-document-explorer>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="action-btns-wrapper">
    <div class="row">
      <div class="col-sm-6">
        <a class="btn btn-outline-secondary" (click)="clear()" role="button">
          <span translate>CLEAR</span>
        </a>
        <button
          class="btn btn-primary"
          type="submit"
          (click)="saveChanges()"
          [disabled]="!courseDetailForm.valid"
        >
          <span translate>SAVE_CHANGES</span>
        </button>
      </div>
    </div>
  </div>

  <div class="table-view">
    <!-- Table starts -->
    <p-table
      #employeeCourseTable
      [value]="employeeCourseArray"
      [rows]="10"
      [paginator]="true"
      [pageLinks]="3"
      [rowsPerPageOptions]="[5, 10, 20]"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{{ 'SUMMARY_TOTALRECORDS' | translate }}"
    >
      <!-- Caption -->
      <ng-template pTemplate="caption">
        <div class="ui-helper-clearfix">
          <div class="table-options">
            <div class="pull-left"></div>
            <div class="pull-right">
              <div class="action-btns-group">
                <kuba-export
                  [reportData]="
                    employeeCourseTable.filteredValue ||
                    employeeCourseTable.value
                  "
                  [additionalData]="additionalData"
                ></kuba-export>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <!-- Header -->
      <ng-template *ngIf="isEmployeecourses == true" pTemplate="header">
        <tr>
          <th pSortableColumn="Course">
            {{ "COURSE_NAME" | translate }}
            <p-sortIcon field="Course"></p-sortIcon>
          </th>
          <th pSortableColumn="Institute">
            {{ "INSTITUTE" | translate }}
            <p-sortIcon field="Institute"></p-sortIcon>
          </th>
          <th pSortableColumn="StartDate" width="110px">
            {{ "START_DATE" | translate }}
            <p-sortIcon field="StartDate"></p-sortIcon>
          </th>
          <th pSortableColumn="EndDate" width="110px">
            {{ "END_DATE" | translate }}
            <p-sortIcon field="EndDate"></p-sortIcon>
          </th>
          <th pSortableColumn="Renewaldate" width="120px">
            {{ "RENEWAL_DATE" | translate }}
            <p-sortIcon field="Renewaldate"></p-sortIcon>
          </th>
          <th pSortableColumn="ResponsiblepersonName">
            {{ "RESPONSIBLE_FOR_EXECUTION" | translate }}
            <p-sortIcon field="ResponsiblepersonName"></p-sortIcon>
          </th>
          <th width="100px">{{ "ATTACHMENTS" | translate }}</th>
          <th width="110px">{{ "OPTIONS" | translate }}</th>
        </tr>
      </ng-template>
      <!-- Body Content -->
      <ng-template
        *ngIf="isEmployeecourses == true"
        pTemplate="body"
        let-course
      >
        <tr>
          <td>
            <span>{{ course.Course }}</span>
          </td>
          <td>
            <span>{{ course.Institute }}</span>
          </td>
          <td>
            <span>{{ course.StartDate | date : "dd/MM/yyyy" }}</span>
          </td>
          <td>
            <span>{{ course.EndDate | date : "dd/MM/yyyy" }}</span>
          </td>
          <td>
            <span>{{ course.Renewaldate | date : "dd/MM/yyyy" }}</span>
          </td>
          <td>
            <span>{{ course.ResponsiblepersonName }}</span>
          </td>
          <td class="col-button">
            <button
              *ngIf="course.EmployeeDocuments.length > 0"
              tooltipPosition="top"
              class="btn btn-icon"
              title="{{ 'Testing' | translate }}"
              (click)="ViewDocument(course.EmployeeDocument)"
            >
              <i class="icon ic-sm icon-attach" aria-hidden="true"></i>
            </button>
          </td>
          <td>
            <button
              type="button"
              class="btn btn-icon"
              title="{{ 'EDIT' | translate }}"
              (click)="edit(course.Id)"
            >
              <i class="icon ic-sm icon-pencil-tip"></i>
            </button>
            <button
              type="button"
              class="btn btn-icon"
              title="{{ 'DELETE' | translate }}"
              (click)="delete(course['Id'])"
            >
              <i class="icon ic-sm icon-trash"></i>
            </button>
          </td>
        </tr>
      </ng-template>
      <!-- Header -->
      <ng-template *ngIf="isEmployeecourses == false" pTemplate="header">
        <tr>
          <th pSortableColumn="Course">
            {{ "CONTRACT_NAME" | translate }}
            <p-sortIcon field="Course"></p-sortIcon>
          </th>
          <th pSortableColumn="Institute">
            {{ "INSTITUTE1" | translate }}
            <p-sortIcon field="Institute"></p-sortIcon>
          </th>
          <th pSortableColumn="StartDate">
            {{ "START_DATE1" | translate }}
            <p-sortIcon field="StartDate"></p-sortIcon>
          </th>
          <th pSortableColumn="EndDate">
            {{ "END_DATE1" | translate }}
            <p-sortIcon field="EndDate"></p-sortIcon>
          </th>
          <th pSortableColumn="Renewaldate">
            {{ "RENEWAL_DATE" | translate }}
            <p-sortIcon field="Renewaldate"></p-sortIcon>
          </th>
          <th pSortableColumn="ResponsiblepersonName">
            {{ "RESPONSIBLE_FOR_EXECUTIONS" | translate }}
            <p-sortIcon field="ResponsiblepersonName"></p-sortIcon>
          </th>
          <th width="100px">{{ "ATTACHMENTS" | translate }}</th>
          <th width="110px">{{ "OPTIONS" | translate }}</th>
        </tr>
      </ng-template>
      <!-- Body Content -->
      <ng-template
        *ngIf="isEmployeecourses == false"
        pTemplate="body"
        let-contract
      >
        <tr>
          <td>
            <span>{{ contract.Course }}</span>
          </td>
          <td>
            <span>{{ contract.Institute }}</span>
          </td>
          <td>
            <span>{{ contract.StartDate | date : "dd/MM/yyyy" }}</span>
          </td>
          <td>
            <span>{{ contract.EndDate | date : "dd/MM/yyyy" }}</span>
          </td>
          <td>
            <span>{{ contract.Renewaldate | date : "dd/MM/yyyy" }}</span>
          </td>
          <td>
            <span>{{ contract.ResponsiblepersonName }}</span>
          </td>
          <td class="col-button">
            <button
              *ngIf="contract.EmployeeDocuments.length > 0"
              tooltipPosition="top"
              class="btn btn-icon"
              title="{{ 'Testing' | translate }}"
              (click)="ViewDocument(contract.EmployeeDocument)"
            >
              <i class="icon ic-sm icon-attach" aria-hidden="true"></i>
            </button>
          </td>
          <td>
            <button
              type="button"
              class="btn btn-icon"
              title="{{ 'EDIT' | translate }}"
              (click)="edit(contract.Id)"
            >
              <i class="icon ic-sm icon-pencil-tip"></i>
            </button>
            <button
              type="button"
              class="btn btn-icon"
              title="{{ 'DELETE' | translate }}"
              (click)="delete(contract['Id'])"
            >
              <i class="icon ic-sm icon-trash"></i>
            </button>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <!-- Table ends -->
  </div>
  <p-dialog
    header="{{ 'ik.Kuba.no says:' | translate }}"
    [(visible)]="dialogDisplay"
    [style]="{ width: '300px' }"
    [resizable]="false"
    [draggable]="false"
  >
    <ng-container
      *ngIf="isEmployeecourses === true; then newCourse; else newContract"
    ></ng-container>
    <ng-template #newCourse
      ><span translate>NEW_COURSE_ADDED_PORTAL_ADMIN_EDITORS</span></ng-template
    >
    <ng-template #newContract
      ><span translate
        >NEW_CONTRACT_ADDED_PORTAL_ADMIN_EDITORS</span
      ></ng-template
    >
  </p-dialog>

  <p-dialog
    *ngIf="isEmployeecourses == true"
    header="{{ 'ADD_COURSE' | translate }}"
    [(visible)]="addCourse"
    [style]="{ width: '700px', height: '500px' }"
    [resizable]="false"
    [draggable]="false"
    (onHide)="onBeforeCourseDialogHide()"
  >
    <form [formGroup]="coursePopup">
      <div class="form-group">
        <label for="title" class="col-form-label">
          <span translate>NAME</span>
          <span class="required">*</span>
        </label>
        <div>
          <input
            type="text"
            #title
            class="label-input form-control"
            formControlName="Course"
            maxlength="100"
          />
          <span style="color: red" *ngIf="isNameExists">{{
            existsAlerttext
          }}</span>
        </div>
      </div>
      <p-footer>
        <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
          <button
            type="button"
            class="btn btn-outline-primary"
            (click)="viewCourse = !viewCourse"
          >
            <span translate>VIEW</span>
          </button>
          <button
            type="button"
            class="btn btn-primary"
            [disabled]="!coursePopup.valid"
            (click)="savecourse()"
          >
            <span translate>SAVE</span>
          </button>
        </div>
        <div *ngIf="viewCourse">
          <div class="table-view">
            <!-- Table starts -->
            <p-table
              #courseTable
              [value]="courseList"
              [rows]="10"
              [paginator]="true"
              [pageLinks]="3"
              [rowsPerPageOptions]="[5, 10, 20]"
              [responsive]="true"
            >
              <!-- Header -->
              <ng-template pTemplate="header">
                <tr>
                  <th>{{ "COURSE_NAME" | translate }}</th>
                  <th></th>
                </tr>
              </ng-template>
              <!-- Body Content -->
              <ng-template pTemplate="body" let-course>
                <tr>
                  <td>
                    <span>{{ course.Title }}</span>
                  </td>
                  <td>
                    <button
                      type="button"
                      title="{{ 'EDIT' | translate }}"
                      class="btn btn-icon"
                      (click)="editCourse(course)"
                    >
                      <i class="icon ic-sm icon-pencil-tip"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-icon"
                      title="{{ 'DELETE' | translate }}"
                      (click)="deleteCourse(course)"
                    >
                      <i class="icon ic-sm icon-trash"></i>
                    </button>
                  </td>
                </tr>
              </ng-template>
            </p-table>
            <!-- Table ends -->
          </div>
        </div>
      </p-footer>
    </form>
  </p-dialog>
  <p-dialog
    *ngIf="isEmployeecourses == false"
    header="{{ 'ADD_CONTRACT' | translate }}"
    [(visible)]="addCourse"
    [style]="{ width: '700px', height: '500px' }"
    [resizable]="false"
    [draggable]="false"
    (onHide)="onBeforeCourseDialogHide()"
  >
    <form [formGroup]="coursePopup">
      <div class="form-group">
        <label for="title" class="col-form-label">
          <span translate>NAME</span>
          <span class="required">*</span>
        </label>
        <div>
          <input
            type="text"
            #title
            class="label-input form-control"
            formControlName="Course"
            maxlength="100"
          />
          <span style="color: red" *ngIf="isNameExists">{{
            existsAlerttext
          }}</span>
        </div>
      </div>
      <p-footer>
        <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
          <button
            type="button"
            class="btn btn-outline-primary"
            (click)="viewCourse = !viewCourse"
          >
            <span translate>VIEW</span>
          </button>
          <button
            type="button"
            class="btn btn-primary"
            [disabled]="!coursePopup.valid"
            (click)="savecourse()"
          >
            <span translate>SAVE</span>
          </button>
        </div>
        <div *ngIf="viewCourse">
          <div class="table-view">
            <!-- Table starts -->
            <p-table
              #courseTable
              [value]="courseList"
              [rows]="10"
              [paginator]="true"
              [pageLinks]="3"
              [rowsPerPageOptions]="[5, 10, 20]"
              [responsive]="true"
            >
              <!-- Header -->
              <ng-template pTemplate="header">
                <tr>
                  <th>{{ "CONTRACT_NAME" | translate }}</th>
                  <th></th>
                </tr>
              </ng-template>
              <!-- Body Content -->
              <ng-template pTemplate="body" let-contract>
                <tr>
                  <td>
                    <span>{{ contract.Title }}</span>
                  </td>
                  <td>
                    <button
                      type="button"
                      title="{{ 'EDIT' | translate }}"
                      class="btn btn-icon"
                      (click)="editCourse(contract)"
                    >
                      <i class="icon ic-sm icon-pencil-tip"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-icon"
                      title="{{ 'DELETE' | translate }}"
                      (click)="deleteCourse(contract)"
                    >
                      <i class="icon ic-sm icon-trash"></i>
                    </button>
                  </td>
                </tr>
              </ng-template>
            </p-table>
            <!-- Table ends -->
          </div>
        </div>
      </p-footer>
    </form>
  </p-dialog>
  <p-confirmDialog
    header="{{ 'CONFIRMATION' | translate }}"
    icon="pi pi-question-circle"
    width="425"
    acceptLabel="{{ 'SWITCH_YES' | translate }}"
    rejectLabel="{{ 'SWITCH_NO' | translate }}"
  ></p-confirmDialog>
  <toaster-component></toaster-component>
</div>
<!-- Does not work currently -->
<p-dialog
  *ngIf="isEmployeecourses"
  header="{{ 'ATTACHMENTS' | translate }}"
  [(visible)]="viewDocument"
  [style]="{ width: '600px', height: '600px' }"
  [resizable]="false"
  [draggable]="false"
>
  <ecpl-document-explorer
    [uploaderConfig]="upConfig"
    [uploadedFiles]="uploadedFiles"
    (saveChanges)="saveUploaded($event)"
  >
  </ecpl-document-explorer>
</p-dialog>
