import { NotificationSubscription } from './../../../shared/employee-contact';
export class ProjectEdit {
  Id: number;
  BusinessId: number;
  Number: string;
  Title: string;
  StartDate: string;
  ExpectedEndDate: string;
  ActualEndDate: string;
  OrderNumber: string;
  Ssbnumber: string;
  Description: string;
  ProjectImage: string;
  ProjectCost: string;
  Status: string;
  Client: number;
  CreatorName: string;
  ModifierName: string;
  CreatedOn: Date;
  ModifiedOn: Date;
  CreatedBy: number;
  ModifiedBy: number;
  ProjectTemplateId: number;
  IsHiddenProject: boolean;
  CopySJA: string;
  CopySJAForm: boolean;
  Contractor: Contractor[];
  ContactPerson: ContactPerson[];
  ProfessionalManager: ProfessionalManager[];
  ApplicationResponsible: ApplicationResponsible[];
  ProjectManager: ProjectManager[];
  ProjectPlan: ProjectPlan[];
  ProjectExecution: ProjectExecution[];
  IndependentControl: IndependentControl[];
  SafetyRepresentatives: SafetyRepresentatives[];
  ConstructionManager: ConstructionManager[];
  CoordinatorProjecting: CoordinatorProjecting[];
  CoordinatorExecution: CoordinatorExecution[];
  ProjectBuilder: ProjectBuilder[];
  ProjectResponsibility: ProjectResponsibility[];
  ProjectUsers: ProjectUsers[];
  SubContractorUsers: SubContractorUser[];
  ClientUsers: ClientUser[];
  ProjectEmailSubscribers: NotificationSubscription[];
  ProjectSMSSubscribers: NotificationSubscription[];
  IsELThreedigit: any;
  IsELFourdigit: any;
  IsELForvaltning: any;
  ISVVSFourdigit: any;
  ISVVSThreedigit: any;
  IsVVSForvaltning: any;
  ShowDeviation?: any;
}

export class ProjectUsers {
  ProjectId: number;
  UserId: number;
  Name: string;
}

export class SubContractorUser {
  ProjectId: number;
  ContractorContactId: number;
  ContractorId: number;
  Name: string;
}
export class Contractor {
  ProjectId: number;
  ContractorId: number;
  Name: string;
}

export class ClientUser {
  ProjectId: number;
  ClientId: number;
  ClientUserId: number;
  Name: string;
}

export class ContactPerson {
  ProjectId: number;
  ContactPersonId: number;
  Name: string;
  Mobile: string;
  EmailId: string;
}

export class ProfessionalManager {
  ProjectId: number;
  ProfessionalManagerId: number;
  Name?: string;
}

export class ProjectManager {
  ProjectId: number;
  ProjectManagerId: number;
  Name?: string;
}

export class ProjectPlan {
  ProjectId: number;
  ProjectPlanId: number;
  Name?: string;
}

export class ProjectExecution {
  ProjectId: number;
  ProjectExecutionId: number;
  Name?: string;
}

export class IndependentControl {
  ProjectId: number;
  IndependentControlId: number;
  Name?: string;
}

export class SafetyRepresentatives {
  ProjectId: number;
  SafetyRepresentativesId: number;
  Name?: string;
}

export class ConstructionManager {
  ProjectId: number;
  ConstructionManagerId: number;
  Name?: string;
}

export class CoordinatorProjecting {
  ProjectId: number;
  CoordinatorProjectingId: number;
  Name?: string;
}

export class CoordinatorExecution {
  ProjectId: number;
  CoordinatorExecutionId: number;
  Name?: string;
}

export class ProjectBuilder {
  ProjectId: number;
  BuilderId: 0;
  Name?: string;
}
export class ApplicationResponsible {
  ProjectId: number;
  ApplicationResponsibleId: number;
  Name: string;
}

export class PropertyObject {
  Id: number;
  Name: string;
  Quantity: number;
}
export class ProjectResponsibility {
  ProjectId: number;
  ProjectResponsibilityId: 0;
  Name: string;
}
export class Client {
  Id: number;
  Name: string;
}

export class ResponsibilityDetail {
  Name: string;
  constructor() {
    this.Name = '';
  }
}
export class ProjectDetail {
  Number: string;
  Title: string;
  StartDate: string;
  ExpectedEndDate: string;
  ActualEndDate: string;
  OrderNumber: string;
  Ssbnumber: string;
  Description: string;
  Status: string;
  ProjectCost: string;
  Address: string;
  City: string;
  ProjectResponsibility: ResponsibilityDetail[];
  Contractor: Contractor[];
  ContactPerson: ContactPerson[];
  ProfessionalManager: ProfessionalManager[];
  ApplicationResponsible: ApplicationResponsible[];
  ProjectManager: ProjectManager[];
  ProjectPlan: ProjectPlan[];
  ProjectExecution: ProjectExecution[];
  IndependentControl: IndependentControl[];
  SafetyRepresentatives: SafetyRepresentatives[];
  ConstructionManager: ConstructionManager[];
  CoordinatorProjecting: CoordinatorProjecting[];
  CoordinatorExecution: CoordinatorExecution[];
  ProjectBuilder: ProjectBuilder[];
  ProjectUsers: ProjectUsers[];
  SubContractorUsers: SubContractorUser[];
  ClientUsers: ClientUser[];
  ProjectImage: string;
  constructor() {
    this.Number = '';
    this.Title = '';
    this.StartDate = '';
    this.ExpectedEndDate = '';
    this.ActualEndDate = '';
    this.OrderNumber = '';
    this.Ssbnumber = '';
    this.Description = '';
    this.Status = '';
    this.ProjectCost = '';
    this.Contractor = [];
    this.ContactPerson = [];
    this.ProfessionalManager = [];
    this.ApplicationResponsible = [];
    this.ProjectManager = [];
    this.ProjectPlan = [];
    this.ProjectExecution = [];
    this.IndependentControl = [];
    this.SafetyRepresentatives = [];
    this.ConstructionManager = [];
    this.CoordinatorProjecting = [];
    this.CoordinatorExecution = [];
    this.ProjectBuilder = [];
    this.ProjectUsers = [];
    this.ProjectResponsibility = [];
    this.SubContractorUsers = [];
    this.ClientUsers = [];
    this.ProjectImage = '';
  }
}
