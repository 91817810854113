import { ActivityServices } from './activities.services';
import { DeviationServices } from './deviation.services';
import { DeviationTopic } from '../../deviation/models/deviationTopic';
import { Department, UserRole } from './../models/deviation';
import { BaseServices } from './../../kuba.services';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
    Resolve,
    ActivatedRouteSnapshot,
    ActivatedRoute
} from '@angular/router';
import { BusinessDepartmentService } from './../../business-department.services';
import { RiskanalysisService } from './risk-analysis.service';
import {
    Riskanalysis,
    ApprovalNotices,
    Business
} from './../models/riskanalysis';
import { ProjectServices } from 'src/app/kuba/projects/services/project.service';
import { Category } from 'src/app/kuba/businesses/models';

@Injectable()
export class RiskAnalysisListResolver implements Resolve<Riskanalysis[]> {
    constructor(private backend: RiskanalysisService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<Riskanalysis[]> {
        return this.backend.list();
    }
}

@Injectable()
export class RiskAnalysisEditResolver implements Resolve<Riskanalysis> {
    constructor(private backend: RiskanalysisService) { }
    resolve(route: ActivatedRouteSnapshot) {
        let riskId = +route.params['rid'];
        return this.backend.getById(riskId);
    }
}
@Injectable()
export class FollowupDepartmentForAdminResolver implements Resolve<Department[]> {
    constructor(private backend: DeviationServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<Department[]> {
        let riskId = +route.params['rid'];
        return this.backend.getDepartmentsForAdmin(riskId);
    }
}
@Injectable()
export class RiskAnalysisDepartmentResolver implements Resolve<Department[]> {
    constructor(private backend: BusinessDepartmentService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<Department[]> {
        return this.backend.getCachedBusinessDepartment();
    }
}
@Injectable()
export class RiskAnalysisTopicForAdminResolver implements Resolve<DeviationTopic[]> {
    constructor(private backend: DeviationServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<DeviationTopic[]> {
        let riskId = +route.params['rid'];
        return this.backend.getTopicsForAdmin(riskId);
    }
}
@Injectable()
export class RiskAnalysisTopicCachedResolver implements Resolve<DeviationTopic[]> {
    constructor(private backend: RiskanalysisService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<DeviationTopic[]> {
        if (+BaseServices.roleId === UserRole.PORTAL) {
            return this.backend.getTopicByPortal(BaseServices.PortalId);
        } else if (+BaseServices.roleId === UserRole.ADMIN){
            return null;
        } else {
            return this.backend.getTopicByBusiness(BaseServices.BusinessId);
        }
    }
}
@Injectable()
export class ApprovalNoticesResolver implements Resolve<any[]> {
    constructor(
        private backend: RiskanalysisService,
        private router: ActivatedRoute
    ) { }
    resolve(route: ActivatedRouteSnapshot) {
        if (BaseServices.roleId === '1' || BaseServices.roleId === '2') {
        } else {
            return this.backend.getApprovalNoticeByBusiness();
        }
    }
}
@Injectable()
export class BusinessesListResolver implements Resolve<Business[]> {
    constructor(
        private backend: RiskanalysisService,
        private router: ActivatedRoute
    ) { }
    resolve(route: ActivatedRouteSnapshot): Observable<Business[]> {
        return this.backend.getBusinesses();
    }
}
@Injectable()
export class RiskAnalysisLogResolver implements Resolve<any[]> {
    constructor(private backend: RiskanalysisService) { }
    resolve(route: ActivatedRouteSnapshot) {
        let riskId = +route.params['rid'];
        return this.backend.getRiskAnalysisLog(riskId);
    }
}
@Injectable()
export class RiskAnalysisAdminResolver implements Resolve<any[]> {
    constructor(private backend: RiskanalysisService) { }
    resolve(route: ActivatedRouteSnapshot) {
        return this.backend.getRiskAnalysisForAdmin(BaseServices.ApplicationId, 1);
    }
}
@Injectable()
export class RiskAttachmentResolver implements Resolve<any[]> {
    constructor(private backend: RiskanalysisService) { }
    resolve(route: ActivatedRouteSnapshot) {
        let riskId = +route.params['rid'];
        return this.backend.getRiskAttachmentByRisk(riskId);
    }
}
@Injectable()
export class UserForAdminResolver implements Resolve<any> {
    constructor(private backend: RiskanalysisService) { }
    resolve(route: ActivatedRouteSnapshot) {
        return this.backend.getUserForAdminRisk(+route.params['rid']);
    }
}

@Injectable()
export class RiskAnalysisCategoryResolver implements Resolve<Category[]> {
    constructor(private backend: RiskanalysisService) { }
    resolve(route: ActivatedRouteSnapshot) {
        let businessId: number;
        if(BaseServices.roleId == '1' || BaseServices.roleId == '2'){
            businessId = +sessionStorage.getItem('CurrentBusinessId')!;
        }
        else{
            businessId = BaseServices.BusinessId;
        }
        return this.backend.getCategoryList(businessId, BaseServices.ApplicationId);
    }
}
