<div class="tab-content">
    <div class="table-view">
        <!-- Table starts -->
        <p-table [value]="myProject" #MyprojectsTable [rows]="10" [paginator]="true" [pageLinks]="3"
            [rowsPerPageOptions]="[5,10,20]" exportFilename="MyProjects list"
            [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}">
            <!-- Caption -->
            <ng-template pTemplate="caption">
                <div class="ui-helper-clearfix">

                    <div class="filter-controls-wrapper" style="display:none">
                        <div class="row">
                            <div class="col-sm-3">
                                <div class="form-group">
                                    <label for="Course" class="col-form-label" translate>PROJ_RESPONS
                                    </label>
                                    <div>
                                        <p-dropdown [options]="ProjectResponsibility" 
                                            #responsibleDropdown
                                            (onChange)="onResponsibleChanged($event, responsibleDropdown)"
                                            [filter]="true"></p-dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="table-options">
                        <div class="pull-right">
                            <div class="action-btns-group">
                                <button class="btn p-3 filter-toggle">
                                    <span class="icon ic-sm icon-filter"></span>
                                    <span translate>FILTER</span>
                                </button>
                            </div>
                            <div class="action-btns-group">
                                <kuba-export [reportData]="MyprojectsTable.filteredValue || MyprojectsTable.value"
                                    [additionalData]="additionalData"></kuba-export>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <!-- Header -->
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="Number">{{'PROJ_NUMBER' | translate}} <p-sortIcon field="Number"></p-sortIcon></th>
                    <th pSortableColumn="Title">{{'PROJ_NAME'| translate}} <p-sortIcon field="Title"></p-sortIcon></th>
                    <th pSortableColumn="StartDate">{{'START_DATE'| translate}} <p-sortIcon field="StartDate"></p-sortIcon></th>
                    <th pSortableColumn="ExpectedEndDate">{{'END_DATE'| translate}} <p-sortIcon field="ExpectedEndDate"></p-sortIcon></th>
                    <th pSortableColumn="ProjectManagers">{{'PROJ_MANAGER'| translate}} <p-sortIcon field="ProjectManagers"></p-sortIcon></th>
                    <th>{{'STATUS'|translate}}</th>
                    <th pSortableColumn="ProjectImage">{{'IMAGE'| translate}} <p-sortIcon field="ProjectImage"></p-sortIcon></th>
                    <th pSortableColumn="CreatorName">{{'CREATED_BY'| translate}} <p-sortIcon field="CreatorName"></p-sortIcon></th>
                    <th pSortableColumn="ModifierName">{{'EDITED_BY'| translate}} <p-sortIcon field="ModifierName"></p-sortIcon></th>
                    <th *ngIf="isAdministrateProject">{{'OPTIONS'| translate}}</th>
                </tr>
                <tr>
                    <th>
                        <input pInputText type="text"
                            (input)="MyprojectsTable.filter($any($event.target)?.value, 'Number', 'contains')" placeholder=""
                            class="p-column-filter">
                    </th>
                    <th>
                        <input pInputText type="text"
                            (input)="MyprojectsTable.filter($any($event.target)?.value, 'Title', 'contains')" placeholder=""
                            class="p-column-filter">
                    </th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>
                        <p-dropdown [options]="statuses" (onChange)="onStatusChanged($event, statusDropdown)"
                            #statusDropdown styleClass="p-column-filter" placeholder="Select" appendTo="body"
                            [showClear]="true">
                            <ng-template let-status pTemplate="item"> {{ status.label | translate }}
                            </ng-template>
                        </p-dropdown>
                    </th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th *ngIf="isAdministrateProject"></th>
                </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-project>
                <tr>
                    <td>
                        <span>{{project.Number}}</span>
                    </td>
                    <td>
                        <a [routerLink]="['./../../details',project.Id]" pTooltip="{{project.Title}}"
                            tooltipPosition="top">{{project.Title}}</a>
                    </td>
                    <td>
                        <span>{{project.StartDate | date : 'dd/MM/yyyy'}}</span>
                    </td>
                    <td>
                        <span>{{project.ExpectedEndDate | date : 'dd/MM/yyyy'}}</span>
                    </td>
                    <td>
                        <span>{{project.ProjectManagers}}</span>
                    </td>
                    <td>
                        <span>{{project.Status | translate}}</span>
                    </td>
                    <td>
                        <img src="{{project.ProjectImage}}" alt="" width="100" height="100" class="thumbnail">
                    </td>
                    <td>
                        <span>{{project.CreatorName}}</span>
                    </td>
                    <td>
                        <span>{{project.ModifierName}}</span>
                    </td>
                    <td class="col-button" *ngIf="isAdministrateProject">
                        <button type="button" [routerLink]="['./../../edit',project.Id]" class="btn btn-icon"
                            title="{{'EDIT'|translate}}">
                            <i class="icon ic-sm icon-pencil-tip"></i>
                        </button>
                        <button type="button" (click)="deleteProject(project)" class="btn btn-icon"
                            title="{{'DELETE'|translate}}" >
                            <i class="icon ic-sm icon-trash"></i>
                        </button>
                    </td>
                </tr>
            </ng-template>
            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td colspan="9" *ngIf="!isAdministrateProject">
                        {{'NO_RECORDS_FOUND'|translate}}
                    </td>
                    <td colspan="10" *ngIf="isAdministrateProject">
                        {{'NO_RECORDS_FOUND'|translate}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <!-- Table ends -->
    </div>
    <toaster-component></toaster-component>
    <p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
        acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>