import {
  Component,
  ChangeDetectorRef,
  OnInit,
  TemplateRef,
  AfterViewChecked,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UploaderConfig } from 'src/app/shared/ecpl-document-explorer/uploader-config';
import { ActivatedRoute, Router } from '@angular/router';
import { FeatureManualService } from 'src/app/kuba/feature-documents/feature-document.services';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { ManualFolder } from 'src/app/kuba/manuals/models/manual-folder.model';
import * as _ from 'lodash';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ManualService } from 'src/app/kuba/manuals/services/manual.services';
import {
  ParentItem,
  ChildItem,
  FeatureChecklistItemUpdate,
} from 'src/app/kuba/feature-documents/feature-document.models';
import { isArray } from 'util';
import { JobAnalysisService } from '../safety-job-analysis/services/jobanalysis.services';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { ArchiveServices } from '../archive/archive.services';
import { FeatureKey, Rights } from '../../_models';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
class AppManualList {
  name: string;
  id: number;
  constructor() {}
}
@Component({
  templateUrl: 'feature-document.component.html',
})
export class FeatureDocumentComponent implements OnInit, AfterViewChecked {
  modalRef: BsModalRef;
  bsConfig = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true,
  };
  applicationId: number;
  featureId: number;
  featureName: string;
  featureKeyName: string;
  featureKey: number;
  triggerUploader = false;
  portalId: number;
  treeNode: any;
  editMode = false;
  hideButton = true;
  treeActiveNode: any;
  apiResponse = 'none';

  // Document Explorer Configs
  additionalData: any;
  upConfig: UploaderConfig;
  uploadedFiles: any;
  UploadableFile: any;

  featuredocumentId: number;
  additionalFeatures: any;
  pageTitle: string;
  isLar = false;
  filename: string;
  folderToFilter: SelectItem[] = [];
  filteredFolderDetails: any;
  showSavingLoader = false;
  isShowAllFile = false;
  isHideShowAllButton = false;
  loading = false;
  allFiles: any[];
  isFs = false;
  roleId: any;
  userRoleId: any;
  private subscriptions: Subscription[] = [];
  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private featureManualService: FeatureManualService,
    private cdr: ChangeDetectorRef,
    private modalService: BsModalService,
    private manualService: ManualService,
    private archiveServices: ArchiveServices,
    private safetyjobservice: JobAnalysisService,
    private translate: TranslateService
  ) {
    this.featureKeyName = this.route.snapshot.params['manualname'];
    this.applicationId = BaseServices.ApplicationId;
    let workingModule = JSON.parse(sessionStorage.getItem('workingModule'));
    this.featureName = workingModule.name;
    this.featureId = workingModule.id;
    this.featureKey = route.snapshot.parent.params['id'];
    this.featureKey = this.featureId == 60 ? 0 : this.featureKey;
    this.treeNode = this.route.snapshot.data['list'];

    if (this.featureId == 20 || this.featureId == 43) {
      this.isFs = true;
    }
    if (+BaseServices.roleId === 4) {
      this.hideButton = false;
    } else if (+BaseServices.roleId === 5) {
      if (this.featureId == FeatureKey.PROJECT) {
        this.hideButton = this.isUserRights(Rights.ADMINISTRATE_PROJECTS);
      } else if (this.featureId == FeatureKey.FDV) {
        this.hideButton = this.isUserRights(Rights.ADMINISTRATE_FDV);
      } else if (this.featureId == FeatureKey.INTERNAL_CONTROL) {
        this.hideButton = this.isUserRights(
          Rights.ADMINISTRATE_INTERNAL_CONTROL
        );
      } else if (this.featureId == FeatureKey.OTHER_SYSTEMS) {
        this.hideButton = this.isUserRights(Rights.ADMINISTRATE_OTHER_SYSTEMS);
      } else if (this.featureId == FeatureKey.QUALITY_SYSTEMS) {
        this.hideButton = this.isUserRights(Rights.ADMINISTRATE_QUALITY_SYSTEM);
      } else if (this.featureId == FeatureKey.KUNDE_EL) {
        this.hideButton = this.isUserRights(Rights.IK_KUNDE_EL_RIGHTS);
      } else if (this.featureId == FeatureKey.FRAMEWORK_AGREEMENT) {
        this.hideButton = this.isUserRights(Rights.FRAMEWORK_AGREEMENT_RIGHTS);
      } else if (this.featureId == FeatureKey.SERVICE_AGREEMENT) {
        this.hideButton = this.isUserRights(Rights.SERVICE_AGREEMENT_RIGHTS);
      } else if (this.featureId == FeatureKey.FOOD_SAFETY) {
        this.hideButton = this.isUserRights(Rights.ADMINISTRATE_FOOD_SAFETY);
      } else if (this.featureId == FeatureKey.FLEXI) {
        this.hideButton = this.isUserRights(Rights.ADMINISTRATE_FLEXI);
      } else if (this.featureId == FeatureKey.KUBA_CONTROL) {
        this.hideButton = this.isUserRights(Rights.ADMINISTRATE_KUBA_CONTROL);
      } else if (this.featureId == FeatureKey.VEHICLES) {
        this.hideButton = true;
      } else {
        this.hideButton = false;
      }
    } else {
      this.hideButton = true;
    }

    if (+BaseServices.roleId === 3) {
      if (this.featureId == FeatureKey.FLEXI) {
        this.hideButton = false;
      } else {
        this.hideButton = true;
      }
    }

    if (+BaseServices.roleId === 3) {
      if (this.featureId == FeatureKey.FLEXI) {
        this.hideButton = false;
      } else {
        this.hideButton = true;
      }
    }

    if (window.name === 'Remote') {
      let businessId = +sessionStorage.getItem('RModeBusinessId');
      let userSettings = sessionStorage.getItem('userSettings');
      let portalId = JSON.parse(userSettings).userProfile;
      let roleId = portalId.RoleId;
      this.userRoleId = roleId;

      if (this.userRoleId === 1) {
        if (this.featureId == FeatureKey.FLEXI) {
          this.hideButton = true;
        }
      } else if (this.userRoleId === 2) {
        if (this.featureId == FeatureKey.FLEXI) {
          this.hideButton = false;
        }
      }
    }

    this.upConfig = {
      title: 'ALL_FILES',
      viewSwitch: false,
      editMode: this.editMode,
      windowedHeight: true,
      showUserMeta: true,
      uploaderUri: environment.BASE_URL + '/file/upload/business/manual',
      addtionalData: null,
      showDownloadAll: true,
      showFoodSafetyFiler:
        this.featureId === FeatureKey.FOOD_SAFETY ? true : false,
      showDeleteAllButton: true,
      documentType: 'allFiles',
    };
    if (BaseServices.UserRole === 'Admin' && BaseServices.BusinessId === 0) {
      this.subscriptions.push(
        this.featureManualService
          .getAdminProjectFeatureKey(this.featureKey)
          .subscribe((result: any) => {
            if (result) {
              this.featureKey = result;
            }
          })
      );
    }

    this.subscriptions.push(
      this.translate.stream('DEFAULT_FOLDER').subscribe((val) => {
        if (this.filteredFolderDetails) {
          let filteredFolder = this.filteredFolderDetails.filter(
            (x) => x.isFolder === true
          );
          if (filteredFolder) {
            this.folderToFilter = [];
            this.folderToFilter.push({ label: val.SELECT, value: null });
            filteredFolder.forEach((element) => {
              let trnKey = element.translateKey;
              let folderName;
              if (trnKey === null) {
                folderName = element.filename;
              } else {
                folderName = val[trnKey];
              }
              this.folderToFilter.push({
                label: folderName,
                value: element.nodeId,
              });
            });
          }
        }
      })
    );
  }

  // Events form Tree
  catchEvent(event): void {
    this.triggerUploader = false;
    switch (event.eventName) {
      case 'CheckBoxStatus':
        this.loading = true;
        this.subscriptions.push(
          this.featureManualService
            .updateManualCheckboxstatus(
              event.node.data,
              this.featureId,
              this.featureKey
            )
            .subscribe(
              (response) => {
                this.refreshTreeNode();
              },
              (error) => console.log('Checkbox status not update : ', error),
              () => console.log('Checkbox status updated')
            )
        );
        break;

      case 'AddNewRootFolder':
        let tempEvent = event;
        tempEvent.parent.nodeId = 0;
        this.subscriptions.push(
          this.featureManualService
            .createFeatureManualFolder(this.featureKey, tempEvent)
            .subscribe(
              (response) => {
                this.refreshTreeNode();
              },
              (error) => console.log('Manual Create folder Error : ', error),
              () =>
                console.log(
                  'Manual Create folder Complete'
                  // this.treeActiveNode.data
                )
            )
        );
        break;
      case 'AddNewFolder':
        this.subscriptions.push(
          this.featureManualService
            .createFeatureManualFolder(this.featureKey, event)
            .subscribe(
              (response) => {
                this.refreshTreeNode();
              },
              (error) => console.log('Manual Create folder Error : ', error),
              () =>
                console.log(
                  'Manual Create folder Complete'
                  // this.treeActiveNode.data
                )
            )
        );
        break;

      case 'RenameFolder':
        this.subscriptions.push(
          this.featureManualService
            .renameFeatureManualFolder(this.featureKey, event)
            .subscribe(
              (response) => {
                this.refreshTreeNode();
              },
              (error) => console.log('Manual Create folder Error : ', error),
              () => console.log('Manual Create folder Complete')
            )
        );
        break;

      case 'DeleteFolder':
        this.subscriptions.push(
          this.featureManualService
            .deleteFeatureManualFolder(this.featureKey, event.item.data.nodeId)
            .subscribe(
              (response) => {
                this.refreshTreeNode();
                this.toasterComponent.callToastDlt();
              },
              (error) => console.log('Manual delete folder Error : ', error),
              () => console.log('Manual delete folder Complete')
            )
        );
        break;

      case 'AddFiles':
        this.triggerUploader = true;
        // console.log(
        //   "AddFiles @ Manual :",
        //   event,
        //   "triggerUploader:",
        //   this.triggerUploader
        // );

        break;
      case 'EditFile':
        // TODO:

        break;
      case 'RenameFile':
        this.subscriptions.push(
          this.featureManualService
            .renameDocument(this.featureKey, event)
            .subscribe(
              (response) => {
                this.refreshTreeNode();
              },
              (error) => console.log('Rename article error : ', error),
              () => console.log('Rename article Complete')
            )
        );
        break;

      case 'DeleteDefaultFile':
        this.subscriptions.push(
          this.safetyjobservice
            .deleteCompleteCheckListData(event.item.data.nodeId)
            .subscribe(
              (response) => {
                this.refreshTreeNode();
                this.toasterComponent.callToastDlt();
              },
              (error) => console.log('Rename article error : ', error),
              () => console.log('delete future checklist undercom')
            )
        );
        break;
      case 'DeleteFile':
        this.subscriptions.push(
          this.featureManualService
            .deleteDocument(this.featureKey, event.item.data.nodeId)
            .subscribe(
              (response) => {
                this.refreshTreeNode();
                this.toasterComponent.callToastDlt();
              },
              (error) => console.log('Manual checklist delete Error : ', error),
              () => console.log('Manual checklist delete Complete')
            )
        );
        break;
      case 'DeleteFSundercompletionFile':
        this.subscriptions.push(
          this.archiveServices
            .deleteFoodSafety(event.item.data.nodeId)
            .subscribe(
              (response) => {
                this.refreshTreeNode();
                this.toasterComponent.callToastDlt();
              },
              (error) => console.log('Manual delete folder Error : ', error),
              () => console.log('Manual delete folder Complete')
            )
        );
        break;
      case 'NewArticle':
        // TODO:
        let aVersion = event.item.articleContent.article.version;
        this.subscriptions.push(
          this.featureManualService
            .newArticle(this.featureKey, event)
            .subscribe(
              (response: any) => {
                this.subscriptions.push(
                  this.featureManualService
                    .newArticleText(
                      this.featureKey,
                      response.Id,
                      event,
                      true,
                      aVersion
                    )
                    .subscribe((result) => {
                      this.refreshTreeNode();
                      this.toasterComponent.callToast();
                    })
                );
              },
              (error) => console.log('New article error : ', error),
              () => console.log('new article Complete')
            )
        );
        break;
      case 'SaveArticle':
        // TODO:
        let version = event.item.articleContent.article.version;
        this.subscriptions.push(
          this.featureManualService
            .saveArticle(this.featureKey, event)
            .subscribe(
              (response: any) => {
                this.subscriptions.push(
                  this.featureManualService
                    .newArticleText(
                      this.featureKey,
                      response.Id,
                      event,
                      false,
                      version
                    )
                    .subscribe((result) => {
                      this.refreshTreeNode();
                      this.toasterComponent.callToast();
                    })
                );
              },
              (error) => console.log('Save article error : ', error),
              () => console.log('save article Complete')
            )
        );
        break;
      case 'RenameArticle':
        this.subscriptions.push(
          this.featureManualService
            .renameArticle(this.featureKey, event)
            .subscribe(
              (response) => {
                this.refreshTreeNode();
              },
              (error) => console.log('Rename article error : ', error),
              () => console.log('Rename article Complete')
            )
        );
        break;
      case 'DeleteArticle':
        this.subscriptions.push(
          this.featureManualService
            .deleteArticle(this.featureKey, event.item.data.nodeId)
            .subscribe(
              (response) => {
                this.refreshTreeNode();
                this.toasterComponent.callToastDlt();
              },
              (error) => console.log('Manual delete delete Error : ', error),
              () => console.log('Manual delete delete Complete')
            )
        );
        break;
      case 'DeleteSelectedArticle':
        this.subscriptions.push(
          this.featureManualService
            .deleteMultipleArticle(event.item.data)
            .subscribe(
              (response) => {
                this.refreshTreeNode();
                this.toasterComponent.callToastDlt();
              },
              (error) => console.log('Manual delete delete Error : ', error),
              () => console.log('Manual delete delete Complete')
            )
        );
        break;
      case 'DeleteSelectedChecklist':
        this.subscriptions.push(
          this.featureManualService
            .deleteMultipleChecklist(event.item.data)
            .subscribe(
              (response) => {
                this.refreshTreeNode();
                this.toasterComponent.callToastDlt();
              },
              (error) => console.log('Manual delete delete Error : ', error),
              () => console.log('Manual delete delete Complete')
            )
        );
        break;
      case 'DeleteSelectedDocuments':
        this.subscriptions.push(
          this.featureManualService
            .deleteMultipleDocument(event.item.data)
            .subscribe(
              (response) => {
                this.refreshTreeNode();
                this.toasterComponent.callToastDlt();
              },
              (error) => console.log('Manual delete delete Error : ', error),
              () => console.log('Manual delete delete Complete')
            )
        );
        break;
      case 'DeleteSelectedUnderCompletionChecklist':
        this.subscriptions.push(
          this.safetyjobservice
            .deleteMultipleCompleteChecklist(event.item.data)
            .subscribe(
              (response) => {
                this.refreshTreeNode();
                this.toasterComponent.callToastDlt();
              },
              (error) => console.log('Manual delete delete Error : ', error),
              () => console.log('Manual delete delete Complete')
            )
        );
        break;
      case 'DeleteSelectedFSUnderCompletionChecklist':
        this.subscriptions.push(
          this.archiveServices
            .deleteMultipleFoodSafety(event.item.data)
            .subscribe(
              (response) => {
                this.refreshTreeNode();
                this.toasterComponent.callToastDlt();
              },
              (error) => console.log('Manual delete folder Error : ', error),
              () => console.log('Manual delete folder Complete')
            )
        );
        break;
      case 'DeleteAllFiles': //KW-912
        let eventDataList = event.item.data;
        let totalEventCount = eventDataList.length;
        let eventList = [];
        eventDataList.forEach((element) => {
          let data = {};
          switch (element.eventName) {
            case 'DeleteSelectedArticle':
              data = {
                functionCalled:
                  this.featureManualService.deleteMultipleArticle.bind(
                    this.featureManualService
                  ),
                dataList: element.item.data,
              };
              eventList.push(data);
              break;
            case 'DeleteSelectedChecklist':
              data = {
                functionCalled:
                  this.featureManualService.deleteMultipleChecklist.bind(
                    this.featureManualService
                  ),
                dataList: element.item.data,
              };
              eventList.push(data);
              break;
            case 'DeleteSelectedDocuments':
              data = {
                functionCalled:
                  this.featureManualService.deleteMultipleDocument.bind(
                    this.featureManualService
                  ),
                dataList: element.item.data,
              };
              eventList.push(data);
              break;
            case 'DeleteSelectedUnderCompletionChecklist':
              data = {
                functionCalled:
                  this.safetyjobservice.deleteMultipleCompleteChecklist.bind(
                    this.safetyjobservice
                  ),
                dataList: element.item.data,
              };
              eventList.push(data);
              break;
            case 'DeleteSelectedFSUnderCompletionChecklist':
              data = {
                functionCalled:
                  this.archiveServices.deleteMultipleFoodSafety.bind(
                    this.archiveServices
                  ),
                dataList: element.item.data,
              };
              eventList.push(data);
              break;
          }
        });
        let count = 0;
        eventList.forEach((element) => {
          let functionCalled = element.functionCalled;
          let data = element.dataList;
          this.subscriptions.push(
            functionCalled(data).subscribe(
              (response) => {
                count++;
                if (count == totalEventCount) {
                  this.refreshTreeNode();
                  this.toasterComponent.callToastDlt();
                }
              },
              (error) => console.log('Delete Error', error),
              () => console.log('Delete Complete')
            )
          );
        });

        break;
      case 'NewChecklist':
        // TODO:

        this.subscriptions.push(
          this.featureManualService
            .createChecklist(this.featureId, this.featureKey, event)
            .subscribe(
              (response: any) => {
                let checklistId = response.Id;
                let listModel = this.checklistSaveObj(event, 0);
                this.subscriptions.push(
                  this.featureManualService
                    .saveChecklist(checklistId, listModel)
                    .subscribe(
                      (response) => {
                        this.refreshTreeNode();
                      },
                      (error) =>
                        console.log('Save checklistitem error : ', error),
                      () => console.log('save checklistitem Complete')
                    )
                );
              },
              (error) => console.log('Save checklist error : ', error),
              () => console.log('save checklist Complete')
            )
        );
        break;
      case 'SaveChecklist':
        // TODO:
        let tempchecklistId = event.item.checkList.id;
        let templistModel = this.checklistSaveObj(event, BaseServices.UserId);
        this.subscriptions.push(
          this.featureManualService
            .saveChecklist(tempchecklistId, templistModel)
            .subscribe(
              (response) => {
                this.refreshTreeNode();
              },
              (error) => console.log('Save checklistitem error : ', error),
              () => console.log('save checklistitem Complete')
            )
        );
        break;
      case 'EditChecklist':
        // TODO:
        let checklistId = event.item.checkList.id;
        let listModel = this.checklistSaveObj(event, BaseServices.UserId);

        this.subscriptions.push(
          this.featureManualService
            .saveChecklist(checklistId, listModel)
            .subscribe(
              (response) => {
                this.refreshTreeNode();
              },
              (error) => console.log('Save checklist error : ', error),
              () => console.log('save checklist Complete')
            )
        );

        break;
      case 'RenameChecklist':
        this.subscriptions.push(
          this.featureManualService
            .renameChecklist(this.featureKey, event)
            .subscribe(
              (response) => {
                this.refreshTreeNode();
              },
              (error) => console.log('Rename checklist error : ', error),
              () => console.log('Rename checklist Complete')
            )
        );
        break;
      case 'DeleteChecklist':
        this.subscriptions.push(
          this.featureManualService
            .deleteChecklist(this.featureKey, event.item.data.nodeId)
            .subscribe(
              (response) => {
                this.refreshTreeNode();
                this.toasterComponent.callToastDlt();
              },
              (error) => console.log('Manual checklist delete Error : ', error),
              () => console.log('Manual checklist delete Complete')
            )
        );
        break;
      case 'moveNode':
        this.subscriptions.push(
          this.featureManualService.moveFeatureNode(event).subscribe(
            (response) => {
              if (response) {
                this.refreshTreeNode();
              }
            },
            (error) => console.log('Manual movenoxde  Error : ', error),
            () => console.log('Manual movenode  Complete')
          )
        );
        break;
      case 'NewLar':
        this.subscriptions.push(
          this.featureManualService
            .CreateLar(this.featureId, this.featureKey, event)
            .subscribe(
              (response) => {
                this.refreshTreeNode();
              },
              (error) => {
                this.apiResponse = 'failed';
                console.log('new lar error : ', error);
              },
              () => console.log('new article Complete')
            )
        );
        break;
      case 'SaveLar':
        this.subscriptions.push(
          this.featureManualService.saveLar(event).subscribe(
            (response) => {
              this.refreshTreeNode();
            },
            (error) => {
              this.apiResponse = 'failed';
              console.log('Save article error : ', error);
            },
            () => console.log('save article Complete')
          )
        );
        break;
      case 'DeleteLar':
        this.subscriptions.push(
          this.featureManualService.deleteLar(event.item.larId).subscribe(
            (response) => {
              this.refreshTreeNode();
              this.toasterComponent.callToastDlt();
            },
            (error) => console.log('Lar  delete Error : ', error),
            () => console.log('Lar  delete Complete')
          )
        );
        break;
      case 'activate':
        if (event.node.hasChildren) {
          this.isShowAllFile = true;
          if (
            event.node.data.documentType === 'laws' ||
            event.node.data.filename === 'Regulation'
          ) {
            this.isLar = true;
            this.uploadedFiles = event.node.data.nodes;
            this.filename = event.node.data.filename;
            this.treeActiveNode = event.node;
          } else {
            this.isLar = false;
            this.uploadedFiles = event.node.data.nodes;
            if (event.node.data.nodes) {
              let filteredFolder = event.node.data.nodes.filter(
                (x) => x.isFolder === true
              );
              this.filteredFolderDetails = event.node.data.nodes;
              if (filteredFolder) {
                this.folderToFilter = [];
                this.folderToFilter.push({
                  label: this.translate.instant('SELECT'),
                  value: null,
                });
                filteredFolder.forEach((element) => {
                  this.folderToFilter.push({
                    label: element.filename,
                    value: element.nodeId,
                  });
                });
              }
            }
            this.upConfig.title = event.node.data.filename;
            this.upConfig.documentType = event.node.data.documentType;
            this.upConfig.addtionalData = {
              ApplicationId: BaseServices.ApplicationId,
              BusinessId: BaseServices.BusinessId,
              FolderPath:
                'fid-' +
                this.featureKey +
                '-' +
                this.featureName.replace(' ', '-').toLowerCase(),
              FolderTypeNafeatureNameme: event.node.data.documentType,
              FeatureId: this.featureId,
              FeatureKey: +this.featureKey,
              FeatureName: this.featureName,
              FeatureFolderId: event.node.data.nodeId,
              userId: BaseServices.UserId,
            };

            this.treeActiveNode = event.node;
          }
        }
        this.treeActiveNode = event.node;
        this.cdr.detectChanges();
        break;
      case 'deactivate':
        if (event.node.data.nodes) {
          let filteredFolder = event.node.data.nodes.filter(
            (x) => x.isFolder === true
          );
          this.filteredFolderDetails = event.node.data.nodes;
          if (filteredFolder) {
            this.folderToFilter = [];
            this.folderToFilter.push({
              label: this.translate.instant('SELECT'),
              value: null,
            });
            filteredFolder.forEach((element) => {
              this.folderToFilter.push({
                label: element.filename,
                value: element.nodeId,
              });
            });
          }
        }
        break;
      case 'CopyFile':
        if (
          confirm(
            'You are about to creating a new copy of this file, Do you really want to proceed?'
          )
        ) {
          // console.log("CopyOfficeDocument @ Manual", event);
          // Service that copy existing file to respective place
          this.subscriptions.push(
            this.manualService.createCopyofDocument(event).subscribe(
              (response: any) => {
                let docPathDto = {
                  Id: event.item.data.nodeId,
                  Path: response.path,
                  ModifiedBy: BaseServices.UserId,
                };
                this.subscriptions.push(
                  this.featureManualService
                    .copyFeatureManualDocument(docPathDto)
                    .subscribe(
                      (result: any) => {
                        this.refreshTreeNode();
                        this.toasterComponent.callToast();
                      },
                      (error: any) => {
                        console.error('Manual document service error:', error);
                      },
                      () => {
                        console.log('Adding manual document service complete.');
                      }
                    )
                );
              },
              (error) => console.error('Document version  Error : ', error),
              () => console.log('Document version  Complete')
            )
          );
          // service that update existing record in document based on Module
        }
        break;
      case 'CopyEditVersionDocument':
        // Service that copy existing file to respective place
        this.subscriptions.push(
          this.manualService.createEditCopyofDocument(event).subscribe(
            (response: any) => {
              let docPathDto = {
                Id: event.item.id,
                Path: response.path,
                ModifiedBy: BaseServices.UserId,
              };
              this.subscriptions.push(
                this.featureManualService
                  .CreateEditDocumentVersion(docPathDto)
                  .subscribe(
                    (response) => console.log('Document version created'),
                    (error) =>
                      console.error('Document version  Error : ', error),
                    () => console.log('Document version  Complete')
                  )
              );
            },
            (error) => console.error('Document version  Error : ', error),
            () => console.log('Document version  Complete')
          )
        );
        break;
      case 'initialized':
        break;
      case 'updateData':
        break;
      case 'loadNodeChildren':
        break;
      case 'ShowOfficeFileSaveloader':
        if (parseInt(sessionStorage.getItem('isDocumentChanged'), null)) {
          this.subscriptions.push(
            this.featureManualService
              .UpdateDocument(event.item.data.nodeId, BaseServices.UserId)
              .subscribe((response) => {})
          );
          this.showSavingLoader = true;
          setTimeout(() => {
            this.refreshTreeNode();
            this.showSavingLoader = false;
          }, 15000);
          sessionStorage.setItem('isDocumentChanged', '0');
        }
        break;
      default:
        if (event.node.data.documentType === 'undercompletion') {
          this.router.navigate(
            [
              '../../../../../complete-checklist/incomplete/' +
                event.node.data.nodeId,
              'feature',
              0,
              0,
            ],
            { relativeTo: this.route }
          );
        } else if (event.node.data.documentType === 'manualfsundercompletion') {
          this.router.navigate(
            [
              '../../../../../fs-complete-checklist/incomplete/' +
                event.node.data.nodeId,
            ],
            { relativeTo: this.route }
          );
        } else if (
          event.node.data.documentType === 'vehiclecontrolundercompletion'
        ) {
          this.router.navigate(
            [
              '../../../../../vehicle-complete-checklist/incomplete/' +
                event.node.data.nodeId,
            ],
            { relativeTo: this.route }
          );
        }
        break;
    }
  }

  // emitter for save from Document Explorer
  saveUploaded(event: any) {
    let fileExtension = '';
    if (event.mimetype) {
      const parts = event.mimetype.split('/');
      if (parts.length > 1) {
        fileExtension = parts[1];
        event.mimetype = fileExtension;
      }
    }
    switch (event.eventName) {
      case 'NewArticle':
        // event.activeNodeId = this.treeActiveNode.data.nodeId;
        if (
          this.treeActiveNode &&
          this.treeActiveNode.data &&
          this.treeActiveNode.data.nodeId
        ) {
          event.activeNodeId = this.treeActiveNode.data.nodeId;
        } else {
          event.activeNodeId = this.treeActiveNode[0].nodeId;
        }
        this.catchEvent(event);
        break;
      case 'AddNewFolder':
        if (!event.parent) {
          event.parent = this.treeActiveNode.data;
        }
        this.catchEvent(event);
        break;
      case 'DeleteFolder':
      case 'DeleteArticle':
      case 'DeleteChecklist':
      case 'DeleteDefaultFile':
      case 'DeleteFile':
      case 'DeleteFSundercompletionFile':
      case 'DeleteSelectedArticle':
      case 'DeleteSelectedChecklist':
      case 'DeleteSelectedDocuments':
      case 'DeleteSelectedUnderCompletionChecklist':
      case 'DeleteSelectedFSUnderCompletionChecklist':
      case 'DeleteAllFiles':
        this.catchEvent(event);
        break;
      case 'SaveArticle':
        if (this.treeActiveNode && this.treeActiveNode.data) {
          event.activeNodeId = this.treeActiveNode.data.nodeId;
        }
        this.catchEvent(event);
        break;
      case 'NewChecklist':
      case 'SaveChecklist':
      case 'EditChecklist':
        if (this.treeActiveNode && this.treeActiveNode.data) {
          event.activeNodeId = this.treeActiveNode.data.nodeId;
        } else {
          event.activeNodeId = 3; // Online Checklist - TODO: Refactor this logic and remove side effects events
        }
        this.catchEvent(event);
        break;
      case 'NewLar':
      case 'SaveLar':
      case 'DeleteLar':
        event.activeNodeId = this.treeActiveNode.data.nodeId;

        this.catchEvent(event);
        break;
      case 'SaveArticle':
        if (this.treeActiveNode !== undefined) {
          event.activeNodeId = this.treeActiveNode.data.nodeId;
        }
        this.catchEvent(event);
        break;
      case 'CopyEditVersionDocument':
        event.activeNodeId = this.treeActiveNode.data.nodeId;
        this.catchEvent(event);
        break;
      case 'ShowOfficeFileSaveloader':
        if (parseInt(sessionStorage.getItem('isDocumentChanged'), null)) {
          this.showSavingLoader = true;
          this.subscriptions.push(
            this.featureManualService
              .UpdateDocument(event.item.id, BaseServices.UserId)
              .subscribe((response) => {
                if (response) {
                  console.log(response);
                }
              })
          );
          setTimeout(() => {
            this.refreshTreeNode();
            this.showSavingLoader = false;
          }, 15000);
          sessionStorage.setItem('isDocumentChanged', '0');
        }
        break;
      default:
        let uploadedFile = Object.assign(
          {},
          event,
          this.upConfig.addtionalData
        );
        this.subscriptions.push(
          this.featureManualService
            .addFeatureManualDocument(uploadedFile)
            .subscribe(
              (result: any) => {
                this.refreshTreeNode();
              },
              (error: any) => {
                console.error('Manual document service error:', error);
              },
              () => {}
            )
        );
        break;
    }
  }

  // Emitter for selected folder from Document Explorer
  setActiveNodeIntree(event: any) {
    this.filteredFolderDetails = event;
    let filteredFiles = event[0].nodes.filter((x) => x.isFolder === true);
    this.filteredFolderDetails = event[0].nodes;
    if (filteredFiles) {
      this.folderToFilter = [];
      this.folderToFilter.push({
        label: this.translate.instant('SELECT'),
        value: null,
      });
      filteredFiles.forEach((element) => {
        this.folderToFilter.push({
          label: element.filename,
          value: element.nodeId,
        });
      });
    }
    this.treeActiveNode = event;
  }

  pushToBack() {
    if (this.featureId === FeatureKey.FOOD_SAFETY) {
      this.router.navigate(['../../../../list/all', { fs: '1' }], {
        relativeTo: this.route,
      });
    } else {
      this.location.back();
    }
  }

  // Toggle Edit Mode
  toggleEditMode() {
    this.editMode = !this.editMode;
    this.upConfig.editMode = !this.upConfig.editMode;
  }

  refreshTreeNode() {
    this.subscriptions.push(
      this.featureManualService.getFeatureManualTree(this.featureKey).subscribe(
        (result: any) => {
          this.treeNode = result;
          if (this.upConfig.title !== 'ALL_FILES') {
            let flattenned = this.flattenArrObj(this.treeNode);
            if (this.treeActiveNode) {
              // const parent = _.filter(flattenned, [
              //   "id",
              //   this.treeActiveNode.data.id,
              // ]);
              if (this.treeActiveNode.data && this.treeActiveNode.data.id) {
                const parent = _.filter(flattenned, [
                  'id',
                  this.treeActiveNode.data.id,
                ]);
                this.uploadedFiles = parent[0].nodes;
                this.upConfig.title = parent[0].filename;
              } else {
                const parent = _.filter(flattenned, [
                  'id',
                  this.treeActiveNode[0].id,
                ]);
                this.uploadedFiles = parent[0].nodes;
                this.upConfig.title = parent[0].filename;
              }
              // this.uploadedFiles = parent[0].nodes;
              // this.upConfig.title = parent[0].filename;
            }
            this.loading = false;
          } else {
            this.ngOnInit();
          }
        },
        (error: any) => {
          console.error('Api Error while refreshing tree:', error);
          this.loading = false;
        },
        () => {
          this.apiResponse = 'reloaded';
          this.loading = false;
        }
      )
    );
  }

  refresh(event) {
    this.loading = true;
    this.refreshTreeNode();
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.bsConfig);
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  onFilteredFolder(value: any) {
    if (this.filteredFolderDetails) {
      let filteredFiles =
        value == null
          ? this.filteredFolderDetails
          : this.filteredFolderDetails.filter((x) => x.nodeId === value);
      this.uploadedFiles =
        value == null
          ? this.flattenArrObj(filteredFiles)
          : filteredFiles[0].nodes;
      filteredFiles = this.uploadedFiles.filter((x) => x.isFolder === true);
      if (this.treeActiveNode) {
        this.filteredFolderDetails = filteredFiles;
        if (filteredFiles) {
          this.folderToFilter = [];
          this.folderToFilter.push({
            label: this.translate.instant('SELECT'),
            value: null,
          });
          filteredFiles.forEach((element) => {
            this.folderToFilter.push({
              label: element.filename,
              value: element.nodeId,
            });
          });
        }
      }
    }
  }

  ngOnInit() {
    document.querySelector('body').classList.remove('opened');
    this.upConfig.title = 'ALL_FILES';
    this.upConfig.documentType = 'allFiles';
    this.showAllFiles();
  }

  // private
  private flattenArrObj(arr) {
    let newArr = [];
    if (Array.isArray(arr) && arr.length > 0) {
      arr.forEach((element) => {
        newArr.push(element);
        if (Array.isArray(element.nodes) && element.nodes.length > 0) {
          newArr = newArr.concat(this.flattenArrObj(element.nodes));
        }
      });
    }
    return newArr;
  }

  private checklistSaveObj(model, modifiedBy) {
    let listModel = new FeatureChecklistItemUpdate();
    listModel.title = model.item.checkList.title;
    listModel.score = model.item.checkList.scoreIndex;
    listModel.userId = BaseServices.UserId;
    listModel.parentItems = [];
    listModel.ModifiedBy = modifiedBy;
    if (model.item.checkList.sections.length > 0) {
      model.item.checkList.sections.forEach((ParentItem) => {
        let section = this.checklistSecObj(ParentItem);
        listModel.parentItems.push(section);
      });
    }

    return listModel;
  }

  private checklistSecObj(obj) {
    let section = new ParentItem();
    section.parentItemId = obj.id; // Id
    section.parentId = 0;
    section.parentItemPoint = obj.position;
    section.parentItemTitle = obj.title;
    section.parentItemStatus = obj.status;
    section.childItems = this.checklistItemObj(obj.items);
    return section;
  }

  private checklistItemObj(objItem) {
    let items: ChildItem[] = [];
    if (isArray(objItem)) {
      objItem.forEach((item: any) => {
        items.push({
          childItemId: item.id,
          childItemTitle: item.title,
          childItemPoint: item.position,
          isView: item.status,
        });
      });
    }
    return items;
  }

  /**
   * Method to show all files
   */
  showAllFiles() {
    if (this.treeNode) {
      this.isShowAllFile = false;
      let flattenned = this.flattenArrObj(this.treeNode);
      this.uploadedFiles = flattenned.filter(
        (x) => x.isFolder !== true && x.status === 1
      );
      let filteredFolder = this.treeNode.filter((x) => x.isFolder === true);
      this.filteredFolderDetails = this.treeNode;
      if (filteredFolder) {
        this.folderToFilter = [];
        this.folderToFilter.push({
          label: this.translate.instant('SELECT'),
          value: null,
        });
        filteredFolder.forEach((element) => {
          let trnKey = element.translateKey;
          let folderName;
          if (trnKey === null) {
            folderName = element.filename;
          } else {
            this.subscriptions.push(
              this.translate.stream('DEFAULT_FOLDER').subscribe((val) => {
                folderName = val[trnKey];
              })
            );
          }
          this.folderToFilter.push({
            label: folderName,
            value: element.nodeId,
          });
        });
      }
    }
  }

  isUserRights(rightsId: number): boolean {
    return BaseServices.checkUserRights(+rightsId, BaseServices.UserRole);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}
