import { AfterViewInit, Directive, Input, OnDestroy, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Calendar } from 'primeng/calendar';
import { Subscription } from 'rxjs';
import { DateRangeEndDirective } from './date-range-end.directive';

@Directive({
  selector: 'p-calendar[dateRangeWith]'
})

export class DateRangeWithDirective implements OnDestroy, AfterViewInit {
  @Input('dateRangeWith') endDirective: DateRangeEndDirective;
  private subscriptions: Subscription[] = [];

  constructor(
    @Self() private calendar: Calendar,
    @Optional() @Self() private control: NgControl | null
  ) {}

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
  
  ngAfterViewInit(): void {
    // Set initial min date on end calendar
    this.endDirective.setMinDate(this.calendar.value);
    
    // Initialize end date constraints and listeners
    this.endDirective.initializeRange(this.calendar);

    // Setup start date listeners
    if (this.control?.control) {
      this.setupControlListeners();
    } else {
      this.setupCalendarListeners();
    }
  }

  private setupControlListeners() {
    this.subscriptions.push(
      this.control.control.valueChanges.subscribe(date => 
        this.endDirective.setMinDate(date)
      )
    );
  }

  private setupCalendarListeners() {
    this.subscriptions.push(
      this.calendar.onSelect.subscribe(date => 
        this.endDirective.setMinDate(date)
      ),
      this.calendar.onClear.subscribe(() => 
        this.endDirective.setMinDate(null)
      ),
      this.calendar.onInput.subscribe(() => 
        this.endDirective.setMinDate(this.calendar.value)
      )
    );
  }
  
}
