import { CountryCodeResolver } from './../employees/services/employeeWorkExperience.resolver.service';
import { ContractorListByBusinessResolver, ContractorEditByResolver,ModuleListResolver } from './services/contractor.resolver';
import { Route } from '@angular/router';
import { SubContractorComponent } from './contractor.component';
import { SubContractorListComponent } from './components/sub-contractor-list.component';
import { SubContractorNewComponent } from './components/sub-contractor-new.component';
export const SubContractorRoutes: Route[] = [
    {
        path: 'contractor',
        component: SubContractorComponent,
        children: [
            {
                path: 'list',
                component: SubContractorListComponent,
                resolve: {
                    list: ContractorListByBusinessResolver
                }
            },
            {
                path: 'create/:cid',
                component: SubContractorNewComponent,
                resolve: {
                    countrycode: CountryCodeResolver,
                    module: ModuleListResolver
                }
            },
            {
                path: 'edit/:cid',
                component: SubContractorNewComponent,
                resolve: {
                    countrycode: CountryCodeResolver,
                    contractor: ContractorEditByResolver,
                    module: ModuleListResolver
                }
            }

        ]
    }
];
