import { BaseServices } from './../../kuba.services';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { environment } from 'src/environments/environment';
import 'rxjs/add/operator/map';
import {
  Activity,
  ActivityHeader,
  FollowUpActivity
} from '../models/activities';
import { PaginatedResult, Pagination } from 'src/app/_models/options';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Injectable()
export class ActivityServices {
  id: number;
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) {}

  // bind business dropdown
  getBusiness() {
    return this.http
      .get(environment.BASE_URL + '/businesses')
      .map(result => result);
  }
  // List all Activity
  list(businessId: number) {
    return this.http
      .get(`${environment.BASE_URL}/businessActivities/${businessId}`)
      .map((res: any) => res);
  }

  getActivityResponsible(businessId: number) {
    return this.http
      .get(`${environment.BASE_URL}/activityResponsible/${businessId}`)
      .map(res => res);
  }
  // List all Activity
  getPortalActiveBusiness() {
    return this.http
      .get(`${environment.BASE_URL}/portalactivebusinesses`)
      .map(res => res);
  }
  // List all business
  activeBusinesslist() {
    return this.http
      .get(environment.BASE_URL + '/activebusinesses')
      .map(result => result);
  }
  // List all business
  responsiblePersons() {
    return this.http
      .get(environment.BASE_URL + '/responsiblepersons')
      .map((result: any) => result);
  }
  // List all Activity
  repetitionByAdmin(appId: number) {
    return this.http
      .get(environment.BASE_URL + '/adminactivityschedule/' + appId)
      .map(res => res);
  }
  // List all Activity
  repetitionByPortal(userId: number) {
    return this.http
      .get(environment.BASE_URL + '/portalactivityschedule/' + userId)
      .map(res => res);
  }
  // List all Activity
  repetitionByBusiness(businessId: number) {
    return this.http
      .get(environment.BASE_URL + '/activityschedulesbybusiness/' + businessId)
      .map(res => res);
  }
  deleteactivity(activity: any) {
    return this.http
      .post(environment.BASE_URL + '/activityChildRecorddelete', activity)
      .map((response: any) => {
        return response;
      });
  }

  /**
   * stop future recurring
   * @param activityId
   */
  stopFutureRecurring(activityId: any) {
    return this.http
      .get(environment.BASE_URL + '/stopFutureRecurringActivity/' + activityId)
      .map((response: any) => {
        return response;
      });
  }

  add(activity: ActivityHeader, applicationId: number) {
    return this.http
      .post(
        environment.BASE_URL + '/followup/postactivity/' + applicationId,
        activity
      )
      .map((response: any) => {
        return response;
      });
  }

  addActivityForPortal(activity: any) {
    return this.http
      .post(environment.BASE_URL + '/activityaddportal', activity)
      .map((response: any) => {
        return response;
      });
  }
  update(activity: FollowUpActivity) {
    return this.http
      .put(environment.BASE_URL + '/followup/putactivity', activity)
      .map(result => result);
  }
  get(id: number) {
    return this.http
      .get(environment.BASE_URL + '/followup/getactivity/' + id)
      .map((res: any) => res);
  }

  getById(id: number) {
    return this.http
      .get(`${environment.BASE_URL}/Lookup/${id}`)
      .map(result => result);
  }

  delete(id: number) {
    return this.http
      .delete(environment.BASE_URL + '/activitydelete/' + id)
      .map(result => result);
  }

  deleteParentwithRecurring(id: number) {
    return this.http
      .delete(
        environment.BASE_URL + '/followup' + '/deleteParentwithRecurring/' + id
      )
      .map(result => result);
  }

  getallRepetition() {
    return this.http.get(environment.BASE_URL + '/repetition').map(result => {
      if (result) {
        return result;
      } else {
        return null;
      }
    });
  }

  // bind Execution and followup
  getAll(id: number) {
    return this.http
      .get(environment.BASE_URL + '/Lookup/' + id)
      .map(result => result);
  }

  getAllFollowUp(id: number) {
    return this.http
      .get(environment.BASE_URL + '/employees/' + id)
      .map(result => result);
  }

  getAllExecution(id: number) {
    return this.http
      .get(environment.BASE_URL + '/employees/' + id)
      .map(result => result);
  }

  getAllRepetition() {
    return this.http
      .get(environment.BASE_URL + '/repetition')
      .map((result: any) => result);
  }

  getActivityLog(businessId: number, id: number) {
    return this.http
      .get(`${environment.BASE_URL}/followup/activitylog/${businessId}/${id}`)
      .map(result => result);
  }

  // bind dropdown Course Name
  getAllByCourseName(id: number) {
    return this.http
      .get(
        environment.BASE_URL +
          '/business/businesscourse/businesscourselist/' +
          id
      )
      .map(result => result);
  }

  getActivitiesByUser(id: number) {
    return this.http
      .get(environment.BASE_URL + '/activitybyuser/' + id)
      .map((result: any) => result);
  }
  getChecklistByBusiness(id: number) {
    return this.http
      .get(
        environment.BASE_URL + '/manual/0 /checklist/checklistbybusiness/' + id
      )
      .map(result => result);
  }
  getAdminActivity(applicationId: number) {
    return this.http
      .get(environment.BASE_URL + '/adminactivities/' + applicationId)
      .map((result: any) => result);
  }
  getPortalActivity(userId: number) {
    return this.http
      .get(environment.BASE_URL + '/portalactivities/' + userId)
      .map((result: any) => result);
  }
  getUserForAdminActivity(activityId: number) {
    return this.http
      .get(environment.BASE_URL + '/usersbybusiness/' + activityId)
      .map(result => result);
  }
  getBusinessPortalContacts(appId: number, userId: number) {
    return this.http
      .get(
        environment.BASE_URL + '/businessportalcontacts/' + appId + '/' + userId
      )
      .map((result: any) => result);
  }

  getActivityCompleteCheckList(activityId: number) {
    return this.http
      .get(
        environment.BASE_URL +
          '/manual/checklist/complete/get/checklistByActivity/' +
          activityId
      )
      .map(result => result);
  }

  /**
   *
   * @param activityId
   */
  checkParentActivity(activityId: number) {
    return this.http
      .get(environment.BASE_URL + '/followup' + '/parentActivity/' + activityId)
      .map(result => result);
  }

  /**
   * Generates a PDF report for the activity.
   * @param businessId Id of the business.
   * @param activityId Id of the activity.
   * @param cultureInfo Language and culture information.
   * @param userId Id of user.
   * @returns Downloadable PDF blob.
   */
  activityReportGenerate(
    businessId: number,
    activityId: number,
    cultureInfo: string,
    userId: number
  ) {
    let dynamicType = 'application/pdf';
    let myData = JSON.stringify({ data: activityId });
    let url = `${environment.BASE_URL}/export-ActivityPrintDetails/${businessId}/${activityId}/${cultureInfo}/${userId}`;
    let crfToken = this.authenticationService.getCsrfToken();
    return Observable.create(observer => {
      let xhr = new XMLHttpRequest();
      xhr.open('POST', url, true);
      xhr.withCredentials = true;
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('Logo-Id', 'ab064fd4-9b4a-4e70-972d-dafdaceb2aba');
      xhr.setRequestHeader('X-XSRF-TOKEN', crfToken);
      xhr.responseType = 'blob';
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            let contentType = dynamicType;
            let blob = new Blob([xhr.response], { type: contentType });
            observer.next(blob);
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };
      xhr.send(myData);
    });
  }
}
