import { DashBoardOwnPageComponent } from './components/dashboard-ownpage.component';
import { Route } from '@angular/router';

import {
  DashboardActivityResolver,
  DashboardDeviationResolver,
  DashboardRiskAnalysisResolver,
  DashboardNewsResolver,
  DashboardBusinessResolver,
  DashboardLoggedUserResolver,
  DashboardAboutKubaResolver,
  DashboardAppLoggedUserResolver,
  DashboardCalendarActivityResolver,
  DashboardLinksListResolver,
  DashboardBusinessFunctionResolver,
  AboutKubaIKResolver,
  DashboardAdminNewsResolver,
  DashboardLinksPortalBusinessResolver,
  DashboardLinksForPortalBusinessResolver
} from './Services/dashboard.resolver.service';
import { AuthGuard } from '../../_guards/index';
import { DashboardComponent } from './index';

export const dashboardRoutes: Route[] = [
  {
    path: '',
    canActivate: [AuthGuard],
    resolve: {
      activity: DashboardActivityResolver,
      deviation: DashboardDeviationResolver,
      riskanalysis: DashboardRiskAnalysisResolver,
      business: DashboardBusinessResolver,
      loggedUser: DashboardLoggedUserResolver,
      appLoggedUser: DashboardAppLoggedUserResolver,
      aboutUs: DashboardAboutKubaResolver,
      calendarActivity: DashboardCalendarActivityResolver,
      links: DashboardLinksListResolver,
      businessFunction: DashboardBusinessFunctionResolver,
      internalNews: DashboardNewsResolver,
      adminNews: DashboardAdminNewsResolver,
      PortalBusiness: DashboardLinksPortalBusinessResolver,
      PortalLinks: DashboardLinksForPortalBusinessResolver
    },
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'dashboard-ownpage/:data',
        component: DashBoardOwnPageComponent,
        resolve: {
          aboutKubaIk: AboutKubaIKResolver
        }
      }
    ]
  }
];
