import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import * as _ from 'lodash';

import { SelectItem } from 'primeng/api';
import { BaseServices } from './../../kuba.services';
import { TaskPlanListDto, ApprovalNotices } from './../../follow-ups/models/riskanalysis';
import { ToasterComponent } from './../../../_directives/toaster.component';
import { JobAnalysisService } from './../services/jobanalysis.services';
import { Safetyjob } from './../models/index';
import { ApprovalNotice } from '../models/index';
import { SafetyBaseComponent } from './safety-base.component';
import { RiskanalysisService } from 'src/app/kuba/follow-ups/services/risk-analysis.service';
import { Module } from 'src/app/kuba/follow-ups/models/deviation';
import { TranslateService } from '@ngx-translate/core';
import { FeatureManualService } from 'src/app/kuba/feature-documents/feature-document.services';
import { Rights } from 'src/app/_models';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
declare var pdfMake: any;
declare var buildPdf: any;
declare var dataBulider: any;
@Component({
    selector: 'safetyjob-list',
    templateUrl: 'safety-job-list.component.html'
})

export class ListComponent extends SafetyBaseComponent implements OnInit {
    //#region  variables
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    @ViewChild('sjaTable',{static: false}) sjaTable: Table;
    test: string;
    sign: any;
    isLocation = true;
    safetyJobId: number;
    isDescription = true;
    isWhatcanHappen = true;
    approvedOn: boolean;
    issues: SelectItem[];
    riskFigures: SelectItem[];
    parentId: any;
    approvalNotices: any = [];
    parentKey: any;
    selectedSJA: any = [];
    isApproved = false;
    safetyDetails = [];
    Topics: SelectItem[];
    safetyJobList:Safetyjob[];
    activitylist: TaskPlanListDto[];
    approvalnotice: ApprovalNotice[];
    approvalList: ApprovalNotice[] = [];
    pdf: any;
    isApprovalHidden = true;
    probability: any;
    consequence: any;
    projectId: number;
    // collapse button
    rowID: number = null!;
    isCollapsed = false;
    isFoodSafety = false;
    showNotification = false;
    selectedReport = [];
    isShowAll = false;
    isGuest = true;
    isUserorguest = false;
    featureId: number;
    loading = false;
    isFS = false;
    isIC = false;
    isHideGuest = true;
    hideButton = true;
    value: boolean;
    isOthers = false;
    generateReportUnderFolder: boolean = false;
    approveall: any;
    private subscriptions: Subscription[] = [];
    //#endregion

    //#region constructor
    /**
     * constructor
     * @param safetyjobservice {JobAnalysisService}
     * @param riskanalysisService {RiskanalysisService}
     * @param confirmationService {ConfirmationService}
     * @param route {ActivatedRoute}
     * @param router {Router}
     * @param translate {TranslateService}
     */
    constructor(private safetyjobservice: JobAnalysisService,
        private riskanalysisService: RiskanalysisService,
        private confirmationService: ConfirmationService,
        private route: ActivatedRoute,
        private router: Router,
        private translate: TranslateService) {
        super();
        this.parentId = route.snapshot.parent!.parent!.params['id'];
        this.parentKey = route.snapshot.parent!.parent!.data['parent']
        let parent = route.snapshot.parent!.parent!.data['parent']
        if (parent === "FS") {
            this.isFS = true;
        } else if (parent === "IC" || parent === 'OS' || parent === 'QS') {
            this.isIC = true;
        } else {
            this.isOthers = true;
        }
        if (+BaseServices.roleId === 4) {
            this.isHideGuest = false;
        }
        switch (parent) {
            case 'PROJECT': this.isApprovalHidden = false;
                this.isFoodSafety = true;
                this.isLocation = false;
                this.isDescription = false;
                this.isWhatcanHappen = true;
                this.projectId = this.parentId;
                this.featureId = Module.PROJECT;
                if (+BaseServices.roleId === 5) {
                    this.hideButton = this.isUserRights(Rights.ADMINISTRATE_PROJECTS);
                }
                break;
            case 'IC': this.isApprovalHidden = false;
                this.isFoodSafety = false;
                this.isLocation = true;
                this.isDescription = true;
                this.isWhatcanHappen = false;
                this.projectId = this.parentId;
                this.featureId = Module.INTERNALCONTROL;
                if (+BaseServices.roleId === 5) {
                    this.hideButton = this.isUserRights(Rights.ADMINISTRATE_INTERNAL_CONTROL);
                }
                break;
            case 'OS': this.isApprovalHidden = false;
                this.isFoodSafety = false;
                this.isLocation = true;
                this.isDescription = true;
                this.isWhatcanHappen = false;
                this.projectId = this.parentId;
                this.featureId = Module.OTHER_SYSTEMS;
                if (+BaseServices.roleId === 5) {
                    this.hideButton = this.isUserRights(Rights.ADMINISTRATE_OTHER_SYSTEMS);
                }
                break;
            case 'QS': this.isApprovalHidden = false;
                this.isFoodSafety = false;
                this.isLocation = true;
                this.isDescription = true;
                this.isWhatcanHappen = false;
                this.projectId = this.parentId;
                this.featureId = Module.QUALITY_SYSTEMS;
                if (+BaseServices.roleId === 5) {
                    this.hideButton = this.isUserRights(Rights.ADMINISTRATE_QUALITY_SYSTEM);
                }
                break;
            case 'FS': this.isApprovalHidden = false;
                this.isFoodSafety = true;
                this.isDescription = true;
                this.isWhatcanHappen = false;
                this.isLocation = true;
                this.projectId = this.parentId;
                this.featureId = Module.FOODSAFETY; if (+BaseServices.roleId === 5) {
                    this.hideButton = false;
                }
                break;
            case 'FDV': this.isApprovalHidden = false;
                this.isFoodSafety = false;
                this.isDescription = true;
                this.isLocation = true;
                this.isWhatcanHappen = false;
                this.projectId = this.parentId;
                this.featureId = Module.FDV;
                if (+BaseServices.roleId === 5) {
                    this.hideButton = this.isUserRights(Rights.ADMINISTRATE_FDV);
                }
                break;
            case 'KUNDE_EL':
                this.isApprovalHidden = false;
                this.isLocation = false;
                this.isDescription = false;
                this.isWhatcanHappen = true;
                this.projectId = this.parentId;
                this.featureId = Module.Kunde_EL;
                if (+BaseServices.roleId === 5) {
                    this.hideButton = this.isUserRights(Rights.IK_KUNDE_EL_RIGHTS);
                }
                break;
            case 'SA':
                this.isApprovalHidden = false;
                this.isLocation = false;
                this.isDescription = false;
                this.isWhatcanHappen = true;
                this.projectId = this.parentId;
                this.featureId = Module.SERVICEAGREEMENT;
                if (+BaseServices.roleId === 5) {
                    this.hideButton = this.isUserRights(Rights.SERVICE_AGREEMENT_RIGHTS);
                }
                break;
            case 'FRAMEWORK':
                this.isApprovalHidden = false;
                this.isLocation = false;
                this.isDescription = false;
                this.isWhatcanHappen = true;
                this.projectId = this.parentId;
                this.featureId = Module.FRAMEWORKAGREEMENT;
                if (+BaseServices.roleId === 5) {
                    this.hideButton = this.isUserRights(Rights.FRAMEWORK_AGREEMENT_RIGHTS);
                }
                break;
        }
    }
    //#endregion

    //#region page-events
    /**
     * page init
     */
    ngOnInit() {
        // To hide side menu bar
        document.querySelector("body").classList.remove("opened");
        if (BaseServices.roleId === '4') {
            this.isGuest = false;
            this.isUserorguest = true;
        }
        if (BaseServices.roleId === '5') {
            this.isUserorguest = true;
        }
        this.bindApprovalNoticeList();
        let toipcs = this.route.snapshot.data['topic'];
        let issueType = this.route.snapshot.data['issue'];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.Topics = [];
            this.Topics.push(
                { label: val.SELECT, value: null }
            );
            if (toipcs) {
                toipcs.forEach((element:any) => {
                    this.Topics.push({ label: element.Name, value: element.Id });
                });
            }
        }));


        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.issues = [];
            this.issues.push(
                { label: val.SELECT, value: null }
            );
            if (issueType) {
                issueType.forEach((element:any) => {
                    this.issues.push({ label: element.Name, value: element.Id });
                });
            }
        }));


        this.bindSJADetails()
        this.subscriptions.push(this.translate.stream('RISKFIGURE_DROPDOWN').subscribe(val => {
            this.riskFigures = [];
            this.riskFigures.push(
                { label: val.SELECT, value: null },
                { label: val.LOW, value: 'Low' },
                { label: val.MEDIUM, value: 'Medium' },
                { label: val.HIGH, value: 'High' },
            );
        }));
    }
    //#endregion

    //#region methods
    showAll() {
        this.isShowAll = true;
        this.isCollapsed = false;
    }
    /**
     * Assigning color to grid depending on risk
     * @param riskFigure {any}
     */
    assignRiskData(riskFigure:any) {
        let riskData: any = [];
        if (riskFigure) {
            switch (riskFigure) {
                case 1:
                    riskData.id = 1;
                    riskData.value = 'Low';
                    riskData.backColor = '#4ec55c';
                    break;
                case 2:
                    riskData.id = 2;
                    riskData.value = 'Medium';
                    riskData.backColor = '#f0ac19';
                    break;
                case 3:
                    riskData.id = 3;
                    riskData.value = 'High';
                    riskData.backColor = '#e7644f';
                    break;
            }
        }
        return riskData;
    }

    /**
        * create list of safetyjobs approved
        * @param i {number}
        */
    generateApproval(i: number) {
        this.subscriptions.push(this.safetyjobservice.addApproval(this.approvalList, this.parentKey)
            .subscribe(result => {
                _.remove(this.approvalList, (x) => {
                    return x.RiskAnalysisId === i;
                })
            }))
    }

    /**
     * delete safetjob
     * @param value {any}
     */
    confirm(value:any) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            header: this.translate.instant('DELETE_CONFIRMATION'),
            // icon: 'pi-trash',
            accept: () => {
                this.subscriptions.push(this.safetyjobservice.deleteRiskAnanlysis(value)
                    .subscribe(result => {
                        this.toasterComponent.callToastDlt();
                        this.bindSJADetails();
                        this.bindApprovalNoticeList();
                    }));
            }
        });
    }

    /**
     * export pdf
     */
    generateReport() {
        if (this.selectedSJA.length > 0) {
            this.loading = true;
            let fileName = this.isIC ? 'IC_SJA' : 'SAFETY_JOB_ANALYSIS';
            let type = 'pdf';
            let position = 0;
            let cultureInfo = sessionStorage.getItem('languageMode')!;
            let ReportJson = this.selectedSJA;
            if(this.approvedOn != null){
                this.generateReportUnderFolder = true;
            }
            else{
                this.generateReportUnderFolder = false;
            }
            this.subscriptions.push(this.safetyjobservice
                .reportGenerate(BaseServices.BusinessId, this.generateReportUnderFolder, BaseServices.UserId, this.projectId, this.featureId, cultureInfo, ReportJson,BaseServices.ApplicationId,true)
                .subscribe(
                    (blob:any) => {
                        this.loading = false;
                        let link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = `${this.translate.instant(fileName)}.${type.toLowerCase()}`;
                        link.click();
                    },
                    (error:any) => {
                        this.loading = false;
                        alert('Export not downloaded');
                    }
                ));
        }
        else {
            alert(this.translate.instant('PLEASE_SELECT_A_REPORT'));
        }
    }

    saveApprovalNotice() {
        let approvalNotices:any = [];
        if (this.selectedSJA.length > 0) {
            this.selectedSJA.forEach((e: any) => {
                let approvalNotice = new ApprovalNotices();
                approvalNotice.Id = 0;
                approvalNotice.BusinessId = BaseServices.BusinessId;
                approvalNotice.RiskAnalysisId = e.Id;
                approvalNotice.ApprovedBy = BaseServices.UserId;
                approvalNotice.ApprovedOn = new Date();
                approvalNotices.push(approvalNotice);
            });
            this.subscriptions.push(this.riskanalysisService.addApprovalNotices(approvalNotices).subscribe(addResult => {
                if (addResult) {
                    this.toasterComponent.callToast();
                    this.bindApprovalNoticeList();
                    this.bindSJADetails();
                }
            }))
        } else {
            this.showNotification = true;
        }
        this.selectedSJA = [];
    }
    bindApprovalNoticeList() {
        this.approvalNotices = [];
        this.subscriptions.push(this.safetyjobservice.getAllApproved(this.parentId, this.parentKey).subscribe((result:any) => {
            if (result) {
                this.approvalNotices = [];
                if (this.parentKey === 'PROJECT') {
                    result.forEach((element:any) => {
                        if (element.FeatureId === 12 || element.FeatureId === 166) {
                            let rightsDetails = JSON.parse(element.Rights);
                            if (rightsDetails) {
                                rightsDetails.forEach((rights:any) => {
                                    if (rights.name === 'READING_SJA_RIGHTS'
                                        && rights.isChecked === true) {
                                        this.approvalNotices.push(element);
                                    }
                                });
                            }
                        } else {
                            this.approvalNotices.push(element);
                        }
                    });
                } else {
                    this.approvalNotices = result;
                }
            }
        }))
    }

    bindSJADetails() {
        this.subscriptions.push(this.safetyjobservice.getAllByEach(this.parentId, this.parentKey).subscribe(result => {
            if (result) {
                this.safetyDetails = result;
            }
        }))
    }
    //#endregion

    //#region control-events
    /**
   * topicDropdown dropdown change event
   * @param e {any}
   */
    onTopicChanged(e: any) {
        if (e.value) {
            // custom filter datatable
            this.sjaTable.filter(e.value, 'TopicId', 'equals');
        } else {
            this.sjaTable.reset();
        }
    }
    /**
 * issueDropdown dropdown change event
 * @param e {any}
 */
    onIssueTypeChanged(e: any) {
        if (e.value) {
            // custom filter datatable
            this.sjaTable.filter(e.value, 'TypeofIssueId', 'equals');
        } else {
            this.sjaTable.reset();
        }
    }
    onRiskFigureChanged(e: any) {
        if (e.value) {
            // custom filter datatable
            this.sjaTable.filter(e.value, 'RiskFigureId', 'equals');
        } else {
            this.sjaTable.reset();
        }
    }
    /**
    * set collapse value
    * @param i {number}
    */
    public setCollapse(i: number): boolean {
        let isCollapsed = (i === this.rowID);
        return !isCollapsed;
    }

    /**
     *  toggle expand icon click
     * @param i {number}
     */
    toggleClick(i: number): void {
        this.rowID = i;
        this.isCollapsed = !this.isCollapsed;
    }

    /**
     *  toggle collapse icon click
     * @param i {number}
     */
    toggleClickHide(i: number): void {
        this.rowID = null!;
    }
    /**
     * check all checkbox
     */
    toggleSelect =  (event:any) => {
        let safetyjoblist:any = new ApprovalNotice();
        this.approveall = event.target.checked;
        safetyjoblist.forEach(function (item:any) {
            item.selected = event.target.checked;
        });
    }

    /**
     * Approval Notice Apprval and unapproval
     * @param event {any}
     * @param i {number}
     */
    checkSelect(event:any, i: number) {
        if (event.target.checked) {
            let approval = new ApprovalNotice();
            approval.Id = 0;
            approval.Name = BaseServices.UserId;
            approval.RiskAnalysisId = i;
            approval.ApprovedBy = BaseServices.UserId;
            approval.ApprovedOn = new Date();
            approval.BusinessId = BaseServices.BusinessId;
            this.approvalList.push(approval);
        } else {
            _.remove(this.approvalList, (x) => {
                return x.RiskAnalysisId === i;
            })
        }
    }
    //#endregion
    isUserRights(rightsId: number): boolean {
        return BaseServices.checkUserRights(
            +rightsId,
            BaseServices.UserRole
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}

