<div *ngIf="file">

    <ecpl-onlyoffice-viewer
        *ngIf="
        file.Path != null &&
        humanizeType(getFileExtension(file.Path)) == 'Document'
        "
        [isDialog]="true"
        [onlyofficeSrc]="file.Path"
        [onlyofficeKey]="file.Id + 'key'"
        [onlyofficeType]="getFileExtension(file.Path)"
        [onlyofficeName]="file.Filename"
        [id]="file.Id"
        (close)="closeDialog()"
    ></ecpl-onlyoffice-viewer>

    <ecpl-image-viewer
        *ngIf="
        file.Path != null &&
        humanizeType(getFileExtension(file.Filename)) == 'Image'
        "
        [isDialog]="true"
        [imgSrc]="file.Path"
        [imgType]="file.MimeType"
        [imgName]="file.Filename"
        [id]="file.Id"
        thumbNail="true"
        (close)="closeDialog()"
    >
    </ecpl-image-viewer>
    <ecpl-pdf-viewer
        *ngIf="
        file.Path != null &&
        humanizeType(getFileExtension(file.Filename)) == 'Pdf'
        "
        [isDialog]="true"
        [id]="file.Id"
        [pdfSrc]="file.Path"
        [pdfName]="file.Filename"
        (close)="closeDialog()"
    ></ecpl-pdf-viewer>

</div>

