import { EmployeeContactUser } from './../../../shared/employee-contact';
import { CustomizeHomePageService } from './../../customize-homepage/customize-homepage.service';
import { EmployeeServices } from './../../employees/services/employee.services';
import { UserService } from './../../users/services/user.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { Observable } from 'rxjs';
import { BusinessList, BusinessView } from '../models/';
import { EmployeeCourse } from './../../employees/models/employeeCourse';
import { EmployeeCourseServices } from './../../employees/services/employeecourse.service';
import { AbsenceCode } from './../models/businessAbsence';
import { BusinessEquipment } from './../models/businessEquipmentType';
import { BusinessAgreement } from './../models/businessList';
import { BusinessServices } from './business.services';
import { BusinessAbsenceService } from './businessAbsenceService';
import { BusinessCategoryService } from './businessCategory.service';
import { BusinessEquipmentService } from './businessEquipment.service';
import { BusinessSettingService } from './businessSetting.service';
import { BusinessSocialAspectsService } from './businessSocialAspects.service';
import { BusinessLogService } from './businessLog.service';

@Injectable()
export class BusinessListResolver implements Resolve<BusinessList[]> {
  constructor(private backend: BusinessServices) {}
  resolve(): Observable<BusinessList[]> {
    return this.backend.list();
  }
}
@Injectable()
export class HomePageEditResolver implements Resolve<any[]> {
  constructor(private backend: CustomizeHomePageService) {}
  resolve(): Observable<any[]> {
    return this.backend.getData(
      BaseServices.ApplicationId,
      45,
      BaseServices.UserId
    );
  }
}

@Injectable()
export class BusinessEditResolver implements Resolve<BusinessView> {
  constructor(private backend: BusinessServices) {}
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getById(+route.params['bid']);
  }
}

@Injectable()
export class BusinessAgreementResolver implements Resolve<BusinessAgreement> {
  constructor(private backend: BusinessServices) {}
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getAgreementByBusinessId(+route.parent?.params['bid']);
  }
}

@Injectable()
export class BusinessEquipmentResolver implements Resolve<BusinessEquipment> {
  constructor(private backend: BusinessEquipmentService) {}
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.list(+route.parent?.params['bid']);
  }
}
@Injectable()
export class BusinessCourseResolverByBusiness
  implements Resolve<EmployeeCourse>
{
  constructor(private backend: EmployeeCourseServices) {}
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.list(+route.parent!.params['bid']);
  }
}

@Injectable()
export class BusinessCourseEditResolver implements Resolve<any> {
  constructor(private backend: EmployeeCourseServices) {}
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getById(+route.params['eid']);
  }
}

@Injectable()
export class BusinessContractEditResolver implements Resolve<any> {
  constructor(private backend: EmployeeCourseServices) {}
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getById(+route.params['eid']);
  }
}

@Injectable()
export class BusinessAbsenceCodeByBusinessResolver
  implements Resolve<AbsenceCode>
{
  constructor(private backend: BusinessAbsenceService) {}
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getAbsenceById(+route.parent?.params['bid']);
  }
}

@Injectable()
export class BusinessSocialAspectsResolver implements Resolve<any> {
  constructor(private backend: BusinessSocialAspectsService) {}
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.listSocialAspects(+route.parent?.params['bid']);
  }
}

@Injectable()
export class UserDropDownResolver implements Resolve<any> {
  constructor(private backend: BusinessSettingService) {}
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getEmployee(BaseServices.BusinessId);
  }
}
@Injectable()
export class UserDropDownResolverForBusiness implements Resolve<any> {
  constructor(private backend: BusinessSettingService) {}
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getEmployee(+route.parent?.params['bid']);
  }
}

@Injectable()
export class LanguageResolver implements Resolve<any> {
  constructor(private backend: BusinessSettingService) {}
  resolve() {
    return this.backend.getLanguage();
  }
}

@Injectable()
export class ThemeResolver implements Resolve<any> {
  constructor(private backend: BusinessSettingService) {}
  resolve() {
    return this.backend.getTheme();
  }
}

@Injectable()
export class SettingByBusinessResolver implements Resolve<any> {
  constructor(private backend: BusinessSettingService) {}
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getSettingByBusiness(+route.parent?.params['bid']);
  }
}

@Injectable()
export class UserByBusinessResolver implements Resolve<any> {
  constructor(private backend: BusinessServices) {}
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getUsersByBusiness(+route.parent?.params['bid']);
  }
}
@Injectable()
export class EmployeeContactUserResolver
  implements Resolve<EmployeeContactUser[]>
{
  constructor(private backend: UserService) {}
  resolve(route: ActivatedRouteSnapshot): Observable<EmployeeContactUser[]> {
    return this.backend.getEmployeeContactUsers(+route.parent.params['bid']);
  }
}

@Injectable()
export class EmployeeOnlyResolver implements Resolve<any> {
  constructor(private backend: UserService) {}
  resolve(route: ActivatedRouteSnapshot): Observable<EmployeeContactUser[]> {
    return this.backend.getEmployeeOnly(+route.parent.params['bid']);
  }
}

@Injectable()
export class HseManagerResolver implements Resolve<any> {
  constructor(private backend: BusinessServices) {}
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getHseManager(+route.parent?.params['bid']);
  }
}
@Injectable()
export class HavePersonalDelegateResolver implements Resolve<any[]> {
  constructor(private backend: BusinessServices) {}
  resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
    return this.backend.getHpdOptions(+route.parent?.params['bid']);
  }
}

@Injectable()
export class PortalContactResolver implements Resolve<any> {
  constructor(private backend: BusinessServices) {}
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getPortalContact(+route.parent?.params['bid']);
  }
}
@Injectable()
export class BusinessCourseListResolver implements Resolve<any> {
  constructor(private backend: EmployeeCourseServices) {}
  resolve(route: ActivatedRouteSnapshot) {
    let url = route.url[0].path;
    let IsEmployeeCourse = false;
    if (url == 'contractlist') {
      IsEmployeeCourse = false;
    } else if (url == 'editContract') {
      IsEmployeeCourse = false;
    } else {
      IsEmployeeCourse = true;
    }
    return this.backend.getAllByCourseName(
      +route.parent?.params['bid'],
      IsEmployeeCourse
    );
  }
}
@Injectable()
export class BusinessUserEditResolver implements Resolve<any> {
  constructor(private backend: BusinessServices) {}
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getByUserId(+route.params['uid']);
  }
}
@Injectable()
export class BusinessCategoryResolver implements Resolve<any> {
  constructor(private backend: BusinessCategoryService) {}
  resolve() {
    return this.backend.getAllCategories();
  }
}

@Injectable()
export class SocialAspectTypeDropdownResolver implements Resolve<any> {
  constructor(private backend: BusinessSocialAspectsService) {}
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.gettype(+route.parent?.params['bid']);
  }
}

@Injectable()
export class PortalContactListResolver implements Resolve<any[]> {
  constructor(private backend: BusinessSettingService) {}
  resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
    return this.backend.getPortalContacts(BaseServices.UserId);
  }
}
@Injectable()
export class HseAdviserResolver implements Resolve<any[]> {
  constructor(private backend: BusinessSettingService) {}
  resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
    return this.backend.getHseAdviser();
  }
}
@Injectable()
export class PortalBusinessResolver implements Resolve<any> {
  constructor(private backend: BusinessSettingService) {}
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getPortalBusiness(+route.parent?.params['bid']);
  }
}

@Injectable()
export class BusinessLogListResolver implements Resolve<any[]> {
  constructor(private backend: BusinessLogService) {}
  resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
    return this.backend.getAllLogs(+route.parent?.params['bid']);
  }
}
@Injectable()
export class EmployeeCountResolver implements Resolve<any[]> {
  constructor(private backend: EmployeeServices) {}
  resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
    if (BaseServices.roleId === '1' || BaseServices.roleId === '2') {
      return this.backend.list(+route.params['bid']);
    } else {
      return this.backend.list(BaseServices.BusinessId);
    }
  }
}
@Injectable()
export class BusinessCacheResolver implements Resolve<any[]> {
  constructor(private backend: BusinessServices) {}
  resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
    return this.backend.list();
  }
}
@Injectable()
export class BusinessCreatorResolver implements Resolve<any> {
  constructor(private backend: BusinessServices) {}
  resolve(): Observable<any> {
    return this.backend.getBusinessCreator(BaseServices.BusinessId);
  }
}
@Injectable()
export class CompanyNameResolver implements Resolve<any[]> {
  constructor(private backend: BusinessServices) {}
  resolve() {
    if (BaseServices.roleId === '1' || BaseServices.roleId === '2') {
      let res: any = this.backend.activeBusinesslist();
      return res;
    }
  }
}

@Injectable()
export class BusinessDepartmentResolver implements Resolve<any> {
  constructor(private backend: BusinessServices) {}
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.businessDepartments(BaseServices.BusinessId);
  }
}

@Injectable()
export class BusinessDataResolver implements Resolve<any> {
  constructor(private backend: BusinessServices) {}
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getById(+route.parent?.params['bid']);
  }
}

@Injectable()
export class BusinessDataFeatureResolver implements Resolve<any> {
  constructor(private backend: BusinessServices) {}
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getById(+route.params['bid']);
  }
}

@Injectable()
export class LicenseCategoryResolver implements Resolve<any> {
  constructor(private backend: BusinessServices) {}
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getAllLicenseCategory();
  }
}
@Injectable()
export class RiskListResolver implements Resolve<any[]> {
  constructor(private backend: BusinessServices) {}
  resolve(): Observable<any[]> {
    return this.backend.getPortalRiskAnalysis(BaseServices.UserId);
  }
}

@Injectable()
export class BusinessUsersCountResolver implements Resolve<any> {
  constructor(private backend: BusinessServices) {}
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getUsersCount(+route.parent?.params['bid']);
  }
}

@Injectable()
export class BusinessEmployeesCountResolver implements Resolve<any> {
  constructor(private backend: BusinessServices) {}
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getEmployeesCount(+route.parent?.params['bid']);
  }
}
