import { Module } from 'src/app/kuba/follow-ups/models/deviation';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormArray,
  FormControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';

import { BaseServices } from './../../kuba.services';
import { Options } from 'src/app/_models/options';
import { ReceptionControlService } from './../services/reception-control.service';
import {
  DeviationFile,
  GoodsType,
  ReceptionControl
} from './../models/reception-control';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { ValidationService } from './../../shared/services/validation.service';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { UploaderConfig } from 'src/app/shared/ecpl-document-explorer/uploader-config';
import { environment } from 'src/environments/environment';
import { HelperService } from 'src/app/_services/helper.service';
import { EmployeeServices } from 'src/app/kuba/employees/services/employee.services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'reception-control-form',
  templateUrl: 'reception-control-form.component.html'
})
export class ReceptionControlFormComponent implements OnInit {
  //#region variable
  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  receptionForm: FormGroup;
  supplierList: any;
  receptionData: any;
  goodsTypeData: any;
  selectedType = new GoodsType();
  isEditing: boolean = false;
  goodsTypeForm: FormGroup;
  goodsTypeId: number;
  isDescription: boolean;
  foodSafetyId: number;
  receptionId = 0;
  goodsList: any;
  addGoodsType = false;
  addSupplier = false;
  viewGoodsType = false;
  measurementUnits: any;
  receptionLogData: any;
  roleId: number;
  receptionNo: any;
  sectionNames = [
    'PACKAGING',
    'TEMPERATURE',
    'LABELLING',
    'QUALITY',
    'ODOR',
    'IMPURITIES',
    'PESTS'
  ];
  sectionNamesNor = [
    'EMBALLASJE',
    'TEMPERATUR',
    'MERKING',
    'KVALITET',
    'LUKT',
    'UNRENHETER',
    'SKADEDYR'
  ];
  units = [
    { name: 'KG', code: 'KG' },
    { name: 'L', code: 'L' },
    { name: 'UNIT', code: 'UNIT' }
  ];

  options = [new Options(8, 'OPEN'), new Options(11, 'CLOSED')];
  upConfig: UploaderConfig;
  additionalData: any;
  newDocumentAdded: any = [];
  uploadedFiles: any = [];
  parentId: number;
  upDeviationConfig: UploaderConfig[] = [];
  deviationData: any;
  newDeviationDocumentAdded: any = [];
  uploadedDeviationFiles: DeviationFile[] = [];
  invalidFormat = false;

  private subscriptions: Subscription[] = [];
  //#endregion

  //#region constructor
  /**
   * Constructor
   * @param _fb {FormBuilder}
   * @param route {ActivatedRoute}
   * @param location {Location}
   * @param receptionControlService {ReceptionControlService}
   * @param confirmationService {ConfirmationService}
   */
  constructor(
    private _fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    protected location: Location,
    public receptionControlService: ReceptionControlService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    public employeeServices: EmployeeServices
  ) {
    this.supplierList = [];
    let supplierData = route.snapshot.data['suppliers'];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
        this.supplierList = [];
        this.supplierList.push({ label: val.SELECT, value: null });
        if (supplierData) {
          supplierData.forEach(element => {
            this.supplierList.push({
              label: element.Name,
              value: `${element.Id}`
            });
          });
        }
      })
    );
    this.goodsList = [];
    this.goodsTypeData = route.snapshot.data['goodsType'];

    this.subscriptions.push(
      this.translate.stream('UNIT').subscribe(val => {
        this.measurementUnits = [];
        this.units.forEach(element => {
          if (element.name == 'UNIT')
            this.measurementUnits.push({
              label: val,
              value: `${element.code}`
            });
          else
            this.measurementUnits.push({
              label: element.name,
              value: `${element.code}`
            });
        });
      })
    );
    this.goodsTypeForm = this._fb.group({
      Name: ['', [ValidationService.noWhitespaceValidator]]
    });
  }
  //#endregion

  //#region page-event
  /**
   * Initialization
   */
  ngOnInit() {
    document.querySelector('body').classList.remove('opened');

    this.populateGoodsList();

    if (this.route.snapshot.data['reception']) {
      this.receptionData = this.route.snapshot.data['reception'];
      this.initForm(this.receptionData);
      this.uploadedDeviationFiles = this.receptionData.CheckListNames;
    } else {
      this.initForm();
    }

    this.roleId = parseInt(BaseServices.roleId);
    if (+this.route.snapshot.params['rid'] != null) {
      this.receptionId = +this.route.snapshot.params['rid'];
    } else if (+this.route.snapshot.queryParams['reception'] != null) {
      this.receptionId = +this.route.snapshot.queryParams['reception'];
    }

    if (+this.route.snapshot.params['fid'] != null) {
      this.foodSafetyId = +this.route.parent.parent.params['_value'].id;
    }
    if (this.receptionId === 0) {
      this.receptionNoIncrement();
    }
    let workingModule = JSON.parse(sessionStorage.getItem('workingModule'));
    this.parentId = workingModule.id;
    this.receptionControlLogList();
    this.additionalData = {
      ApplicationId: BaseServices.ApplicationId,
      BusinessId: BaseServices.BusinessId,
      ReceptionControlId: this.receptionId != null ? this.receptionId : 0,
      status: 1,
      CreatedOn: new Date(),
      CreatedBy: BaseServices.UserId,
      ModifiedBy: BaseServices.UserId,
      ModifiedOn: new Date(),
      FeatureKey: this.foodSafetyId,
      FeatureId: this.parentId
    };
    this.deviationData = {
      ApplicationId: BaseServices.ApplicationId,
      BusinessId: BaseServices.BusinessId,
      Status: '1',
      CreatedOn: new Date(),
      ModifiedBy: BaseServices.UserId,
      ModifiedOn: new Date(),
      ParentId: this.parentId ? this.parentId : 0,
      ChildId: 0,
      ModuleName: 'Deviation'
    };

    this.upConfig = {
      title: 'RECEPTION_CONTROL_DOCUMENT',
      titleAsLabel: true,
      editMode: true,
      windowedHeight: false,
      viewSwitch: false,
      showUserMeta: false,
      showSearchFilter: false,
      showUploadButton: true,
      showDeleteButton: BaseServices.roleId === '5' ? false : true,
      uploaderUri:
        environment.BASE_URL + '/file/upload/application/receptioncontrol',
      addtionalData: this.additionalData
    };

    let data;
    for (var i = 0; i < 7; i++) {
      data = [];
      data = {
        title: 'DEVIATION_DOCUMENT',
        titleAsLabel: true,
        editMode: true,
        windowedHeight: false,
        viewSwitch: false,
        showUserMeta: false,
        showSearchFilter: false,
        showUploadButton: true,
        showDeleteButton: BaseServices.roleId === '5' ? false : true,
        uploaderUri: environment.BASE_URL + '/foodsafety/file-upload',
        addtionalData: this.deviationData
      };
      if (
        this.uploadedDeviationFiles.length == 0 ||
        this.uploadedDeviationFiles == undefined
      ) {
        data.editMode = true;
        data.showDeleteButton = true;
      } else {
        data.editMode =
          this.uploadedDeviationFiles[i] &&
          this.uploadedDeviationFiles[i].DeviationAttachment &&
          this.uploadedDeviationFiles[i].DeviationAttachment.length <= 0
            ? true
            : false;
        data.showDeleteButton =
          this.uploadedDeviationFiles[i] &&
          this.uploadedDeviationFiles[i].DeviationAttachment &&
          this.uploadedDeviationFiles[i].DeviationAttachment.length <= 0
            ? true
            : false;
      }
      this.upDeviationConfig.push(data);
    }
    let docList = this.route.snapshot.data['docList'];

    if (docList && docList.length > 0) {
      this.uploadedFiles = this.route.snapshot.data['docList'];
    }
  }

  initForm(data?: any) {
    let receptionNumber = 0;
    let Id = 0;
    let supplier = '';
    let qtyLeft = '';
    let qtyMeasurement = '';
    let kindOfGoods = '';
    let temperature = '';
    let additionalInformation = '';
    let receptionArray: FormArray = new FormArray([]);
    let description = '';
    let status = 8;
    if (data) {
      this.isEditing = !!data;
      this.receptionNo = data.ReceptionNo ? data.ReceptionNo : '';
      status = (data.Status && data.Status == 11) || data.Status == 9 ? 11 : 8;
      Id = data.Id ? data.Id : '';
      receptionNumber = data.ReceptionNo ? data.ReceptionNo : '';
      supplier = data.SupplierId ? data.SupplierId : '';
      qtyLeft = data.Quantity ? data.Quantity : '';
      qtyMeasurement = data.Measurement ? data.Measurement : 'KG';
      kindOfGoods = data.GoodsTypeId ? `${data.GoodsTypeId}` : '';
      temperature = data.Temperature ? data.Temperature : '';
      additionalInformation = data.AdditionalInformation
        ? data.AdditionalInformation
        : '';
      description = data.Description ? data.Description : '';
    }
    this.receptionForm = this._fb.group({
      Status: new FormControl(status),
      ReceptionNumber: new FormControl(receptionNumber),
      Supplier: new FormControl(`${supplier}`, Validators.required),
      QuantityLeft: new FormControl(qtyLeft),
      QuantityMeasurement: new FormControl(qtyMeasurement),
      KindsOfGoods: new FormControl(kindOfGoods, Validators.required),
      Temperature: new FormControl(temperature),
      AdditionalInformation: new FormControl(additionalInformation),
      Description: new FormControl(description),
      receptionArray: receptionArray
    });
    // add three formarrays
    if (data && data.CheckListNames.length > 0) {
      data.CheckListNames.forEach(element => {
        let checkListIndex = this.sectionNames.indexOf(
          element.checkListName.toUpperCase()
        );
        if (checkListIndex > -1) {
          this.addReceptionCheckList(
            this.sectionNames[checkListIndex],
            element
          );
        }
      });
    } else {
      this.receptionForm.value.receptionArray = [];
      for (let index = 0; index <= 6; index++)
        this.addReceptionCheckList(this.sectionNames[index]);
    }

    // Set default status if the form is new (data is undefined)
    if (!data) {
      this.receptionForm.controls['Status'].setValue('8');
    }
  }

  populateGoodsList() {
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
        this.goodsList = [];
        this.goodsList.push({ label: val.SELECT, value: null });

        if (this.goodsTypeData && this.goodsTypeData.length > 0) {
          this.goodsTypeData.forEach(element => {
            this.goodsList.push({
              label: element.Name,
              value: `${element.Id}`
            });
          });
        }

        if (this.isEditing) {
          let fetchedGoodsType = this.receptionForm.get('KindsOfGoods').value;
          fetchedGoodsType = fetchedGoodsType ? `${fetchedGoodsType}` : null;
          if (
            fetchedGoodsType &&
            this.goodsList.find(item => item.value === fetchedGoodsType)
          ) {
            this.receptionForm.get('KindsOfGoods').setValue(fetchedGoodsType);
          }
        } else {
          if (this.goodsList.length > 1) {
            this.receptionForm
              .get('KindsOfGoods')
              .setValue(this.goodsList[1].value);
          }
        }
      })
    );
  }
  //#endregion

  //#region methods
  addReceptionCheckList(sectionName, data?: any) {
    let title = 'RC-' + this.receptionNo + ' - ' + sectionName;
    let comment = '';
    let isOk = true;
    let check2 = false;
    let deviationId = 0;
    let id = 0;
    let disble = false;
    if (data) {
      id = isOk = data.Id;
      isOk = data.OkStatus;
      check2 = data.OkStatus ? false : true;
      title = data.Title ? data.Title : '';
      comment = data.Comment ? data.Comment : '';
      deviationId = data.DeviationId ? data.DeviationId : 0;
      if (id > 0 && comment != '') {
        disble = true;
      } else {
        disble = false;
      }
    }

    (<FormArray>this.receptionForm.controls['receptionArray']).push(
      new FormGroup({
        Id: new FormControl(id),
        ReceptionControlCheckListId: new FormControl(
          ReceptionChecklist[sectionName.toUpperCase()]
        ),
        DeviationId: new FormControl(deviationId),
        IsOk: new FormControl(isOk),
        check2: new FormControl(check2),
        Title: new FormControl(title),
        Comment: new FormControl(comment),
        Isdisabled: new FormControl(disble),
        DeviationAttachment: new FormArray([])
      })
    );
  }
  /**
   * back to the list
   */
  gotoList() {
    this.location.back();
  }
  /**
   * Edit the goods type
   * @param goodsType {GoodsType}
   */
  editGoodsType(goodsType: GoodsType) {
    this.selectedType = goodsType;
    this.goodsTypeId = goodsType.Id;
  }

  /**
   * delete the goods type
   * @param goodsTypeId
   */
  deleteGoodsType(goodsTypeId: number) {
    this.confirmationService.confirm({
      message: this.translate.instant('DELETE_THIS_RECORD'),
      accept: () => {
        let deleteGoodsTypeid = goodsTypeId;
        this.subscriptions.push(
          this.receptionControlService
            .deleteGoodsType(deleteGoodsTypeid)
            .subscribe((result: any) => {
              this.bindGoodsTypeDetails();
              this.toasterComponent.callToastDlt();
            })
        );
      }
    });
  }

  /**
   * Bind the goods type details
   */
  bindGoodsTypeDetails() {
    this.subscriptions.push(
      this.receptionControlService
        .getGoodsTypeByBusiness(BaseServices.BusinessId)
        .subscribe((goodsTypeList: any) => {
          this.goodsTypeData = goodsTypeList;
        })
    );
  }
  /**
   * Add/Update the goods type
   */
  saveGoodsType() {
    let goodsType = new GoodsType();
    goodsType.Id = this.goodsTypeId ? this.goodsTypeId : 0;
    goodsType.Name = this.goodsTypeForm.value.Name;
    goodsType.Status = '1';
    goodsType.BusinessId = BaseServices.BusinessId;
    goodsType.AppId = BaseServices.ApplicationId;
    if (goodsType.Id > 0) {
      goodsType.ModifiedBy = BaseServices.UserId;
      this.subscriptions.push(
        this.receptionControlService
          .updateGoodsType(goodsType)
          .subscribe((goodsTypeEdited: any) => {
            if (goodsTypeEdited) {
              this.bindGoodsTypeDetails();
              this.toasterComponent.callToast();
              this.goodsTypeForm.get('Name').patchValue(goodsTypeEdited.Id);
              this.goodsTypeId = 0;
            }
          })
      );
    } else {
      goodsType.CreatedBy = BaseServices.UserId;
      this.subscriptions.push(
        this.receptionControlService
          .createGoodsType(goodsType)
          .subscribe((goodsTypeAdded: any) => {
            if (goodsTypeAdded) {
              this.bindGoodsTypeDetails();
              this.toasterComponent.callToast();
              this.goodsTypeForm.get('goodsType').patchValue(goodsTypeAdded.Id);
            }
          })
      );
    }

    this.clear();
  }

  clear() {
    this.goodsTypeId = 0;
    this.goodsTypeForm = this._fb.group({
      Name: ['']
    });
  }
  onGoodsTypeFormHide() {
    this.goodsTypeForm.reset();
    this.goodsList = [];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
        this.goodsList = [];
        this.goodsList.push({ label: val.SELECT, value: null });
        if (this.goodsTypeData) {
          this.goodsTypeData.forEach(element => {
            this.goodsList.push({
              label: element.Name,
              value: `${element.Id}`
            });
          });
        }
      })
    );
  }
  /**
   * checkbox change events
   */
  checkboxChange(e: any, index: number, controlName = 'D') {
    const formArray = <FormArray>this.receptionForm.controls['receptionArray'];
    formArray.controls[index].get('IsOk').patchValue(controlName !== 'D');
    formArray.controls[index].get('check2').patchValue(controlName === 'D');
  }

  /**
   * save the reception details
   */
  saveReception() {
    let receptionData = this.receptionData
      ? this.receptionData
      : new ReceptionControl();
    receptionData.BusinessId = BaseServices.BusinessId;
    receptionData.CultureInfo = sessionStorage.getItem('languageMode');
    receptionData.Description = this.receptionForm.value.Description;
    receptionData.GoodsTypeId = this.receptionForm.value.KindsOfGoods;
    receptionData.Measurement = this.receptionForm.value.QuantityMeasurement
      .code
      ? this.receptionForm.value.QuantityMeasurement.code
      : 'KG';
    receptionData.Temperature = this.receptionForm.value.Temperature
      ? this.receptionForm.value.Temperature
      : '0';
    receptionData.AdditionalInformation =
      this.receptionForm.value.AdditionalInformation;
    receptionData.SupplierId = this.receptionForm.value.Supplier;
    receptionData.Quantity = this.receptionForm.value.QuantityLeft
      ? this.receptionForm.value.QuantityLeft
      : '0';
    receptionData.Status = this.receptionForm.value.Status;
    receptionData.FeatureId = Module.FOODSAFETY;
    receptionData.FeatureKey = this.route.parent.parent.params['_value'].id;
    receptionData.CreatedBy = BaseServices.UserId;
    receptionData.ReceptionNo = this.receptionForm.value.ReceptionNumber;
    // KW-645 4th point | image attachment
    let data = [];
    if (
      this.newDeviationDocumentAdded != null ||
      this.newDeviationDocumentAdded != undefined
    ) {
      for (var i = 0; i < 6; i++) {
        data = [];
        data = this.newDeviationDocumentAdded.filter(
          indexId => indexId.SectionNamesIndex == i
        );
        this.receptionForm.value.receptionArray[i].DeviationAttachment = [];
        this.receptionForm.value.receptionArray[i].DeviationAttachment = data;
      }
    }

    receptionData.ReceptionControlCheckListItemDto =
      this.receptionForm.value.receptionArray;
    receptionData.ReceptionDocument = this.newDocumentAdded;
    if (receptionData.CultureInfo == 'en-GB') {
      this.receptionForm.value.receptionArray.forEach((element, i) => {
        element.Title =
          'RC-' +
          this.receptionForm.value.ReceptionNumber +
          '-' +
          this.sectionNames[
            element.ReceptionControlCheckListId - 1
          ].toUpperCase();
      });
    } else {
      this.receptionForm.value.receptionArray.forEach(element => {
        element.Title =
          'RC-' +
          this.receptionForm.value.ReceptionNumber +
          '-' +
          this.sectionNamesNor[
            element.ReceptionControlCheckListId - 1
          ].toUpperCase();
      });
    }
    this.receptionControlService
      .addReceptionControl(receptionData)
      .subscribe((isAdded: any) => {
        if (isAdded) {
          this.receptionId = isAdded.Id;
          this.fetchUpdatedData();
        }
      });
  }
  fetchUpdatedData() {
    this.subscriptions.push(
      this.receptionControlService
        .getAll(this.foodSafetyId)
        .subscribe(result => {
          if (result != null) {
            this.toasterComponent.callToast();
          }
        })
    );
    this.receptionForm.reset();
    setTimeout(() => {
      this.initForm();
      this.gotoList();
    }, 1000);
  }

  clearform() {
    this.uploadedFiles = [];
  }
  /**
   * Get the next reception number
   */
  receptionNoIncrement() {
    // to be reviewed
    this.subscriptions.push(
      this.receptionControlService
        .getNextReceptionNo()
        .subscribe((newReceptionId: any) => {
          this.receptionForm
            .get('ReceptionNumber')
            .patchValue(newReceptionId ? newReceptionId : 1);
        })
    );
  }
  receptionControlLogList() {
    if (this.receptionId) {
      this.subscriptions.push(
        this.receptionControlService
          .getReceptionLog(this.receptionId)
          .subscribe(res => {
            this.receptionLogData = res;
          })
      );
    }
  }
  /**
   * mobilenumber keypress event restrict number greater than 20 and restrict text
   */
  onNumberOfDays(e: any, limitNumber: any) {
    const limit = limitNumber;
    let charCode = e.which ? e.which : e.keyCode;
    if (
      e.target.value.length === limit ||
      (charCode > 31 && (charCode < 48 || charCode > 57))
    ) {
      e.preventDefault();
    }
  }

  /**
   * file upload events
   * @param event {any}
   */
  saveUploaded(event: any) {
    if (event) {
      let eventType = event.eventName;
      let keyBindings = {
        Originalname: 'OriginalFileName',
        FileTypeId: 'FileType'
      };
      let receptionDoc = Object.assign({}, event, this.additionalData);
      switch (eventType) {
        case 'DeleteDefaultFile':
          if (this.uploadedFiles.length > 0) {
            this.uploadedFiles = this.uploadedFiles.filter(
              (x: any) => x.path !== event.item.data.path
            );
          }
          if (event.item.data.id === 0) {
            this.newDocumentAdded = this.newDocumentAdded.filter(
              (x: any) => x.Path !== event.item.data.path
            );
          } else {
            //  remove existing attachement
            this.subscriptions.push(
              this.receptionControlService
                .deleteReceptionControlDocument(
                  this.receptionId,
                  this.foodSafetyId,
                  event.item.data.id
                )
                .subscribe((result: any) => {
                  if (result) {
                    // remove from display list
                    this.uploadedFiles = this.uploadedFiles.filter(
                      (x: any) => x.path !== event.item.data.path
                    );
                    this.toasterComponent.callToastDlt();
                  }
                })
            );
          }
          break;
        default:
          receptionDoc.id = 0;
          receptionDoc.createdBy = BaseServices.UserId;
          receptionDoc.status = 1;
          this.newDocumentAdded.push(
            HelperService.switchKeysCase(receptionDoc, keyBindings)
          );
          break;
      }
    }
  }

  saveUploadDeviationDoc(event: any, i: any) {
    if (event) {
      this.invalidFormat = false;
      let eventType = event.eventName;
      let keyBindings = {
        Originalname: 'OriginalFileName',
        FileTypeId: 'FileType'
      };
      let receptionDoc = Object.assign({}, event, this.additionalData);
      switch (eventType) {
        case 'DeleteDefaultFile':
          this.uploadedFiles = this.uploadedFiles.filter(
            (x: any) => x.path !== event.item.data.path
          );
          if (event.item.data.id === 0) {
            this.newDeviationDocumentAdded =
              this.newDeviationDocumentAdded.filter(
                (x: any) => x.Path !== event.item.data.path
              );
          } else {
            //  remove existing attachement
            this.subscriptions.push(
              this.receptionControlService
                .deleteReceptionControlDocument(
                  this.receptionId,
                  this.foodSafetyId,
                  event.item.data.id
                )
                .subscribe((result: any) => {
                  if (result) {
                    // remove from display list
                    this.uploadedFiles = this.uploadedFiles.filter(
                      (x: any) => x.path !== event.item.data.path
                    );
                    this.toasterComponent.callToastDlt();
                  }
                })
            );
          }
          break;
        default:
          receptionDoc.id = 0;
          receptionDoc.createdBy = BaseServices.UserId;
          receptionDoc.status = 1;
          receptionDoc.sectionNamesIndex = i;
          this.newDeviationDocumentAdded.push(
            HelperService.switchKeysCase(receptionDoc, keyBindings)
          );
          break;
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
  //#endregion
}

export enum ReceptionChecklist {
  PACKAGING = 1,
  TEMPERATURE = 2,
  LABELLING = 3,
  QUALITY = 4,
  ODOR = 5,
  IMPURITIES = 6,
  PESTS = 7
}
