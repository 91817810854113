<div class="tab-content">
  <div class="page-title">
    <button
      *ngIf="hideNewUserBackButton"
      class="btn btn-icon"
      (click)="pushToBack()"
    >
      <span class="icon ic-sm icon-back"></span>
    </button>
    <span *ngIf="translateUser === true; else other" translate>MY_USER</span>
    <ng-template #other>
      <span *ngIf="!hideNewUserBackButton && Id == 0" translate>USER_LIST</span>
      <span *ngIf="hideNewUserBackButton" translate>NEW_USER</span>
      <span *ngIf="!hideNewUserBackButton && Id > 0" translate>EDIT_USER</span>
    </ng-template>
    <div class="page-actions text-right">
      <a
        *ngIf="hideNewUserButton"
        (click)="clickNewUser()"
        class="btn btn-success pull-right"
      >
        <span class="icon ic-sm icon-add"></span>
        <span translate>NEW_USER</span>
      </a>
    </div>
  </div>
  <form
    *ngIf="hideUserForm"
    class="form"
    [formGroup]="portalUserForm"
    #f="ngForm"
    novalidate
    name="form"
  >
    <div class="row">
      <div class="col-md-8">
        <user-form
          *ngIf="portalUserForm"
          (setPortalUserRights)="onCheckStatus($event)"
          [portalUserRightsData]="portalRightsData"
          [portalUSerId]="portalId"
          [userForm]="portalUserForm"
        >
        </user-form>
        <div class="form-group">
          <div class="upload-file-container">
            <label translate>USER_IMAGE</label>
            <div class="upload-actions">
              <input
                type="file"
                id="files"
                class="hidden"
                (change)="filesSelectForLogo($event)"
              />
              <label for="files" class="btn btn-outline-primary" translate
                >UPLOAD_IMAGE</label
              >
            </div>
            <div *ngIf="uploadStartForLogo">
              <span translate>IMAGE_IS_UPLOADING</span>
            </div>
            <div *ngIf="userImage != null">
              <img
                src="{{ userImage }}"
                alt=""
                style="max-width: 200px; max-height: 200px"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div *ngIf="assignBusiness">
          <span
            class="page-title title-equal-space"
            *ngIf="roleId === 1 || (roleId === 2 && isPortalManager === true)"
            translate
            >ASSIGN_ACCESS</span
          >
          <span
            class="page-title title-equal-space"
            *ngIf="roleId === 2 && isPortalManager === false"
            translate
            >ASSIGNED_ACCESS</span
          >

          <!-- Table container with fixed height using inline styles -->
          <div style="height: 500px; overflow-y: auto; overflow-x: hidden">
            <p-table #ct [value]="businessList" dataKey="Id">
              <!-- Header -->
              <ng-template pTemplate="header">
                <tr>
                  <th width="35px">
                    <p-checkbox
                      [disabled]="disabled"
                      [ngModel]="checkedAll"
                      (ngModelChange)="checkAll($event)"
                      [ngModelOptions]="{ standalone: true }"
                      binary="true"
                    ></p-checkbox>
                  </th>
                  <th pSortableColumn="CompanyName">
                    {{ "NAME" | translate }}
                    <p-sortIcon field="CompanyName"></p-sortIcon>
                  </th>
                </tr>
                <tr>
                  <th></th>
                  <th>
                    <input
                      pInputText
                      type="text"
                      (input)="
                        ct.filter(
                          $any($event.target)?.value,
                          'CompanyName',
                          'contains'
                        )
                      "
                      placeholder=""
                      class="p-column-filter"
                    />
                  </th>
                </tr>
              </ng-template>
              <!-- Body Content -->
              <ng-template pTemplate="body" let-user>
                <tr>
                  <td width="35px">
                    <p-checkbox
                      [disabled]="disabled"
                      [(ngModel)]="user.status"
                      (ngModelChange)="checked(user)"
                      [ngModelOptions]="{ standalone: true }"
                      binary="true"
                    >
                    </p-checkbox>
                  </td>
                  <td>
                    <span>{{ user.CompanyName }}</span>
                  </td>
                </tr>
              </ng-template>
              <!-- Empty message -->
              <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                  <td colspan="2">
                    {{ "NO_RECORDS_FOUND" | translate }}
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
          <!-- Table ends -->
        </div>
      </div>
    </div>

    <div *ngIf="hidePassword">
      <label>
        <span translate>YOUR_RANDOM_PASSWORD</span>:
        <span style="color: green">{{ showPassword }}</span>
      </label>
    </div>
    <div class="action-btns-wrapper">
      <button
        *ngIf="roleId === 1"
        class="btn btn-outline-secondary"
        type="button"
        (click)="gotoList()"
      >
        <span class="icon ic-sm icon-back"></span>
        <span translate>BACK_LIST</span>
      </button>
      <button
        *ngIf="hideBackButton"
        class="btn btn-outline-secondary"
        (click)="goBackLocation()"
      >
        <span class="icon ic-sm icon-back"></span>
        <span translate>BACK</span>
      </button>

      <button
        class="btn btn-outline-primary"
        type="button"
        *ngIf="hideRandomPassword"
        (click)="generateRandomPassword()"
      >
        <span translate>RANDOM_PASSWORD</span>
      </button>

      <button
        *ngIf="hideSave"
        class="btn btn-primary"
        [ngClass]="{ loading: loading }"
        type="button"
        (click)="saveChanges()"
        [disabled]="!portalUserForm.valid"
      >
        <span *ngIf="!loading" translate>SAVE</span>
        <span *ngIf="loading" class="loader-icon"></span>
      </button>
      <button
        class="btn btn-primary"
        (click)="showResetPassword()"
        *ngIf="!isExistingUser"
      >
        <span translate>RESET_PASSWORD</span>
      </button>
    </div>
  </form>
  <div class="pt-4">
    <span class="page-title title-equal-space" translate>USER_LIST</span>
  </div>

  <div class="table-view" *ngIf="showUserList">
    <!-- Table starts -->
    <p-table
      var="user"
      [value]="portalUserList"
      [rows]="10"
      #dt
      [paginator]="true"
      [pageLinks]="3"
      [rowsPerPageOptions]="[5, 10, 20]"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{{ 'SUMMARY_TOTALRECORDS' | translate }}"
    >
      <!-- Header -->
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="Name">
            {{ "NAME" | translate }} <p-sortIcon field="Name"></p-sortIcon>
          </th>
          <th pSortableColumn="Username">
            {{ "USERNAME" | translate }}
            <p-sortIcon field="Username"></p-sortIcon>
          </th>
          <th pSortableColumn="Email">
            {{ "Email" | translate }} <p-sortIcon field="Email"></p-sortIcon>
          </th>
          <th pSortableColumn="Mobile">
            {{ "MOBILE" | translate }} <p-sortIcon field="Mobile"></p-sortIcon>
          </th>
          <th pSortableColumn="StatusKey">
            {{ "STATUS" | translate }}
            <p-sortIcon field="StatusKey"></p-sortIcon>
          </th>
          <th>{{ "OPTIONS" | translate }}</th>
        </tr>
        <tr>
          <th>
            <input
              pInputText
              type="text"
              (input)="
                dt.filter($any($event.target)?.value, 'Name', 'contains')
              "
              class="p-column-filter"
            />
          </th>
          <th>
            <input
              pInputText
              type="text"
              (input)="
                dt.filter($any($event.target)?.value, 'Username', 'contains')
              "
              class="p-column-filter"
            />
          </th>
          <th>
            <input
              pInputText
              type="text"
              (input)="
                dt.filter($any($event.target)?.value, 'Email', 'contains')
              "
              class="p-column-filter"
            />
          </th>
          <th>
            <input
              pInputText
              type="text"
              (input)="
                dt.filter($any($event.target)?.value, 'Mobile', 'contains')
              "
              class="p-column-filter"
            />
          </th>
          <th>
            <p-dropdown
              [options]="statuses"
              #statusDropdown
              (onChange)="onStatusChanged($event, statusDropdown)"
              styleClass="p-column-filter"
              placeholder="Select"
              [filterBy]="null"
              [showClear]="true"
              appendTo="body"
            >
              <ng-template let-status pTemplate="item">
                {{ status.label | translate }}
              </ng-template>
            </p-dropdown>
          </th>
          <th></th>
        </tr>
      </ng-template>
      <!-- Body Content -->
      <ng-template pTemplate="body" let-portal>
        <tr>
          <td>
            <span>{{ portal.Name }}</span>
          </td>
          <td>
            <span>{{ portal.Username }}</span>
          </td>
          <td>
            <span>{{ portal.Email }}</span>
          </td>
          <td>
            <span>{{ portal.Mobile }}</span>
          </td>
          <td>
            <span>{{ portal.StatusKey.toUpperCase() | translate }}</span>
          </td>
          <td styleClass="col-button" [style]="{ width: '65px' }">
            <a
              (click)="editUser(portal)"
              *ngIf="hideOrDisplayEdit(portal.Id)"
              class="btn btn-icon"
              title="{{ 'EDIT' | translate }}"
              routerLinkActive="active"
            >
              <i class="icon ic-sm icon-pencil-tip"></i>
            </a>
            <button
              *ngIf="
                roleId === 2
                  ? false
                  : roleId === 1 && portal.IsPortalManager === true
                    ? false
                    : true
              "
              type="button"
              (click)="delete(portal.Id)"
              class="btn btn-icon"
              title="{{ 'DELETE' | translate }}"
            >
              <i class="icon ic-sm icon-trash"></i>
            </button>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <!-- Table ends -->
  </div>
</div>
<p-dialog
  header="{{ 'RESET_PASSWORD' | translate }}"
  (onHide)="clearPasswordResetForm()"
  [(visible)]="showPasswordResetDialog"
  [style]="{ width: '300px' }"
  [resizable]="false"
  [draggable]="false"
>
  <form [formGroup]="updatePasswordForm">
    <div formGroupName="passwordValidationGroup">
      <div class="form-group">
        <label for="RepeatPassword">
          <span translate>NEW_PASSWORD</span>
          <span class="required">*</span>
        </label>
        <div>
          <input
            type="password"
            pInputText
            id="NewPassword"
            formControlName="Password"
          />
        </div>
      </div>
      <div class="form-group">
        <label for="RepeatPassword">
          <span translate>CONFIRM_PASSWORD</span>
          <span class="required">*</span>
        </label>
        <div>
          <input
            type="password"
            pInputText
            formControlName="ConfirmPassword"
            required
          />
        </div>
      </div>
      <control-messages
        [control]="updatePasswordForm.controls['passwordValidationGroup']"
      ></control-messages>
    </div>
    <button
      class="btn btn-outline-primary"
      (click)="resetPassWord()"
      [disabled]="!updatePasswordForm.valid"
    >
      <span translate>SAVE</span>
    </button>
  </form>
</p-dialog>
<p-dialog
  header="{{ 'RESET_PASSWORD' | translate }}"
  (onHide)="clearPasswordResetForm()"
  [(visible)]="passwordValidation"
  [style]="{ width: '300px' }"
  [resizable]="false"
  [draggable]="false"
>
  <span translate style="color: red">YOUR_PASSWORD_DONOT_MATCH</span>
</p-dialog>
<p-confirmDialog
  header="{{ 'CONFIRMATION' | translate }}"
  width="425"
  acceptLabel="{{ 'SWITCH_YES' | translate }}"
  rejectLabel="{{ 'SWITCH_NO' | translate }}"
></p-confirmDialog>
<toaster-component></toaster-component>
