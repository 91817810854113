import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ConfirmationService } from 'primeng/api';
import { Rights } from 'src/app/_models';
// import { ToasterComponent } from './../../../_directives/toaster.component';
import { News } from '../models/news';
import { NewsServices } from '../services/news.service';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { TranslateService } from '@ngx-translate/core';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
@Component({
    selector: 'newslist',
    templateUrl: './NewsList.component.html'
})
export class NewsListComponent implements OnInit {

    //#region variable

    @ViewChild(ToasterComponent, { static: false }) toasterComponent: ToasterComponent;
    public news: News[];
    isAdministrateNews = true;
    isHideDeleteIcon = true;
    isHideEditIcon = true;
    isUser = true;
    isAdmin: boolean;
    cols: any[];
    isEditor = false;
    private subscriptions: Subscription[] = [];
    ipAddress: any;

    //#endregion

    //#region constructor

    constructor(
        public newsServices: NewsServices,
        private router: Router,
        private route: ActivatedRoute,
        private confirmationService: ConfirmationService,
        private translate: TranslateService,
        private http: HttpClient
    ) { }

    //#endregion

    //#region page-event

    /**
     * page load event
     */
    ngOnInit(): void {
        let news = [];
        document.querySelector("body").classList.remove("opened");
        news = this.route.snapshot.data['list'];
        if (this.router.url.indexOf('admin') > 0) {
            if (+BaseServices.roleId === 3) {
                this.isHideDeleteIcon = false;
                this.isAdmin = true;
                this.isHideEditIcon = false;
            } else {
                this.isAdmin = false;
                this.isHideDeleteIcon = true;
                this.isHideEditIcon = true;

            }
        }
        if (news != null) {
            this.news = news.filter((x: any) => x.Status === 1);
        }
        if (BaseServices.roleId === '5') {
            this.isUser = false;
        }
        if (BaseServices.UserRole == 'Editor') {
            this.isEditor = true;
        }
        else {
            this.isEditor = false;
        }
        this.onAdministrateInternalNews();
        this.cols = [
            { field: 'Title', header: 'Title' },
            { field: 'Status', header: 'Status' },
            { field: 'CreatedOn', header: 'CreatedOn' },
            { field: 'PublishedOn', header: 'PublishedOn' },
            { field: 'ExpiredOn', header: 'ExpiredOn' }
        ];
    }

    //#endregion

    //#region control-event
    /**
     * delete news
     */
    confirm(news: any) {

        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_NEWS'),
            accept: () => {
                this.newsServices.delete(news).then(isNewsDeleted => {
                    if (isNewsDeleted) {
                        this.fetchNewsData();
                        this.toasterComponent.callToastDlt();
                    }
                });
            }
        });
    }

    /**
     * refresh list
     */
    fetchNewsData() {
        this.subscriptions.push(this.newsServices.list().subscribe((res: any) => {
            this.news = res.filter((x: any) => x.Status === 1);
        }));
    }
    /**
     * Edit & update News based on administrate news rights
     */

    onAdministrateInternalNews() {
        let userRightsId = Rights.ADMINISTRATE_INTERNAL_NEWS;
        let role = BaseServices.UserRole;
        if (role === 'User' || role === 'Guest' ) {
            this.isAdministrateNews = BaseServices.checkUserRights(
                userRightsId,
                role
            );
            this.isHideDeleteIcon = true;
            this.isEditor = true;

        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
    //#endregion
}
