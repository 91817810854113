import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
  ElementRef,
  ChangeDetectorRef,
  TemplateRef,
  AfterViewChecked,
} from '@angular/core';
import { EcplTreeData } from './intefaces/ecpl-tree-data';
import {
  ITreeOptions,
  TREE_ACTIONS,
  KEYS,
  TreeNode,
  TreeModel,
  TreeComponent,
} from '@circlon/angular-tree-component';
// import {
//     ITreeOptions,
//     TREE_ACTIONS,
//     KEYS,
//     TreeNode,
//     TreeModel,
//     TreeComponent
// } from 'angular-tree-component';
// import { ITreeNode } from 'angular-tree-component/dist/defs/api';
import { IecplTreeConfig } from './intefaces/iecpl-tree-config';
import * as _ from 'lodash';
import { isObject } from 'util';
import { ContextMenuComponent } from '@perfectmemory/ngx-contextmenu';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ArticleModel } from 'src/app/shared/ecpl-article-viewer/interfaces';
import { BaseServices } from 'src/app/kuba/kuba.services';
import {
  ChecklistModel,
  ChecklistSectionModel,
} from 'src/app/shared/ecpl-checklist-viewer/ecpl-checklist-viewer.model';
// import { UUID } from 'angular2-uuid';
import { BusinessServices } from './../../kuba/businesses/services/business.services';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FeatureKey } from 'src/app/_models';
import { UUID } from 'angular2-uuid';
import { HelperV2Service } from "../../_services/helper-v2.service";
@Component({
  selector: 'app-ecpl-document-tree',
  templateUrl: './ecpl-document-tree.component.html',
  styleUrls: ['./ecpl-document-tree.component.scss'],
})
export class EcplDocumentTreeComponent
  implements OnInit, OnChanges, AfterViewInit, AfterViewChecked
{
  @Input() treeId: string;
  @Input() ecplTreeCheckbox: boolean;
  @Input() addFromManuals: boolean;
  @Input() ecplActiveNode: any;
  @Input() ecplApiResponse: string;
  @Input() adminUsers: any[];
  @Input() ecplTreeData: EcplTreeData[];
  @Input() isAdminManual: boolean;
  @Input() allowDrop: boolean;
  @Input() allowDrag: boolean;
  @Input() businessMode: string;
  @Input() treeType: string;
  @Output() ecplNodeEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild('ecplTree', { static: false }) treeComponent: TreeComponent;
  @ViewChild('hiddenAction', { static: false }) hiddenAction: ElementRef;
  @ViewChild('newFolderModal', { static: false })
  newFolderModal: TemplateRef<any>;
  @Output() ecplNodeOptionsEvent: EventEmitter<any> = new EventEmitter();

  options: ITreeOptions;
  staticOptions: ITreeOptions;
  nodes: any = [];
  isCheckbox: boolean;
  file: any = null;
  currentEventData: any;
  newFolderNode: EcplTreeData;
  bsModalRef: BsModalRef;
  bsConfig = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true,
  };
  bsModalTitle: string;
  userRole: string;
  applicationId: number;
  businessId: number;
  portalId: number;
  businessPortalId: number;
  featureId: any;
  get UserId() {
    return BaseServices.UserId;
  }
  projectId: number;
  isClientUser = false;
  //#region Context Menu
  @ViewChild(ContextMenuComponent) public basicMenu: ContextMenuComponent;
  contextMenuActions = [
    {
      html: (node: any | null) => this.translate.instant('NEW_ROOT_FOLDER'),
      action: (node: any | null) => 'AddNewRootFolder',
      enabled: (node: any | null) => true,
      visible: (node: any | null) =>
        node.data.type === 'folder' &&
        (node.data.isDefault === true ||
          (node.data.parentFolderId === 0 &&
            node.data.featureId === FeatureKey.ELECTRO)) &&
        node.data.documentType !== null,
    },
    {
      html: (node: any | null) => this.translate.instant('NEW_FOLDER'),
      action: (node: any | null) => 'AddNewRootFolder',
      enabled: (node: any | null) => true,
      visible: (node: any | null) =>
        node.data.type === 'folder' &&
        node.data.parentFolderId !== 0 &&
        node.data.documentType !== null,
    },
    {
      html: (node: any | null) => this.translate.instant('NEW_SUB_FOLDER'),
      action: (node: any | null) => 'AddNewFolder',
      enabled: (node: any | null) => true,
      visible: (node: any | null) =>
        node.data.type === 'folder' && node.data.documentType !== null,
    },
    {
      html: (node: any | null) => this.translate.instant('NEW_FILES'),
      action: (node: any | null) => 'AddFiles',
      enabled: (node: any | null) => true,
      visible: (node: any | null) =>
        node.data.type === 'folder' &&
        node.data.documentType !== 'article' &&
        node.data.documentType !== 'checklist' &&
        node.data.documentType !== 'Laws/Regulation' &&
        node.data.documentType !== null &&
        node.data.documentType !== 'laws' &&
        node.data.documentType !== 'regulation' &&
        node.data.documentType !== 'videos' &&
        node.data.documentType !== 'archiveundercompletionfolder' &&
        node.data.documentType !== 'FS Checklist Under Completion' &&
        node.data.documentType !== 'fsundercompletion' &&
        node.data.documentType !== 'fscompleted',
    },
    {
      html: (node: any | null) => this.translate.instant('EDIT'),
      action: (node: any | null) => 'EditFile',
      enabled: (node: any | null) => true,
      visible: (node: any | null) =>
        node.data.type === 'file' &&
        node.data.documentType !== 'article' &&
        node.data.documentType !== 'checklist' &&
        node.data.documentType !== 'regulation' &&
        node.data.documentType !== 'videos' &&
        node.data.documentType !== 'law' &&
        node.data.documentType !== null &&
        this.humanizeType(node.data.mimetype) === 'Document' &&
        node.data.documentType !== 'laws' &&
        node.data.documentType !== 'videos' &&
        node.data.documentType !== 'regulation' &&
        node.data.isEditable === true,
    },
    {
      html: (node: any | null) => this.translate.instant('COPY_FILE'),
      action: (node: any | null) => 'CopyFile',
      enabled: (node: any | null) => true,
      visible: (node: any | null) =>
        node.data.type === 'file' &&
        node.data.documentType !== 'article' &&
        node.data.documentType !== 'checklist' &&
        node.data.documentType !== 'regulation' &&
        node.data.documentType !== 'videos' &&
        node.data.documentType !== 'law' &&
        node.data.documentType !== null &&
        node.data.documentType !== 'laws' &&
        node.data.documentType !== 'videos' &&
        node.data.documentType !== 'regulation' &&
        node.data.featureId !== FeatureKey.ELECTRO,
    },
    {
      html: (node: any | null) => this.translate.instant('RENAME'),
      action: (node: any | null) => 'RenameFile',
      enabled: (node: any | null) => true,
      visible: (node: any | null) =>
        node.data.type === 'file' &&
        node.data.documentType !== 'article' &&
        node.data.documentType !== 'checklist' &&
        node.data.documentType !== 'archiveundercompletion' &&
        node.data.documentType !== 'installationform' &&
        node.data.documentType !== 'undercompletioninstallationform' &&
        node.data.documentType !== 'videos' &&
        node.data.documentType !== 'regulation' &&
        node.data.documentType !== 'law' &&
        node.data.documentType !== null &&
        node.data.isEditable === true,
    },
    {
      html: (node: any | null) => this.translate.instant('DELETE'),
      action: (node: any | null) => 'DeleteFile',
      enabled: (node: any | null) => true,
      visible: (node: any | null) =>
        node.data.type === 'file' &&
        node.data.documentType !== 'article' &&
        node.data.documentType !== 'checklist' &&
        node.data.documentType !== 'archiveundercompletion' &&
        node.data.documentType !== 'installationform' &&
        node.data.documentType !== 'undercompletioninstallationform' &&
        node.data.documentType !== 'videos' &&
        node.data.documentType !== 'regulation' &&
        node.data.documentType !== 'law' &&
        node.data.documentType !== null &&
        node.data.isEditable === true,
    },

    {
      html: (node: any | null) => this.translate.instant('NEW_ARTICLE'),
      action: (node: any | null) => 'AddArticle',
      enabled: (node: any | null) => true,
      visible: (node: any | null) =>
        node.data.type === 'folder' && node.data.documentType === 'article',
    },
    {
      html: (node: any | null) => this.translate.instant('EDIT'),
      action: (node: any | null) => 'EditArticle',
      enabled: (node: any | null) => true,
      visible: (node: any | null) =>
        node.data.type === 'file' &&
        node.data.documentType === 'article' &&
        node.data.isEditable === true,
    },
    {
      html: (node: any | null) => this.translate.instant('RENAME'),
      action: (node: any | null) => 'RenameArticle',
      enabled: (node: any | null) => true,
      visible: (node: any | null) =>
        node.data.type === 'file' &&
        node.data.documentType === 'article' &&
        node.data.isEditable === true,
    },
    {
      html: (node: any | null) => this.translate.instant('DELETE'),
      action: (node: any | null) => 'DeleteArticle',
      enabled: (node: any | null) => true,
      visible: (node: any | null) =>
        node.data.type === 'file' &&
        node.data.documentType === 'article' &&
        node.data.isEditable === true,
    },
    {
      html: (node: any | null) => this.translate.instant('NEW_CHECKLIST'),
      action: (node: any | null) => 'AddChecklist',
      enabled: (node: any | null) => true,
      visible: (node: any | null) =>
        node.data.type === 'folder' && node.data.documentType === 'checklist',
    },
    {
      html: (node: any | null) => this.translate.instant('EDIT'),
      action: (node: any | null) => 'EditChecklist',
      enabled: (node: any | null) => true,
      visible: (node: any | null) =>
        node.data.type === 'file' &&
        node.data.documentType === 'checklist' &&
        node.data.featureId !== FeatureKey.ELECTRO &&
        node.data.isEditable === true,
    },
    {
      html: (node: any | null) => this.translate.instant('RENAME'),
      action: (node: any | null) => 'RenameChecklist',
      enabled: (node: any | null) => true,
      visible: (node: any | null) =>
        node.data.type === 'file' &&
        node.data.documentType === 'checklist' &&
        node.data.featureId !== FeatureKey.ELECTRO &&
        node.data.isEditable === true,
    },
    {
      html: (node: any | null) => this.translate.instant('DELETE'),
      action: (node: any | null) => 'DeleteChecklist',
      enabled: (node: any | null) => true,
      visible: (node: any | null) =>
        node.data.type === 'file' &&
        node.data.documentType === 'checklist' &&
        node.data.featureId !== FeatureKey.ELECTRO &&
        node.data.isEditable === true,
    },
    {
      html: (node: any | null) => this.translate.instant('EDIT'),
      action: (node: any | null) => 'EditLaws',
      enabled: (node: any | null) => true,
      visible: (node: any | null) =>
        node.data.type === 'file' && node.data.documentType === 'law',
    },
    {
      html: (node: any | null) => this.translate.instant('EDIT'),
      action: (node: any | null) => 'EditRegulations',
      enabled: (node: any | null) => true,
      visible: (node: any | null) =>
        node.data.type === 'file' && node.data.documentType === 'regulation',
    },
    {
      html: (node: any | null) => this.translate.instant('RENAME'),
      action: (node: any | null) => 'RenameFolder',
      enabled: (node: any | null) => true,
      visible: (node: any | null) =>
        node.data.type === 'folder' &&
        node.data.documentType !== null &&
        node.data.isDefault === false &&
        node.data.isEditable === true,
    },
    {
      html: (node: any | null) => this.translate.instant('DELETE'),
      action: (node: any | null) => 'DeleteFolder',
      enabled: (node: any | null) => true,
      visible: (node: any | null) =>
        node.data.type === 'folder' &&
        node.data.documentType !== null &&
        node.data.isDefault === false &&
        node.data.isEditable === true,
    },
  ];
  //#endregion

  // #Icons region
  /**
   * Returns a set of classes for the FontAwesome icon based on the node data.
   * The class to be applied is determined by the `status` and `isPermission` properties of the data.
   *
   * @param data - The node data containing properties `status` and `isPermission`.
   * @return An object representing the classes to be applied.
   *
   * - If `isPermission` is `false`(disabled from admin level), the classes `fa-eye-slash`, `darkgray-eye-slash`, and `text-disabled` are applied.
   * - If `isPermission` is `true`,
   *   - When `status` is `1` (checkbox checked), the classes `fa-eye` and `darkgray-eye` are applied.
   *   - When `status` is not `1(checkbox unchecked)`, the classes `fa-eye-slash` and `darkgray-eye-slash` are applied.
   */
  getIconClass(data: any) {
    if (!data.isPermission) {
      return {
        'fa-eye-slash': true,
        'darkgray-eye-slash': true,
        'text-disabled': true,
      };
    }
    if (data.status === 1) {
      return { 'fa-eye': true, 'darkgray-eye': true };
    }
    return { 'fa-eye-slash': true, 'darkgray-eye-slash': true };
  }
  /**
   * Returns the tooltip text for the node based on its status.
   * The text is retrieved using the translation service.
   *
   * @param data - The node data containing the `status` property.
   * @return A string representing the tooltip text.
   *
   * - When `status` is `1`, the tooltip text is "CLICK_TO_HIDE".
   * - When `status` is `2`, the tooltip text is "CLICK_TO_SHOW".
   */
  getTooltipText(data: any) {
    if (data.status === 1) {
      return this.translate.instant('CLICK_TO_HIDE');
    }
    if (data.status === 2) {
      return this.translate.instant('CLICK_TO_SHOW');
    }
  }
  /**
   * Returns the path to the appropriate icon for an object (node) based on its documentType property.
   * The icon also depends on whether the node is expanded or collapsed.
   * If the documentType is not listed in the `icons` dictionary, then a default icon is used.
   *
   * @param {object} node - The object(node) which icon file path is to be determined. It should have two properties:
   *    - [data.documentType]: A string which specifies the type of the document.
   *    - [isExpanded]: A boolean which tells whether the node is expanded or not.
   * @returns {string} The path to the icon file relative to the '/assets/file-icons/' directory.
   * The first icon from the iconGroup is used when the node is collapsed, and the second one is used when the node is expanded.
   *
   * @example
   * getFileIcon({data: {documentType: 'article'}, isExpanded: true}); // returns '/assets/file-icons/kb-folder-open-blue.png'
   */ getFileIcon(node) {
    const icons = {
      article: ['kb-folder-blue.png', 'kb-folder-open-blue.png'],
      document: ['kb-folder.png', 'kb-folder-open.png'],
      checklist: ['kb-folder-red.png', 'kb-folder-open-red.png'],
      archiveundercompletionfolder: [
        'kb-folder-red.png',
        'kb-folder-open-red.png',
      ],
      laws: ['kb-folder-gray.png', 'kb-folder-open-gray.png'],
      regulation: ['kb-folder-gray.png', 'kb-folder-open-gray.png'],
      videos: ['kb-folder-green.png', 'kb-folder-open-green.png'],
    };

    let iconGroup = icons[node.data.documentType];

    if (!iconGroup) {
      // handle error here or assign a default icon
      iconGroup = ['kb-folder.png', 'kb-folder-open.png'];
    }

    // use the first icon in the array for when the node is collasped, the second one when the node is expanded
    return `/assets/file-icons/${
      node.isExpanded ? iconGroup[1] : iconGroup[0]
    }`;
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private bsModalService: BsModalService,
    private businessService: BusinessServices,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private helperV2Service: HelperV2Service
  ) {
    this.newFolderNode = {
      filename: '',
    };
    this.userRole = BaseServices.UserRole;
    this.businessId = +this.route.snapshot.params['mBusinessId'];
    this.applicationId = BaseServices.ApplicationId;
    this.portalId = +this.route.snapshot.params['mPortalId'];
    this.projectId = this.route.snapshot.params['Id'];
    this.isClientUser =
      BaseServices.FeatureId == FeatureKey.CLIENT ||
      BaseServices.FeatureId == FeatureKey.CLIENT_CONTACT;
    let workingModule = JSON.parse(sessionStorage.getItem('workingModule'));
    this.featureId = workingModule.id;
  }

  saveFolderFile(eventData: any) {
    this.ecplActiveNode = this.treeComponent.treeModel.getActiveNode();
    if (eventData.eventName == 'AddNewRootFolder') {
      if (eventData.item.data.parentFolderId != 0) {
        var x = this.ecplActiveNode.treeModel.nodes.filter(
          (x: any) => x.nodeId == eventData.item.data.parentFolderId
        );
        if (x.length == 0) {
          var node = this.ecplActiveNode.treeModel.nodes;
          for (let i = 0; i < node.length; i++) {
            var y = node[i].nodes;
            var z = y.filter(
              (x: any) => x.nodeId == eventData.item.data.parentFolderId
            );
            if (z.length != 0) {
              eventData.item.data.id = z[0].id;
              eventData.item.data.nodeId = z[0].nodeId;
            }
          }
        } else {
          eventData.item.data.id = x[0].id;
          eventData.item.data.nodeId = x[0].nodeId;
        }
        eventData.eventName = 'AddNewFolder';
        eventData.item.data.parentFolderId = 0;
        eventData.item.data.hasChildren = true;
      }
    }
    switch (eventData.eventName) {
      case 'SaveLar':
        break;
      case 'AddNewRootFolder':
        if (this.newFolderNode.filename !== '') {
          this.newFolderNode.type = 'folder';
          (this.newFolderNode.documentType = eventData.item.data.documentType),
            (this.newFolderNode.isDefault = false);
          this.newFolderNode.isFolder = true;
          this.newFolderNode.status = 1;
          eventData.parent = eventData.item.data;
          eventData.newNode = { ...this.newFolderNode };
          this.ecplNodeEvent.emit(eventData);
          this.bsModalRef.hide();
        }
        break;
      case 'AddNewFolder':
        if (this.newFolderNode.filename !== '') {
          this.newFolderNode.type = 'folder';
          (this.newFolderNode.documentType = eventData.item.data.documentType),
            (this.newFolderNode.isDefault = false);
          this.newFolderNode.isFolder = true;
          this.newFolderNode.status = 1;
          eventData.parent = eventData.item.data;
          eventData.newNode = { ...this.newFolderNode };
          // eventData.item.data.nodes.push(eventData.newNode);
          this.bsModalRef.hide();
          // this.treeComponent.treeModel.update();
          this.ecplNodeEvent.emit(eventData);
        }
        break;

      case 'RenameFolder':
        if (this.newFolderNode.filename !== '') {
          eventData.item.data.filename = this.newFolderNode.filename;
          this.bsModalRef.hide();
          this.treeComponent.treeModel.update();
          this.ecplNodeEvent.emit(eventData);
        }
        break;
      case 'RenameFile':
        if (this.newFolderNode.filename !== '') {
          eventData.item.data.filename = this.newFolderNode.filename;
          this.bsModalRef.hide();
          this.treeComponent.treeModel.update();
          this.ecplNodeEvent.emit(eventData);
        }
        break;
      case 'RenameArticle':
        if (this.newFolderNode.filename !== '') {
          eventData.item.data.filename = this.newFolderNode.filename;
          this.bsModalRef.hide();
          this.treeComponent.treeModel.update();
          this.ecplNodeEvent.emit(eventData);
        }
        break;
      case 'RenameChecklist':
        if (this.newFolderNode.filename !== '') {
          eventData.item.data.filename = this.newFolderNode.filename;
          this.bsModalRef.hide();
          this.treeComponent.treeModel.update();
          this.ecplNodeEvent.emit(eventData);
        }
        break;
      case 'NewArticle':
        eventData.activeNodeId = this.currentEventData.item.data.nodeId;
        this.ecplNodeEvent.emit(eventData);
        break;

      case 'SaveArticle':
        this.ecplNodeEvent.emit(eventData);
        break;
      case 'NewChecklist':
        eventData.activeNodeId = this.currentEventData.item.data.nodeId;
        this.ecplNodeEvent.emit(eventData);
        break;
      case 'CopyChecklist':
        eventData.activeNodeId = this.currentEventData.item.data.nodeId;
        this.ecplNodeEvent.emit(eventData);
        break;

      case 'SaveChecklist':
        //  eventData.activeNodeId = this.currentEventData.item.parent.data.nodeId;
        this.ecplNodeEvent.emit(eventData);

        break;
      case 'CopyOfficeDocument':
        this.ecplNodeEvent.emit(eventData);

        break;
      case 'ShowOfficeFileSaveloader':
        this.ecplNodeEvent.emit(eventData);
        break;
      case 'CopyEditVersionDocument':
        this.ecplNodeEvent.emit(eventData);
        break;
      default:
        break;
    }
  }

  //#region Emitters
  sendTreeEvents(event: any) {
    if (event.eventName === 'activate' && event.node.data.type === 'file') {
      this.file = event.node.data;
      this.cdr.detectChanges();
    }

    this.ecplNodeEvent.emit(event);
  }

  // manual events
  saveUpdateEvents(event: any, eventName: any) {
    event.eventName = eventName;
    switch (eventName) {
      case 'AddNewRootFolder':
        // add new node in selected folder with input fields and expand and focus
        this.treeComponent.treeModel.setActiveNode(event.item, true);
        this.bsModalTitle = this.translate.instant('NEW_ROOT_FOLDER');
        this.currentEventData = event;
        this.bsModalRef = this.bsModalService.show(
          this.newFolderModal,
          this.bsConfig
        );
        break;
      case 'AddNewFolder':
        // add new node in selected folder with input fields and expand and focus
        this.treeComponent.treeModel.setExpandedNode(event.item, true);
        this.treeComponent.treeModel.setActiveNode(event.item, true);
        this.bsModalTitle = this.translate.instant('NEW_SUB_FOLDER');
        this.currentEventData = event;
        this.bsModalRef = this.bsModalService.show(
          this.newFolderModal,
          this.bsConfig
        );
        break;

      case 'AddFiles':
        // Open windows file explorer for upload from file-window explorer component
        this.treeComponent.treeModel.setActiveNode(event.item, true);
        this.ecplNodeEvent.emit(event);
        break;

      case 'EditFile':
        // if office file open in onlyoffice viewer
        this.treeComponent.treeModel.setActiveNode(event.item, true);
        this.file = event.item.data;
        this.cdr.detectChanges();
        this.triggerFalseClick();

        break;

      case 'RenameFile':
        // edit node(filename) name input field and emit action
        // this.treeComponent.treeModel.setActiveNode(event.item, true);
        this.newFolderNode.filename = event.item.data.filename;
        this.bsModalTitle = 'Rename : ' + event.item.data.filename;
        this.currentEventData = event;
        this.bsModalRef = this.bsModalService.show(
          this.newFolderModal,
          this.bsConfig
        );

        break;

      case 'DeleteFile':
        // Remove node from tree and emit acction
        this.treeComponent.treeModel.setActiveNode(event.item, true);
        if (
          !confirm('Are you sure want to delete ' + event.item.data.filename)
        ) {
          return false;
        }
        this.ecplNodeEvent.emit(event);
        break;

      case 'AddArticle':
        BaseServices.setArticleId(0);
        this.file = new ArticleModel();
        this.file.id = 0;
        this.file.nodeId = 0;
        this.file.filename = '';
        this.file.originalFileName = '';
        this.file.path = '';
        this.file.mimetype = '';
        this.file.size = 0;
        this.file.isFolder = false;
        this.file.type = 'file';
        this.file.isDefault = false;
        this.file.documentType = 'article';
        this.file.status = 1;
        this.file.createdBy = this.UserId;
        this.file.modifiedBy = this.UserId;
        this.file.parentFolderId = event.item.parentFolderId;
        this.file.documentTypeId = event.item.documentTypeId;
        if (this.businessId === 0 && this.portalId === 0) {
          this.file.content = {
            article: {
              id: 0,
              articleName: '',
              documentNumber: null,
              chapter: '',
              version: 1,
              roleStatus: {
                admin: [
                  { name: 'Own Text', status: 'Active', disable: 'false' },
                  { name: 'Standard Text', status: 'Active', disable: 'false' },
                  { name: 'Legislation', status: 'Active', disable: 'false' },
                ],
                portal: [
                  { name: 'Own Text', status: 'Active', disable: 'false' },
                  { name: 'Portal Text', status: 'Active', disable: 'false' },
                  { name: 'Standard Text', status: 'Active', disable: 'false' },
                  { name: 'Legislation', status: 'Active', disable: 'false' },
                ],
                editor: [
                  { name: 'Own Text', status: 'Active', disable: 'false' },
                  { name: 'Portal Text', status: 'Active', disable: 'false' },
                  { name: 'Standard Text', status: 'Active', disable: 'false' },
                  { name: 'Legislation', status: 'Active', disable: 'false' },
                ],
              },
            },
            subArticles: [
              {
                name: 'Own Text',
                text: '',
                version: 1,
                status: 'Active',
              },
              {
                name: 'Portal Text',
                text: '',
                version: 1,
                status: 'Active',
              },
              {
                name: 'Standard Text',
                text: '',
                version: 1,
                status: 'Active',
              },
              {
                name: 'Legislation',
                text: '',
                version: 1,
                status: 'Active',
              },
            ],
            revisionComments: null,
          };
        } else if (this.portalId !== 0 && this.businessId === 0) {
          this.file.content = {
            article: {
              id: 0,
              articleName: '',
              documentNumber: null,
              chapter: '',
              version: 1,
              roleStatus: {
                admin: [],
                portal: [
                  { name: 'Own Text', status: 'Active', disable: 'false' },
                  { name: 'Portal Text', status: 'Active', disable: 'false' },
                ],
                editor: [
                  { name: 'Own Text', status: 'Active', disable: 'false' },
                  { name: 'Portal Text', status: 'Active', disable: 'false' },
                ],
              },
            },
            subArticles: [
              {
                name: 'Own Text',
                text: '',
                version: 1,
                status: 'Active',
              },
              {
                name: 'Portal Text',
                text: '',
                version: 1,
                status: 'Active',
              },
            ],
            revisionComments: null,
          };
        } else if (this.businessId !== 0 && this.projectId !== undefined) {
          this.file.content = {
            article: {
              id: 0,
              articleName: '',
              documentNumber: null,
              chapter: '',
              version: 1,
            },
            subArticles: [
              {
                name: 'Project Text',
                text: '',
                version: 1,
                status: 'Active',
              },
              {
                name: 'Own Text',
                text: '',
                version: 1,
                status: 'Active',
              },
              {
                name: 'Portal Text',
                text: '',
                version: 1,
                status: 'Active',
              },
            ],
            revisionComments: null,
          };
        } else if (this.portalId === 0 && this.businessId !== 0) {
          this.file.content = {
            article: {
              id: 0,
              articleName: '',
              documentNumber: null,
              chapter: '',
              version: 1,
              roleStatus: {
                admin: [],
                portal: [],
                editor: [
                  { name: 'Own Text', status: 'Active', disable: 'false' },
                ],
              },
            },
            subArticles: [
              {
                name: 'Own Text',
                text: '',
                version: 1,
                status: 'Active',
              },
            ],
            revisionComments: null,
          };
        } else {
        }

        this.currentEventData = event;

        this.cdr.detectChanges();
        this.triggerFalseClick();
        break;

      case 'EditArticle':
        // Open article viewer with data
        this.treeComponent.treeModel.setActiveNode(event.item, true);
        this.file = event.item.data;
        this.currentEventData = event;
        this.cdr.detectChanges();
        this.triggerFalseClick();
        break;

      case 'RenameArticle':
        // edit node(filename) name input field and emit action
        // this.treeComponent.treeModel.setActiveNode(event.item, true);
        this.newFolderNode.filename = event.item.data.filename;
        this.bsModalTitle = 'Rename : ' + event.item.data.filename;
        this.currentEventData = event;
        this.bsModalRef = this.bsModalService.show(
          this.newFolderModal,
          this.bsConfig
        );

        break;
      case 'DeleteArticle':
        // Remove node from tree and emit action
        this.treeComponent.treeModel.setActiveNode(event.item, true);
        if (
          !confirm('Are you sure want to delete ' + event.item.data.filename)
        ) {
          return false;
        }
        this.ecplNodeEvent.emit(event);
        break;
      case 'AddChecklist':
        // open empty checklist viewer
        this.currentEventData = event;
        this.file = null;
        this.file = new ChecklistModel();
        this.file.filename = '';
        this.file.originalFileName = '';
        this.file.path = '';
        this.file.mimetype = 'checklist';
        this.file.size = 0;
        this.file.isFolder = false;
        this.file.type = 'file';
        this.file.isDefault = false;
        this.file.documentType = 'checklist';
        this.file.status = 1;
        this.file.createdBy = BaseServices.UserId;
        this.file.modifiedBy = BaseServices.UserId;
        this.file.createdOn = new Date();
        this.file.parentFolderId = 0;
        this.file.documentTypeId = 0;
        this.file.content = {
          checkList: {
            id: 0,
            title: '',
            scoreIndex: false,
            isEditable: true,
            status: 1,
            createdOn: new Date(),
            sections: [
              {
                id: 0,
                position: 1,
                title: '',
                status: 1,
                items: [
                  {
                    id: 0,
                    position: 1,
                    title: '',
                    status: 1,
                  },
                ],
              },
            ],
          },
        };
        this.cdr.detectChanges();
        this.triggerFalseClick();
        break;

      case 'EditChecklist':
        // Open checklist viewer with data
        this.currentEventData = event;
        this.treeComponent.treeModel.setActiveNode(event.item, true);
        this.file = null;
        this.file = event.item.data;
        this.cdr.detectChanges();
        this.triggerFalseClick();

        break;

      case 'RenameChecklist':
        // edit node(filename) name input field and emit action
        // this.treeComponent.treeModel.setActiveNode(event.item, true);
        this.newFolderNode.filename = event.item.data.filename;
        this.bsModalTitle = 'Rename : ' + event.item.data.filename;
        this.currentEventData = event;
        this.bsModalRef = this.bsModalService.show(
          this.newFolderModal,
          this.bsConfig
        );

        break;
      case 'DeleteChecklist':
        // Remove node from tree and emit action
        this.treeComponent.treeModel.setActiveNode(event.item, true);
        if (
          !confirm('Are you sure want to delete ' + event.item.data.filename)
        ) {
          let result: any;
          return result;
        }
        this.ecplNodeEvent.emit(event);
        break;
      case 'EditLaws':
        // Open law viewer with data
        this.treeComponent.treeModel.setActiveNode(event.item, true);
        this.file = event.item.data;
        this.cdr.detectChanges();
        this.triggerFalseClick();

        break;

      case 'EditRegulations':
        // Open regulation viewer with data
        this.treeComponent.treeModel.setActiveNode(event.item, true);
        this.file = event.item.data;
        this.cdr.detectChanges();
        this.triggerFalseClick();

        break;
      case 'RenameFolder':
        // edit node(filename) name input field and emit action
        this.treeComponent.treeModel.setActiveNode(event.item, true);
        this.treeComponent.treeModel.setExpandedNode(event.item, true);
        this.newFolderNode.filename = event.item.data.filename;
        this.bsModalTitle = 'Rename : ' + event.item.data.filename;
        this.currentEventData = event;
        this.bsModalRef = this.bsModalService.show(
          this.newFolderModal,
          this.bsConfig
        );

        break;
      case 'DeleteFolder':
        // Remove node from tree and emit action
        this.treeComponent.treeModel.setActiveNode(event.item, true);
        let childCount =
          event.item.data.childCount > 0
            ? ' (' + event.item.data.childCount + ')?'
            : '?';
        let hasNodeMsg =
          event.item.data.childCount > 0 ? ' It has sub nodes.' : '';
        if (
          !confirm(
            'Are you sure want to delete ' +
              event.item.data.filename +
              childCount +
              hasNodeMsg
          )
        ) {
          return false;
        }
        this.treeComponent.treeModel.setActiveNode(event.item.parent, true);
        this.ecplNodeEvent.emit(event);
        break;
      case 'DragDropFolder':
        // emit event with from-folder and to-folder
        break;
      case 'DragDropArticle':
        // emit event with from-folder and to-folder
        break;
      case 'DragDropChecklist':
        // emit event with from-folder and to-folder
        break;
      case 'DragDropDocument':
        // emit event with from-folder and to-folder
        break;

      default:
        this.ecplNodeEvent.emit(event);
        break;
    }
  }

  setNodeStatus(node: any, event: any) {
    if (node.data.status === 1) {
      if (node.data.nodes != null) {
        if (node.data.nodes.length > 0) {
          for (var i = 0; i < node.data.nodes.length; i++) {
            node.data.nodes[i].status = 2;
          }
        }
      }
      node.data.status = 2;
    } else {
      if (node.data.nodes != null) {
        if (node.data.nodes.length > 0) {
          for (var i = 0; i < node.data.nodes.length; i++) {
            node.data.nodes[i].status = 1;
          }
        }
      }
      node.data.status = 1;
    }
    event.eventName = 'CheckBoxStatus';
    event.node = node;
    this.ecplNodeEvent.emit(event);
  }

  //#endregion

  //#region Component life cycle hooks
  ngOnChanges(changes: SimpleChanges) {
    if (changes['ecplTreeData'] && changes['ecplTreeData'].currentValue) {
      this.newFolderNode = {
        filename: '',
      };

      this.nodes = this.ecplTreeData;
      // this.nodes = [].concat(this.ecplTreeData);
      // this.treeComponent.treeModel.update();
      //  this.treeComponent.loadNodeChildren();
      this.cdr.detectChanges();
    }
    if (
      changes['ecplTreeCheckbox'] &&
      changes['ecplTreeCheckbox'].currentValue !==
        changes['ecplTreeCheckbox'].previousValue
    ) {
      this.setTreeOptionsAndData();
      this.treeComponent.treeModel.update();
      this.cdr.detectChanges();
    }

    // if (
    //     changes['ecplApiResponse'] &&
    //     changes['ecplApiResponse'].currentValue === 'reloaded'
    // ) {
    //     if (this.ecplActiveNode !== null) {
    //         const activeNode: TreeNode = this.treeComponent.treeModel.getNodeById(
    //             this.ecplActiveNode.id
    //         );
    //         activeNode.setActiveAndVisible();
    //         this.treeComponent.treeModel.update();
    //     }
    // }
  }

  ngOnInit() {
    if (this.treeId) {
      const tree = this.treeComponent.treeModel.roots[0];
      if (tree) {
        if (tree.data !== undefined && tree.data !== null) {
          if (tree.data.originalFileName === 'Archive') {
            //  Expand Archive first folder
            tree.expand();
          }
        }
      }
      // if (this.treeType) {
      //     const alltree = this.treeComponent.treeModel;
      //     if (alltree) {
      //         setTimeout(() => {
      //             alltree.expandAll()
      //         });
      //         ;
      //     }
      // }
    }
  }

  ngAfterViewInit() {
    this.setTreeOptionsAndData();
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  //#endregion

  //#region Private funs

  setTreeOptionsAndData() {
    // angular2-tree options
    this.options = {
      displayField: 'filename',
      isExpandedField: 'expanded',
      idField: 'id',
      hasChildrenField: 'isFolder',
      childrenField: 'nodes',
      nodeClass: (node: TreeNode) => {
        return 'ecpl-tree-icon-' + node.data.mimetype;
      },
      allowDrag: (node: any | null) => {
        if (!node.data.isDefault && this.ecplTreeCheckbox) {
          let result: any = true;
          return result;
        }
      },
      allowDrop: (element, { parent, index }) => {
        return true;
      },
      actionMapping: {
        mouse: {
          dblClick: (tree: any, node: any, $event: any) => {
            if (node.hasChildren) {
              TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
            } else {
              TREE_ACTIONS.TOGGLE_ACTIVE(tree, node, $event);
              this.triggerFalseClick();
            }
          },
          checkboxClick: (tree: any, node: any, $event: any) => {
            TREE_ACTIONS.EXPAND(tree, node, $event);
            TREE_ACTIONS.TOGGLE_SELECTED(tree, node, $event);
          },
          contextmenu: (tree: any, node: any, $event: any) => {
            if (node.hasChildren) {
              TREE_ACTIONS.EXPAND(tree, node, $event);
            } else {
              TREE_ACTIONS.TOGGLE_ACTIVE(tree, node, $event);
            }
          },
          drop: (
            tree: TreeModel,
            node: TreeNode,
            $event: any,
            { from, to }
          ) => {
            // if (
            //     from.data.documentType.toLowerCase() ===
            //     to.parent.data.documentType.toLowerCase()
            // ) {
            if (
              this.portalId !== 0 &&
              from.data.portalId === 0 &&
              from.data.businessId === 0
            ) {
              alert(`Not allowed to drag and drop admin file`);
            } else if (
              (this.businessId !== 0 &&
                from.data.portalId === 0 &&
                from.data.businessId === 0) ||
              (this.businessId !== 0 &&
                from.data.portalId !== 0 &&
                from.data.businessId === 0)
            ) {
              if (from.data.portalId === 0 && from.data.businessId === 0) {
                alert(`Not allowed to drag and drop admin file`);
              } else if (
                from.data.portalId !== 0 &&
                from.data.businessId === 0
              ) {
                alert(`Not allowed to drag and drop portal file`);
              }
            } else if (
              to.parent.data.documentType == 'article' &&
              from.data.mimetype != 'article'
            ) {
              alert(`Not allowed to drag and drop other than article file`);
            } else if (
              to.parent.data.documentType == 'checklist' &&
              from.data.mimetype != 'checklist'
            ) {
              alert(`Not allowed to drag and drop other than checklist`);
            } else {
              TREE_ACTIONS.MOVE_NODE(tree, node, $event, {
                from,
                to,
              });
            }
            // } else {
            //     alert(
            //         `Only ${
            //         to.parent.data.documentType
            //         } are allowed`
            //     );
            // }
            // // https://angular2-tree.readme.io/docs/drag-drop
            // use from to get the dragged node.
            // use to.parent and to.index to get the drop location
            // use TREE_ACTIONS.MOVE_NODE to invoke the original action
          },
        },
        keys: {
          [KEYS.ENTER]: (tree: any, node: any, $event: any) => {
            node.expand();
          },
        },
      },
      nodeHeight: 24,
      useCheckbox: this.ecplTreeCheckbox,
      useVirtualScroll: false,
      animateExpand: true,
      animateSpeed: 30,
      animateAcceleration: 1.2,
    };

    this.staticOptions = {
      displayField: 'filename',
      isExpandedField: 'expanded',
      idField: 'id',
      hasChildrenField: 'isFolder',
      childrenField: 'nodes',
      nodeClass: (node: TreeNode) => {
        return 'ecpl-tree-icon-' + node.data.mimetype;
      },
      allowDrag: this.allowDrag,
      allowDrop: this.allowDrop,
      getNodeClone: (node: any | null) => ({
        ...node.data,
        id: UUID.UUID(),
        filename: `${node.data.filename}`,
      }),
      actionMapping: {
        mouse: {
          dblClick: (tree: any, node: any, $event: any) => {
            if (node.hasChildren) {
              TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
            }
          },
          drop: (
            tree: TreeModel,
            node: TreeNode,
            $event: any,
            { from, to }
          ) => {
            if (
              from.data.documentType.toLowerCase() ===
              to.parent.data.documentType.toLowerCase()
            ) {
              TREE_ACTIONS.MOVE_NODE(tree, node, $event, {
                from,
                to,
              });

              alert(
                `${from.data.filename} ${this.translate.instant(
                  'COPIED_TO'
                )}  ${to.parent.data.filename}`
              );
            } else {
              alert(`Only ${to.parent.data.documentType} are allowed`);
            }
            // https://angular2-tree.readme.io/docs/drag-drop
            // use from to get the dragged node.
            // use to.parent and to.index to get the drop location
            // use TREE_ACTIONS.MOVE_NODE to invoke the original action
          },
        },
        keys: {
          [KEYS.ENTER]: (tree: any, node: any, $event: any) => {
            node.expand();
          },
        },
      },
      nodeHeight: 24,
      useVirtualScroll: false,
      animateExpand: true,
      animateSpeed: 30,
      animateAcceleration: 1.2,
    };

    if (this.allowDrag || this.allowDrag) {
      this.options = this.staticOptions;
    }

    let allData = _.cloneDeep(this.ecplTreeData);
    // data for angular2-tree based on Editmode
    if (this.ecplTreeCheckbox) {
      this.nodes = [];
      this.nodes.push(...this.ecplTreeData);

      // // Set checkbox enabled if editmode true
      // allData.forEach((nodeData, index) => {
      //     this.setSelectedNodes(nodeData);
      // });
    } else {
      const activeNodes: any = [];
      allData.forEach((node, i) => {
        const nd = this.getSelectedNodes(node);
        if (nd) {
          activeNodes.push(nd);
        }
      });
      this.nodes = [];
      this.nodes.push(...activeNodes);
    }
  }

  setSelectedNodes(nodeData: any) {
    const treeModel: TreeModel = this.treeComponent.treeModel;
    if (!nodeData.isFolder) {
      const node: TreeNode = treeModel.getNodeById(nodeData.id);
      if (node && nodeData.status === 1) {
        node.setIsSelected(true);
      }
    } else {
      const node: TreeNode = treeModel.getNodeById(nodeData.id);
      if (node && nodeData.isFolder && nodeData.isDefault) {
        node.setIsSelected(true);
      }
      nodeData.nodes.forEach((n: any) => {
        this.setSelectedNodes(n);
      });
    }
  }

  getSelectedNodes(nodeData: any) {
    let selectedNodes: any = {};
    if (nodeData.status === 1) {
      selectedNodes = nodeData;
    } else {
      return null;
    }

    if (nodeData.nodes != null && nodeData.nodes.length > 0) {
      const nodes: any = [];
      nodeData.nodes.forEach((node: any, i: any) => {
        const nd = this.getSelectedNodes(node);
        if (nd) {
          nodes.push(nd);
        }
      });
      selectedNodes['nodes'] = nodes;
    }

    return selectedNodes;
  }


   getFileExtensionFromMimeType(input: string): string | null {
      return this.helperV2Service.getFileExtension(input);
   }

  //#endregion

  //#region file viewer/editors

  humanizeType(mimetype: any) {
    let type = '';
    //

    switch (mimetype) {
      case 'xlsx':
      case 'xls':
      case 'csv':
      case 'docx':
      case 'doc':
      case 'rtf':
      case 'ppt':
      case 'pptx':
      case 'csv':
        type = 'Document';
        break;
      case 'png':
      case 'jpg':
      case 'JPG':
      case 'jpeg':
      case 'bmp':
      case 'gif':
      case 'tiff':
        type = 'Image';
        break;
      case 'mp3':
      case 'wav':
        type = 'Audio';
        break;
      case 'avi':
      case 'mp4':
      case '3gpp':
      case 'webm':
      case 'flv':
        type = 'Video';
        break;
      case 'txt':
        type = 'Text';
        break;
      case 'PDF':
      case 'pdf':
        type = 'Pdf';
        break;
      case 'zip':
      case 'rar':
      case '7z':
        type = 'Archive file';
        break;
      default:
        type = 'Article';
        break;
    }

    return type;
  }

  getFileExtension(filename: any) {
    let fileExtension = filename.substr(filename.lastIndexOf('.') + 1);
    return fileExtension;
  }

  triggerFalseClick() {
    let el: HTMLElement = this.hiddenAction.nativeElement.querySelector('a');

    el.click();
  }

  showConsoleLog(name: any, obj: any) {}

  filterNodes(text: string, tree: any) {
    tree.treeModel.filterNodes(text);
    if (text === '') {
      tree.treeModel.expandedNodeIds = {};
    }
  }

  //#endregion

  editClick(node: any) {
    this.ecplNodeOptionsEvent.emit({ data: node, action: 'edit' });
  }
  printClick(node: any) {
    this.ecplNodeOptionsEvent.emit({ data: node, action: 'print' });
  }
  deleteClick(node: any) {
    this.ecplNodeOptionsEvent.emit({ data: node, action: 'delete' });
  }
}
