import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { FeatureManualService } from '../feature-documents/feature-document.services';
import { ManualService } from '../manuals/services/manual.services';
import { BaseServices } from '../kuba.services';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

class AppManualList {
  name: string;
  id: number;
  constructor() {}
}

@Component({
  selector: 'copy-manual-files',
  templateUrl: 'copyManualFiles.component.html'
})
export class CopyManualFilesComponent implements OnInit {
  featureKey: any;
  appManualTree: any;
  appManualTreeClone: any;
  featureManualTree: any;
  appManualList: AppManualList[] = [];
  selectedAppManual: AppManualList;
  treeActiveNode: any;
  treeNode: any;
  featureId: number;
  isAdminMode: boolean;
  toolTipForDegAndDrop = 'CTRL_DRAG_DROP';
  flattenned: any;
  flattenedClone: any;
  loading = false;
  private subscriptions: Subscription[] = [];
  @Output() refresh: EventEmitter<string>;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private featureManualService: FeatureManualService,
    private manualService: ManualService,
    private translate: TranslateService
  ) {
    this.featureKey = route.snapshot.parent.params['id'];
    let workingModule = JSON.parse(sessionStorage.getItem('workingModule'));
    this.featureId = workingModule.id;
    this.isAdminMode = BaseServices.UserRole === 'Admin';
    this.refresh = new EventEmitter<string>();
  }

  ShowSelectedAppManualTree() {
    let businessId: number = BaseServices.BusinessId
      ? BaseServices.BusinessId
      : 0;
    this.appManualTree = [];
    this.appManualTreeClone = [];
    this.flattenedClone = [];
    if (this.selectedAppManual.id != null) {
      this.subscriptions.push(
        this.manualService
          .getManualTreeBusinessLevel(
            this.selectedAppManual.id,
            +businessId,
            true // passing is coppy manual flag
          )
          .subscribe(
            (result: any) => {
              this.appManualTree = result;
              this.appManualTreeClone = _.clone(result);
              this.flattenedClone = _.clone(this.flattenArrObj(result));
            },
            (error: any) => {
              console.error('Api Error while SelectedAppManualTree:', error);
            },
            () => {
              console.log('RSelectedAppManualTree service complete.');
            }
          )
      );
    }
  }

  ngOnInit(): void {
    if (!this.isAdminMode) {
      this.subscriptions.push(
        this.manualService
          .getCommonManuallistByBusinessLogin(BaseServices.BusinessId)
          .subscribe(
            (result: any) => {
              this.appManualList = [];
              result.forEach(manual => {
                let m = new AppManualList();
                m.id = manual.AppManualId;
                m.name = manual.Title;
                this.appManualList.push(m);
              });
            },
            (error: any) => {
              console.error(
                'Api Error while getting assigned biz manual:',
                error
              );
            },
            () => {
              console.log('Got Assigned Manual');
            }
          )
      );
    } else {
      this.subscriptions.push(
        this.manualService
          .getCommonManuallist(BaseServices.ApplicationId)
          .subscribe(
            (result: any) => {
              this.appManualList = [];
              this.appManualList.push({
                id: null,
                name: this.translate.instant('SELECT_A_MANUAL')
              });
              result.forEach(manual => {
                let m = new AppManualList();
                m.id = manual.AppManualId;
                m.name = manual.Title;
                this.appManualList.push(m);
              });
            },
            (error: any) => {
              console.error(
                'Api Error while getting assigned biz manual:',
                error
              );
            },
            () => {
              console.log('Got Assigned Manual');
            }
          )
      );
    }

    this.subscriptions.push(
      this.featureManualService.getFeatureManualTree(this.featureKey).subscribe(
        (result: any) => {
          this.featureManualTree = result;
        },
        (error: any) => {
          console.error(
            'Api Error while getFeatureManualTree instance for Copy:',
            error
          );
        },
        () => {
          console.log('getFeatureManualTree instance for Copy complete.');
        }
      )
    );
  }

  refreshTreeNode() {
    this.subscriptions.push(
      this.featureManualService.getFeatureManualTree(this.featureKey).subscribe(
        (result: any) => {
          this.treeNode = result;
          this.refresh.emit('referesh');
        },
        (error: any) => {
          console.error('Api Error while refreshing tree:', error);
        },
        () => {
          console.log('Refreshing tree service complete.');
        }
      )
    );
  }
  // private
  private flattenArrObj(arr) {
    let newArr = [];
    if (Array.isArray(arr) && arr.length > 0) {
      arr.forEach(element => {
        newArr.push(element);
        if (Array.isArray(element.nodes) && element.nodes.length > 0) {
          newArr = newArr.concat(this.flattenArrObj(element.nodes));
        }
      });
    }
    return newArr;
  }

  // Events form Tree
  addManualEvent(event): void {
    switch (event.eventName) {
      case 'activate':
        break;
      case 'copyNode':
        this.loading = true;
        let treeData = _.clone(this.appManualTree);
        this.flattenned = this.flattenArrObj(treeData);
        event.treeData = this.flattenned;
        this.subscriptions.push(
          this.featureManualService
            .moveNode(
              +this.featureId,
              +this.featureKey,
              event,
              this.selectedAppManual.id
            )
            .subscribe(
              response => {
                if (response) {
                  this.refreshTreeNode();
                  this.loading = false;
                  this.refresh.emit('referesh');
                }
              },
              error => console.log('Manual movenoxde  Error : ', error),
              () => console.log('Manual movenode  Complete')
            )
        );
        break;
      case 'moveNode':
        this.loading = true;
        let treeMoveData = _.clone(this.appManualTreeClone);
        this.flattenned = this.flattenArrObj(treeMoveData);
        event.treeData = this.flattenedClone; // this.flattenned;
        this.subscriptions.push(
          this.featureManualService
            .moveNode(
              +this.featureId,
              +this.featureKey,
              event,
              this.selectedAppManual.id
            )
            .subscribe(
              response => {
                if (response) {
                  this.refreshTreeNode();
                  this.loading = false;
                  this.refresh.emit('referesh');
                }
              },
              error => console.log('Manual movenoxde  Error : ', error),
              () => console.log('Manual movenode  Complete')
            )
        );
        break;
      case 'initialized':
        break;
      case 'updateData':
        break;
      case 'loadNodeChildren':
        break;
      default:
        if (event.node.data.documentType === 'undercompletion') {
          this.router.navigate(
            [
              '../../../../../complete-checklist/incomplete/' +
                event.node.data.nodeId,
              'feature',
              0,
              0
            ],
            { relativeTo: this.route }
          );
        }
        break;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}
