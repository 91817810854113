import { DeviationServices as FollowDeviationService } from './../../../follow-ups/services/deviation.services';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ConfirmationService, SelectItem } from 'primeng/api';
import * as _ from 'lodash';
import { EmployeeRights } from './../../../employees/models/employee';
import { FDVServices } from './../../../FDV/services/fdv.service';
import { ProjectServices } from './../../../projects/services/project.service';
import { ToasterComponent } from './../../../../_directives/toaster.component';
import { BaseServices } from './../../../kuba.services';
import { DeviationList } from './../../models/deviationList';
import { deviationCaseType } from './../../models/deviationCaseType';
import { Component, ViewChild, OnInit } from '@angular/core';
import { DeviationServices } from '../../services/deviation.service';
import { ValidationService } from './../../../shared/services/validation.service';
import { Rights } from 'src/app/_models';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';

@Component({
  selector: 'deviations',
  templateUrl: 'deviation-list.component.html'
})
export class ListDeviationComponent implements OnInit {
  @ViewChild('deviationTable', { static: false }) deviationTable: Table;
  @ViewChild('dt', { static: false }) dt: Table;
  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  projectId: number;
  parentKey: any;
  isHideFS = true;
  parentId: any;
  parent: number;
  businessid: number;
  TypeOfCaseForm: FormGroup;
  public Title = '';
  imgUrl: string;
  daysDiff: number;
  hiddenEditedBy = false;
  Id: number;
  showAddDeviation = false;
  showEditDeviation = false;
  case = false;
  public Name = '';
  caseForm: FormGroup;
  aDeviation: any = {};
  value: any;
  pdf: any;
  status: SelectItem[];
  Case: SelectItem[];
  selectedFollowup: any;
  departments: SelectItem[];
  followup: SelectItem[];
  createdBy: SelectItem[];
  caseType: deviationCaseType[];
  selectedCaseType = new deviationCaseType();
  public deviationList: DeviationList[];
  statuses: SelectItem[];
  caseName: any;
  selectedCase = new deviationCaseType();
  hidingElement = false;
  caseId: number;
  isLabelDisabled: Boolean;
  isNotDone = true;
  canShowDeviation = false;
  currentLoginUserId = 0;
  isShowDeptDeviation = false;
  isDisplayDeviation = false;
  isHideDeleteIcon = true;
  isShowSaveButton = true;
  isDisableNewButton = true;
  isDisplayOwnDeviation: boolean;
  additionalData: any;
  Execution: SelectItem[];
  isCaseExists = false;
  caseExisting: string;
  isHideObject = true;
  selectedDeviation: any[];
  loading = false;
  isAhlsell = false;
  ProjectName: any;
  Showloader: boolean;
  isHideButton = true;
  isShowNewButton = true;
  rowsPerPageOptions = [];
  isHideChecklistName = true;
  isShowDeviation = false;
  isExistCaseText: string;
  showFilter = false;
  bulkReportDialog = false;
  isFs = false;
  private subscriptions: Subscription[] = [];
  isHidecostandcreated: true;
  isKundeEl: boolean = false;
  /**
   * constructor
   * @param router {{Router}}
   * @param route {{ActivatedRoute}}
   * @param deviationservice {{DeviationServices}}
   * @param formBuilder {{FormBuilder}}
   * @param confirmationService {{ConfirmationService}}
   */
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private deviationservice: DeviationServices,
    private formBuilder: FormBuilder,
    private confirmationService: ConfirmationService,
    private followupDeviationService: FollowDeviationService,
    private translate: TranslateService
  ) {
    this.parentId = route.snapshot.params['Id'];
    this.parentKey = route.snapshot.parent.parent.data['parent'];
    if (this.parentKey === 'FS') {
      this.isFs = true;
    }
    this.currentLoginUserId = BaseServices.UserId;
    this.statuses = [];
    this.subscriptions.push(
      this.translate.stream('SELECT_STATUSES').subscribe(val => {
        this.statuses = [];
        this.statuses.push(
          { label: val.SELECT, value: null },
          { label: val.NEW, value: 7 },
          { label: val.OPEN, value: 8 },
          { label: val.DONE, value: 9 },
          { label: val.REJECTED, value: 10 }
        );
      })
    );
    if (+BaseServices.roleId === 4) {
      this.isHideButton = false;
    }
    this.caseForm = this.formBuilder.group({
      Name: ['', [Validators.required, ValidationService.noWhitespaceValidator]]
    });

    this.TypeOfCaseForm = this.formBuilder.group({
      typeOfCase: []
    });
  }

  /**
   * get deviation list
   * responsible name is incorrect
   */
  ngOnInit() {
    // To hide side menu bar
    document.querySelector('body').classList.remove('opened');
    this.isKundeEl = this.parentKey === 'KUNDE_EL';
    if (
      (BaseServices.ApplicationId === 4 || BaseServices.ApplicationId === 2) &&
      this.parentKey === 'KUNDE_EL'
    ) {
      this.isAhlsell = true;
      this.isHideChecklistName = false;
      this.isHidecostandcreated = true;
    }
    if (BaseServices.UserRole == 'User' || BaseServices.UserRole == 'Guest') {
      this.onAdministrateDeviation();
    } else {
      this.bindDeviationList();
    }
    if (this.parentKey === 'PROJECT') {
      this.isHideFS = true;
      this.hiddenEditedBy = false;
    } else if (this.parentKey === 'FDV' || this.parentKey === 'VEHICLE') {
      this.isHideFS = true;
      this.hiddenEditedBy = true;
    } else if (this.parentKey === 'FS') {
      this.hiddenEditedBy = true;
      this.isHideObject = this.parentKey === 'FS' ? false : true;
      if (
        BaseServices.UserRole === 'User' ||
        BaseServices.UserRole === 'Guest'
      ) {
        this.isHideFS = true;
        this.isHideObject = false;
      } else {
        this.isHideFS = false;
      }
    } else if (
      this.parentKey === 'IC' ||
      this.parentKey === 'OS' ||
      this.parentKey === 'QS'
    ) {
      this.hiddenEditedBy = true;
      this.isHideFS = true;
    }
    if (this.deviationList) {
      this.initForm(this.deviationList);
    }
    this.bindDropDowns();
    this.exportPdf();
  }
  /**
   * bind deviation list
   */
  bindDeviationList() {
    this.deviationList = <DeviationList[]>this.route.snapshot.data['list'];
  }

  refreshList() {
    this.subscriptions.push(
      this.deviationservice
        .getAllByEach(this.parentId, this.parentKey)
        .subscribe(result => this.initForm(result))
    );
  }
  /**
   * bind deviation list by user
   */

  bindDeviationByUser() {
    if (BaseServices.UserRole == 'User' || BaseServices.UserRole == 'Guest') {
      this.deviationList = [];
      this.subscriptions.push(
        this.followupDeviationService
          .getDeviationsByUser(BaseServices.UserId)
          .subscribe((list: any) => {
            if (list) {
              this.deviationList = list;
            }
          })
      );
    }
  }
  /**
   * bind status icon for deviation list
   * @param deviations {{any}}
   */
  initForm(deviations?: any): any {
    this.deviationList = [];
    deviations.forEach((deviation: any) => {
      let daysDiff = 0;
      let statusIcon = '';
      let currentdate = new Date();
      let deadLine = new Date(deviation.Deadline);
      if (deviation.Deadline != null) {
        daysDiff = this.getDaysDiff(currentdate, deadLine);
      }
      if (deviation.Status === 'DONE') {
        statusIcon = 'icon ic-sm icon-clear-deadline';
      } else if (deviation.Status === 'REJECTED') {
        statusIcon = 'icon ic-sm icon-cancel';
      } else if (deviation.Deadline) {
        if (daysDiff > 3) {
          statusIcon = 'icon ic-sm icon-far-deadline';
        } else if (daysDiff > 0 && daysDiff < 3) {
          statusIcon = 'icon ic-sm icon-near-deadline';
        } else {
          statusIcon = 'icon ic-sm icon-over-deadline';
        }
      } else if (deviation.Status === 'NEW') {
        statusIcon = 'icon ic-sm icon-new-deadline';
      } else {
        statusIcon = 'icon ic-sm icon-over-deadline';
      }
      deviation.StatusIcon = statusIcon;
      this.rowsPerPageOptions = [10, 20, 50, 100];
      if (this.deviationList.length > 100) {
        this.rowsPerPageOptions.push(this.deviationList.length);
      }

      this.deviationList.push(deviation);
    });
  }

  /**
   * custom filter datatable of resp. for followup
   */
  onFollowupChanged(e: any, followUpDropdown: any) {
    if (e.value !== '') {
      this.deviationTable.filter(
        followUpDropdown.selectedOption.value,
        'FollowUpUserId',
        'equals'
      );
    } else {
      this.deviationTable.reset();
    }
  }
  /**
   * execution dropdown change
   */
  onExecutionChanged(e: any, executionDropdown: any) {
    if (e.value !== '') {
      this.deviationTable.filter(
        executionDropdown.selectedOption.value,
        'ExecutionUserId',
        'equals'
      );
    } else {
      this.deviationTable.reset();
    }
  }

  /**
   * custom filter datatable of resp. for department
   */
  onDepartmentChanged(e: any, departmentDropdown: any) {
    if (e.value) {
      this.deviationTable.filter(
        departmentDropdown.selectedOption.value,
        'BusinessDepartmentId',
        'equals'
      );
    } else {
      this.deviationTable.reset();
    }
  }

  /**
   * custom filter datatable of type of case
   */

  onDescriptionChanged(control: any) {
    if (control.value !== '') {
      this.deviationTable.filter(control.value, 'Description', 'contains');
    } else {
      this.deviationTable.reset();
    }
  }
  /**
   * custom filter datatable of type of case
   */
  onCaseChanged(e: any, caseDropdown: any) {
    if (e.value) {
      this.deviationTable.filter(
        caseDropdown.selectedOption.value,
        'CaseId',
        'equals'
      );
    } else {
      this.deviationTable.reset();
    }
  }
  onCreatedByChanged(e: any, createdByDropdown: any) {
    if (e.value !== '') {
      this.deviationTable.filter(
        createdByDropdown.selectedOption.value,
        'CreatedBy',
        'equals'
      );
    } else {
      this.deviationTable.reset();
    }
  }

  /**
   *delete deviation list
   */
  confirm(value: any) {
    this.confirmationService.confirm({
      message: this.translate.instant('DELETE_THIS_RECORD'),
      header: this.translate.instant('DELETE_CONFIRMATION'),
      icon: 'pi-trash',
      accept: () => {
        this.subscriptions.push(
          this.deviationservice.deleteDev(value).subscribe(response => {
            if (response) {
              this.refreshList();
              this.toasterComponent.callToastDlt();
            }
          })
        );
      }
    });
  }

  /**
   * custom filter datatable of status
   */
  onStatusChanged(e: any, statusDropdown: any) {
    if (e.value) {
      // custom filter datatable
      this.deviationTable.filter(
        statusDropdown.selectedOption.value,
        'StatusId',
        'equals'
      );
    } else {
      this.deviationTable.reset();
    }
  }

  /**
   * listing all casetype
   */
  viewCaseType() {
    this.isLabelDisabled = false;
    this.subscriptions.push(
      this.deviationservice.getAllCaseType().subscribe(result => {
        this.caseType = result;
      })
    );
  }

  /**
   *  getting case type value for edit in field
   */

  editCase(casetype: deviationCaseType) {
    this.caseId = casetype.Id;
    let result = {
      Name: casetype.Name
    };
    (<FormGroup>this.caseForm).setValue(result, { onlySelf: true });
  }

  /**
   * save and update type of case dropdown
   */
  saveCase() {
    this.isExistCaseText = this.translate.instant('TOPIC_EXIST');
    let isLabelDisabled: Boolean;
    let casetype = new deviationCaseType();
    casetype.Id = this.caseId ? this.caseId : 0;
    casetype.Name = this.caseForm.value.Name;
    casetype.Status = '1';
    casetype.BusinessId = BaseServices.BusinessId;
    casetype.ApplicationId = BaseServices.ApplicationId;
    if (casetype.Id > 0) {
      this.subscriptions.push(
        this.deviationservice
          .updateCaseType(casetype)
          .subscribe((caseType: any) => {
            this.case = false;
            this.bindCaseDropdown();
            this.TypeOfCaseForm.get('typeOfCase').patchValue(caseType.Id);
            this.toasterComponent.callToast();
            this.caseId = 0;
          })
      );
    } else {
      this.subscriptions.push(
        this.deviationservice
          .checkTypecaseExist(casetype.Name)
          .subscribe((result: any) => {
            if (result.Isavailable === true) {
              this.isLabelDisabled = true;
            } else {
              this.subscriptions.push(
                this.deviationservice
                  .createCaseType(casetype)
                  .subscribe((caseType: any) => {
                    this.case = false;
                    this.bindCaseDropdown();
                    this.TypeOfCaseForm.get('typeOfCase').patchValue(
                      caseType.Id
                    );
                    this.toasterComponent.callToast();
                  })
              );
            }
          })
      );
    }
  }

  /**
   * generate pdf
   */
  exportPdf() {
    this.parentKey = this.route.snapshot.parent.parent.data['parent'];
    this.ProjectName = this.route.snapshot.parent.parent.data['project'];
    this.subscriptions.push(
      this.translate.stream('FILENAME').subscribe(val => {
        if (this.parentKey === 'PROJECT') {
          this.additionalData = {
            fileName: val.PROJECT_DEVIATION_LIST,
            header: 'Project Deviation List',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
              { title: 'Deviation Number', dataKey: 'No' },
              { title: 'Project Name', dataKey: 'ProjectName' },
              { title: 'Title', dataKey: 'Title' },
              { title: 'Status', dataKey: 'Status' },
              { title: 'Importance', dataKey: 'Importance' },
              { title: 'Cost', dataKey: 'Cost' },
              { title: 'DisplayCost', dataKey: 'DisplayCost' },
              { title: 'Deadline', dataKey: 'DeadlineForPdf' },
              {
                title: 'Responsible for execution',
                dataKey: 'ResponsiblePerson'
              },
              { title: 'Created on', dataKey: 'CreatedOn' },
              { title: 'Done On', dataKey: 'CompletedDateForPdf' },
              { title: 'Edited By', dataKey: 'EditedBy' }
            ]
          };
        } else if (this.parentKey === 'FDV') {
          this.additionalData = {
            fileName: val.FDV_DLIST,
            header: 'FDV Deviation List',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
              {
                title: 'Name of the object',
                dataKey: 'TypeOfObject'
              },
              { title: 'Title', dataKey: 'Title' },
              { title: 'Status', dataKey: 'Status' },
              { title: 'Importance', dataKey: 'Importance' },
              { title: 'Cost', dataKey: 'Cost' },
              { title: 'DisplayCost', datakey: 'DisplayCost' },
              { title: 'Deadline', dataKey: 'Deadline' },
              {
                title: 'Responsible for execution',
                dataKey: 'ResponsiblePerson'
              },
              { title: 'Created on', dataKey: 'CreatedOn' },
              { title: 'Done On', dataKey: 'CompletedDate' },
              { title: 'Edited By', dataKey: 'EditedBy' }
            ]
          };
        } else if (this.parentKey === 'FS') {
          this.additionalData = {
            fileName: val.FS_DEVIATION_LIST,
            header: 'FS Deviation List',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
              { title: 'Title', dataKey: 'Title' },
              { title: 'Status', dataKey: 'Status' },
              { title: 'Importance', dataKey: 'Importance' },
              { title: 'Cost', dataKey: 'Cost' },
              { title: 'DisplayCost', datakey: 'DisplayCost' },
              { title: 'Modules', dataKey: 'Modules' },
              { title: 'Temperature', dataKey: 'Temperature' },
              { title: 'Deadline', dataKey: 'Deadline' },
              {
                title: 'Responsible for Execution',
                dataKey: 'ExecutionUserId'
              },
              { title: 'Created on', dataKey: 'CreatedOn' },
              { title: 'Created By', dataKey: 'CreatorName' },
              { title: 'Done On', dataKey: 'CompletedDate' }
            ]
          };
        } else if (this.parentKey === 'KUNDE_EL') {
          this.additionalData = {
            fileName: val.DEVIATION_LIST,
            header: 'Deviation List',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
              { title: 'Deviation Number', dataKey: 'No' },
              { title: 'Title', dataKey: 'Title' },
              { title: 'Status', dataKey: 'Status' },
              { title: 'Importance', dataKey: 'Importance' },
              { title: 'Cost', dataKey: 'Cost' },
              { title: 'DisplayCost', datakey: 'DisplayCost' },
              { title: 'Deadline', dataKey: 'Deadline' },
              {
                title: 'Responsible for Execution',
                dataKey: 'ExecutionUserId'
              },
              { title: 'Created on', dataKey: 'CreatedOn' },
              { title: 'Created By', dataKey: 'CreatorName' },
              { title: 'Done On', dataKey: 'CompletedDate' },
              { title: 'Edited By', dataKey: 'EditedBy' }
            ]
          };
        } else {
          this.additionalData = {
            fileName: val.DEVIATION_LIST,
            header: 'Deviation list',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
              { title: 'Title', dataKey: 'Title' },
              { title: 'Status', dataKey: 'Status' },
              { title: 'Importance', dataKey: 'Importance' },
              { title: 'Cost', dataKey: 'Cost' },
              { title: 'DisplayCost', datakey: 'DisplayCost' },
              { title: 'Modules', dataKey: 'Modules' },
              { title: 'Deadline', dataKey: 'Deadline' },
              {
                title: 'Responsible for Execution',
                dataKey: 'ExecutionUserId'
              },
              { title: 'Created on', dataKey: 'CreatedOn' },
              { title: 'Created By', dataKey: 'CreatorName' },
              { title: 'Done On', dataKey: 'CompletedDate' },
              {
                title: 'Notification before the Follow-up[days]',
                dataKey: 'NotificationBeforeFollowup'
              }
            ]
          };
        }
      })
    );
  }

  /**
   * binding color based on importance
   */
  assignRiskData(importance: any) {
    let impData: any = [];
    if (importance) {
      switch (importance) {
        case 'Low':
          impData.id = 1;
          impData.value = 'Low';
          impData.backColor = '#4ec55c';
          break;
        case 'Medium':
          impData.id = 2;
          impData.value = 'Medium';
          impData.backColor = '#f0ac19';
          break;
        case 'High':
          impData.id = 3;
          impData.value = 'High';
          impData.backColor = '#e7644f';
          break;
      }
    }
    return impData;
  }
  /**
   *  delete case type dropdown
   */
  deleteCase(selectedCaseType: any) {
    this.subscriptions.push(
      this.deviationservice
        .checkExistingTypeForDelete(selectedCaseType)
        .subscribe((result: any) => {
          if (result) {
            this.isCaseExists = true;
            this.caseExisting = this.translate.instant(
              'YOU_CANNOT_DELETE_CASE_USED_BY_ANOTHER_DEVIATION'
            );
            setTimeout(
              function () {
                this.isCaseExists = false;
              }.bind(this),
              3000
            );
          } else {
            this.confirmationService.confirm({
              message: this.translate.instant('DELETE_THIS_RECORD'),
              accept: () => {
                this.subscriptions.push(
                  this.deviationservice
                    .deleteType(selectedCaseType)
                    .subscribe(() => {
                      this.viewCaseType();
                      this.bindCaseDropdown();
                      this.toasterComponent.callToastDlt();
                    })
                );
              }
            });
          }
        })
    );
  }
  /**
   * bind dropdowns
   */
  bindDropDowns() {
    this.status = [
      { label: 'All', value: null },
      { label: 'New Issue', value: '1' },
      { label: 'Open', value: '2' },
      { label: 'Done', value: '3' },
      { label: 'Rejected', value: '4' }
    ];
    this.followup = [];
    let followup = this.route.snapshot.data['user'];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
        this.followup = [];
        this.followup.push({ label: val.SELECT, value: null });
        if (followup) {
          followup.forEach((user: any) => {
            this.followup.push({
              label: user.Name,
              value: user.Id
            });
          });
        }
      })
    );

    this.Execution = [];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
        this.Execution = [];
        this.Execution.push({ label: val.SELECT, value: null });
        if (followup) {
          followup.forEach((user: any) => {
            this.Execution.push({
              label: user.Name,
              value: user.Id
            });
          });
        }
      })
    );

    this.createdBy = [];
    let createdBy = this.route.snapshot.data['createdBy'];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
        this.createdBy = [];
        this.createdBy.push({ label: val.SELECT, value: null });
        if (createdBy) {
          createdBy.forEach((user: any) => {
            this.createdBy.push({
              label: user.Name,
              value: user.Id
            });
          });
        }
      })
    );
    this.departments = [];
    let departments = this.route.snapshot.data['department'];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
        this.departments = [];
        this.departments.push({ label: val.SELECT, value: null });
        if (departments) {
          departments.forEach((department: any) => {
            this.departments.push({
              label: department.Name,
              value: department.Id
            });
          });
        }
      })
    );

    this.Case = [];
    let typeCase = this.route.snapshot.data['typeCase'];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
        this.Case = [];
        this.Case.push({ label: val.SELECT, value: null });
        if (typeCase) {
          typeCase.forEach((type: any) => {
            this.Case.push({ label: type.Name, value: type.Id });
          });
        }
      })
    );
  }

  /**
   * bind case dropdown
   */
  bindCaseDropdown() {
    this.subscriptions.push(
      this.deviationservice.getAllCaseType().subscribe((cases: any) => {
        this.Case = [];
        this.subscriptions.push(
          this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.Case.push({ label: val.SELECT, value: null });
          })
        );
        if (cases) {
          cases.forEach((caseValue: any) => {
            this.Case.push({
              label: caseValue.Name,
              value: caseValue.Id
            });
          });
        }
        this.caseType = cases;
      })
    );
  }
  onCaseDialogHide() {
    this.isLabelDisabled = false;
    this.caseForm.reset();
  }
  /**
   * display case dropdown
   */
  showCase() {
    this.case = true;
  }
  /**
   * get days difference between start date and end date
   * @param StartDate {{date}}
   * @param EndDate {{date}}
   */
  getDaysDiff(StartDate: Date, EndDate: Date) {
    if (StartDate && EndDate) {
      let _MS_PER_DAY = 1000 * 60 * 60 * 24;
      let date1 = Date.UTC(
        StartDate.getFullYear(),
        StartDate.getMonth(),
        StartDate.getDate()
      );
      let date2 = Date.UTC(
        EndDate.getFullYear(),
        EndDate.getMonth(),
        EndDate.getDate()
      );
      let res: any = Math.floor((date2 - date1) / _MS_PER_DAY);
      return res;
    }
  }
  editDeviation(id: number) {
    if (
      BaseServices.UserRole.toUpperCase() === 'USER' ||
      BaseServices.UserRole.toUpperCase() === 'GUEST'
    ) {
      let userRights = BaseServices.getUserRights();
      if (userRights) {
        userRights = JSON.parse(userRights);
        _.each(userRights, (x: EmployeeRights) => {
          if (
            (x.id === 20 || x.id === 43) &&
            x.isChecked === userRights.isChecked
          ) {
            this.deviationList.forEach(element => {
              if (element.ExecutionUserId === BaseServices.UserId) {
                this.router.navigate(['./../deviation', id], {
                  relativeTo: this.route
                });
              } else {
                alert('You do not have rights to do this operation');
              }
            });
          } else {
            this.router.navigate(['./../deviation', id], {
              relativeTo: this.route
            });
          }
        });
      }
    } else {
      this.router.navigate(['./../deviation', id], {
        relativeTo: this.route
      });
    }
  }

  /**
   * add deviation based on the deviation rights
   */
  onDeviationRights() {
    let userRightsId = Rights.DEVIATION_RIGHTS;
    let role = BaseServices.UserRole;
    if (role === 'Guest') {
      this.isHideDeleteIcon = false;
      this.isDisableNewButton = BaseServices.checkUserRights(
        userRightsId,
        role
      );
      if (this.isDisableNewButton === true) {
        this.isShowNewButton = true;
      } else {
        this.isShowNewButton = false;
      }
    }
  }

  /**
   * see only their department deviation based on their rights
   */
  onSeeOnlyDeviationInOwnDepartment() {
    let currentUserRole = BaseServices.UserRole;
    let userRightsId = Rights.SEE_ONLY_DEVIATION_IN_OWN_DEPT;
    this.isShowDeptDeviation = BaseServices.checkUserRights(
      userRightsId,
      currentUserRole
    );
    if (this.isShowDeptDeviation) {
      this.subscriptions.push(
        this.deviationservice
          .getAllDeviationByDepartment(BaseServices.UserId)
          .subscribe((deviations: any) => {
            if (deviations != null) {
              this.deviationList = deviations;
            }
          })
      );
    } else {
      this.bindDeviationList();
    }
  }
  /**
   * Edit the page based upon administrate deviation rights
   */
  onAdministrateDeviation() {
    let currentUserRole = BaseServices.UserRole;
    let userRightsId = Rights.ADMINISTRATE_DEVIATION;
    if (currentUserRole === 'User' || currentUserRole === 'Guest') {
      this.isShowSaveButton = BaseServices.checkUserRights(
        userRightsId,
        currentUserRole
      );
      this.isHideButton = BaseServices.checkUserRights(
        userRightsId,
        currentUserRole
      );
      this.isHideDeleteIcon = false;
      if (this.isShowSaveButton === false) {
        this.isShowDeviation = true;
        if (currentUserRole === 'Guest') {
          this.onDeviationRights();
        } else {
          this.isDisableNewButton = false;
        }
        this.onDisplayOwnDeviation();
        this.onViewDeviation();
      } else if (this.isShowSaveButton === true) {
        this.bindDeviationList();
      }
    }
  }

  /**
   * Display the deviation list based upon displayown deviation rights
   */

  onDisplayOwnDeviation() {
    let currentUserRole = BaseServices.UserRole;
    let userRightsId = Rights.DISPLAY_OWN_DEVIATION_ONLY;
    if (currentUserRole === 'User' || currentUserRole === 'Guest') {
      this.isDisplayOwnDeviation = BaseServices.checkUserRights(
        userRightsId,
        currentUserRole
      );
      if (this.isDisplayOwnDeviation === true) {
        this.bindDeviationByUser();
      } else {
        this.onSeeOnlyDeviationInOwnDepartment();
      }
    }
  }

  /**
   * update deviation based on viewdeviation rights
   */
  onViewDeviation() {
    let currentUserRole = BaseServices.UserRole;
    let userRightsId = Rights.VIEW_DEVIATION;
    if (currentUserRole === 'User' || currentUserRole === 'Guest') {
      this.isShowSaveButton = BaseServices.checkUserRights(
        userRightsId,
        currentUserRole
      );
    }
  }

  bulkReport() {
    this.loading = true;
    this.bulkReportDialog = true;
    let fileName = 'KontrollRapport';
    let type = 'pdf';
    let ReportJson: any[] = [];
    let position = 0;
    let cultureInfo = sessionStorage.getItem('languageMode');
    let selectedDeviationList: any[] = this.selectedDeviation;
    selectedDeviationList.forEach(element => {
      let details: any = {
        DeviationId: element.Id,
        DeviationNo: element.No,
        Title: element.Title,
        Status: element.StatusId,
        Importance: element.Importance,
        Cost: element.Cost,
        Deadline: element.Deadline,
        ResponsiblePerson: element.ResponsiblePerson,
        EditedBy: element.EditedBy,
        CreatedBy: element.CreatedBy
      };
      ReportJson.push(details);
    });

    this.confirmationService.confirm({
      message: this.translate.instant('DEVIATIONREPORT'),
      header: this.translate.instant('DELETE_CONFIRMATION'),
      icon: 'pi-trash',
      accept: () => {
        this.subscriptions.push(
          this.deviationservice
            .reportGenerate(
              BaseServices.BusinessId,
              this.parentId,
              cultureInfo,
              ReportJson,
              'YES'
            )
            .subscribe(
              blob => {
                if (blob != null) {
                  this.loading = false;
                  let link = document.createElement('a');
                  link.href = window.URL.createObjectURL(blob);
                  link.download = `${fileName}.${type.toLowerCase()}`;
                  link.click();
                } else {
                  this.loading = false;
                }
              },
              error => {
                this.loading = false;
              }
            )
        );
      },
      reject: () => {
        this.subscriptions.push(
          this.deviationservice
            .reportGenerate(
              BaseServices.BusinessId,
              this.parentId,
              cultureInfo,
              ReportJson,
              'NO'
            )
            .subscribe(
              blob => {
                if (blob != null) {
                  this.loading = false;
                  let link = document.createElement('a');
                  link.href = window.URL.createObjectURL(blob);
                  link.download = `${fileName}.${type.toLowerCase()}`;
                  link.click();
                } else {
                  this.loading = false;
                }
              },
              error => {
                this.loading = false;
              }
            )
        );
      }
    });
  }
  /**
   * Show loading bar when export the data.
   */
  loader(value: any) {
    this.Showloader = value;
  }
  /**
   * get user rights
   * @param featureName
   */
  hideTypeOfcase(featureName: string): boolean {
    if (featureName === 'PROJECT') {
      return this.isUserRights(Rights.ADMINISTRATE_PROJECTS);
    } else if (featureName === 'FDV') {
      return this.isUserRights(Rights.ADMINISTRATE_FDV);
    } else if (featureName === 'IC') {
      return this.isUserRights(Rights.ADMINISTRATE_INTERNAL_CONTROL);
    } else if (this.parentKey === 'OS') {
      return this.isUserRights(Rights.ADMINISTRATE_OTHER_SYSTEMS);
    } else if (this.parentKey === 'QS') {
      return this.isUserRights(Rights.ADMINISTRATE_QUALITY_SYSTEM);
    } else if (featureName === 'VEHICLE') {
      return false;
    } else if (featureName === 'FS') {
      return false;
    } else if (featureName === 'KUNDE_EL') {
      return true;
    } else if (featureName === 'FRAMEWORK') {
      return this.isUserRights(Rights.FRAMEWORK_AGREEMENT_RIGHTS);
    } else if (featureName === 'SA') {
      return this.isUserRights(Rights.FRAMEWORK_AGREEMENT_RIGHTS);
    }
    return true;
  }

  isUserRights(rightsId: number): boolean {
    return BaseServices.checkUserRights(+rightsId, BaseServices.UserRole);
  }
  showNotificationTab() {
    this.bulkReportDialog = false;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}
