import { Route } from '@angular/router';
import { AuthGuard } from './../../_guards/auth.guard';
import { deviationRoutes } from './../deviation/deviation.routing';
import { FeatureDocumentRoutes } from './../feature-documents/feature-document.routing';
import { registrationRoutes } from './../Registration/registration.routing';
import { safetyjobRoutes } from './../safety-job-analysis/jobanalysis.routing';
import { taskmanagerRoutes } from './../task-manager/task-manager.routing';
import { timetableRoutes } from './../time-table/timetable.routing';
import {
  EmployeeContactUserResolver,
  EmployeeContactUserSubscriptionResolver,
} from './../users/services/user.resolver.service';
import { FdvListComponent } from './component/fdvList.component';
import { FdvListAllComponent } from './component/fdvListAll.component';
import { FdvMyListComponent } from './component/fdvMyList.component';
import { FdvNewComponent } from './component/fdvNew.component';
import { FdvViewComponent } from './component/fdvView.component';
import { FdvComponent } from './fdv.component';
import {
  ChooseObjectDropDownResolver,
  FdvEditResolver,
  FdvFeaturesResolver,
  FdvListResolver,
  FdvMyListResolver,
} from './services/fdv.resolver.service';

export const fdvRoutes: Route[] = [
  {
    path: 'fire-deviation',
    component: FdvComponent,
    children: [
      {
        path: 'list',
        component: FdvListAllComponent,
        canActivate: [AuthGuard],
        children: [
          { path: '', redirectTo: 'list', pathMatch: 'full' },
          {
            path: 'list',
            component: FdvListComponent,
            resolve: {
              list: FdvListResolver,
            },
          },
          {
            path: 'myList',
            component: FdvMyListComponent,
            resolve: {
              myList: FdvMyListResolver,
            },
          },
        ],
      },
      {
        path: 'edit/:id',
        component: FdvNewComponent,
        resolve: {
          edit: FdvEditResolver,
          contact: EmployeeContactUserResolver,
          chooseObject: ChooseObjectDropDownResolver,
          users_subscription: EmployeeContactUserSubscriptionResolver,
        },
      },
      {
        path: 'createFdv',
        component: FdvNewComponent,
        resolve: {
          contact: EmployeeContactUserResolver,
          chooseObject: ChooseObjectDropDownResolver,
          users_subscription: EmployeeContactUserSubscriptionResolver,
        },
      },
      {
        path: 'details/:id',
        component: FdvViewComponent,
        resolve: {
          additionalFeatures: FdvFeaturesResolver,
          edit: FdvEditResolver,
        },
        data: { parent: 'FDV' },
        canActivate: [AuthGuard],
        children: [
          ...FeatureDocumentRoutes,
          ...deviationRoutes,
          ...taskmanagerRoutes,
          ...timetableRoutes,
          ...safetyjobRoutes,
          ...registrationRoutes,
        ],
      },
    ],
  },
];
