<div class="page-title">
  <span>
    <span translate>{{ applicationId === 3 ? 'TASK_MANAGING' : 'TASK_MANAGER' }}</span>  </span>
  <span class="page-actions">
    <a
      [routerLink]="['./../edit', 0]"
      class="btn btn-success"
      *ngIf="isNewButtonEnable || projectcheck"
    >
      <span class="icon ic-sm icon-add"></span>
      <span>
        <span translate>NEW</span>
      </span>
    </a>
  </span>
</div>
<div class="table-view mbtm-30">
  <!-- Table starts -->
  <p-table
    #taskTable
    [value]="tasklist"
    exportFilename="TaskManager list"
    selectionMode="multiple"
    [(selection)]="selectedTasklist"
    [rows]="10"
    [paginator]="true"
    [pageLinks]="3"
    [rowsPerPageOptions]="[5, 10, 20]"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="{{ 'SUMMARY_TOTALRECORDS' | translate }}"
  >
    <!-- Caption -->
    <ng-template pTemplate="caption">
      <div class="ui-helper-clearfix">
        <div class="table-options">
          <div class="pull-right">
            <kuba-export
              [reportData]="taskTable.filteredValue || taskTable.value"
              [additionalData]="additionalData"
            ></kuba-export>
          </div>
        </div>
      </div>
    </ng-template>
    <!-- Header -->
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 55px">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th pSortableColumn="No" width="110px">
          {{ "NUMBER_NO" | translate }} <p-sortIcon field="No"></p-sortIcon>
        </th>
        <th width="35px"></th>
        <th pSortableColumn="Title">
          {{ "TITLE" | translate }} <p-sortIcon field="Title"></p-sortIcon>
        </th>
        <th pSortableColumn="Description">
          {{ "DESCRIPTION" | translate }}
          <p-sortIcon field="Description"> </p-sortIcon>
        </th>
        <th pSortableColumn="RecurrDayName">
          {{ "RECURRENCE" | translate }}
          <p-sortIcon field="RecurrDayName"> </p-sortIcon>
        </th>
        <th pSortableColumn="EndDate" width="110px">
          {{ "DEADLINE" | translate }}
          <p-sortIcon field="EndDate"> </p-sortIcon>
        </th>
        <th pSortableColumn="CreatorName">
          {{ "CREATED_BY" | translate }}
          <p-sortIcon field="CreatorName"> </p-sortIcon>
        </th>
        <th *ngIf="isDisableDeleteIcon" width="85px">
          {{ "OPTIONS" | translate }}
        </th>
      </tr>
      <tr>
        <th></th>
        <th>
          <input
            pInputText
            type="text"
            (input)="
              taskTable.filter($any($event.target)?.value, 'No', 'contains')
            "
            placeholder=""
            class="p-column-filter"
          />
        </th>
        <th width="54px"></th>
        <th>
          <input
            pInputText
            type="text"
            (input)="
              taskTable.filter($any($event.target)?.value, 'Title', 'contains')
            "
            placeholder=""
            class="p-column-filter"
          />
        </th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th *ngIf="isDisableDeleteIcon"></th>
      </tr>
    </ng-template>
    <!-- Body Content -->
    <ng-template pTemplate="body" let-task>
      <tr>
        <td style="width: 55px">
          <p-tableCheckbox id="selectedOrders" [value]="task">
          </p-tableCheckbox>
        </td>
        <td>
          <span>{{ task.No }}</span>
        </td>
        <td width="54px">
          <div
            *ngIf="task.ChecklistId != 0 && task.ChecklistId != null"
            class="ic-md"
          >
            <img
              src="/assets/file-icons/kb-checklist.png"
              alt="checklist file"
              width="28px"
            />
          </div>
        </td>
        <td>
          <a
            *ngIf="task.Responsible || isAdministrateActivity"
            [routerLink]="['./../edit', task.Id]"
            [class.disabled]="task.RoleId === '1' || task.RoleId === '2'"
            pTooltip="{{ task.Title }}"
            tooltipPosition="right"
            >{{ task.Title }}</a
          >
          <div
            *ngIf="!(task.Responsible || isAdministrateActivity)"
            pTooltip="{{ task.Title }}"
            tooltipPosition="right"
          >
            {{ task.Title }}
          </div>
        </td>
        <td>
          <div
            pTooltip="{{ task.Description }}"
            tooltipPosition="right"
            class="truncate-data"
          >
            {{ task.Description }}
          </div>
        </td>
        <td>
          <div
            pTooltip="{{ recurTranslate(task.RecurrDayName) }}"
            tooltipPosition="right"
            class="truncate-data"
          >
            {{ recurTranslate(task.RecurrDayName) }}
          </div>
        </td>
        <td>
          <span>{{ task.EndDate | date : "dd/MM/yyyy" }}</span>
        </td>
        <td>
          <span>{{ task.CreatorName }}</span>
        </td>
        <td class="col-button" *ngIf="isDisableDeleteIcon">
          <a
            (click)="onClickDeleteRecurringActivity(task)"
            *ngIf="roleId === '3'"
            class="btn btn-icon"
            title="{{ 'DELETE' | translate }}"
            routerLinkActive="active"
          >
            <i class="icon ic-sm icon-trash"></i>
          </a>
        </td>
      </tr>
    </ng-template>

    <!-- Empty message -->
    <ng-template
      pTemplate="emptymessage"
      *ngIf="isDisableDeleteIcon"
      let-columns
    >
      <tr>
        <td colspan="9">
          {{ "NO_RECORDS_FOUND" | translate }}
        </td>
      </tr>
    </ng-template>
    <!-- Empty message -->
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td colspan="8">
          {{ "NO_RECORDS_FOUND" | translate }}
        </td>
      </tr>
    </ng-template>
  </p-table>
  <!-- Table Ends-->
</div>

<p-confirmDialog
  header="{{ 'CONFIRMATION' | translate }}"
  icon="pi pi-question-circle"
  width="425"
  acceptLabel="{{ 'SWITCH_YES' | translate }}"
  rejectLabel="{{ 'SWITCH_NO' | translate }}"
></p-confirmDialog>
<toaster-component></toaster-component>
<div class="panel-loader" *ngIf="loading">
  <span>
    <span class="loader-icon"></span>
    <span translate>PROCESSING</span>
  </span>
</div>
