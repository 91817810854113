import { LocationStrategy } from '@angular/common';
import { Component, OnInit, Input, Renderer2, AfterViewInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'ecpl-pdf-viewer',
  templateUrl: './ecpl-pdf-viewer.component.html',
  styleUrls: ['./ecpl-pdf-viewer.component.css'],
})
export class EcplPdfViewerComponent implements OnInit, AfterViewInit {
  @Input() id: string;
  @Input() pdfSrc = 'https://vadimdez.github.io/ng2-pdf-viewer/pdf-test.pdf';
  @Input() pdfName: string;
  @Input() isDialog: boolean;
  @Output() close = new EventEmitter<void>();
  showModalWindow: boolean;

  constructor(private renderer: Renderer2, location: LocationStrategy) {
    location.onPopState(() => {
      this.showModalWindow = false;
      this.renderer.removeClass(document.body, 'modal-open');
    });
  }

  showModal() {
    this.showModalWindow = true;
    this.renderer.addClass(document.body, 'modal-open');
  }

  hideModal() {
    this.showModalWindow = false;
    this.renderer.removeClass(document.body, 'modal-open');
    this.close.emit();
  }

  ngOnInit() {}

  ngAfterViewInit() {
    if (this.isDialog) {
      this.showModal();
    }
  }
}
