import { BaseServices } from 'src/app/kuba/kuba.services';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';

import { MoveBusinessServices } from './../services/move-business.service';
import { BusinessView } from './../../businesses/models/businessView';
import { ToasterComponent } from './../../../_directives/toaster.component';
import { TranslateService } from '@ngx-translate/core';
import { BusinessPortalService } from 'src/app/kuba/portals/services/business-portal.services';
import * as _ from 'lodash';
import { PortalUser } from '../models/portalUser';
import { Subscription } from 'rxjs';

@Component({
  selector: 'move-business',
  templateUrl: 'move-business.component.html'
})
export class MoveBusinessComponent implements OnInit {
  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  moveBusinessForm: FormGroup;
  Users: any[];
  SelectedUser: any[];
  businesslist: any = [];
  selectedBusiness: any = [];
  selectedUserId: number;
  isFromUserSelected = false;
  userId: number;
  userList: any[];
  selectedUserList: any[];
  tblUser: PortalUser[];
  loading = false;
  private subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private moveBusinessServies: MoveBusinessServices,
    private _fb: FormBuilder,
    private translate: TranslateService,
    private businessPortalService: BusinessPortalService
  ) {
    this.moveBusinessForm = this._fb.group({});
  }

  ngOnInit() {
    let list = this.route.snapshot.data['list'];
    if (list) {
      this.userList = list;
      this.Users = [];
      this.subscriptions.push(
        this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
          this.Users = [];
          this.Users.push({ label: val.SELECT, value: null });
        })
      );
      list.forEach((listData: any) => {
        this.Users.push({
          label: listData.Name,
          value: listData.Id,
          portalManId: listData.PortalManagerId
        });
      });
    }
    this.SelectedUser = [];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
        this.SelectedUser = [];
        this.SelectedUser.push({ label: val.SELECT, value: null });
      })
    );
  }
  onFromUserChange(e: any) {
    this.selectedBusiness = [];
    this.isFromUserSelected = true;

    //if portalManager exists then it is portal select portalmanager id from selected portal
    //else it is admin user select userid
    this.userId = e.selectedOption.portalManId
      ? e.selectedOption.portalManId
      : e.value;

    //if portalManager exists then it is portal select portal id
    //else it is admin user portal id is 0
    let portalId = e.selectedOption.portalManId ? e.value : 0;
    if (this.userId) {
      this.subscriptions.push(
        this.moveBusinessServies
          .businessListByAdminUserOrPortal(this.userId, portalId)
          .subscribe((list: any) => {
            if (list) {
              this.businesslist = list;
            }
          })
      );
      this.subscriptions.push(
        this.moveBusinessServies
          .getUserAndPortalTomoveBusiness(
            e.value,
            e.selectedOption.portalManId > 0
          )
          .subscribe((selectedList: any) => {
            if (selectedList) {
              this.SelectedUser = [];
              this.subscriptions.push(
                this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
                  this.SelectedUser = [];
                  this.SelectedUser.push({ label: val.SELECT, value: null });
                })
              );
              selectedList.forEach((listData: any) => {
                this.SelectedUser.push({
                  label: listData.Name,
                  value: listData.Id,
                  portalManId: listData.PortalManagerId
                });
              });
            }
          })
      );
    } else {
      this.isFromUserSelected = false;
      this.SelectedUser = [];
      this.subscriptions.push(
        this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
          this.SelectedUser = [];
          this.SelectedUser.push({ label: val.SELECT, value: null });
        })
      );
    }
  }

  onToUserChange(e: any) {
    this.selectedUserList = [];
    this.tblUser = [];
    //if selected to is portal portalManagerId set to  selectedUserId
    //if selected to is admin user userid set to selectedUserId
    this.selectedUserId = e.selectedOption.portalManId
      ? e.selectedOption.portalManId
      : e.value;
    if (e.selectedOption.portalManId) {
      this.subscriptions.push(
        this.businessPortalService
          .getPortalUsersByPortalManagerId(e.value)
          .subscribe(result => {
            if (result) {
              this.tblUser = result;
            }
          })
      );
    }
  }

  userChecked(value: any) {}

  moveBusiness() {
    this.loading = true;
    if (
      this.userId &&
      this.selectedUserId &&
      this.selectedBusiness.length > 0
    ) {
      let selectedPortalUser: any = [];
      this.tblUser.forEach((element: any) => {
        if (element.IsChecked && element.IsPortalManager !== true) {
          selectedPortalUser.push({ Id: element.Id });
        }
      });

      let selectBusinesslst: any = [];
      this.selectedBusiness.forEach((element: any) => {
        let business = new BusinessView();
        selectBusinesslst.push({
          Id: element.Id,
          Organization: element.OrganizationNumber,
          CompanyName: element.CompanyName,
          Company: element.CompanyNumber,
          AboutBusiness: element.AboutBusiness,
          Address: element.Address,
          Zipcode: element.Zipcode,
          Telephone: element.Telephone,
          Email: element.Email,
          BusinessUsersId: element.BusinessUsersId,
          Status: element.Status,
          ModifiedBy: BaseServices.UserId,
          PortalUsers: selectedPortalUser
        });
      });

      this.subscriptions.push(
        this.moveBusinessServies
          .updateBusiness(this.selectedUserId, selectBusinesslst)
          .subscribe((updatedBusiness: any) => {
            if (updatedBusiness) {
              this.tblUser = [];
              this.toasterComponent.callToast();
              this.loading = false;
            }
          })
      );
    } else {
      this.loading = false;
      if (!this.userId) {
        alert(this.translate.instant('PLEASE_SELECT_FROM_USER'));
      } else if (!this.selectedUserId) {
        alert(this.translate.instant('PLEASE_SELECT_TO_USER'));
      } else if (this.selectedBusiness.length === 0) {
        alert(this.translate.instant('PLEASE_SELECT_ONE_RECORD'));
      } else if (this.selectedBusiness.length === 0 && !this.selectedUserId) {
        alert(this.translate.instant('PLEASE_SELECT_ONE_RECORD'));
      }
    }
    this.resetForm();
  }

  checkAll(event: any) {
    _.forEach(this.tblUser, (item: any) => {
      if (event) {
        item.IsChecked = true;
      } else {
        item.IsChecked = false;
      }
    });
    let defaultUser = this.tblUser.filter(
      (x: any) => x.Id === this.selectedUserId
    );
    if (!event) {
      this.selectedUserList = [];
      if (defaultUser.length > 0) {
        this.selectedUserList = defaultUser;
      }
    }
  }

  resetForm() {
    this.Users = [];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
        this.Users = [];
        this.Users.push({ label: val.SELECT, value: null });
      })
    );
    this.isFromUserSelected = false;
    this.SelectedUser = [];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
        this.SelectedUser = [];
        this.SelectedUser.push({ label: val.SELECT, value: null });
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}
