import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { QualitySystem } from '../models/quality-system';
import { BaseServices } from './../../kuba.services';
import { QualitySystemService } from './quality-system.service';

@Injectable()
export class InternalControlResolver implements Resolve<QualitySystem[]> {
  constructor(private backend: QualitySystemService) {}
  resolve(route: ActivatedRouteSnapshot): Observable<QualitySystem[]> {
    let businessId = BaseServices.BusinessId;
    return this.backend.getAll(BaseServices.BusinessId);
  }
}

@Injectable()
export class InternalControlFeaturesResolver implements Resolve<any[]> {
  constructor(private backend: QualitySystemService) {}
  resolve(route: ActivatedRouteSnapshot): any {
    if (BaseServices.roleId === '3') {
      return this.backend.getInternalControlFeatures(BaseServices.BusinessId);
    }
  }
}

@Injectable()
export class InternalControlEditResolver implements Resolve<QualitySystem[]> {
  constructor(private backend: QualitySystemService) {}
  resolve(route: ActivatedRouteSnapshot): Observable<QualitySystem[]> {
    let businessId = BaseServices.BusinessId;
    return this.backend.getInternalControlById(+route.params['id']);
  }
}

@Injectable()
export class InternalControlByUserResolver implements Resolve<QualitySystem[]> {
  constructor(private backend: QualitySystemService) {}
  resolve(route: ActivatedRouteSnapshot): Observable<QualitySystem[]> {
    let businessId = BaseServices.BusinessId;
    return this.backend.getInternalByUser(BaseServices.UserId);
  }
}
