<div class="flex gap-4">
    <div class="dialog-content">
        <i class="pi pi-question-circle p-confirm-dialog-icon question-icon"></i>
        <h4 *ngIf="!isChecklist" translate>COMPLETE_TASK?</h4>
        <h4 *ngIf="isChecklist" translate>{{task.Title}}</h4>
    </div>
    <div *ngIf="task.Description">
        <p class="dialog-description">{{task.Description}}</p>
    </div>
    <div>
        <h5 *ngIf="task.ActivityAttachment.length" translate>ATTACHMENTS</h5>
        <ul>
            <li *ngFor="let file of task.ActivityAttachment" >
                <div class="icon-preview">
                    <a href="javascript:void(0)" (click)="onFileClick(file)">
                        <img *ngIf="humanizeType(getFileExtension(file.Path)) === 'Image'" [src]="file.Path" alt="Image icon">
                        <img *ngIf="humanizeType(getFileExtension(file.Path)) === 'Pdf'" src="/assets/icons/pdf.png" alt="Pdf icon">
                        <img *ngIf="humanizeType(getFileExtension(file.Path)) === 'Document'" [src]="'/assets/icons/' + getFileExtension(file.Path) + '.png'"  alt="Office File"> 
                    </a>
                    <a
                        *ngIf="humanizeType(getFileExtension(file.Path)) == 'Video'"
                        [href]="file.Path"
                    >
                        <img
                        src="/assets/file-icons/kb-file-video.png"
                        alt="Folder"
                        />
                    </a>
                    <a 
                        *ngIf="humanizeType(getFileExtension(file.Path)) == 'Audio'" 
                        [href]="file.Path"
                    >
                        <img src="/assets/file-icons/kb-file-audio.png" alt="Audio" />
                    </a>
                    <a 
                        *ngIf="humanizeType(getFileExtension(file.Path)) == 'Text'" 
                        [href]="file.Path"
                    >
                        <img src="/assets/icons/txt.png" alt="Text" />
                    </a>
                </div>
                <span class="truncated-text">{{ file.Filename }}</span>
            </li>
        </ul> 
    </div>
</div>   
<div class="p-dialog-footer">
    <button type="button" class="btn btn-outline-primary" (click)="closeDialog()">
        <span class="p-button-icon p-button-icon-left pi pi-times"></span>
        <span  *ngIf="!isChecklist" translate>NO</span>
        <span  *ngIf="isChecklist" translate>BACK</span>
    </button>
    <button type="button" class="btn btn-primary"  (click)="onConfirm()" >
        <span class="p-button-icon p-button-icon-left pi pi-check"></span>
        <span *ngIf="!isChecklist" translate>YES</span>
        <span *ngIf="isChecklist" translate>SHOW_CHECKLIST</span>
    </button>
</div>