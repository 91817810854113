import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EcplConfirmViewerService } from './ecpl-confirm-viewer.service';
import { ActivityAttachment } from 'src/app/kuba/task-manager/models';

@Component({
  selector: 'ecpl-confirm-viewer',
  templateUrl: './ecpl-confirm-viewer.component.html',
})
export class EcplConfirmViewerComponent implements OnInit {
   
    @Output() closeEvent = new EventEmitter<void>();
    @Input() file: ActivityAttachment;

    constructor(private ecplConfirmViewerService: EcplConfirmViewerService) {}

    ngOnInit() {}

    getFileExtension(filename: string) {
        return this.ecplConfirmViewerService.getFileExtension(filename);
    }

    humanizeType(mimetype: string) {
        return this.ecplConfirmViewerService.humanizeType(mimetype);
    }


    closeDialog() {
        this.closeEvent.emit(); 
    }
}

