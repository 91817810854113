<div class="main-content">
  <div class="main-heading">
    <div class="row">
      <div class="col-md-6">
        <!-- <span>{{featureName}}</span> -->
      </div>
    </div>
    <div class="col-md-12">
      <div class="page-actions text-right">
        <button
          type="button"
          *ngIf="isShowAllFile"
          class="btn btn-outline-primary"
          (click)="showAllFiles()"
          [ngClass]="editMode ? 'text-danger' : 'text-primary'"
        >
          <span translate>SHOW_ALL_FILES</span>
        </button>
        <button
          type="button"
          *ngIf="roleId != 4"
          class="btn"
          [ngClass]="editMode ? 'text-danger' : 'text-success'"
          (click)="toggleEditMode()"
        >
          <span *ngIf="editMode" translate>GO_DISPLAY</span>
          <span *ngIf="!editMode" translate>GO_EDIT</span>
          <span
            class="icon ic-md"
            [ngClass]="editMode ? 'icon-view-content' : 'icon-edit-content'"
          ></span>
        </button>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-sm-3">
          <app-ecpl-document-tree
            *ngIf="treeNode"
            treeId="ArchiveDocumentsTree"
            [(ecplTreeData)]="treeNode"
            (ecplNodeEvent)="catchTreeEvent($event)"
            [(ecplTreeCheckbox)]="editMode"
          >
          </app-ecpl-document-tree>
        </div>
        <div class="col-sm-9">
          <div>
            <ecpl-document-explorer
              [(triggerUploader)]="triggerUploader"
              [uploaderConfig]="upConfig"
              [uploadedFiles]="uploadedFiles"
              (activeFolder)="setActiveNodeIntree($event)"
              (saveChanges)="catchExplorerEvent($event)"
              (openChecklistOrFormEmit)="openChecklistOrForm($event)"
            >
            </ecpl-document-explorer>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<p-dialog
  header="{{ previewHeader | translate }}"
  *ngIf="previewChecklist"
  [(visible)]="previewChecklist"
  [modal]="true"
  [style]="{ width: '1000px' }"
  [resizable]="false"
  [draggable]="false"
  [contentStyle]="{ overflow: 'scroll' }"
>
  <course-overview
    #course
    *ngIf="enableForm == 'course-overview'"
    [courseOverview]="formData"
    (distributionChange)="onBuidingSelect($event)"
  ></course-overview>
  <distribution-overview
    #distribution
    *ngIf="enableForm == 'distribution-overview'"
    [distributionOverview]="formData"
  >
  </distribution-overview>
  <document-assessment
    #documentAssessment
    *ngIf="enableForm == 'document-assessment'"
    [DocumentAssessment]="formData"
  >
  </document-assessment>
  <emergency-lighting
    #emergencyLighting
    *ngIf="enableForm == 'emergency-lighting'"
    [lighting]="formData"
  >
  </emergency-lighting>
  <fire-alarm
    #filreAlarm
    *ngIf="enableForm == 'fire-alarm'"
    [firealarmSystem]="formData"
  ></fire-alarm>
  <risk-assessment
    #riskAssessment
    *ngIf="enableForm == 'risk-assessment'"
    [riskAssessment]="formData"
  >
  </risk-assessment>
  <measure-protocol-preview
    *ngIf="enableForm == 'measure-protocol'"
    [mpCompleteId]="formData.Id"
  >
  </measure-protocol-preview>
  <div *ngIf="enableForm == 'checklist' && CompleteChecklistEdit.Section">
    <ng-template
      ngFor
      let-section
      let-headPoint="index"
      [ngForOf]="CompleteChecklistEdit.Section"
    >
      <table class="table">
        <thead>
          <tr>
            <th>{{ headPoint + 1 }} {{ section.Title }}</th>
            <th style="width: 70px">
              <span *ngIf="headPoint === 0" translate>OK</span>
            </th>
            <th style="width: 70px">
              <span *ngIf="headPoint === 0" translate>N/A</span>
            </th>
            <th style="width: 70px">
              <span *ngIf="headPoint === 0" translate>DEVIATION</span>
            </th>
            <th style="width: 250px">
              <span *ngIf="headPoint === 0" translate>COMMENTS</span>
            </th>
          </tr>
        </thead>
        <ng-template
          ngFor
          let-sectionitem
          let-i="index"
          [ngForOf]="section.Items"
        >
          <tbody>
            <tr>
              <td>
                <label
                  >{{ headPoint + 1 }}. {{ i + 1 }}
                  {{ sectionitem.Title }}</label
                >
              </td>
              <ng-template ngFor let-status [ngForOf]="sectionitem.Status">
                <td *ngIf="status.Label != 'Deviation'">
                  <input
                    id="statusOptions[{{ headPoint }}{{ i }}]"
                    disabled
                    name="options[{{ headPoint }}{{ i }}]"
                    type="checkbox"
                    [value]="status.value"
                    style="width: 28px; height: 28px; margin: 0px"
                    (change)="
                      setStatus(status, sectionitem.Status, sectionitem)
                    "
                    [checked]="status.value"
                  />
                </td>
              </ng-template>
              <td>
                <a
                  class="btn btn-danger"
                  disabled
                  (click)="addEditDeviation(0, headPoint, i)"
                >
                  <span class="icon ic-sm icon-add"></span
                ></a>
              </td>
              <td>
                <textarea
                  disabled
                  [(ngModel)]="sectionitem.Comments"
                  id="statusComments"
                  class="form-control single-line"
                ></textarea>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="text-right" style="border: 0">
                <strong>{{ sectionitem.Signature }}</strong>
              </td>
            </tr>
            <tr *ngIf="sectionitem.DeviationList.length">
              <td colspan="6" style="border: 0">
                <table class="table table-hover">
                  <tr>
                    <th style="width: 80px">
                      <span translate>DEVIATION_NUMBER</span>
                    </th>
                    <th style="width: 100px">
                      <span translate>LOCATION</span>
                    </th>
                    <th><span translate>DESCRIPTION</span></th>
                    <th style="width: 80px"><span translate>STATUS</span></th>
                    <th style="width: 100px">
                      <span translate>DEADLINE</span>
                    </th>
                    <th style="width: 160px">
                      <span translate>RESPONSIBLE_EXECUTION</span>
                    </th>
                  </tr>
                  <tr *ngFor="let dev of sectionitem.DeviationList">
                    <td>{{ dev.Id }}</td>
                    <td>
                      <span translate>{{ dev.Location }}</span>
                    </td>
                    <td>
                      <span translate>{{ dev.Description }}</span>
                    </td>
                    <td>
                      <span translate>{{ dev.StatusText }}</span>
                    </td>
                    <td>
                      <span translate>{{
                        dev.Deadline | date : "dd.MM.yyyy"
                      }}</span>
                    </td>
                    <td>
                      <span translate>{{ dev.ResponsiblePersonName }}</span>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </tbody>
        </ng-template>
      </table>
    </ng-template>
  </div>
</p-dialog>
