export class AccessLog {
    Id ?: number;
    UserId ?: number;
    BusinessId ?: number;
    IP ?: string;
    Browser ?: string;
    OS ?: string;
    ApplicationId ?: number;
    CreatedOn ?: Date;
    LogIn ?: string;
    LogOff ?: Date;
    Type ?: string;
    Business ?: Business;
    User ?: User;
}
export class User {
    Id ?: number;
    Language ?: string;
    Role ?: string;
    Application ?: string;
    Business ?: string;
    UserType ?: string;
    Username ?: string;
    Email ?: string;
    Name ?: string;
    SignatureImg ?: string;
    Avatar ?: string;
    Sso ?: string;
    SsoUsername ?: string;
    SsoDomain ?: string;
    Status ?: string;
    CreatedBy ?: number;
    CreatedOn ?: Date;
    ModifiedBy ?: number;
    ModifiedOn ?: Date;
}
export class Business {
    Id ?: number;
    OrganizationNumber ?: string;
    CompanyName ?: string;
    CompanyNumber ?: string;
    AboutBusiness ?: string;
    Address ?: string;
    Zipcode ?: string;
    Telephone ?: string;
    Email ?: string;
    BusinessCategoryId ?: number;
    ApplicationId ?: number;
    Status ?: string;
    BusinessPortalId ?: number;
    CreatedBy ?: number;
    ModifiedBy ?: number
}