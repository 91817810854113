import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ToasterComponent } from '../../../_directives/toaster.component';
import { HelperService } from '../../../_services/helper.service';
import { PdfSetting } from './../../../_services/helper.service';
import { BaseServices } from './../../kuba.services';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { ContactServices } from '../services/contact.service';
import { Module } from './../../follow-ups/models/deviation';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
@Component({
  selector: 'our-contact',
  templateUrl: 'ourContact.component.html'
})
export class OurContactComponent implements OnInit {
  //#region variables
  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  @ViewChild('contactsTable', { static: false }) contactsTable: Table;
  pdf: any;
  typeOfContacts: SelectItem[];
  selectedContacts: any = [];
  contacts: any;
  contactsMedium: any;
  contactView: any;
  isDisable = true;
  viewExport = false;
  viewFilter = false;
  isClientGuest = false;
  isSelectedValue = false;
  additionalData: any;
  functions: SelectItem[];
  private subscriptions: Subscription[] = [];

  //#endregion

  //#region constructor
  /**
   * constructor
   * @param activatedRoute {activatedRoute}
   * @param contactsrvc {contactsrvc}
   * @param confirmationService {confirmationService}
   */
  constructor(
    private activatedRoute: ActivatedRoute,
    public contactsrvc: ContactServices,
    private confirmationService: ConfirmationService,
    private router: Router,
    private translate: TranslateService
  ) {
    let currentUserRole = BaseServices.UserRole;
    let userFeatureId = BaseServices.FeatureId;
    if (currentUserRole === 'Guest' && userFeatureId === Module.CLIENT) {
      this.isClientGuest = true;
    }
    this.subscriptions.push(
      this.translate.stream('FILENAME').subscribe(val => {
        this.additionalData = {
          fileName: val.OUR_CONTACTS,
          header: 'Our-Contact',
          businessId: BaseServices.BusinessId,
          cultureInfo: BaseServices.userCultureInfo(),
          columnNames: [
            { title: 'Name', dataKey: 'Name' },
            { title: 'Cellular Phone', dataKey: 'Telephone' },
            { title: 'Email Id ', dataKey: 'Emailid' },
            { title: 'Function ', dataKey: 'ContactFunctionalArea.' },
            { title: 'Status ', dataKey: 'Status' },
            { title: 'Company Name ', dataKey: 'ContactWorkLocation.Company' }
          ]
        };
      })
    );
  }
  //#endregion

  //#region page-event
  /**
   * page-event
   */
  ngOnInit(): void {
    document.querySelector('body').classList.remove('opened');
    this.contactView = this.activatedRoute.snapshot.data['list'];
    this.showBusiness(this.contactView);
    this.mobileNumberWithCode(this.contactView);
    this.typeOfContacts = [];
    let typeOfContacts = this.activatedRoute.snapshot.data['contactType'];
    this.typeOfContacts.push({ label: 'All', value: '' });
    if (typeOfContacts) {
      typeOfContacts.forEach((element: any) => {
        this.typeOfContacts.push({
          label: element.Name,
          value: element.Id
        });
      });
    }
    this.contactsMedium = [];
    this.contactsMedium.push({ label: 'All', value: 'ALL' });
    this.contactsMedium.push({ label: 'Both', value: 'BOTH' });
    this.contactsMedium.push({ label: 'E-Mail', value: 'EMAIL' });
    this.contactsMedium.push({ label: 'SMS', value: 'SMS' });
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
        this.functions = [];
        this.functions.push({ label: val.SELECT, value: null });
      })
    );
    let functions = this.activatedRoute.snapshot.data['function'];
    if (functions) {
      functions.forEach((element: any) => {
        this.functions.push({ label: element.Name, value: element.Id });
      });
    }
  }
  //#endregion

  //#region control-event
  /**
   * type dropdown change events
   */
  onTypeOfContactChanged(typeOfContactsDropdown: any) {
    if (typeOfContactsDropdown.selectedOption.value) {
      this.contactsTable.filter(
        typeOfContactsDropdown.selectedOption.value,
        'ContactTypeId',
        'equals'
      );
    } else {
      this.contactsTable.reset();
    }
  }
  onFunctionChanged(e: any, functionDropdown: any) {
    this.contactsTable.filter(
      functionDropdown.selectedOption.value,
      'ContactFunctionalAreaId',
      'equals'
    );
    if (e.value == null) {
      this.contactsTable.reset();
    }
  }
  onRowSelect(selectedValue: any) {
    if (this.selectedContacts) {
      this.isSelectedValue = true;
    } else {
      this.isSelectedValue = false;
    }
  }
  onRowUnselect(selectedValue: any) {
    if (this.selectedContacts.length > 0) {
      this.isSelectedValue = true;
    } else {
      this.isSelectedValue = false;
    }
  }
  /**
   * company dropdown change event
   * @param control {control}
   */
  onCompanyChanged(control: any) {
    if (control.value !== '') {
      this.contactsTable.filter(
        control.value,
        'ContactWorkLocation.Company',
        'contains'
      );
    } else {
      this.contactsTable.reset();
    }
  }
  //#endregion

  //#region methods
  /**
   * get all contacts
   */
  getContacts() {
    this.subscriptions.push(
      this.contactsrvc.getAdminContacts().subscribe(result => {
        if (result) {
          this.showBusiness(result);
          this.mobileNumberWithCode(result);
        }
      })
    );
  }
  /**
   * multiple contact delete
   */
  multiContactsdeletion() {
    let contactIds: any = ([] = []);
    if (this.selectedContacts.length > 0) {
      this.selectedContacts.forEach((element: any) => {
        contactIds.push({ Id: element.Id });
      });
      this.confirmationService.confirm({
        message: this.translate.instant('DELETE_THIS_RECORD'),
        accept: () => {
          this.subscriptions.push(
            this.contactsrvc.deleteAll(contactIds).subscribe(() => {
              this.getContacts();
              this.toasterComponent.callToastDlt();
            })
          );
        }
      });
    }
    this.selectedContacts = [];
  }
  /**
   * pdf export
   */
  exportPdf() {
    let pdfSetting = new PdfSetting();
    pdfSetting.date = 'Date:' + HelperService.formatDate(new Date());
    pdfSetting.businessName = BaseServices.BusinessName;
    pdfSetting.pageHeader = 'Our Contact';
    let columns: any = [
      { title: 'Name', dataKey: 'Name' },
      { title: 'Cellular Phone', dataKey: 'Telephone' },
      { title: 'Email Id ', dataKey: 'Emailid' },
      { title: 'Function ', dataKey: 'ContactFunctionalArea.' },
      { title: 'Status ', dataKey: 'Status' },
      { title: 'Company Name ', dataKey: 'ContactWorkLocation.Company' }
    ];
    HelperService.generatePdf(
      this.contactView,
      columns,
      'Our Contact List',
      pdfSetting,
      'l'
    );
  }
  onContactMediumChanged(contactsMediumDropdown: any) {
    let key = contactsMediumDropdown.selectedOption.value.toUpperCase();

    switch (key) {
      case 'SMS':
        this.contactsTable.filter(true, 'IsSMS', 'equals');
        break;
      case 'EMAIL':
        this.contactsTable.filter(true, 'IsEmail', 'equals');
        break;
      case 'BOTH':
        this.contactsTable.filter(true, 'IsEmail', 'equals');
        this.contactsTable.filter(true, 'IsSMS', 'equals');
        break;
      case 'ALL':
        this.contactsTable.reset();
        break;
    }
  }
  mobileNumberWithCode(data: any) {
    this.contactView = [];
    if (data) {
      data.forEach((element: any) => {
        element.Mobile = `${
          element.CountryCode ? element.CountryCode : ''
        }${element.Mobile ? element.Mobile : ''}`;
        this.contactView.push(element);
      });
    }
  }

  showBusiness(data: any) {
    if (data) {
      data.forEach((element: any) => {
        if (element.ShowInBusiness === true) {
          let Name = element.Name + ' ' + '(' + BaseServices.UserRole + ')';
          element.Name = Name;
        }
      });
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
  //#endregion
}
