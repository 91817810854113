<link
  rel="stylesheet"
  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"
/>
<div class="ecpl-tree-view">
  <div class="search-block">
    <!-- <input id="filter" class="form-control" #filter (keyup)="ecplTree.treeModel.filterNodes(filter.value)" placeholder="Filter"
        /> -->
    <input
      #filter
      class="form-control"
      (keyup)="filterNodes(filter.value, ecplTree)"
      placeholder="{{ 'FILTER_NODES' | translate }}"
    />
  </div>
  <tree-root
    id="{{ treeId }}"
    #ecplTree
    class="reverse"
    (event)="sendTreeEvents($event)"
    [nodes]="nodes"
    [options]="options"
  >
    <ng-template #treeNodeWrapperTemplate let-node let-index="index">
      <div
        *ngIf="
          node.data.isPermission &&
          (node.data.documentType != 'laws' || node.data.isFolder != false) &&
          (node.data.filename != 'Regulation' || node.data.isFolder != false) &&
          (node.data.documentType != 'videos' || node.data.isFolder != false)
        "
      >
        <div
          class="node-wrapper"
          [style.padding-left]="node.getNodePadding()"
          [contextMenu]="basicMenu"
          [contextMenuSubject]="node"
        >
          <!-- Show "eye" icons to hide/show files in manuals, documents and archive. -->
          <tree-node-expander [node]="node"></tree-node-expander>
          <div *ngIf="ecplTreeCheckbox && !addFromManuals">
            <span class="tooltip-container">
              <i
                class="fa"
                [ngClass]="getIconClass(node.data)"
                [attr.name]="node.data.id"
                [attr.id]="node.data.id"
                (click)="node.data.isPermission && setNodeStatus(node, $event)"
                [ngStyle]="{
                  cursor: node.data.isPermission ? 'pointer' : 'not-allowed'
                }"
              ></i>
              <span class="tooltip-text">
                {{ getTooltipText(node.data) }}
              </span>
            </span>
          </div>
          <!-- Replace "eye" icons with select-check-boxes in "add from manuals". -->
          <div *ngIf="ecplTreeCheckbox && addFromManuals">
            <span class="tooltip-container">
              <input
                type="checkbox"
                [attr.name]="node.data.id"
                [attr.id]="node.data.id"
                [checked]="node.data.selected"
                (change)="node.data.isPermission && setNodeStatus(node, $event)"
                [ngStyle]="{
                  cursor: node.data.isPermission ? 'pointer' : 'not-allowed'
                }"
              />
            </span>
          </div>

          <span
            class="ecpl-node-icon {{ node.data.mimetype }}"
            [ngClass]="{ folder: node.data.isFolder }"
          >
            <img
              *ngIf="node.data.isFolder"
              [src]="getFileIcon(node)"
              alt="Folder"
            />
            <img
              *ngIf="!node.data.isFolder"
              class="file-type-icon"
              src="/assets/icons/{{ getFileExtensionFromMimeType(node.data.mimetype) || node.data.mimetype }}.png"
              alt="File"
            />
          </span>
          <div class="node-content-wrapper">
            <div
              [class.col-sm-9]="node.data.additionalFeatureId == 186"
              [class.node-disabled]="node.data.status === 2"
              [class.node-content-wrapper-active]="node.isActive"
              [class.node-content-wrapper-focused]="node.isFocused"
              (click)="node.mouseAction('click', $event)"
              (dblclick)="node.mouseAction('dblClick', $event)"
              (contextmenu)="node.mouseAction('contextMenu', $event)"
              (treeDrop)="node.onDrop($event)"
              [treeAllowDrop]="node.allowDrop"
              [treeDrag]="node"
              [treeDragEnabled]="node.allowDrag()"
            >
              <tree-node-content
                [node]="node | defaultFolderTranslate"
                [index]="index"
              >
              </tree-node-content>
              <span
                class="badge"
                *ngIf="
                  node.data.type != 'file' &&
                  node.data.documentType != 'laws' &&
                  node.data.documentType != 'regulation' &&
                  node.data.filename != 'Regulation'
                "
              >
                <span
                  *ngIf="
                    node.data.documentType != 'laws' &&
                    node.data.documentType != 'regulation' &&
                    node.data.filename != 'Regulation'
                  "
                  >{{ node.data.nodes | activeFolderCount }}
                </span>
                <span
                  *ngIf="
                    node.data.type != 'file' &&
                    node.data.documentType != 'laws' &&
                    node.data.documentType != 'regulation' &&
                    node.data.filename != 'Regulation'
                  "
                >
                  /
                </span>
                <span
                  *ngIf="
                    node.data.type != 'file' &&
                    node.data.documentType != 'laws' &&
                    node.data.documentType != 'regulation' &&
                    node.data.filename != 'Regulation'
                  "
                >
                  {{ node.data.nodes | activeFileCount }}
                </span>
              </span>
              <!-- <span *ngIf="node.data.childCount" class="badge">{{ node.data.nodes.filter(x=>x.type =='file')|json}}</span> -->
              <!-- <span *ngIf="node.data.childCount" class="badge">{{ node.data.childCount}}</span> -->
            </div>
            <div
              class="col-sm-3 p-0"
              *ngIf="node.data.additionalFeatureId == 186"
            >
              <span *ngIf="!node.data.isFolder" style="float: right">
                <button
                  *ngIf="!isClientUser"
                  class="btn btn-outline-primary btn-icon"
                  (click)="editClick(node.data)"
                >
                  <i class="icon ic-sm icon-pencil-tip"></i>
                </button>
                <button
                  class="btn btn-outline-primary btn-icon"
                  (click)="printClick(node.data)"
                >
                  <i class="icon ic-sm icon-print"></i>
                </button>
                <button
                  *ngIf="!isClientUser"
                  class="btn btn-outline-primary btn-icon"
                  (click)="deleteClick(node.data)"
                >
                  <i class="icon ic-sm icon-trash"></i>
                </button>
              </span>
              <span *ngIf="node.data.isFolder" style="float: right">
                <button class="btn btn-primary" (click)="printClick(node.data)">
                  <span translate>INSPECTION_REPORT</span>
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </tree-root>

  <!-- Editors and Viewers -->
  <div
    #hiddenAction
    style="width: 1px; height: 1px; overflow: hidden"
    *ngIf="file != null"
  >
    <ecpl-image-viewer
      *ngIf="file.path != null && humanizeType(file.mimetype) == 'Image'"
      [imgSrc]="file.path"
      [imgType]="file.mimetype"
      [imgName]="file.filename"
      [id]="file.nodeId"
      thumbNail="true"
    >
    </ecpl-image-viewer>

    <ecpl-article-viewer
      *ngIf="file.type == 'file' && file.documentType == 'article'"
      [articleName]="file.filename"
      [(articleSrc)]="file.content"
      [id]="file.nodeId"
      [adminUsers]="adminUsers"
      [businessMode]="businessMode"
      [editMode]="ecplTreeCheckbox"
      (saveChanges)="saveFolderFile($event)"
      [(ecplApiResponse)]="ecplApiResponse"
    >
    </ecpl-article-viewer>

    <ecpl-onlyoffice-viewer
      *ngIf="
        file.path != null &&
        humanizeType(getFileExtension(file.path)) == 'Document'
      "
      [onlyofficeSrc]="file.path.replace('azureedge', 'blob.core.windows')"
      [onlyofficeKey]="file.nodeId + 'key'"
      [onlyofficeType]="getFileExtension(file.path)"
      [onlyofficeName]="file.filename"
      [id]="file.nodeId"
      [onlyofficeMode]="
        ecplTreeCheckbox === true
          ? file.isEditable === false
            ? false
            : true
          : false
      "
      (saveChanges)="saveFolderFile($event)"
    ></ecpl-onlyoffice-viewer>

    <ecpl-pdf-viewer
      *ngIf="
        file.path != null && humanizeType(getFileExtension(file.path)) == 'Pdf'
      "
      [id]="file.nodeId"
      [pdfSrc]="file.path"
      [pdfName]="file.filename"
    ></ecpl-pdf-viewer>

    <ecpl-checklist-viewer
      *ngIf="
        file.type == 'file' &&
        file.documentType == 'checklist' &&
        file.featureId != 36
      "
      [checklistName]="file.filename"
      [(checklistSrc)]="file"
      [id]="file.nodeId"
      [editMode]="ecplTreeCheckbox"
      (saveChanges)="saveFolderFile($event)"
    ></ecpl-checklist-viewer>

    <ecpl-lar-viewer (saveChanges)="saveFolderFile($event)"></ecpl-lar-viewer>
  </div>
</div>

<context-menu [disabled]="!ecplTreeCheckbox">
  <ng-template
    *ngFor="let menu of contextMenuActions"
    contextMenuItem
    let-item
    [visible]="menu.visible"
    [enabled]="menu.enabled"
    (execute)="saveUpdateEvents($event, menu.action(menu))"
  >
    {{ menu.html(item) }}
  </ng-template>
</context-menu>

<!-- New Folder Modal -->
<ng-template #newFolderModal>
  <div class="modal-header">
    <h4>{{ bsModalTitle }}</h4>
    <button
      type="button"
      class="btn btn-icon close"
      aria-label="Close"
      (click)="bsModalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="form-inline">
      <div class="form-group">
        <input
          *ngIf="newFolderNode"
          type="text"
          [(ngModel)]="newFolderNode.filename"
          name="folderName"
          id="folderName"
          class="form-control"
          style="width: 300px"
        />
      </div>

      <button
        type="button"
        class="btn btn-primary"
        [disabled]="!newFolderNode.filename.trim()"
        (click)="saveFolderFile(currentEventData)"
      >
        <span translate>SAVE</span>
      </button>
    </form>
  </div>
</ng-template>
