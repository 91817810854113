import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import {
  EmployeeHoliday,
  EmployeeLeave,
  EmployeeLeaveAvailability,
} from '../models';
import { BaseServices } from './../../kuba.services';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from 'src/app/_services';

@Injectable()
export class EmployeeLeaveService {
  ses: string;

  constructor(
      private http: HttpClient,
      private authenticationService: AuthenticationService
    ) {}
  getAllByBusinessId(businessId: number, getYear: any) {
    // remesh
    return this.http
      .get(
        environment.BASE_URL + '/employeeleaves/' + businessId + '/' + getYear,

      )
      .map((result: any) => result);
  }
  getById(id: number) {
    return this.http
      .get(
        environment.BASE_URL + '/employeeleavesbyid/' + id,

      )
      .map((result: any) => result);
  }
  getEmployeeLeavesByEmployeeId(id: number) {
    return this.http
      .get(
        environment.BASE_URL + '/employeeleavesbyemployeeid/' + id,

      )
      .map((result) => result);
  }
  getEmployeeAppliedLeavesByEmployeeId(id: number) {
    return this.http
      .get(
        environment.BASE_URL + '/employeeappliedleaves/' + id,

      )
      .map((result) => result);
  }
  getEmployeeLeaveByEmployeeId(id: number) {
    return this.http
      .get(
        environment.BASE_URL + '/employeeleavebyemployeeid/' + id,

      )
      .map((result) => result);
  }
  getAvailableLeaves(empId: number, bid: number, year: number) {
    return this.http
      .get(
        environment.BASE_URL +
          '/employeeavailableleaves?EmployeeId=' +
          empId +
          '&BusinessId=' +
          bid +
          '&Year=' +
          year,

      )
      .map((result) => result);
  }

  getEmployeeLeaveByLeaveId(id: number) {
    return this.http
      .get(
        environment.BASE_URL + '/employeeleavebyleaveid/' + id,

      )
      .map((result: any) => result);
  }
  
  exportEmployeeLeaveToExcel(
    langId: number,
    id: number,
    startDate: string,
    endDate: string
  ) {
    let myData = JSON.stringify({ langId, id, startDate, endDate });
    let crfToken = this.authenticationService.getCsrfToken();
    let url = `${environment.BASE_URL}/employeeleave/exportToExcel/langId/${langId}/businessId/${id}/startDate/${startDate}/endDate/${endDate}`;
  
    return new Observable((observer: any) => {
      let xhr = new XMLHttpRequest();
      xhr.open('POST', url, true);
      xhr.withCredentials = true;
      xhr.responseType = 'blob';
  
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('X-XSRF-TOKEN', crfToken);
  
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            let blob = new Blob([xhr.response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            observer.next(blob);
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };
  
      xhr.send(myData);
    });
  }
  
  
  // bind emp dropdown
  getEmpByName(id: number) {
    return this.http
      .get(environment.BASE_URL + '/employees/' + id, )
      .map((employees) => employees);
  }

  add(employee: EmployeeLeave) {
    return this.http
      .post(
        environment.BASE_URL + '/employeeleave',
        JSON.stringify(employee),

      )
      .map((result) => result);
  }

  update(employee: EmployeeLeave) {
    return this.http
      .put(
        environment.BASE_URL + '/employeeleave/' + employee.Id,
        employee,

      )
      .map((result) => result);
  }

  deleteLeave(id: number) {
    return this.http
      .delete(
        environment.BASE_URL + '/employeeleave/' + id,

      )
      .map((leave) => leave);
  }

  deleteHoliday(id: number) {
    return this.http
      .delete(
        environment.BASE_URL + '/employeeHoliday/' + id,

      )
      .map((response: any) => {
        return response;
      });
  }
  // get approval person list
  getAllApprovedPerson() {
    return this.http
      .get(
        environment.BASE_URL +
          '/approval-persons-business/' +
          BaseServices.BusinessId,

      )
      .map((result: any) => result);
  }
  // bing grid holidaty type
  getAllLeaveAllocation(bid: number, year: number) {
    return this.http
      .get(
        environment.BASE_URL + '/leaveavailability/' + bid + '/' + year,

      )
      .map((result) => result);
  }
  getAllHolidays(bid: number) {
    return this.http
      .get(
        environment.BASE_URL + '/employeeholidays/' + bid,

      )
      .map((result: any) => result);
  }

  saveLeaveAllocation(leave: EmployeeLeaveAvailability[]) {
    return this.http
      .post(
        environment.BASE_URL + '/employeeallocatedleave',
        leave,

      )
      .map((result) => result);
  }
  // update employee type
  updateHoliday(existingCategory: EmployeeHoliday) {
    return this.http
      .put(
        environment.BASE_URL + '/employeeholiday/' + existingCategory.Id,
        existingCategory,

      )
      .map((result) => result);
  }
  // adding employee Type
  createHoliday(newCategory: EmployeeHoliday) {
    return this.http
      .post(
        environment.BASE_URL + '/employeeholiday',
        JSON.stringify(newCategory),

      )
      .map((result) => result);
  }
  saveDayPilotLeaves(saveDayPilotLeaves: any, editedBy: number) {
    return this.http
      .post(
        environment.BASE_URL +
          '/employeeleave/savedaypilotleaves/' +
          editedBy +
          '/' +
          BaseServices.userLanguageId,
        JSON.stringify(saveDayPilotLeaves),

      )
      .map((result) => result);
  }
}
