import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    FormControl,
    Validators,
    FormArray,
    AbstractControl,
    ValidatorFn
} from '@angular/forms'
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { ToasterComponent } from './../../../_directives/toaster.component';

import { Module } from './../../follow-ups/models/deviation';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { HelperService } from './../../../_services/helper.service';
import { ProductionType } from './../models/productionType';
import { FSProduction } from './../models/production';
import { ProductionService } from './../services/production.services';
import { ReceptionControlListDto } from './../../reception-control/models/reception-control';
import { ReceptionControlService } from './../../reception-control/services/reception-control.service';
import { ValidationService } from './../../shared/services/validation.service';
import * as _ from 'lodash';
import { ToasterConfig } from 'angular2-toaster';
import { TranslateService } from '@ngx-translate/core';
import { DATE } from 'ngx-bootstrap/chronos/units/constants';
import { Subscription } from 'rxjs';

@Component({
    selector: 'production-form',
    templateUrl: 'production-form.component.html'
})

export class ProductionFormComponent implements OnInit, OnDestroy {
    //#region variable
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    foodSafetyProductionForm: FormGroup;
    foodSafetyGoodsForm: FormGroup;
    productionForm: FormGroup;
    productionId: number;
    parentId: number;
    parentKey: any;
    productionType: any;
    selectedProdType = new ProductionType();
    typesOfProduction: SelectItem[];
    prodTypesList = [];
    clients: any;
    viewType = false;
    addGoods = false;
    recptDetails: any;
    productionDetails: any;
    isEditMode = false;
    addType = false;
    recptionData: ReceptionControlListDto;
    existsAlerttext: string;
    isNameExists: boolean;
    public config1: ToasterConfig = new ToasterConfig({
        positionClass: 'toast-top-right',
        showCloseButton: false,
        tapToDismiss: false,
        timeout: 2000
    });
    private subscriptions: Subscription[] = [];
    //#endregion

    //#region constructor
    /**
     *
     * @param route {ActivatedRoute}
     * @param confirmationService {ConfirmationService}
     * @param _fb {FormBuilder}
     * @param location {Location}
     * @param productionService {ProductionService}
     * @param receptionControlService {ReceptionControlService}
     */
    constructor(
        private route: ActivatedRoute,
        private confirmationService: ConfirmationService,
        private _fb: FormBuilder,
        protected location: Location,
        private productionService: ProductionService,
        private receptionControlService: ReceptionControlService,
        private translate: TranslateService) {
        this.initForm();
        this.parentId = route.snapshot.parent.parent.params['id'];
        this.parentKey = route.snapshot.parent.parent.data['parent'];
        if (+route.snapshot.params['prodId'] > 0) {
            this.productionId = route.snapshot.params['prodId'];
            this.isEditMode = true;
        }
        this.typesOfProduction = [];
        this.prodTypesList = [];
        let prodTypeDetails = this.route.snapshot.data['typeDetails'];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.typesOfProduction = [];
            this.typesOfProduction.push(
                { label: val.SELECT, value: null }
            );
        if (prodTypeDetails) {
            prodTypeDetails.forEach(element => {
                this.typesOfProduction.push({ label: element.Name, value: element.Id });
                this.prodTypesList.push({ Name: element.Name, Id: element.Id });
            });
        }
        }));
        
       this.clients = [];
        let clientDetails = this.route.snapshot.data['clients'];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.clients = [];
            this.clients.push(
                { label: val.SELECT, value: null }
            );
            if (clientDetails) {
            clientDetails.forEach(element => {
                this.clients.push({ label: element.Name, value: element.Id });
            });
        }
        }));
        this.recptDetails = [];
        let recptionDetails;
        let receptionControl = this.route.snapshot.data['receptions'];
        if (receptionControl.length > 0) {
            if(!this.isEditMode)
                recptionDetails = receptionControl.filter(x => x.Status === '8' && x.QtyLeft > 0 );
            else 
                recptionDetails = receptionControl;
        }
        this.productionDetails = this.route.snapshot.data['details'];
        if(this.productionDetails)
            this.initForm(this.productionDetails);
            this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.recptDetails = [];
            this.recptDetails.push(
                { label: val.SELECT, value: null }
            );
            if (recptionDetails) {
                recptionDetails.forEach(element => {
                    this.recptDetails.push({ label: element.ReceptionNo + '( ' + element.KindOfGoods + ' )', value: element.Id });
                });
            }
        }));
        
        this.productionForm = this._fb.group({
            'Name': ['', [ValidationService.noWhitespaceValidator]]
        })
    }
    //#endregion

    //#region page-event
    /**
     * initialization
     */
    ngOnInit() {
        this.initForm(this.productionDetails);
        // To hide side menu bar
        document.querySelector("body").classList.remove("opened");
    }

    /**
     * page destroy event
     */
    ngOnDestroy() {
        document.querySelector("body").classList.add("opened");
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }

    /**
     * Initialize the dynamic form
     * @param prodControlData{any}
     */
    initForm(prodControlData?: any) {
        let productionType = 0;
        let client = '';
        let deliveryDate = Date.now();
        let productDeliveryId = 0;
        let receptionData;
        let recpArray = [];
        let quantity = 1;
        
        if (prodControlData) {
            productionType = prodControlData.ProductionTypeId ? prodControlData.ProductionTypeId : '';
            client = prodControlData && prodControlData ? prodControlData.ClientId : '';
            deliveryDate = prodControlData && prodControlData ? prodControlData.DeliveryOn : Date.now();
            productDeliveryId = prodControlData.ProductDeliveryId ? prodControlData.ProductDeliveryId : 0;
            quantity = prodControlData.Quantity ? prodControlData.Quantity : 1;
            prodControlData.ProductionArray.forEach(ele =>{
                receptionData = {
                    ReceptionNo: ele.ReceptionNo,
                    RemainingQuantity: ele.Quantities.RemainingQuantity == 0 ? '0' : ele.Quantities.RemainingQuantity,
                    Measurement: ele.Measurement,
                    Quantity: ele.Quantities.SelectedQuantity
                }
                
            recpArray.push(receptionData);
            });
        }
        let receptionArray: FormArray = new FormArray([], CustomValidators.uniqueBy('ReceptionNo', false));
        this.foodSafetyProductionForm = this._fb.group({
            ProductionType: new FormControl(productionType, Validators.required),
            Client: new FormControl(client, Validators.required),
            DeliveryDate: new FormControl(HelperService.formatDate(deliveryDate), Validators.required),
            ProductDeliveryId: new FormControl(productDeliveryId),
            receptionArray: receptionArray,
            Quantity : new FormControl(quantity, Validators.required)
        });
        if(recpArray.length > 0) {
            recpArray.forEach(ele => {
                this.addReception(ele);
            });
        } else {
            this.addReception();
        }
    }
    //#endregion

    //#region methods
    /**
     * add receptiondata
     * @param receptionData {any}
     */
    addReception(receptionData?: any) {
        let receptionNo = '';
        let remainingQuantity = '';
        let measurement = '';
        let selectedQuantity = '';
        if (receptionData) {
            receptionNo = receptionData.ReceptionNo ? receptionData.ReceptionNo : '';
            remainingQuantity = receptionData.RemainingQuantity ? receptionData.RemainingQuantity : '';
            measurement = receptionData.Measurement ? receptionData.Measurement : '';
            selectedQuantity = receptionData.Quantity ? receptionData.Quantity : '';
        }

        (<FormArray>this.foodSafetyProductionForm.controls['receptionArray']).push(
            new FormGroup({
                ReceptionNo: new FormControl(receptionNo, Validators.required),
                Measurement: new FormControl(measurement),
                Quantities: this._fb.group({
                    RemainingQuantity: new FormControl(remainingQuantity),
                    SelectedQuantity: new FormControl(selectedQuantity, Validators.required)
                }, { validator: ValidationService.rangeValidator })
            })
        )
    }

    /**
   * delete reception form array
   * @param i
   */
    removeProperties(i: number) {
        const control = <FormArray>this.foodSafetyProductionForm.controls['receptionArray'];
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                // remove uncommited property
                control.removeAt(i);
            }
        });
    }

    /**
     * Description: Bind the ProductionType
     */
    bindProdTypeDetails() {
        this.typesOfProduction = [];
        this.prodTypesList = [];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.typesOfProduction = [];
            this.typesOfProduction.push(
                { label: val.SELECT, value: null }
            );
        }));
        this.subscriptions.push(this.productionService.getAllProdType(this.parentKey, this.parentId).subscribe(result => {
            result.forEach(element => {
                this.typesOfProduction.push({ label: element.Name, value: element.Id });
                this.prodTypesList.push({ Name: element.Name, Id: element.Id });
            });
        }));
    }

    /**
    * Description: Edit the ProductionType
    */
    editProdType(productionType) {
        this.selectedProdType = productionType;
    }

    /**
     *  Description: Delete the ProductionType
     * @param productionType
     */
    deleteProdType(productionType) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                let deleteTypeid = productionType.Id;
                this.subscriptions.push(this.productionService.deleteProdType(deleteTypeid).subscribe(result => {
                    if (result) { this.bindProdTypeDetails(); }
                }))
            }
        });
    }

    /*
    Description: save  ProductionType
    Date: Nov 16,2017
    */
    saveProdType() {
        let productionType = new ProductionType();
        productionType.Id = (this.selectedProdType.Id) ? this.selectedProdType.Id : 0;
        productionType.Name = this.productionForm.value.Name;
        productionType.FeatureKeyId = this.parentId;
        productionType.FeatureId = Module.FOODSAFETY;
        productionType.Status = '1';
        if (productionType.Id > 0) {
            this.subscriptions.push(this.productionService.updateProdType(productionType, this.parentKey).subscribe(isProdTypeUpdated => {
                this.bindProductionTypeDropdown(isProdTypeUpdated);
            }));
        } else {
            productionType.FeatureKeyId = this.parentId;
            this.subscriptions.push(this.productionService.createProdType(productionType, this.parentKey)
                .subscribe(isProdTypeAdded => {
                    this.bindProductionTypeDropdown(isProdTypeAdded);
                }));
        }
    }

    /**
    * production type after event trigger
    * @param production {any}
    */
    bindProductionTypeDropdown(production: any) {
        if (production) {
            this.bindProdTypeDetails();
            this.toasterComponent.callToast();
            this.addType = false;
            this.foodSafetyProductionForm.get('ProductionType').patchValue(production.Id);
        } else {
            this.existsAlerttext = this.translate.instant(`TOPIC_EXIST`);
            this.isNameExists = true;
            setTimeout(
                function () {
                    this.isNameExists = false;
                }.bind(this),
                3000
            );
        }
    }
    /**
     * back to the list
     */
    gotoList() {
        this.location.back();
    }
    /**
     * Add/Update the Production
     */
    submitForm(isSaveAndCreateNew: boolean) {
        let production: FSProduction = new FSProduction();
        production.ClientId = this.foodSafetyProductionForm.value.Client;
        production.DeliveryOn = this.foodSafetyProductionForm.value.DeliveryDate;
        production.ProductionTypeId = this.foodSafetyProductionForm.value.ProductionType;
        production.Quantity = this.foodSafetyProductionForm.value.Quantity;
        production.Status = this.foodSafetyProductionForm.value.Status;
    
        if (this.productionId > 0) {
            production.Id = this.productionId;
            production.ProductDeliveryId = this.foodSafetyProductionForm.value.ProductDeliveryId;
            production.ModifiedBy = BaseServices.UserId;
            this.subscriptions.push(this.productionService.updateProduction(production)
                .subscribe(result => {
                    this.toasterComponent.callToast();
                    if (!isSaveAndCreateNew) {
                        this.gotoList();
                    }
                }));
        } else {
            production.Status = '1';
            production.ProductionArray = this.foodSafetyProductionForm.value.receptionArray;
            production.BusinessId = BaseServices.BusinessId;
            production.Id = 0;
            production.CreatedBy = BaseServices.UserId;
            this.subscriptions.push(this.productionService.addProduction(production)
                .subscribe(result => {
                    if (result) {
                        this.toasterComponent.callToast();
                        if (!isSaveAndCreateNew) {
                            this.gotoList();
                        }
                    }
                }));
        }
    
        this.foodSafetyProductionForm.reset();
        if(!isSaveAndCreateNew){
            this.initForm();
        }
    }
    onNumberChange(evt: any){
        if(this.isEditMode == true)
        evt.preventDefault();
    }
    /**
     * set Quantity and Measurement
     * @param event {e}
     * @param index {number}
     */
    setQuantityAndMeasurement(event, index) {
        if (event.value) {
            const receptionArray = <FormArray>this.foodSafetyProductionForm.controls['receptionArray'];
            this.subscriptions.push(this.receptionControlService.getById(this.parentId, event.value).subscribe(res => {
                this.recptionData = res;
                receptionArray.controls[index].get('Quantities').patchValue({ 'RemainingQuantity': res.RemainingQuantity });
                receptionArray.controls[index].patchValue({ 'Measurement': res.Measurement });
            }));
        }
    }

    //#endregion
}

export const isNil = (value: any): value is (null | undefined) => {
    return value === null || typeof (value) === 'undefined';
};

export const isObject = (value: any): boolean => {
    return value && value.constructor === Object;
};

export const isBlank = (value: any): boolean => {
    return isNil(value) ||
        (isObject(value) && Object.keys(value).length === 0) ||
        value.toString().trim() === '';
};

export const isPresent = (value: any): boolean => {
    return !isBlank(value);
};

export class CustomValidators {

    public static uniqueBy = (field: string, caseSensitive = true): ValidatorFn => {
        return (formArray: FormArray): { [key: string]: boolean } => {
            const controls: AbstractControl[] = formArray['controls'].filter((formGroup: FormGroup) => {
                return isPresent(formGroup.get(field).value);
            });
            const uniqueObj: any = { uniqueBy: true };
            let find = false;
            if (controls.length > 1) {
                controls.forEach(x => x.get)
                let result = _.countBy(controls, field);
                for (let i = 0; i < controls.length; i++) {

                    const formGroup = controls[i];
                    const mainControl: AbstractControl = formGroup.get(field);

                    const val: string = mainControl.value;

                    const mainValue: string = caseSensitive ? val.toLowerCase() : val;
                    controls.forEach((group: FormGroup, index: number) => {
                        if (i === index) {
                            // Same group
                            return;
                        }

                        const currControl: any = group.get(field);
                        const tempValue: string = currControl.value;
                        const currValue: string = caseSensitive ? tempValue.toLowerCase() : tempValue;
                        let newErrors: any;

                        if (mainValue === currValue) {
                            if (isBlank(currControl.errors)) {
                                newErrors = uniqueObj;
                            } else {
                                newErrors = Object.assign(currControl.errors, uniqueObj);
                            }

                            find = true;
                        } else {
                            newErrors = currControl.errors;

                            if (isPresent(newErrors)) {
                                // delete uniqueBy error
                                delete newErrors['uniqueBy'];
                                if (isBlank(newErrors)) {
                                    // {} to undefined/null
                                    newErrors = null;
                                }
                            }
                        }

                        // Add specific errors based on condition
                        currControl.setErrors(newErrors);
                    });
                }

                if (find) {
                    // Set errors to whole formArray
                    return uniqueObj;
                }
            }

            // Clean errors
            return null;
        };
    }
    isEditMode: boolean;

}
