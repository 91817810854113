import { RiskanalysisService } from './../services/risk-analysis.service';
import { ActivityServices } from './../services/activities.services';

import {
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import 'rxjs/Rx';
import { IMyDateModel, IMyDpOptions } from 'mydatepicker';
import { BusinessDepartmentService } from './../../business-department.services';
import { Employee } from '../../employees/models/employee';
import { UploaderConfig } from 'src/app/shared/ecpl-document-explorer/uploader-config';
import { HelperService } from 'src/app/_services/helper.service';
import { ConfirmationService } from 'primeng/api';
import { environment } from './../../../../environments/environment';
import { ValidationService } from './../../shared/services/validation.service';
import { ToasterComponent } from './../../../_directives/toaster.component';
import { BaseServices } from './../../kuba.services';
import { Topic } from '../../safety-job-analysis/models/topic';
import { Department, Deviation, TypeOfCase } from './../models/deviation';
import { DeviationServices } from './../services/deviation.services';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { FeatureKey, Feature } from 'src/app/_models';
import { Rights } from 'src/app/_models';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { EmployeeServices } from '../../employees/services/employee.services';

@Component({
  selector: 'deviation',
  templateUrl: 'deviation-new.component.html',
  styleUrls: ['deviation-new.component.scss'],
})
export class AddDeviationComponent implements OnInit {
  @Input() isEditSubDept: boolean;
  @Input() departmentIdList: number[];
  @Output() subDepModalClosed = new EventEmitter<void>();
  //#region variables
  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  deviationForm: FormGroup;
  featureId: number;
  selectedDept = new Department();
  selectedTypeOfCase = new TypeOfCase();
  selectedTopic = new Topic();
  departments: SelectItem[];
  selectedDepartments: any[] = [];
  TypeOfCases: SelectItem[];
  Topics: SelectItem[];
  selectedTypeCaseId: number;
  editDepts: any = [];
  viewDept = false;
  addtypeOfCase = false;
  addTopic = false;
  viewTopic = false;
  viewTypeOfCase = false;
  editTypeOfCases: any = [];
  editTopics: any = [];
  upConfig: UploaderConfig;
  uploadedFiles: any = [];
  additionalData: any;
  newDocumentAdded: any = [];
  deviationId: number;
  departmentForm: FormGroup;
  caseForm: FormGroup;
  deptId: number;
  deptParentId: string;
  addSubDept: boolean;
  showSubDept: boolean;
  deptName: boolean;
  caseId: number;
  topicForm: FormGroup;
  topicId: number;
  addDept: boolean;
  SubDepartmentForm: FormGroup;
  isDepartmentNameExists: boolean;
  existsDepartmentAlerttext: string;
  existsTypeCaseAlerttext: string;
  isTypeCaseNameExists: boolean;
  isTopicNameExists: boolean;
  existsTopicAlerttext: string;
  loading: boolean;
  isHideGuest = true;
  businessFeature: Feature[];
  SendonlytoDepartment: boolean;
  IsClear = false;
  departmentList: any = [];
  private subscriptions: Subscription[] = [];

  showMore = false;
  importances: SelectItem[];
  currentDeadLine: Date;
  currentDate: Date;
  executions: SelectItem[];
  followUps: SelectItem[];
  followUpNotify: any;
  followLabel: any;
  execLabel: any;
  selectedFollowup: string;
  isAddNewEmployee = true;
  hideSaveUserButton: boolean;
  assignedDepartments: any[] = [];
  subDepartmentsList: any[] = [];
  IsHidden = true;
  departmentValue: number[];
  subDepartmentPopup: FormGroup;
  subDeptId: number;
  departmentCreator: number;
  AddSubDepartmentloading = false;
  existsAlerttext: string;
  isNameExists: boolean;
  editSubDepartments: any;
  SubDepartment: any = [];
  viewSubDept = false;
  selectedSubDept: any[] = [];
  isNewForm = true;
  /**
   * Date picker configuration option
   */
  public defaultDateOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
    editableDateField: false,
    openSelectorOnInputClick: true,
    firstDayOfWeek: 'su',
    satHighlight: true,
    inline: false,
    height: '32px',
    selectionTxtFontSize: '14px',
    disableSince: this.getCurrentDate(),
    todayBtnTxt: this.translate.instant('TODAY'),
    dayLabels: {
      su: this.translate.instant('SUN'),
      mo: this.translate.instant('MON'),
      tu: this.translate.instant('TUE'),
      we: this.translate.instant('WED'),
      th: this.translate.instant('THU'),
      fr: this.translate.instant('FRI'),
      sa: this.translate.instant('SAT'),
    },
    monthLabels: {
      1: this.translate.instant('JANUARY'),
      2: this.translate.instant('FEBRUARY'),
      3: this.translate.instant('MARCH'),
      4: this.translate.instant('APRIL'),
      5: this.translate.instant('MAY'),
      6: this.translate.instant('JUNE'),
      7: this.translate.instant('JULY'),
      8: this.translate.instant('AUGUST'),
      9: this.translate.instant('SEPTEMBER'),
      10: this.translate.instant('OCTOBER'),
      11: this.translate.instant('NOVEMBER'),
      12: this.translate.instant('DECEMBER'),
    },
  };

  /**
   * Date picker configuration option
   */
  public startDateOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
    editableDateField: false,
    openSelectorOnInputClick: true,
    firstDayOfWeek: 'su',
    satHighlight: true,
    height: '29px',
    selectionTxtFontSize: '13px',
    todayBtnTxt: this.translate.instant('TODAY'),
    dayLabels: {
      su: this.translate.instant('SUN'),
      mo: this.translate.instant('MON'),
      tu: this.translate.instant('TUE'),
      we: this.translate.instant('WED'),
      th: this.translate.instant('THU'),
      fr: this.translate.instant('FRI'),
      sa: this.translate.instant('SAT'),
    },
    monthLabels: {
      1: this.translate.instant('JANUARY'),
      2: this.translate.instant('FEBRUARY'),
      3: this.translate.instant('MARCH'),
      4: this.translate.instant('APRIL'),
      5: this.translate.instant('MAY'),
      6: this.translate.instant('JUNE'),
      7: this.translate.instant('JULY'),
      8: this.translate.instant('AUGUST'),
      9: this.translate.instant('SEPTEMBER'),
      10: this.translate.instant('OCTOBER'),
      11: this.translate.instant('NOVEMBER'),
      12: this.translate.instant('DECEMBER'),
    },
    disableUntil: HelperService.formatInputDate(
      new Date().setDate(new Date().getDate() - 1)
    ).date,
  };
  /**
   * Date picker configuration option
   */
  public eventDateOptions: IMyDpOptions = this.defaultDateOptions;
  //#endregion
  //#region constructor
  /**
   * constructor
   * @param activatedRoute {ActivatedRoute}
   * @param _fb {FormBuilder}
   * @param deviationService {DeviationServices}
   * @param location {Location}
   * @param confirmationService {ConfirmationService}
   */
  constructor(
    private activatedRoute: ActivatedRoute,
    private _fb: FormBuilder,
    private deviationService: DeviationServices,
    protected location: Location,
    private confirmationService: ConfirmationService,
    private riskAnalysisServices: RiskanalysisService,
    private businessDepartmentService: BusinessDepartmentService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    public employeeServices: EmployeeServices
  ) {
    this.deviationForm = this._fb.group({
      Title: ['', Validators.required],
      Description: [''],
      Status: 'NEW_ISSUE',
      TypeCase: ['', [Validators.required]],
      Department: ['', [Validators.required]],
      SubDepartment: [''],
      Topic: ['', [Validators.required]],
      showUser: [''],
      Browse: [''],
      SendOnlyDepartment: [''],
      SendonlytoEditors: [0],
      ImmediateAction: [''],
      EventLocation: [''],
      EventDate: [''],
      EventTime: [''],
      Importance: [''],
      Cost: [],
      Deadline: [],
      Execution: [],
      Followup: [],
      CauseAnalysis: [''],
      CorrectiveAction: [''],
      EffectOfAction: [''],
      Comment: [''],
      Notification: [],
      FollowupNotification: [],
    });
    this.departmentForm = this._fb.group({
      Name: [
        '',
        [Validators.required, ValidationService.noWhitespaceValidator],
      ],
    });
    this.SubDepartmentForm = this._fb.group({
      SubDepartment: [
        '',
        [Validators.required, ValidationService.noWhitespaceValidator],
      ],
    });
    this.caseForm = this._fb.group({
      Name: [
        '',
        [Validators.required, ValidationService.noWhitespaceValidator],
      ],
    });
    this.topicForm = this._fb.group({
      Name: [
        '',
        [Validators.required, ValidationService.noWhitespaceValidator],
      ],
    });
    this.subDepartmentPopup = this._fb.group({
      SubDepartment: [
        '',
        [Validators.required, ValidationService.noWhitespaceValidator],
      ],
      BusinessDepartment: [''],
    });
    if (+BaseServices.roleId === 4) {
      this.isHideGuest = false;
    }
    let currentUserRole = BaseServices.UserRole;
    let userRightsId = Rights.ADMINISTRATE_DEVIATION;
    if (+BaseServices.roleId === 5) {
      this.isHideGuest = BaseServices.checkUserRights(
        userRightsId,
        currentUserRole
      );
    }
  }
  //#endregion
  //#region page-events
  /**
   * bind dropdowns
   */
  ngOnInit(): void {
    document.querySelector('body').classList.remove('opened');
    this.subscriptions.push(
      this.translate.stream('NEW_ISSUE_DEVIATION').subscribe((val) => {
        this.deviationForm.get('Status').patchValue(val.NEW_ISSUE);
      })
    );
    this.deptName = false;
    this.additionalData = {
      ApplicationId: BaseServices.ApplicationId,
      BusinessId: BaseServices.BusinessId,
      ParentId: 0,
      Status: '1',
      CreatedOn: new Date(),
      ModifiedBy: BaseServices.UserId,
      ModifiedOn: new Date(),
    };
    let docList = this.activatedRoute.snapshot.data['doclist'];
    if (docList && docList.length > 0) {
      this.uploadedFiles = this.activatedRoute.snapshot.data['doclist'];
    }

    this.bindDropDowns();
    this.bindImportanceDropdown();
    this.bindFormDropdown();
    this.upConfig = {
      title: 'DEVIATION_DOCUMENT',
      titleAsLabel: true,
      editMode: true,
      windowedHeight: false,
      viewSwitch: false,
      showUserMeta: false,
      showSearchFilter: false,
      showUploadButton: true,
      showDeleteButton: true,
      uploaderUri:
        environment.BASE_URL + '/file/upload/business/followup/deviation-docs',
      addtionalData: this.additionalData,
    };
    if (this.isEditSubDept != null) {
      this.isNewForm = false;
      this.addSubDept = this.isEditSubDept;
      if (this.departmentIdList != null && this.departmentIdList.length > 0) {
        this.bindSubDepartments(this.departmentIdList);
      }
    }
  }
  //#endregion

  /**
   * file upload events
   * @param event {any}
   */
  saveUploaded(event: any) {
    if (event) {
      let eventType = event.eventName;
      let keyBindings = {
        Originalname: 'OriginalFileName',
        FileTypeId: 'FileType',
      };
      let deviationDoc = Object.assign({}, event, this.additionalData);
      switch (eventType) {
        case 'DeleteDefaultFile':
          this.uploadedFiles = this.uploadedFiles.filter(
            (x: any) => x.path !== event.item.data.path
          );
          if (event.item.data.id === 0) {
            this.newDocumentAdded = this.newDocumentAdded.filter(
              (x: any) => x.Path !== event.item.data.path
            );
          } else {
            deviationDoc.id = event.item.data.id;
            deviationDoc.createdBy = BaseServices.UserId;
            deviationDoc.status = 2;
            this.newDocumentAdded.push(
              HelperService.switchKeysCase(deviationDoc, keyBindings)
            );
          }
          break;
        default:
          deviationDoc.id = 0;
          deviationDoc.createdBy = BaseServices.UserId;
          deviationDoc.status = 1;
          this.newDocumentAdded.push(
            HelperService.switchKeysCase(deviationDoc, keyBindings)
          );
          break;
      }
    }
  }
  //#region control-events
  /**
   * save deviation
   * @param data {{any}}
   */
  saveDeviation() {
    this.loading = true;
    let windowOrigin = window.location.origin;
    let applicationBase = '';
    let appSettings = JSON.parse(sessionStorage.getItem('appSettings')!);
    if (appSettings) {
      applicationBase = appSettings.BaseUrl;
    }
    let usersettings = JSON.parse(sessionStorage.getItem('userSettings')!);
    if (usersettings) {
      this.businessFeature = <Feature[]>(
        JSON.parse(usersettings.userFeature.Features)
      );
      usersettings = this.businessFeature.find(
        (record) => record.id === FeatureKey.FOLLOW_UP
      );
      if (usersettings.children) {
        let feature = usersettings.children.find(
          (data: any) => data.id === FeatureKey.SEND_ONLY_TO_DEPARTMENT
        );
        this.SendonlytoDepartment = feature ? feature.checked : false;
      }
    }
    let url =
      windowOrigin +
      '/' +
      applicationBase +
      '/login?returnUrl=' +
      '/app/business/' +
      BaseServices.BusinessId +
      '/follow-up/deviations/edit/';
    let deviationdetails = new Deviation();
    deviationdetails.DeviationUrl = url;
    deviationdetails.FeatureId = this.featureId;
    deviationdetails.BusinessId = BaseServices.BusinessId;
    deviationdetails.ApplicationId = BaseServices.ApplicationId;
    deviationdetails.Id =
      this.selectedTypeCaseId > 0 ? this.selectedTypeCaseId : 0;
    deviationdetails.BusinessDepartmentIds =
      this.deviationForm.value.Department;
    deviationdetails.SubDepartmentId = this.deviationForm.value.SubDepartment;
    deviationdetails.CaseId = this.deviationForm.value.TypeCase;
    deviationdetails.Description = this.deviationForm.value.Description;
    deviationdetails.Title = this.deviationForm.value.Title;
    deviationdetails.IsShowtoUsers = this.deviationForm.value.showUser;
    deviationdetails.IsSendOnlyDepartment =
      this.deviationForm.value.SendOnlyDepartment;
    deviationdetails.TopicId = this.deviationForm.value.Topic;
    deviationdetails.Status = '7';
    deviationdetails.CreatedBy = BaseServices.UserId;
    deviationdetails.DeviationAttachment = this.newDocumentAdded;
    deviationdetails.ImmediateAction = this.deviationForm.value.ImmediateAction;
    deviationdetails.CultureInfo = sessionStorage.getItem('languageMode')!;
    deviationdetails.SendonlytoDepartment = this.SendonlytoDepartment;
    deviationdetails.SendonlytoEditors =
      this.deviationForm.value.SendonlytoEditors;
    deviationdetails.EventDate = this.deviationForm.value.EventDate
      ? HelperService.formatDateFilter(
          this.deviationForm.value.EventDate.formatted
        )
      : null!;
    deviationdetails.EventTime = this.deviationForm.value.EventTime
      ? HelperService.formatTime(this.deviationForm.value.EventTime)
      : null!;
    deviationdetails.EventLocation = this.deviationForm.value.EventLocation;
    deviationdetails.Importance = this.deviationForm.value.Importance;
    deviationdetails.Comment = this.deviationForm.value.Comment;
    deviationdetails.ExecutionUserId = this.deviationForm.value.Execution;
    deviationdetails.FollowupUserId = this.deviationForm.value.Followup;
    deviationdetails.ImmediateAction = this.deviationForm.value.ImmediateAction;
    deviationdetails.CauseAnalysis = this.deviationForm.value.CauseAnalysis;
    deviationdetails.CorrectiveAction =
      this.deviationForm.value.CorrectiveAction;
    deviationdetails.EffectOfAction = this.deviationForm.value.EffectOfAction;
    deviationdetails.NotifyBeforeDeadLine =
      this.deviationForm.value.Notification;
    deviationdetails.NotificationBeforeFollowup =
      this.deviationForm.value.FollowupNotification;
    deviationdetails.Cost = this.deviationForm.value.Cost;
    if (
      this.deviationForm.value.Deadline != null &&
      this.deviationForm.value.Deadline != undefined
    ) {
      deviationdetails.Deadline = this.deviationForm.value.Deadline.jsdate;
    }
    this.subscriptions.push(
      this.deviationService.addDeviation(deviationdetails).subscribe(
        (isDeviationAdded) => {
          if (isDeviationAdded) {
            this.toasterComponent.callToast();
            this.loading = false;
            this.gotoList();
          }
        }
      )
    );
  }
  /**
   * save and update department
   */
  saveDepartment() {
    let department = new Department();
    if (this.showSubDept) {
      this.deptParentId += this.deptId;
      this.deptId = 0;
      department.Name = this.SubDepartmentForm.value.SubDepartment;
    } else {
      department.Name = this.departmentForm.value.Name;
    }
    department.Id = this.deptId ? this.deptId : 0;
    department.Status = 1;
    department.BusinessId = BaseServices.BusinessId;
    department.ApplicationId = BaseServices.ApplicationId;
    department.ParentId = this.deptParentId ? this.deptParentId : '';
    if (department.Id > 0) {
      department.ModifiedBy = BaseServices.UserId;
      this.clearCachedDepartment();
      this.subscriptions.push(
        this.deviationService
          .updateDepartment(department.Id, department)
          .subscribe((isDeptUpdated) => {
            if (isDeptUpdated) {
              this.toasterComponent.callToast();
              this.refreshDepartmentDropDown(
                isDeptUpdated.Id,
                isDeptUpdated.name
              );
              this.addDept = false;
            } else {
              this.existsDepartmentAlerttext =
                this.translate.instant(`TOPIC_EXIST`);
              this.isDepartmentNameExists = true;
              setTimeout(() => {
                this.isDepartmentNameExists = false;
              }, 3000);
            }
          })
      );
    } else {
      this.clearCachedDepartment();
      department.CreatedBy = BaseServices.UserId;
      this.subscriptions.push(
        this.deviationService
          .addDepartment(department)
          .subscribe((isDeptAdded) => {
            if (isDeptAdded) {
              this.toasterComponent.callToast();
              this.refreshDepartmentDropDown(isDeptAdded.Id, isDeptAdded.name);
              this.addDept = false;
            } else {
              this.existsDepartmentAlerttext =
                this.translate.instant(`TOPIC_EXIST`);
              this.isDepartmentNameExists = true;
              setTimeout(() => {
                this.isDepartmentNameExists = false;
              }, 3000);
            }
          })
      );
    }
  }
  /**
   * cache clear of department
   */
  clearCachedDepartment() {
    this.businessDepartmentService.clearCachedAllDepartment();
  }
  /**
   * save type of case
   */
  saveTypeOfCase() {
    let typeOfcase = new TypeOfCase();
    typeOfcase.Id = this.caseId ? this.caseId : 0;
    typeOfcase.Name = this.caseForm.value.Name;
    typeOfcase.BusinessId = BaseServices.BusinessId;
    typeOfcase.ApplicationId = BaseServices.ApplicationId;
    typeOfcase.Status = '1';
    if (typeOfcase.Id > 0) {
      this.subscriptions.push(
        this.deviationService
          .updateCaseType(typeOfcase.Id, typeOfcase)
          .subscribe((isTypeUpdated) => {
            if (isTypeUpdated) {
              this.addtypeOfCase = false;
              this.toasterComponent.callToast();
              this.bindTypeCaseDropDown();
              this.deviationForm.get('TypeCase')!.patchValue(isTypeUpdated.Id);
            } else {
              this.existsTypeCaseAlerttext =
                this.translate.instant(`TOPIC_EXIST`);
              this.isTypeCaseNameExists = true;
              setTimeout(() => {
                this.isTypeCaseNameExists = false;
              }, 3000);
            }
          })
      );
    } else {
      this.subscriptions.push(
        this.deviationService
          .addTypeCase(typeOfcase)
          .subscribe((isTypeSaved) => {
            if (isTypeSaved) {
              this.addtypeOfCase = false;
              this.toasterComponent.callToast();
              this.deviationService.clearCachedCaseType();
              this.bindTypeCaseDropDown();
              this.deviationForm.get('TypeCase')!.patchValue(isTypeSaved.Id);
            } else {
              this.existsTypeCaseAlerttext =
                this.translate.instant(`TOPIC_EXIST`);
              this.isTypeCaseNameExists = true;
              setTimeout(() => {
                this.isTypeCaseNameExists = false;
              }, 3000);
            }
          })
      );
    }
  }
  /**
   * save topic
   */
  saveTopic() {
    let topic = new Topic();
    topic.Id = this.topicId ? this.topicId : 0;
    topic.Name = this.topicForm.value.Name;
    topic.Status = '1';
    topic.BusinessId = BaseServices.BusinessId;
    if (topic.Id > 0) {
      this.subscriptions.push(
        this.deviationService
          .updateTopics(topic)
          .subscribe((isTopicUpdated) => {
            if (isTopicUpdated) {
              this.addTopic = false;
              this.toasterComponent.callToast();
              this.fetchTopic();
              this.deviationForm.get('Topic')!.patchValue(isTopicUpdated.Id);
            } else {
              this.existsTopicAlerttext = this.translate.instant(`TOPIC_EXIST`);
              this.isTopicNameExists = true;
              setTimeout(() => {
                this.isTopicNameExists = false;
              }, 3000);
            }
          })
      );
    } else {
      this.subscriptions.push(
        this.deviationService.addTopics(topic).subscribe((isTopicAdded) => {
          if (isTopicAdded) {
            this.addTopic = false;
            this.toasterComponent.callToast();
            this.riskAnalysisServices.clearCachedTopic();
            this.fetchTopic();
            this.deviationForm.get('Topic')!.patchValue(isTopicAdded.Id);
          } else {
            this.existsTopicAlerttext = this.translate.instant(`TOPIC_EXIST`);
            this.isTopicNameExists = true;
            setTimeout(() => {
              this.isTopicNameExists = false;
            }, 3000);
          }
        })
      );
    }
    this.selectedTopic = new Topic();
  }
  /**
   * department popup reset
   */
  onBeforeDeptDialogHide() {
    this.departmentForm.reset();
    this.deptId = 0;
    this.deptParentId = '';
    this.addSubDept = false;
    this.showSubDept = false;
    this.deptName = false;
    if (this.isEditSubDept) {
      this.closeSubDepartmentModal();
    }
  }
  /**
   * case popup reset
   */
  onBeforeCaseDialogHide() {
    this.caseForm.reset();
    this.caseId = 0;
  }
  /**
   * topic popup reset
   */
  onBeforeTopicDialogHide() {
    this.topicForm.reset();
    this.topicId = 0;
  }

  //#endregion
  //#region methods
  /**
   * go to list
   */
  gotoList() {
    this.location.back();
  }

  /**
   * bind all dropdowns
   */
  bindDropDowns() {
    this.bindDepartmentDropDown();
    this.TypeOfCases = [];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.TypeOfCases = [];
        this.TypeOfCases.push({ label: val.SELECT, value: null });
        this.editTypeOfCases = [];
        let typeOfCases = this.activatedRoute.snapshot.data['caseType'];
        if (typeOfCases) {
          typeOfCases.forEach((element: any) => {
            this.TypeOfCases.push({ label: element.Name, value: element.Id });
            this.editTypeOfCases.push({ Name: element.Name, Id: element.Id });
          });
        }
      })
    );

    this.editTopics = [];
    this.Topics = [];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.Topics = [];
        this.Topics.push({ label: val.SELECT, value: null });
        let topics = this.activatedRoute.snapshot.data['topic'];
        if (topics) {
          topics.forEach((element: any) => {
            this.Topics.push({ label: element.Name, value: element.Id });
            this.editTopics.push({ Name: element.Name, Id: element.Id });
          });
        }
      })
    );
  }
  /**
   * bind department dropdown
   */
  bindDepartmentDropDown() {
    this.departments = [];
    this.editDepts = [];
    let departments = this.activatedRoute.snapshot.data['department'];
    if (departments) {
      this.departmentDropDown(departments);
    }
  }
  /**
   * common dropdown bind
   * @param departments {any}
   */
  departmentDropDown(departments: any) {
    let department = _.filter(departments, { ParentId: null });
    department = _.orderBy(department, ['Name'], ['asc', 'desc']);
    this.departments = [];
    this.editDepts = [];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.departments = [];
        this.departments
          .push
          ();
        if (department) {
          department.forEach((department: any) => {
            this.departments.push({
              label: department.Name,
              value: department.Id,
            });
            this.editDepts.push({ Name: department.Name, Id: department.Id });
            if (department.Id) {
              let subDepartment = _.filter(departments, {
                ParentId: department.Id,
              });
              subDepartment.forEach((subDept: Department) => {
                this.departments.push({
                  label: `${department.Name} (${subDept.Name})`,
                  value: subDept.Id,
                });
                this.editDepts.push({
                  Name: `${department.Name} (${subDept.Name})`,
                  Id: subDept.Id,
                });
              });
            }
          });
        }
      })
    );
    if (department) {
      return true;
    }
    return false;
  }

  onDepartmentDropDownChanged(departmentDropdown: any) {
    this.IsHidden = false;
    this.departmentValue = departmentDropdown.value;
    this.assignSubDepartmentDropdown(this.departmentValue);
    this.bindSubDepartments(this.departmentValue);
  }

  onBeforeSubDepartmentDialogHide() {
    this.subDepartmentPopup.reset();
    this.subDeptId = 0;
  }
  editSubDept(empSubDept: any) {
    this.subDeptId = empSubDept.Id;
    this.departmentCreator = empSubDept.CreatedBy;
    this.subDepartmentPopup = this._fb.group({
      SubDepartment: empSubDept.Name,
    });
  }
  deleteSubDept(empSubdept: any) {
    if (empSubdept.busId > 0 || empSubdept.BusinessId > 0) {
      this.confirmationService.confirm({
        message: this.translate.instant('DELETE_THIS_RECORD'),
        accept: () => {
          this.subscriptions.push(
            this.employeeServices
              .deleteSubDepartment(empSubdept.Id)
              .subscribe((isDocumentDeleted) => {
                if (isDocumentDeleted) {
                  // this.bindSubDepartment(this.departmentValue);
                }
              })
          );
        },
      });
    }
  }

  saveSubDept() {
    this.AddSubDepartmentloading = true;
    let employeeSubDept = new Employee();
    employeeSubDept.Id = this.subDeptId ? this.subDeptId : 0;
    employeeSubDept.Name = this.subDepartmentPopup.value.SubDepartment;
    employeeSubDept.Status = '1';
    employeeSubDept.BusinessId = BaseServices.BusinessId;
    employeeSubDept.ApplicationId = BaseServices.ApplicationId;
    employeeSubDept.BusinessDeptId =
      this.subDepartmentPopup.value.BusinessDepartment;
    if (employeeSubDept.Id > 0) {
      employeeSubDept.ModifiedBy = BaseServices.UserId;
      employeeSubDept.CreatedBy = this.departmentCreator;
      this.subscriptions.push(
        this.employeeServices
          .updateDept(employeeSubDept)
          .subscribe((updatedSubDept) => {
            this.bindSubDeptDropdown(updatedSubDept);
            this.AddSubDepartmentloading = false;
          })
      );
    } else {
      employeeSubDept.CreatedBy = BaseServices.UserId;
      this.subscriptions.push(
        this.employeeServices
          .createSubDept(employeeSubDept)
          .subscribe((addedSubdept) => {
            this.bindSubDeptDropdown(addedSubdept);
            this.AddSubDepartmentloading = false;
          })
      );
    }
  }

  bindSubDeptDropdown(subDeptInfo: any) {
    if (subDeptInfo) {
      this.addSubDept = false;
      this.toasterComponent.callToast();
      this.bindSubDepartments(subDeptInfo.BusinessDeptId);
      this.deviationForm.get('SubDepartment')!.patchValue(subDeptInfo.Id);
    } else {
      this.existsAlerttext = this.translate.instant(`TOPIC_EXIST`);
      this.isNameExists = true;
      setTimeout(() => {
        this.isNameExists = false;
      }, 3000);
    }
  }

  bindSubDepartments(value: number[]) {
    const roleId = BaseServices.roleId;
    this.subscriptions.push(
      this.employeeServices
        .getSubDepartments(value, BaseServices.BusinessId, roleId)
        .subscribe((subDepartment: any) => {
          this.subDepartmentsList = [];
          subDepartment.forEach((element: any) => {
            if (subDepartment) {
              this.subDepartmentsList.push({
                label: element.Name,
                value: element.Id,
              });
            }
            this.editSubDepartments = subDepartment;
          });
        })
    );
  }
  assignSubDepartmentDropdown(value: number[]) {
    this.assignedDepartments = [];
    this.assignedDepartments.push({
      label: this.translate.instant('SELECT'),
      value: null,
    });
    this.subscriptions.push(
      this.employeeServices
        .getAssignedDepartments(value)
        .subscribe((subDepartment) => {
          this.SubDepartment = subDepartment;
          if (subDepartment) {
            this.SubDepartment.forEach((element: any) => {
              if (this.assignedDepartments) {
                this.assignedDepartments.push({
                  label: element.Name,
                  value: element.Id,
                });
              }
            });
          }
        })
    );
  }
  /**
   * bind type department dropdown
   */
  refreshDepartmentDropDown(departmentId: number, departmentname: any[]) {
    this.subscriptions.push(
      this.businessDepartmentService
        .getCachedBusinessDepartment()
        .subscribe((deptDropDown) => {
          this.departmentList = deptDropDown;
          if (deptDropDown) {
            let isUpdated = this.departmentDropDown(deptDropDown);
            if (isUpdated) {
              this.deviationForm.get('Department')!.patchValue(departmentId);
              this.selectedDepartments = departmentname;
            }
          }
        })
    );
  }
  bindDepartment() {
    this.subscriptions.push(
      this.businessDepartmentService
        .getCachedBusinessDepartment()
        .subscribe((res) => {
          if (res) {
            this.editDepts = res;
          }
        })
    );
  }
  /**
   * bind type case dropdown
   */
  bindTypeCaseDropDown() {
    this.subscriptions.push(
      this.deviationService.getAllCaseType().subscribe((caseDropDown) => {
        this.TypeOfCases = [];
        this.subscriptions.push(
          this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
            this.TypeOfCases = [];
            this.TypeOfCases.push({ label: val.SELECT, value: null });
            caseDropDown.forEach((element: any) => {
              this.TypeOfCases.push({ label: element.Name, value: element.Id });
            });
            this.editTypeOfCases = caseDropDown;
          })
        );
      })
    );
  }

  /**
   * bind topic dropdown
   */
  bindTopicDropDown() {
    let topic = this.activatedRoute.snapshot.data['deviationTopic'];
    if (topic) {
      this.Topics = [];
      this.subscriptions.push(
        this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
          this.Topics = [];
          this.Topics.push({ label: val.SELECT, value: null });
          topic.forEach((element: any) => {
            this.Topics.push({ label: element.Name, value: element.Id });
          });
          this.editTopics = topic;
        })
      );
    };
  }
  checkDeptValid() {
    if (this.showSubDept) {
      this.deptName = true;
      if (this.SubDepartmentForm.status === 'INVALID') {
        return true;
      }
    } else if (this.departmentForm.status === 'INVALID') {
      return true;
    }
    return false;
  }
  /**
   * edit department
   * @param Dept {{any}}
   */
  editDept(Dept: any) {
    this.showSubDept = false;
    let departments: any = [];
    departments = this.activatedRoute.snapshot.data['department'];
    if (departments) {
      departments = this.departmentList.filter((x: any) => x.Id === Dept.Id);
    }
    this.deptId = Dept.Id;
    this.deptParentId = departments[0].ParentId;
    if (this.deptParentId === null) {
      this.addSubDept = true;
    } else {
      this.addSubDept = false;
    }
    let department = {
      Name: departments[0].Name,
    };
    (<FormGroup>this.departmentForm).setValue(department, { onlySelf: true });
  }

  addSubDepartment() {
    this.addSubDept = false;
    this.showSubDept = true;
  }
  /**
   * delete department
   * @param deleteType {{any}}
   */
  deleteDept(deleteType: any) {
    this.confirmationService.confirm({
      message: this.translate.instant('DELETE_THIS_RECORD'),
      accept: () => {
        this.subscriptions.push(
          this.deviationService
            .deleteDepartment(deleteType.Id)
            .subscribe((res) => {
              if (res) {
                this.departments = this.departments.filter(
                  (department) => department.value !== deleteType.Id
                );

                this.bindDepartment();
                this.clearCachedDepartment();
                this.toasterComponent.callToastDlt();
              }
            })
        );
      },
    });
  }

  /**
   * edit type of case
   * @param TypeOfCase {{any}}
   */
  editTypeOfCase(typeOfCase: any) {
    this.caseId = typeOfCase.Id;
    let Case = {
      Name: typeOfCase.Name,
    };
    (<FormGroup>this.caseForm).setValue(Case, { onlySelf: true });
  }

  /**
   * delete type of case
   * @param deleteTypeId {{number}}
   */
  deleteTypeOfCase(deleteTypeId: number) {
    this.confirmationService.confirm({
      message: this.translate.instant('DELETE_THIS_RECORD'),
      accept: () => {
        this.subscriptions.push(
          this.deviationService
            .deleteTypeCase(deleteTypeId)
            .subscribe((res) => {
              if (res) {
                this.bindTypeCaseDropDown();
                this.deviationService.clearCachedCaseType();
                this.toasterComponent.callToastDlt();
              }
            })
        );
      },
    });
  }
  /**
   * Fetch the topics
   */
  fetchTopic() {
    this.isTopicNameExists = false;
    this.subscriptions.push(
      this.deviationService
        .getAllTopics(BaseServices.BusinessId)
        .subscribe((topic: any) => {
          this.subscriptions.push(
            this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
              this.Topics = [];
              this.Topics.push({ label: val.SELECT, value: null });
              if (topic) {
                topic.forEach((topicValue: any) => {
                  this.Topics.push({
                    label: topicValue.Name,
                    value: topicValue.Id,
                  });
                });
              }
            })
          );
          this.editTopics = topic;
        })
    );
  }

  /**
   * edit topic
   * @param Topic {{any}}
   */
  editTopic(topic: any) {
    this.topicId = topic.Id;
    let topicControl = {
      Name: topic.Name,
    };
    (<FormGroup>this.topicForm).setValue(topicControl, { onlySelf: true });
  }

  /**
   * delete type of topic
   * @param deleteTopic {{any}}
   */
  deleteTopic(deleteTopic: any) {
    this.confirmationService.confirm({
      message: this.translate.instant('DELETE_THIS_RECORD'),
      accept: () => {
        this.subscriptions.push(
          this.deviationService
            .deleteTopics(deleteTopic)
            .subscribe((isTopicDeleted) => {
              if (isTopicDeleted) {
                this.fetchTopic();
                this.riskAnalysisServices.clearCachedTopic();
                this.toasterComponent.callToastDlt();
              }
            })
        );
      },
    });
  }

  /**
   * get current date
   */
  getCurrentDate() {
    let dateFormat = new Date();
    return {
      year: dateFormat.getFullYear(),
      month: dateFormat.getMonth() + 1,
      day: dateFormat.getDate() + 1,
    };
  }

  /**
   * bind evnet date and time
   */
  bindEventDate() {
    let dateFormat = new Date();
    this.deviationForm.controls['EventDate'].setValue(
      HelperService.formatInputDate(new Date())
    );
    this.deviationForm.controls['EventTime'].setValue(
      this.datePipe.transform(dateFormat, 'HH:mm')
    );
    this.IsClear = true;
  }

  /**
   * clear evnet date and time
   */
  clearEventDate() {
    this.deviationForm.controls['EventDate'].setValue(null);
    this.deviationForm.controls['EventTime'].setValue(null);
    this.IsClear = false;
  }

  /**
   * enable clear button
   */
  enableClear() {
    this.IsClear = true;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }

  bindImportanceDropdown() {
    this.subscriptions.push(
      this.translate.stream('RISKFIGURE_DROPDOWN').subscribe((val) => {
        this.importances = [];
        this.importances.push(
          { label: val.SELECT, value: null },
          { label: val.LOW, value: 1 },
          { label: val.MEDIUM, value: 2 },
          { label: val.HIGH, value: 3 }
        );
      })
    );
  }

  /**
   * cost key press event
   * @param e {e}
   * @param limitNumber {limitNumber}
   */
  onNumber(e: any, limitNumber: any) {
    const limit = limitNumber;
    const dots = e.target.value.indexOf('.');
    let charCode = e.which ? e.which : e.keyCode;
    if (
      e.target.value.length === limit ||
      dots > 1 ||
      (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46)
    ) {
      e.preventDefault();
    }
  }

  onDeadLineChanged(event: IMyDateModel) {
    this.currentDeadLine = event.jsdate
      ? new Date(event.jsdate.getTime())
      : null;
    this.currentDate = new Date();
    this.deviationForm['controls']['Notification'].setValue(0);
  }

  bindFormDropdown() {
    let responsiblePerson = this.activatedRoute.snapshot.data['user'];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.executions = [];
        this.executions.push({ label: val.SELECT, value: null });
        if (responsiblePerson) {
          responsiblePerson.forEach((followupExecution: any) => {
            this.executions.push({
              label: followupExecution.Name,
              value: followupExecution.Id,
            });
          });
        }
      })
    );
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.followUps = [];
        this.followUps.push({ label: val.SELECT, value: null });
        if (responsiblePerson) {
          responsiblePerson.forEach((followupExecution: any) => {
            this.followUps.push({
              label: followupExecution.Name,
              value: followupExecution.Id,
            });
          });
        }
      })
    );

    this.followUpNotify = [];
    for (let n = 0; n <= 14; n++) {
      if (n === 0) {
        this.followUpNotify.push({
          label: this.translate.instant('ON_DEADLINE_DATE'),
          value: n,
        });
      } else {
        this.followUpNotify.push({ label: n.toString(), value: n });
      }
    }
    this.followUpNotify.push(
      { label: this.translate.instant('TREE_WEEKS'), value: 21 },
      { label: this.translate.instant('ONE_MONTH'), value: 30 }
    );
  }

  /**
   * followup dropdown change event
   * @param e
   * @param followupDropdown
   */

  onFollowChanged(e: any, follow: any) {
    if (e.value) {
      this.followLabel = follow.selectedOption.label;
    }
  }

  /**
   * execution dropdown change eventF
   * @param e
   * @param executionDropdown
   */

  onExecChanged(e: any, exec: any) {
    if (e.value) {
      this.execLabel = exec.selectedOption.label;
    }
  }

  ShowMore(data) {
    if (data == true) {
      this.showMore = true;
      this.deviationForm.controls['Importance'].setValidators(
        Validators.required
      );
      this.deviationForm.controls['Importance'].updateValueAndValidity();
      this.deviationForm.controls['Deadline'].setValidators(
        Validators.required
      );
      this.deviationForm.controls['Deadline'].updateValueAndValidity();
      this.deviationForm.controls['Execution'].setValidators(
        Validators.required
      );
      this.deviationForm.controls['Execution'].updateValueAndValidity();
      this.deviationForm.controls['Followup'].setValidators(
        Validators.required
      );
      this.deviationForm.controls['Followup'].updateValueAndValidity();
    } else {
      this.showMore = false;
      this.deviationForm.controls['Department'].setValidators(
        Validators.required
      );
      this.deviationForm.controls['Department'].updateValueAndValidity();
      this.deviationForm.controls['Topic'].setValidators(Validators.required);
      this.deviationForm.controls['Topic'].updateValueAndValidity();
      this.deviationForm.controls['TypeCase'].setValidators(
        Validators.required
      );
      this.deviationForm.controls['TypeCase'].updateValueAndValidity();
      this.deviationForm.controls['Title'].setValidators(Validators.required);
      this.deviationForm.controls['Title'].updateValueAndValidity();
      this.deviationForm.controls['Followup'].clearValidators();
      this.deviationForm.controls['Followup'].updateValueAndValidity();
      this.deviationForm.controls['Execution'].clearValidators();
      this.deviationForm.controls['Execution'].updateValueAndValidity();
      this.deviationForm.controls['Deadline'].clearValidators();
      this.deviationForm.controls['Deadline'].updateValueAndValidity();
      this.deviationForm.controls['Importance'].clearValidators();
      this.deviationForm.controls['Importance'].updateValueAndValidity();
    }

    //#endregion
  }

  closeSubDepartmentModal() {
    this.subDepModalClosed.emit();
  }
}
