<div class="main-content">
  <div class="main-heading">
    <span>{{ featureName }}</span>

    <span class="page-actions text-right">
      <button
        type="button"
        *ngIf="isShowAllFile"
        class="btn"
        (click)="showAllFiles()"
        [ngClass]="'btn-outline-primary'"
      >
        <span translate>SHOW_ALL_FILES</span>
      </button>
      <button
        *ngIf="isShowButton"
        type="button"
        style="margin-left: 16px"
        class="btn btn-icon btn-sm btn-border"
        (click)="toggleEditMode()"
        [ngClass]="editMode ? 'text-danger' : 'text-primary'"
      >
        <span *ngIf="editMode" translate>GO_DISPLAY</span>
        <span *ngIf="!editMode" translate>GO_EDIT</span>
        <span
          class="icon ic-md"
          [ngClass]="
            editMode
              ? 'icon-edit-content text-danger'
              : 'icon-view-content text-primary'
          "
        ></span>
      </button>
    </span>
  </div>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-sm-3">
          <app-ecpl-document-tree
            *ngIf="treeNode"
            treeId="ArchiveDocumentsTree"
            [(ecplTreeData)]="treeNode"
            (ecplNodeEvent)="catchTreeEvent($event)"
            [(ecplTreeCheckbox)]="editMode"
          >
          </app-ecpl-document-tree>
        </div>
        <div class="col-sm-9">
          <div>
            <ecpl-document-explorer
              [(triggerUploader)]="triggerUploader"
              [uploaderConfig]="upConfig"
              [uploadedFiles]="uploadedFiles"
              (activeFolder)="setActiveNodeIntree($event)"
              (saveChanges)="catchExplorerEvent($event)"
            >
            </ecpl-document-explorer>
          </div>
        </div>
      </div>
    </div>
    <div class="saving-loader overlay" *ngIf="showSavingLoader">
      <div class="saving-loader-message text-center">
        <img src="/assets/icons/blocks.gif" alt="" />
        <p translate>SAVING</p>
      </div>
    </div>
  </div>
</div>
