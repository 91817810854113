<div class="page-title">
  <span>
    <span translate>DAILY_TASK</span>
  </span>
</div>
<div class="table-view mbtm-30">
  <!-- Table starts -->
  <p-table
    #taskTable
    [value]="tasklist"
    exportFilename="TaskManager list"
    selectionMode="multiple"
    [(selection)]="selectedTasklist"
    [rows]="10"
    [paginator]="true"
    [pageLinks]="3"
    [rowsPerPageOptions]="[5, 10, 20]"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="{{ 'SUMMARY_TOTALRECORDS' | translate }}"
  >
    <!-- Caption -->
    <ng-template pTemplate="caption">
      <div class="ui-helper-clearfix">
        <div class="table-options">
          <div class="pull-right">
            <kuba-export
              [reportData]="taskTable.filteredValue || taskTable.value"
              [additionalData]="additionalData"
            ></kuba-export>
          </div>
        </div>
      </div>
    </ng-template>
    <!-- Header -->
    <ng-template pTemplate="header">
      <tr>
        <th width="35px"></th>
        <th pSortableColumn="No" width="110px">
          {{ "NUMBER_NO" | translate }} <p-sortIcon field="No"></p-sortIcon>
        </th>
        <th pSortableColumn="Title">
          {{ "TITLE" | translate }} <p-sortIcon field="Title"></p-sortIcon>
        </th>
        <th pSortableColumn="Description">
          {{ "DESCRIPTION" | translate }}
          <p-sortIcon field="Description"></p-sortIcon>
        </th>
        <th pSortableColumn="RecurrDayName">
          {{ "RECURRENCE" | translate }}
          <p-sortIcon field="RecurrDayName"></p-sortIcon>
        </th>
        <th pSortableColumn="EndDate" width="110px">
          {{ "DEADLINE" | translate }} <p-sortIcon field="EndDate"></p-sortIcon>
        </th>
        <th pSortableColumn="CreatorName" width="110px">
          {{ "CREATED_BY" | translate }}
          <p-sortIcon field="CreatorName"></p-sortIcon>
        </th>
      </tr>
      <tr>
        <th></th>
        <th>
          <input
            pInputText
            type="text"
            (input)="
              taskTable.filter($any($event.target)?.value, 'No', 'contains')
            "
            placeholder=""
            class="p-column-filter"
          />
        </th>
        <th>
          <input
            pInputText
            type="text"
            (input)="
              taskTable.filter($any($event.target)?.value, 'Title', 'contains')
            "
            placeholder=""
            class="p-column-filter"
          />
        </th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
      </tr>
    </ng-template>
    <!-- Body Content -->
    <ng-template pTemplate="body" let-dailytask>
      <tr>
        <td>
          <span class="{{ dailytask.StatusIcon }}"></span>
        </td>
        <td>
          <span>{{ dailytask.No }}</span>
        </td>
        <td>
          <div>
            <a
              *ngIf="!dailytask.IsTempControlRecurring"
              (click)="
                dailytask.Status !== 'DONE' && checkTaskStatus(dailytask)
              "
              [ngClass]="{ disabled: dailytask.Status === 'DONE' }"
              pTooltip="{{ dailytask.Title }}"
              tooltipPosition="right"
              >{{ dailytask.Title }}</a
            >
            <a
              *ngIf="
                dailytask.IsTempControlRecurring && dailytask.Status === 'NEW'
              "
              [routerLink]="[
                '../../../../fs-complete-checklist/new/',
                dailytask.Id
              ]"
              [class.disabled]="
                dailytask.RoleId === '1' || dailytask.RoleId === '2'
              "
              pTooltip="{{ dailytask.Title }}"
              tooltipPosition="right"
              ><span>{{ "TEMPERATURE_CONTROL" | translate }} - </span
              >{{ dailytask.Title }}</a
            >
            <a
              *ngIf="
                dailytask.IsTempControlRecurring && dailytask.Status === 'OPEN'
              "
              [routerLink]="[
                '../../../../fs-complete-checklist/incomplete/',
                dailytask.UnderCompletionTempControlId
              ]"
              [class.disabled]="
                dailytask.RoleId === '1' || dailytask.RoleId === '2'
              "
              pTooltip="{{ dailytask.Title }}"
              tooltipPosition="right"
              ><span>{{ "TEMPERATURE_CONTROL" | translate }} - </span
              >{{ dailytask.Title }}</a
            >
            <a
              *ngIf="
                dailytask.IsTempControlRecurring && dailytask.Status === 'DONE'
              "
              [class.disabled]="
                dailytask.RoleId === '1' || dailytask.RoleId === '2'
              "
              pTooltip="{{ dailytask.Title }}"
              tooltipPosition="right"
              ><span>{{ "TEMPERATURE_CONTROL" | translate }} - </span
              >{{ dailytask.Title }}</a
            >
          </div>
        </td>
        <td>
          <div
            pTooltip="{{ dailytask.Description }}"
            tooltipPosition="right"
            class="truncate-data"
          >
            {{ dailytask.Description }}
          </div>
        </td>
        <td>
          <div
            pTooltip="{{ recurTranslate(dailytask.RecurrDayName) }}"
            tooltipPosition="right"
            class="truncate-data"
          >
            {{ recurTranslate(dailytask.RecurrDayName) }}
          </div>
        </td>
        <td>
          <span>{{ dailytask.EndDate | date : "dd/MM/yyyy" }}</span>
        </td>
        <td>
          <span>{{ dailytask.CreatorName }}</span>
        </td>
      </tr>
    </ng-template>
    <!-- Footer -->
    <ng-template pTemplate="summary">
      <div class="footer-data">
        <div class="symbol-descriptions">
          <div class="symbol-desc-title">
            <span translate>DESCRIPTION_SYMBOLS</span>
          </div>
          <div class="symbol-desc-items">
            <span class="text-primary">
              <i class="icon ic-md icon-star-circle"></i>
              <span translate>NEW_TASK</span>
            </span>
            <span class="text-secondary">
              <i class="icon ic-md icon-star-circle"></i>
              <span>
                <span translate>TASK_STARTED</span>
              </span>
            </span>
            <span class="clear-deadline">
              <i class="icon ic-md icon-clear-deadline"></i>
              <span>
                <span translate>DONE_STATUS</span>
              </span>
            </span>
          </div>
        </div>
      </div>
    </ng-template>
    <!-- Empty message -->
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td colspan="7">
          {{ "NO_RECORDS_FOUND" | translate }}
        </td>
      </tr>
    </ng-template>
  </p-table>
  <!-- Table Ends-->
</div>

<div class="page-title">
  <span translate>CLEANING</span>
</div>
<div class="list">
  <table class="table table-striped list">
    <thead>
      <tr>
        <th translate>FILE_NAME</th>
        <th translate>STATUS</th>
      </tr>
    </thead>
    <tbody class="uploads">
      <tr
        class="file-item"
        *ngFor="let f of uploadedFiles | paginate : pgConfig; index as i"
      >
        <td>
          <span class="thumb-sm">
            <ecpl-checklist-viewer
              *ngIf="f.type == 'file'"
              [checklistName]="f.filename"
              [(checklistSrc)]="uploadedFiles[i]"
              [id]="f.nodeId"
              (saveChanges)="saveEmitter($event)"
              [editMode]="isEditMode"
            ></ecpl-checklist-viewer>
          </span>
          {{ f.filename }}
        </td>
        <td>
          <span translate *ngIf="f.latestCompletionStatus == 'UNDERCOMPLETION'"
            >STARTED_ON</span
          >
          <span translate *ngIf="f.latestCompletionStatus != 'UNDERCOMPLETION'"
            >LAST_PERFORMED</span
          >
          <span>: {{ f.latestPerformance | date : "dd-MM-yyyy" }}</span>
          <span
            class="badge"
            translate
            *ngIf="f.latestCompletionStatus == 'UNDERCOMPLETION'"
            [ngStyle]="{ 'background-color': '#f7941d' }"
            >UNDER_COMPLETION
          </span>
        </td>
      </tr>
    </tbody>
  </table>
  <pagination-controls
    *ngIf="uploadedFiles?.length > 0 && selectedPageSize != 0"
    (pageChange)="pgConfig.currentPage = $event"
    previousLabel="{{ 'PREVIOUS' | translate }}"
    nextLabel="{{ 'NEXT' | translate }}"
  >
  </pagination-controls>
  <div class="action-btns-wrapper" style="text-align: center">
    <p-dropdown
      *ngIf="uploadedFiles?.length > 0"
      [options]="pageSizeList"
      [(ngModel)]="selectedPageSize"
      (onChange)="onPageChanged($event)"
      [style]="{ width: '50px', float: 'right' }"
    ></p-dropdown>
  </div>
</div>
<toaster-component></toaster-component>
<div class="panel-loader" *ngIf="loading">
  <span>
    <span class="loader-icon"></span>
    <span translate>PROCESSING</span>
  </span>
</div>

<ecpl-confirm-viewer [file]="selectedFile" (closeEvent)="resetSelectedFile()">
</ecpl-confirm-viewer>
