import { FeatureKey, Rights } from 'src/app/_models';
import { Module } from 'src/app/kuba/follow-ups/models/deviation';
import { BusinessServices } from 'src/app/kuba/businesses/services/business.services';
import { EmployeeServices } from './../../employees/services/employee.services';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { ContactEdit } from './../../contact/models/contactEdit';
import { EmployeeRights } from './../../employees/models/employee';
import { UserService } from './../../users/services/user.service';
import { BaseServices } from './../../kuba.services';
import { SaveUsers, UserCredential } from './../../users/models/user';
import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { ContactFunctionalArea } from './../../contact/models/contactFunctionalArea';
import {
  ContactView,
  ContactWorkLocation,
} from './../../contact/models/contactView';
import { ContactServices } from './../../contact/services/contact.service';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
} from '@angular/forms';
import { EditorContactsServices } from './../services/editorcontacts.services';
import { Component, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { User } from '../../kunde-EL/models/user';
import { BusinessDepartmentService } from 'src/app/kuba/business-department.services';
import { Subscription } from 'rxjs';
import { HelperService } from 'src/app/_services/helper.service';
import { R } from '@fullcalendar/core/internal-common';
import { environment } from 'src/environments/environment';
@Component({
  templateUrl: 'new-editorcontacts.component.html',
})
export class NewEditorContactComponent implements OnInit {
  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  contactForm: FormGroup;
  categories: any;
  userRightsData: any = [];
  guestRightsData: any = [];
  editorRightsData: any = [];
  editedUser = false;
  editedGuest = false;
  editedEditor = false;
  userId: number;
  role: SelectItem[];
  workPlaceForm: FormGroup;
  userForm: FormGroup;
  functionForm: FormGroup;
  rightsData: any = [];
  addCategory: boolean;
  editTypes: any;
  contactFunctions: SelectItem[];
  addFunction = false;
  typeOfContact: any;
  functions: SelectItem[];
  functionId: number;
  contactId: number;
  featureKey: number;
  contactData = new ContactView();
  contactEdit = new ContactEdit();
  editFunction: any = [];
  CountryCode: SelectItem[];
  workLocationId: number;
  selectedFunction = new ContactFunctionalArea();
  showPassword: string;
  israndomPassword = false;
  viewFunction = false;
  cachedUserName: string;
  userNameValidation = false;
  isRightSelect: boolean;
  isNewUser = false;
  updatePasswordForm: FormGroup;
  userCredentials = new UserCredential();
  showPasswordResetDialog = false;
  cachedEmail: string;
  passwordValidation: boolean;
  passwordValidationSuccess: boolean;
  tempUserName: string;
  isNameExists: boolean;
  existsAlerttext: string;
  isfunctionExists = false;
  functionExisting: string;
  isPortalCreated = false;
  isHideSaveUserButton = true;
  departments: SelectItem[];
  businessId: number;
  isEditor = true;
  canViewUser: boolean;
  private subscriptions: Subscription[] = [];
  isMobile: boolean;
  hideEmployeeRights: boolean;
  dialogDisplay: boolean = false;

  constructor(
    private editorContactsServices: EditorContactsServices,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private contactService: ContactServices,
    private location: Location,
    private confirmationService: ConfirmationService,
    private userService: UserService,
    private businessDepartmentService: BusinessDepartmentService,

    public employeeServices: EmployeeServices,
    public businessServices: BusinessServices,
    private translate: TranslateService
  ) {
    this.contactForm = this.formBuilder.group({
      ContactTypeId: ['', Validators.required],
      Name: ['', Validators.required],
      Address: [''],
      Zipcode: [''],
      Location: [''],
      CountryCode: [2],
      Mobile: [''],
      Telephone: [''],
      Fax: [''],
      Email: ['', [ValidationService.emailValidator]],
      NotificationBySms: [''],
      NotificationByEmail: [''],
      Position: [''],
      Company: [''],
      workAddress: [''],
      workZipcode: [''],
      workLocation: [''],
      Website: ['http://', [ValidationService.websiteValidator]],
      Note: [''],
      Function: [''],
      NotifySuggestion: [''],
      showDashboard: [''],
      ShowToGuest: [''],
      IsEmailNewDeviation: [''],
    });
    this.functionForm = this.formBuilder.group({
      Name: [
        '',
        [Validators.required, ValidationService.noWhitespaceValidator],
      ],
    });
    if (BaseServices.roleId === '1' || BaseServices.roleId === '2') {
      this.businessId = this.route.snapshot.parent!.params['bid'];
    } else {
      this.businessId = BaseServices.BusinessId;
    }
    this.initForm();
  }

  ngOnInit() {
    this.isEditor = +BaseServices.roleId === 3;

    this.updatePasswordForm = this.formBuilder.group({
      passwordValidation: this.formBuilder.group(
        {
          Password: ['', [Validators.required]],
          ConfirmPassword: ['', [Validators.required]],
        },
        { validator: ValidationService.matchPassword }
      ),
    });
    this.role = [];
    this.role.push({ label: 'Select a Role', value: null });
    this.role.push({ label: 'Guest', value: 4 });
    this.role.push({ label: 'User', value: 5 });
    this.role.push({ label: 'Editor', value: 3 });

    this.subscriptions.push(
      this.translate.stream('SUB_CONTRACTOR_TYPES').subscribe((val) => {
        this.typeOfContact = [];
        this.typeOfContact.push(
          { label: val.SUB_CONTRACTOR_SELECT, value: null },
          { label: val.SUB_CONTRACTOR_PERSON, value: 1 },
          { label: val.SUB_CONTRACTOR_COMPANY, value: 2 }
        );
      })
    );
    this.editTypes = [];
    this.contactFunctions = [];
    this.editTypes.push({ label: 'Select', value: null });
    this.subscriptions.push(
      this.editorContactsServices
        .getContactFunctions()
        .subscribe((result: any) => {
          result.forEach((element: any) => {
            this.contactFunctions.push({
              label: element.Name,
              value: element.Id,
            });
            this.editTypes.push({ Name: element.Name, Id: element.Id });
          });
        })
    );
    this.functions = [];
    this.editFunction = [];
    this.functions.push({ label: 'Select Function', value: null });
    let cTypeOfConatct = this.route.snapshot.data['function'];
    if (cTypeOfConatct) {
      cTypeOfConatct.forEach((element: any) => {
        this.functions.push({ label: element.Name, value: element.Id });
        this.editFunction.push({ Name: element.Name, Id: element.Id });
      });
    }

    this.CountryCode = [];
    let btype = this.route.snapshot.data['dropdown'];
    if (btype) {
      btype.forEach((element: any) => {
        this.CountryCode.push({
          label: '+' + element.Phonecode,
          value: element.Id,
        });
      });
    }
    this.contactData = this.route.snapshot.data['edit'];
    if (this.contactData) {
      this.isPortalCreated = this.contactData.RoleId === 2;
      this.cachedEmail = this.contactData.Emailid;
    }
    if (this.contactData) {
      this.workLocationId = this.contactData.ContactWorkLocationId;
      this.contactId = this.contactData.Id;
      this.featureKey = this.contactData.Id;
      this.workLocationId = this.contactData.ContactWorkLocationId;

      let contactForm = {
        ContactTypeId: this.contactData.ContactTypeId
          ? this.contactData.ContactTypeId
          : 0,
        Name: this.contactData.Name ? this.contactData.Name : '',
        Address: this.contactData.Address ? this.contactData.Address : '',
        Zipcode: this.contactData.Zipcode ? this.contactData.Zipcode : '',
        Location: this.contactData.Location ? this.contactData.Location : '',
        CountryCode: Number(this.contactData.CountryCode)
          ? Number(this.contactData.CountryCode)
          : 2,
        Mobile: this.contactData.Mobile ? this.contactData.Mobile : '',
        Telephone: this.contactData.Telephone ? this.contactData.Telephone : '',
        Email: this.contactData.Emailid ? this.contactData.Emailid : '',
        Fax: this.contactData.Fax ? this.contactData.Fax : '',
        NotificationBySms: this.contactData.IsSMS
          ? this.contactData.IsSMS
          : false,
        NotificationByEmail: this.contactData.IsEmail
          ? this.contactData.IsEmail
          : false,
        Note: this.contactData.Notes,
        Function: this.contactData.ContactFunctionalAreaId
          ? this.contactData.ContactFunctionalAreaId
          : '',
        ShowToGuest: this.contactData.ShowToGuest
          ? this.contactData.ShowToGuest
          : false,
        showDashboard: this.contactData.ShowOnDashBoard
          ? this.contactData.ShowOnDashBoard
          : false,
        NotifySuggestion: this.contactData.SuggestionBoxNotification
          ? this.contactData.SuggestionBoxNotification
          : false,
        IsEmailNewDeviation: this.contactData.IsEmailNewDeviation
          ? this.contactData.IsEmailNewDeviation
          : false,
        Position: this.contactData.WorkPosition
          ? this.contactData.WorkPosition
          : '',
        Company: this.contactData.WorkLocationCompany
          ? this.contactData.WorkLocationCompany
          : '',
        workAddress: this.contactData.WorkLocationAddress
          ? this.contactData.WorkLocationAddress
          : '',
        workZipcode: this.contactData.WorkLocationZip
          ? this.contactData.WorkLocationZip
          : '',
        workLocation: this.contactData.WorkLocationName
          ? this.contactData.WorkLocationName
          : '',
        Website: this.contactData.WorkLocationWebSite
          ? this.contactData.WorkLocationWebSite
          : '',
      };
      (<FormGroup>this.contactForm).setValue(contactForm, { onlySelf: true });
    }
    this.UserExitence();
    this.initForm();
  }

  /**
   * bind function dropdown
   */
  bindFunctionDropdown() {
    this.subscriptions.push(
      this.contactService.getAllByFunction().subscribe((result) => {
        if (result) {
          this.functions = [];
          this.functions.push({
            label: 'Select a Function',
            value: null,
          });
          result.forEach((element: any) => {
            this.functions.push({
              label: element.Name,
              value: element.Id,
            });
          });
          this.editFunction = result;
        }
      })
    );
  }
  /**
   * edit function popup
   */
  editFunctions(contactFunction: ContactFunctionalArea) {
    this.functionId = contactFunction.Id;
    let result = {
      Name: contactFunction.Name,
    };
    (<FormGroup>this.functionForm).setValue(result, { onlySelf: true });
  }

  /**
   * add and update function
   */
  saveFunction() {
    let contactFunction = new ContactFunctionalArea();
    contactFunction.Id = this.functionId ? this.functionId : 0;
    contactFunction.Name = this.functionForm.value.Name;
    contactFunction.ApplicationId = BaseServices.ApplicationId;
    contactFunction.Status = 1;
    contactFunction.CreatedBy = BaseServices.UserId;
    if (contactFunction.Id > 0) {
      contactFunction.ModifiedBy = BaseServices.UserId;
      this.subscriptions.push(
        this.contactService
          .updateFunction(contactFunction)
          .subscribe((functionEdited: any) => {
            if (functionEdited) {
              this.bindFunctionDropdown();
              this.toasterComponent.callToast();
              this.contactForm.get('Function')!.patchValue(functionEdited.Id);
              this.addFunction = false;
              this.functionId = 0;
            } else {
              this.isNameExists = true;
              this.existsAlerttext = this.translate.instant(`TOPIC_EXIST`);
              setTimeout(() => {
                this.isNameExists = false;
              }, 3000);
            }
          })
      );
      this.selectedFunction = new ContactFunctionalArea();
    } else {
      this.subscriptions.push(
        this.contactService
          .createFunction(contactFunction)
          .subscribe((functionAdded: any) => {
            if (functionAdded) {
              this.bindFunctionDropdown();
              this.toasterComponent.callToast();
              this.contactForm.get('Function')!.patchValue(functionAdded.Id);
              this.addFunction = false;
            } else {
              this.isNameExists = true;
              this.existsAlerttext = this.translate.instant(`TOPIC_EXIST`);
              setTimeout(() => {
                this.isNameExists = false;
              }, 3000);
            }
          })
      );
    }
    this.functionForm.reset();
    this.clearFunction();
  }


  /**
   * clear function
   */
  clearFunction() {
    this.functionId = 0;
    this.functionForm = this.formBuilder.group({
      Name: '',
    });
  }

  /**
   * delete function
   * @param value
   */
  deleteFunction(functionId: any) {
    this.subscriptions.push(
      this.contactService
        .checkExistingFunction(functionId)
        .subscribe((result: any) => {
          if (result) {
            this.isfunctionExists = true;
            this.functionExisting =
              'You cannot delete this Function / Role as it is being used by another Contact';
            setTimeout(() => {
              this.isfunctionExists = false;
            }, 3000);
          } else {
            this.confirmationService.confirm({
              message: this.translate.instant('DELETE_THIS_RECORD'),
              accept: () => {
                this.subscriptions.push(
                  this.contactService.deleteType(functionId).subscribe(() => {
                    this.toasterComponent.callToastDlt();
                    this.bindFunctionDropdown();
                  })
                );
              },
            });
          }
        })
    );
  }
  /**
   * add and update contact
   */
  submitForm(saveConteact: boolean) {
    this.tempUserName = this.contactForm.value.Email;
    if (this.contactForm.valid) {
      this.contactEdit.Id = this.contactId ? this.contactId : 0;
      this.contactEdit.ContactTypeId = this.contactForm.value.ContactTypeId;
      this.contactEdit.Name = this.contactForm.value.Name;
      this.contactEdit.BusinessId = BaseServices.BusinessId;
      this.contactEdit.Address = this.contactForm.value.Address;
      this.contactEdit.Zipcode = this.contactForm.value.Zipcode;
      this.contactEdit.Location = this.contactForm.value.Location;
      this.contactEdit.CountryCode = this.contactForm.value.CountryCode;
      this.contactEdit.Mobile = this.contactForm.value.Mobile;
      this.contactEdit.Telephone = this.contactForm.value.Telephone;
      this.contactEdit.Emailid = this.contactForm.value.Email;
      this.contactEdit.CreatedBy = BaseServices.UserId;
      this.contactEdit.IsSMS = this.contactForm.value.NotificationBySms;
      this.contactEdit.IsEmail = this.contactForm.value.NotificationByEmail;
      this.contactEdit.ShowInBusiness = window.name === 'Remote' ? false : true;
      this.contactEdit.Fax = this.contactForm.value.Fax;
      this.contactEdit.WorkPosition = this.contactForm.value.Position;
      this.contactEdit.Notes = this.contactForm.value.Note;
      this.contactEdit.ContactFunctionalAreaId =
        this.contactForm.value.Function;
      this.contactEdit.SuggestionBoxNotification =
        this.contactForm.value.NotifySuggestion;
      this.contactEdit.ShowOnDashBoard = this.contactForm.value.showDashboard;
      this.contactEdit.ShowToGuest = this.contactForm.value.ShowToGuest;
      this.contactEdit.IsEmailNewDeviation =
        this.contactForm.value.IsEmailNewDeviation;
      this.contactEdit.Status = 1;
      let workplace = new ContactWorkLocation();
      workplace.Id = 0; // new work location
      workplace.ContactId = this.contactId ? this.contactId : 0; // new contact
      workplace.Address = this.contactForm.value.workAddress;
      workplace.Company = this.contactForm.value.Company;
      workplace.Location = this.contactForm.value.workLocation;
      workplace.Zipcode = this.contactForm.value.workZipcode;
      workplace.Website = this.contactForm.value.Website;
      this.contactEdit.ContactWorkLocation = workplace;
      if (this.contactEdit.Id > 0) {
        this.subscriptions.push(
          this.contactService
            .update(this.contactEdit)
            .subscribe((result: any) => {
              this.featureKey = result.Id;
              this.toasterComponent.callToast();
              this.clearContact();
            })
        );
      } else {
        // add new work location details
        this.subscriptions.push(
          this.contactService.add(this.contactEdit).subscribe((result: any) => {
            if (result) {
              this.featureKey = result.Id;
              this.toasterComponent.callToast();
              this.clearContact();
              this.contactId = result.Id;
              if (saveConteact) {
                this.routeToNewContact(result.Id);
              }
            }
          })
        );
      }
    }
  }

  /**
   * Method routes to the new contact to its edit page
   * @param contactId {number} contact id
   * @returns {void}
   * @memberof NewEditorContactComponent
   * @description Method routes to the new contact to its edit page.
   */
  routeToNewContact(contactId: number) {
    this.router.navigate([`../edit/${contactId}`], {
      relativeTo: this.route,
    });
  }

  /**
   * Method does validation on the mobile number field. If validation is passed, the isMobile flag is set to true to unlock the "Save and send SMS button"
   * @param e {any} event
   * @param limitNumber {number} limit of the mobile number
   * @returns {void}
   * @memberof NewEditorContactComponent
   * @description Method does validation on the mobile number field. If validation is passed, the isMobile flag is set to true to unlock the "Save and send SMS button".
   */
  onMobileNumber(e: any, limitNumber: number) {
    const limit = limitNumber;
    let charCode = e.which ? e.which : e.keyCode;
    if (
      e.target.value.length === limit ||
      (charCode > 31 && (charCode < 48 || charCode > 57))
    ) {
      e.preventDefault();
    } else {
      this.isMobile = true;
    }
  }
  /**
   * go  back to list
   */
  gotoList() {
    this.location.back();
  }
  /**
   * Initialize the user form
   * @param data {any}
   */
  initForm(data?: any) {
    let Id: number;
    let Name = '';
    let Email = '';
    let Username = '';
    let Password = '';
    let RoleId = 5;
    let Status = '';
    let CountryCode = 47;
    let Mobile = '';
    let NotificationBySms = '';
    let ConfirmPassword = '';
    if (data) {
      Id = data.Id ? +data.Id : 0;
      Name = data.Name ? data.Name : '';
      Email = data.Email ? data.Email : '';
      Username = data.Username ? data.Username : '';
      Password = data.Password ? data.Password : '';
      ConfirmPassword = data.Password ? data.Password : '';
      RoleId = data.RoleId ? +data.RoleId : RoleId;
      Status = data.Status ? data.Status : '1';
      CountryCode = data.CountryCode ? data.CountryCode : '';
      Mobile = data.Mobile ? data.Mobile : '';
      NotificationBySms = data.SendUserData ? data.SendUserData : '';
      if (RoleId === 3) {
        // editor
        this.rightsData = data.Rights
          ? JSON.parse(data.Rights)
          : this.editorRightsData;
      } else if (RoleId === 4) {
        // guest
        this.rightsData = data.Rights
          ? JSON.parse(data.Rights)
          : this.guestRightsData;
      } else if (RoleId === 5) {
        // user
        this.rightsData = data.Rights
          ? JSON.parse(data.Rights)
          : this.userRightsData;
      }
    }

    let allCategories: FormArray | any = new FormArray([]);
    let gCategories: FormArray | any = new FormArray([]);
    let eCategories: FormArray | any = new FormArray([]);
    this.userForm = new FormGroup({
      Id: new FormControl(Id!),
      Name: new FormControl(Name),
      Email: new FormControl(Email, [Validators.required, Validators.email]),
      Username: new FormControl(
        Username,
        [Validators.required],
        this.isUserNameUnique.bind(this)
      ),
      passwordValidation: this.formBuilder.group(
        {
          Password: [Password, [Validators.required]],
          ConfirmPassword: [Password, [Validators.required]],
        },
        { validator: ValidationService.matchPassword }
      ),
      RoleId: new FormControl(RoleId),
      Status: new FormControl(Status),
      CountryCode: new FormControl(CountryCode),
      Mobile: new FormControl(Mobile),
      NotificationBySms: new FormControl(NotificationBySms),
      userCategories: allCategories,
      guestCategories: gCategories,
      editorCategories: eCategories,
    });
    if (RoleId > 0) {
      this.setRoleVisiblity(RoleId);
    }
    let businessFeatures = BaseServices.userFeatures;
    if (window.name === 'Remote') {
      this.subscriptions.push(
        this.businessServices
          .getById(BaseServices.BusinessId)
          .subscribe((result) => {
            if (result) {
              businessFeatures = <any>JSON.parse(result.Features);
            }
          })
      );
    }
    this.bindRightsCheckbox(data);
  }

  onRoleDropDownChanged(e: any) {
    this.setRoleVisiblity(e.value);
  }

  onRightsCheckboxChange(e: any, rightsId: any, rightCategory: any) {
    if (this.rightsData) {
      _.each(this.rightsData, (x: EmployeeRights) => {
        if (x.id === rightsId) {
          x.isChecked = e.target.checked;
        }
      });
    }
  }

  arrayCompare(list: any, key: number) {
    let isMatched = false;
    let dataList = JSON.parse(list || null);
    if (dataList && dataList.length > 0) {
      for (let index = 0; index < dataList.length; index++) {
        let element = dataList[index];
        if (element.id === key) {
          return element.isChecked;
        }
      }
    }
    return isMatched;
  }

  setRoleVisiblity(val: number) {
    if (val === 3) {
      // editor
      this.rightsData = this.editorRightsData;
      this.editedEditor = true;
      this.editedGuest = false;
      this.editedUser = false;
      this.userForm.get('guestCategories')!.reset();
      this.userForm.get('userCategories')!.reset();
    }
    if (val === 4) {
      // guest
      this.editedGuest = true;
      this.editedEditor = false;
      this.editedUser = false;
      this.rightsData = this.guestRightsData;
      this.userForm.get('editorCategories')!.reset();
      this.userForm.get('userCategories')!.reset();
    }
    if (val === 5) {
      // user
      this.editedUser = true;
      this.editedEditor = false;
      this.editedGuest = false;
      this.rightsData = this.userRightsData;
      this.userForm.get('editorCategories')!.reset();
      this.userForm.get('guestCategories')!.reset();
    }
  }
  bindRightsCheckbox(data?: any) {
    this.userRightsData = [
      {
        id: '1',
        name: 'ACTIVE_ACCOUNT',
        isChecked: true,
        rightsType: 1,
      },
      {
        id: '2',
        name: 'EDIT_MY_PAGE',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '3',
        name: 'EDIT_MANUALS',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '4',
        name: 'ADMINISTRATE_ACTIVITIES',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '5',
        name: 'ADMINISTRATE_RISK_ANALYSIS',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '6',
        name: 'ADMINISTRATE_PROJECTS',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '7',
        name: 'ADMINISTRATE_FDV',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '8',
        name: 'ADMINISTRATE_INTERNAL_CONTROL',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '9',
        name: 'ADMINISTRATE_INTERNAL_NEWS',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '10',
        name: 'APPROVE_ABSENCE',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '11',
        name: 'APPROVE_LEAVE',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '12',
        name: 'APPROVE_TIMETABLE',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '13',
        name: 'ADD_EMPLOYEE',
        isChecked: false,
        rightsType: 2,
      },
      {
        id: '14',
        name: 'DEPARTMENT_EMPLOYEE_RIGHTS',
        isChecked: false,
        rightsType: 2,
      },
      {
        id: '15',
        name: 'HIDE_EMPLOYEE_LIST',
        isChecked: false,
        rightsType: 2,
      },
      {
        id: '16',
        name: 'ADMINISTRATE_DEVIATION',
        isChecked: false,
        rightsType: 3,
      },
      {
        id: '17',
        name: 'VIEW_DEVIATION',
        isChecked: false,
        rightsType: 3,
      },
      {
        id: '18',
        name: 'DISPLAY_OWN_DEVIATIONS',
        isChecked: false,
        rightsType: 3,
      },
      {
        id: '19',
        name: 'DEVIATIONS_OWN_DEPARTMENT',
        isChecked: false,
        rightsType: 3,
      },
      {
        id: '48',
        name: 'ADMINISTRATE_OTHER_SYSTEMS',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '49',
        name: 'ADMINISTRATE_QUALITY_SYSTEM',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '51',
        name: 'ADMINISTRATE_KUBA_CONTROL',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '38',
        name: 'ADMINISTRATE_FOOD_SAFETY',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '52',
        name: 'ADMINISTRATE_FLEXI',
        isChecked: false,
        rightsType: 1,
      },
    ];

    const userControl = <FormArray>this.userForm.controls['userCategories'];
    for (let i = 0; i < this.userRightsData.length; i++) {
      let fg = new FormGroup({});
      fg.addControl(
        `${this.userRightsData[i].id}`,
        new FormControl(
          data && data.Rights
            ? this.arrayCompare(data.Rights, this.userRightsData[i].id)
            : this.userRightsData[i].isChecked
        )
      );
      userControl.push(fg);
    }
    this.guestRightsData = [
      {
        id: '1',
        name: 'ACTIVE_ACCOUNT',
        isChecked: true,
        rightsType: 1,
      },
      {
        id: '20',
        name: 'DEVIATION_RIGHTS',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '21',
        name: 'VIEW_MANUALS',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '15',
        name: 'HIDE_EMPLOYEE_LIST',
        isChecked: false,
        rightsType: 2,
      },
      {
        id: '17',
        name: 'VIEW_DEVIATION',
        isChecked: false,
        rightsType: 3,
      },
      {
        id: '18',
        name: 'DISPLAY_OWN_DEVIATIONS',
        isChecked: false,
        rightsType: 3,
      },
      {
        id: '19',
        name: 'DEVIATIONS_OWN_DEPARTMENT',
        isChecked: false,
        rightsType: 3,
      },
    ];

    const guestcontrol = <FormArray>this.userForm.controls['guestCategories'];
    for (let j = 0; j < this.guestRightsData.length; j++) {
      let ft = new FormGroup({});
      ft.addControl(
        `${this.guestRightsData[j].id}`,
        new FormControl(
          data && data.Rights
            ? this.arrayCompare(data.Rights, this.guestRightsData[j].id)
            : this.guestRightsData[j].isChecked
        )
      );
      guestcontrol.push(ft);
    }

    this.editorRightsData = [
      {
        id: '1',
        name: 'ACTIVE_ACCOUNT',
        isChecked: true,
        rightsType: 1,
      },
      {
        id: '10',
        name: 'APPROVE_ABSENCE',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '11',
        name: 'APPROVE_LEAVE',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '12',
        name: 'APPROVE_TIMETABLE',
        isChecked: false,
        rightsType: 1,
      },
    ];

    const editorControl = <FormArray>this.userForm.controls['editorCategories'];
    for (let k = 0; k < this.editorRightsData.length; k++) {
      let fk = new FormGroup({});
      fk.addControl(
        `${this.editorRightsData[k].id}`,
        new FormControl(
          data && data.Rights
            ? this.arrayCompare(data.Rights, this.editorRightsData[k].id)
            : this.editorRightsData[k].isChecked
        )
      );

      editorControl.push(fk);
    }
  }

  saveUserAndProceed() {
    this.submitForm(false);
    let userData = {
      Id: this.userId ? this.userId : 0,
      Name: this.contactForm.value.Name,
      Email: this.contactForm.value.Email,
      Username: this.contactForm.value.Email,
      CountryCode: this.contactForm.value.CountryCode,
      Mobile: this.contactForm.value.Mobile,
      RoleId: this.contactForm.value.RoleId,
    };
    if (this.contactId > 0 && this.contactId != null) {
      this.editorContactsServices
        .getContactUser(this.contactId)
        .subscribe((user: any) => {
          if (user) {
            this.router.navigate(
              [
                `../../business/edit/` +
                  BaseServices.BusinessId +
                  `/user/edit/` +
                  user.Id,
              ],
              { relativeTo: this.route }
            );
          }
           else {
            this.isNewUser = true;
            this.initForm(userData);
            this.addCategory = true;
          }
        });
    } else {
      this.isNewUser = true;
      this.initForm(userData);
      this.addCategory = true;
    }
    this.bindDepartment();
  }
  // }
  /**
   * generating random password
   */
  generateRandomPassword() {
    this.israndomPassword = true;
    let randomstring = Math.random().toString(36).slice(-8);
    this.showPassword = randomstring;
    this.userForm.patchValue({
      passwordValidation: {
        Password: randomstring,
        ConfirmPassword: randomstring,
      },
    });
  }
  /**
   * create user
   */
  saveUser() {
    let Users = new SaveUsers();
    Users.Id = this.userId ? this.userId : 0;
    Users.Name = this.userForm.value.Name;
    Users.Email = this.userForm.value.Email;
    Users.Username = this.userForm.value.Username;
    Users.RoleId = this.userForm.value.RoleId;
    Users.Mobile = this.userForm.value.Mobile;
    Users.CountryCode = this.userForm.value.CountryCode;
    Users.Status = '1';
    Users.FeatureId = Module.CONTACT;
    Users.FeatureKey = this.featureKey ? this.featureKey : 0;
    Users.ApplicationId = BaseServices.ApplicationId;
    Users.Password = this.userForm.value.passwordValidation.Password;
    Users.BusinessId = BaseServices.BusinessId;
    Users.CreatedBy = BaseServices.UserId;
    Users.SendUserData = this.userForm.value.NotificationBySms;
    if (window.name === 'Remote' && this.userId === 0) {
      Users.LanguageId = 2; // default language Norsk
    } else {
      Users.LanguageId = BaseServices.userLanguageId;
    }
    if (this.rightsData && this.rightsData.length > 0) {
      Users.Rights = JSON.stringify(this.rightsData);
    } else {
      Users.Rights = null!;
    }
    if (Users.Id > 0) {
      this.subscriptions.push(
        this.userService.updateUser(Users.Id, Users).subscribe((result) => {
          if (result) {
            this.toasterComponent.callToast();
          }
        })
      );
    } else {
      this.subscriptions.push(
        this.userService.addusers(Users).subscribe((result) => {
          if (result) {
            this.userService.clearCachedUserList();
            this.toasterComponent.callToast();
          }
        })
      );
    }
    this.clearUserForm();
    this.addCategory = false;
    this.router.navigate([`../edit/${this.contactId}`], {
      relativeTo: this.route,
    });
  }

  clearUserForm() {
    this.userForm.reset();
  }

  clearUserName() {
    this.userForm.get('Username')!.patchValue('');
  }

  refreshUsername() {
    if (this.cachedUserName) {
      this.userForm.get('Username')!.patchValue(this.cachedUserName);
    } else {
      this.userForm.get('Username')!.patchValue(this.contactEdit.Emailid);
    }
  }

  /**
   * user name validation
   */
  isUserNameUnique(control: FormControl) {
    const q = new Promise((resolve) => {
      setTimeout(() => {
        if (control.value === this.cachedUserName) {
          resolve(null);
        } else {
          let AppId = BaseServices.ApplicationId;
          this.subscriptions.push(
            this.employeeServices.getUserName(control.value).subscribe(
              (x) => {
                if (!x) {
                  resolve(null);
                } else {
                  resolve({ invalidUserNameWithId: true });
                }
              },
              () => {
                resolve({ invalidUserNameWithId: true });
              }
            )
          );
        }
      }, 1000);
    });
    return q;
  }

  close() {
    this.userNameValidation = false;
  }
  showDialog() {
    this.dialogDisplay = true;
  }

  /**
   * mobile number validation
   * @param e {any}
   * @param limitNumber {any}
   */
  onNumberChange(e: any, limitNumber: any) {
    HelperService.numberFieldValidation(e, limitNumber);
  }

  /**
   * closing the popup
   */
  saveAndClose() {
    this.saveUser();
    this.addCategory = false;
  }
  clear() {
    this.rightsData = [''];
    this.userForm.value.categories = [''];
    (<FormGroup>this.userForm).setValue(this.categories, {
      onlySelf: true,
    });
  }
  clearContact() {
    this.contactForm = this.formBuilder.group({
      ContactTypeId: ['', Validators.required],
      Name: ['', Validators.required],
      Address: [''],
      Zipcode: [''],
      Location: [''],
      CountryCode: [2],
      Mobile: [''],
      Telephone: [''],
      Fax: [''],
      Email: ['', [ValidationService.emailValidator]],
      NotificationBySms: [''],
      NotificationByEmail: [''],
      Position: [''],
      Company: [''],
      workAddress: [''],
      workZipcode: [''],
      workLocation: [''],
      Website: ['http://', [ValidationService.websiteValidator]],
      Note: [''],
      Function: [''],
      NotifySuggestion: [''],
      showDashboard: [''],
      ShowToGuest: [''],
      IsEmailNewDeviation: [''],
    });
  }
  onFuctionFormHide() {
    this.functionForm.reset();
  }
  resetPassWord() {
    this.userCredentials.Id = this.userId;
    this.userCredentials.Password =
      this.updatePasswordForm.value.passwordValidation.Password;
    this.userCredentials.Password =
      this.updatePasswordForm.value.passwordValidation.ConfirmPassword;
    this.subscriptions.push(
      this.businessServices
        .updatePassword(this.userCredentials)
        .subscribe((result) => {
          if (result === false) {
            this.passwordValidation = true;
            setTimeout(() => {
              this.passwordValidation = false;
            }, 3000);
          } else {
            this.toasterComponent.callToast();
          }
        })
    );
  }
  closeResetPassword() {
    this.showPasswordResetDialog = false;
    this.updatePasswordForm.reset();
  }
  showResetPassword() {
    this.showPasswordResetDialog = true;
  }
  /**
   * user name validation
   */
  isPasswordMatch() {
    const q = new Promise((resolve) => {
      setTimeout(() => {
        {
          resolve({ invalidPassWord: true });
        }
      }, 1000);
    });
    return q;
  }
  isEmailUnique(control: FormControl) {
    const q = new Promise((resolve) => {
      setTimeout(() => {
        if (control.value === '' || control.value === this.cachedEmail) {
          resolve(null);
        } else {
          this.subscriptions.push(
            this.editorContactsServices.checkEmail(control.value).subscribe(
              (x: any) => {
                if (!x) {
                  resolve(null);
                } else {
                  resolve({ isEmailUnique: true });
                }
              },
              () => {
                resolve({ isEmailUnique: true });
              }
            )
          );
        }
      }, 1000);
    });
    return q;
  }
  UserExitence() {
    if (this.contactId) {
      this.subscriptions.push(
        this.userService
          .getUserExits(Module.CONTACT, this.contactId)
          .subscribe((res: any) => {
            if (res) {
              this.isHideSaveUserButton = false;
              this.canViewUser = true;
            }
          })
      );
    } else {
      this.isHideSaveUserButton = true;
    }
  }
  saveAndSendSMS() {
    let Users = new SaveUsers();
    Users.Id = this.userId ? this.userId : 0;
    Users.Name = this.userForm.value.Name;
    Users.Email = this.userForm.value.Email;
    Users.Username = this.userForm.value.Username;
    Users.RoleId = this.userForm.value.RoleId;
    Users.Mobile = this.userForm.value.Mobile;
    Users.CountryCode = this.userForm.value.CountryCode;
    Users.Status = '1';
    Users.FeatureId = Module.CONTACT;
    Users.FeatureKey = this.featureKey ? this.featureKey : 0;
    Users.ApplicationId = BaseServices.ApplicationId;
    Users.Password = this.userForm.value.passwordValidation.Password;
    Users.BusinessId = BaseServices.BusinessId;
    Users.SendUserData = this.userForm.value.NotificationBySms;
    if (this.rightsData && this.rightsData.length > 0) {
      Users.Rights = JSON.stringify(this.rightsData);
    } else {
      Users.Rights = null!;
    }
    if (Users.Id > 0) {
    } else {
      this.subscriptions.push(
        this.businessServices.addUsersAndSendSMS(Users).subscribe((result) => {
          if (result) {
            this.userService.clearCachedUserList();
            this.toasterComponent.callToast();
            this.routeToNewContact(this.contactId);
          }
        })
      );
    }
    this.addCategory = false;
  }

  // refresh the department dropdown
  bindDepartment() {
    this.subscriptions.push(
      this.businessServices
        .businessDepartments(BaseServices.BusinessId)
        .subscribe((department: any) => {
          if (department) {
            department = department.filter((x: any) => x.ParentId === null);
            this.departments = [];
            department.forEach((element: any) => {
              this.departments.push({
                label: element.Name,
                value: element.Id,
              });
            });
          }
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}
