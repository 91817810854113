import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EcplConfirmViewerService } from './ecpl-confirm-viewer.service';
import { Tasklist } from 'src/app/kuba/task-manager/models';

@Component({
  selector: 'ecpl-confirm-viewer-list',
  templateUrl: './ecpl-confirm-viewer-list.component.html',
  styleUrls: ['./ecpl-confirm-viewer-list.component.css']
})
export class EcplConfirmViewerListComponent implements OnInit {
   
    task: Tasklist;
    isChecklist: boolean;
    @Output() fileSelected = new EventEmitter<any>();
    @Input() confirmAction: (taskId: number, taskChecklistId?: number) => void;
    

    constructor(
        private dialogConfig: DynamicDialogConfig,
        private dialogRef: DynamicDialogRef,
        private ecplConfirmViewerService: EcplConfirmViewerService,
    ) {}

    ngOnInit() {
        this.task = this.dialogConfig.data?.task;
        this.confirmAction = this.dialogConfig.data?.confirmAction;
        this.isChecklist = this.dialogConfig.data?.isChecklist;
    }
    
    onFileClick(file: Tasklist) {
        this.fileSelected.emit(file);
        this.dialogRef.close(file);
    }

    getFileExtension(filename: string) {
        return this.ecplConfirmViewerService.getFileExtension(filename);
    }

    humanizeType(mimetype: string) {
        return this.ecplConfirmViewerService.humanizeType(mimetype);
    }

    closeDialog() {
        this.dialogRef.close();
    }
    
    onConfirm() {
        this.confirmAction(this.task.Id, this.task.ChecklistId); 
        this.dialogRef.close();
    }

}
