import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToasterComponent } from './../../../_directives/toaster.component';
import { ActivityServices } from '../services/activities.services';
import { SelectItem, ConfirmationService, LazyLoadEvent } from 'primeng/api';
import { HelperService } from 'src/app/_services/helper.service';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { FeatureKey, Rights } from 'src/app/_models';
import { TranslateService } from '@ngx-translate/core';
import { ActivityList } from '../models/riskanalysis';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
@Component({
  selector: 'activity-list',
  templateUrl: 'activity-list.component.html',
  styleUrls: ['activity-list.component.scss'],
})
export class ActivityListComponent implements OnInit {
  //#region variables

  @ViewChild('activityTable', { static: false }) activityTable: Table;
  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  public activityList: any | ActivityList[];
  repetitions: any;
  showActivities: any;
  vresperson: SelectItem[];
  businesses: SelectItem[];
  doneOn: any;
  follows: any[];
  ActivityForm: FormGroup;
  isShowActivity = true;
  enablePaginator = true;
  activitylist: any = [];
  vDepartment: SelectItem[];
  portalContacts: SelectItem[];
  statuses: SelectItem[];
  isAdministrateActivity: boolean;
  isDisableDeleteIcon = true;
  additionalData: any;
  totalRecords = 0;
  isAdmin = true;
  isNewButtonEnable: boolean;
  isNotAdmin = false;
  roleId: string;
  isBusiness = false;
  startDate: string;
  endDate: string;
  totalItems: number;
  originalActivity: any;
  userId: number;
  newActivityTitle: string;
  activitylistArr: any[];
  showFilter = false;
  responsibleFollowUpFilter: any = null;
  repetitionFilter: any = null;
  doneOnFilter: any = null;
  fromDateFilter: any = null;
  endDateFilter: any = null;
  iscreatedToFilter = true;
  rowsPerPageOptions: any = [];
  saveButtonClicked: boolean;
  selectedBusiness: any = [];
  currentBusinessId: number;
  public startDateOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
    editableDateField: false,
    openSelectorOnInputClick: true,
    firstDayOfWeek: 'su',
    satHighlight: true,
    selectionTxtFontSize: '14px',
    height: '32px',
    todayBtnTxt: this.translate.instant('TODAY'),
    dayLabels: {
      su: this.translate.instant('SUN'),
      mo: this.translate.instant('MON'),
      tu: this.translate.instant('TUE'),
      we: this.translate.instant('WED'),
      th: this.translate.instant('THU'),
      fr: this.translate.instant('FRI'),
      sa: this.translate.instant('SAT'),
    },
    monthLabels: {
      1: this.translate.instant('JANUARY'),
      2: this.translate.instant('FEBRUARY'),
      3: this.translate.instant('MARCH'),
      4: this.translate.instant('APRIL'),
      5: this.translate.instant('MAY'),
      6: this.translate.instant('JUNE'),
      7: this.translate.instant('JULY'),
      8: this.translate.instant('AUGUST'),
      9: this.translate.instant('SEPTEMBER'),
      10: this.translate.instant('OCTOBER'),
      11: this.translate.instant('NOVEMBER'),
      12: this.translate.instant('DECEMBER'),
    },
    disableSince: this.getCurrentDate(),
  };
  displayDialog = false;
  displayDialogForRecurring = false;
  newActivityForm: FormGroup;
  loading = false;
  recurringActivityId = 0;
  IsFutureRecurring: boolean;
  public endDateOptions: IMyDpOptions = this.startDateOptions;
  isDisableShowActivity = false;
  totalrecords: number;
  private subscriptions: Subscription[] = [];
  isPortal: boolean = true;
  isEditor: boolean = true;
  showSave = false;
  selectedBusinessId: any;
  isPortalAccess: boolean;
  userrights: any;
  myactivity: boolean = false;
  selectedActivity: string;
  //#endregion

  //#region contructor
  /**
   * constructor
   * @param activityService {ActivityServices}
   * @param route {ActivatedRoute}
   * @param router {Router}
   * @param _fb {FormBuilder}
   * @param confirmationService {ConfirmationService}
   */
  constructor(
    private activityService: ActivityServices,
    private route: ActivatedRoute,
    public router: Router,
    public _fb: FormBuilder,
    private confirmationService: ConfirmationService,
    private translate: TranslateService
  ) {
    this.userId = BaseServices.UserId;
    this.ActivityForm = this._fb.group({
      DoneOn: [''],
      FollowupUserName: [''],
    });
    this.subscriptions.push(
      this.translate.stream('FILENAME').subscribe((val) => {
        if (BaseServices.roleId == '2') {
          this.additionalData = {
            fileName: val.ACTIVITY_LIST,
            header: 'Activity List',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
              { title: 'Activity Number', dataKey: 'No' },
              { title: 'Title', dataKey: 'Title' },
              { title: 'Status', dataKey: 'Status' },
              { title: 'Repetitions', dataKey: 'repetitions' },
              { title: 'Department', dataKey: 'Department' },
              { title: 'Description', dataKey: 'Description' },
              { title: 'Execution UserName', dataKey: 'ExecutionUserList' },
              { title: 'Followup UserName', dataKey: 'FollowupUserList' },
              { title: 'Deadline', dataKey: 'EndDateForPdf' },
              { title: 'Business', dataKey: 'BusinessName' },
              { title: 'Created On', dataKey: 'CreatedOnForPdf' },
              { title: 'Done On', dataKey: 'CompletedDate' },
            ],
          };
        } else {
          this.additionalData = {
            fileName: val.ACTIVITY_LIST,
            header: 'Activity List',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
              { title: 'Activity Number', dataKey: 'No' },
              { title: 'Title', dataKey: 'Title' },
              { title: 'Status', dataKey: 'Status' },
              { title: 'Repetitions', dataKey: 'repetitions' },
              { title: 'Department', dataKey: 'Department' },
              { title: 'Description', dataKey: 'Description' },
              { title: 'Execution UserName', dataKey: 'ExecutionUserList' },
              { title: 'Followup UserName', dataKey: 'FollowupUserList' },
              { title: 'Deadline', dataKey: 'EndDateForPdf' },
              { title: 'Created On', dataKey: 'CreatedOnForPdf' },
              { title: 'Done On', dataKey: 'CompletedDate' },
            ],
          };
        }
      })
    );
    this.newActivityTitle = this.translate.instant('NEW_ACTIVITY');
    this.newActivityForm = this._fb.group({
      Status: this.newActivityTitle,
      Title: ['', Validators.required],
      Description: [''],
    });
    this.saveButtonClicked = false;
  }

  //#endregion

  //#region page-event
  /**
   * binding dropdowns and list of activities
   */
  ngOnInit(): void {
    document.querySelector('body').classList.remove('opened');
    this.loading = true;
    // this.subscriptions.push(this.route.queryParams
    //     .filter((params:any) => params.prop)
    //     .subscribe(params => {
    //         if (params.prop === 'new') {
    //             this.displayDialog = true;
    //         }
    //     }));
    this.bindDetails(BaseServices.BusinessId);
    this.SelectedActivityList();
    this.displayDialog = false;
    if (this.activitylist != null) {
      this.totalrecords = this.activitylist.length;
    }
    if (BaseServices.roleId !== '1' && BaseServices.roleId !== '2') {
      if (this.activitylist) {
        this.subscriptions.push(
          this.activityService
            .getActivityResponsible(+BaseServices.BusinessId)
            .subscribe((res: any) => {
              this.activitylist.forEach((element: any) => {
                if (element.Status !== 'NEW') {
                  element.ExecutionUserIdList = res.activity_Execution
                    .filter((x: any) => x.ActivityId === element.Id)
                    .map(({ UserId }: any) => UserId);
                  element.FollowupUserIdList = res.activity_FollowUp
                    .filter((x: any) => x.ActivityId === element.Id)
                    .map(({ UserId }: any) => UserId);
                }
              });
            })
        );
      }
    }
    this.bindBusiness();

    if (BaseServices.roleId === '1') {
      this.isDisableDeleteIcon = false;
      this.isAdmin = true;
      this.isNewButtonEnable = false;
      this.isNotAdmin = false;
      this.isShowActivity = false;
      this.isBusiness = true;
    } else if (BaseServices.roleId === '2') {
      this.isDisableDeleteIcon = false;
      this.isNotAdmin = true;
      this.isNewButtonEnable = true;
      this.isAdmin = false;
      this.isBusiness = true;
      this.isShowActivity = false;
    } else if (BaseServices.roleId === '5') {
      let currentUserRole = BaseServices.UserRole;
      let userRightsId = Rights.ADMINISTRATE_ACTIVITIES;
      this.isNewButtonEnable = BaseServices.checkUserRights(
        userRightsId,
        currentUserRole
      );
    } else {
      this.isNewButtonEnable = BaseServices.roleId === '3' ? true : false;
      this.isNotAdmin = true;
    }
    if (BaseServices.roleId === '5') {
      this.isDisableDeleteIcon = false;
    }
    if (window.name === 'Remote') {
      this.isDisableShowActivity = true;
    }
    // To hide side menu bar
    document.querySelector('body').classList.remove('opened');

    
    this.onAdministrateActivity();
    this.activityResponsible();
    this.roleId = BaseServices.roleId;

    let portalContacts = this.route.snapshot.data['portalContacts'];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.newActivityTitle = this.translate.instant('NEW_ACTIVITY');
        this.newActivityForm = this._fb.group({
          Status: this.newActivityTitle,
          Title: ['', Validators.required],
          Description: [''],
        });
        this.portalContacts = [];
        this.portalContacts.push({ label: val.SELECT, value: null });
      })
    );
    if (portalContacts) {
      portalContacts.forEach((portalContact: any) => {
        this.portalContacts.push({
          label: portalContact.Name,
          value: portalContact.Id,
        });
      });
    }
    this.loading = false;
    this.showSave =
      BaseServices.roleId == '2' && this.selectedBusinessId > 0 ? true : false;
    this.selectedBusinessId = +sessionStorage.getItem('CurrentBusinessId');
    this.isPortal = BaseServices.roleId == '2' ? true : false;
    this.isEditor = BaseServices.roleId == '3' ? true : false;
    if (this.roleId == '2') {
      this.isPortalAccess = this.selectedBusinessId > 0 ? false : true;
    } else {
      this.isPortalAccess = false;
    }
    // Check for the 'openDialog' query param
    this.route.queryParams.subscribe((params) => {
      if (params['openDialog'] === 'true') {
        this.displayDialog = true;
      } else {
        this.displayDialog = false;
      }
    });
  }
  //#endregion

  //#region control-events
  /**
   * filtering list based on Repetition
   * @param e {any}
   * @param repetitionDropdown {any}
   */
  onRepeatChanged(e: any, repetitionDropdown: any) {
    sessionStorage.setItem(
      'search_FollowupAct_Repetition',
      repetitionDropdown.value
    );
    if (e.value) {
      // custom filter datatable
      this.activityTable.filter(
        repetitionDropdown.selectedOption.value,
        'RepetitionId',
        'equals'
      );
    } else {
      this.activityTable.reset();
    }
  }
  /**
   * filtering list based on followup person
   * @param e {any}
   * @param followUpDropdown {any}
   */
  onFollowupChanged(e: any, followUpDropdown: any) {
    sessionStorage.setItem(
      'search_FollowupAct_ResponsibleForFollowup',
      followUpDropdown.value
    );
    setTimeout(() => {}, 500);
    let followUpName = this.follows.filter((x: any) => x.value === e.value);
    if (followUpName.length > 0) {
      if (followUpName[0].value) {
        this.activityTable.filter(
          followUpName[0].value,
          'FollowupUserIdList',
          'contains'
        );
      } else {
        this.activityTable.reset();
      }
    }
  }

  /**
   * filtering activity list based on show activity value
   * @param e {any}
   * @param showAtivityDropDown {any}
   */
  onShowActivityChanged(e: any, showAtivityDropDown: any) {
    if (e.value === '1') {
      if (BaseServices.roleId != '5') {
        this.activitylist = this.route.snapshot.data['list'];
      } else if (BaseServices.roleId == '5' && this.myactivity == true) {
        this.activitylist = this.route.snapshot.data['list'];
      } else {
        this.activitylist = this.route.snapshot.data['listbyuser'];
      }
    } else if (e.value === '2') {
      this.activitylist = this.route.snapshot.data['listbyuser'];
    }
    this.activityResponsible();
  }

  activityResponsible(){
    if (this.activitylist) {
      this.activitylist.forEach((activity: any) => {
        activity.Responsible = false;
        if (activity.FollowupUserIdList) {
          for (let i = 0; i < activity.FollowupUserIdList.length; i++) {
            if (BaseServices.UserId === activity.FollowupUserIdList[i]) {
              activity.Responsible = true;
            }
          }
        }
        if (!activity.Responsible) {
          if (activity.ExecutionUserIdList) {
            for (let i = 0; i < activity.ExecutionUserIdList.length; i++) {
              if (BaseServices.UserId === activity.ExecutionUserIdList[i]) {
                activity.Responsible = true;
              }
            }
          }
        }
        if (!activity.Responsible) {
          if (BaseServices.UserId == activity.CreatedBy) {
            activity.Responsible = true;
          }
        }
      });
    }
  }
  onBusinessChanged(e: any, business: any) {
    if (e.value) {
      this.subscriptions.push(
        this.activityService.list(e.value).subscribe((result) => {
          this.activitylist = result;
        })
      );
    } else {
      this.activityTable.reset();
    }
  }

    SelectedActivityList(){
    this.userrights = JSON.parse(BaseServices.getUserRights());
    if (this.userrights != null) {
      for (let i = 0; i < this.userrights.length; i++) {
        if (this.userrights[i].name == 'ADMINISTRATE_ACTIVITIES') {
          this.myactivity = this.userrights[i].isChecked;
        }
      }
    }
    if (BaseServices.roleId != '5') {
      this.activitylist = this.route.snapshot.data['list'];
      this.selectedActivity = '1';
    } else if (BaseServices.roleId === '5' && this.myactivity == true) {
      this.activitylist = this.route.snapshot.data['list'];
      this.selectedActivity = '1';
    } else {
      this.activitylist = this.route.snapshot.data['listbyuser'];
      this.selectedActivity = '2';
    }
  }

  /**
   * startdate calendar onselect event
   * @param event{any}
   */
  onStartDateChanged(event: any) {
    sessionStorage.setItem('search_FollowupAct_FromDate', event.jsdate);
    let date: Date = event.jsdate ? new Date(event.jsdate.getTime()) : null!;
    this.endDateOptions = this.setDateInput(
      this.endDateOptions,
      event,
      'startDate'
    );
    if (!date) {
      let copyConfig: IMyDpOptions = HelperService.getCopyOfDatePickerOptions(
        this.endDateOptions
      );
      copyConfig.disableSince = this.getCurrentDate();
      this.startDateOptions = copyConfig;
    }
    if (event) {
      // custom filter datatable
      this.startDate = HelperService.formatDateFilter(event.formatted);
      this.filterTable();
    }
  }
  /**
   * Method for setting date range
   * @param dateConfig {{IMyDpOptions}}
   * @param event {{IMyDateModel}}
   * @param type {{string}}
   */
  setDateInput(
    dateConfig: IMyDpOptions,
    event: IMyDateModel,
    type: string
  ): IMyDpOptions {
    let date: Date = event.jsdate ? new Date(event.jsdate.getTime()) : null!;
    let dateOptions = HelperService.setDateRange(dateConfig, date, type);
    return dateOptions;
  }
  /**
   * enddate calendar onselect event
   * @param event {IMyDateModel}
   */
  onEndDateChanged(event: any) {
    sessionStorage.setItem('search_FollowupAct_EndDate', event.jsdate);
    let date: Date = event.jsdate ? new Date(event.jsdate.getTime()) : null!;
    this.startDateOptions = this.setDateInput(
      this.startDateOptions,
      event,
      'endDate'
    );
    if (!date) {
      let copyConfig: IMyDpOptions = HelperService.getCopyOfDatePickerOptions(
        this.startDateOptions
      );
      copyConfig.disableSince = this.getCurrentDate();
      this.startDateOptions = copyConfig;
    }
    if (event) {
      // custom filter datatable
      this.endDate = HelperService.formatDateFilter(event.formatted);
      this.filterTable();
    }
  }

  /**
   * date filter for table
   * @param activity {any}
   */
  filterTable() {
    let list: any[];
    list = this.route.snapshot.data['list'];
    if (list) {
      this.activitylist = list;
    }
    this.activitylist = [];
    if (this.startDate || this.endDate) {
      if (this.startDate && this.endDate) {
        list = list.filter((x: any) => {
          return (
            new Date(HelperService.formatDateFilter(x.EndDateForPdf)) >=
              new Date(this.startDate) &&
            new Date(HelperService.formatDateFilter(x.EndDateForPdf)) <=
              new Date(this.endDate)
          );
        });
      } else if (this.endDate) {
        list = list.filter((x: any) => {
          return (
            new Date(HelperService.formatDateFilter(x.EndDateForPdf)) <=
            new Date(this.endDate)
          );
        });
      } else if (this.startDate) {
        list = list.filter((x: any) => {
          return (
            new Date(HelperService.formatDateFilter(x.EndDateForPdf)) >=
            new Date(this.startDate)
          );
        });
      }
    }
    this.activitylist.push(...list);
  }
  /**
   * generating pdf
   */
  onSubmitTemplateBased(activity: any) {
    this.saveButtonClicked = true;
    this.loading = true;
    let role = BaseServices.roleId;
    let businessId = BaseServices.BusinessId;
    activity.Status = 7;
    activity.BusinessId = businessId ? businessId : 0;
    activity.ApplicationId = BaseServices.ApplicationId;
    activity.CreatedBy = BaseServices.UserId;
    if (this.isPortal) {
      let activityCount = 0;
      for (var i = 0; i <= this.selectedBusiness.length - 1; i++) {
        if (BaseServices.roleId == '2') {
          activity.BusinessId = this.selectedBusiness[i].value;
          this.subscriptions.push(
            this.activityService
              .add(activity, BaseServices.ApplicationId)
              .subscribe(
                (res) => {
                  if (res) {
                    if (activityCount == 0) {
                      this.toasterComponent.callToast();
                    }
                    activityCount++;
                    this.cancel();
                    this.refreshActivity();
                    this.saveButtonClicked = false;
                    this.loading = false;
                    this.selectedBusiness = [];
                  }
                },
                (error) => {
                  console.log('Error : ', error);
                  this.saveButtonClicked = false;
                },
                () => {
                  this.saveButtonClicked = false;
                }
              )
          );
        }
      }
      let count = 0;
      activity.Businesses = '';
      this.selectedBusiness.forEach((x) => {
        activity.Businesses += count == 0 ? x.value : ',' + x.value;
        count++;
      });
      if (BaseServices.roleId === '1') {
        activity.BusinessId = this.currentBusinessId;
        this.selectedBusiness.push(activity.BusinessId);
      } else if (BaseServices.roleId >= '3') {
        activity.BusinessId = BaseServices.BusinessId;
        this.selectedBusiness.push(activity.BusinessId);
      }
    } else {
      this.subscriptions.push(
        this.activityService
          .add(activity, BaseServices.ApplicationId)
          .subscribe(
            (res) => {
              if (res) {
                this.toasterComponent.callToast();
                this.cancel();
                this.refreshActivity();
                this.saveButtonClicked = false;
              }
            },
            (error) => {
              console.log('Error : ', error);
              this.saveButtonClicked = false;
            },
            () => {
              this.saveButtonClicked = false;
            }
          )
      );
    }

    if (role === '1') {
      activity.IsAdmin = true;
    } else {
      activity.IsAdmin = false;
    }
  }
  //#endregion
  //#region methods
  /**
   * binding all dropdowns
   * @param businessId {number}
   */
  bindDetails(businessId: any) {
    this.vresperson = [];
    let responsiblePerson: any[];
    responsiblePerson = this.route.snapshot.data['users'];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.vresperson = [];
        this.vresperson.push({ label: val.SELECT, value: null });
      })
    );
    if (responsiblePerson) {
      responsiblePerson.forEach((responblePerson: any) => {
        this.vresperson.push({
          label: responblePerson.Name,
          value: responblePerson.Id,
        });
      });
    }

    this.follows = [];
    let followUpPerson: any[];    
    followUpPerson = this.route.snapshot.data['user'];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.follows = [];
        this.follows.push({ label: val.SELECT, value: null });
        if (followUpPerson) {
          followUpPerson.forEach((followupPerson: any) => {
            this.follows.push({
              label: followupPerson.Name,
              value: followupPerson.Id,
            });
          });
        }
      })
    );  
    this.subscriptions.push(
      this.translate.stream('REPETITION_LIST').subscribe((val) => {
        this.repetitions = [];
        this.repetitions.push(
          { label: val.REPETITION_LIST_SELECT, value: null },
          { label: val.REPETITION_LIST_NO_REPETITION, value: 1 },
          { label: val.REPETITION_LIST_DAILY, value: 2 },
          { label: val.REPETITION_LIST_WEEKDAYS, value: 3 },
          { label: val.REPETITION_LIST_EVERY_WEEK, value: 4 },
          { label: val.REPETITION_LIST_EVERY_2_WEEKS, value: 5 },
          { label: val.REPETITION_LIST_EVERY_3_WEEKS, value: 6 },
          { label: val.REPETITION_LIST_EVERY_MONTH, value: 7 },
          { label: val.REPETITION_LIST_EVERY_2_MONTH, value: 8 },
          { label: val.REPETITION_LIST_EVERY_3_MONTH, value: 9 },
          { label: val.REPETITION_LIST_EVERY_4_MONTH, value: 10 },
          { label: val.REPETITION_LIST_EVERY_6_MONTH, value: 11 },
          { label: val.REPETITION_LIST_EVERY_YEAR, value: 12 },
          { label: val.REPETITION_LIST_EVERY_2_YEARS, value: 13 },
          { label: val.REPETITION_LIST_EVERY_3_YEARS, value: 14 },
          { label: val.REPETITION_LIST_EVERY_4_YEARS, value: 15 },
          { label: val.REPETITION_LIST_EVERY_5_YEARS, value: 16 },
          { label: val.REPETITION_LIST_EVERY_6_YEARS, value: 17 },
          { label: val.REPETITION_LIST_EVERY_7_YEARS, value: 18 },
          { label: val.REPETITION_LIST_EVERY_8_YEARS, value: 19 },
          { label: val.REPETITION_LIST_EVERY_9_YEARS, value: 20 },
          { label: val.REPETITION_LIST_EVERY_10_YEARS, value: 21 }
        );
      })
    );

    this.vDepartment = [];
    let departments: any[];
    departments = this.route.snapshot.data['departments'];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.vDepartment = [];
        this.vDepartment.push({ label: val.SELECT, value: null });
      })
    );
    if (departments) {
      departments.forEach((department: any) => {
        this.vDepartment.push({
          label: department.Name,
          value: department.Id,
        });
      });
    }
    this.subscriptions.push(
      this.translate.stream('SELECT_STATUSES').subscribe((val) => {
        this.statuses = [];
        this.statuses.push(
          { label: val.SELECT, value: null },
          { label: val.NEW, value: 7 },
          { label: val.OPEN, value: 8 },
          { label: val.DONE, value: 9 },
          { label: val.REJECTED, value: 10 }
        );
      })
    );
    this.subscriptions.push(
      this.translate.stream('ACTIVITY_DONE_DROPDOWN').subscribe((val) => {
        this.doneOn = [];
        this.doneOn.push(
          { label: val.ALL, value: 0 },
          { label: val.NOTICE_PERIOD, value: 1 },
          { label: val.OVERDEADLINE, value: 2 },
          { label: val.TIME_REMAIN, value: 3 },
          { label: val.UP_SIX_MONTH, value: 4 },
          { label: val.UP_TWELEVE_MONTH, value: 5 },
          { label: val.DONE_ON, value: 6 }
        );
        this.showActivities = [];
        this.showActivities.push({ label: val.ALL, value: '1' });
        this.showActivities.push({
          label: this.translate.instant('MYACTIVITY'),
          value: '2',
        });
      })
    );
  }
  /**
   * deleting activity by id
   * @param id {any}
   */
  confirm(id: any) {
    this.subscriptions.push(
      this.activityService.checkParentActivity(id).subscribe((count: any) => {
        // check for normal activity or child activity
        if (count === 0 || count === 2) {
          this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
              this.loading = true;
              this.subscriptions.push(
                this.activityService
                  .delete(id)
                  .subscribe((isRecurringActivityDeleted) => {
                    if (isRecurringActivityDeleted) {
                      this.toasterComponent.callToastDlt();
                      this.refreshActivity();
                      this.loading = false;
                    }
                  })
              );
            },
          });
        } else {
          // parent activity delete
          this.confirmationService.confirm({
            message: this.translate.instant('DELETE_PARENT_ACTIVITY'),
            accept: () => {
              this.loading = true;
              this.subscriptions.push(
                this.activityService
                  .deleteParentwithRecurring(id)
                  .subscribe((isActivityDeleted) => {
                    if (isActivityDeleted) {
                      this.toasterComponent.callToastDlt();
                      this.refreshActivity();
                      this.loading = false;
                    }
                  })
              );
            },
          });
        }
      })
    );
  }
  /**
   * binding list
   */
  refreshActivity() {
    this.loading = true;
    if (BaseServices.roleId === '1') {
      this.activitylist = [];
      this.subscriptions.push(
        this.activityService
          .getAdminActivity(BaseServices.ApplicationId)
          .subscribe((list: any) => {
            this.activitylist = list;
            this.loading = false;
            if (this.activitylist) {
              this.activitylist.forEach((activity: any) => {
                activity.Responsible = false;
                if (activity.FollowupUserIdList) {
                  for (let i = 0; i < activity.FollowupUserIdList.length; i++) {
                    if (
                      BaseServices.UserId === activity.FollowupUserIdList[i]
                    ) {
                      activity.Responsible = true;
                    }
                  }
                }
                if (!activity.Responsible) {
                  if (activity.ExecutionUserIdList) {
                    for (
                      let i = 0;
                      i < activity.ExecutionUserIdList.length;
                      i++
                    ) {
                      if (
                        BaseServices.UserId === activity.ExecutionUserIdList[i]
                      ) {
                        activity.Responsible = true;
                      }
                    }
                  }
                }
                if (!activity.Responsible) {
                  if (BaseServices.UserId == activity.CreatedBy) {
                    activity.Responsible = true;
                  }
                }
              });
            }
          })
      );
    } else if (BaseServices.roleId !== '2') {
      this.activitylist = [];
      this.subscriptions.push(
        this.activityService
          .list(BaseServices.BusinessId)
          .subscribe((result: any) => {
            this.activitylist = result;
            if (this.activitylist) {
              this.subscriptions.push(
                this.activityService
                  .getActivityResponsible(+BaseServices.BusinessId)
                  .subscribe((res: any) => {
                    this.activitylist.forEach((element: any) => {
                      if (element.Status !== 'NEW') {
                        element.ExecutionUserIdList = res.activity_Execution
                          .filter((x: any) => x.ActivityId === element.Id)
                          .map(({ UserId }: any) => UserId);
                        element.FollowupUserIdList = res.activity_FollowUp
                          .filter((x: any) => x.ActivityId === element.Id)
                          .map(({ UserId }: any) => UserId);
                      }
                    });
                  })
              );
              this.bindStatusIcon(this.activitylist);
            }
            this.loading = false;

            if (this.activitylist) {
              this.activitylist.forEach((activity: any) => {
                activity.Responsible = false;
                if (activity.FollowupUserIdList) {
                  for (let i = 0; i < activity.FollowupUserIdList.length; i++) {
                    if (
                      BaseServices.UserId === activity.FollowupUserIdList[i]
                    ) {
                      activity.Responsible = true;
                    }
                  }
                }
                if (!activity.Responsible) {
                  if (activity.ExecutionUserIdList) {
                    for (
                      let i = 0;
                      i < activity.ExecutionUserIdList.length;
                      i++
                    ) {
                      if (
                        BaseServices.UserId === activity.ExecutionUserIdList[i]
                      ) {
                        activity.Responsible = true;
                      }
                    }
                  }
                }
                if (!activity.Responsible) {
                  if (BaseServices.UserId == activity.CreatedBy) {
                    activity.Responsible = true;
                  }
                }
              });
            }
          })
      );
    }
  }

  bindBusiness() {
    this.businesses = [];
    const business = this.route.snapshot.data['business'];
    if (Array.isArray(business)) {
      business.forEach((element) => {
        if (element && element.Features != null) {
          try {
            const feature = JSON.parse(element.Features);
            if (Array.isArray(feature)) {
              feature.forEach((featureItem) => {
                if (
                  featureItem &&
                  featureItem.id === FeatureKey.FOLLOW_UP &&
                  featureItem.children
                ) {
                  const isActivity = featureItem.children.find(
                    (x) => x.id === FeatureKey.ACTIVITIES
                  );
                  if (isActivity && isActivity.checked) {
                    this.businesses.push({
                      label: element.Name,
                      value: element.Id,
                    });
                  }
                }
              });
            }
          } catch (error) {
            console.error('Error parsing JSON:', error);
          }
        }
      });
    }

    // This comment for checked undifined issue.

    //     this.businesses = [];
    //     let business = this.route.snapshot.data['business'];
    //     if (business) {
    //         business.forEach(element => {
    //             if(element.Features != null){
    //                 var feature = JSON.parse(element.Features);
    //                 if (feature) {
    //                     feature.forEach(feature => {
    //                         if (feature.id === FeatureKey.FOLLOW_UP) {
    //                             let childrens = feature.children;
    //                             let isActivity = childrens.filter(x => x.id === FeatureKey.ACTIVITIES);
    //                             if(isActivity[0].checked){
    //                                 this.businesses.push({ label: element.Name, value: element.Id });
    //                             }
    //                         }});}
    //             }
    //         });
    //     }
  }

  BusinessCount() {
    if (this.isPortal) {
      this.showSave = this.selectedBusiness.length > 0 ? true : false;
    }
  }
  bindActivity() {
    this.loading = true;
    if (BaseServices.roleId === '1') {
      this.loading = false;
      if (this.activitylist) {
        this.activitylist.forEach((activity) => {
          activity.Responsible = false;
          if (activity.FollowupUserIdList) {
            for (let i = 0; i < activity.FollowupUserIdList.length; i++) {
              if (BaseServices.UserId === activity.FollowupUserIdList[i]) {
                activity.Responsible = true;
              }
            }
          }
          if (!activity.Responsible) {
            if (activity.ExecutionUserIdList) {
              for (let i = 0; i < activity.ExecutionUserIdList.length; i++) {
                if (BaseServices.UserId === activity.ExecutionUserIdList[i]) {
                  activity.Responsible = true;
                }
              }
            }
          }
          if (!activity.Responsible) {
            if (BaseServices.UserId == activity.CreatedBy) {
              activity.Responsible = true;
            }
          }
        });
      }
    } else if (BaseServices.roleId !== '2') {
      if (this.activitylist) {
        this.subscriptions.push(
          this.activityService
            .getActivityResponsible(+BaseServices.BusinessId)
            .subscribe((res: any) => {
              this.activitylist.forEach((element) => {
                if (element.Status !== 'NEW') {
                  element.ExecutionUserIdList = res.activity_Execution
                    .filter((x) => x.ActivityId === element.Id)
                    .map(({ UserId }) => UserId);
                  element.FollowupUserIdList = res.activity_FollowUp
                    .filter((x) => x.ActivityId === element.Id)
                    .map(({ UserId }) => UserId);
                }
              });
            })
        );
        this.bindStatusIcon(this.activitylist);
      }
      this.loading = false;
      if (this.activitylist) {
        this.activitylist.forEach((activity: any) => {
          activity.Responsible = false;
          if (activity.FollowupUserIdList) {
            for (let i = 0; i < activity.FollowupUserIdList.length; i++) {
              if (BaseServices.UserId === activity.FollowupUserIdList[i]) {
                activity.Responsible = true;
              }
            }
          }
          if (!activity.Responsible) {
            if (activity.ExecutionUserIdList) {
              for (let i = 0; i < activity.ExecutionUserIdList.length; i++) {
                if (BaseServices.UserId === activity.ExecutionUserIdList[i]) {
                  activity.Responsible = true;
                }
              }
            }
          }
          if (!activity.Responsible) {
            if (BaseServices.UserId == activity.CreatedBy) {
              activity.Responsible = true;
            }
          }
        });
      }
    }
  }

  savePortalActivity() {
    this.loading = true;
    this.confirmationService.confirm({
      message: this.translate.instant('DELETION_OF_ACTIVITY'),
      accept: () => {
        this.onSubmitTemplateBased(this.newActivityForm.value);
      },
    });
  }
  addMonths(date: any, months: any) {
    date.setMonth(date.getMonth() + months);
    return date;
  }
  /**
   * filtering list based on Done on
   * @param e {any}
   * @param doneOnDropDown {any}
   */
  onDoneOnChanged(e: any, doneOnDropDown: any) {
    sessionStorage.setItem('search_FollowupAct_DoneOn', doneOnDropDown.value);
    if (e.value) {
      let futureSixMonths = this.addMonths(new Date(), +6);
      let futureTwelveMonths = this.addMonths(new Date(), +12);
      let list = [];
      list = this.route.snapshot.data['list'];
      if (list) {
        this.activitylist = list;
      }
      if (e.value === 4) {
        // filter upto six months
        this.activitylist = list.filter(
          (x: any) =>
            new Date(x.EndDate) > new Date() &&
            new Date(x.EndDate) <= futureSixMonths
        );
      } else if (e.value === 5) {
        this.activitylist = list.filter(
          (x: any) =>
            new Date(x.EndDate) > new Date() &&
            new Date(x.EndDate) <= futureTwelveMonths
        );
      } else if (e.value === 2) {
        // Over due
        this.activitylist = list.filter(
          (x: any) =>
            new Date(x.EndDate) < new Date() &&
            x.EndDate !== null &&
            x.CompletedDate === null
        );
      } else {
        this.activityTable.filter(
          doneOnDropDown.selectedOption.value,
          'value',
          'equals'
        );
      }
    } else {
      let list = [];
      list = this.route.snapshot.data['list'];
      if (list) {
        this.activitylist = list;
      }
      this.activityTable.reset();
    }
  }

  /**
   * getting days diffrence between start date and end date
   * @param StartDate {date}
   * @param EndDate {date}
   */
  getDaysDiff(StartDate: Date, EndDate: Date) {
    if (StartDate && EndDate) {
      let _MS_PER_DAY = 1000 * 60 * 60 * 24;
      let date1 = Date.UTC(
        StartDate.getFullYear(),
        StartDate.getMonth(),
        StartDate.getDate()
      );
      let date2 = Date.UTC(
        EndDate.getFullYear(),
        EndDate.getMonth(),
        EndDate.getDate()
      );
      let res: any = Math.floor((date2 - date1) / _MS_PER_DAY);
      return res;
    }
  }
  /**
   * show all list
   */
  showAll() {
    this.activitylist = [];
    if (BaseServices.roleId === '1') {
      this.isAdmin = true;
      this.isNotAdmin = false;
      this.isBusiness = true;
      this.isShowActivity = false;
      this.subscriptions.push(
        this.activityService
          .repetitionByAdmin(BaseServices.ApplicationId)
          .subscribe((adminActivity) => {
            if (adminActivity) {
              this.activitylist = adminActivity ? adminActivity : null;
            }
          })
      );
    } else if (BaseServices.roleId === '2') {
      this.isNotAdmin = true;
      this.isAdmin = true;
      this.isBusiness = true;
      this.isShowActivity = false;
      this.subscriptions.push(
        this.activityService
          .repetitionByPortal(BaseServices.UserId)
          .subscribe((portalActivity) => {
            if (portalActivity) {
              this.activitylist = portalActivity ? portalActivity : null;
            }
          })
      );
    } else {
      this.isNotAdmin = true;
      this.subscriptions.push(
        this.activityService
          .repetitionByBusiness(BaseServices.BusinessId)
          .subscribe((businessActivity) => {
            if (businessActivity) {
              this.activitylist = businessActivity ? businessActivity : null;
            }
          })
      );
    }
  }
  /**
   * bind activity by User Id
   */
  bindActitvityByUser() {
    this.loading = true;
    this.activitylist = [];
    this.subscriptions.push(
      this.activityService
        .getActivitiesByUser(BaseServices.UserId)
        .subscribe((activityList) => {
          if (activityList) {
            this.activitylist = activityList;
            this.loading = false;
            activityList.forEach((activityInfo: any) => {
              this.bindStatusIcon(activityList);
            });
          }
        })
    );
  }
  /**
   * edit the activity page based upon the rights
   */
  onAdministrateActivity() {
    let currentUserRole = BaseServices.UserRole;
    let userRightsId = Rights.ADMINISTRATE_ACTIVITIES;
    if (currentUserRole === 'User') {
      this.isAdministrateActivity = BaseServices.checkUserRights(
        userRightsId,
        currentUserRole
      );
    } else if (currentUserRole === 'Guest') {
      this.isAdministrateActivity = false;
    } else {
      this.isAdministrateActivity = true;
    }
  }
  /**
   * get current date
   */
  getCurrentDate() {
    let dateFormat = new Date();
    return {
      year: dateFormat.getFullYear(),
      month: dateFormat.getMonth() + 1,
      day: dateFormat.getDate() + 1,
    };
  }
  /**
   * custom filter datatable of type of case
   */

  onDescriptionChanged(control: any) {
    if (control.value !== '') {
      this.activityTable.filter(control.value, 'Description', 'contains');
    } else {
      this.activityTable.reset();
    }
  }
  onPortalContactChanged(e: any) {
    if (e.value !== '') {
      this.activityTable.filter(e.value, 'FollowupUserId', 'contains');
    } else {
      this.activityTable.reset();
    }
  }
  /**
   * custom filter datatable of status
   */
  onStatusChanged(e: any, statusDropdown: any) {
    if (e.value) {
      this.activityTable.filter(statusDropdown.value, 'StatusId', 'equals');
    } else {
      this.activityTable.reset();
    }
  }
  /**
   * on department change
   * @param e {any}
   * @param statusDropdown {any}
   */
  onResponsiblePersonChange(e: any, responseDropdown: any) {
    if (e.value) {
      this.activityTable.filter(
        responseDropdown.selectedOption.label,
        'ExecutionUserList',
        'equals'
      );
    } else {
      this.activityTable.reset();
    }
  }
  /**
   * on department change
   * @param e {any}
   * @param statusDropdown {any}
   */
  onDepartmentChange(e: any, departmentDropdown: any) {
    if (e.value) {
      this.activityTable.filter(
        departmentDropdown.selectedOption.label,
        'Department',
        'equals'
      );
    } else {
      this.activityTable.reset();
    }
  }

  deleteActivity(activity: any) {
    activity.Status = 0;
    this.subscriptions.push(
      this.activityService.deleteactivity(activity).subscribe((isDeleted) => {
        if (isDeleted) {
        }
      })
    );
  }
  //#endregion

  /**
   * show new activity pop-up
   */
  showDialogToAdd() {
    this.displayDialog = true;
    // this.router.navigate(['portalactivity'], { relativeTo: this.route });
  }

  /**
   * close new activity pop-up
   */
  cancel() {
    this.newActivityForm = this._fb.group({
      Status: this.newActivityTitle,
      Title: ['', Validators.required],
      Description: [''],
    });
    this.displayDialog = false;
  }
  /**
   * popup close event
   */
  onCloseEvent() {
    this.newActivityForm = this._fb.group({
      Status: this.newActivityTitle,
      Title: ['', Validators.required],
      Description: [''],
    });
  }
  /**
   * show dialog for stop future recurring activity
   */
  showDialogForStopFutureRecurring(data: any) {
    this.displayDialogForRecurring = true;
    this.recurringActivityId = data.Id;
    this.IsFutureRecurring = data.IsFutureRecurring
      ? data.IsFutureRecurring
      : false;
  }
  /**
   * stops the chain from rucurring
   */
  onClickStopFutureRecurring() {
    if (this.recurringActivityId > 0) {
      // this.confirmationService.confirm({
      //     message: this.translate.instant('STOP_FUTURE_RECURRING_ACTIVTY'),
      //     accept: () => {
      this.displayDialogForRecurring = false;
      this.loading = true;
      this.subscriptions.push(
        this.activityService
          .stopFutureRecurring(this.recurringActivityId)
          .subscribe((result) => {
            if (result) {
              this.toasterComponent.callToaststopRecurring();
              this.refreshActivity();
              this.loading = false;
            }
          })
      );
      // },
      // reject: () => {
      //     this.loading = false;
      // }
      // });
    }
  }

  onClickDeleteRecurringActivity() {
    if (this.recurringActivityId > 0) {
      this.subscriptions.push(
        this.activityService
          .checkParentActivity(this.recurringActivityId)
          .subscribe((count: any) => {
            // check for normal activity or child activity
            if (count === 0 || count === 2) {
              // this.confirmationService.confirm({
              //     message: this.translate.instant('DELETE_THIS_RECORD'),
              //     accept: () => {
              this.loading = true;
              this.subscriptions.push(
                this.activityService
                  .delete(this.recurringActivityId)
                  .subscribe((isRecurringActivityDeleted) => {
                    if (isRecurringActivityDeleted) {
                      this.toasterComponent.callToastDlt();
                      this.refreshActivity();
                      this.loading = false;
                    }
                  })
              );
              // }
              // });
            } else {
              // parent activity delete
              // this.confirmationService.confirm({
              //     message: this.translate.instant('DELETE_PARENT_ACTIVITY'),
              //     accept: () => {
              this.loading = true;
              this.subscriptions.push(
                this.activityService
                  .deleteParentwithRecurring(this.recurringActivityId)
                  .subscribe((isActivityDeleted) => {
                    if (isActivityDeleted) {
                      this.toasterComponent.callToastDlt();
                      this.refreshActivity();
                      this.loading = false;
                    }
                  })
              );
              //     }
              // });
            }
          })
      );
      this.displayDialogForRecurring = false;
    }
  }

  concatenateEmployeeName(responsibleList: any, actId: number, status: string) {
    let userName = '';
    if (status !== 'NEW') {
      let userList = responsibleList
        .filter((x: any) => x.ActivityId === actId)
        .map(({ Name }: any) => Name);
      if (userList.length > 0) {
        userName = userList.join(', ');
      }
    }
    return userName;
  }
  loadActivitiesLazy(event: any | LazyLoadEvent) {
    setTimeout(() => {
      if (this.activitylist) {
        this.activitylistArr = this.activitylist.slice(
          event.first,
          event.first + event.rows
        );
      }
    }, 1000);
  }

  bindStatusIcon(activityList: any) {
    this.activityList = [];
    if (activityList) {
      activityList.forEach((activityInfo: any) => {
        let statusIcon = '';
        this.activityList.push(activityInfo);
        this.rowsPerPageOptions = [10, 20, 50, 100];
        if (this.activityList.length > 100) {
          this.rowsPerPageOptions.push(this.activityList.length);
        }
      });
      this.globalActivityFilter();
    }
  }

  globalActivityFilter() {
    if (this.activityList.length > 0) {
      let responsibleForFollowup = sessionStorage.getItem(
        'search_FollowupAct_ResponsibleForFollowup'
      );
      let repetition = sessionStorage.getItem('search_FollowupAct_Repetition');
      let doneOn = sessionStorage.getItem('search_FollowupAct_DoneOn');
      let fromDate = sessionStorage.getItem('search_FollowupAct_FromDate');
      let endDate = sessionStorage.getItem('search_FollowupAct_EndDate');

      if (responsibleForFollowup && responsibleForFollowup !== 'null') {
        this.responsibleFollowUpFilter = responsibleForFollowup;
        this.activityTable.filter(
          responsibleForFollowup,
          'FollowUpUserId',
          'equals'
        );
      }

      if (repetition && repetition !== 'null') {
        this.repetitionFilter = repetition;
        this.activityTable.filter(repetition, 'RepetitionId', 'equals');
      }

      if (doneOn && doneOn !== 'null') {
        this.doneOnFilter = doneOn;
        this.activityTable.filter(doneOn, 'value', 'equals');
      }

      this.fromDateFilter =
        fromDate && fromDate !== 'null'
          ? HelperService.formatInputDate(new Date(fromDate))
          : null;

      this.endDateFilter =
        endDate && endDate !== 'null'
          ? HelperService.formatInputDate(new Date(fromDate!))
          : null;

      if (this.fromDateFilter || this.endDateFilter) {
        if (this.iscreatedToFilter) {
          this.filterTable();
          this.iscreatedToFilter = false;
        }
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}
