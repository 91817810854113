import { Component, OnInit, Input, Renderer2, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { LocationStrategy } from '@angular/common';

@Component({
  selector: 'ecpl-image-viewer',
  templateUrl: './ecpl-image-viewer.component.html',
  styleUrls: ['./ecpl-image-viewer.component.css']
})
export class EcplImageViewerComponent implements OnInit, AfterViewInit {
  @Input() id: string;
  @Input() imgSrc: string;
  @Input() imgName: string;
  @Input() imgType: string;
  @Input() isDialog: boolean;
  @Output() close = new EventEmitter<void>();

  showModalWindow: boolean;

  constructor(
    private renderer: Renderer2,
    location: LocationStrategy
    ) {
      location.onPopState(() => {
        this.showModalWindow = false;
        this.renderer.removeClass(document.body, 'modal-open');
      });
  }

  showModal() {
    this.showModalWindow = true;
    this.renderer.addClass(document.body, 'modal-open');
  }

  hideModal() {
    this.showModalWindow = false;
    this.renderer.removeClass(document.body, 'modal-open');
    this.close.emit();
  }

  ngOnInit() {}

  ngAfterViewInit() {
    if (this.isDialog) {
      this.showModal();
    }
  }
}
