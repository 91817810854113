import { ReturnStatement } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RequestOptions } from 'http';
import { Observable } from 'rxjs';
import { ApprovalNotices } from '../follow-ups/models/riskanalysis';
import { BaseServices } from '../kuba.services';
import { SjaForm, SjaTitleForm } from './components/sja-form';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from 'src/app/_services';

@Injectable()
export class SJAFormServices {
  headers: Headers;
  options: RequestOptions;
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) {}

  createSjaForm(sjaFormData: SjaForm) {
    return this.http
      .post(environment.BASE_URL + '/project/sjaform/save', sjaFormData)
      .map(result => result);
  }

  getSJAFormList(featureId: number, featureKey: number, BusinessId: number) {
    let applicationId = BaseServices.ApplicationId;
    return this.http
      .get(
        `${environment.BASE_URL}/project/get/sjadata/${featureId}/${featureKey}/${BusinessId}/${applicationId}`
      )
      .map(result => result);
  }

  getSJAForm(Id: number) {
    return this.http
      .get(environment.BASE_URL + '/project/get/sjadata/' + Id)
      .map(result => result);
  }

  deleteSJAForm(Id: number) {
    return this.http.put(
      environment.BASE_URL + '/project/delete/sjaform/' + Id,
      null
    );
  }

  exportSJAForm(sjaFormId: number, cultureInfo: string, appId: number) {
    let dynamicType = 'application/pdf';
    let myData = JSON.stringify({ data: sjaFormId });
    let crfToken = this.authenticationService.getCsrfToken();
    let url = `${environment.BASE_URL}/export-sjaform/${sjaFormId}/${BaseServices.BusinessId}/${BaseServices.ApplicationId}/${cultureInfo}`;
    return Observable.create(observer => {
      let xhr = new XMLHttpRequest();
      xhr.open('POST', url, true);
      xhr.withCredentials = true;
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('Logo-Id', 'ab064fd4-9b4a-4e70-972d-dafdaceb2aba');
      xhr.setRequestHeader('X-XSRF-TOKEN', crfToken);
      xhr.responseType = 'blob';
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            let contentType = dynamicType;
            let blob = new Blob([xhr.response], { type: contentType });
            observer.next(blob);
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };
      xhr.send(myData);
    });
  }

  addApprovalNotices(approvalNotice: ApprovalNotices[]) {
    return this.http
      .post(environment.BASE_URL + '/project/sjaform/approved', approvalNotice)
      .map(result => result);
  }

  getAllApproved(featureKey: number) {
    return this.http
      .get(
        `${environment.BASE_URL}/project/sjaform/approval/${BaseServices.BusinessId}/${featureKey}`
      )
      .map(result => result);
  }

  getAllApprovedForKubaControl(featureKey: number) {
    return this.http
      .get(
        `${environment.BASE_URL}/kubacontrol/sjaform/approval/${BaseServices.BusinessId}/${featureKey}`
      )
      .map(result => result);
  }

  SaveSjaTitle(sjaTitleForm: SjaTitleForm) {
    return this.http
      .post(environment.BASE_URL + '/project/sjaTitle/save', sjaTitleForm)
      .map(result => result);
  }

  GetSJATitleList() {
    return this.http
      .get(
        `${environment.BASE_URL}/project/sjaformtitle/${BaseServices.ApplicationId}/${BaseServices.BusinessId}`
      )
      .map(result => result);
  }

  DeleteSJATitle(Id: number) {
    return this.http.delete(
      `${environment.BASE_URL}/project/sjaTitle/Delete/${Id}`
    );
  }
}
