import { FeatureKey, LicenseCategory, Application } from './../_models/feature';
import { KubaServices } from './../kuba/kuba.services';
import { BaseServices } from './kuba.services';
import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { LoggedUser } from '../_models/';
import { ThemeService } from '../theme.service';

import {
  Router,
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
  ActivatedRoute,
} from '@angular/router';
import 'rxjs/Rx';
import { Observable, Subscription } from 'rxjs';
import * as _ from 'lodash';

import { BusinessServices } from './businesses/services/business.services';
import { ViewChild } from '@angular/core';
import { NavigationComponent } from '../_directives/navigation.component';

@Component({
  selector: 'kuba-root',
  templateUrl: 'kuba.component.html',
  styleUrls: ['kuba.component.css'],
  providers: [KubaServices, TranslateService],
})
export class KubaComponent implements OnInit {
  assetloc: string = environment.BASE_STYLES_PATH;
  // Sets initial value to true to show loading spinner on first load
  loading: boolean | any;
  placeholder = true;
  baseUrl: any;
  userSettings: {} | any;
  isReloadPath: boolean | any;
  businessId: number | any;
  licenseCategoryId: number | any;
  showWarningMsg = false;
  @ViewChild(NavigationComponent, { static: false })
  navigationComponent!: NavigationComponent;
  // showWarningMsg = true;
  warningMsg = '';
  private subscriptions: Subscription[] = [];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private themeService: ThemeService,
    private translate: TranslateService,
    private kubaServices: KubaServices,
    public businessServices: BusinessServices
  ) {
    this.subscriptions.push(
      router.events.subscribe((event: RouterEvent) => {
        this.navigationInterceptor(event);
      })
    );
  }
  reloadNavigationComponent() {
    if (this.navigationComponent) {
      this.navigationComponent.reload(); // Create a reload method in the navigation component
    }
  }
  ngOnInit() {
    this.subscriptions.push(
      this.translate.stream('LICENSE_AGREEMENT_MSG').subscribe((val) => {
        this.warningMsg = val.DEMO_INTERNAL_USE;
      })
    );
    if (window.name === 'Remote') {
      this.businessId = +sessionStorage.getItem('RModeBusinessId')!;
    } else {
      this.businessId = BaseServices.BusinessId;
    }
    if (
      BaseServices.ApplicationId === Application.DIN_HMS ||
      BaseServices.ApplicationId === Application.IK
    ) {
      this.subscriptions.push(
        this.businessServices
          .getAgreementByBusinessId(this.businessId)
          .subscribe((result: any) => {
            if (result) {
              this.licenseCategoryId = result.LicenseCategoryId;
              if (
                this.licenseCategoryId === LicenseCategory.DEMO_INTERNAL_USE
              ) {
                this.showWarningMsg = true; // #KW-176
              }
            }
          })
      );
    }

    //this.loading = false;
    //  setTimeout(() => {
    this.placeholder = false;
    //   }, 1500);
    const timeNow = (Date.now() / 1000) | 1;
    if (window.location.pathname.split('/').length > 3) {
      this.isReloadPath = true;

      // listening to the local storage for anonymous of client users
      window.addEventListener('storage', function (e) {
        if (e.oldValue !== e.newValue) {
          this.location.href = '/login';
        }
      });
    }
    // Setting current application/Business theme
    // if (BaseServices.userTheme != null) {
    //     const themeTitle = BaseServices.userTheme.Title.toLowerCase();
    //     localStorage.setItem('themeTitle', themeTitle);

    //     const themePath = `${this.assetloc}/styles${BaseServices.userTheme.Stylepath}`;
    //     this.themeService.setTheme(themePath);
    //   }
    if (BaseServices.userTheme && BaseServices.userTheme.Stylepath) {
      const themePath = this.assetloc + BaseServices.userTheme.Stylepath;
      const themeId = BaseServices.userTheme.Id;
      this.themeService.setTheme(themeId);
      this.themeService.applyTheme();
    }

    switch (BaseServices.UserRole) {
      case 'Admin':
        this.baseUrl = 'admin/' + BaseServices.ApplicationId;
        this.router.navigate(
          ['admin', BaseServices.ApplicationId, 'dashboard'],
          { relativeTo: this.route }
        );
        break;
      case 'Portal':
        // TODO: need clarification about routing from hameed
        // temporary fix of url implemented
        this.baseUrl = 'portal/' + BaseServices.ApplicationId;
        if (!this.isReloadPath) {
          this.router.navigate(['portal', BaseServices.PortalId, 'dashboard'], {
            relativeTo: this.route,
          });
        }
        break;

      case 'Editor':
        this.baseUrl = 'business/' + BaseServices.BusinessId;
        if (!this.isReloadPath) {
          this.router.navigate(
            ['business', BaseServices.BusinessId, 'dashboard'],
            { relativeTo: this.route }
          );
        }
        break;
      case 'Guest':
        this.baseUrl = 'business/' + BaseServices.BusinessId;
        if (
          !this.isReloadPath &&
          (BaseServices.FeatureId === FeatureKey.EMPLOYEES ||
            BaseServices.FeatureId === FeatureKey.CONTACTS)
        ) {
          this.router.navigate(
            ['business', BaseServices.BusinessId, 'dashboard'],
            { relativeTo: this.route }
          );
        } else {
          this.router.navigate([this.baseUrl, 'my-page'], {
            relativeTo: this.route,
          });
        }
        break;
      case 'Super':
        this.baseUrl = 'super';
        if (!this.isReloadPath) {
          this.router.navigate(['super', 'dashboard'], {
            relativeTo: this.route,
          });
        }
        break;
      case 'Developer':
        this.baseUrl = 'developer';
        if (!this.isReloadPath) {
          this.router.navigate(['developer', 'dashboard'], {
            relativeTo: this.route,
          });
        }
        break;
      default:
        this.baseUrl = 'business/' + BaseServices.BusinessId;
        if (!this.isReloadPath) {
          this.router.navigate(
            ['business', BaseServices.BusinessId, 'dashboard'],
            { relativeTo: this.route }
          );
        }
        break;
    }
    // Register this component in the KubaService
    this.kubaServices.setKubaComponent(this);

  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.placeholder = true;
    }
    if (event instanceof NavigationEnd) {
      this.placeholder = false;
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.placeholder = false;
    }
    if (event instanceof NavigationError) {
      this.placeholder = false;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}
