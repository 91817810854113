<div class="main-content">
  <div class="main-heading">
    <strong>
      <span translate>ADD_EDIT_VEHICLE</span>
    </strong>
  </div>
  <div class="card">
    <div class="card-body">
      <form [formGroup]="vehicleEditForm">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>
                <span translate>DEPARTMENT</span>
              </label>
              <div class="input-btn-group">
                <p-dropdown [options]="departments" [style]="{'width':'100%'}" type="text"
                  formControlName="BusinessDepartmentId"></p-dropdown>
                <button class="btn btn-outline-primary" type="button" (click)="addDept = !addDept">
                  <i class="pi pi-plus" aria-hidden="true"></i>
                </button>
              </div>
            </div>
            <div class="form-group">
              <label>
                <span translate>VEHICLE_NAME</span>
              </label>
              <div>
                <input type="text" maxlength="100" required formControlName="VehicleName" class="form-control" />
              </div>
            </div>
            <div class="form-group">
              <label for="Brand">
                <span translate>BRAND</span>
              </label>
              <div>
                <input type="text" maxlength="100"  formControlName="BrandName"
                       class="form-control" />
              </div>
            </div>
            <div class="form-group">
              <label for="Model">
                <span translate>MODEL</span>
              </label>
              <div>
                <input type="text" maxlength="100" formControlName="ModelName"
                       class="form-control" />
              </div>
            </div>
            <div class="form-group">
              <label>
                <span translate>REGISTRATION_NUMBER</span>
              </label>
              <div>
                <input type="text" maxlength="100" required formControlName="RegistrationNo" class="form-control" />
              </div>
            </div>
            <div class="form-group">
              <label>
                <span translate>REGISTRATION_YEAR</span>
              </label>
              <div>
                <input type="text" maxlength="100" required formControlName="RegistrationYear" class="form-control" />
              </div>
            </div>
            <div class="form-group">
              <label>
                <span translate>TYPE_OF_VEHICLE</span>
              </label>
              <div>
                <p-dropdown [options]="vehicleType" type="text" formControlName="VehicleTypeId"
                  [style]="{'width':'100%'}"></p-dropdown>
              </div>
            </div>
            <div class="checkbox">
              <p-checkbox label="{{'DO_NOT_DISPLAY_DEVIATION'|translate}}" formControlName="IsShowDeviationChecked"
                binary="true"></p-checkbox>
            </div>
            <div class="action-btns-wrapper">
              <button class="btn btn-outline-secondary" type="button" (click)="gotoList()">
                <span class="icon ic-xs icon-back"></span>
                <span translate>BACK_LIST</span>
              </button>
              <button class="btn btn-primary" type="submit" (click)="saveVehicle()" [disabled]="!vehicleEditForm.valid">
                <span translate>SAVE</span>
              </button>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <div class="sub-panel-title">
                <span translate>ASSIGN_ACCESS</span>
              </div>
              <!-- Table starts -->
              <p-table #dt [value]="vehicleUsers" dataKey="Id" scrollable="true" scrollHeight="200px">
                <!-- Header -->
                <ng-template pTemplate="header">
                  <tr>
                    <th>
                      <p-checkbox [ngModel]="checkedAll" (ngModelChange)="checkAll($event)"
                        [ngModelOptions]="{standalone: true}" binary="true"></p-checkbox>
                    </th>
                    <th>{{'NAME' | translate}}</th>
                  </tr>
                </ng-template>
                <!-- Body Content -->
                <ng-template pTemplate="body" let-vechile>
                  <tr>
                    <td>
                      <p-checkbox [(ngModel)]="vechile.Id === userId || vechile.status"
                        [disabled]="vechile.Id === userId" (ngModelChange)="checked(vechile)"
                        [ngModelOptions]="{standalone: true}" binary="true">
                      </p-checkbox>
                    </td>
                    <td>
                      <span>{{vechile.Name}}</span>
                    </td>
                  </tr>
                </ng-template>
                <!-- Empty message -->
                <ng-template pTemplate="emptymessage" let-columns>
                  <tr>
                    <td colspan="2">
                      {{'NO_RECORDS_FOUND'|translate}}
                    </td>
                  </tr>
                </ng-template>
              </p-table>
              <!-- Table ends -->
            </div>
            <div class="form-group">
              <label for="EmailDevaition">
                <span translate>EMAIL_NEWDEVIATION_LABEL</span>
              </label>

              <div *ngIf="bindSelectedUsers">
                <p-multiSelect defaultLabel="{{'SELECT'|translate}}" [options]="userSubscribers"
                  formControlName="selectedEmailSubscriber" [style]="{'width':'100%'}">
                </p-multiSelect>
              </div>
              <div *ngIf="bindAllUsers">
                <p-multiSelect defaultLabel="{{'SELECT'|translate}}" [dataKey]="'Id'" [options]="selectedSubscribers"
                  formControlName="selectAllEmailSubscriber" [style]="{'width':'100%'}">
                </p-multiSelect>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<p-dialog header="{{'DEPARTMENT'|translate}}" [(visible)]="addDept" [modal]="true"
[style]="{width: '300px'}" [resizable]="false" [draggable]="false">
  <form [formGroup]="DeptForm">
    <div class="form-group">
      <label for="title" class="col-form-label">
        <span translate>NAME</span>
      </label>
      <div>
        <input type="text" class="label-input form-control" [(ngModel)]="selectedDepartment.Name"
          formControlName="Name">
      </div>
    </div>
    <p-footer>
      <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
        <button type="button" class="btn btn-primary" (click)="saveDept()" [disabled]="!DeptForm.valid">
          <span translate>SAVE</span>
        </button>
        <button type="button" class="btn btn-outline-primary" (click)="showDept = !showDept">
          <span translate>VIEW</span>
        </button>
      </div>
      <div *ngIf="showDept">
        <div class="table-view">
          <!-- Table starts -->
          <p-table [value]="editDepartments" [rows]="10" [paginator]="true" [pageLinks]="3"
            [rowsPerPageOptions]="[5,10,20]" [responsive]="true">
            <!-- Header -->
            <ng-template pTemplate="header">
              <tr>
                <th>{{'DEPARTMENT_NAME'|translate}}</th>
                <th></th>
              </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-dept>
              <tr>
                <td>{{dept.Name}}</td>
                <td class="col-button">
                  <button type="button" (click)="editDept(dept)" class="btn btn-icon" title="{{'EDIT'|translate}}">
                    <i class="icon ic-sm icon-pencil-tip"></i>
                  </button>
                  <button type="button" class="btn btn-icon" title="{{'DELETE'|translate}}"
                    (click)="deleteDepartment(dept)">
                    <i class="icon ic-sm icon-trash"></i>
                  </button>
                </td>
              </tr>
            </ng-template>
          </p-table>
          <!-- Table ends -->
        </div>
      </div>
    </p-footer>
  </form>
</p-dialog>
<toaster-component></toaster-component>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
  acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
