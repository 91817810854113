import { MoveBusinessRoutes } from './move-business/move-business.routing';
import { EditorContactsRoutes } from './editorcontacts/editorcontacts.routing';
import { AdminUserRoutes } from './adminuser/adminuser.routing';
import { userRoutes } from './users/user.routing';
import { EmployeeAdminRoutes } from './employee-admin/employee-admin.routing';

import { activityLogRoutes } from './activitylog/activity.routing';
import { accessLogRoutes } from './accesslog/accesslog.routing';
import { productionRoutes } from './production/production.routing';
import { trashRoutes } from './trash/trash.routing';
import { crewroutes } from './crew-list/crew-list.routing';
import { MyPageRoutes } from './my-page/my-page.routing';
import { SubContractorRoutes } from './contractor/contractor.routing';
import { foodsafetyRoutes } from './foodsafety/foodsafety.routing';
import { kubaControlRoutes } from './kuba-control/kuba-control-routing.module';
import { clientRoutes } from './client/client.routing';
import { SupplierRoutes } from './Suppliers/supplier.routing';
import { Route } from '@angular/router';
import { dashboardRoutes } from './dashboard/dashboard.routing';
import { KubaSubComponent } from './kuba.sub.component';
import { businessRoutes } from './businesses/businesses.routing';
import { manualsRoutes } from './manuals/manual.routing';
import { newsRoutes } from './news/news.routing';
import { helpsupportRoutes } from './help-support/help-support.routing';
import { moduleoverviewRoutes } from './module-overview/module-overview.routing';
import { MyFeaturesRoutes } from './my-features/my-features.routing';
import { customizehomepageRoutes } from './customize-homepage/customize-homepage.routing';
import { followupRoutes } from './follow-ups/followups.routing';
import { contactRoutes } from './contact/contact.routing';

import { deviationRoutes } from './deviation/deviation.routing';
import { projectRoutes } from './projects/project.routing';
import { employeeRoutes } from './employees/employees.routing';
import { portalRoutes } from './portals/portals.routing';
import { fdvDocumentRoutes } from './fdv-Document/fdv-doc.routing';
import { taskmanagerRoutes } from './task-manager/task-manager.routing';
import { safetyjobRoutes } from './safety-job-analysis/jobanalysis.routing';
import { registrationRoutes } from './Registration/registration.routing';
import { timetableRoutes } from './time-table/timetable.routing';
import { aboutRoutes } from './about/about.routing';
import { fdvRoutes } from './FDV/fdv.routing';
import { internalcontrolRoutes } from './internal-control/internal-control.routing';
import { ReceptionControlRoutes } from './reception-control/reception-control.routing';
import { ManualControlRoutes } from './manual-control/manual-control.routing';
import { elRoutes } from './kunde-EL/el.routing';
import { measureprotocolRoutes } from './measure-protocol/measure-protocol.routing';

// vehicle Routes
import { vehicleRoutes } from './vehicle/vehicle.routing';
import { vehicleChecklistRoutes } from './vehicle-checklist/vehicleChecklist.routing';
import { vehicleConsumptionRoutes } from './vehicle-consumption/vehicle-consumption.routing';
import { vehicleControlRoutes } from './vehicle-control/vehicle-control.routing';
import { vehicleTaskRoutes } from './vehicle-task/vehicleTask.routing';
import { CompleteCheckListRoutes } from './checklist-complete/checklist-complete.routing';
import { archiveRoutes } from './archive/archive.routing';
import { LogListRoutes } from 'src/app/kuba/log/log.routing';
import { PotalLinkRoutes } from 'src/app/kuba/links/links.routing';
import { completeChecklistRoutes } from './shared/kuba-complete-checklist/kuba-complete-checklist-routing';
import { notificationUserRoutes } from './notificationuser/notificationuser.routing';
import { taskManagerNorecordRoutes } from 'src/app/kuba/shared/taskmanagernorecords/taskmanagernorecords.routing';
import { electroRoutes } from './electro/electro.routing';
import { licenseAgreementRoute } from './license-agreement/license-agreement.routing';
import { SjaFormRoutingModule } from './sja-form/sja-form-routing.module';
import { OtherSystemRoutes } from './other-system/other-system-routing.module';
import { qualitySystemRoutes } from './quality-system/quality-system-routing.module';
import { flexiRoutes } from './flexi/flexi.routing';

export const kubaSubRoutes: Route[] = [
  {
    path: '',
    component: KubaSubComponent,
    children: [
      ...dashboardRoutes,
      ...businessRoutes,
      ...userRoutes,
      ...manualsRoutes,
      ...newsRoutes,
      ...helpsupportRoutes,
      ...moduleoverviewRoutes,
      ...MyFeaturesRoutes,
      ...fdvRoutes,
      ...customizehomepageRoutes,
      ...portalRoutes,
      ...followupRoutes,
      ...contactRoutes,
      ...safetyjobRoutes,
      ...SjaFormRoutingModule,
      ...deviationRoutes,
      ...projectRoutes,
      ...employeeRoutes,
      ...registrationRoutes,
      ...timetableRoutes,
      ...SubContractorRoutes,
      ...vehicleRoutes,
      ...vehicleChecklistRoutes,
      ...vehicleConsumptionRoutes,
      ...vehicleControlRoutes,
      ...vehicleTaskRoutes,
      ...clientRoutes,
      ...foodsafetyRoutes,
      ...kubaControlRoutes,
      ...productionRoutes,
      ...SupplierRoutes,
      ...internalcontrolRoutes,
      ...OtherSystemRoutes,
      ...qualitySystemRoutes,
      ...MyPageRoutes,
      ...crewroutes,

      ...taskmanagerRoutes,

      ...trashRoutes,
      ...ManualControlRoutes,
      ...ReceptionControlRoutes,
      ...accessLogRoutes,
      ...activityLogRoutes,

      ...EmployeeAdminRoutes,
      ...AdminUserRoutes,
      ...EditorContactsRoutes,
      ...fdvDocumentRoutes,
      ...CompleteCheckListRoutes,
      ...elRoutes,
      ...MoveBusinessRoutes,
      ...archiveRoutes,
      ...measureprotocolRoutes,
      ...PotalLinkRoutes,
      ...LogListRoutes,
      ...aboutRoutes,
      ...notificationUserRoutes,
      ...taskManagerNorecordRoutes,
      ...completeChecklistRoutes,
      ...electroRoutes,
      ...licenseAgreementRoute,
      ...flexiRoutes,
    ],
  },
];
