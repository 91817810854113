import { Module } from 'src/app/kuba/follow-ups/models/deviation';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { IMyDpOptions } from 'mydatepicker';

import { Rights } from './../_models/feature';
const StringBuilder = require('string-builder');
let jsPDF = require('jspdf');
require('jspdf-autotable');

interface DateParts {
  day: string;
  month: string;
  year: string;
}

@Injectable()
export class HelperService {
  static formatDate(dateTime: any, pickerDate = null, delimiter = '/') {
    let time = '';
    if (pickerDate) {
    }
    if (dateTime) {
      let date = new Date(dateTime);
      if (date) {
        let formattedMonth =
          date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
        let formattedDate =
          date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
        time = `${formattedMonth}${delimiter}${formattedDate}${delimiter}${date.getFullYear()}`;
      }
    }
    return time ? time : '';
  }

  static formatDateToUS(dateStr: string): string {
    const [day, month, year] = dateStr.split('/').map(Number);
    const date = new Date(year, month - 1, day); // month is 0-based in Date()
    return date.toLocaleDateString('en-US'); // This will give 'mm/dd/yyyy' format
  }
  static formatDate1(dateString: any) {
    let parts = dateString.split('-');
    if (parts.length === 3) {
      return parts[0] + '.' + parts[1] + '.' + parts[2];
    }
    return dateString; // Return original date string if format is not as expected
  }
  static formatDate2(dateString: any) {
    let parts = dateString.split('/');
    if (parts.length === 3) {
      return parts[0] + '.' + parts[1] + '.' + parts[2];
    }
    return dateString; // Return original date string if format is not as expected
  }

  static formatDate4(dateString: any) {
    let parts = dateString.split('/');
    if (parts.length === 3) {
      return parts[1] + '.' + parts[0] + '.' + parts[2];
    }
    return dateString; // Return original date string if format is not as expected
  }

  static formatDate3(dateString: any) {
    let datePart = dateString.split('T')[0];
    let parts = datePart.split('-');
    if (parts.length === 3) {
      return parts[2] + '.' + parts[1] + '.' + parts[0];
    }
    return dateString;
  }
  static formatDate5(dateString: any) {
    let datePart = dateString.split('T')[0];
    let parts = datePart.split('-');
    if (parts.length === 3) {
      return parts[1] + '.' + parts[2] + '.' + parts[0];
    }
    return dateString;
  }

  static formatDateForFilter(
    dateTime: string | number | Date,
    pickerDate = null,
    delimiter = '-'
  ) {
    let time = '';
    if (pickerDate) {
    }
    if (dateTime) {
      let date = new Date(dateTime);
      if (date) {
        let formattedMonth =
          date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
        let formattedDate =
          date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
        time = `${formattedMonth}${delimiter}${formattedDate}${delimiter}${date.getFullYear()}`;
      }
    }
    return time ? time : '';
  }

  static formatDateTo(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

/**
 * Splits a date string into its constituent parts.
 * 
 * This private utility function takes a date string and splits it into day, month,
 * and year components based on the specified delimiter.
 * 
 * @param dateString - The date string to be split. Expected format: 'mm/dd/yyyy'.
 * @param delimiter - The delimiter used in the date string. Defaults to '/'.
 * @returns An object containing the day, month, and year as strings, or null if the input is invalid or empty.
 */
private static splitDateParts(dateString: string, delimiter = '/') {
  if (dateString) {
    const date = dateString.split(delimiter);
    const day = date[0];
    const month = date[1];
    const year = date[2];

    return { day, month, year } as DateParts;
  }
  return null;
}

  /**
   * Converts a date string into a formatted date string.
   *
   * This function takes a date string, splits it into its constituent parts,
   * and then returns a formatted date string using the specified delimiter.
   *
   * @param dateString {string} The date string to be formatted. Expected format: 'mm/dd/yyyy'.
   * @param delimiter {string} The delimiter to use in the formatted date string. Defaults to '/'.
   * @returns {string} The formatted date string in 'mm/dd/yyyy' format, or an empty string if the input is invalid.
   */
  static formatDateFilter(dateString: string, delimiter = '/') {
    const dateObject = this.splitDateParts(dateString, '/');
    return dateObject
      ? `${dateObject.month}${delimiter}${dateObject.day}${delimiter}${dateObject.year}` // mm/dd/yyyy format
      : '';
  }

  static getYearFilter(dateString: any, delimiter = '') {
    let dateObject = this.splitDateParts(dateString, '/');
    return dateObject
      ? dateObject.year
      : // mm/dd/yyyy format
        '';
  }

  static formateDateFilterYMD(dateString: any, delimiter = '/', rev = 0) {
    let dateObject = this.splitDateParts(dateString, '/');
    if (rev === 0) {
      return dateObject
        ? `${dateObject.year}${delimiter}${dateObject.month}${delimiter}${dateObject.day}` // yyyy/mm/dd format
        : '';
    } else {
      return dateObject
        ? `${dateObject.year}${delimiter}${dateObject.day}${delimiter}${dateObject.month}` // yyyy/mm/dd format
        : '';
    }
  }

  static formatTime(dateTime: string | number | Date) {
    let time = '';
    let date;

    if (dateTime) {
      date = new Date(dateTime);
      if (!date || isNaN(date.getTime())) {
        // setting a default date  to get time
        date = new Date(`1970-01-01 ${dateTime}`);
      }
      time = `${date.getHours()}:${date.getMinutes()}`;
    } else {
      time = '';
    }
    return time ? time : '';
  }

  static getDaysDiff(StartDate: Date, EndDate: Date) {
    if (StartDate && EndDate) {
      let _MS_PER_DAY = 1000 * 60 * 60 * 24;
      let date1 = Date.UTC(
        StartDate.getFullYear(),
        StartDate.getMonth(),
        StartDate.getDate()
      );
      let date2 = Date.UTC(
        EndDate.getFullYear(),
        EndDate.getMonth(),
        EndDate.getDate()
      );
      let getDays: any = Math.floor((date2 - date1) / _MS_PER_DAY);
      return getDays;
    }
  }

  public dateRangeValidator(startDate: Date, endDate: Date) {
    if (startDate && endDate) {
      if (new Date(endDate) < new Date(startDate)) {
        let errorMsg: any = {
          isError: true,
          errorMessage: 'End Date cannot before start date',
        };
        return errorMsg;
      }
      return null;
    }
  }

  /**
   * calculating HoursSpent
   * @param StartDate {{date}}
   * @param EndDate {{date}}
   * @param StartTime {{date}}
   * @param EndTime {{date}}
   */
  static calculateHourSpent(
    StartDate: Date,
    EndDate: Date,
    StartTime: Date,
    EndTime: Date
  ) {
    let date1 = new Date(
      StartDate.getFullYear(),
      StartDate.getMonth(),
      StartDate.getDate(),
      StartTime.getHours(),
      StartTime.getMinutes(),
      StartTime.getSeconds(),
      StartTime.getMilliseconds()
    );
    let date2 = new Date(
      EndDate.getFullYear(),
      EndDate.getMonth(),
      EndDate.getDate(),
      EndTime.getHours(),
      EndTime.getMinutes(),
      EndTime.getSeconds(),
      EndTime.getMilliseconds()
    );
    let timeStart = new Date(date1).getTime();
    let timeEnd = new Date(date2).getTime();
    let hourDiff = Math.abs(timeEnd - timeStart); // in ms
    // let secDiff = hourDiff / 1000; // in s
    let minDiff = Math.round(((hourDiff % 86400000) % 3600000) / 60000); // in minutes
    let hDiff = hourDiff / 3600 / 1000; // in hours
    let hours = Math.floor(hDiff);
    let minutes;
    if (minDiff === 60) {
      minutes = '00';
      hours = hours + 1;
    } else {
      minutes = minDiff.toString().length === 1 ? `0${minDiff}` : minDiff;
    }

    return `${hours}:${minutes}`;
  }
  static decimalCalculation(data: { Cost: any }) {
    let number = data.Cost;
    if (number) {
      return number.toFixed(2);
    }
  }

  static addTotalPrice(data: any[], columnName: string) {
    let totalPrice = 0;
    data.forEach((element: { PricePerMonth: number }) => {
      if (element) {
        totalPrice += element.PricePerMonth;
      }
    });
    return totalPrice;
  }

  static addTimes(
    timeMap: { [x: string]: { [x: string]: string } },
    columnName: string
  ) {
    let totalH = 0;
    let totalM = 0;

    // First simply adding all of it together, total hours and total minutes
    for (let x in timeMap) {
      if (x) {
        if (timeMap[x][columnName] !== null && timeMap[x][columnName] !== '') {
          let timeArray = timeMap[x][columnName].split(':');
          let hour = timeArray[0];
          let minutesValue = timeArray[1];
          if (minutesValue === undefined) {
            var minutes = '00';
          } else {
            minutes = timeArray[1];
          }
          totalH += parseInt(hour, 10);
          totalM += parseInt(minutes, 10);
        }
      }
    }

    // If the minutes exceed 60
    if (totalM >= 60) {
      // Divide minutes by 60 and add result to hours
      totalH += Math.floor(totalM / 60);
      // Add remainder of totalM / 60 to minutes
      totalM = totalM % 60;
    }
    return `${totalH}:${
      totalM.toString().length === 1 ? `0${totalM}` : totalM
    }`;
  }

  /**
   * create pdf with given json and column names
   * @param data {json}
   * @param columnArray {columns with key}
   * @param fileName {string}
   */
  static generatePdf(
    data: any,
    columnArray = [],
    fileName: string,
    pdfSetting: PdfSetting = null!,
    defaultOrientation = 'p'
  ) {
    let doc = new jsPDF(defaultOrientation, 'pt');
    let filteredData = JSON.stringify(data, (key, value) => {
      // if value is null, return "" as a replacement
      if (value === null) {
        return '';
      }

      // otherwise, leave the value unchanged
      return value;
    });
    doc.autoTable(columnArray, JSON.parse(filteredData), {
      headerStyles: { align: 'center' },
      margin: { top: 60 },
      addPageContent: function (data: any) {
        if (pdfSetting) {
          doc.setFontSize(15);
          doc.text(pdfSetting.pageHeader, 340, 30);
          doc.setFontSize(10);
          doc.text(pdfSetting.date, 650, 50);
          doc.setFontSize(10);
          doc.text(pdfSetting.businessName, 40, 50);
        }
      },
    });
    doc.save(`${fileName}.pdf`);
  }

  static generateCheckListPdf(
    data: any,
    columnArray = [],
    fileName: string,
    pdfSetting: PdfSetting = null!,
    defaultOrientation = 'p'
  ) {
    let doc = new jsPDF(defaultOrientation, 'pt');
    let filteredData = JSON.stringify(data, (key, value) => {
      // if value is null, return "" as a replacement
      if (value === null) {
        return '';
      }

      // otherwise, leave the value unchanged
      return value;
    });
    doc.autoTable(columnArray, JSON.parse(filteredData), {
      headerStyles: { align: 'center' },
      margin: { top: 100 },
      addPageContent: function (data: any) {
        if (pdfSetting) {
          doc.setFontSize(15);
          doc.text(pdfSetting.pageHeader, 340, 30);
          doc.setFontSize(10);
          doc.text(pdfSetting.date, 650, 50);
          doc.setFontSize(10);
          doc.text(pdfSetting.businessName, 40, 50);
          doc.setFontSize(10);
          doc.text(pdfSetting.userName, 40, 70);
          doc.setFontSize(10);
          doc.text(pdfSetting.checkListName, 40, 90);
          doc.setFontSize(10);
        }
      },
    });
    doc.save(`${fileName}.pdf`);
  }

  static generatePdfSafetyJobAnalysis(
    safetyData: any,
    columnArray = [],
    fileName: string,
    pdfSetting: PdfSetting = null!,
    defaultOrientation = 'p',
    htmlDiv: any
  ) {
    let doc = new jsPDF(defaultOrientation, 'pt');
    let filteredData = JSON.stringify(safetyData, (key, value) => {
      // if value is null, return "" as a replacement
      if (value === null) {
        return '';
      }
      // otherwise, leave the value unchanged
      return value;
    });
    doc.autoTable(columnArray, safetyData, {
      headerStyles: { align: 'center' },
      margin: { top: 60 },
      drawCell: function (
        cell: { x: any; y: any; width: any; height: any },
        data: {
          column: { dataKey: any };
          row: { raw: { RiskFigureData: { BackColor: any } } };
        }
      ) {
        if (data.column.dataKey === 'RiskFigure.value') {
          // Rowspan
          let hex = data.row.raw.RiskFigureData.BackColor;
          if (hex) {
            hex = hex.replace('#', '');
            let r = parseInt(hex.substring(0, 2), 16);
            let g = parseInt(hex.substring(2, 4), 16);
            let b = parseInt(hex.substring(4, 6), 16);
            doc.setFillColor(r, g, b);
          }
          doc.setDrawColor(0);
          // doc.rect(cell.x + 5, cell.y + 10, 10, 10, 'F');
          doc.rect(cell.x, cell.y, cell.width, cell.height, 'F');
          doc.setTextColor(255, 255, 255);
          doc.setFontType('bold');
          doc.autoTableText(
            data.row.raw.RiskFigureData.BackColor,
            cell.x + 25,
            cell.y + 10,
            {
              halign: 'middle',
              valign: 'middle',
            }
          );
          let generatePdf: any = false;
          return generatePdf;
        }
      },
      addPageContent: function (data: any) {
        if (pdfSetting) {
          doc.setFontSize(15);
          doc.text(pdfSetting.pageHeader, 340, 30);
          doc.setFontSize(10);
          doc.text(pdfSetting.date, 650, 50);
          doc.setFontSize(10);
          doc.text(pdfSetting.businessName, 40, 50);
        }
      },
    });
    doc.save(`${fileName}.pdf`);
  }

  static getlength(val: string) {
    let strVal = new StringBuilder();
    if (val.toString().length === 1) {
      return strVal.append('0' + val);
    }
    return val;
  }

  /**
   *
   * @param date date to format
   * @returns
   */
  static formatInputDate(date: any) {
    if (date != null) {
      let dateFormat = new Date(date);
      let formatDate: any = {
        date: {
          year: dateFormat.getFullYear(),
          month: dateFormat.getMonth() + 1,
          day: dateFormat.getDate(),
        },
        formatted: `${dateFormat.getDate()}/${this.getlength(
          (dateFormat.getMonth() + 1).toString()
        )}/${dateFormat.getFullYear()}`,
        jsDate: dateFormat,
        jsdate: dateFormat,
      };
      // return {
      //     date: {
      //         year: dateFormat.getFullYear(),
      //         month: dateFormat.getMonth() + 1,
      //         day: dateFormat.getDate()
      //     },
      //     formatted: `${dateFormat.getDate()}/${this.getlength((dateFormat.getMonth() + 1).toString())}/${dateFormat.getFullYear()}`,
      //     jsDate: dateFormat,
      //     jsdate: dateFormat
      // };
      return formatDate;
    }
  }

  static formatDisableDate(date) {
    if (date != null) {
      let dateFormat = new Date(date);
      let res: any = {
        date: {
          year: dateFormat.getFullYear(),
          month: dateFormat.getMonth() + 1,
          day: dateFormat.getDate() - 1,
        },
      };
      return res;
    }
  }

  static formatInputDateToDatetime(date: any) {
    if (date != null) {
      // return new Date(date);
      let ToTime = new Date(date);
      let dateToTime: any = ToTime;
      return dateToTime;
    }
  }

  static setDateRange(
    dateConfig: IMyDpOptions,
    date: Date,
    type: string
  ): IMyDpOptions {
    // Get new copy of options in order the date picker detect change
    let copyConfig: IMyDpOptions = this.getCopyOfDatePickerOptions(dateConfig);
    if (type === 'startDate') {
      if (date) {
        // set previous of selected date
        date.setDate(date.getDate() - 1);
        copyConfig.disableUntil = {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate(),
        };
      } else {
        copyConfig.disableSince = { year: 0, month: 0, day: 0 };
        copyConfig.disableUntil = { year: 0, month: 0, day: 0 };
      }
    } else {
      if (date) {
        // set previous of selected date
        date.setDate(date.getDate() + 1);
        copyConfig.disableSince = {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate(),
        };
      } else {
        copyConfig.disableSince = { year: 0, month: 0, day: 0 };
      }
    }
    return copyConfig;
  }

  static getCopyOfDatePickerOptions(
    myDatePickerOptions: IMyDpOptions
  ): IMyDpOptions {
    return JSON.parse(JSON.stringify(myDatePickerOptions));
  }

  static checkRightsData(rightsData: any, rightsId: number): boolean {
    let userRight = false;
    if (rightsData && rightsData.length > 0) {
      for (let x = 0; x < rightsData.length; x++) {
        let element = rightsData[x];
        let id = +element.id;
        if (id === rightsId) {
          return element.isChecked;
        }
      }
    }
    return userRight;
  }

  static ApprovalPersonRightsCheck(rightsData: any, rightsId: number): boolean {
    let userRight = false;
    let activeAccount = false;
    if (rightsData && rightsData.length > 0) {
      for (let x = 0; x < rightsData.length; x++) {
        let element = rightsData[x];
        let id = +element.id;
        if (id === Rights.ACTIVE_ACCOUNT) {
          if (element.isChecked) {
            activeAccount = true;
          } else {
            return userRight;
          }
        }
        if (activeAccount) {
          if (id === rightsId) {
            activeAccount = false;
            return element.isChecked;
          }
        }
      }
    }
    return userRight;
  }

  static featureBasedCheck(featureData: any, featureId: number): boolean {
    let userRight = false;
    let activeAccount = false;
    if (featureData && featureData.length > 0) {
      for (let x = 0; x < featureData.length; x++) {
        let element = featureData[x];
        let id = +element.id;
        if (id === Module.PROJECT) {
          if (element.checked) {
            activeAccount = true;
          } else {
            return userRight;
          }
        }
        if (activeAccount) {
          if (id === featureId) {
            activeAccount = false;
            return element.checked;
          }
        }
      }
    }
    return userRight;
  }
  static switchKeysCaseForDetail(
    obj: { [x: string]: any; hasOwnProperty: (arg0: string) => any },
    keyBindings?: any,
    keyCase = 'U'
  ) {
    let k = '';
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        let k = key;
        let v = obj[key];
        let type = typeof v;
        if (type === 'object') {
          if (v !== null && v.length >= 0) {
            v.forEach((element: any) => {
              this.switchKeysCase(element, keyBindings);
            });
          } else if (v !== null) {
            this.switchKeysCase(v, keyBindings);
          }
        } else {
          // this.switchKeysCase(key, keyBindings);
          let k = '';
          // Object.keys(obj).forEach(key => {
          if (keyCase === 'U') {
            k = _.upperFirst(key);
          } else {
            k = _.lowerFirst(key);
          }
          if (keyBindings && keyBindings[k]) {
            k = keyBindings[k];
          }
          if (k !== key) {
            obj[k] = obj[key];
            delete obj[key];
          }
          // });
        }
      }
    }
    return obj;
  }
  /**
   * convert keys to title-case
   */
  static switchKeysCase(
    obj: { [x: string]: any },
    keyBindings?: any,
    keyCase = 'U'
  ) {
    let k = '';
    Object.keys(obj).forEach((key) => {
      if (keyCase === 'U') {
        k = _.upperFirst(key);
      } else {
        k = _.lowerFirst(key);
      }
      if (keyBindings && keyBindings[k]) {
        k = keyBindings[k];
      }
      if (k !== key) {
        obj[k] = obj[key];
        delete obj[key];
      }
    });
    return obj;
  }

  static numberFieldValidation(e: any, limitNumber: any) {
    const limit = limitNumber;
    let charCode = e.which ? e.which : e.keyCode;
    if (
      e.target.value.length === limit ||
      (charCode > 31 && (charCode < 48 || charCode > 57))
    ) {
      e.preventDefault();
    }
  }
  static numberFieldValidationWithoutLimit(e: any) {
    let charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
    }
  }
  static filterArrayAgainstArray(
    dataList: any,
    filterList: any,
    nodeName: any
  ) {
    return dataList.filter((dl: { [x: string]: string | any[] }) => {
      return filterList.some((f: any) => {
        return dl[nodeName].indexOf(f) > -1;
      });
    });
  }

  /**
   * filters the list recursively
   * @param filterText {string}
   * @param list {any}
   * @param nodeName {string}
   */
  static filterTree(filterText: string, list: any, nodeName: string) {
    return _.filter(list, (item) => {
      if (
        !item.children &&
        _.includes(_.toLower(item[nodeName]), _.toLower(filterText))
      ) {
        let filterTrees: any = true;
        return filterTrees;
      } else if (item.children) {
        item.children = this.filterTree(filterText, item.children, nodeName);
        return !_.isEmpty(item.children);
      }
    });
  }

  static getCurrentData() {
    let today = new Date();
    let dd = String(today.getDate());
    let mm = String(today.getMonth() + 1); //January is 0!
    let yyyy = today.getFullYear();
    let currentDate = mm + '/' + dd + '/' + yyyy;
    return currentDate;
  }

  static dateFormatMMDDYY(stringDate: any) {
    let replaceDate = stringDate.replace('/', '-').replace('/', '-');
    let splitDate = replaceDate.split('-');
    let formattedDate: any;
    let date = splitDate[0];
    let month = splitDate[1];
    let year = splitDate[2];
    return (formattedDate = month + '/' + date + '/' + year);
  }

  /**
   * Validates if a string is a valid JSON. Also has handling for invalid JSON-strings
   * @param input String validate for JSON
   * @returns JSON-object if string is valid JSON. Null if invalid JSON
   */
  static validateJson(input: string): JSON | null {
    let result: JSON;
    try {
      result = JSON.parse(input);
      return result;
    } catch {
      return null;
    }
  }
}

export class PdfSetting {
  pageHeader?: string;
  businessName?: string;
  date?: string;
  pagination?: number;
  columnStyle?: any;
  userName?: string;
  checkListName?: string;
}
