import { Module } from './../../../follow-ups/models/deviation';
import {
  Component,
  ViewChild,
  OnInit,
  ViewContainerRef,
  ComponentRef,
} from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Params } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';

import { UploaderConfig } from './../../../../shared/ecpl-document-explorer/uploader-config';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { Topic } from '../../../safety-job-analysis/models/topic';
import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { DeviationServices } from '../../services/deviation.service';
import { HelperService } from './../../../../_services/helper.service';
import { BaseServices } from './../../../kuba.services';
import { ToasterComponent } from './../../../../_directives/toaster.component';
import { Options } from './../../../../_models/options';
import { DeviationShared } from '../../models/';
import { Auditlogdata } from '../../../safety-job-analysis/models/safetyLog';
import { deviationCaseType } from './../../models/deviationCaseType';
import { Rights } from 'src/app/_models';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { Department } from './../../models/deviationDepartment';
import { BusinessDepartmentService } from 'src/app/kuba/business-department.services';
import { Subscription } from 'rxjs';
import { EmployeeServices } from 'src/app/kuba/employees/services/employee.services';
import { AddDeviationComponent } from 'src/app/kuba/follow-ups/components';
import { DateService } from 'src/app/_services/date.service';

let jsPDF = require('jspdf');

@Component({
  selector: 'editDeviation',
  templateUrl: 'deviation-edit.component.html',
  styleUrls: ['deviation-edit.component.scss'],
})
export class EditDeviationComponent implements OnInit {
  //#region variables
  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  time: any;
  CreatedBy: any;
  CreatedOn: any;
  deviationId: number;
  public PageTitle: any;
  projectId: number;
  editTypes: any = [];
  topicList: any = [];
  caseId: number;
  topicId: number;
  deviationList: any;
  deviationEditForm: FormGroup | any;
  caseForm: FormGroup;
  isDepartmentAllocated = false;
  TopicForm: FormGroup;
  isHideFS = true;
  deviationLog: Auditlogdata[];
  deviationlist: DeviationShared[];
  isCaseDialog = false;
  isAddTopic = false;
  topics: SelectItem[];
  departments: SelectItem[];
  importances: SelectItem[];
  cases: SelectItem[];
  executions: SelectItem[];
  followUps: SelectItem[];
  followUpNotify: any;
  selectedFollowup: string;
  pdf: any;
  selectedCaseType = new deviationCaseType();
  selectedTopic = new Topic();
  console: Options;
  parentKey: any;
  parentId: any;
  departmentList: any[];
  data: any;
  options = [
    new Options(8, 'OPEN'),
    new Options(9, 'DONE'),
    new Options(10, 'REJECTED'),
  ];
  hidingElement = false;
  viewTopic = false;
  viewDashboard = false;
  isLabelDisabled: Boolean;
  isTopicalertDisabled: Boolean;
  upConfig: UploaderConfig;
  uploadedFiles: any = [];
  additionalData: any;
  newDocumentAdded: any = [];
  isShowDeviation = true;
  canShowDeviation: boolean;
  followup: any;
  loading = false;
  AddTopicLoading = false;
  manualListItems: SelectItem[];
  isShowManualList = false;
  manualID = 0;
  deviationFeatureKey = 0;
  isFollowup = false;
  parentPath: string;
  loadingReport = false;
  deviationNumberPrefix: string;
  deviationNumber: number;
  featureManualChecklistId: number;
  appManualChecklistId: number;
  activityId: number;
  createdOnForSave: string;
  createdByForSave: number;
  FeatureId: number;
  isGuestHideButton = true;
  topicLabel: any;
  departmentsLabel: any;
  caseLabel: any;
  importanceLabel: any;
  execLabel: any;
  followLabel: any;
  featureId: number;
  featureKey: number;
  followUpAssignedUsers: any[];
  disableSaveButton = false;
  executionId: number;
  followUpId: number;
  currentDeadLine: Date;
  currentDate: Date;
  dateDiff: number;
  featureCompleteChecklistId = 0;
  BusinessId: number;
  IsClear = false;
  isShowNotification = true;
  isExistCaseText: string;
  isFoodSafety = false;
  isShowOnlyEditors: boolean;
  Reference: boolean;
  department = false;
  selectedDepartment = new Department();
  departmentForm: FormGroup;
  private subscriptions: Subscription[] = [];
  NotifyMyDeviationsChange: boolean;
  showDeviationLog: boolean;
  IsSubDepartmentHidden = true;
  subDepartmentsList: SelectItem[];
  editSubDepartments: SelectItem[];
  @ViewChild('addSubDeptViewRef', { read: ViewContainerRef })
  addSubDeptModalContainer!: ViewContainerRef;
  addSubDeptComponentRef: ComponentRef<AddDeviationComponent> | null = null;

  //#endregion

  isDepartmentErrorDisabled: boolean;
  deptId: number;
  formDeviation: FormGroup;
  Department: SelectItem[];
  departmentdetails: Department[];
  hidesElement = false;
  //#endregion

  //#region constructor
  /**
   * constructor
   * @param router {Router}
   * @param route {ActivatedRoute}
   * @param deviationService {DeviationServices}
   * @param formBuilder {FormBuilder}
   * @param location {Location}
   * @param confirmationService {ConfirmationService}
   */
  constructor(
    private route: ActivatedRoute,
    private deviationService: DeviationServices,
    private formBuilder: FormBuilder,
    private location: Location,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private businessDepartmentService: BusinessDepartmentService,
    private datePipe: DatePipe,
    private employeeServices: EmployeeServices,
    private dateService: DateService
  ) {
    if (+BaseServices.roleId === 4) {
      this.isGuestHideButton = false;
    }
    let currentUserRole = BaseServices.UserRole;
    let userRightsId = Rights.ADMINISTRATE_DEVIATION;
    if (+BaseServices.roleId === 5) {
      this.isGuestHideButton = BaseServices.checkUserRights(
        userRightsId,
        currentUserRole
      );
    }

    this.parentId = route.snapshot.parent.parent.params['id'];
    this.parentKey = this.route.snapshot.parent.parent.data['parent'];
    if (this.parentKey == 'FS' || this.parentKey == 'KC')
      this.isFoodSafety = true;
    this.initForm();
    this.routePath();

    if (this.parentKey == 'FS' || this.parentKey == 'KC') {
      this.isShowOnlyEditors = false;
    } else {
      this.isShowOnlyEditors = true;
    }
  }

  /**
   * editing deviation and binding deviation data
   */
  ngOnInit(): void {
    document.querySelector('body').classList.remove('opened');
    this.isShowDeviation = BaseServices.roleId === '2' ? false : true;
    this.caseForm = this.formBuilder.group({
      Name: [
        '',
        [Validators.required, ValidationService.noWhitespaceValidator],
      ],
    });
    this.departmentForm = this.formBuilder.group({
      Name: [
        '',
        [Validators.required, ValidationService.noWhitespaceValidator],
      ],
    });
    this.TopicForm = this.formBuilder.group({
      Topic: [
        '',
        [Validators.required, ValidationService.noWhitespaceValidator],
      ],
    });
    this.route.parent.params.subscribe((params: Params) => {
      let pId = +params['id'];
      this.projectId = +pId;
    });
    this.bindFormDropdowns();
    this.console = this.options.filter((item) => item.id === 1)[0];
    this.subscriptions.push(
      this.route.params.subscribe((params: Params) => {
        this.deviationId = +params['deviationId'];
        this.additionalData = {
          ApplicationId: BaseServices.ApplicationId,
          BusinessId: BaseServices.BusinessId,
          Status: '1',
          CreatedOn: new Date(),
          ModifiedBy: BaseServices.UserId,
          ModifiedOn: new Date(),
          ParentId: this.parentId ? this.parentId : 0,
          ChildId: this.deviationId,
          ModuleName: 'Deviation',
        };
        if (this.parentKey) {
          this.upConfig = {
            title: 'DEVIATION_DOCUMENT',
            titleAsLabel: true,
            editMode: true,
            windowedHeight: false,
            viewSwitch: false,
            showUserMeta: false,
            showSearchFilter: false,
            showUploadButton: true,
            showDeleteButton: true,
            uploaderUri: `${environment.BASE_URL}/${BaseServices.apiRoute(
              this.parentKey
            )}/file-upload`,
            addtionalData: this.additionalData,
          };
        } else {
          this.upConfig = {
            title: 'DEVIATION_DOCUMENT',
            titleAsLabel: true,
            windowedHeight: false,
            viewSwitch: false,
            editMode: true,
            showUserMeta: false,
            showSearchFilter: false,
            showUploadButton: true,
            showDeleteButton: true,
            uploaderUri:
              environment.BASE_URL +
              '/file/upload/business/followup/deviation-docs',
            addtionalData: this.additionalData,
          };
        }
        if (this.deviationId) {
          let deviationInfo = this.route.snapshot.data['edit'];

          if (BaseServices.UserRole === 'Guest') {
            this.disableSaveButton =
              BaseServices.UserId === deviationInfo.ExecutionUserId
                ? false
                : BaseServices.UserId === deviationInfo.FollowupUserId
                ? false
                : true;
          }
          if (BaseServices.UserRole === 'User') {
            this.onAdministrateDeviation();
          }
          deviationInfo.Status =
            deviationInfo.Status && deviationInfo.Status === 7
              ? 8
              : deviationInfo.Status;
          deviationInfo.Status = deviationInfo.Status?.toString();
          deviationInfo.department = deviationInfo.BIds;
          deviationInfo.Comment = deviationInfo.Comment;
          deviationInfo.Importance = deviationInfo.Importance;
          this.featureCompleteChecklistId =
            deviationInfo.FeatureCompleteChecklistId;
          this.executionId = deviationInfo.ExecutionUserId;
          this.followUpId = deviationInfo.FollowupUserId;
          this.featureKey = deviationInfo.FeatureId;
          this.featureId = deviationInfo.FeatureKey;
          this.featureManualChecklistId = deviationInfo.FeatureManualChecklistId
            ? deviationInfo.FeatureManualChecklistId
            : 0;
          this.appManualChecklistId = deviationInfo.AppManualChecklistId
            ? deviationInfo.AppManualChecklistId
            : 0;
          this.activityId = deviationInfo.ActivityId
            ? deviationInfo.ActivityId
            : 0;
          this.FeatureId = deviationInfo.FeatureKey
            ? deviationInfo.FeatureKey
            : 0;
          this.deviationNumber = deviationInfo.DeviationNumber;
          this.deviationNumberPrefix = deviationInfo.DeviationNumberPrefix;
          this.bindSubDepartmentDropdown(deviationInfo.department);
          if (deviationInfo.SubDepartmentId > 0) {
            this.IsSubDepartmentHidden = false;
          }
          this.initForm(deviationInfo);
          if (
            (this.parentKey === 'FS' || this.parentKey == 'KC') &&
            deviationInfo.ObjectName !== null
          ) {
            this.isHideFS = false;
          } else {
            this.isHideFS = true;
          }
        }
      })
    );
    this.deviationLogData();
    if (
      BaseServices.UserId === this.followup &&
      (BaseServices.UserRole === 'User' || BaseServices.UserRole === 'Guest')
    ) {
      this.isShowDeviation = true;
      this.onAdministrateDeviation();
    }
    if (
      BaseServices.UserId === this.followUpId ||
      BaseServices.UserId === this.executionId
    ) {
      this.isShowDeviation = true;
    }
    if (+BaseServices.roleId === 2) {
      this.isGuestHideButton = false;
    }
    this.getBusinessDepartment();
  }

  initForm(data?: any) {
    let title = '';
    let department = '';
    let subDepartment = '';
    let Case = '';
    let topic = '';
    let importance = '';
    let cost = '';
    let deadline: any;
    let execution = '';
    let eventdate: any;
    let eventtime: any;
    let eventlocation = '';
    let description = '';
    let immediateAction = '';
    let analysis = '';
    let correctiveAction = '';
    let effectOfAction = '';
    let comment = '';
    let users = '';
    let status = '';
    let beforedeadline = '0';
    let beforefollowup = '0';
    let temperature = '';
    let objectName = '';
    let manual = '';
    this.createdByForSave = null;
    let reference = '';

    if (data) {
      this.Reference = data.Reference ? true : false;
      this.currentDeadLine = data.Deadline ? new Date(data.Deadline) : null;
      if (data.Status === 7) {
        this.isFollowup = false;
      }
      this.uploadedFiles = data.DeviationAttachment;

      deadline = data.Deadline ? new Date(data.Deadline) : null;

      title = data.Title;
      department = data.BIds;
      subDepartment = data.SubDepartmentId;
      topic = data.TopicId;
      importance = data.Importance;
      cost = data.Cost ? data.Cost : '0';
      eventdate = data.EventDate ? new Date(data.EventDate) : null;
      eventtime = HelperService.formatTime(
        data.EventTime ? data.EventTime : null
      );
      this.IsClear = eventdate !== null && eventtime !== null ? true : false;
      eventlocation = data.EventLocation;
      Case = data.CaseId;
      execution = data.ExecutionUserId;
      this.followup = data.FollowupUserId;
      description = data.Description;
      immediateAction = data.ImmediateAction;
      analysis = data.CauseAnalysis;
      correctiveAction = data.CorrectiveAction;
      effectOfAction = data.EffectOfAction;
      comment = data.Comment;
      temperature = data.TemperatureValue;
      objectName = data.ObjectName;
      users = data.SendOnlyToEditors;
      status = data.Status === 7 ? 8 : data.Status;
      this.PageTitle =
        data.Title +
        ' (' +
        this.translate.instant('DEVIATIONNO') +
        ': ' +
        this.deviationNumberPrefix +
        '-' +
        this.deviationNumber +
        ')' +
        (data.Modules ? '-' + data.Modules : '');

      this.CreatedBy = data.CreatorName;
      this.CreatedOn = new Date(data.CreatedOn).toLocaleDateString();
      this.time = new Date(data.CreatedOn).toLocaleTimeString();
      this.createdOnForSave = data.CreatedOn;
      this.createdByForSave = data.CreatedBy;
      beforedeadline = data.NotifyBeforeDeadLine
        ? data.NotifyBeforeDeadLine
        : '0';
      beforefollowup = data.NotificationBeforeFollowup
        ? data.NotificationBeforeFollowup
        : '0';
      manual = data.ManualId ? data.ManualId : '';
      reference = data.Reference ? data.Reference : null;

      // Debugging information
    }

    this.deviationEditForm = this.formBuilder.group({
      Title: new FormControl(title, [
        Validators.required,
        Validators.nullValidator,
      ]),
      Department: new FormControl(
        department,
        this.isFoodSafety ? [] : [Validators.required]
      ),
      SubDepartment: new FormControl(subDepartment),
      Topic: new FormControl(
        topic,
        this.isFoodSafety ? [] : [Validators.required]
      ),
      Importance: new FormControl(
        importance,
        this.isFoodSafety ? [] : [Validators.required]
      ),
      Cost: new FormControl(cost),
      Case: new FormControl(
        Case,
        this.isFoodSafety ? [] : [Validators.required, Validators.nullValidator]
      ),
      Execution: new FormControl(execution, [Validators.required]),
      Followup: new FormControl(this.followup, [Validators.required]),
      EventDate: new FormControl<Date | null>(eventdate),
      EventTime: new FormControl(eventtime),
      EventLocation: new FormControl(eventlocation),
      Description: new FormControl(description),
      ImmediateAction: new FormControl(immediateAction),
      Analysis: new FormControl(analysis),
      CorrectiveAction: new FormControl(correctiveAction),
      EffectOfAction: new FormControl(effectOfAction),
      Comment: new FormControl(comment),
      Reference: new FormControl(reference),
      days: this.formBuilder.group({
        Deadline: new FormControl<Date | null>(deadline, [
          Validators.required,
          Validators.nullValidator,
        ]),
        Notification: new FormControl(
          beforedeadline,
          [ValidationService.nonNegativeValidator],
          this.notificationdays.bind(this)
        ),
        FollowupNotification: new FormControl(beforefollowup),
      }),
      Users: new FormControl(users),
      Status: new FormControl(status),
      Temperature: new FormControl(temperature),
      ObjectName: new FormControl(objectName),
      Manual: new FormControl(manual),
    });

    // Explicitly set the Notification and FollowupNotification values after form initialization with a timeout
    setTimeout(() => {
      this.deviationEditForm
        .get('days')
        .get('Notification')
        .setValue(beforedeadline);
      this.deviationEditForm
        .get('days')
        .get('FollowupNotification')
        .setValue(beforefollowup);
    }, 0);
  }

  routePath() {
    this.parentPath = this.route.snapshot.parent.parent.routeConfig['path'];
    if (this.parentPath === 'follow-up') {
      this.isFollowup = true;
    } else {
      this.isFollowup = false;
    }
  }
  //#endregion
  //#region page-event

  //#endregion
  /**
   * cost key press event
   * @param e {e}
   * @param limitNumber {limitNumber}
   */
  onNumber(e: any, limitNumber: any) {
    const limit = limitNumber;
    const dots = e.target.value.indexOf('.');
    let charCode = e.which ? e.which : e.keyCode;
    if (
      e.target.value.length === limit ||
      dots > 1 ||
      (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46)
    ) {
      e.preventDefault();
    }
  }
  /**
   * file upload events
   * @param event {any}
   */
  saveUploaded(event: any) {
    if (event) {
      let eventType = event.eventName;
      let keyBindings = {
        Originalname: 'OriginalFileName',
        FileTypeId: 'FileType',
      };
      let deviationDoc = Object.assign({}, event, this.additionalData);
      switch (eventType) {
        case 'DeleteDefaultFile':
          this.uploadedFiles = this.uploadedFiles.filter(
            (x: any) => x.path !== event.item.data.path
          );
          if (event.item.data.id === 0) {
            this.newDocumentAdded = this.newDocumentAdded.filter(
              (x: any) => x.Path !== event.item.data.path
            );
          } else {
            //  remove existing attachement
            this.subscriptions.push(
              this.deviationService
                .deleteDeviationAttachment(event.item.data.id)
                .subscribe((result: any) => {
                  if (result) {
                    // remove from display list
                    this.uploadedFiles = this.uploadedFiles.filter(
                      (x: any) => x.path !== event.item.data.path
                    );
                    this.toasterComponent.callToastDlt();
                  }
                })
            );
          }
          break;
        default:
          deviationDoc.id = 0;
          deviationDoc.createdBy = BaseServices.UserId;
          deviationDoc.status = 1;
          this.newDocumentAdded.push(
            HelperService.switchKeysCase(deviationDoc, keyBindings)
          );
          break;
      }
    }
  }

  /**
   * sava case dropdown
   */
  saveCase() {
    this.isExistCaseText = this.translate.instant('TOPIC_EXIST');
    let casetype = new deviationCaseType();
    casetype.Id = this.caseId ? this.caseId : 0;
    casetype.Name = this.selectedCaseType.Name;
    casetype.BusinessId = BaseServices.BusinessId;
    casetype.ApplicationId = BaseServices.ApplicationId;
    casetype.Status = '1';
    if (casetype.Id > 0) {
      this.subscriptions.push(
        this.deviationService
          .updateCaseType(casetype)
          .subscribe((updateCaseType: any) => {
            if (updateCaseType) {
              this.isCaseDialog = false;
              this.bindCaseDropdown();
              this.deviationEditForm.get('Case').patchValue(updateCaseType.Id);
              this.selectedCaseType = new deviationCaseType();
              this.toasterComponent.callToast();
              this.caseId = 0;
            }
          })
      );
    } else {
      this.subscriptions.push(
        this.deviationService
          .checkTypecaseExist(casetype.Name)
          .subscribe((result: any) => {
            if (result.Isavailable === true) {
              this.isLabelDisabled = true;
            } else {
              this.isCaseDialog = false;
              this.subscriptions.push(
                this.deviationService
                  .createCaseType(casetype)
                  .subscribe((caseType: any) => {
                    if (caseType) {
                      this.bindCaseDropdown();
                      this.deviationEditForm
                        .get('Case')
                        .patchValue(caseType.Id);
                      this.toasterComponent.callToast();
                    }
                  })
              );
            }
          })
      );
    }
    this.clearCase();
  }

  viewCaseType() {
    this.isLabelDisabled = false;
  }

  /**
   * clear popup value
   */
  clearCase() {
    this.caseForm = this.formBuilder.group({
      Name: [''],
    });
  }
  /**
   * save topic
   */
  saveTopic() {
    this.AddTopicLoading = true;
    let topic = new Topic();
    topic.Id = this.topicId ? this.topicId : 0;
    topic.Name = this.TopicForm.value.Topic;
    topic.Status = '1';
    topic.BusinessId = BaseServices.BusinessId;
    if (this.topicId > 0) {
      this.subscriptions.push(
        this.deviationService
          .updateTopics(topic)
          .subscribe((isTopicUpdated: any) => {
            if (isTopicUpdated) {
              this.isAddTopic = false;
              this.toasterComponent.callToast();
              this.AddTopicLoading = false;
              this.fetchTopic();
              this.deviationEditForm.get('Topic').patchValue(isTopicUpdated.Id);
              this.topicId = 0;
            }
          })
      );
    } else {
      this.subscriptions.push(
        this.deviationService
          .checkTopicExist(topic.Name)
          .subscribe((result: any) => {
            if (result.Isavailable === true) {
              this.isTopicalertDisabled = true;
            } else {
              this.isAddTopic = false;
              this.subscriptions.push(
                this.deviationService
                  .addTopics(topic)
                  .subscribe((isAddTopics: any) => {
                    if (isAddTopics) {
                      this.toasterComponent.callToast();
                      this.AddTopicLoading = false;
                      this.fetchTopic();
                      this.deviationEditForm
                        .get('Topic')
                        .patchValue(isAddTopics.Id);
                    }
                  })
              );
            }
          })
      );
    }
  }
  /**
   * update deviation list
   */
  updateChanges() {
    this.loading = true;
    let windowOrigin = window.location.origin;
    let applicationBase = '';
    let appSettings = JSON.parse(sessionStorage.getItem('appSettings'));
    if (appSettings) {
      applicationBase = appSettings.BaseUrl;
    }
    let url =
      windowOrigin +
      '/' +
      applicationBase +
      '/login?returnUrl=' +
      '/app/business/' +
      BaseServices.BusinessId +
      '/follow-up/deviations/edit/';
    if (
      this.isFoodSafety ||
      this.deviationEditForm.valid ||
      this.deviationEditForm.value.Status === '10'
    ) {
      let deviation = new DeviationShared();
      deviation.ManualId = this.deviationEditForm.value.Manual;
      this.manualID = this.deviationEditForm.value.Manual
        ? this.deviationEditForm.value.Manual
        : 0;
      deviation.FeatureKey = this.parentId;
      deviation.DeviationNumber = this.deviationNumber;
      deviation.DeviationNumberPrefix = this.deviationNumberPrefix;
      deviation.DeviationUrl = url;
      deviation.Id = this.deviationId ? this.deviationId : 0;
      deviation.BusinessDepartmentIds = this.deviationEditForm.value.Department;
      deviation.BusinessId = BaseServices.BusinessId;
      deviation.TopicId = this.deviationEditForm.value.Topic;
      deviation.Title = this.deviationEditForm.value.Title;
      deviation.ApplicationId = BaseServices.ApplicationId;
      deviation.Importance = this.deviationEditForm.value.Importance;

      deviation.EventDate = this.deviationEditForm.value.EventDate
        ? this.dateService.formatDateToString(
            this.deviationEditForm.value.EventDate
          )
        : '';

      deviation.EventTime = this.deviationEditForm.value.EventTime
        ? HelperService.formatTime(this.deviationEditForm.value.EventTime)
        : null;

      deviation.EventLocation = this.deviationEditForm.value.EventLocation;
      deviation.Cost = this.deviationEditForm.value.Cost;

      deviation.Deadline = this.deviationEditForm.value.days.Deadline
        ? this.dateService.formatDateToString(
            this.deviationEditForm.value.days.Deadline
          )
        : '';

      deviation.CaseId = this.deviationEditForm.value.Case;
      deviation.Comment = this.deviationEditForm.value.Comment;
      deviation.ExecutionUserId = this.deviationEditForm.value.Execution;
      deviation.FollowupUserId = this.deviationEditForm.value.Followup;
      deviation.Description = this.deviationEditForm.value.Description;
      deviation.ImmediateAction = this.deviationEditForm.value.ImmediateAction;
      deviation.CauseAnalysis = this.deviationEditForm.value.Analysis;
      deviation.CorrectiveAction =
        this.deviationEditForm.value.CorrectiveAction;
      deviation.EffectOfAction = this.deviationEditForm.value.EffectOfAction;
      deviation.CreatedOn = this.createdOnForSave;
      deviation.CreatedBy =
        this.createdByForSave !== null
          ? this.createdByForSave
          : BaseServices.UserId;
      deviation.NotifyBeforeDeadLine =
        this.deviationEditForm.value.days.Notification;
      deviation.NotificationBeforeFollowup =
        this.deviationEditForm.value.days.FollowupNotification;
      deviation.IsShowtoUsers = this.deviationEditForm.value.Users;
      deviation.Status = this.deviationEditForm.value.Status;
      if (deviation.Status === '9') {
        deviation.CompletedDate = HelperService.formatDate(new Date());
      }
      deviation.ModifiedBy = BaseServices.UserId;
      deviation.DeviationAttachment = this.newDocumentAdded;
      deviation.TemperatureValue = this.deviationEditForm.value.Temperature;
      deviation.ObjectName = this.deviationEditForm.value.ObjectName;
      deviation.ActivityId = this.activityId;
      deviation.FeatureManualChecklistId = this.featureManualChecklistId;
      deviation.AppManualChecklistId = this.appManualChecklistId;
      deviation.FeatureId = this.FeatureId;
      deviation.FeatureCompleteChecklistId = this.featureCompleteChecklistId;
      if (deviation.Id != 0) {
        deviation.NotifyMyDeviationsChange = true;
      } else {
        deviation.NotifyMyDeviationsChange = false;
      }
      this.subscriptions.push(
        this.deviationService
          .updateDeviation(deviation, this.parentKey)
          .subscribe((isDeviationUpdated) => {
            if (isDeviationUpdated) {
              if (deviation.Status === '9' && this.manualID != 0) {
                alert(this.translate.instant('REPORT_UPLOADED_FOODSAFETY'));
              }
              this.toasterComponent.callToast();
              this.loading = false;
              this.gotoList();
            }
          })
      );
    }
  }

  /**
   * topic dropdown change eventF
   * @param e
   * @param topicDropdown
   */

  onTopicChanged(e: any, topic: any) {
    if (e.value) {
      this.topicLabel = topic.selectedOption.label;
    }
  }

  /**
   * department dropdown change eventF
   * @param e
   * @param departmentDropdown
   */
  onDepartmentChanged(e: any, department: any) {
    if (e.value) {
      this.departmentsLabel = department.selectedOption.label; // This line is causing the error
    }
  }

  /**
   * case dropdown change eventF
   * @param e
   * @param caseDropdown
   */

  onCaseChanged(e: any, casename: any) {
    if (e.value) {
      this.caseLabel = casename.selectedOption.label;
    }
  }

  /**
   * importance dropdown change eventF
   * @param e
   * @param importanceDropdown
   */

  onImportanceChanged(e: any, importance: any) {
    if (e.value) {
      this.importanceLabel = importance.selectedOption.label;
    }
  }

  /**
   * execution dropdown change eventF
   * @param e
   * @param executionDropdown
   */

  onExecChanged(e: any, exec: any) {
    if (e.value) {
      this.execLabel = exec.selectedOption.label;
    }
  }

  /**
   * followup dropdown change event
   * @param e
   * @param followupDropdown
   */

  onFollowChanged(e: any, follow: any) {
    if (e.value) {
      this.followLabel = follow.selectedOption.label;
    }
  }

  /**
   * print this page event
   */
  exportPdf() {
    let deviation = new DeviationShared();
    deviation.DeviationNumber = this.deviationNumber;
    deviation.DeviationNumberPrefix = this.deviationNumberPrefix;
    deviation.BusinessDepartmentId = this.deviationEditForm.value.Department;
    deviation.BusinessId = BaseServices.BusinessId;
    deviation.TopicId = this.deviationEditForm.value.Topic;
    deviation.TopicLabel = this.topicLabel;
    deviation.DepartmentLabel = this.departmentsLabel;
    deviation.CaseLabel = this.caseLabel;
    deviation.ImportanceLabel = this.importanceLabel;
    deviation.ExecLabel = this.execLabel;
    deviation.FollowLabel = this.followLabel;
    deviation.Title = this.deviationEditForm.value.Title;
    deviation.Importance = this.deviationEditForm.value.Importance;
    const eventDate = this.deviationEditForm.value.EventDate as Date;
    deviation.EventDate = eventDate
      ? new Intl.DateTimeFormat('en-US').format(eventDate)
      : '';

    deviation.EventTime = this.deviationEditForm.value.EventTime
      ? HelperService.formatTime(this.deviationEditForm.value.EventTime)
      : null;

    deviation.EventLocation = this.deviationEditForm.value.EventLocation;
    deviation.Cost = this.deviationEditForm.value.Cost;
    deviation.Deadline = this.deviationEditForm.value.days.Deadline
      ? this.dateService.formatDateToString(
          this.deviationEditForm.value.days.Deadline
        )
      : '';

    deviation.CaseId = this.deviationEditForm.value.Case;
    deviation.Comment = this.deviationEditForm.value.Comment;
    deviation.ExecutionUserId = this.deviationEditForm.value.Execution;
    deviation.FollowupUserId = this.deviationEditForm.value.Followup;
    deviation.Description = this.deviationEditForm.value.Description;
    deviation.ImmediateAction = this.deviationEditForm.value.ImmediateAction;
    deviation.CauseAnalysis = this.deviationEditForm.value.Analysis;
    deviation.CorrectiveAction = this.deviationEditForm.value.CorrectiveAction;
    deviation.EffectOfAction = this.deviationEditForm.value.EffectOfAction;
    deviation.CreatedOn = this.createdOnForSave;

    deviation.CreatedBy = BaseServices.UserId;
    deviation.NotifyBeforeDeadLine =
      this.deviationEditForm.value.days.Notification;
    deviation.NotificationBeforeFollowup =
      this.deviationEditForm.value.days.FollowupNotification;
    deviation.IsShowtoUsers = this.deviationEditForm.value.Users;
    deviation.Status = this.deviationEditForm.value.Status;
    deviation.ApplicationId = BaseServices.ApplicationId;

    if (deviation.Status === '9') {
      deviation.CompletedDate = HelperService.formatDate(new Date());
    }

    deviation.ModifiedBy = BaseServices.UserId;
    deviation.Reference = this.deviationEditForm.value.Reference;
    deviation.DeviationAttachment = this.newDocumentAdded;

    this.loadingReport = true;
    let fileName = this.translate.instant('DEVIATION_DETAILS');
    let filetype = 'pdf';
    let position = 0;
    let cultureInfo = sessionStorage.getItem('languageMode');
    let ReportJson = this.route.snapshot.data['edit'];
    let FeatureId = ReportJson.FeatureKey;
    let projectId = this.route.snapshot.params['deviationId'];

    this.subscriptions.push(
      this.deviationService
        .deviationReportGenerate(
          projectId,
          BaseServices.BusinessId,
          this.deviationId,
          cultureInfo,
          deviation,
          FeatureId,
          BaseServices.UserId
        )
        .subscribe(
          (blob) => {
            this.loadingReport = false;
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${fileName}.${filetype.toLowerCase()}`;
            link.click();
          },
          (error) => {
            this.loadingReport = false;
            alert('Export not downloaded');
          }
        )
    );
  }
  //#endregion
  onDeadLineChanged(event: IMyDateModel) {
    this.currentDeadLine = event.jsdate
      ? new Date(event.jsdate.getTime())
      : null;
    this.currentDate = new Date();
    this.deviationEditForm['controls'].days['controls'].Notification.setValue(
      0
    );
  }
  notificationdays(control: FormControl) {
    this.currentDate = new Date();
    if (this.currentDate && this.currentDeadLine) {
      this.currentDate.setHours(0, 0, 0, 0);
      this.currentDeadLine.setHours(0, 0, 0, 0);
      let ONE_DAY = 1000 * 60 * 60 * 24;
      // Convert both dates to milliseconds
      let date1 = this.currentDate.getTime();
      let date2 = this.currentDeadLine.getTime();
      // Calculate the difference in milliseconds
      let difference_ms = Math.abs(date1 - date2);
      this.dateDiff = Math.round(difference_ms / ONE_DAY) + 1;
    }
    const q = new Promise((resolve) => {
      if (control.value >= this.dateDiff) {
        resolve({ notificationdays: true });
      } else {
        resolve(null);
      }
    });
    return q;
  }

  /**
   * binding dropdowns
   */
  bindFormDropdowns() {
    this.followUpNotify = [];
    for (let n = 0; n <= 14; n++) {
      if (n === 0) {
        this.followUpNotify.push({
          label: this.translate.instant('ON_DEADLINE_DATE'),
          value: n,
        });
      } else {
        this.followUpNotify.push({ label: n.toString(), value: n });
      }
    }
    this.followUpNotify.push(
      { label: this.translate.instant('TREE_WEEKS'), value: 21 },
      { label: this.translate.instant('ONE_MONTH'), value: 30 }
    );

    this.subscriptions.push(
      this.translate.stream('RISKFIGURE_DROPDOWN').subscribe((val) => {
        this.importances = [];
        this.importances.push(
          { label: val.SELECT, value: null },
          { label: val.LOW, value: 1 },
          { label: val.MEDIUM, value: 2 },
          { label: val.HIGH, value: 3 }
        );
      })
    );

    let topics = this.route.snapshot.data['topic'];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.topics = [];
        this.topics.push({ label: val.SELECT, value: null });
        if (topics) {
          topics.forEach((topicList: any) => {
            this.topics.push({
              label: topicList.Name,
              value: topicList.Id,
            });
            this.topicList.push({ Name: topicList.Name, Id: topicList.Id });
          });
        }
      })
    );

    this.departments = [];
    let roleId = BaseServices.roleId;
    if (roleId === '1' || roleId === '2') {
      this.departmentList = this.route.snapshot.data['adminDepartment'];
    } else {
      this.departmentList = this.route.snapshot.data['department'];
    }
    let department = _.filter(this.departmentList, {
      ParentId: null,
    });
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.departments = [];
        this.departments.push({ label: val.SELECT, value: null });
        if (department) {
          department.forEach((department: any) => {
            if (department.Id) {
              let subDepartment = _.filter(this.departmentList, {
                ParentId: department.Id,
              });
              this.departments.push({
                label: department.Name,
                value: department.Id,
              });
              if (subDepartment.length > 0) {
                subDepartment.forEach((subDept: any) => {
                  this.departments.push({
                    label: `${department.Name} (${subDept.Name})`,
                    value: department.Id,
                  });
                });
              }
            }
          });
        }
      })
    );

    let responsiblePerson = this.route.snapshot.data['user'];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.executions = [];
        this.executions.push({ label: val.SELECT, value: null });
        if (responsiblePerson) {
          responsiblePerson.forEach((followupExecution: any) => {
            this.executions.push({
              label: followupExecution.Name,
              value: followupExecution.Id,
            });
          });
        }
      })
    );
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.followUps = [];
        this.followUps.push({ label: val.SELECT, value: null });
        if (responsiblePerson) {
          responsiblePerson.forEach((followupExecution: any) => {
            this.followUps.push({
              label: followupExecution.Name,
              value: followupExecution.Id,
            });
          });
        }
      })
    );
    this.followUpAssignedUsers = [];
    if (this.parentPath === 'follow-up') {
      this.subscriptions.push(
        this.deviationService
          .getResponsibleAndAssignedUsersForFollowup(
            BaseServices.BusinessId,
            BaseServices.FeatureId,
            BaseServices.FeatureKey
          )
          .subscribe((result: any) => {
            if (result) {
              result.forEach((element) => {
                this.followUpAssignedUsers.push({
                  label: element.Name,
                  value: element.Id,
                });
              });
              this.executions.push(...this.followUpAssignedUsers);
              this.followUps.push(...this.followUpAssignedUsers);
            }
          })
      );
    }
    let typeOfCases = this.route.snapshot.data['caseType'];
    if (typeOfCases) {
      this.editTypes = [];
      this.subscriptions.push(
        this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
          this.cases = [];
          this.cases.push({ label: val.SELECT, value: null });
          typeOfCases.forEach((caseList: any) => {
            this.cases.push({ label: caseList.Name, value: caseList.Id });
            this.editTypes.push({ Name: caseList.Name, Id: caseList.Id });
          });
        })
      );
    }
    let deviationInfo = this.route.snapshot.data['edit'];
    if (deviationInfo) {
      this.deviationFeatureKey = deviationInfo.FeatureKey;
    }
    if (this.deviationFeatureKey === Module.FOLLOWUPS) {
      let currentUserRole = BaseServices.UserRole;
      let manualRightsId = Rights.VIEW_MANUALS;
      this.isShowManualList = true;
      this.BusinessId = deviationInfo.BusinessId;
      this.bindManualDropdown(this.BusinessId);
    } else {
      this.isShowManualList = false;
    }
  }

  bindManualDropdown(BusinessId: number) {
    this.subscriptions.push(
      this.deviationService
        .getAssignedBusinessManual(+BusinessId)
        .subscribe((response) => {
          if (response) {
            this.subscriptions.push(
              this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
                this.manualListItems = [];
                this.manualListItems.push({ label: val.SELECT, value: null });
              })
            );
            response.forEach((manualData: any) => {
              this.manualListItems.push({
                label: manualData.Title,
                value: manualData.Id,
              });
            });
          }
        })
    );
  }

  /**
   * bind type case dropdown
   */
  bindCaseDropdown() {
    this.subscriptions.push(
      this.deviationService.getAllCaseType().subscribe((caseType: any) => {
        if (caseType) {
          this.subscriptions.push(
            this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
              this.cases = [];
              this.cases.push({ label: val.SELECT, value: null });
            })
          );
          caseType.forEach((caseList: any) => {
            this.cases.push({
              label: caseList.Name,
              value: caseList.Id,
            });
          });
          this.editTypes = caseType;
        }
      })
    );
  }

  /**
   * edit case type
   * @param casetype {any}
   */
  editCase(casetype: any) {
    this.selectedCaseType = casetype;
    this.caseId = this.selectedCaseType.Id;
  }

  /**
   * display case dropdown
   */
  showCase() {
    this.isCaseDialog = true;
  }
  /**
   * display topic dialog
   */
  showTopic() {
    this.isAddTopic = true;
  }
  /**
   * delete case dropdown item
   * @param selectedCaseType
   */
  deleteCase(selectedCaseType: any) {
    this.subscriptions.push(
      this.deviationService
        .getAllByEach(this.parentId, this.parentKey)
        .subscribe((result) => {
          this.deviationList = result;
        })
    );
    let data = ([] = this.deviationList.filter(
      (element) => element.CaseId === selectedCaseType
    ));
    if (data.length > 0) {
      alert(
        this.translate.instant(
          'YOU_CANNOT_DELETE_CASE_USED_BY_ANOTHER_DEVIATION'
        )
      );
    } else {
      this.confirmationService.confirm({
        message: this.translate.instant('DELETE_THIS_RECORD'),
        accept: () => {
          this.subscriptions.push(
            this.deviationService
              .deleteType(selectedCaseType)
              .subscribe((isCaseDeleted) => {
                if (isCaseDeleted) {
                  this.bindCaseDropdown();
                  this.toasterComponent.callToastDlt();
                }
              })
          );
        },
      });
    }
  }
  /**
   * Fetch the topics
   */
  fetchTopic() {
    this.subscriptions.push(
      this.deviationService
        .getAllTopics(BaseServices.BusinessId)
        .subscribe((topic: any) => {
          this.subscriptions.push(
            this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
              this.topics = [];
              this.topics.push({ label: val.SELECT, value: null });
              if (topic) {
                topic.forEach((topics: any) => {
                  if (topics) {
                    this.topics.push({
                      label: topics.Name,
                      value: topics.Id,
                    });
                  }
                });
                this.topicList = topic;
              }
            })
          );
        })
    );
  }
  /**
   * edit topic
   * @param Topic {any}
   */
  editTopic(topic: Topic) {
    this.topicId = topic.Id;
    let topicName = {
      Topic: topic.Name,
    };
    (<FormGroup>this.TopicForm).setValue(topicName, { onlySelf: true });
  }
  clear() {
    this.TopicForm = this.formBuilder.group({
      Topic: '',
    });
  }

  /**
   * delete type of topic
   * @param deleteTopic {any}
   */
  deleteTopic(deleteTopic: any) {
    this.confirmationService.confirm({
      message: this.translate.instant('DELETE_THIS_RECORD'),
      accept: () => {
        this.subscriptions.push(
          this.deviationService
            .deleteTopics(deleteTopic)
            .subscribe((topic: any) => {
              if (topic) {
                this.fetchTopic();
                this.toasterComponent.callToastDlt();
              }
            })
        );
      },
    });
  }
  /**
   * getting and listing deviation log grid values
   */
  deviationLogData() {
    this.subscriptions.push(
      this.deviationService
        .getDeviationLog(this.deviationId)
        .subscribe((deviationLog: any) => {
          if (deviationLog) {
            deviationLog.forEach((log: any) => {
              if (log.ColumnName === 'Importance') {
                log.OldValue = this.assignRiskData(log.OldValue);
                deviationLog.OldValue = log.OldValue;
              } else if (log.ColumnName === 'Status') {
                log.OldValue = this.assignStatus(log.OldValue);
                deviationLog.OldValue = log.OldValue;
              }
            });
            this.deviationLog = deviationLog;
          }
        })
    );
  }
  assignStatus(status: string) {
    let statusText: string;
    if (status) {
      switch (status) {
        case '7':
          statusText = 'New';
          break;
        case '8':
          statusText = 'Open';
          break;
        case '9':
          statusText = 'Done';
          break;
        case '10':
          statusText = 'Rejected';
          break;
        default:
          statusText = status;
      }
    }
    return statusText;
  }
  assignRiskData(importance: any) {
    let impData: any = [];
    if (importance) {
      switch (importance) {
        case '1':
          impData = 'Low';
          break;
        case '2':
          impData = 'Medium';
          break;
        case '3':
          impData = 'High';
          break;
      }
    }
    return impData;
  }
  /**
   * display department dropdown
   */
  showDepartment() {
    this.department = true;
  }

  /**
   * go back to list
   */
  gotoList() {
    this.location.back();
  }
  /**
   * close popup
   */
  /**
   * get department values for edit
   * @param deptdetails
   */
  editDepartment(deptdetails: any) {
    this.deptId = this.selectedDepartment.Id;
    this.selectedDepartment = deptdetails;
    this.getBusinessDepartment();
  }

  onBeforeCaseDialogHide() {
    this.isLabelDisabled = false;
    this.caseForm.reset();
    this.hidingElement = false;
    this.caseForm.reset();
    this.hidesElement = false;
    this.isDepartmentErrorDisabled = false;
  }
  /**
     /**
   * close popup
   */
  onBeforeTopicDialogHide() {
    this.isTopicalertDisabled = false;
    this.TopicForm.reset();
    this.viewTopic = false;
  }
  /**
   * get current date
   */
  getCurrentDate() {
    let dateFormat = new Date();
    return {
      year: dateFormat.getFullYear(),
      month: dateFormat.getMonth() + 1,
      day: dateFormat.getDate() - 1,
    };
  }
  /**
   * update deviation based on viewdeviation rights
   */
  onViewDeviation() {
    let currentUserRole = BaseServices.UserRole;
    let userRightsId = Rights.VIEW_DEVIATION;
    if (currentUserRole === 'User' || currentUserRole === 'Guest') {
      this.canShowDeviation = BaseServices.checkUserRights(
        userRightsId,
        currentUserRole
      );
      if (this.canShowDeviation) {
        this.isShowDeviation = false;
      }
    }
  }
  statusChange(event: any, option: any) {
    if (option && this.deviationFeatureKey === Module.FOLLOWUPS) {
      this.isShowManualList = option.id === 9 ? true : false;
      if (this.isShowManualList) {
        this.bindManualDropdown(this.BusinessId);
      }
    }
    this.isShowNotification =
      option.id === 9 || option.id === 10 ? false : true;
  }
  /**
   * Edit the page based upon administrate deviation rights
   */
  onAdministrateDeviation() {
    let currentUserRole = BaseServices.UserRole;
    let userRightsId = Rights.ADMINISTRATE_DEVIATION;
    let userId = BaseServices.UserId;
    if (currentUserRole === 'User' || currentUserRole === 'Guest') {
      if (userId === this.followUpId || userId === this.executionId) {
        this.isShowDeviation = true;
      } else {
        this.isShowDeviation = BaseServices.checkUserRights(
          userRightsId,
          currentUserRole
        );
        if (this.isShowDeviation === false) {
          this.onViewDeviation();
        }
      }
    }
    this.upConfig.showDeleteButton = this.isShowDeviation;
  }
  //#endregion

  /**
   * get selected manual id
   */
  manualChangeItem(event: any) {
    this.manualID = event.value;
  }

  /**
   * get current date
   */
  getCurrentEventDate() {
    let dateFormat = new Date();
    return {
      year: dateFormat.getFullYear(),
      month: dateFormat.getMonth() + 1,
      day: dateFormat.getDate() + 1,
    };
  }

  /**
   * bind evnet date and time
   */
  bindEventDate() {
    let dateFormat = new Date();
    this.deviationEditForm.get('EventDate')?.setValue(new Date());
    this.deviationEditForm
      .get('EventTime')
      ?.setValue(this.datePipe.transform(dateFormat, 'HH:mm'));
    this.IsClear = true;
  }

  /**
   * clear evnet date and time
   */
  clearEventDate() {
    this.deviationEditForm.controls['EventDate'].setValue(null);
    this.deviationEditForm.controls['EventTime'].setValue(null);
    this.IsClear = false;
  }

  /**
   * enable clear button
   */
  enableClear() {
    this.IsClear = true;
  }

  /**
   * get all department dropdown list
   */
  getBusinessDepartment() {
    this.isDepartmentErrorDisabled = false;
    this.subscriptions.push(
      this.businessDepartmentService
        .getCachedBusinessDepartment()
        .subscribe((department) => {
          if (department) {
            this.subscriptions.push(
              this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
                this.Department = [];
                this.departments = [];
                this.Department.push({ label: val.SELECT, value: null });
                if (department) {
                  department.forEach((caseValue: any) => {
                    this.Department.push({
                      label: caseValue.Name,
                      value: caseValue.Id,
                    });
                    this.departments.push({
                      label: caseValue.Name,
                      value: caseValue.Id,
                    });
                  });
                }
              })
            );
            this.departmentdetails = department;
          }
        })
    );
  }

  /**
   * save department dropdown
   */
  saveDepartment() {
    let deptdetails = new Department();
    deptdetails.BusinessId = BaseServices.BusinessId;
    deptdetails.ApplicationId = BaseServices.ApplicationId;
    deptdetails.Id = this.deptId ? this.deptId : 0;
    deptdetails.Name = this.selectedDepartment.Name;
    deptdetails.Status = '1';
    if (deptdetails.Id > 0) {
      deptdetails.ModifiedBy = BaseServices.UserId;
      this.subscriptions.push(
        this.deviationService
          .updateDepartment(deptdetails)
          .subscribe((department: any) => {
            if (department) {
              this.clearCachedDepartment();
              this.department = false;
              this.toasterComponent.callToast();
              this.getBusinessDepartment();
              this.deptId = 0;
            }
          })
      );
    } else {
      this.clearCachedDepartment();
      deptdetails.Id = 0;
      deptdetails.CreatedBy = BaseServices.UserId;
      this.subscriptions.push(
        this.deviationService
          .checkDepartmentExist(deptdetails.Name)
          .subscribe((result: any) => {
            if (result.Isavailable === true) {
              this.isDepartmentErrorDisabled = true;
            } else {
              this.subscriptions.push(
                this.deviationService
                  .addDepartment(deptdetails)
                  .subscribe((department: any) => {
                    if (department) {
                      this.department = false;
                      this.toasterComponent.callToast();
                      this.getBusinessDepartment();
                    }
                  })
              );
            }
          })
      );
    }
    this.clearDepartment();
  }
  /**
   * clear popup value
   */
  clearDepartment() {
    this.selectedDepartment.Id = 0;
    this.departmentForm = this.formBuilder.group({
      Name: [''],
    });
  }
  /**
   * cache clear of department
   */
  clearCachedDepartment() {
    this.businessDepartmentService.clearCachedAllDepartment();
  }
  /**
   * department dropdown delete
   * @param selectedDepartment
   */
  deleteDepartment(selectedDepartment: Department) {
    this.subscriptions.push(
      this.deviationService
        .checkDepartmentExist(selectedDepartment.Name)
        .subscribe(() => {
          this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
              this.subscriptions.push(
                this.deviationService
                  .deleteDepartment(selectedDepartment.Id)
                  .subscribe((result) => {
                    if (result) {
                      this.departments = this.departments.filter(
                        (department) =>
                          department.value !== selectedDepartment.Id
                      );
                      this.getBusinessDepartment();
                      this.toasterComponent.callToastDlt();
                    }
                  })
              );
            },
          });
        })
    );
    this.getBusinessDepartment();
  }

  toggleDeviationLog() {
    this.showDeviationLog = !this.showDeviationLog;
  }
  /**
   * Bind sub department dropdown
   */
  bindSubDepartmentDropdown(value: number[]) {
    const roleId = BaseServices.roleId;
    this.subscriptions.push(
      this.employeeServices
        .getSubDepartments(value, BaseServices.BusinessId, roleId)
        .subscribe((subDepartment: any) => {
          this.subDepartmentsList = [];
          subDepartment.forEach((element: any) => {
            if (subDepartment) {
              this.subDepartmentsList.push({
                label: element.Name,
                value: element.Id,
              });
            }
            this.editSubDepartments = subDepartment;
          });
        })
    );
  }
  /**
   * Creates and opens the Add Sub Department modal dynamically
   */
  addSubDepartment() {
    const modalComponentRef = this.addSubDeptModalContainer.createComponent(
      AddDeviationComponent
    );
    this.addSubDeptComponentRef = modalComponentRef;
    modalComponentRef.setInput('isEditSubDept', true);
    modalComponentRef.setInput(
      'departmentIdList',
      this.deviationEditForm.value.Department
    );
    modalComponentRef.instance.subDepModalClosed.subscribe(() => {
      this.closeModal();
    });
  }
  /**
   * Destroys the dynamically created Add Sub Department modal and clears the reference
   */
  closeModal(): void {
    if (this.addSubDeptComponentRef) {
      this.addSubDeptComponentRef.destroy();
      this.addSubDeptComponentRef = null;
    }
  }
  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}
