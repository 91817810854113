import { FeatureKey } from './../../_models/feature';
import { FunctionListResolver } from './../contact/services/contact.resolver.service';
import { UserRoleResolver } from './../users/services/user.resolver.service';
import { FirstOfKinListResolver } from './services/employeefirstofkin.resolver.service';
import {
  BusinessCourseEditResolver,
  BusinessCreatorResolver,
  BusinessDepartmentResolver,
  BusinessUserEditResolver,
} from './../businesses/services/business.resolver.service';
import { EditTimetableComponent } from './../time-table/components/edit-timetable/edit-timetable.component';
import { CreateTimetableComponent } from './../time-table/components/new-timetable/new-timetable.component';
import {
  ApprovalDocumentResolver,
  DocumentedTrainingResolver,
  EmployeePrivateDocResolver,
  MultiDocumentedTrainingResolver,
} from './services/employeeprivatedoc.resolver.service';
import { DashboardCalendarActivityResolver } from './../dashboard/Services/dashboard.resolver.service';
import {
  EmployeeWorkExperienceResolver,
  CountryCodeResolver,
} from './services/employeeWorkExperience.resolver.service';
import { Route, Routes } from '@angular/router';
import { AuthGuard } from '../../_guards/index';
import { EmployeesComponent } from './employees.component';
import { InfoComponent } from './components/info.component';
import { BusinessListResolver } from 'src/app/kuba/businesses/services/business.resolver.service';
import { TimeTableListResolver } from 'src/app/kuba/employees/services/timetable.resolver.service';
import {
  EmpLeaveListResolver,
  ApprovalPersonResolver,
  EmpLeaveByLeaveResolver,
  SicknessAbsenceCodeResolver,
  ResposibleExecutionResolver,
  BusinessHolidaysResolver,
} from './services/employeeleave.resolver.service';
import {
  EmployeeListResolver,
  CourseListContractResolver,
  PortalContractListResolver,
  EmployeeEditResolver,
  EmployeeSicknessListResolver,
  CourseListResolver,
  BusinessCourseResolver,
  BusinessContractResolver,
  EmployeeTimeTableResolver,
  DepartmentDropDownResolver,
  PositionDropDownResolver,
  EmployeeDropDownResolver,
  EmployeeLeaveResolver,
  EmployeeActiveStatusResolver,
  EmployeeInActiveStatusResolver,
  EmployeeDocResolver,
  EmployeeSicknessByIdResolver,
  PortalEmployeeListResolver,
  PortalCourseListResolver,
  PortalCourseResolver,
  BusinessAbsenceCodesBusinessResolver,
  UserDetailsResolver,
  EmployeeSubDepartmentResolver,
  EmployeeBusinessDataResolver,
} from './services/employee.resolver.service';
import {
  EmployeeFormComponent,
  EmployeeListComponent,
  SicknessComponent,
  SicknessListComponent,
  SicknessEditComponent,
  LeaveEditComponent,
  LeaveComponent,
  LeaveListComponent,
  LeaveAllocateComponent,
  TimetableComponent,
  EmployeeTimetableComponent,
  CourseComponent,
  CourseDetailComponent,
  CourseListComponent,
  FirstKinComponent,
  FirstKinListComponent,
  FirstKinEditComponent,
  WorkExpEditComponent,
  PrivateDocumentComponent,
} from './components/index';
import {
  TimeTableEditResolver,
  TypeOfWorkDropdownResolver,
  EmployeeDropdownResolver,
  ApprovalPersonDropdownResolver,
  ProjectDropdownResolver,
  EmployeeContactUserResolver,
} from 'src/app/kuba/time-table/services/timetable.resolver.service';
import { DocumentedTrainingComponent } from './components/employee-documented-training.component';

export const employeeRoutes: Route[] = [
  {
    path: 'employee',
    component: EmployeesComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'list',
        component: EmployeeListComponent,
        canActivate: [AuthGuard],
        data: {
          mode: 'LIST',
          additionalFeatureKey: FeatureKey.EMPLOYEES,
        },
        resolve: {
          list: EmployeeListResolver,
          portalList: PortalEmployeeListResolver,
          // department: DepartmentDropDownResolver,    // commented for performance improvement
          active: EmployeeActiveStatusResolver,
          inactive: EmployeeInActiveStatusResolver,
        },
      },
      {
        path: 'new',
        component: EmployeeFormComponent,
        canActivate: [AuthGuard],
        resolve: {
          countrycodedropdown: CountryCodeResolver,
          businessdropdown: DepartmentDropDownResolver,
          positiondropdown: PositionDropDownResolver,
          employee: EmployeeDropDownResolver,
          function: FunctionListResolver,
          subDept: EmployeeSubDepartmentResolver,
        },
      },
      {
        path: 'edit/:eid',
        component: EmployeeFormComponent,
        canActivate: [AuthGuard],
        data: { parent: 'EMPLOYEE', mode: 'EDIT' },
        resolve: {
          edit: EmployeeEditResolver,
          countrycodedropdown: CountryCodeResolver,
          businessdropdown: BusinessDepartmentResolver,
          positiondropdown: PositionDropDownResolver,
          employee: EmployeeDropDownResolver,
          userrole: UserRoleResolver,
          function: FunctionListResolver,
          // doclist: EmployeeDocResolver,
          subDept: EmployeeSubDepartmentResolver,
        },
      },
      {
        path: 'sickness',
        component: SicknessComponent,
        children: [
          {
            path: 'list',
            component: SicknessListComponent,
            data: {
              additionalFeatureKey: FeatureKey.EMPLOYEE_SICKNESS_ABSENCE,
            },
            resolve: {
              list: EmployeeSicknessListResolver,
              departments: DepartmentDropDownResolver,
              absenceCode: BusinessAbsenceCodesBusinessResolver,
              // business: BusinessListResolver    performance fix
            },
          },
          {
            path: 'edit/:eid',
            data: { parent: 'EMPLOYEE' },
            component: SicknessEditComponent,
            resolve: {
              approvalperson: ApprovalPersonResolver,
              sickness: SicknessAbsenceCodeResolver,
              holiday: BusinessHolidaysResolver,
            },
          },
          {
            path: 'editSickness/:sid',
            component: SicknessEditComponent,
            data: { parent: 'SICKNESS' },
            resolve: {
              approvalperson: ApprovalPersonResolver,
              sickness: SicknessAbsenceCodeResolver,
              doclist: EmployeeDocResolver,
              editSickness: EmployeeSicknessByIdResolver,
              holiday: BusinessHolidaysResolver,
            },
          },
        ],
      },
      {
        path: 'leave',
        component: LeaveComponent,
        children: [
          {
            path: 'list',
            component: LeaveListComponent,
            data: {
              additionalFeatureKey: FeatureKey.EMPLOYEE_LEAVE,
            },
            resolve: {
              list: EmpLeaveListResolver,
            },
          },
          {
            path: 'edit/:eid',
            component: LeaveEditComponent,
            data: { parent: 'EMPLOYEE' },
            resolve: {
              approvalperson: ApprovalPersonResolver,
              edit: EmployeeLeaveResolver,
              holiday: BusinessHolidaysResolver,
            },
          },
          {
            path: 'editLeave/:lid',
            component: LeaveEditComponent,
            data: { parent: 'LEAVE' },
            resolve: {
              approvalperson: ApprovalPersonResolver,
              leaveEdit: EmpLeaveByLeaveResolver,
              holiday: BusinessHolidaysResolver,
            },
          },
          {
            path: 'allocate',
            component: LeaveAllocateComponent,
            resolve: {
              list: EmployeeListResolver,
            },
          },
        ],
      },
      {
        path: 'timetable',
        component: TimetableComponent,
        children: [
          {
            path: 'list/:tid',
            component: EmployeeTimetableComponent,
            data: {
              additionalFeatureKey: FeatureKey.EMPLOYEE_TIMETABLE,
            },
            canActivate: [AuthGuard],
            resolve: {
              timetable: EmployeeTimeTableResolver,
            },
          },
          {
            path: 'edit/:tid',
            component: EditTimetableComponent,
            data: { parent: 'TIMETABLE' },
            resolve: {
              edit: TimeTableEditResolver,
              typeOfWork: TypeOfWorkDropdownResolver,
              employee: EmployeeDropdownResolver,
              approvalPerson: ApprovalPersonResolver,
            },
          },
          {
            path: 'edit-timetable/:eid',
            component: EditTimetableComponent,
            data: { parent: 'EMPLOYEE' },
            resolve: {
              typeOfWork: TypeOfWorkDropdownResolver,
              employee: EmployeeDropdownResolver,
              approvalPerson: ApprovalPersonResolver,
              timetable: EmployeeTimeTableResolver,
            },
          },
          {
            path: 'direct/list',
            component: EmployeeTimetableComponent,
            data: {
              additionalFeatureKey: FeatureKey.EMPLOYEE_TIMETABLE,
            },
            resolve: {
              list: TimeTableListResolver,
              userData: UserDetailsResolver,
            },
          },
          {
            path: 'new',
            component: CreateTimetableComponent,
            resolve: {
              typeOfWork: TypeOfWorkDropdownResolver,
              employee: EmployeeDropdownResolver,
              approvalPerson: ApprovalPersonDropdownResolver,
              project: ProjectDropdownResolver,
            },
          },
        ],
      },
      {
        path: 'course',
        component: CourseComponent,
        children: [
          {
            path: 'list',
            component: CourseListComponent,
            data: {
              additionalFeatureKey: FeatureKey.EMPLOYEE_COURSE_DETAILS,
            },
            resolve: {
              list: CourseListResolver,
              portalList: PortalCourseListResolver,
              department: DepartmentDropDownResolver,
              listcontract: CourseListContractResolver,
              portalListContract: PortalContractListResolver,
              employee: EmployeeListResolver,
              course: BusinessCourseResolver,
              portalcourse: PortalCourseResolver,

              // business: BusinessListResolver, //perfomance fix
              businesscreator: BusinessCreatorResolver,
              responsibleExecution: EmployeeContactUserResolver,
            },
          },
          {
            path: 'contractlist',
            component: CourseListComponent,
            data: {
              additionalFeatureKey: FeatureKey.EMPLOYEE_COURSE_DETAILS,
            },
            resolve: {
              department: DepartmentDropDownResolver,
              listcontract: CourseListContractResolver,
              portalListContract: PortalContractListResolver,
              employee: EmployeeListResolver,
              // course: BusinessContractResolver,
              course: BusinessCourseResolver,
              portalcourse: PortalCourseResolver,

              // business: BusinessListResolver, //perfomance fix
              businesscreator: BusinessCreatorResolver,
            },
          },
          {
            path: 'edit/:eid',
            component: CourseDetailComponent,
            resolve: {
              course: BusinessCourseResolver,
              portalcourse: PortalCourseResolver,
              responsible: ApprovalPersonResolver,
              businesseditcourse: BusinessCourseEditResolver,
              responsibleExecution: EmployeeContactUserResolver,
              businesscreator: BusinessCreatorResolver,
            },
          },
          {
            path: 'contractEdit/:eid',
            component: CourseDetailComponent,
            resolve: {
              course: BusinessCourseResolver,
              portalcourse: PortalCourseResolver,
              responsible: ApprovalPersonResolver,
              businesseditcourse: BusinessCourseEditResolver,
              responsibleExecution: EmployeeContactUserResolver,
              businesscreator: BusinessCreatorResolver,
            },
          },
          {
            path: 'editCourse/:eid',
            component: CourseDetailComponent,
            data: { parent: 'EMPLOYEE' },
            resolve: {
              // course: BusinessCourseResolver,
              portalcourse: PortalCourseResolver,
              responsible: ApprovalPersonResolver,
              businesseditcourse: BusinessCourseEditResolver,
              responsibleExecution: EmployeeContactUserResolver,
              // doclist: EmployeeDocResolver,
              businesscreator: BusinessCreatorResolver,
            },
          },
          {
            path: 'editContract/:eid',
            component: CourseDetailComponent,
            data: { parent: 'EMPLOYEE' },
            resolve: {
              // course: BusinessCourseResolver,
              portalcourse: PortalCourseResolver,
              responsible: ApprovalPersonResolver,
              businesseditcourse: BusinessCourseEditResolver,
              responsibleExecution: EmployeeContactUserResolver,
              // doclist: EmployeeDocResolver,
              businesscreator: BusinessCreatorResolver,
            },
          },
          {
            path: 'new',
            component: CourseDetailComponent,
          },
        ],
      },
      {
        path: 'first-kin',
        component: FirstKinComponent,
        children: [
          {
            path: 'list',
            component: FirstKinListComponent,
            data: {
              additionalFeatureKey: FeatureKey.EMPLOYEE_FIRST_OF_KIN,
            },
            resolve: {
              list: FirstOfKinListResolver,
            },
          },
          {
            path: 'edit/:eid',
            component: FirstKinEditComponent,
            resolve: {
              countrycodedropdown: CountryCodeResolver,
            },
          },
        ],
      },
      {
        path: 'work-exp/edit/:eid',
        component: WorkExpEditComponent,
        resolve: {
          countrycodedropdown: CountryCodeResolver,
          list: EmployeeWorkExperienceResolver,
        },
      },
      {
        path: 'private-doc/edit/:eid',
        component: PrivateDocumentComponent,
        resolve: {
          list: EmployeePrivateDocResolver,
        },
      },
      {
        path: 'private-doc/multi-doc/:eid/:mId',
        component: PrivateDocumentComponent,
        data: {
          additionalFeatureKey: FeatureKey.APPROVAL_DOCUMENTS,
        },
        resolve: {
          applist: ApprovalDocumentResolver,
        },
      },
      {
        path: 'documented-training/multi-doc/:eid/:mId',
        component: DocumentedTrainingComponent,
        data: {
          additionalFeatureKey: FeatureKey.DOCUMENTED_TRAINING,
        },

        resolve: {
          applist: MultiDocumentedTrainingResolver,
        },
      },
      {
        path: 'documented-training/multi-doc/:eid',
        component: DocumentedTrainingComponent,
        resolve: {
          list: DocumentedTrainingResolver,
        },
      },
    ],
  },
];
