<div class="page-title">
  <span translate>REGULATIONS</span>
  <span class="page-actions text-right" *ngIf="treeData.isEditMode">
    <button type="button" (click)="initLARForm()" class="btn btn-success">
      <span class="icon ic-sm icon-add"></span>
      <span translate>NEW</span>
    </button>
  </span>
</div>
<div class="table-view">
  <p-selectButton
    [options]="types"
    [(ngModel)]="isLaws"
    [style]="{ width: '300px' }"
    (onChange)="manualLARSTypeFilter()"
  ></p-selectButton>
  <!-- Table starts -->
  <p-table
    #manualLARSTable
    [value]="manualLARS"
    [rows]="10"
    [paginator]="true"
    [pageLinks]="3"
    [rowsPerPageOptions]="[5, 10, 20]"
    [responsive]="true"
  >
    <!-- Header -->
    <ng-template pTemplate="header">
      <tr>
        <th>{{ "TITLE" | translate }}</th>
        <th>{{ "URL" | translate }}</th>
        <th *ngIf="treeData.isEditMode && nodeData.levelId != 3"></th>
        <th *ngIf="treeData.isEditMode"></th>
      </tr>
    </ng-template>
    <!-- Body Content -->
    <ng-template pTemplate="body" let-manual>
      <tr>
        <td>{{ manual.Title }}</td>
        <td>{{ manual.Url }}</td>
        <td *ngIf="treeData.isEditMode && nodeData.levelId != 3">
          <div>
            <!-- Header -->
            <ng-template pTemplate="header"> Public/Private </ng-template>
            <p-inputSwitch
              onLabel="Public"
              offLabel="Private"
              (onChange)="changeSwitch(manual)"
              [(ngModel)]="manual.IsCommon"
            ></p-inputSwitch>
          </div>
        </td>
        <td class="col-button" *ngIf="treeData.isEditMode">
          <a
            (click)="initLARForm(manual)"
            class="btn btn-icon"
            *ngIf="nodeData.levelId != 3 || !manual.IsCommon"
          >
            <i
              class="icon ic-sm icon-pencil-tip"
              pTooltip="Edit"
              tooltipPosition="top"
            ></i>
          </a>
          <a
            (click)="deleteLAR(manual)"
            class="btn btn-icon"
            *ngIf="nodeData.levelId != 3 || !manual.IsCommon"
          >
            <i
              class="icon ic-sm icon-trash"
              pTooltip="Delete"
              tooltipPosition="top"
            ></i>
          </a>
        </td>
      </tr>
    </ng-template>
    <!-- Footer -->
    <ng-template pTemplate="summary">
      <span>Todo:Users Info and Export buttons </span>
    </ng-template>
  </p-table>
  <!-- Table ends -->
</div>
<p-dialog
  header="Add / Update Laws and Regulations"
  *ngIf="showDialog"
  [(visible)]="showDialog"
  [style]="{ width: '500px' }"
  [resizable]="false"
  [draggable]="false"
  [contentStyle]="{ overflow: 'visible' }"
>
  <form [formGroup]="createLARForm" (ngSubmit)="saveLAR()">
    <div class="form-group">
      <label for="title" translate
        >TITLE
        <span class="required">*</span>
      </label>
      <div>
        <input
          type="text"
          formControlName="title"
          id="title"
          pInputText
          class="form-control"
        />
      </div>
      <control-messages
        [control]="createLARForm.controls['title']"
      ></control-messages>
    </div>
    <div class="form-group">
      <label for="url" translate
        >URL
        <span class="required">*</span>
      </label>
      <div>
        <input
          type="text"
          formControlName="url"
          id="url"
          pInputText
          class="form-control"
        />
      </div>
      <control-messages
        [control]="createLARForm.controls['url']"
      ></control-messages>
    </div>
    <div class="form-group">
      <p-radioButton
        name="group"
        value="Laws"
        label="Laws"
        formControlName="selectedType"
      ></p-radioButton>
      <p-radioButton
        name="group"
        value="Regulation"
        label="Regulation"
        formControlName="selectedType"
      >
      </p-radioButton>
    </div>
    <div class="action-btns-wrapper">
      <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
        <button
          type="button"
          class="btn btn-outline-secondary"
          (click)="showDialog = false"
          translate
        >
          CLOSE
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          [disabled]="!createLARForm.valid"
          translate
        >
          SAVE
        </button>
      </div>
    </div>
  </form>
</p-dialog>
<toaster-component [toast]="toast"></toaster-component>
