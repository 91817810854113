import { LoginOriginalComponent } from './login-original.component';
import { Route, Resolve } from '@angular/router';
import { LoginComponent } from './index';
import { AuthGuard } from '../_guards/index';
import { ImageUploaderComponent } from './../shared/ecpl-article-viewer/image-uploader.component';

export const loginRoutes: Route[] = [
  {
    path: 'login', // commen login for all app
    data: { phpLogin: false },
    component: LoginComponent,
  },
  {
    path: 'login/php/:username/:password', // Login with home page
    data: { phpLogin: true },
    component: LoginComponent,
  },
  {
    path: 'login/:ulink/:businessId', // Login with home page by app and business
    component: LoginOriginalComponent,
  },
  {
    path: 'image-uploader',
    component: ImageUploaderComponent,
  },
];
