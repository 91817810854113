import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { Issue, SafetyJobListedit, TaskPlan, Topic } from '../models';
import { BaseServices } from './../../kuba.services';
import { ApprovalNotice } from './../models/approval';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from 'src/app/_services';

@Injectable()
export class JobAnalysisService {
  _issueType: Observable<any> = null!;

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) {}
  clearCachedIssuesType() {
    this._issueType = null!;
  }
  getAll(id: number) {
    return this.http
      .get(environment.BASE_URL + '/riskanalysisbyproject/' + id)
      .map(result => result);
  }
  getAllByEach(id: number, key: string) {
    return this.http
      .get(
        `${environment.BASE_URL}/${BaseServices.apiRoute(key)}/safetyjobanalysis/listByBusiness/${BaseServices.BusinessId}/${id}`
      )
      .map((result: any) => result);
  }

  getAllApproved(featureKey: number, key: string) {
    return this.http
      .get(
        `${environment.BASE_URL}/${BaseServices.apiRoute(key)}/safetyjobanalysis/approvalList/${BaseServices.BusinessId}/${featureKey}`
      )
      .map(result => result);
  }

  getSafetyLog(id: number) {
    return this.http
      .get(environment.BASE_URL + '/safetyjobanalysislog/' + id)
      .map(result => result);
  }

  create(newSafetyJob: SafetyJobListedit, key: string) {
    return this.http
      .post(
        `${environment.BASE_URL}/${BaseServices.apiRoute(key)}/safetyjobanalysis`,
        newSafetyJob
      )
      .map(result => result);
  }
  addApproval(newApproval: ApprovalNotice[], key: string) {
    return this.http
      .post(
        `${environment.BASE_URL}/${BaseServices.apiRoute(key)}/safetyjobanalysis/approved`,
        newApproval
      )
      .map(result => result);
  }

  getIssueType(id: number) {
    return this.http
      .get(environment.BASE_URL + '/issuetype/' + id)
      .map(result => result);
  }
  getTopicList(id: number) {
    return this.http
      .get(environment.BASE_URL + '/DeviationTopic/' + id)
      .map(result => result);
  }

  // get all issues
  getAllIssues() {
    // BaseServices.BusinessId - businessid from r mode
    return this.http
      .get(
        environment.BASE_URL +
          '/issuetypes/' +
          BaseServices.BusinessId +
          '/' +
          BaseServices.roleId
      )
      .map(result => result);
  }

  // add issue type dropdown
  createIssue(issueType: Issue) {
    return this.http
      .post(environment.BASE_URL + '/issuetype', JSON.stringify(issueType))
      .map(result => result);
  }

  // edit issue type dropdowm
  updateIssue(issueType: Issue) {
    return this.http
      .put(environment.BASE_URL + '/issuetype/' + issueType.Id, issueType)
      .map(result => result);
  }

  // delete issue type dropdown
  deleteIssue(id: number) {
    let result: boolean;
    return this.http.delete(environment.BASE_URL + '/issuetype/' + id);
  }

  createTopic(newTopic: Topic) {
    return this.http
      .post(environment.BASE_URL + '/Save', JSON.stringify(newTopic))
      .map(result => result);
  }

  updateTopic(updateTopic: Topic) {
    return this.http
      .put(environment.BASE_URL + '/Update', updateTopic)
      .map(result => result);
  }

  deleteTopic(id: number) {
    let result: boolean;
    return this.http.delete(environment.BASE_URL + '/Delete/' + id);
  }

  getUser(id: number) {
    return this.http
      .get(environment.BASE_URL + '/employees/' + id)
      .map(result => result);
  }

  addTaskPlan(newTaskPlan: any) {
    return this.http
      .post(environment.BASE_URL + '/RiskAnalysisTask', newTaskPlan)
      .map(result => result);
  }
  addSafetyJobAnalysis(task: any) {
    return this.http
      .post(environment.BASE_URL + '/foodsafety/safetyjobanalysis', task)
      .map(result => result);
  }
  updateTaskPlan(taskplan: TaskPlan) {
    return this.http
      .put(environment.BASE_URL + '/riskanalysistask', taskplan)
      .map(result => result);
  }
  updateFoodSafetTaskPlan(taskplan: TaskPlan) {
    return this.http
      .put(environment.BASE_URL + '/foodsafety/safetyjobanalysis', taskplan)
      .map(result => result);
  }
  getRiskById(id: number) {
    return this.http
      .get(environment.BASE_URL + '/riskanalysis/' + id)
      .map((result: any) => result);
  }
  getByAnalysis(id: number) {
    return this.http
      .get(environment.BASE_URL + '/riskanalysistaskplan/' + id)
      .map(result => result);
  }

  getActivityById(id: any) {
    return this.http
      .get(environment.BASE_URL + '/activity/' + id)
      .map(result => result);
  }

  updateJobAnalysis(updateSafetyJob: any, key: string) {
    return this.http
      .put(
        `${environment.BASE_URL}/${BaseServices.apiRoute(key)}/safetyjobanalysis`,
        updateSafetyJob
      )
      .map(result => result);
  }
  private extractData(res: any) {
    let body = res; // return body || {};
    return res;
  }

  deleteRiskAnanlysis(id: number) {
    let result: boolean;
    return this.http.delete(environment.BASE_URL + '/risanalysisdelete/' + id);
  }
  deleteTaskPlan(Id: any) {
    return this.http
      .delete(environment.BASE_URL + '/activitydelete/' + Id)
      .map(result => result);
  }
  deleteTaskPlanInRiskActvity(data: any) {
    return this.http
      .put(environment.BASE_URL + '/activitydeleteinriskactivity', data)
      .map(result => result);
  }
  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

  getCheckListData(businessId: number, featureId: number, featurekey: number) {
    return this.http
      .get(
        `${environment.BASE_URL}/feature/checklist/GetAllCheckList/${businessId}/${featureId}/${featurekey}`
      )
      .map(result => result);
  }

  getCompleteCheckListData(
    businessId: number,
    featureId: number,
    featurekey: number
  ) {
    return this.http
      .get(
        `${environment.BASE_URL}/features/FeatureManualComplete/getall/featurecompletechecklist/${businessId}/${featureId}/${featurekey}`
      )
      .map(result => result);
  }

  deleteCompleteCheckListData(Id: any) {
    return this.http
      .put(
        environment.BASE_URL + '/features/FeatureManualComplete/delete/' + Id,
        {}
      )
      .map(result => result);
  }

  deleteMultipleCompleteChecklist(data: any) {
    return this.http
      .put(
        environment.BASE_URL + '/features/FeatureManualComplete/delete/',
        data
      )
      .map(result => result);
  }
  // Delete document
  deleteDocument(did: any) {
    return this.http
      .delete(`${environment.BASE_URL}/feature/delete/${did}`)
      .map(result => result)
      .catch(error => BaseServices.handleError(error));
  }

  checkExistingFunction(functionId: any) {
    return this.http
      .get(environment.BASE_URL + '/checkcase/' + functionId)
      .map(result => result);
  }
  checkforTopicexistingFunction(functionId: any) {
    return this.http
      .get(environment.BASE_URL + '/checktopicforrisk/' + functionId)
      .map(result => result);
  }

  reportGenerate(
    businessId: number,
    generateReportUnderFolder: any,
    userId: number,
    projectId: number,
    featureId: number,
    cultureInfo: string,
    ReportJson: any,
    appId: number,
    withApprovalStatus: any
  ) {
    let dynamicType = 'application/pdf';
    let myData = JSON.stringify({ data: ReportJson });
    let url = `${environment.BASE_URL}/export-SJAlist/${businessId}/${generateReportUnderFolder}/${userId}/${projectId}/${featureId}/${cultureInfo}/${appId}/${withApprovalStatus}`;
    let crfToken = this.authenticationService.getCsrfToken();
    return Observable.create((observer: any) => {
      let xhr = new XMLHttpRequest();
      xhr.open('POST', url, true);
      xhr.withCredentials = true;
      // token = tokenParts.join('.'); commented for token issue fix
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('Logo-Id', 'ab064fd4-9b4a-4e70-972d-dafdaceb2aba');
      xhr.setRequestHeader('X-XSRF-TOKEN', crfToken);
      xhr.responseType = 'blob';
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            let contentType = dynamicType;
            let blob = new Blob([xhr.response], { type: contentType });
            observer.next(blob);
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };
      xhr.send(myData);
    });
  }
}
