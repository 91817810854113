import {
  Component,
  Input,
  OnInit,
  OnChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-deviation-list-charts',
  templateUrl: './deviation-list-charts.component.html',
  styleUrls: ['./deviation-list-charts.component.css'],
})
export class DeviationListChartsComponent implements OnInit, OnChanges {
  @Input() deviationList: any[];
  @Input() chartView: string;
  @Input() isPortal: boolean;
  @Output() buttonClicked = new EventEmitter<void>();

  single: any[] = [];
  departmentData: any[] = [];
  businessData: any[] = [];
  importanceData: any[] = [];
  view: any[];
  legendTitle: string;
  gradient: boolean = false;
  showLegend: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = false;
  colorScheme = {
    domain: ['#AAAAAA', '#4DC55C', '#F0AC19', '#0AB7E2'],
  };
  colorSchemeImportance = {
    domain: ['#4DC55C', '#F0AC19', '#E5634F'],
  };

  // Pagination properties
  currentDepartmentPage: number = 0;
  currentBusinessPage: number = 0;
  itemsPerPage: number = 10;
  paginatedDepartmentData: any[] = [];
  paginatedBusinessData: any[] = [];

  constructor(
    private translate: TranslateService,
    private cdr: ChangeDetectorRef
  ) {
    this.legendTitle = this.translate.instant('DESCRIPTION');
  }

  ngOnInit(): void {
    this.updateChartData();
  }

  ngOnChanges(): void {
    if (this.deviationList) {
      this.updateChartData();
    }
  }

  /**
   * Decodes HTML entities in a string.
   * @param str - The string to decode.
   * @returns The decoded string.
   */
  private decodeHtmlEntity(str: string): string {
    const textarea = document.createElement('textarea');
    textarea.innerHTML = str;
    return textarea.value;
  }

  /**
   * Updates all chart data.
   */
  async updateChartData(): Promise<void> {
    await this.updateStatusData();
    await this.updateDepartmentData();
    await this.updateBusinessData();
    await this.updateImportanceData();
  }

  /**
   * Updates the status data for the chart.
   */
  async updateStatusData(): Promise<void> {
    const statusCount = this.deviationList.reduce((acc, deviation) => {
      acc[deviation.Status] = (acc[deviation.Status] || 0) + 1;
      return acc;
    }, {});

    const allStatuses = ['REJECTED', 'DONE', 'OPEN', 'NEW'];
    const translatedStatuses = await this.translateStatuses(allStatuses);

    this.single = translatedStatuses.map((status, index) => {
      return {
        name: status,
        value: statusCount[allStatuses[index]] || 0,
      };
    });
  }

  /**
   * Updates the department data for the chart.
   */
  async updateDepartmentData(): Promise<void> {
    const departmentStatusCount = this.deviationList.reduce(
      (acc, deviation) => {
        const departments = (deviation.Department || 'Unknown').split(','); // Split multiple departments
        departments.forEach((department) => {
          department = department.trim(); // Trim any whitespace
          if (!acc[department]) {
            acc[department] = { REJECTED: 0, DONE: 0, OPEN: 0, NEW: 0 };
          }
          acc[department][deviation.Status] =
            (acc[department][deviation.Status] || 0) + 1;
        });
        return acc;
      },
      {}
    );

    const allStatuses = ['REJECTED', 'DONE', 'OPEN', 'NEW'];
    const translatedStatuses = await this.translateStatuses(allStatuses);

    this.departmentData = Object.keys(departmentStatusCount).map(
      (department) => {
        return {
          name: this.decodeHtmlEntity(department),
          series: translatedStatuses.map((status, index) => {
            return {
              name: status,
              value: departmentStatusCount[department][allStatuses[index]],
            };
          }),
        };
      }
    );

    this.paginateData('department');
  }

  /**
   * Updates the business data for the chart.
   */
  async updateBusinessData(): Promise<void> {
    const businessStatusCount = this.deviationList.reduce((acc, deviation) => {
      const business = deviation.BusinessName || 'Unknown';
      if (!acc[business]) {
        acc[business] = { REJECTED: 0, DONE: 0, OPEN: 0, NEW: 0 };
      }
      acc[business][deviation.Status] =
        (acc[business][deviation.Status] || 0) + 1;
      return acc;
    }, {});

    const allStatuses = ['REJECTED', 'DONE', 'OPEN', 'NEW'];
    const translatedStatuses = await this.translateStatuses(allStatuses);

    this.businessData = Object.keys(businessStatusCount).map((business) => {
      return {
        name: this.decodeHtmlEntity(business),
        series: translatedStatuses.map((status, index) => {
          return {
            name: status,
            value: businessStatusCount[business][allStatuses[index]],
          };
        }),
      };
    });

    this.paginateData('business');
  }

  /**
   * Updates the importance data for the chart.
   */
  async updateImportanceData(): Promise<void> {
    const importanceCount = this.deviationList.reduce((acc, deviation) => {
      if (deviation.Importance && deviation.Importance.value) {
        const importanceValue = deviation.Importance.value || 'Unknown';
        acc[importanceValue] = (acc[importanceValue] || 0) + 1;
      }
      return acc;
    }, {});

    const allImportances = ['LOW', 'MEDIUM', 'HIGH'];
    const translatedImportances = await this.translateImportances(
      allImportances
    );

    this.importanceData = translatedImportances.map((importance, index) => {
      return {
        name: importance,
        value: importanceCount[allImportances[index]] || 0,
      };
    });
  }

  /**
   * Translates an array of statuses.
   * @param statuses - The statuses to translate.
   * @returns A promise that resolves to the translated statuses.
   */
  async translateStatuses(statuses: string[]): Promise<string[]> {
    const translations = await Promise.all(
      statuses.map((status) => this.translate.get(status).toPromise())
    );
    return translations;
  }

  /**
   * Translates an array of importances.
   * @param importances - The importances to translate.
   * @returns A promise that resolves to the translated importances.
   */
  async translateImportances(importances: string[]): Promise<string[]> {
    const translations = await Promise.all(
      importances.map((importance) =>
        this.translate.get(importance).toPromise()
      )
    );
    return translations;
  }

  /**
   * Paginates the data for the given type (department or business).
   * @param type - The type of data to paginate ('department' or 'business').
   */
  paginateData(type: string): void {
    if (type === 'department') {
      const start = this.currentDepartmentPage * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      this.paginatedDepartmentData = this.departmentData.slice(start, end);
    } else if (type === 'business') {
      const start = this.currentBusinessPage * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      this.paginatedBusinessData = this.businessData.slice(start, end);
    }
    this.cdr.detectChanges(); // Ensure Angular detects changes
  }

  /**
   * Navigates to the previous page of data for the given type.
   * @param type - The type of data ('department' or 'business').
   */
  prevPage(type: string): void {
    this.buttonClicked.emit(); // Emit event when button is clicked

    if (type === 'department') {
      if (this.currentDepartmentPage > 0) {
        this.currentDepartmentPage--;
      } else {
        this.currentDepartmentPage =
          Math.ceil(this.departmentData.length / this.itemsPerPage) - 1;
      }
      this.paginateData('department');
    } else if (type === 'business') {
      if (this.currentBusinessPage > 0) {
        this.currentBusinessPage--;
      } else {
        this.currentBusinessPage =
          Math.ceil(this.businessData.length / this.itemsPerPage) - 1;
      }
      this.paginateData('business');
    }
  }

  /**
   * Navigates to the next page of data for the given type.
   * @param type - The type of data ('department' or 'business').
   */
  nextPage(type: string): void {
    this.buttonClicked.emit(); // Emit event when button is clicked

    if (type === 'department') {
      if (
        (this.currentDepartmentPage + 1) * this.itemsPerPage <
        this.departmentData.length
      ) {
        this.currentDepartmentPage++;
      } else {
        this.currentDepartmentPage = 0;
      }
      this.paginateData('department');
    } else if (type === 'business') {
      if (
        (this.currentBusinessPage + 1) * this.itemsPerPage <
        this.businessData.length
      ) {
        this.currentBusinessPage++;
      } else {
        this.currentBusinessPage = 0;
      }
      this.paginateData('business');
    }
  }

  /**
   * Checks if there are more pages of data for the given type.
   * @param type - The type of data ('department' or 'business').
   * @returns A boolean indicating if there are more pages.
   */
  hasMorePages(type: string): boolean {
    if (type === 'department') {
      return (
        (this.currentDepartmentPage + 1) * this.itemsPerPage <
        this.departmentData.length
      );
    } else if (type === 'business') {
      return (
        (this.currentBusinessPage + 1) * this.itemsPerPage <
        this.businessData.length
      );
    }
    return false;
  }
}
