<div class="main-content">
  <div class="main-heading">
    <span translate
      >{{ isEditMode ? ("EDIT" | translate) : ("NEW" | translate) }}
      {{ "CONTRACT_EL" | translate }}
    </span>
  </div>
  <div class="card">
    <div class="card-body">
      <form [formGroup]="elForm">
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <div class="radio-inline" *ngFor="let option of options">
                <label>
                  <input
                    type="radio"
                    formControlName="Status"
                    [checked]="option.id === elForm.controls['Status'].value"
                    name="Status"
                    value="{{ option.id }}"
                  />{{ option.name | translate }}
                </label>
              </div>
            </div>
            <div class="form-group">
              <label for="Title">
                <span translate>CONTRACT_NUMBER</span>
                <span class="required">*</span>
              </label>
              <div>
                <input
                  type="text"
                  (keypress)="onContractNumber($event, 35)"
                  formControlName="ContractNumber"
                  id="ContractNumber"
                  class="ex-full-width"
                />
              </div>
            </div>
            <div class="form-group">
              <label>
                <span translate>CONTRACT_NAME</span>
                <span class="required">*</span>
              </label>
              <div>
                <input
                  type="text"
                  pInputText
                  formControlName="ContractName"
                  id="ContactName"
                  class="ex-full-width"
                />
              </div>
            </div>
            <div class="form-group">
              <label>
                <span translate>ADDRESS</span>
              </label>
              <div>
                <input
                  type="text"
                  pInputText
                  formControlName="Address"
                  id="Address"
                  class="ex-full-width"
                />
              </div>
            </div>
            <div class="form-group">
              <label for="Title">
                <span translate>ZIP</span>
              </label>
              <div>
                <input
                  type="number"
                  pInputText
                  formControlName="Zip"
                  id="Zip"
                  class="ex-full-width"
                />
              </div>
            </div>
            <div class="form-group">
              <label for="Title">
                <span translate>CITY</span>
              </label>
              <div>
                <input
                  type="text"
                  pInputText
                  formControlName="City"
                  id="City"
                  class="ex-full-width"
                />
              </div>
            </div>
            <div class="form-group">
              <label for="Comments">
                <span translate>COMMENTS</span>
              </label>
              <div>
                <textarea
                  pInputTextarea
                  id="Comments"
                  formControlName="Comments"
                  class="ex-description"
                ></textarea>
              </div>
            </div>
            <div class="form-group">
              <label for="EmailDevaition">
                <span translate>EMAIL_NEWDEVIATION_LABEL</span>
              </label>
              <div *ngIf="bindSelectedUsers">
                <p-multiSelect
                  defaultLabel="{{ 'SELECT' | translate }}"
                  [dataKey]="'Id'"
                  [options]="userSubscribers"
                  formControlName="selectedEmailSubscriber"
                  [style]="{ width: '100%' }"
                >
                  <ng-template
                    let-emailDeviation
                    let-i="index"
                    pTemplate="item"
                  >
                    {{ i }}
                    <span>{{ emailDeviation.label }}</span>
                  </ng-template>
                </p-multiSelect>
              </div>
              <div *ngIf="bindAllUsers">
                <p-multiSelect
                  defaultLabel="{{ 'SELECT' | translate }}"
                  [dataKey]="'Id'"
                  [options]="selectedSubscribers"
                  formControlName="selectedEmailSMSSubscriber"
                  [style]="{ width: '100%' }"
                >
                  <ng-template
                    let-emailDeviations
                    let-i="index"
                    pTemplate="item"
                  >
                    {{ i }}
                    <span>{{ emailDeviations.label }}</span>
                  </ng-template>
                </p-multiSelect>
              </div>
            </div>
            <div class="checkbox">
              <p-checkbox
                label="{{ 'DO_NOT_DISPLAY_DEVIATION' | translate }}"
                formControlName="IsShowDeviationChecked"
                binary="true"
              ></p-checkbox>
            </div>
            <div class="form-group">
              <label for="Document" translate>MORE_ATTACHMENTS</label>
              <div>
                <ecpl-document-explorer
                  [uploaderConfig]="upConfig"
                  [uploadedFiles]="uploadedFiles"
                  (saveChanges)="saveUploaded($event)"
                >
                </ecpl-document-explorer>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group" [hidden]="IsHidden">
              <div class="sub-panel-title">
                <span translate>COPYFOLDERS_CONTENT</span>
              </div>
              <label for="ChooseContract" translate>CHOOSE_CONTRACT</label>
              <div>
                <p-dropdown
                  [options]="elList"
                  id="ChooseContract"
                  formControlName="ChooseContracts"
                  [style]="{ width: '500px' }"
                ></p-dropdown>
              </div>
            </div>
            <div class="form-group" [hidden]="IsHidden">
              <p-checkbox
                value="CopySja"
                label="{{ 'COPY_SJA' | translate }}"
                formControlName="CopySJA"
                binary="true"
              ></p-checkbox>
            </div>
            <div class="upload-file-container">
              <label for="Avatar">
                <span translate>PROJECT_IMAGE</span>
              </label>
              <div class="form-group">
                <div class="upload-file-container">
                  <div class="upload-actions">
                    <input
                      type="file"
                      id="businessfiles"
                      class="hidden"
                      (change)="filesSelect($event)"
                    />
                    <label
                      for="businessfiles"
                      class="btn btn-outline-primary"
                      translate
                      >UPLOAD_IMAGE</label
                    >
                  </div>
                  <div *ngIf="uploadStart">
                    <span translate>IMAGE_IS_UPLOADING</span>
                  </div>
                  <div *ngIf="kundeElImage != null">
                    <img src="{{ kundeElImage }}" alt="" width="200px" />
                  </div>
                </div>
              </div>
            </div>
            <div class="sub-panel-title">
              <span translate>ASSIGN_CONTRACT</span>
            </div>
            <div class="table-view">
              <!-- Table starts -->
              <p-table
                #dt
                [value]="elUsers"
                dataKey="Id"
                scrollable="true"
                scrollHeight="200px"
              >
                <!-- Header -->
                <ng-template pTemplate="header">
                  <tr>
                    <th>
                      <p-checkbox
                        [ngModel]="checkedAll"
                        (ngModelChange)="checkAll($event)"
                        [ngModelOptions]="{ standalone: true }"
                        binary="true"
                      ></p-checkbox>
                    </th>
                    <th>{{ "NAME" | translate }}</th>
                  </tr>
                </ng-template>
                <!-- Body Content -->
                <ng-template pTemplate="body" let-kundeEL>
                  <tr>
                    <td>
                      <p-checkbox
                        [(ngModel)]="kundeEL.Id === userId || kundeEL.status"
                        [disabled]="kundeEL.Id === userId"
                        (ngModelChange)="checked(kundeEL)"
                        [ngModelOptions]="{ standalone: true }"
                        binary="true"
                      ></p-checkbox>
                    </td>
                    <td>
                      <span>{{ kundeEL.Name }}</span>
                    </td>
                  </tr>
                </ng-template>
                <!-- Empty message -->
                <ng-template pTemplate="emptymessage" let-columns>
                  <tr>
                    <td colspan="2">
                      {{ "NO_RECORDS_FOUND" | translate }}
                    </td>
                  </tr>
                </ng-template>
              </p-table>
              <!-- Table Ends-->
            </div>
            <div class="sub-panel-title" *ngIf="showAssignClient">
              <span translate>ASSIGN_CLIENT</span>
            </div>
            <div class="table-view" *ngIf="showAssignClient">
              <!-- Table starts -->
              <p-table
                #dt
                [value]="kundeElClientUsers"
                dataKey="Id"
                [(selection)]="selectedClient"
                scrollable="true"
                scrollHeight="200px"
              >
                <!-- Header -->
                <ng-template pTemplate="header">
                  <tr>
                    <th style="width: 55px">
                      <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th>{{ "NAME" | translate }}</th>
                  </tr>
                </ng-template>
                <!-- Body Content -->
                <ng-template pTemplate="body" let-client>
                  <tr>
                    <td style="width: 55px">
                      <p-tableCheckbox id="selectedOrders" [value]="client">
                      </p-tableCheckbox>
                    </td>
                    <td>
                      <span>{{ client.Name }}</span>
                    </td>
                  </tr>
                </ng-template>
                <!-- Empty message -->
                <ng-template pTemplate="emptymessage" let-columns>
                  <tr>
                    <td colspan="2">
                      {{ "NO_RECORDS_FOUND" | translate }}
                    </td>
                  </tr>
                </ng-template>
              </p-table>
              <!-- Table Ends-->
            </div>
          </div>
        </div>
        <div class="action-btns-wrapper">
          <button class="btn btn-outline-secondary" (click)="gotoBack()">
            <span class="icon ic-xs icon-back"></span>
            <span translate>BACK_LIST</span>
          </button>
          <button
            *ngIf="isGuestButtonHide"
            class="btn btn-primary"
            (click)="saveChanges()"
            [disabled]="!elForm.valid"
          >
            <span translate>SAVE_AND_BACK</span>
          </button>
          <button
            *ngIf="isGuestButtonHide"
            class="btn btn-primary"
            type="submit"
            (click)="saveAndCreateNew()"
            [disabled]="!elForm.valid"
          >
            <span translate>SAVE_CREATE_NEW</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
<toaster-component></toaster-component>
