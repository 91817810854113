import { ActivityDelete } from './../models/task';
import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs/Observable';

import { ActivityList, Repetition, Tasklist } from '../models';
import { BaseServices } from './../../kuba.services';
import { HttpClient, HttpHeaderResponse } from '@angular/common/http';

@Injectable()
export class TaskService {
  constructor(private http: HttpClient) {}

  // get task using business id
  getAll(id: number) {
    return this.http
      .get(environment.BASE_URL + '/activities/' + id)
      .map(result => result);
  }
  getAllByEach(id: number, key: number) {
    return this.http
      .get<any>(
        `${environment.BASE_URL}/${BaseServices.apiRoute(
          key
        )}/task-manager/list/${id}`
      )
      .map(result => result);
  }

  // Add deviation
  addActivity(activity: ActivityList, key: number) {
    return this.http
      .post(
        `${environment.BASE_URL}/${BaseServices.apiRoute(key)}/task-manager`,
        activity
      )
      .map(result => result);
  }
  // for edit
  getById(id) {
    return this.http
      .get(environment.BASE_URL + '/activity/' + id)
      .map((response: any) => {
        return response;
      });
  }

  // add task to list
  addTask(data): Observable<Repetition[]> {
    let body = JSON.stringify(data);
    return this.http
      .post(environment.BASE_URL + '/internalcontrol/task-manager', body)
      .map((res: any) => {
        return res;
      });
  }

  // updating Task list
  updateTask(ExistingTask: ActivityList, key: string) {
    return this.http
      .put(
        `${environment.BASE_URL}/${BaseServices.apiRoute(key)}/task-manager`,
        ExistingTask
      )
      .map(result => result);
  }

  // delete task from list
  deleteTask(Id) {
    return this.http
      .delete(environment.BASE_URL + '/activitydelete/' + Id)
      .map(result => result);
  }
  // delete task from list
  deleteTaskInFeatureTable(activity: ActivityDelete) {
    return this.http
      .put(environment.BASE_URL + '/activitydeleteinfeature', activity)
      .map(result => result);
  }
  getTaskById(id: number) {
    return this.http
      .get(environment.BASE_URL + '/activity/' + id)
      .map(result => result);
  }

  delete(id: number) {
    // let result: boolean;
    return this.http
      .delete(environment.BASE_URL + '/activitydelete/' + id)
      .map(result => result);
  }

  deleteParentwithRecurring(id: number) {
    return this.http
      .delete(
        environment.BASE_URL + '/followup' + '/deleteParentwithRecurring/' + id
      )
      .map(result => result);
  }

  // create new task
  add(data): Promise<any> {
    let body = JSON.stringify(data);
    return this.http
      .post(environment.BASE_URL + '/activityadd', body)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  update(id, data) {
    return this.http
      .put(environment.BASE_URL + '/activityupdate/' + id, data)
      .toPromise()
      .then((response: any) => {
        return response;
      });
  }

  // bind repetition dropdown
  getAllRepeat() {
    return this.http
      .get(environment.BASE_URL + '/repetition')
      .map((response: any) => {
        return response;
      });
  }

  // bind  execution dropdown
  getAllExce(id: number) {
    return this.http
      .get(environment.BASE_URL + '/employees/' + id)
      .map((response: any) => {
        return response;
      });
  }

  // bind followup dropdown
  getAllFollow(id: number) {
    return this.http
      .get(environment.BASE_URL + '/employees/' + id)
      .map((response: any) => {
        return response;
      });
  }

  private extractData(res: any) {
    let body = res; // return body || {};
    return <Tasklist[]>res.json();
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
  getActivityLog(id: number) {
    return this.http
      .get(`${environment.BASE_URL}/project/task-manager-log/${id}`)
      .map(result => result);
  }

  getTaskManagerAuditLog(id: number, internalControlId: number, key: string) {
    return this.http
      .get(
        `${environment.BASE_URL}/${BaseServices.apiRoute(
          key
        )}/task-manager-auditlog/${id}/${internalControlId}`
      )
      .map((result: any) => result);
  }
  deleteActivityAttachment(Id) {
    return this.http
      .delete(environment.BASE_URL + '/deleteactivityattachment/' + Id)
      .map(result => result);
  }

  checkActivityRepetition(activityId: number) {
    return this.http
      .get(environment.BASE_URL + '/checkrepetition/' + activityId)
      .map((response: any) => {
        return response;
      });
  }

  getCheckListData(businessId: number, featureId: number, featurekey: number) {
    return this.http
      .get(
        `${environment.BASE_URL}/feature/checklist/GetAllCheckList/${businessId}/${featureId}/${featurekey}`
      )
      .map(result => result);
  }

  /**
   *
   * @param activityId
   */
  checkParentActivity(activityId: number) {
    return this.http
      .get(environment.BASE_URL + '/followup' + '/parentActivity/' + activityId)
      .map(result => result);
  }

  getActivityCompleteCheckList(activityId: number) {
    return this.http
      .get(
        environment.BASE_URL +
          '/features/FeatureManualComplete/get/checklistByActivity/' +
          activityId
      )
      .map(result => result);
  }

  getFeatureChecklistItems(chkId: number) {
    return this.http
      .get(environment.BASE_URL + '/feature/checklist/checklist/' + chkId)
      .map(result => result);
  }

  /**
   * stop future recurring
   * @param activityId
   */
  stopFutureRecurring(activityId) {
    return this.http
      .get(environment.BASE_URL + '/stopFutureRecurringActivity/' + activityId)
      .map((response: any) => {
        return response;
      });
  }

  getAssignedUsers(
    businessId: number,
    featureId: number,
    featurekey: number,
    applicationId: number
  ) {
    return this.http
      .get(
        `${environment.BASE_URL}/AssingedUsers/${businessId}/${featurekey}/${featureId}/${applicationId}`
      )
      .map(result => result);
  }

  getCheckList(featureId: number, featurekey: number) {
    return this.http
      .get(
        environment.BASE_URL +
          '/foodsafety/checklist/' +
          featureId +
          '/' +
          featurekey
      )
      .map(result => result);
  }

  DeleteWeekRecurrTask(Id: number) {
    return this.http
      .delete(environment.BASE_URL + '/deleteweekrecurrtask/' + Id)
      .map(result => result);
  }

  StopWeekFutureRecurrTask(Id: number) {
    return this.http
      .delete(environment.BASE_URL + '/stopweekfuturerecurrtask/' + Id)
      .map(result => result);
  }

  CompleteFSTask(Id: any, UserId: number) {
    return this.http
      .get(
        environment.BASE_URL + '/foodsafety/completefstask/' + Id + '/' + UserId
      )
      .map(result => result);
  }

  deleteParentwithRecurringFS(id: number) {
    return this.http
      .delete(
        environment.BASE_URL + '/foodsafety/deleteParentwithRecurring-fs/' + id
      )
      .map(result => result);
  }
}
