import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateRangeWithDirective } from './date-range-with.directive';
import { DateRangeEndDirective } from './date-range-end.directive';

@NgModule({
  declarations: [DateRangeWithDirective, DateRangeEndDirective],
  exports: [DateRangeWithDirective, DateRangeEndDirective],
  imports: [CommonModule],
})
export class DateRangeWithModule {}
