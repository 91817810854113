<div class="main-content">
  <form [formGroup]="portalForm">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group" *ngIf="portalForm.controls['Title']">
          <label for="title" class="col-form-label" translate
            >TITLE
            <span class="required">*</span>
          </label>
          <div>
            <input
              type="text"
              [readonly]="readonly"
              class="form-control"
              id="title"
              formControlName="Title"
            />
          </div>
          <control-messages
            [control]="portalForm.controls['Title']"
          ></control-messages>
        </div>
        <div class="form-group" *ngIf="portalForm.controls['Description']">
          <label for="Description" class="col-form-label" translate
            >DESCRIPTION
            <span class="required">*</span>
          </label>
          <div>
            <textarea
              class="form-control"
              [readonly]="readonly"
              id="Description"
              formControlName="Description"
              rows="25"
            ></textarea>
          </div>
          <control-messages
            [control]="portalForm.controls['Description']"
          ></control-messages>
        </div>
        <div class="form-group">
          <div class="checkbox">
            <p-checkbox
              [disabled]="readonly"
              value="copy SJA"
              label="{{ 'COMMON_PORTAL_COURSE_NAMES' | translate }}"
              formControlName="IsCommonPortalCourse"
              [binary]="true"
            ></p-checkbox>
          </div>
        </div>
        <div class="form-group">
          <label for="businessListName" class="col-form-label" translate
            >BUSINESS_LIST_NAME
          </label>
          <div>
            <input
              type="text"
              [readonly]="readonly"
              class="form-control"
              id="businessListName"
              formControlName="BusinessListName"
            />
          </div>
          <control-messages
            [control]="portalForm.controls['BusinessListName']"
          ></control-messages>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <div class="upload-file-container">
            <label translate>PORTAL_DASHBOARD_IMAGE</label>
            <div class="upload-actions">
              <input
                type="file"
                id="files"
                class="hidden"
                (change)="filesSelectForLogo($event)"
              />
              <label for="files" class="btn btn-outline-primary" translate
                >UPLOAD_IMAGE</label
              >
            </div>
            <div *ngIf="uploadStartForLogo">
              <span translate>IMAGE_IS_UPLOADING</span>
            </div>
            <div *ngIf="businessLogo != null">
              <img
                src="{{ businessLogo }}"
                alt=""
                style="max-width: 200px; max-height: 200px"
              />
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="upload-file-container">
            <label translate>Portal Logo</label>
            <div class="upload-actions">
              <input
                type="file"
                id="upload"
                class="hidden"
                (change)="filesSelectForPortalLogo($event)"
              />
              <label for="upload" class="btn btn-outline-primary" translate
                >UPLOAD_IMAGE</label
              >
            </div>
            <div *ngIf="uploadStartForPortalLogo">
              <span translate>IMAGE_IS_UPLOADING</span>
            </div>
            <div *ngIf="PortalLogo != null">
              <img
                src="{{ PortalLogo }}"
                alt=""
                style="max-width: 200px; max-height: 200px"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="action-btns-wrapper">
      <button
        class="btn btn-outline-secondary"
        (click)="gotoList()"
        *ngIf="!isProfileSettings"
      >
        <span class="icon ic-sm icon-back"></span>
        <span translate>BACK_LIST</span>
      </button>
      <button
        type="submit"
        class="btn btn-primary"
        (click)="savePortal()"
        [disabled]="!portalForm.valid"
      >
        <span translate>SAVE</span>
      </button>
    </div>
    <br />
    <label
      for="title"
      color
      class="col-form-label"
      *ngIf="isLabelDisabled"
      [ngStyle]="{ color: 'red' }"
    >
      <span translate>TOPIC_EXIST</span>
    </label>
  </form>
</div>

<toaster-component></toaster-component>
