import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  EmployeeContactUserResolver,
  EmployeeContactConvenerResolver
} from './../users/services/user.resolver.service';
import { KubaExportModule } from './../shared/kuba-exports/kuba-export.module';
import { PortalActivitiesComponent } from './components/activity-portal.component';
import { ActivityNewComponent } from './components/activity-new.component';
import {
  BusinessListResolver,
  RiskListResolver
} from './../businesses/services/business.resolver.service';
import { EcplTabNavigationModule } from 'src/app/shared/ecpl-tab-navigation/ecpl-tab-navigation.module';
import { EcplDocumentExplorerModule } from './../../shared/ecpl-document-explorer/ecpl-document-explorer.module';
import { EmployeeDocResolver } from './../employees/services/employee.resolver.service';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DeviationEditResolver } from '../deviation/services/deviation.resolver';
import {
  RiskAnalysisTopicCachedResolver,
  RiskAnalysisEditResolver,
  RiskAnalysisListResolver,
  RiskAnalysisDepartmentResolver,
  BusinessesListResolver,
  ApprovalNoticesResolver,
  RiskAnalysisLogResolver,
  RiskAnalysisAdminResolver,
  RiskAttachmentResolver,
  RiskAnalysisTopicForAdminResolver,
  FollowupDepartmentForAdminResolver,
  UserForAdminResolver,
  RiskAnalysisCategoryResolver
} from './services/risk-analysis.resolver.service';
import {
  FollowupDeviationListResolver,
  FollowupTopicResolver,
  AllDepartmentCachedResolver,
  FollowupCaseTypeResolver,
  BusinessResolver,
  ClientResolver,
  ResponsibleForExecutionResolver,
  DeviationsByUserResolver,
  EmployeeContactsListResolver,
  AllDepartmentResolver,
  DeviationUserResolver,
  AllDepartmentPortalResolver
} from './services/deviation.resolver.service';
import {
  ActivityListResolver,
  ActivityEditResolver,
  ResponsiblePersonListResolver,
  RepetionListResolver,
  ActivtyListByUserResolver,
  DepartmentCachedListResolver,
  ChecklistResolver,
  ActivityUserForAdminResolver,
  UserListResolver,
  RepetitionResolver,
  ActiveBusinessResolver
} from './services/activities.resolver.service';
import { ActivityServices } from './services/activities.services';
import { AppSharedModule } from './../shared/appShared.module';
import { RiskanalysisService } from './services/risk-analysis.service';
import { DeviationServices } from './services/deviation.services';
import { FollowUpsComponent } from './followups.component';
import {
  FollowUpActivitiesComponent,
  FollowUpDeviationComponent,
  MeetingCalendarComponent,
  DepartmentComponent,
  TopicComponent,
  uploadComponent,
  EditActivitiesComponent,
  ActivityListComponent,
  NewRiskAnalysisComponent,
  RiskAnalysisListComponent,
  RiskAnalysisComponent,
  DeviationListComponent,
  AddDeviationComponent,
  viewlogComponent,
  NewRiskPortalComponent
} from './components/index';
import { TranslateModule } from '@ngx-translate/core';
import { ArchiveTreeDataResolver } from '../archive/archive.resolver.service';
import { EcplDocumentTreeModule } from 'src/app/shared/ecpl-document-tree/ecpl-document-tree.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AdminContactsResolver } from '../contact/services/contact.resolver.service';
import { ContactServices } from '../contact/services/contact.service';
import { TableModule } from 'primeng/table';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, SharedModule } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { FileUploadModule } from 'primeng/fileupload';
import { MultiSelectModule } from 'primeng/multiselect';
import { SpinnerModule } from 'primeng/spinner';
import { PaginatorModule } from 'primeng/paginator';
import { FieldsetModule } from 'primeng/fieldset';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MyDatePickerModule } from 'mydatepicker';
import { RiskanalysisListChartComponent } from './components/riskanalysis-list-chart/riskanalysis-list-chart.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { DeviationListChartsComponent } from './components/deviation-list-charts/deviation-list-charts.component';
import { ActivityListChartComponent } from './components/activity-list-chart/activity-list-chart.component';

@NgModule({
  imports: [
    AppSharedModule,
    RouterModule,
    TranslateModule,
    ConfirmDialogModule,
    OverlayPanelModule,
    TableModule,
    SharedModule,
    DialogModule,
    CalendarModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    InputTextareaModule,
    InputTextModule,
    TranslateModule,
    AppSharedModule,
    RouterModule,
    ButtonModule,
    FileUploadModule,
    MultiSelectModule,
    EcplTabNavigationModule,
    FieldsetModule,
    SpinnerModule,
    PaginatorModule,
    KubaExportModule,
    EcplDocumentExplorerModule,
    EcplDocumentTreeModule,
    MyDatePickerModule,
    ModalModule.forRoot(),
    NgxChartsModule
  ],
  exports: [
    FollowUpsComponent,
    MeetingCalendarComponent,
    FollowUpDeviationComponent,
    NewRiskAnalysisComponent,
    DepartmentComponent,
    TopicComponent,
    uploadComponent,
    EditActivitiesComponent,
    ActivityListComponent,
    NewRiskAnalysisComponent,
    RiskAnalysisComponent,
    RiskAnalysisListComponent,
    DeviationListComponent,
    AddDeviationComponent,
    viewlogComponent,
    NewRiskPortalComponent,
    ActivityNewComponent,
    PortalActivitiesComponent
  ],
  declarations: [
    FollowUpsComponent,
    MeetingCalendarComponent,
    FollowUpActivitiesComponent,
    FollowUpDeviationComponent,
    NewRiskAnalysisComponent,
    DepartmentComponent,
    TopicComponent,
    uploadComponent,
    EditActivitiesComponent,
    ActivityListComponent,
    NewRiskAnalysisComponent,
    RiskAnalysisComponent,
    RiskAnalysisListComponent,
    DeviationListComponent,
    AddDeviationComponent,
    viewlogComponent,
    NewRiskPortalComponent,
    ActivityNewComponent,
    PortalActivitiesComponent,
    RiskanalysisListChartComponent,
    DeviationListChartsComponent,
    ActivityListChartComponent
  ],
  providers: [
    ActivityServices,
    DeviationServices,
    ConfirmationService,
    ActivityListResolver,
    RiskanalysisService,
    DeviationEditResolver,
    ActivityEditResolver,
    RiskAnalysisTopicCachedResolver,
    RiskAnalysisEditResolver,
    RiskAnalysisListResolver,
    RiskAnalysisDepartmentResolver,
    FollowupDeviationListResolver,
    FollowupTopicResolver,
    AllDepartmentCachedResolver,
    FollowupCaseTypeResolver,
    BusinessResolver,
    ClientResolver,
    ResponsibleForExecutionResolver,
    ResponsiblePersonListResolver,
    RepetionListResolver,
    ActivtyListByUserResolver,
    DeviationsByUserResolver,
    BusinessesListResolver,
    DepartmentCachedListResolver,
    EmployeeContactsListResolver,
    EmployeeDocResolver,
    ApprovalNoticesResolver,
    ArchiveTreeDataResolver,
    ChecklistResolver,
    EmployeeContactUserResolver,
    EmployeeContactConvenerResolver,
    BusinessListResolver,
    RiskAnalysisLogResolver,
    ActivityUserForAdminResolver,
    RiskAnalysisAdminResolver,
    RiskAttachmentResolver,
    RiskAnalysisTopicForAdminResolver,
    FollowupDepartmentForAdminResolver,
    UserForAdminResolver,
    UserListResolver,
    AllDepartmentResolver,
    AdminContactsResolver,
    ContactServices,
    RepetitionResolver,
    ActiveBusinessResolver,
    DeviationUserResolver,
    AllDepartmentPortalResolver,
    RiskListResolver,
    RiskAnalysisCategoryResolver
  ]
})
export class FollowUpsModule {}
