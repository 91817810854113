import { UserService } from './../../users/services/user.service';
import { User } from './../../users/models/user';

import { FoodSafetyServices } from './../../foodsafety/services/foodsafety.services';
import { FoodSafetyList } from './../../foodsafety/models/foodsafety-list';
import { CommonChecklistService } from '../../common/services/common-checklist.services';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { BusinessDepartmentService } from './../../business-department.services';
import {
    Activity,
    Execution,
    FollowUpActivity,
    Repetition
} from '../models/activities';
import { BaseServices } from './../../kuba.services';
import { ActivityServices } from './activities.services';

@Injectable()
export class ActivityListResolver implements Resolve<Activity[]> {
    constructor(private backend: ActivityServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<Activity[]> {
        if (BaseServices.roleId === '1') {
            return this.backend.getAdminActivity(BaseServices.ApplicationId);
        } else if (BaseServices.roleId === '2') {
            return this.backend.getPortalActivity(BaseServices.UserId);
        } else {
            return this.backend.list(BaseServices.BusinessId);
        }
    }
}

@Injectable()
export class ActivityEditResolver implements Resolve<FollowUpActivity> {
    constructor(private backend: ActivityServices) { }
    resolve(route: ActivatedRouteSnapshot) {
        return this.backend.get(+route.params['aid']);
    }
}

@Injectable()
export class ResponsiblePersonListResolver implements Resolve<any> {
    constructor(private backend: ActivityServices) { }
    resolve(route: ActivatedRouteSnapshot) {
        return this.backend.getAllFollowUp(BaseServices.BusinessId);
    }
}
@Injectable()
export class RepetionListResolver implements Resolve<Repetition[]> {
    constructor(private backend: ActivityServices) { }
    resolve(route: ActivatedRouteSnapshot) {
        return this.backend.getAllRepetition();
    }
}
@Injectable()
export class ActivtyListByUserResolver implements Resolve<Activity[]> {
    constructor(private backend: ActivityServices) { }
    resolve(route: ActivatedRouteSnapshot) {
        if (window.name !== 'Remote') {
            if (BaseServices.roleId !== '2') {
                let result:any = this.backend.getActivitiesByUser(BaseServices.UserId);
                return result;
            }
        }
        return null;
    }
}
@Injectable()
export class DepartmentCachedListResolver implements Resolve<any> {
    constructor(private backend: BusinessDepartmentService) { }
    resolve(route: ActivatedRouteSnapshot) {
        return this.backend.getCachedBusinessDepartment();
    }
}
@Injectable()
export class ChecklistResolver implements Resolve<any[]> {
    constructor(private backend: CommonChecklistService) { }
    resolve(route: ActivatedRouteSnapshot) {
        return this.backend.getCheckListforTemplate(
            0,
            BaseServices.ApplicationId,
            0,
            BaseServices.BusinessId,
            0
        );
    }
}
@Injectable()
export class AdminActivityResolver implements Resolve<any> {
    constructor(private backend: ActivityServices) { }
    resolve(route: ActivatedRouteSnapshot) {
        return this.backend.getAdminActivity(BaseServices.ApplicationId);
    }
}
@Injectable()
export class ActiveBusinessResolver implements Resolve<any> {
    constructor(private backend: ActivityServices) { }
    resolve(route: ActivatedRouteSnapshot) {
        if (BaseServices.roleId === '1' || BaseServices.roleId === '2') {
            let result: any = this.backend.activeBusinesslist();
            return result;
        }
    }
}
@Injectable()
export class RepetitionResolver implements Resolve<any> {
    constructor(private backend: ActivityServices) { }
    resolve(route: ActivatedRouteSnapshot) {
        if (BaseServices.roleId === '1') {
            return this.backend.repetitionByAdmin(BaseServices.ApplicationId);
        } else if (BaseServices.roleId === '2') {
            return this.backend.repetitionByPortal(BaseServices.UserId);
        } else {
            return this.backend.repetitionByBusiness(BaseServices.BusinessId);
        }
    }
}
@Injectable()
export class PortalActivityResolver implements Resolve<any> {
    constructor(private backend: ActivityServices) { }
    resolve(route: ActivatedRouteSnapshot) {
        return this.backend.getPortalActivity(BaseServices.UserId);
    }
}

@Injectable()
export class ActivityUserForAdminResolver implements Resolve<any> {
    constructor(private backend: ActivityServices) { }
    resolve(route: ActivatedRouteSnapshot) {
        return this.backend.getUserForAdminActivity(+route.params['aid']);
    }
}
@Injectable()
export class UserListResolver implements Resolve<User[]> {
    constructor(private backend: UserService, private activityservice: ActivityServices) { }
    resolve(): Observable<User[]> {
        if (BaseServices.roleId === '1') {
            return this.activityservice.responsiblePersons();
        } else {
            return this.backend.getUser(BaseServices.BusinessId);
        }

    }
}