import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EcplConfirmViewerListComponent } from './ecpl-confirm-viewer-list.component';
import { EcplConfirmViewerComponent } from './ecpl-confirm-viewer.component';
import { EcplImageViewerModule } from '../ecpl-image-viewer/ecpl-image-viewer.module';
import { EcplArticleViewerModule } from '../ecpl-article-viewer/ecpl-article-viewer.module';
import { EcplPdfViewerModule } from '../ecpl-pdf-viewer';
import { EcplOnlyofficeViewerModule } from '../ecpl-onlyoffice-viewer';
import { TranslateModule } from '@ngx-translate/core';
import { EcplConfirmViewerService } from './ecpl-confirm-viewer.service';


@NgModule({
  declarations: [EcplConfirmViewerListComponent, EcplConfirmViewerComponent],
  imports: [CommonModule,
    EcplImageViewerModule,
    EcplArticleViewerModule,
    EcplPdfViewerModule,
    EcplOnlyofficeViewerModule,
    TranslateModule.forRoot(), 
  ],
  exports: [EcplConfirmViewerListComponent, EcplConfirmViewerComponent],
  providers: [EcplConfirmViewerService]
})
export class EcplConfirmViewerModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: EcplConfirmViewerModule
    };
  }
}