<div class="tab-content">
  <div class="page-title" translate>
    {{ employeeName }}
  </div>
  <form [formGroup]="employeeForm">
    <div class="row">
      <div class="col-sm-4 section">
        <h4 translate>COMMON_DETAILS</h4>
        <div class="sub-panel-title">
          <span translate>USER_IMAGE</span>
        </div>
        <div class="upload-file-container">
          <div class="form-group">
            <div
              *ngIf="!employeeImage"
              class="default-image-container center-image"
            >
              <div class="image-wrapper" (click)="triggerFileInput()">
                <img
                  [src]="defaultImage"
                  alt="Default Image"
                  width="150"
                  height="150"
                  class="profile_image"
                />
                <div class="overlay profile_image" translate>UPLOAD_IMAGE</div>
              </div>
            </div>
            <div
              *ngIf="employeeImage"
              class="uploaded-image-container center-image"
            >
              <div class="image-wrapper" (click)="triggerFileInput()">
                <img
                  [src]="employeeImage"
                  alt="User Image"
                  width="150"
                  height="150"
                  class="profile_image"
                />
                <div class="overlay profile_image" translate>UPLOAD_IMAGE</div>
              </div>
            </div>
            <div class="upload-actions center-image">
              <input
                type="file"
                id="uploadimage"
                class="hidden"
                (change)="filesSelect($event)"
                #fileInput
              />
              <!-- <label
                for="uploadimage"
                class="btn btn-outline-primary m-0"
                translate
              >
                UPLOAD_IMAGE
              </label> -->
              <button
                type="button"
                *ngIf="employeeImage && showMyPageSaveButton === true"
                class="btn btn-icon text-danger"
                title="{{ 'DELETE' | translate }}"
                (click)="deleteEmpImage(employeeDetail)"
              >
                <i class="icon ic-sm icon-trash"></i>
              </button>
            </div>
            <div *ngIf="uploadStart">
              <span translate>IMAGE_IS_UPLOADING</span>
            </div>
          </div>
        </div>

        <div class="form-group" *ngIf="employeeForm.controls['Name']">
          <label for="Name">
            <span translate>NAME</span>
            <span class="required">*</span>
          </label>
          <div>
            <input
              type="text"
              pInputText
              formControlName="Name"
              (keypress)="onKeyChange($event)"
              maxlength="100"
            />
          </div>
          <control-messages
            [control]="employeeForm.controls['Name']"
          ></control-messages>
        </div>
        <div class="form-group">
          <label for="Dob">
            <span translate>DATE_BIRTH</span>
          </label>
          <div>
            <p-calendar
                dateFormat="dd/mm/yy"
                [style]="{ width: '100%' }"
                [showIcon]="true"
                formControlName="Dob"
                placeholder="{{ 'DATE_BIRTH' | translate }}"
                >
                </p-calendar>
          </div>
        </div>

        <div class="form-group">
          <label for="Address">
            <span translate>ADDRESS</span>
          </label>
          <div>
            <textarea
              id="Address"
              name="Address"
              pInputTextarea
              formControlName="Address"
              [autoResize]="true"
            ></textarea>
          </div>
        </div>
        <div class="form-group-25_75">
          <div class="form-group">
            <label for="Zip">
              <span translate>ZIP_CODE</span>
            </label>
            <div>
              <input
                type="number"
                pInputText
                formControlName="ZipOrCity"
                id="Zip"
                (keypress)="onNumberChange($event, 6)"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="Location">
              <span translate>LOCATION</span>
            </label>
            <div>
              <input
                type="text"
                pInputText
                formControlName="Location"
                id="Location"
              />
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="Mobile">
            <span translate>MOBILE</span>
          </label>
          <div *ngIf="!IsHidding" class="phone-field">
            <p-dropdown
              [options]="CountryCode"
              formControlName="CountryCode"
              [filter]="true"
              [style]="{ width: '110px' }"
            >
            </p-dropdown>
            <input
              type="number"
              pInputText
              formControlName="Mobile"
              (keypress)="onNumberChange($event, 20)"
            />
            <button class="btn btn-link" type="button" (click)="showDialog()">
              <span class="icon ic-sm icon-043"></span>
            </button>
          </div>
          <!-- <div [hidden]="IsHide" class="phone-field"> -->
          <div *ngIf="!IsHide" class="phone-field">
            <input
              type="number"
              pInputText
              formControlName="OtherCode"
              (keypress)="onNumberChange($event, 4)"
            />
            <input
              type="number"
              pInputText
              formControlName="Mobile"
              (keypress)="onNumberChange($event, 20)"
            />
            <button class="btn btn-link" type="button" (click)="showDialog()">
              <span class="icon ic-sm icon-043"></span>
            </button>
          </div>
          <!-- <div *ngIf="!IsHidding">
            <a
              href="javascript:void(0);"
              class="btn btn-link"
              routerLinkActive="active"
              (click)="changeCountryCode()"
            >
              <span translate>OTHER_CODES</span>
            </a>
          </div>
          <div *ngIf="!IsHide">
            <a
              routerLinkActive="active"
              href="javascript:void(0);"
              class="btn btn-link"
              (click)="changeToCountryCodeDropDown()"
            >
              <span translate>PREDEFINED_CODES</span>
            </a>
          </div> -->
        </div>
        <div class="form-group-50_50">
          <div class="form-group">
            <label for="Telephone">
              <span translate>TELEPHONE</span>
            </label>
            <div>
              <input
                type="number"
                pInputText
                formControlName="Telephone"
                id="Telephone"
                onpaste="return false;"
                (keypress)="onNumberChange($event, 20)"
              />
            </div>
          </div>
        </div>
        <div class="form-group" *ngIf="employeeForm.controls['Email']">
          <label for="Email">
            <span translate>EMAIL</span>
            <span class="required">*</span>
          </label>
          <div>
            <input
              type="text"
              pInputText
              formControlName="Email"
              maxlength="100"
            />
          </div>
          <control-messages
            [control]="employeeForm.controls['Email']"
          ></control-messages>
        </div>
        <div class="form-group" *ngIf="isShowHSE">
          <label for="Number">
            <span translate>HSE_CARD_NUMBER</span>
          </label>
          <div>
            <input
              type="number"
              pInputText
              formControlName="HSE_Cardnumber"
              onpaste="return false;"
              id="HSE_Cardnumber"
              (keypress)="onNumberChange($event, 20)"
            />
          </div>
        </div>
      </div>
      <div class="col-sm-4 section">
        <h4 translate>EMPLOYMENT_INFORMATION</h4>
        <div class="form-group">
          <label for="Number">
            <span translate>EMPLOYEE_NUMBER</span>
          </label>
          <div>
            <input
              type="number"
              pInputText
              formControlName="Number"
              onpaste="return false;"
              id="Number"
              (keypress)="onNumberChange($event, 20)"
            />
          </div>
        </div>

        <div class="form-group" *ngIf="employeeForm.controls['TypeOfEmployee']">
          <label for="Type">
            <span translate>TYPE_OF_EMPLOYEE</span>
            <span class="required">*</span>
          </label>
          <div class="input-btn-group">
            <p-dropdown
              [filter]="true"
              formControlName="TypeOfEmployee"
              [options]="types"
              [disabled]="hideSaveUserButton"
            ></p-dropdown>
            <button
              class="btn btn-outline-primary"
              type="button"
              *ngIf="isAddNewEmployee"
              (click)="addType = !addType"
            >
              <i class="pi pi-plus"></i>
            </button>
          </div>
          <control-messages
            [control]="employeeForm.controls['TypeOfEmployee']"
          ></control-messages>
        </div>

        <div
          class="form-group"
          *ngIf="employeeForm.controls['EmployeePosition']"
        >
          <label for="Position">
            <span translate>POSITION</span>
            <span class="required">*</span>
          </label>
          <div class="input-btn-group">
            <p-dropdown
              [filter]="true"
              formControlName="EmployeePosition"
              [options]="positions"
              [style]="{ width: '100%' }"
            ></p-dropdown>
            <button
              class="btn btn-outline-primary"
              type="button"
              *ngIf="isAddNewEmployee"
              (click)="addPosition = !addPosition"
            >
              <i class="pi pi-plus"></i>
            </button>
          </div>
          <control-messages
            [control]="employeeForm.controls['EmployeePosition']"
          ></control-messages>
        </div>
        <div class="form-group">
          <label for="Percentage">
            <span translate>PERCENTAGE</span>
          </label>
          <div>
            <p-dropdown
              formControlName="Percentage"
              id="Percentage"
              [options]="percentage"
              [style]="{ width: '100%' }"
              [disabled]="hideSaveUserButton"
            ></p-dropdown>
          </div>
        </div>
        <div
          class="form-group"
          *ngIf="employeeForm.controls['BusinessDepartmentId']"
        >
          <label for="BusinessDepartmentId">
            <span translate>DEPARTMENT</span>
            <span class="required">*</span>
          </label>
          <div class="input-btn-group">
            <p-multiSelect
              [filter]="true"
              formControlName="BusinessDepartmentId"
              id="Department"
              [options]="departments"
              [style]="{ width: '100%' }"
              #departmentDropdown
              (onChange)="onDepartmentDropDownChanged(departmentDropdown)"
              [disabled]="hideSaveUserButton"
              [defaultLabel]="'CHOOSE' | translate"
            ></p-multiSelect>
            <button
              class="btn btn-outline-primary"
              type="button"
              *ngIf="isAddNewEmployee"
              (click)="viewDepartmentDialog()"
            >
              <i class="pi pi-plus" aria-hidden="true"></i>
            </button>
          </div>
          <control-messages
            [control]="employeeForm.controls['BusinessDepartmentId']"
          ></control-messages>
        </div>
        <div class="form-group" [hidden]="IsHidden">
          <label for="Position">
            <span translate>SUB_DEPARTMENT_NAME</span>
          </label>
          <div class="input-btn-group">
            <p-dropdown
              [filter]="true"
              [options]="subDepartmentsList"
              formControlName="SubDepartmentId"
              [style]="{ width: '100%' }"
              [disabled]="hideSaveUserButton"
            ></p-dropdown>
            <button
              class="btn btn-outline-primary"
              type="button"
              *ngIf="isAddNewEmployee"
              (click)="addSubDept = !addSubDept"
            >
              <i class="pi pi-plus" aria-hidden="true"></i>
            </button>
          </div>
        </div>

      </div>
      <div class="col-sm-4 section">
        <h4 translate>SETTINGS</h4>

        <div class="form-group">
          <div class="mb-2">
            <p-checkbox
              label="{{ 'RECEIVE_MAIL_DEVIATION' | translate }}"
              [binary]="true"
              formControlName="NotifyMyDeviationsChange"
            ></p-checkbox>
          </div>
          <div class="mb-2">
            <p-checkbox
              label="{{ 'RECEIVE_NEW_DEVIATION' | translate }}"
              formControlName="IsEmailNewDeviation"
              [binary]="true"
            ></p-checkbox>
          </div>
          <div class="mb-2">
            <p-checkbox
              (onChange)="onShowDashBoard($event)"
              label="{{ 'SHOW_DASHBOARD' | translate }}"
              [binary]="true"
              formControlName="ShowIndashboard"
              [disabled]="hideSaveUserButton"
            >
            </p-checkbox>
          </div>
          <div class="form-group" *ngIf="isShowOnDashBoard">
            <label for="Address">
              <span translate>FUNCTION</span>
              <span class="required">*</span>
            </label>
            <div>
              <p-dropdown
                [filter]="true"
                id="Function"
                formControlName="ContactFunctionalAreaId"
                [options]="functions"
                [style]="{ width: '200px' }"
              >
              </p-dropdown>
            </div>
          </div>
          <div class="mb-2">
            <p-checkbox
              label="{{ 'NOTIFICATION_SMS' | translate }}"
              formControlName="NotificationBySms"
              [binary]="true"
            ></p-checkbox>
          </div>
          <div class="mb-2">
            <p-checkbox
              label="{{ 'NOTIFICATION_EMAIL' | translate }}"
              formControlName="NotificationByEmail"
              [binary]="true"
            ></p-checkbox>
          </div>
          <!-- Conmmented out as this is never used -->
          <!-- <div class="mb-2">
            <p-checkbox
              label="{{ 'SHOW_GUEST' | translate }}"
              formControlName="IsShowToGuest"
              [binary]="true"
              [disabled]="hideSaveUserButton"
            >
            </p-checkbox>
          </div> -->
          <div class="mb-2">
            <p-checkbox
              [(ngModel)]="checked"
              label="{{ 'APPROVED_LICENSE_AGREEMENT' | translate }}"
              [disabled]="true"
              formControlName="IsApprovalNotice"
              [binary]="true"
              (onChange)="onApprovedDateChecked($event)"
            >
            </p-checkbox>
          </div>
          <label *ngIf="approvedDate">
            <span translate>READ_APPLICATION</span>
            {{ approvedDate | date : "dd/MM/yyyy, h:mm a" }}
          </label>
        </div>
        <p-dialog
          header="Kuba - Terms and Conditions"
          [(visible)]="displayDialog"
          [style]="{ width: '560px' }"
          [resizable]="false"
          [draggable]="false"
          showEffect="fade"
          [closable]="false"
          [modal]="true"
          styleClass="terms-dialog"
        >
          <div>
            <h4>PERSONVERNPOLICY</h4>
            <h5>Slik behandler KUBA NORGE AS personopplysninger</h5>
            <p>
              Vi er avhengige av tillit fra våre kunder. Derfor er vi også
              opptatt av å ivareta ditt personvern. Alle dine personopplysninger
              skal være trygge hos oss. Det inkluderer alt som kan knyttes
              direkte til deg som person, blant annet adresse,
              kontaktiemployeeDetail.Avatarnformasjon, GPS-posisjoner og øvrige
              personopplysninger.
            </p>
            <h4>Innsamling av personopplysninger</h4>
            <p>
              KUBA NORGE AS behandler personopplysninger først og fremst i
              forbindelse med kundeservice, kundeoppfølging,
              kundeadministrasjon, markedsføring, fakturering og gjennom
              avtalene vi har inngått med våre kunder. <br />
              KUBA NORGE AS innhenter som regel opplysningene direkte fra deg.
              Noen ganger henter vi opplysninger fra andre kilder, som kan være
              både offentlige og private institusjoner. I tillegg innhentes GPS
              posisjoner automatisk gjennom noen av våre produkter. Disse GPS
              posisjonene er også å anse som personopplysninger. <br />
              Vi informerer deg når vi innhenter opplysninger om deg og KUBA
              NORGE AS er behandlingsansvarlig for disse opplysningene, med
              mindre innsamlingen er lovbestemt, hvis varsling er umulig eller
              uforholdsmessig vanskelig, eller hvis vi vet at du allerede er
              kjent med at vi innhenter disse opplysningene. Dersom KUBA NORGE
              AS mottar dine opplysninger i listeform i forbindelse med
              markedsføringsaktiviteter, vil du ved første kommunikasjon fra
              KUBA NORGE AS relatert til markedsføring basert på slik liste, ha
              mulighet til å reservere deg fra fremtidig kommunikasjon basert på
              slik liste. I tilfeller hvor KUBA NORGE AS samler inn
              personopplysninger på oppdrag fra våre kunder, og slik sett
              opptrer som en databehandler på vår kundes vegne, vil det være vår
              kunde som har ansvaret for informasjonen til deg. Vi vil i slike
              tilfeller også kunne gi deg informasjon direkte, men da på oppdrag
              fra vår kunde. <br />
              Tidvis kan vi gjøre opptak av telefonsamtaler hvis du samtykker
              til det.
            </p>

            <h4>Kundeundersøkelser</h4>
            <p>
              Når du har vært i kontakt med KUBA NORGE AS, kan vi spørre deg om
              hvordan opplevelsen din var. Da lærer vi mer om hvordan vi kan gi
              våre kunder enda bedre produkter og tjenester. Ved hjelp av disse
              tilbakemeldingene kan vi også måle effekten av forbedringstiltak
              og se på koblingen mellom kundetilfredshet og kundeadferd over
              tid. <br />
              Hvis du ikke vil dele denne typen opplysninger med oss, kan du la
              være å svare på undersøkelsen vi sender deg.
            </p>

            <h4>Nyhetsbrev og markedsføring</h4>
            <p>
              Du vil kunne motta markedsføring, informasjon og varslinger fra
              KUBA NORGE AS. Dette kan komme på e-post, gjennom våre hjemmesider
              eller i andre hensiktsmessige kanaler. Dette vil kunne komme i tre
              forskjellige kategorier:
            </p>
            <ul>
              <li>
                Informasjon og nyheter, som regel i form av nyhetsbrev på
                e-post.
              </li>
              <li>Systemvarslinger om dine tjenester fra KUBA NORGE AS.</li>
            </ul>
            <p>
              Du kan på ethvert tidspunkt registrere eller avregistrere deg i
              forhold til å motta denne kommunikasjonen.
            </p>

            <h4>Utlevering av personopplysninger</h4>
            <p>
              Internt i KUBA NORGE AS har vi et kunderegister som er
              tilgjengelig for KUBA NORGE AS, datterselskaper og
              samarbeidspartnere. Formålet med dette kunderegisteret er å gi
              kundene våre best mulig service, samt informasjon og tilbud om
              produkter og tjenester vi tilbyr. Kunderegister kan inneholde
              disse opplysningene om deg:
            </p>
            <ul>
              <li>navn</li>
              <li>kontaktopplysninger</li>
              <li>hvilket selskap du er ansatt i</li>
              <li>opplysninger om hvilket konsernselskap du er kunde i</li>
              <li>hvilke tjenester og produkter du har avtale om</li>
              <li>historikk over din kontakt med KUBA NORGE AS</li>
            </ul>

            <h4>Behandlingsansvarlig</h4>
            <p>
              Behandlingsansvarlig er den som bestemmer formålet med
              behandlingen av personopplysninger. For personopplysninger som
              samles inn basert på oppfyllelse av avtaler med våre kunder vil
              det som regel være kunden selv som er behandlingsansvarlig.
            </p>

            <h4>Databehandler</h4>
            <p>
              Når det er KUBA NORGE AS sin kunde som er Behandlingsansvarlig har
              KUBA NORGE AS rollen som Databehandler. For dette formål er det
              inngått en Databehandleravtale mellom kunden og KUBA NORGE AS.
              <br />
              KUBA NORGE AS har også inngått avtaler med underleverandører om
              databehandling. Egne databehandleravtaler regulerer alle
              personopplysninger som deles med våre underleverandører. Våre
              underleverandører kan ikke bruke opplysningene til andre formål
              enn det som er bestemt av Behandlingsansvarlig.
            </p>

            <h4>Dine rettigheter</h4>
            <h4>Innsyn</h4>
            <p>
              Du har rett til å få informasjon om hvilke personopplysninger vi
              behandler og hvordan vi behandler dem. En god del av den
              informasjonen vi har om deg som kunde, får du oversikt over på
              dine personlige sider. Forespørsler om ytterligere innsyn gjøres
              til Behandlingsansvarlig for opplysningene. For ansatte i
              bedrifter som er kunder av KUBA NORGE AS er noen datafelter kun
              synlige for den ansatte selv, og ikke for arbeidsgiver (bl.a.
              private turer i KUBA NORGE AS Kjørebok). Dersom det via
              arbeidsgiver (Behandlingsansvarlig) bes om kopi av alle data
              registrert på den ansatte, gjøres det oppmerksom på at disse data
              da også kan være synlige for arbeidsgiver i denne prosessen.
            </p>

            <h4>Retting</h4>
            <p>
              Det er viktig at opplysningene vi har om deg er riktige og at de
              er nødvendige for å iverksette avtalene vi har inngått med deg og
              gi deg god service. Du kan kreve at vi retter og sletter
              opplysninger om deg hvis de er mangelfulle eller unødvendige. Krav
              om retting gjøres til Behandlingsansvarlig for opplysningene.
            </p>

            <h4>Sletting</h4>
            <p>
              KUBA NORGE AS sletter personopplysninger når de ikke lenger er
              nødvendige for å oppfylle det formålet de ble innhentet for. Det
              betyr at så lenge du har en avtale med oss, lagrer vi nødvendige
              opplysninger om deg. Når avtaleforholdet avsluttes, vil vi slette
              data med mindre avtale om videre lagring er inngått. Mer
              informasjon om sletting finnes i vilkår for våre tjenester. Krav
              om sletting av opplysninger rettes til Behandlingsansvarlig. Om
              det er lovbestemte krav til minimum lagringstid, for eksempel for
              regnskapsmateriale, vil ikke sletting kunne gjøres før denne
              minimumstiden er passert. Informasjonskapsler (cookies)
            </p>

            <h4>Formål</h4>
            <p>
              Informasjonskapsler (cookies) er små filer som lagrer informasjon
              om hvordan du bruker en internettside. Informasjonen lagres i den
              nettleseren du bruker, altså hos deg. KUBA NORGE AS bruker
              informasjonskapsler på våre hjemmesider for å:
            </p>
            <ul>
              <li>
                lære av din adferd, slik at vi kan forbedre nettstedets
                funksjonalitet, brukeropplevelse og innhold
              </li>
              <li>
                tilpasse innholdet, slik at det blir mest mulig relevant for deg
              </li>
              <li>
                gi deg relevant og tilpasset markedsføring på andre nettsteder
                du besøker
              </li>
            </ul>
            <p>
              Du kan oppleve å se annonser fra oss på andre nettsteder, basert
              på innhold og sider du har besøkt på nettsidene våre.
              Informasjonskapslene som brukes for å få til det inneholder kun
              informasjon om hvilke sider du har besøkt på nettstedet vårt, og
              ikke opplysninger som kan identifisere deg som kunde. <br />
              Opplysningene vi innhenter i forbindelse med bruk av våre
              hjemmesider kan i visse tilfeller bli kombinert med informasjon
              fra ditt øvrige kundeforhold. <br />
              Benytter du nettsidene våre uten å skru av funksjonen for
              informasjonskapsler i nettleseren din, aksepterer du KUBA NORGE AS
              sin bruk av informasjonskapsler. Det er dessverre ikke teknisk
              mulig å logge inn på sidene våre hvis du ikke aksepterer at vi
              legger informasjonskapsler i nettleseren din.
            </p>

            <h4>Slik unngår du informasjonskapsler</h4>
            <p>
              Om du ikke vil tillate lagring av informasjonskapsler på din
              datamaskin, må du slå av funksjonen i nettleseren. Vær oppmerksom
              på at dette vil føre til at du mister funksjonalitet på
              www.kuba.no og andre nettsteder. <br />
              Du kan slette informasjonskapsler ved å følge instruksjonene for
              sletting av informasjonskapsler i din nettleser. <br />
              Hvis du ønsker å tillate informasjonskapsler, men samtidig følge
              med på hva vi egentlig lagrer, så finnes det tillegg til
              nettleseren din som lar deg gjøre det på en forholdsvis enkel
              måte. Et mye brukt alternativ finner du på www.ghostery.com.
              <br />
            </p>

            <h4>Personvern på www.kuba.no</h4>
            <h4>Bruk av analyseverktøy</h4>
            <p>På www.kuba.no registrerer vi følgende informasjon om deg:</p>
            <ul>
              <li>
                din posisjon, ved bruk av IP-adresse, posisjonsdata eller
                lignende
              </li>
              <li>
                dine elektroniske spor, for eksempel hvilke sider du besøker og
                hvilke produkter du bestiller
              </li>
              <li>
                teknisk informasjon om din nettleser og ditt operativsystem
              </li>
            </ul>
            <p>
              Informasjonen om din adferd på våre nettsider bruker vi til disse
              formålene:
            </p>
            <ul>
              <li>analyse</li>
              <li>personlig tilpassing av nettstedet</li>
              <li>kundeservice</li>
              <li>markedsføring</li>
            </ul>
            <p>
              Vi benytter Google Analytics til trafikkanalyse på www.Kuba Norge
              AS.no og tilhørende nettsteder. Du kan reservere deg mot Google
              Analytics fra denne siden: Google Analytics
            </p>

            <h4>Du kan bli kontaktet</h4>
            <p>
              Dersom du benytter veileder for produkt på våre hjemmesider, kan
              det hende at vi kontakter deg i salgsøyemed basert på din bruk av
              veilederen.
            </p>

            <h4>Personprofiler</h4>
            <p>
              I KUBA NORGE AS bruker vi ved noen anledninger personprofiler for
              å tilpasse våre tilbud til deg. Personprofiler er en
              sammenstilling av opplysninger vi har fått fra deg, som for
              eksempel navn, adresse, andre opplysninger du har oppgitt, hvilke
              tjenester du bruker og trafikkopplysninger. Når vi benytter oss av
              profilering i vår markedsføring, opplyser vi deg om det. <br />
              Personvernombud <br />
              KUBA NORGE AS sitt personvernombud, Lars Arne Dahl, skal blant
              annet være en hjelp for deg som kunde. Har du spørsmål om hvordan
              vi behandler dine personopplysninger, kan du kontakte
              personvernombudet på lars.arne"&#64;"kuba.no.
            </p>
            <p>
              På Datatilsynets hjemmesider kan du lese mer om hvilke krav som
              stilles for behandling av personopplysninger.
            </p>
          </div>
          <div class="action-btns-wrapper text-right">
            <button
              type="button"
              class="btn btn-outline-secondary"
              icon="pi-times"
              (click)="decline()"
            >
              <span>Avslå</span>
            </button>
            <button
              type="button"
              class="btn btn-primary"
              icon="pi-times"
              (click)="accept()"
            >
              <span>Aksepterer</span>
            </button>
          </div>
        </p-dialog>

        <div class="form-group">
          <div class="sub-panel-title">
            <span translate>EMPLOYEE_DOCUMENT</span>
          </div>
          <div class="tab-content">
            <ecpl-document-explorer
              [uploaderConfig]="upConfig"
              (saveChanges)="saveUploaded($event)"
              [uploadedFiles]="uploadedFiles"
            >
            </ecpl-document-explorer>
          </div>
        </div>
        <div class="sub-panel-title">
          <span translate>NOTES</span>
        </div>
        <div class="form-group">
          <div>
            <textarea
              id="Notes"
              class="ex-full-width"
              pInputTextarea
              formControlName="Note"
              [autoResize]="true"
            ></textarea>
          </div>
        </div>
        <button
          class="btn btn-outline-primary"
          type="submit"
          (click)="addSignature()"
        >
          <span translate>SIGN</span>
        </button>
      </div>
    </div>
    <div class="action-btns-wrapper">
      <div class="row">
        <div class="col-sm-6">
          <a [routerLink]="['../../list']" class="btn btn-outline-secondary">
            <span class="icon ic-xs icon-back"></span>
            <span translate>BACK_LIST</span>
          </a>
          <button
            class="btn btn-primary"
            [ngClass]="{ 'Formloading ': Formloading }"
            type="submit"
            *ngIf="showMyPageSaveButton && !isPortal"
            [disabled]="!employeeForm.valid || Formloading"
            (click)="saveChanges()"
          >
            <span *ngIf="!Formloading" translate>SAVE</span>
            <span *ngIf="Formloading" translate>SAVE_PROCESS</span>
            <span *ngIf="Formloading" class="loader-icon"></span>
          </button>
          <button
            class="btn btn-primary"
            (click)="saveUserAndProceed()"
            [disabled]="hideSaveUserButton"
            [disabled]="!employeeForm.valid"
            *ngIf="canViewEmployee && !isPortal"
          >
            <span translate>SAVE_ADD_USER</span>
          </button>
          <button
            class="btn btn-primary"
            (click)="EditUserPage()"
            *ngIf="
              canViewUser &&
              !isPortal &&
              !canViewEmployee &&
              !hideSaveUserButton
            "
          >
            <span translate>EDIT_USER</span>
          </button>
        </div>
        <div class="col-sm-6 text-right">
          <button class="btn btn-outline-primary" (click)="exportPdf()">
            <i class="icon ic-sm icon-print"></i>
            <span translate>PRINT_PAGE</span>
          </button>
        </div>
      </div>
    </div>
  </form>
  <!--User form-->
  <p-dialog
    header="{{ 'ADD_EDIT_USER' | translate }}"
    [(visible)]="addCategory"
    [style]="{ width: '1700px' }"
    [resizable]="false"
    [draggable]="false"
  >
    <form [formGroup]="userForm">
      <div class="row">
        <div class="col-sm-6">
          <div class="form-horizontal">
            <div class="form-group row" *ngIf="userForm.controls['Name']">
              <label class="col-sm-4" for="Name">
                <span translate>NAME</span>
                <span class="required">*</span>
              </label>
              <div class="col-sm-6">
                <input
                  type="text"
                  pInputText
                  formControlName="Name"
                  maxlength="100"
                />
                <control-messages
                  [control]="userForm.controls['Name']"
                ></control-messages>
              </div>
            </div>
            <div class="form-group row" *ngIf="userForm.controls['Username']">
              <label class="col-sm-4" for="Username">
                <span translate>USERNAME</span>
                <span class="required">*</span>
              </label>
              <div class="col-sm-6">
                <input
                  type="text"
                  (input)="isUserNameUnique($event)"
                  pInputText
                  formControlName="Username"
                  id="Username"
                  maxlength="100"
                />
                <!-- <div>
                  <a
                    routerLinkActive="active"
                    class="btn btn-link"
                    (click)="clearUserName()"
                    translate
                    >OTHER_NAME</a
                  >
                </div> -->
                <div *ngIf="invalidUserNameWithId" class="alert alert-danger">
                  <span translate>INVALID_USER_NAME</span>
                </div>
                <!-- <control-messages
                  [control]="userForm.controls['Username']"
                ></control-messages> -->
              </div>
              <!-- <button class="btn btn-primary-outline" type="button">
                <i
                  class="fa fa-refresh"
                  (click)="refreshUsername()"
                  aria-hidden="true"
                ></i>
              </button> -->
            </div>
            <div class="form-group row">
              <label class="col-sm-4" for="RoleId">
                <span translate>ADDITIONAL_ROLE</span>
              </label>
              <div class="col-sm-6">
                <p-dropdown
                  formControlName="RoleId"
                  [options]="roles"
                  [style]="{ width: '100%' }"
                  (onChange)="onRoleDropDownChanged($event)"
                ></p-dropdown>
              </div>
            </div>
            <!-- <div class="form-group row">
              <label class="col-sm-4" for="Name">
                <span translate>OWNER_ACCOUNT</span>
              </label>
              <div class="col-sm-6">
                <input type="Name" pInputText formControlName="Name" readonly />
              </div>
            </div> -->
            <div class="form-group row">
              <label class="col-sm-4" for="Mobile">
                <span translate>MOBILE</span>
              </label>
              <div class="col-sm-6 phone-field">
                <p-dropdown
                  [options]="CountryCode"
                  formControlName="CountryCode"
                  filter="true"
                  [style]="{ width: '110px' }"
                >
                </p-dropdown>
                <input
                  type="number"
                  pInputText
                  formControlName="Mobile"
                  id="mobilenum"
                  (keypress)="onNumberChange($event, 20)"
                  (input)="MobileEvent($event)"
                />
                <!-- <div translate>ADD_MOBILE_NUMBER</div> -->
              </div>
              <button class="btn btn-link" type="button" (click)="showDialog()">
                <span class="icon ic-sm icon-043"></span>
              </button>
            </div>
            <div class="form-group row" *ngIf="userForm.controls['Email']">
              <label class="col-sm-4" for="Email">
                <span translate>EMAIL</span>
                <span class="required">*</span>
              </label>
              <div class="col-sm-6">
                <input
                  type="text"
                  pInputText
                  formControlName="Email"
                  maxlength="100"
                />
                <control-messages
                  [control]="userForm.controls['Email']"
                ></control-messages>
              </div>
            </div>
            <div class="form-group row" *ngIf="isNewUser">
              <label class="col-sm-4" for="Password">
                <span translate>PASSWORD</span>
                <span class="required">*</span>
              </label>
              <div class="col-sm-6" formGroupName="passwordValidation">
                <input
                  type="password"
                  pInputText
                  formControlName="Password"
                  id="Password"
                  required
                />
                <control-messages
                  [control]="userForm.controls['Password']"
                ></control-messages>
              </div>
            </div>
            <div class="form-group row" *ngIf="isNewUser">
              <label class="col-sm-4" for="ConfirmPassword">
                <span translate>CONFIRM_PASSWORD</span>
                <span class="required">*</span>
              </label>
              <div class="col-sm-6" formGroupName="passwordValidation">
                <input
                  type="password"
                  pInputText
                  formControlName="ConfirmPassword"
                />
                <control-messages
                  [control]="userForm.controls['Password']"
                ></control-messages>
                <control-messages
                  [control]="userForm.controls['passwordValidation']"
                >
                </control-messages>
              </div>
            </div>

            <div class="form-group" *ngIf="hidePassword">
              <label>
                <span translate>YOUR_RANDOM_PASSWORD</span>:
                <span style="color: green">{{ showPassword }}</span>
              </label>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div formArrayName="userCategories" *ngIf="editedUser">
            <div class="row">
              <div class="col-sm-4">
                <span class="page-title title-equal-space" translate
                  >GENERAL_RIGHTS</span
                >
                <div *ngFor="let def of userFormGroups.controls; let i = index">
                  <div *ngIf="userRightsData[i].rightsType === 1">
                    <label>
                      <span formGroupName="{{ i }}">
                        <input
                          type="checkbox"
                          formControlName="{{ userRightsData[i].id }}"
                          (change)="
                            onRightsCheckboxChange(
                              $event,
                              userRightsData[i].id,
                              'USER'
                            )
                          "
                        />
                        {{ userRightsData[i].name | translate }}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <span class="page-title title-equal-space" translate
                  >EMPLOYEE_RIGHTS</span
                >
                <div *ngFor="let def of userFormGroups.controls; let i = index">
                  <div *ngIf="userRightsData[i].rightsType === 2">
                    <label>
                      <span formGroupName="{{ i }}">
                        <input
                          type="checkbox"
                          formControlName="{{ userRightsData[i].id }}"
                          (change)="
                            onRightsCheckboxChange(
                              $event,
                              userRightsData[i].id,
                              'USER'
                            )
                          "
                        />
                        {{ userRightsData[i].name | translate }}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <span class="page-title title-equal-space" translate
                  >DEVIATION_RIGHTS</span
                >
                <div *ngFor="let def of userFormGroups.controls; let i = index">
                  <div *ngIf="userRightsData[i].rightsType === 3">
                    <label>
                      <span formGroupName="{{ i }}">
                        <input
                          type="checkbox"
                          formControlName="{{ userRightsData[i].id }}"
                          (change)="
                            onRightsCheckboxChange(
                              $event,
                              userRightsData[i].id,
                              'USER'
                            )
                          "
                        />
                        {{ userRightsData[i].name | translate }}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div formArrayName="guestCategories" *ngIf="editedGuest">
            <div class="row">
              <div class="col-sm-4">
                <span class="page-title title-equal-space" translate
                  >GENERAL_RIGHTS</span
                >
                <div
                  class="checkbox"
                  *ngFor="let def of guestFormGroups.controls; let j = index"
                >
                  <div *ngIf="guestRightsData[j].rightsType === 1">
                    <label>
                      <span formGroupName="{{ j }}">
                        <input
                          type="checkbox"
                          formControlName="{{ guestRightsData[j].id }}"
                          (change)="
                            onRightsCheckboxChange(
                              $event,
                              guestRightsData[j].id,
                              'GUEST'
                            )
                          "
                        />
                        {{ guestRightsData[j].name | translate }}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <span class="page-title title-equal-space" translate
                  >EMPLOYEE_RIGHTS</span
                >
                <div
                  class="checkbox"
                  *ngFor="let def of guestFormGroups.controls; let j = index"
                >
                  <div *ngIf="guestRightsData[j].rightsType === 2">
                    <label>
                      <span formGroupName="{{ j }}">
                        <input
                          type="checkbox"
                          formControlName="{{ guestRightsData[j].id }}"
                          (change)="
                            onRightsCheckboxChange(
                              $event,
                              guestRightsData[j].id,
                              'GUEST'
                            )
                          "
                        />
                        {{ guestRightsData[j].name | translate }}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <span class="page-title title-equal-space" translate
                  >DEVIATION_RIGHTS</span
                >
                <div
                  class="checkbox"
                  *ngFor="let def of guestFormGroups.controls; let j = index"
                >
                  <div *ngIf="guestRightsData[j].rightsType === 3">
                    <label>
                      <span formGroupName="{{ j }}">
                        <input
                          type="checkbox"
                          formControlName="{{ guestRightsData[j].id }}"
                          (change)="
                            onRightsCheckboxChange(
                              $event,
                              guestRightsData[j].id,
                              'GUEST'
                            )
                          "
                        />
                        {{ guestRightsData[j].name | translate }}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            formArrayName="editorCategories"
            class="form-group"
            *ngIf="editedEditor"
          >
            <div class="row">
              <div class="col-sm-6">
                <span class="page-title title-equal-space" translate
                  >GENERAL_RIGHTS</span
                >
                <div
                  class="checkbox"
                  *ngFor="
                    let def of editCategoriesFormGroups.controls;
                    let k = index
                  "
                >
                  <div *ngIf="editorRightsData[k].rightsType === 1">
                    <!-- *ngFor="let def of userForm.controls['editorCategories'].controls; let k = index"> -->
                    <label>
                      <span formGroupName="{{ k }}">
                        <input
                          type="checkbox"
                          formControlName="{{ editorRightsData[k].id }}"
                          (change)="
                            onRightsCheckboxChange(
                              $event,
                              editorRightsData[k].id,
                              'EDITOR'
                            )
                          "
                        />
                        {{ editorRightsData[k].name | translate }}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <p-footer>
      <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
        <button
          class="btn btn-outline-primary"
          *ngIf="isNewUser"
          type="button"
          (click)="generateRandomPassword()"
        >
          <span translate>RANDOM_PASSWORD</span>
        </button>
        <!-- <button class="btn btn-primary" [ngClass]="{'loading' : loading}" type="button" (click)="saveUser()"
                  [disabled]="!userForm.valid || loading">
                    <span *ngIf="!loading" translate>SAVE</span>
                    <span *ngIf="loading" translate>SAVE_PROCESS</span>
                    <span *ngIf="loading" class="loader-icon"></span>
                </button> -->
        <button
          class="btn btn-primary"
          type="button"
          (click)="saveAndClose()"
          [disabled]="!userForm.valid"
        >
          <span translate>SAVE_CLOSE</span>
        </button>
        <button
          class="btn btn-primary"
          type="button"
          (click)="saveAndSendSMS()"
          [disabled]="!userForm.valid || isMobile"
        >
          <span translate>SAVE_AND_SENDSMS</span>
        </button>
      </div>
    </p-footer>
  </p-dialog>
  <p-dialog
    header="{{ 'ADD_EMPLOYEE_TYPE' | translate }}"
    [(visible)]="addType"
    [style]="{ width: '300px' }"
    [resizable]="false"
    [draggable]="false"
    (onHide)="onBeforeEmployeeTypeDialogHide()"
  >
    <form [formGroup]="employeeTypePopup">
      <div class="form-group">
        <label for="title" class="col-form-label">
          <span translate>TYPE</span>
          <span class="required">*</span>
        </label>
        <div>
          <input
            type="text"
            #title
            class="label-input form-control"
            formControlName="employeeType"
            maxlength="100"
          />
          <span style="color: red" *ngIf="isNameExists">{{
            existsAlerttext
          }}</span>
        </div>
      </div>
    </form>
    <p-footer>
      <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
        <button
          type="button"
          class="btn btn-outline-primary"
          (click)="viewType = !viewType"
        >
          <span translate>VIEW</span>
        </button>
        <button
          type="button"
          class="btn btn-primary"
          [ngClass]="{ AddTypeloading: AddTypeloading }"
          [disabled]="!employeeTypePopup.valid || AddTypeloading"
          (click)="saveType()"
        >
          <span *ngIf="!AddTypeloading" translate>SAVE</span>
          <span *ngIf="AddTypeloading" translate>SAVE_PROCESS</span>
          <span *ngIf="AddTypeloading" class="loader-icon"></span>
        </button>
      </div>
      <div *ngIf="viewType">
        <div class="table-view">
          <!-- Table starts -->
          <p-table
            #employeeTypeTable
            [value]="editTypes"
            [rows]="10"
            [paginator]="true"
            [pageLinks]="3"
            [rowsPerPageOptions]="[5, 10, 20]"
            [responsive]="true"
          >
            <!-- Header -->
            <ng-template pTemplate="header">
              <tr>
                <th>{{ "TYPE_NAME" | translate }}</th>
                <th></th>
              </tr>
              <tr>
                <th>
                  <input
                    pInputText
                    type="text"
                    (input)="
                      employeeTypeTable.filter(
                        $any($event.target)?.value,
                        'Type',
                        'contains'
                      )
                    "
                    placeholder=""
                    class="p-column-filter"
                  />
                </th>
                <th></th>
              </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-categoryType>
              <tr>
                <td>
                  <span>{{ categoryType.Type }}</span>
                </td>
                <td>
                  <button
                    type="button"
                    class="btn btn-icon"
                    title="{{ 'EDIT' | translate }}"
                    (click)="editType(categoryType)"
                  >
                    <i class="icon ic-sm icon-pencil-tip"></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-icon"
                    title="{{ 'DELETE' | translate }}"
                    (click)="deleteType(categoryType)"
                  >
                    <i class="icon ic-sm icon-trash"></i>
                  </button>
                </td>
              </tr>
            </ng-template>

            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" let-columns>
              <tr>
                <td colspan="2">
                  {{ "NO_RECORDS_FOUND" | translate }}
                </td>
              </tr>
            </ng-template>
          </p-table>
          <!-- Table ends -->
        </div>
      </div>
    </p-footer>
  </p-dialog>
  <p-dialog
    header="{{ 'ADD_POSITION' | translate }}"
    [(visible)]="addPosition"
    [style]="{ width: '300px' }"
    [resizable]="false"
    [draggable]="false"
    (onHide)="onBeforePositionDialogHide()"
  >
    <form [formGroup]="positionPopup">
      <div class="form-group">
        <label for="title" class="col-form-label">
          <span translate>NAME</span>
          <span class="required">*</span>
        </label>
        <div>
          <input
            type="text"
            #title
            class="label-input form-control"
            formControlName="position"
            maxlength="100"
          />
          <span style="color: red" *ngIf="isNameExists">{{
            existsAlerttext
          }}</span>
        </div>
      </div>
    </form>
    <p-footer>
      <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
        <button
          type="button"
          class="btn btn-outline-primary"
          (click)="viewPosition = !viewPosition"
        >
          <span translate>VIEW</span>
        </button>
        <button
          type="button"
          class="btn btn-primary"
          [ngClass]="{ AddPositionloading: AddPositionloading }"
          [disabled]="!positionPopup.valid || AddPositionloading"
          (click)="savePosition()"
        >
          <span *ngIf="!AddPositionloading" translate>SAVE</span>
          <span *ngIf="AddPositionloading" translate>SAVE_PROCESS</span>
          <span *ngIf="AddPositionloading" class="loader-icon"></span>
        </button>
      </div>
      <div *ngIf="viewPosition">
        <div class="table-view">
          <!-- Table starts -->
          <p-table
            #positionTable
            [value]="editPositions"
            [rows]="10"
            [paginator]="true"
            [pageLinks]="3"
            [rowsPerPageOptions]="[5, 10, 20]"
            [responsive]="true"
          >
            <!-- Header -->
            <ng-template pTemplate="header">
              <tr>
                <th>{{ "POSITION_NAME" | translate }}</th>
                <th></th>
              </tr>
              <tr>
                <th>
                  <input
                    pInputText
                    type="text"
                    (input)="
                      positionTable.filter(
                        $any($event.target)?.value,
                        'Name',
                        'contains'
                      )
                    "
                    placeholder=""
                    class="p-column-filter"
                  />
                </th>
                <th></th>
              </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-categoryPosition>
              <tr>
                <td>
                  <span>{{ categoryPosition.Name }}</span>
                </td>
                <td>
                  <button
                    type="button"
                    class="btn btn-icon"
                    title="{{ 'EDIT' | translate }}"
                    (click)="editPosition(categoryPosition)"
                  >
                    <i class="icon ic-sm icon-pencil-tip"></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-icon"
                    title="{{ 'DELETE' | translate }}"
                    (click)="deletePosition(categoryPosition)"
                  >
                    <i class="icon ic-sm icon-trash"></i>
                  </button>
                </td>
              </tr>
            </ng-template>

            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" let-columns>
              <tr>
                <td colspan="2">
                  {{ "NO_RECORDS_FOUND" | translate }}
                </td>
              </tr>
            </ng-template>
          </p-table>
          <!-- Table ends -->
        </div>
      </div>
    </p-footer>
  </p-dialog>
  <p-dialog
    header="{{ 'ADD_DEPARTMENT' | translate }}"
    [(visible)]="addDept"
    [style]="{ width: '300px' }"
    [resizable]="false"
    [draggable]="false"
    (onHide)="onBeforeDepartmentDialogHide()"
  >
    <form [formGroup]="departmentPopup">
      <div class="form-group">
        <label for="title" class="col-form-label">
          <span translate>NAME</span>
          <span class="required">*</span>
        </label>
        <div>
          <input
            type="text"
            #title
            class="label-input form-control"
            formControlName="department"
            maxlength="100"
          />
          <span style="color: red" *ngIf="isNameExists">{{
            existsAlerttext
          }}</span>
        </div>
      </div>
    </form>
    <p-footer>
      <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
        <button
          type="button"
          class="btn btn-outline-primary"
          (click)="viewDept = !viewDept"
        >
          <span translate>VIEW</span>
        </button>
        <button
          type="button"
          class="btn btn-primary"
          [ngClass]="{ AddDepartmentloading: AddDepartmentloading }"
          [disabled]="!departmentPopup.valid || departmentPopup['department'] == 0 || AddDepartmentloading"
          (click)="saveDept()"
        >
          <span *ngIf="!AddDepartmentloading" translate>SAVE</span>
          <span *ngIf="AddDepartmentloading" translate>SAVE_PROCESS</span>
          <span *ngIf="AddDepartmentloading" class="loader-icon"></span>
        </button>
      </div>
      <div *ngIf="viewDept">
        <div class="table-view">
          <!-- Table starts -->
          <p-table
            #departmenttable
            [value]="editDepartments"
            [rows]="10"
            [paginator]="true"
            [pageLinks]="3"
            [rowsPerPageOptions]="[5, 10, 20]"
            [responsive]="true"
          >
            <!-- Header -->
            <ng-template pTemplate="header">
              <tr>
                <th>{{ "DEPARTMENT_NAME" | translate }}</th>
                <th></th>
              </tr>
              <tr>
                <th>
                  <input
                    pInputText
                    type="text"
                    (input)="
                      departmenttable.filter(
                        $any($event.target)?.value,
                        'Name',
                        'contains'
                      )
                    "
                    placeholder=""
                    class="p-column-filter"
                  />
                </th>
                <th></th>
              </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-categoryDept>
              <tr>
                <td>
                  <span>{{ categoryDept.Name }}</span>
                </td>
                <td>
                  <button
                    type="button"
                    class="btn btn-icon"
                    title="{{ 'EDIT' | translate }}"
                    (click)="editDept(categoryDept)"
                  >
                    <i class="icon ic-sm icon-pencil-tip"></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-icon"
                    title="{{ 'DELETE' | translate }}"
                    (click)="deleteDept(categoryDept)"
                  >
                    <i class="icon ic-sm icon-trash"></i>
                  </button>
                </td>
              </tr>
            </ng-template>

            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" let-columns>
              <tr>
                <td colspan="2">
                  {{ "NO_RECORDS_FOUND" | translate }}
                </td>
              </tr>
            </ng-template>
          </p-table>
          <!-- Table ends -->
        </div>
      </div>
    </p-footer>
  </p-dialog>
  <p-dialog
    header="{{ 'ADD_SUBDEPARTMENT' | translate }}"
    [(visible)]="addSubDept"
    *ngIf="addSubDept"
    [style]="{ width: '300px' }"
    [resizable]="false"
    [draggable]="false"
    [dismissableMask]="true"
    [modal]="true"
    (onHide)="onBeforeSubDepartmentDialogHide()"
  >
    <form [formGroup]="subDepartmentPopup">
      <div class="form-group">
        <label for="selectedDept" class="col-form-label">
          <span translate>DEPARTMENT</span>
          <span class="required">*</span>
        </label>
        <div>
          <p-dropdown
            [filter]="true"
            appendTo="body"
            [options]="departments"
            [(ngModel)]="selectedSubDept"
            [style]="{ width: '100%' }"
            formControlName="BusinessDepartment"
          ></p-dropdown>
        </div>
      </div>

      <div class="form-group">
        <label for="title" class="col-form-label">
          <span translate>NAME</span>
          <span class="required">*</span>
        </label>
        <div>
          <input
            type="text"
            #title
            class="label-input form-control"
            formControlName="SubDepartment"
            maxlength="100"
          />
          <span style="color: red" *ngIf="isNameExists">{{
            existsAlerttext
          }}</span>
        </div>
      </div>
      <p-footer>
        <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
          <button
            type="button"
            class="btn btn-outline-primary"
            (click)="viewSubDept = !viewSubDept"
          >
            <span translate>VIEW</span>
          </button>
          <button
            type="button"
            class="btn btn-primary"
            [ngClass]="{ AddSubDepartmentloading: AddSubDepartmentloading }"
            [disabled]="!subDepartmentPopup.valid || AddSubDepartmentloading"
            (click)="saveSubDept()"
          >
            <span *ngIf="!AddSubDepartmentloading" translate>SAVE</span>
            <span *ngIf="AddSubDepartmentloading" translate>SAVE_PROCESS</span>
            <span *ngIf="AddSubDepartmentloading" class="loader-icon"></span>
          </button>
        </div>
        <label
        for="title"
        color
        class="control-label"
        *ngIf="isSubDepartmentInUse"
        [ngStyle]="{ color: 'red' }"
      >
        <span translate>CANNOT_DELETE</span>
      </label>
        <div *ngIf="viewSubDept">
          <div class="table-view">
            <!-- Table starts -->
            <p-table
              #subDeptTable
              [value]="editSubDepartments"
              [rows]="10"
              [paginator]="true"
              [pageLinks]="3"
              [rowsPerPageOptions]="[5, 10, 20]"
              [responsive]="true"
            >
              <!-- Header -->
              <ng-template pTemplate="header">
                <tr>
                  <th>{{ "SUB_DEPARTMENT_NAME" | translate }}</th>
                  <th></th>
                </tr>
                <tr>
                  <th>
                    <input
                      pInputText
                      type="text"
                      (input)="
                        subDeptTable.filter(
                          $any($event.target)?.value,
                          'Name',
                          'contains'
                        )
                      "
                      placeholder=""
                      class="p-column-filter"
                    />
                  </th>
                  <th></th>
                </tr>
              </ng-template>
              <!-- Body Content -->
              <ng-template pTemplate="body" let-categorySubDept>
                <tr>
                  <td>
                    <span>{{ categorySubDept.Name }}</span>
                  </td>
                  <td>
                    <button
                      type="button"
                      title="{{ 'EDIT' | translate }}"
                      class="btn btn-icon"
                      (click)="editSubDept(categorySubDept)"
                    >
                      <i class="icon ic-sm icon-pencil-tip"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-icon"
                      title="{{ 'DELETE' | translate }}"
                      (click)="deleteSubDept(categorySubDept)"
                    >
                      <i class="icon ic-sm icon-trash"></i>
                    </button>
                  </td>
                </tr>
              </ng-template>

              <!-- Empty message -->
              <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                  <td colspan="2">
                    {{ "NO_RECORDS_FOUND" | translate }}
                  </td>
                </tr>
              </ng-template>
            </p-table>
            <!-- Table ends -->
          </div>
        </div>
      </p-footer>
    </form>
  </p-dialog>
  <p-dialog
    header="{{ 'RESET_PASSWORD' | translate }}"
    (onHide)="closeResetPassword()"
    [(visible)]="showPasswordResetDialog"
    [style]="{ width: '300px' }"
    [resizable]="false"
    [draggable]="false"
  >
    <form [formGroup]="updatePasswordForm">
      <div formGroupName="passwordValidation">
        <div class="form-group">
          <label for="RepeatPassword">
            <span translate>NEW_PASSWORD</span>
            <span class="required">*</span>
          </label>
          <div>
            <input
              type="password"
              pInputText
              id="NewPassword"
              formControlName="Password"
            />
          </div>
        </div>
        <div class="form-group">
          <label for="RepeatPassword">
            <span translate>CONFIRM_PASSWORD</span>
            <span class="required">*</span>
          </label>
          <div>
            <input
              type="password"
              pInputText
              formControlName="ConfirmPassword"
              required
            />
          </div>
        </div>
        <div *ngIf="passwordValidation">
          <span class="text-danger">YOUR_PASSWORD_DONOT_MATCH</span>
        </div>
        <control-messages
          [control]="updatePasswordForm.controls['passwordValidation']"
        ></control-messages>
      </div>
      <button
        class="btn btn-outline-primary"
        [ngClass]="{ Passwordloading: Passwordloading }"
        (click)="resetPassWord()"
        [disabled]="!updatePasswordForm.valid || Passwordloading"
      >
        <span *ngIf="!Passwordloading" translate>SAVE</span>
        <span *ngIf="Passwordloading" translate>SAVE_PROCESS</span>
        <span *ngIf="Passwordloading" class="loader-icon"></span>
      </button>
    </form>
  </p-dialog>
  <toaster-component></toaster-component>
  <p-dialog
    header="{{ 'INFO' | translate }}"
    [(visible)]="dialogDisplay"
    [modal]="true"
    width="300"
  >
    <span translate>
      PLEASE_ADD_YOUR_MOBILE_NUMBER_TO_RECEIVE_USERNAME_AND_PASSWORD
    </span>
  </p-dialog>
  <p-confirmDialog
    header="{{ 'CONFIRMATION' | translate }}"
    icon="pi pi-question-circle"
    width="425"
    acceptLabel="{{ 'SWITCH_YES' | translate }}"
    rejectLabel="{{ 'SWITCH_NO' | translate }}"
  ></p-confirmDialog>
</div>
