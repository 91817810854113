<div class="tab-content">
    <div class="page-title">
        <span class="page-actions text-right">
            <a class="btn btn-success" [routerLink]="['../../new']" routerLinkActive="active">
                <span class="icon ic-sm icon-add"></span>
                <span translate>NEW</span>
            </a>
        </span>
    </div>
    <div class="table-view">
        <!-- Table starts -->
        <p-table #dt [value]="inboxData" [rows]="10" [(selection)]="selectedMessages"
            (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" exportFilename="Inbox List"
            [responsive]="true"
            [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}">
            <!-- Caption -->
            <ng-template pTemplate="caption">
                <div class="ui-helper-clearfix">
                    <div class="filter-controls-wrapper" style="display:none">
                        <div class="row">
                            <div class="col-sm-3">
                                <div class="form-group">
                                    <label for="Course" class="col-form-label" translate>STATUS
                                    </label>
                                    <div>
                                        <p-dropdown [options]="Status" styleClass="ui-column-filter" #statusDropdown
                                            (onChange)="onStatusChanged($event, statusDropdown)"
                                            styleClass="ui-column-filter"></p-dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="table-options">
                        <div class="pull-right">
                            <div>
                                <kuba-export [reportData]="dt.filteredValue || dt.value"
                                    [additionalData]="additionalData"></kuba-export>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <!-- Header -->
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 55px;">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th>{{'FROM'|translate}}</th>
                    <th>{{'SUBJECT'|translate}}</th>
                    <th>{{'MESSAGE'|translate}}</th>
                    <th>{{'ATTACHMENTS'|translate}}</th>
                    <th>{{'RECEIVED_DATE'|translate}}</th>
                </tr>
                <tr>
                    <th></th>
                    <th>
                        <input pInputText type="text"
                            (input)="dt.filter($any($event.target)?.value, 'FromuserName', 'contains')" placeholder=""
                            class="p-column-filter" >
                    </th>
                    <th>
                        <input pInputText type="text" (input)="dt.filter($any($event.target)?.value, 'Subject', 'contains')"
                            placeholder="" class="p-column-filter">
                    </th>
                    <th>
                        <input pInputText type="text" (input)="dt.filter($any($event.target)?.value, 'Message', 'contains')"
                            placeholder="" class="p-column-filter" >
                    </th>
                    <th></th>
                    <th></th>
                </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-message>
                <tr>
                    <td style="width: 55px;">
                        <p-tableCheckbox id="selectedOrders" [value]="message">
                        </p-tableCheckbox>
                    </td>
                    <td>
                        <span>{{message.FromuserName}}</span>
                    </td>
                    <td>
                        <span>
                            <a [routerLink]="['../../view',message['Id']]"
                                (click)="updateStatus(message.Id)">{{message.Subject}}</a>
                        </span>
                    </td>
                    <td>
                        <span>{{message.Message}}</span>
                    </td>
                    <td>
                        <span>{{message.FileName}}</span>
                    </td>
                    <td>
                        <span>{{message.SendDate |date : 'dd/MM/yyyy'}}</span>
                    </td>
                </tr>
            </ng-template>
            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td colspan="6">
                        {{'NO_RECORDS_FOUND'|translate}}
                    </td>
                </tr>
            </ng-template>
            <!-- Footer -->
            <ng-template pTemplate="summary">
                <div class="ui-helper-clearfix">
                    <div class="footer-data">
                        <div class="text-left">
                            <span pTooltip="{{'SELECT_TO_DELETE' | translate}}">
                                <button class="btn-link btn-danger" [disabled]="!isSelectedValue"
                                    (click)="deleteMessages()">
                                    <i class="icon ic-sm icon-trash"></i>
                                    <span translate>DELETE_CHECKED</span>
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </ng-template>
        </p-table>
        <!-- Table Ends -->
    </div>
    <toaster-component></toaster-component>
    <p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
        acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
