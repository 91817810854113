import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  Renderer2,
  OnDestroy,
  ViewChild,
  ElementRef,
  EventEmitter,
  Output,
  SimpleChanges,
  OnChanges,
  ChangeDetectorRef,
  NgZone,
} from '@angular/core';
import { LocationStrategy } from '@angular/common';
import { EcplOnlyOfficeViwerService } from './ecpl-onlyoffice-viewer.service';
import { ConfirmationService } from 'primeng/api';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

declare var DocsAPI: any;

@Component({
  selector: 'ecpl-onlyoffice-viewer',
  templateUrl: './ecpl-onlyoffice-viewer.component.html',
  styleUrls: ['./ecpl-onlyoffice-viewer.component.css'],
})
export class EcplOnlyofficeViewerComponent
  implements OnInit, AfterViewInit, OnChanges
{
  @ViewChild('officeEditor', { static: true }) private officeEditor: ElementRef;
  divElem: any;
  isCopying: boolean;
  isOverview: boolean = false;
  @Input() id: string;
  @Input() onlyofficeSrc: string;
  @Input() onlyofficeName: string;
  @Input() onlyofficeType: string;
  @Input() onlyofficeKey: string;
  @Input() onlyofficeMode: any;
  @Input() isDialog: boolean;
  @Output() close = new EventEmitter<void>();

  @Output() saveChanges: EventEmitter<any> = new EventEmitter<any>();

  editorConfig: any;
  docEditor: any;
  showModalWindow = false;
  documentType: string;
  showPreEditAction = false;
  timer = false;
  showProgress = false;
  ooEvent: any;
  isDocumentChanged: boolean;
  isLoading: boolean = false;

  constructor(
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private onlyofficeService: EcplOnlyOfficeViwerService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private ngZone: NgZone,
    location: LocationStrategy
  ) {
    location.onPopState(() => {
      this.showModalWindow = false;
      this.renderer.removeClass(document.body, 'modal-open');
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['onlyofficeSrc'] &&
      changes['onlyofficeSrc'].currentValue !==
        changes['onlyofficeSrc'].previousValue
    ) {
      // Handle the change if necessary
    }
  }

  ngOnInit() {
    this.isOverview = this.isDialog || false;
  }

  ngAfterViewInit() {
    if (this.isOverview) {
      this.showModal();
    }
  }

  showModal() {
    this._setOfficeConfig();
    this.showPreEditAction = false;
    this.showProgress = false;
    this.showModalWindow = true;
    this.renderer.addClass(document.body, 'modal-open');
    this.editorConfig.document.key =
      this.id + '-' + Math.floor(Date.now() / 1000);

    if (this.onlyofficeMode) {
      //this.showPreEditAction = true;
      this.editorConfig.editorConfig.mode = 'edit';
    } else {
      this.editorConfig.editorConfig.mode = 'view';
    }
    this.createOfficeViewFrame();
  }

  hideModal() {
    if (this.onlyofficeMode) {
      this.ooEvent = {
        eventName: 'ShowOfficeFileSaveloader',
        item: {
          id: this.id,
          path: this.onlyofficeSrc,
          filename: this.onlyofficeName,
          documentType: this.onlyofficeType,
        },
      };
      this.isLoading = true;
      this.saveChanges.emit(this.ooEvent.item);
    }

    // Destroy the editor if it exists
    if (this.docEditor) {
      this.docEditor.destroyEditor();
      this.docEditor = null;
    }

    if (this.editorConfig.editorConfig.mode === 'edit') {
      // Keep the modal open while loading
      // After 2 seconds, hide the spinner and the modal
      setTimeout(() => {
        this.isLoading = false;
        this.showModalWindow = false;
        this.renderer.removeClass(document.body, 'modal-open');

        if (this.divElem) {
          this.renderer.removeChild(
            this.officeEditor.nativeElement,
            this.divElem
          );
          this.divElem = null;
        }
      }, 3800);
    } else {
      // If not in edit mode, hide the modal immediately
      this.showModalWindow = false;
      this.renderer.removeClass(document.body, 'modal-open');

      // Remove the editor element if it exists
      if (this.divElem) {
        this.renderer.removeChild(
          this.officeEditor.nativeElement,
          this.divElem
        );
        this.divElem = null;
      }
    }
    this.close.emit(); 
  }

  editExisting() {
    if (confirm(this.translate.instant('YOU_ABOUT_TO_DEL_EXIST_FILE'))) {
      this.showPreEditAction = false;
      // Trigger a copy event
      this.ooEvent = {
        eventName: 'CopyEditVersionDocument',
        item: {
          id: this.id,
          path: this.onlyofficeSrc,
          filename: this.onlyofficeName,
          documentType: this.onlyofficeType,
        },
      };
      this.saveChanges.emit(this.ooEvent);
    } else {
      this.hideModal();
    }
  }

  editAsCopy() {
    if (
      confirm(
        'You are about to create a new copy of this file, Do you really want to proceed?'
      )
    ) {
      // Trigger a copy event
      this.ooEvent = {
        eventName: 'CopyOfficeDocument',
        item: {
          id: this.id,
          path: this.onlyofficeSrc,
          filename: this.onlyofficeName,
          documentType: this.onlyofficeType,
        },
      };
      this.saveChanges.emit(this.ooEvent);
      this.isCopying = true;
      setTimeout(() => {
        this.showPreEditAction = false;
        this.isCopying = false;
        this.createOfficeViewFrame();
      }, 5000);
    } else {
      this.hideModal();
    }
  }

  createOfficeViewFrame() {
    this.divElem = this.renderer.createElement('div');
    this.renderer.addClass(this.divElem, 'height-100');
    this.renderer.setAttribute(this.divElem, 'id', this.onlyofficeKey);
    this.renderer.appendChild(this.officeEditor.nativeElement, this.divElem);
    this.cdr.detectChanges();

    this.ngZone.runOutsideAngular(() => {
      this.docEditor = new DocsAPI.DocEditor(
        this.onlyofficeKey,
        this.editorConfig
      );
    });
  }

  onDocumentStateChange(event: any) {
    if (event.data) {
      this.ngZone.run(() => {
        this.isDocumentChanged = true;
      });
    }
  }

  _setOfficeConfig() {
    switch (this.onlyofficeType) {
      case 'csv':
      case 'fods':
      case 'ods':
      case 'xls':
      case 'xlsm':
      case 'xlsx':
      case 'xlt':
      case 'xltm':
      case 'xltx':
        this.documentType = 'spreadsheet';
        break;
      case 'doc':
      case 'docm':
      case 'docx':
      case 'dot':
      case 'dotm':
      case 'dotx':
      case 'epub':
      case 'fodt':
      case 'htm':
      case 'html':
      case 'mht':
      case 'odt':
      case 'pdf':
      case 'rtf':
      case 'txt':
      case 'djvu':
      case 'xps':
        this.documentType = 'text';
        break;
      case 'fodp':
      case 'odp':
      case 'pot':
      case 'potm':
      case 'potx':
      case 'pps':
      case 'ppsm':
      case 'ppsx':
      case 'ppt':
      case 'pptm':
      case 'pptx':
        this.documentType = 'presentation';
        break;
      default:
        this.documentType = 'text';
        break;
    }
    let mode = this.onlyofficeMode ? 'edit' : 'view';

    let userData = {
      ApplicationId: BaseServices.ApplicationId,
      FileType: this.onlyofficeType,
      Key: this.onlyofficeKey,
      Title: this.onlyofficeName,
      Url: this.onlyofficeSrc,
    };

    const additionalData = encodeURIComponent(JSON.stringify(userData));

    this.editorConfig = {
      documentType: this.documentType,
      document: {
        fileType: this.onlyofficeType,
        key: this.onlyofficeKey,
        title: this.onlyofficeName,
        url: this.onlyofficeSrc,
        permissions: {
          comment: false,
          download: true,
          edit: true,
          print: true,
          review: false,
        },
      },
      editorConfig: {
        callbackUrl: `${environment.BASE_URL}/file/doc-save?additionaldata=${additionalData}`,
        lang: 'en-US',
        mode: mode,
        customization: {
          chat: false,
          comments: false,
          compactToolbar: true,
          feedback: false,
          forcesave: false,
          goback: false,
          showReviewChanges: false,
          zoom: 120,
        },
      },
      events: {
        onDocumentStateChange: (event) => this.onDocumentStateChange(event),
        onError: (error) => {
          console.error('OnlyOffice SDK Error:', error);
        },
        onRequestClose: () => {
          this.ngZone.run(() => {
            this.hideModal();
          });
        },
      },
    };
  }
}
