import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FeaturesBaseServices } from './../../features/services/features-base.services';
import { FeatureComponent } from '../../application/components/feature.component';
import { BusinessServices } from '../services/business.services';
import { BusinessList } from '../models/';
import { ToasterComponent } from './../../../_directives/toaster.component';
import { BaseServices } from './../../kuba.services';
import { CacheHandlerService } from './../../cached.services';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, LazyLoadEvent, SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
@Component({
  selector: 'businesses-list',
  templateUrl: 'business-list.component.html',
  providers: [ConfirmationService],
})
export class BusinessListComponent implements OnInit, OnDestroy {
  //#region variables
  @ViewChild(FeatureComponent, { static: false })
  featuresComponent: FeatureComponent;
  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  @ViewChild('dt') dt: Table;
  IsAdmin: boolean;
  applicationId: number;
  businesses: BusinessList[];
  statuses: SelectItem[];
  months: SelectItem[];
  remoteWindow: Window;
  isChecked = false;
  slider: FormGroup;
  isPortal: boolean;
  additionalData: any;
  loading = false;
  hideNewButton = true;
  bArr: BusinessList[];
  totalrecords = 0;
  cols: any[];
  private subscriptions: Subscription[] = [];
  //#endregion

  //#region constructors
  /**
   * constructor
   * @param router {Router}
   * @param route {ActivatedRoute}
   * @param businessServices {BusinessServices}
   * @param confirmationService {ConfirmationService}
   * @param featuresBaseServices {FeaturesBaseServices}
   */
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private businessServices: BusinessServices,
    private confirmationService: ConfirmationService,
    public featuresBaseServices: FeaturesBaseServices,
    private _fb: FormBuilder,
    private cacheHandlerService: CacheHandlerService,
    private translate: TranslateService
  ) {
    this.subscriptions.push(
      this.translate.stream('SELECT_STATUS_DROP').subscribe((val) => {
        this.statuses = [];
        this.statuses.push(
          { label: this.translate.instant('ALL'), value: null },
          { label: val.INACTIVE, value: 2 },
          { label: val.ACTIVE, value: 1 }
        );
      })
    );

    this.subscriptions.push(
      this.translate.stream('SELECT_MONTH').subscribe((val) => {
        this.months = [];
        this.months.push(
          { label: this.translate.instant('ALL'), value: null },
          { label: val.JANUARY, value: '01' },
          { label: val.FEBRUARY, value: '02' },
          { label: val.MARCH, value: '03' },
          { label: val.APRIL, value: '04' },
          { label: val.MAY, value: '05' },
          { label: val.JUNE, value: '06' },
          { label: val.JULY, value: '07' },
          { label: val.AUGUST, value: '08' },
          { label: val.SEPTEMBER, value: '09' },
          { label: val.OCTOBER, value: '10' },
          { label: val.NOVEMBER, value: '11' },
          { label: val.DECEMBER, value: '12' }
        );
      })
    );

    this.slider = this._fb.group({
      isChecked: [''],
    });
    this.translate.stream('FILENAME').subscribe((val) => {
      this.additionalData = {
        fileName: val.BUSINESS_LIST,
        header: 'Business List',
        businessId: BaseServices.BusinessId,
        cultureInfo: BaseServices.userCultureInfo(),
        columnNames: [
          { title: 'Title', dataKey: 'CompanyName' },
          { title: 'Telephone', dataKey: 'Telephone' },
          { title: 'Email', dataKey: 'Email' },
          { title: 'Category', dataKey: 'CategoryName' },
          { title: 'Month', dataKey: 'LicenseMonth' },
          { title: 'Created On', dataKey: 'CreatedOn' },
          { title: 'Modified On', dataKey: 'ModifiedOn' },
          { title: 'Login Date', dataKey: 'LoginDate1' },
          { title: 'Login Count', dataKey: 'LoginCount' },
        ],
      };
    });
  }
  //#endregion

  //#region page-events
  /**
   * page load event
   */
  ngOnInit(): void {
    this.applicationId = BaseServices.ApplicationId;
    console.log('ApplicationId', this.applicationId);
    document.querySelector('body').classList.remove('opened');
    if (+BaseServices.roleId === 2 && BaseServices.PortalManager !== true) {
      this.hideNewButton = false;
    }
    this.businesses = this.route.snapshot.data['list'];
    this.cols = [
      { field: 'CompanyName', header: 'CompanyName' },
      { field: 'Connectedto', header: 'Connectedto' },
      { field: 'Telephone', header: 'Telephone' },
      { field: 'CategoryName', header: 'CategoryName' },
      { field: 'LicenseMonth', header: 'Month' },
      { field: 'EmployeeCount', header: 'Employees' },
      { field: 'CreatedOn', header: 'CreatedOn' },
      { field: 'ModifiedOn', header: 'ModifiedOn' },
      { field: 'LoginDate', header: 'LoginDate' },
      { field: 'LoginCount', header: 'LoginCount' },
    ];
    this.totalrecords = this.businesses ? this.businesses.length : 0;
    this.isPortal = BaseServices.roleId === '2' ? true : false;
    // To hide side menu bar
    document.querySelector('body').classList.remove('opened');
  }

  /**
   * page destroy event
   */
  ngOnDestroy() {
    document.querySelector('body').classList.add('opened');
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
  //#endregion

  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }
  //#region methods
  /**
   * create new business
   */
  createNewBusiness() {
    this.router.navigate(['./wizard']);
  }

  /**
   * delete event
   * @param id {number}
   * @param data {any}
   */
  confirm(id: number, data: any) {
    this.confirmationService.confirm({
      message: this.translate.instant('DELETE_THIS_RECORD'),
      accept: () => {
        this.loading = true;
        this.subscriptions.push(
          this.businessServices.deleteById(id, data).subscribe(() => {
            this.toasterComponent.callToastDlt();
            this.businessServices.clearCachedBusinessService();
            this.fetchData();
          })
        );
      },
    });
  }

  /**
   * status event
   * @param id {number}
   * @param status {any}
   */
  editStatus(id: number, Status: string) {
    const newStatus = Number(Status) === 1 ? '2' : '1';

    const confirmationMessage =
      newStatus === '1'
        ? this.translate.instant('EDIT_ACTIVE_THIS_RECORD')
        : this.translate.instant('EDIT_INACTIVE_THIS_RECORD');

    this.confirmationService.confirm({
      message: confirmationMessage,
      accept: () => {
        this.subscriptions.push(
          this.businessServices.statusUpdate(id, newStatus).subscribe(() => {
            this.businessServices.clearCachedBusinessService();
            this.fetchData();
          })
        );
      },
    });
  }


  /**
   * Redirects to the CRM using the organization's number.
   *
   * This method constructs a URL using the organization's number
   * and the base CRM URL from the environment configuration.
   * It then opens this URL in a new tab.
   *
   * @param business - The business object containing organization number.
   */
  cMode(business: any) {
    let baseCRMURL = environment.BASE_CRM_URL;
    const orgNumber = business.OrganizationNumber;
    const routeURL = `${baseCRMURL}ik-redirector/${orgNumber}`;
    // Use the ClientNumber to route to the CRM
    window.open(routeURL, '_blank');
  }
  /**
   * R mode chage
   * @param event {any}
   * @param business {any}
   */
  rModeCheckFeature(business: any) {
    if (this.remoteWindow) {
      this.remoteWindow.close();
    }
    this.cacheHandlerService.clearCachedData();
    let businessSetting = {
      BusinessName: business.CompanyName,
      BusinessLogo: business.BusinessLogo,
    };
    sessionStorage.setItem('RModeBusiness', JSON.stringify(businessSetting));
    sessionStorage.setItem('RModeBusinessId', business.Id);
    let windowOrigin = window.location.origin;
    let windowControl =
      'location=yes,width=' +
      screen.availWidth +
      ', height = ' +
      screen.availHeight +
      ', scrollbars = yes, status = yes';
    let baseURL = BaseServices.userBase.BaseUrl;
    this.remoteWindow = window.open(
      windowOrigin +
        '/' +
        baseURL +
        '/app/business/' +
        business.Id +
        '/dashboard',
      'Remote',
      windowControl
    )!;
  }

  /**
   * refresh list
   */
  fetchData() {
    this.subscriptions.push(
      this.businessServices.list().subscribe((businessList) => {
        if (businessList) {
          this.loading = false;
          this.businesses = businessList;
        }
      })
    );
  }
  loadBusinessLazy(event: LazyLoadEvent) {
    setTimeout(() => {
      if (this.businesses) {
        this.bArr = this.businesses.slice(
          event.first,
          event.first! + event.rows!
        );
      }
    }, 1000);
  }

  //#endregion
}
