<a
 *ngIf="!isOverview"
  href="javascript:void(0)"
  (click)="showModal()"
  id="onlyoffice-file-{{ id }}"
>
  <img src="/assets/icons/{{ onlyofficeType }}.png" alt="Office File" />
</a>

<div
  class="onlyoffice-modal"
  [hidden]="!showModalWindow"
  id="onlyoffice-pop-{{ id }}"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4>
          <span class="ic-md">
            <img
              src="/assets/icons/{{ onlyofficeType }}.png"
              alt="Office File"
              class="onlyoffice-icon-header"
            />
          </span>
          <span>{{ onlyofficeName }}</span>
        </h4>
        <button (click)="hideModal()" class="close">
          <span class="icon ic-md icon-multiply"></span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="isLoading" class="loading-spinner">
          <p-progressSpinner
            styleClass="w-4rem h-4rem"
            animationDuration=".5s"
          ></p-progressSpinner>
        </div>
        <div *ngIf="showPreEditAction" id="PreEditAction">
          <div class="action-btn-group">
            <button
              class="btn btn-outline-secondary"
              (click)="editExisting()"
              translate
            >
              EDIT
            </button>
          </div>
          <p *ngIf="isCopying" translate>FILE_COPIED</p>
        </div>
        <div class="file-preview-wrapper">
          <div #officeEditor class="file-preview-box"></div>
        </div>
        <div *ngIf="showProgress" translate>FILE_BEING_SAVED ...</div>
      </div>
    </div>
  </div>
</div>
<p-confirmDialog
  header="{{ 'CONFIRMATION' | translate }}"
  icon="pi pi-question-circle"
  width="425"
  acceptLabel="{{ 'SWITCH_YES' | translate }}"
  rejectLabel="{{ 'SWITCH_NO' | translate }}"
></p-confirmDialog>
