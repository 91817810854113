<div class="page-title">
  <span translate>LIST_OF_OBJECTS</span>

  <span class="page-actions text-right">
    <button
      type="button"
      class="btn btn-primary"
      *ngIf="manualobjects.length > 0"
      (click)="completeChecklist()"
      translate
    >
      COMPLETE_MANUAL_CONTROL
    </button>
    <button
      type="button"
      class="btn btn-warning"
      (click)="showDialogToAdd()"
      translate
    >
      MANAGE_MANUAL_CONTROL
    </button>
    <a [routerLink]="['./../add']" class="btn btn-success">
      <span class="icon ic-sm icon-add"></span>
      <span translate>NEW</span>
    </a>
  </span>
</div>

<div class="table-view mbtm-30">
  <!-- Table starts -->
  <p-table
    #dt
    [value]="manualobjects"
    [rows]="10"
    [paginator]="true"
    [pageLinks]="3"
    [rowsPerPageOptions]="[5, 10, 20]"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="{{ 'SUMMARY_TOTALRECORDS' | translate }}"
  >
    <!-- Caption -->
    <ng-template pTemplate="caption">
      <div class="table-options">
        <div class="pull-right">
          <div class="action-btns-group">
            <kuba-export
              [reportData]="dt.filteredValue || dt.value"
              [additionalData]="additionalData"
            >
            </kuba-export>
          </div>
        </div>
      </div>
    </ng-template>
    <!-- Header -->
    <ng-template pTemplate="header">
      <tr>
        <th style="width:100px;"><span translate>ORDER NUMBER</span></th>
        <th pSortableColumn="ObjectName" width="165px">
          {{ "OBJECT_NAME" | translate }}
          <p-sortIcon field="ObjectName"></p-sortIcon>
        </th>
        <th pSortableColumn="MinTemp" width="110px">
          {{ "TEMP_MIN" | translate }} <p-sortIcon field="MinTemp"></p-sortIcon>
        </th>
        <th pSortableColumn="MaxTemp" width="110px">
          {{ "TEMP_MAX" | translate }} <p-sortIcon field="MaxTemp"></p-sortIcon>
        </th>
        <th pSortableColumn="Description">
          {{ "DESCRIPTION" | translate }}
          <p-sortIcon field="Description"></p-sortIcon>
        </th>
        <th width="85px">{{ "OPTIONS" | translate }}</th>
      </tr>
      <tr>
        <th>
          <button
          *ngIf="isChanged"
          type="button"
          class="btn btn-primary"
          icon="pi-times"
          (click)="saveOrderNumber(manualobjects)"
        >
        <span translate>SAVE</span>
      </button>
        </th>
        <th>
          <input
            pInputText
            type="text"
            (input)="dt.filter($event.target.value, 'ObjectName', 'contains')"
            placeholder=""
            class="p-column-filter"
          />
        </th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
      </tr>
    </ng-template>
    <!-- Body Content -->
    <ng-template pTemplate="body" let-mc let-i="rowIndex">
      <tr>
        <td>
            <input
            pInputText
            type="number"
            [(ngModel)]="mc.OrderNumber"
            (change)="setOrderNumber(mc.OrderNumber, manualobjects, i)"
            class="p-column-filter"
          />
        </td>
        <td>
          <span>{{ mc.ObjectName }}</span>
        </td>
        <td>
          <span>{{ mc.MinTemp }}</span>
        </td>
        <td>
          <span>{{ mc.MaxTemp }}</span>
        </td>
        <td>
          <div pTooltip="{{ mc.Description }}" tooltipPosition="bottom">
            {{ mc.Description }}
          </div>
        </td>
        <td class="col-button">
          <button
            type="button"
            class="btn btn-icon"
            title="{{ 'EDIT' | translate }}"
            (click)="editFoodControl(mc['Id'])"
          >
            <i class="icon ic-sm icon-pencil-tip"></i>
          </button>
          <button
            type="button"
            class="btn btn-icon"
            title="{{ 'DELETE' | translate }}"
            (click)="deleteFoodControl(mc['Id'])"
          >
            <i class="icon ic-sm icon-trash"></i>
          </button>
        </td>
      </tr>
    </ng-template>
    <!-- Empty message -->
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td colspan="5">
          {{ "NO_RECORDS_FOUND" | translate }}
        </td>
      </tr>
    </ng-template>
  </p-table>
  <!-- Table Ends-->
</div>
<toaster-component></toaster-component>
<p-confirmDialog
  header="{{ 'CONFIRMATION' | translate }}"
  icon="pi pi-question-circle"
  width="425"
  acceptLabel="{{ 'SWITCH_YES' | translate }}"
  rejectLabel="{{ 'SWITCH_NO' | translate }}"
></p-confirmDialog>

<p-dialog
  header="{{ 'MANAGE_MANUAL_CONTROL' | translate }}"
  [(visible)]="displayDialog"
  [style]="{ width: '300px', height: '280px' }"
  [resizable]="false"
  [draggable]="false"
  showEffect="fade"
  [modal]="true"
  [contentStyle]="{ overflow: 'visible' }"
>
  <form [formGroup]="manageTempControlForm">
    <div class="form">
      <div class="form-group">
        <label for="Repetition">
          <span translate>REPETITION</span>
        </label>
        <div>
          <p-multiSelect
            [options]="weekDays"
            selectedItemsLabel="{0} Valgt"
            defaultLabel="{{ 'CHOOSE' | translate }}"
            formControlName="Repetition"
            [style]="{ width: '200px' }"
          >
          </p-multiSelect>
        </div>
      </div>
      <div class="form-group">
        <label for="exeandfollwusers">
          <span translate>EMAIL_NOTIFICATION_PAST_DEADLINE</span>
        </label>
        <div>
          <p-dropdown
            [options]="executionAndFollowupusers"
            [style]="{ width: '200px' }"
            formControlName="EmailNotificationpastDeadline"
          >
          </p-dropdown>
        </div>
      </div>
    </div>
  </form>
  <div class="action-btns-wrapper">
    <button
      type="submit"
      class="btn btn-primary"
      icon="pi-check"
      label="Save"
      (click)="onSubmitForm()"
      [disabled]="manageTempControlForm.invalid"
    >
      <span translate>SAVE</span>
    </button>
    <button
      type="button"
      class="btn btn-outline-secondary"
      icon="pi-times"
      (click)="cancel()"
    >
      <span translate>CANCEL</span>
    </button>
  </div>
</p-dialog>
