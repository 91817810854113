import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import { Zone } from './../models/zone';
import { Department } from './../../deviation/models/deviationDepartment';
import { FoodSafety } from './../models/foodsafety';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';

import { BaseServices } from './../../kuba.services';
import { RegistrationType } from './../../Registration/models/registration-type';
import { FoodSafetyManualCompleteChecklist } from '../../shared/models/complete-checklist';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from 'src/app/_services';

@Injectable()
export class FoodSafetyServices {
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) {}

  getAllByBusinessId(bid: number) {
    return this.http
      .get(environment.BASE_URL + '/foodsafety/food-safetys/' + bid)
      .map((result: any) => result);
  }

  getFoodsafetyById(id: number) {
    return this.http
      .get(environment.BASE_URL + '/foodsafety/food-safety/' + id)
      .map((result: any) => result);
  }
  getZoneName(businessId: number) {
    return this.http
      .get(environment.BASE_URL + '/foodsafety/zones/' + businessId)
      .map((result: any) => result);
  }
  // add  the department
  addDepartment(data) {
    let result: Department;
    return this.http
      .post(environment.BASE_URL + '/department', data)
      .map(result => result);
  }
  // update  the department
  updateDepartment(department: Department) {
    let result: Department;
    return this.http
      .put(environment.BASE_URL + '/department/' + department.Id, department)
      .map(result => result);
  }
  // delete  the department
  deleteDepartment(Id: number) {
    let result: boolean;
    return this.http.delete(environment.BASE_URL + '/departmentdelete/' + Id);
  }

  getZone(businessId: number) {
    return this.http
      .get(environment.BASE_URL + '/foodsafety/zones/' + businessId)
      .map(result => result);
  }
  addZone(zone) {
    let result: Zone;
    return this.http
      .post(environment.BASE_URL + '/foodsafety/zone', zone)
      .map(result => result);
  }
  updateZone(zone) {
    let result: Zone;
    return this.http
      .put(environment.BASE_URL + '/foodsafety/Zone/' + zone.Id, zone)
      .map(result => result);
  }
  checkAllocatedDepartment(departmentID: number) {
    return this.http
      .get(
        environment.BASE_URL + '/checkdepartmentforfoodsafety/' + departmentID
      )
      .map(result => result);
  }
  checkAllocatedZone(departmentID: number) {
    return this.http
      .get(
        environment.BASE_URL +
          '/foodsafety/checkzoneforfoodsafety/' +
          departmentID
      )
      .map(result => result);
  }
  deleteZone(Id: number) {
    let result: boolean;
    return this.http.delete(environment.BASE_URL + '/foodsafety/Zone/' + Id);
  }

  createFoodsafety(foodSafety: FoodSafety) {
    return this.http
      .post(environment.BASE_URL + '/foodsafety/food-safety', foodSafety)
      .map(result => result);
  }

  updateFoodsafety(foodSafety: FoodSafety) {
    return this.http
      .put(environment.BASE_URL + '/foodsafety/food-safety/', foodSafety)
      .map(result => result);
  }

  deleteFoodSafety(id: number) {
    let result: boolean;
    return this.http.delete(
      environment.BASE_URL + '/foodsafety/food-safety/' + id
    );
  }

  getFoodsafetyFeatures(businessId: number) {
    let featureId: number;
    let workingModule = JSON.parse(sessionStorage.getItem('workingModule'));
    featureId = workingModule.id;
    return this.http
      .get(environment.BASE_URL + '/business/' + businessId)
      .map((result: any) => {
        let feature = result.Features;
        let foodsafetyAdditionalFeature = _.find(JSON.parse(feature), {
          id: featureId,
          access: true
        });
        return foodsafetyAdditionalFeature;
      });
  }
  createCompleteChecklist(
    FoodSafetyManualCompleteChecklist: FoodSafetyManualCompleteChecklist
  ) {
    return this.http
      .post(
        environment.BASE_URL + '/foodsafety/CompleteAllChecklist',
        FoodSafetyManualCompleteChecklist
      )
      .map((response: any) => {
        return response;
      });
  }
  // update registration Type
  updateRegType(existingCategory: RegistrationType, key: number) {
    return this.http
      .put(
        `${environment.BASE_URL}/foodsafety/registrationtype`,
        existingCategory
      )
      .map(result => result);
  }

  // adding registration Type
  createRegType(newCategory: RegistrationType, key: number) {
    return this.http
      .post(`${environment.BASE_URL}/foodsafety/registrationtype`, newCategory)
      .map(result => result);
  }

  getFoodByUser(Id: number) {
    return this.http
      .get(environment.BASE_URL + '/foodsafety/getuserFS/' + Id)
      .map((result: any) => result);
  }

  getZonesbyBusiness() {
    return this.http
      .get(
        environment.BASE_URL +
          '/foodsafety/getzonesbybusiness/' +
          BaseServices.BusinessId
      )
      .map((result: any) => result);
  }

  getFSDailyTaskList(fsId: number) {
    return this.http
      .get(environment.BASE_URL + '/foodsafety/dailytask/list/' + fsId)
      .map((result: any) => result);
  }

  foodSafetyAuditList(featureKey: number, minDate, maxDate, isShiftRight) {
    return this.http
      .get(
        environment.BASE_URL +
          `/foodsafety/foodsafetyauditlist/${featureKey}/${minDate}/${maxDate}/${isShiftRight}`
      )
      .map((result: any) => result);
  }

  saveHazardSectionsAndSubSections(hazardAnalysis) {
    return this.http
      .post(
        environment.BASE_URL + '/foodsafety/hazardAnalysis/save',
        hazardAnalysis
      )
      .map(result => result);
  }

  getHazardAnalysis(foodSafetyId: number, featureId: number) {
    return this.http
      .get(
        environment.BASE_URL +
          `/foodsafety/gethazardAnalysis/${foodSafetyId}/${featureId}`
      )
      .map((result: any) => result);
  }

  deleteHazardSection(Id: number) {
    return this.http
      .delete(environment.BASE_URL + '/foodsafety/deletehazardsection/' + Id)
      .map(result => result);
  }

  deleteHazardSubSection(Id: number) {
    return this.http
      .delete(environment.BASE_URL + '/foodsafety/deletehazardsubsection/' + Id)
      .map(result => result);
  }

  foodSafetyAuditDateList(featureKey: number) {
    return this.http
      .get(environment.BASE_URL + '/foodsafety/getdatelist/' + featureKey)
      .map((result: any) => result);
  }

  exportFSHazardAnalysis(
    appId: number,
    bId: number,
    FSId: number,
    CulInfo,
    FeatureId: number
  ) {
    let dynamicType = 'application/pdf';
    let url = `${environment.BASE_URL}/foodsafety/exportHazardAnalysis/${appId}/${bId}/${FSId}/${CulInfo}/${FeatureId}`;
    let crfToken = this.authenticationService.getCsrfToken();
    return Observable.create(observer => {
      let xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.withCredentials = true;
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('Logo-Id', 'ab064fd4-9b4a-4e70-972d-dafdaceb2aba');
      xhr.setRequestHeader('X-XSRF-TOKEN', crfToken);
      xhr.responseType = 'blob';
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            let contentType = dynamicType;
            let blob = new Blob([xhr.response], { type: contentType });
            observer.next(blob);
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };
      xhr.send();
    });
  }

  exportFSAudit(
    cultureInfo: string,
    featureId: number,
    BusinessId,
    UserId,
    auditData,
    zoneId: number,
    appId: number,
    departmentName
  ) {
    let dynamicType = 'application/pdf';
    let myData = JSON.stringify({ data: auditData });
    let url = `${environment.BASE_URL}/export-FSAudit/${cultureInfo}/${featureId}/${BusinessId}/${UserId}/${zoneId}/${appId}/${departmentName}`;
    let crfToken = this.authenticationService.getCsrfToken();
    return Observable.create(observer => {
      let xhr = new XMLHttpRequest();
      xhr.open('POST', url, true);
      xhr.withCredentials = true;
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('Logo-Id', 'ab064fd4-9b4a-4e70-972d-dafdaceb2aba');
      xhr.setRequestHeader('X-XSRF-TOKEN', crfToken);
      xhr.responseType = 'blob';
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            let contentType = dynamicType;
            let blob = new Blob([xhr.response], { type: contentType });
            observer.next(blob);
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };
      xhr.send(myData);
    });
  }

  getFSZoneName(fsId: number) {
    return this.http
      .get(environment.BASE_URL + '/foodsafety/getzonename/' + fsId)
      .map(result => result);
  }

  getCompleteTempControlPDF(
    featureId: number,
    featureKey: number,
    tempControlChecklistId: number,
    completeChecklistId: number
  ) {
    return this.http
      .get(
        environment.BASE_URL +
          '/foodsafety/getfstempcontrolpdf/' +
          featureId +
          '/' +
          featureKey +
          '/' +
          tempControlChecklistId +
          '/' +
          completeChecklistId
      )
      .map(result => result);
  }

  exportFSAuditForTempControl(
    cultureInfo: string,
    featureId: number,
    BusinessId,
    UserId,
    ChecklistId,
    CompleteChecklistId,
    dataList
  ) {
    let dynamicType = 'application/pdf';
    let myData = JSON.stringify({ data: dataList });
    let url = `${environment.BASE_URL}/export-FSAuditTempConrtol/${cultureInfo}/${featureId}/${BusinessId}/${UserId}/${ChecklistId}/${CompleteChecklistId}`;
    return Observable.create(observer => {
      let xhr = new XMLHttpRequest();
      xhr.open('POST', url, true);
      xhr.withCredentials = true;
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.responseType = 'blob';
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            let contentType = dynamicType;
            let blob = new Blob([xhr.response], { type: contentType });
            observer.next(blob);
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };
      xhr.send(myData);
    });
  }

  exportFSAuditForDailyTask(activityId: number, cultureInfo: string) {
    let dynamicType = 'application/pdf';
    let myData = JSON.stringify({ data: activityId });
    let url = `${environment.BASE_URL}/export-FSAuditDailyTask/${activityId}/${cultureInfo}`;
    return Observable.create(observer => {
      let xhr = new XMLHttpRequest();
      xhr.open('POST', url, true);
      xhr.withCredentials = true;
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.responseType = 'blob';
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            let contentType = dynamicType;
            let blob = new Blob([xhr.response], { type: contentType });
            observer.next(blob);
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };
      xhr.send(myData);
    });
  }

  deleteHazardForm(foodSafetyId: number, featureId: number) {
    return this.http
      .delete(
        environment.BASE_URL +
          `/foodsafety/deletehazardform/${foodSafetyId}/${featureId}` +
          foodSafetyId
      )
      .map(result => result);
  }

  saveReasonForLateTaskCompletion(data) {
    return this.http
      .post(environment.BASE_URL + '/foodsafety/saveReason', data)
      .map(result => result);
  }

  getAuditInformation(Id: number, Type: string) {
    return this.http
      .get(
        `${environment.BASE_URL}/foodsafety/foodsafetyauditDetails/${Id}/${Type}`
      )
      .map(result => result);
  }

  getCoolingBatches(foodsafetyId: number) {
    return this.http
      .get(
        `${environment.BASE_URL}/foodsafety/getcoolingbatches/${BaseServices.BusinessId}/${foodsafetyId}`
      )
      .map(result => result);
  }

  getCoolingProducts() {
    return this.http
      .get(
        `${environment.BASE_URL}/foodsafety/getcoolingproducts/${BaseServices.BusinessId}`
      )
      .map(result => result);
  }

  getCoolingProduct(Id: number) {
    return this.http
      .get(`${environment.BASE_URL}/foodsafety/getcoolingproduct/${Id}`)
      .map(result => result);
  }

  getCoolingTemperatures() {
    return this.http
      .get(`${environment.BASE_URL}/foodsafety/getcoolingtemperatures`)
      .map(result => result);
  }

  getCoolingMeasurements(batchId: number) {
    return this.http
      .get(
        `${environment.BASE_URL}/foodsafety/getcoolingmeasurements/${batchId}`
      )
      .map(result => result);
  }

  createCoolingProduct(data) {
    return this.http
      .post(environment.BASE_URL + '/foodsafety/createcoolingproduct', data)
      .map(result => result);
  }

  createCoolingBatch(data) {
    return this.http
      .post(environment.BASE_URL + '/foodsafety/createcoolingbatch', data)
      .map(result => result);
  }

  createCoolingMeasurement(data) {
    return this.http
      .post(`${environment.BASE_URL}/foodsafety/createcoolingmeasurement`, data)
      .map(result => result);
  }

  updateCoolingProduct(data) {
    return this.http
      .put(environment.BASE_URL + '/foodsafety/updatecoolingproduct', data)
      .map(result => result);
  }

  getCoolingBatch(batchId: number) {
    return this.http
      .get(environment.BASE_URL + '/foodsafety/getcoolingbatch/' + batchId)
      .map(result => result);
  }

  updateCoolingBatch(data) {
    return this.http
      .put(`${environment.BASE_URL}/foodsafety/updatecoolingbatch`, data)
      .map(result => result);
  }

  updateCoolingMeasurement(data) {
    return this.http
      .put(`${environment.BASE_URL}/foodsafety/updatecoolingmeasurement`, data)
      .map(result => result);
  }
  deleteCoolingBatch(Id: number) {
    return this.http
      .delete(
        environment.BASE_URL +
          '/foodsafety/deletecoolingbatch/' +
          Id +
          '/' +
          BaseServices.UserId
      )
      .map(result => result);
  }

  deleteCoolingMeasurement(Id: number) {
    return this.http
      .delete(
        environment.BASE_URL +
          '/foodsafety/deletecoolingmeasurement/' +
          Id +
          '/' +
          BaseServices.UserId
      )
      .map(result => result);
  }

  deleteCoolingProduct(Id: number) {
    return this.http
      .delete(
        environment.BASE_URL +
          '/foodsafety/deletecoolingproduct/' +
          Id +
          '/' +
          BaseServices.UserId
      )
      .map(result => result);
  }

  checkProductAllocate(Id: number) {
    return this.http
      .get(environment.BASE_URL + '/foodsafety/checkproductcooling/' + Id)
      .map(result => result);
  }

  reportGenerate(batchIds, foodsafetyId: number, cultureInfo: string) {
    let dynamicType = 'application/pdf';
    let myData = JSON.stringify({ data: batchIds });
    let url = `${environment.BASE_URL}/export-FSCoolingBacthlist/${BaseServices.BusinessId}/${foodsafetyId}/${BaseServices.UserId}/${cultureInfo}`;
    let crfToken = this.authenticationService.getCsrfToken();
    return Observable.create(observer => {
      let xhr = new XMLHttpRequest();
      xhr.open('POST', url, true);
      xhr.withCredentials = true;
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('Logo-Id', 'ab064fd4-9b4a-4e70-972d-dafdaceb2aba');
      xhr.setRequestHeader('X-XSRF-TOKEN', crfToken);
      xhr.responseType = 'blob';
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            let contentType = dynamicType;
            let blob = new Blob([xhr.response], { type: contentType });
            observer.next(blob);
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };
      xhr.send(myData);
    });
  }
}
