<form [formGroup]="businessUserForm">
  <div class="form">
    <div class="row">
      <div class="col-sm-5">
        <div class="form-group" *ngIf="businessUserForm.controls['Name']">
          <label for="Name">
            <span translate>NAME</span>
            <span class="required">*</span>
          </label>
          <div>
            <input
              type="text"
              pInputText
              formControlName="Name"
              id="Name"
              required
            />
          </div>
          <control-messages
            [control]="businessUserForm.controls['Name']"
          ></control-messages>
        </div>
        <div class="form-group" *ngIf="businessUserForm.controls['Username']">
          <label for="Username">
            <span translate>USERNAME</span>
            <span class="required">*</span>
          </label>
          <div>
            <input
              type="text"
              pInputText
              formControlName="Username"
              id="Username"
              required
            />
          </div>
          <!-- <div>
            <a
              routerLinkActive="active"
              class="btn btn-link"
              (click)="clearUserName()"
              translate
              >OTHER_NAME</a
            >
          </div> -->
          <control-messages
            [control]="businessUserForm.controls['Username']"
          ></control-messages>
        </div>
        <div class="form-group" *ngIf="businessUserForm.controls['RoleId']">
          <label for="RoleId">
            <span translate>ADDITIONAL_ROLE</span>
          </label>
          <div>
            <p-dropdown
              formControlName="RoleId"
              id="RoleId"
              [disabled]="isUser"
              [options]="Roles"
              [style]="{ width: '100%' }"
              (onChange)="onRoleDropDownChanged($event)"
            >
              <ng-template let-role pTemplate="item">
                {{ role.label }}
              </ng-template>
            </p-dropdown>
          </div>
        </div>
        <div class="form-group">
          <label for="Mobile">
            <span translate>MOBILE</span>
          </label>
          <div class="phone-field">
            <p-dropdown
              [options]="mobileCodes"
              formControlName="countryCode"
              filter="true"
              [style]="{ width: '110px' }"
            ></p-dropdown>
            <input
              style="width: 67%"
              type="text"
              onpaste="return false;"
              pInputText
              formControlName="Mobile"
              (keypress)="onMobileNumber($event, 20)"
              id="mobilenum"
              (input)="MobileEvent($event)"
            />
            <button class="btn btn-link" type="button" (click)="showDialog()">
              <span class="icon ic-sm icon-043"></span>
            </button>
          </div>
        </div>
        <div class="form-group" *ngIf="businessUserForm.controls['Email']">
          <label for="Email">
            <span translate>EMAIL</span>
            <span class="required">*</span>
          </label>
          <div>
            <input
              type="text"
              pInputText
              formControlName="Email"
              id="Email"
              required
            />
          </div>
          <control-messages
            [control]="businessUserForm.controls['Email']"
          ></control-messages>
        </div>
        <div formGroupName="newPasswordValidation">
          <div class="form-group" *ngIf="isNewUser">
            <label for="Password">
              <span translate>PASSWORD</span>
              <span class="required">*</span>
            </label>
            <div>
              <input
                type="password"
                pInputText
                formControlName="Password"
                id="Password"
                required
              />
            </div>
            <control-messages
              [control]="businessUserForm.controls['Password']"
            ></control-messages>
          </div>
          <div class="form-group" *ngIf="isNewUser">
            <label for="RepeatPassword">
              <span translate>REPEAT_PASSWORD</span>
              <span class="required">*</span>
            </label>
            <div>
              <input
                type="password"
                pInputText
                id="ConfirmPassword"
                formControlName="ConfirmPassword"
              />
            </div>
          </div>
          <control-messages
            [control]="businessUserForm.controls['ConfirmPassword']"
          ></control-messages>
        </div>
        <div *ngIf="hidden">
          <label>
            <span translate>YOUR_RANDOM_PASSWORD</span>:
            <span style="color: green">{{ showPassword }}</span>
          </label>
        </div>
      </div>

      <div class="col-sm-7" *ngIf="isRightSelect">
        <div formArrayName="userCategories" *ngIf="editedUser">
          <div class="row">
            <div class="col-sm-4">
              <span class="page-title title-equal-space" translate
                >GENERAL_RIGHTS</span
              >
              <div
                *ngFor="
                  let def of businessUserForm.controls['userCategories'][
                    'controls'
                  ];
                  let i = index
                "
              >
                <div
                  class="checkbox"
                  *ngIf="
                    userRightsData[i].rightsType === 1 && applicationId != 7
                  "
                >
                  <label>
                    <span formGroupName="{{ i }}">
                      <input
                        type="checkbox"
                        formControlName="{{ userRightsData[i].id }}"
                        (change)="
                          onRightsCheckboxChange(
                            $event,
                            userRightsData[i].id,
                            'USER'
                          )
                        "
                      />
                      {{ userRightsData[i].name | translate }}
                    </span>
                  </label>
                </div>
                <div
                  class="checkbox"
                  *ngIf="
                    userRightsData[i].rightsType === 1 &&
                    applicationId == 7 &&
                    userRightsData[i].id != 6 &&
                    userRightsData[i].id != 7
                  "
                >
                  <label>
                    <span formGroupName="{{ i }}">
                      <input
                        type="checkbox"
                        formControlName="{{ userRightsData[i].id }}"
                        (change)="
                          onRightsCheckboxChange(
                            $event,
                            userRightsData[i].id,
                            'USER'
                          )
                        "
                      />
                      {{ userRightsData[i].name | translate }}
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <span class="page-title title-equal-space" translate=""
                >EMPLOYEE_RIGHTS</span
              >
              <div
                *ngFor="
                  let def of businessUserForm.controls['userCategories'][
                    'controls'
                  ];
                  let i = index
                "
              >
                <div
                  class="checkbox"
                  *ngIf="userRightsData[i].rightsType === 2"
                >
                  <label>
                    <span formGroupName="{{ i }}">
                      <input
                        type="checkbox"
                        formControlName="{{ userRightsData[i].id }}"
                        (change)="
                          onRightsCheckboxChange(
                            $event,
                            userRightsData[i].id,
                            'USER'
                          )
                        "
                      />
                      {{ userRightsData[i].name | translate }}
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <span class="page-title title-equal-space" translate
                >DEVIATION_RIGHTS</span
              >
              <div
                *ngFor="
                  let def of businessUserForm.controls['userCategories'][
                    'controls'
                  ];
                  let i = index
                "
              >
                <div
                  class="checkbox"
                  *ngIf="userRightsData[i].rightsType === 3"
                >
                  <label>
                    <span formGroupName="{{ i }}">
                      <input
                        type="checkbox"
                        formControlName="{{ userRightsData[i].id }}"
                        (change)="
                          onRightsCheckboxChange(
                            $event,
                            userRightsData[i].id,
                            'USER'
                          )
                        "
                      />
                      {{ userRightsData[i].name | translate }}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div formArrayName="guestCategories" *ngIf="editedGuest">
          <div class="row">
            <div class="col-sm-4">
              <span class="page-title title-equal-space" translate
                >GENERAL_RIGHTS</span
              >
              <div
                class="checkbox"
                *ngFor="
                  let def of businessUserForm.controls['guestCategories'][
                    'controls'
                  ];
                  let j = index
                "
              >
                <div *ngIf="guestRightsData[j].rightsType === 1">
                  <label>
                    <span formGroupName="{{ j }}">
                      <input
                        type="checkbox"
                        formControlName="{{ guestRightsData[j].id }}"
                        (change)="
                          onRightsCheckboxChange(
                            $event,
                            guestRightsData[j].id,
                            'GUEST'
                          )
                        "
                      />
                      {{ guestRightsData[j].name | translate }}
                    </span>
                  </label>
                </div>
              </div>
              <button
                class="btn btn-primary"
                *ngIf="showChooseProject && applicationId !== 5"
                (click)="chooseProject()"
                translate
              >
                CHOOSE_PROJECT_FDV
              </button>
              <button
                class="btn btn-primary"
                *ngIf="showChooseKundeEL"
                style="margin: 5px 0"
                (click)="chooseKundeEL()"
                translate
              >
                CHOOSE_KUNDE_EL
              </button>
            </div>
            <div class="col-sm-4" *ngIf="!hideEmployeeRights">
              <span class="page-title title-equal-space" translate
                >EMPLOYEE_RIGHTS</span
              >
              <div
                class="checkbox"
                *ngFor="
                  let def of businessUserForm.controls['guestCategories'][
                    'controls'
                  ];
                  let j = index
                "
              >
                <div *ngIf="guestRightsData[j].rightsType === 2">
                  <label>
                    <span formGroupName="{{ j }}">
                      <input
                        type="checkbox"
                        formControlName="{{ guestRightsData[j].id }}"
                        (change)="
                          onRightsCheckboxChange(
                            $event,
                            guestRightsData[j].id,
                            'GUEST'
                          )
                        "
                      />
                      {{ guestRightsData[j].name | translate }}
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <span class="page-title title-equal-space" translate
                >DEVIATION_RIGHTS</span
              >
              <div
                class="checkbox"
                *ngFor="
                  let def of businessUserForm.controls['guestCategories'][
                    'controls'
                  ];
                  let j = index
                "
              >
                <div *ngIf="guestRightsData[j].rightsType === 3">
                  <label>
                    <span formGroupName="{{ j }}">
                      <input
                        type="checkbox"
                        formControlName="{{ guestRightsData[j].id }}"
                        (change)="
                          onRightsCheckboxChange(
                            $event,
                            guestRightsData[j].id,
                            'GUEST'
                          )
                        "
                      />
                      {{ guestRightsData[j].name | translate }}
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div class="col-sm-4" *ngIf="showElectroClientRights">
              <span class="page-title title-equal-space" translate>{{
                electroRightsHead
              }}</span>
              <div
                class="checkbox"
                *ngFor="
                  let def of businessUserForm.controls['guestCategories'][
                    'controls'
                  ];
                  let j = index
                "
              >
                <div *ngIf="guestRightsData[j].rightsType === 7">
                  <label>
                    <span formGroupName="{{ j }}">
                      <input
                        type="checkbox"
                        formControlName="{{ guestRightsData[j].id }}"
                        (change)="
                          onRightsCheckboxChange(
                            $event,
                            guestRightsData[j].id,
                            'GUEST'
                          )
                        "
                      />
                      {{ guestRightsData[j].name | translate }}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div formArrayName="editorCategories" *ngIf="editedEditor">
          <div class="row">
            <div class="col-sm-6">
              <span class="page-title title-equal-space" translate
                >GENERAL_RIGHTS</span
              >
              <div
                class="checkbox"
                *ngFor="
                  let def of businessUserForm.controls['editorCategories'][
                    'controls'
                  ];
                  let k = index
                "
              >
                <div *ngIf="editorRightsData[k].rightsType === 1">
                  <label>
                    <span formGroupName="{{ k }}">
                      <input
                        type="checkbox"
                        formControlName="{{ editorRightsData[k].id }}"
                        (change)="
                          onRightsCheckboxChange(
                            $event,
                            editorRightsData[k].id,
                            'EDITOR'
                          )
                        "
                      />
                      {{ editorRightsData[k].name | translate }}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
<div class="action-btns-wrapper">
  <button class="btn btn-outline-secondary" (click)="gotoList()">
    <span class="icon ic-xs icon-back"></span>
    <span translate>BACK_LIST</span>
  </button>
  <button
    class="btn btn-outline-primary"
    *ngIf="isNewUser"
    (click)="generateRandomPassword()"
  >
    <span translate>RANDOM_PASSWORD</span>
  </button>
  <button class="btn btn-primary" (click)="saveUser()" [disabled]="loading">
    <span *ngIf="!loader" translate>SAVE</span>
    <span *ngIf="loader" translate>SAVE_PROCESS</span>
    <span *ngIf="loader" class="loader-icon"></span>
  </button>
  <button
    class="btn btn-danger"
    *ngIf="!isNewUser"
    (click)="showResetPassword()"
  >
    <span translate>RESET_PASSWORD</span>
  </button>
</div>
<p-confirmDialog
  header="{{ 'CONFIRMATION' | translate }}"
  icon="pi pi-question-circle"
  width="425"
  acceptLabel="{{ 'SWITCH_YES' | translate }}"
  rejectLabel="{{ 'SWITCH_NO' | translate }}"
></p-confirmDialog>
<toaster-component></toaster-component>
<p-dialog
  header="{{ 'INFO' | translate }}"
  [(visible)]="dialogDisplay"
  [modal]="true"
  [style]="{ width: '300px' }"
  [resizable]="false"
  [draggable]="false"
>
  <span translate>
    PLEASE_ADD_YOUR_MOBILE_NUMBER_TO_RECEIVE_USERNAME_AND_PASSWORD
  </span>
</p-dialog>
<p-dialog
  header="{{ 'RESET_PASSWORD' | translate }}"
  (onHide)="clear()"
  [(visible)]="showPasswordResetDialog"
  [modal]="true"
  [style]="{ width: '430px' }"
  [resizable]="false"
  [draggable]="false"
  icon="pi pi-info-circle"
>
  <form [formGroup]="updatePasswordForm">
    <div formGroupName="passwordValidation">
      <div class="form-group" *ngIf="!isNewUser">
        <label for="RepeatPassword">
          <span translate>NEW_PASSWORD</span>
          <span class="required">*</span>
        </label>
        <div>
          <input
            type="password"
            pInputText
            id="Password"
            formControlName="Password"
          />
        </div>
      </div>
      <div class="form-group" *ngIf="!isNewUser">
        <label for="RepeatPassword">
          <span translate>CONFIRM_PASSWORD</span>
          <span class="required">*</span>
        </label>
        <div>
          <input
            type="password"
            pInputText
            id="ConfirmPassword"
            formControlName="ConfirmPassword"
            required
          />
        </div>
      </div>
      <control-messages
        [control]="updatePasswordForm.controls['passwordValidation']"
      ></control-messages>
    </div>
    <div *ngIf="hidden">
      <label>
        <span translate>YOUR_RANDOM_PASSWORD</span>:
        <span style="color: green">{{ showPassword }}</span></label
      >
    </div>
    <div>
      <button
        class="btn btn-outline-primary"
        (click)="generateRandomPasswordForResetPassword()"
      >
        <span translate>RANDOM_PASSWORD</span>
      </button>
    </div>
    <div class="action-btns-wrapper">
      <button
        class="btn btn-primary"
        (click)="resetPassWord(false)"
        [disabled]="!updatePasswordForm.valid"
      >
        <span translate>SAVE</span>
      </button>
      <button
        class="btn btn-primary"
        (click)="resetPassWord(true)"
        [disabled]="!updatePasswordForm.valid || isMobile"
      >
        <span translate>USERINFO_SMS</span>
      </button>
    </div>
  </form>
</p-dialog>

<p-dialog
  header="{{ 'PROJECT_FDV' | translate }}"
  (onHide)="clear()"
  [(visible)]="showProjectDialog"
  [modal]="true"
  [style]="{ width: '300px' }"
  [resizable]="false"
  [draggable]="false"
>
  <div class="table-view">
    <!-- Table starts -->
    <p-table
      *ngIf="isProjExists"
      #at
      [value]="projectList"
      [rows]="5"
      dataKey="Id"
      [(selection)]="selectedProject"
      [paginator]="true"
      [pageLinks]="3"
      [rowsPerPageOptions]="[5, 10, 20]"
      [responsive]="true"
    >
      <!-- Header -->
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 38px">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th pSortableColumn="Title">{{ "PROJ_NAME" | translate }}</th>
        </tr>
        <tr>
          <th style="width: 38px"></th>
          <th>
            <input
              pInputText
              type="text"
              (input)="
                at.filter($any($event.target)?.value, 'Title', 'contains')
              "
              placeholder=""
              class="p-column-filter"
            />
          </th>
        </tr>
      </ng-template>
      <!-- Body Content -->
      <ng-template pTemplate="body" let-business>
        <tr>
          <td style="width: 38px">
            <p-tableCheckbox id="selectedOrders" [value]="business">
            </p-tableCheckbox>
          </td>
          <td>
            <span>{{ business.Title }}</span>
          </td>
        </tr>
      </ng-template>
      <!-- Empty message -->
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td colspan="2">
            {{ "NO_RECORDS_FOUND" | translate }}
          </td>
        </tr>
      </ng-template>
    </p-table>
    <!-- Table ends -->
    <!-- Table starts -->
    <p-table
      *ngIf="isFDVExists"
      #fdv
      [value]="FDVList"
      [rows]="5"
      dataKey="Id"
      [(selection)]="selectedFDV"
      [paginator]="true"
      [pageLinks]="3"
      [rowsPerPageOptions]="[5, 10, 20]"
      [responsive]="true"
    >
      <!-- Header -->
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 38px">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th pSortableColumn="Name">{{ "FDV_NAME" | translate }}</th>
        </tr>
        <tr>
          <th style="width: 38px"></th>
          <th>
            <input
              pInputText
              type="text"
              (input)="
                fdv.filter($any($event.target)?.value, 'Name', 'contains')
              "
              placeholder=""
              class="p-column-filter"
            />
          </th>
        </tr>
      </ng-template>
      <!-- Body Content -->
      <ng-template pTemplate="body" let-fdv>
        <tr>
          <td style="width: 38px">
            <p-tableCheckbox id="selectedOrders" [value]="fdv">
            </p-tableCheckbox>
          </td>
          <td>
            <span>{{ fdv.Name }}</span>
          </td>
        </tr>
      </ng-template>
      <!-- Empty message -->
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td colspan="2">
            {{ "NO_RECORDS_FOUND" | translate }}
          </td>
        </tr>
      </ng-template>
    </p-table>
    <!-- Table ends -->
  </div>
  <div *ngIf="showLoader" class="panel-loader">
    <span>
      <span class="loader-icon"></span>
      <span>Processing...</span>
    </span>
  </div>
  <button class="btn btn-primary" (click)="saveProjectAndFdv()" translate>
    SAVE_CLOSE
  </button>
</p-dialog>
<p-dialog
  header="{{ 'KundeEL' | translate }}"
  (onHide)="clear()"
  [(visible)]="showKundeELDialog"
  [modal]="true"
  [style]="{ width: '300px' }"
  [resizable]="false"
  [draggable]="false"
>
  <div class="table-view">
    <!-- Table starts -->
    <p-table
      [value]="contractList"
      #contract
      [rows]="5"
      dataKey="Id"
      [(selection)]="selectedContract"
      [paginator]="true"
      [pageLinks]="3"
      [rowsPerPageOptions]="[5, 10, 20]"
      [responsive]="true"
    >
      <!-- Header -->
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 38px">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th pSortableColumn="ContractName">
            {{ "CONTRACT_NAME" | translate }}
          </th>
        </tr>
        <tr>
          <th style="width: 38px"></th>
          <th>
            <input
              pInputText
              type="text"
              (input)="
                contract.filter(
                  $any($event.target)?.value,
                  'ContractName',
                  'contains'
                )
              "
              placeholder=""
              class="p-column-filter"
            />
          </th>
        </tr>
      </ng-template>
      <!-- Body Content -->
      <ng-template pTemplate="body" let-contract>
        <tr>
          <td style="width: 38px">
            <p-tableCheckbox id="selectedOrders" [value]="contract">
            </p-tableCheckbox>
          </td>
          <td>
            <span>{{ contract.ContractName }}</span>
          </td>
        </tr>
      </ng-template>
      <!-- Empty message -->
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td colspan="2">
            {{ "NO_RECORDS_FOUND" | translate }}
          </td>
        </tr>
      </ng-template>
    </p-table>
    <!-- Table ends -->
  </div>
  <button class="btn btn-primary" (click)="saveKundeEL()" translate>
    SAVE_CLOSE
  </button>
</p-dialog>
