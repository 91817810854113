import { APP_BASE_HREF } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { InitialServices } from 'src/app/_services/initial.services';
import * as _ from 'lodash';
import { forkJoin, Subscription } from 'rxjs';
import * as Cookies from 'es-cookie';
import * as $ from 'jquery';
import { ThemeId, ThemeService } from '../theme.service';
import { EmployeeServices } from './../kuba/employees/services/employee.services';
import { CacheHandlerService } from './../kuba/cached.services';
import { NetworkConnection, ConnectionStatusEnum } from './connection.check';
import { BaseServices, KubaServices } from '../kuba/kuba.services';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../_services/authentication.service';
import { AlertService } from '../_services/alert.service';
import { AccessLog } from '../kuba/accesslog/models/accesslog';
import { AccessLogServices } from '../kuba/accesslog/services/accesslog.services';
import { IdentityToken } from "../_models/identityToken";
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.css'],
})
export class LoginComponent implements OnInit {
  accesslog: any;
  model: any = {};
  loading = false;
  returnUrl: any;
  baseUrl: any;
  userSettings: any;
  deviceInfo = null;
  acessLog: AccessLog[] | any;
  isInvalid = false;
  userName: any;
  password: any;
  isPhp = false;
  displayDialog = false;
  IsTOCApproved: any;
  userRole: any;
  assetloc: string = environment.BASE_STYLES_PATH;
  isOnline = true;
  isMedi3 = false;
  termsAndCondition: any;
  csession: any;
  private subscriptions: Subscription[] = [];
  ipAddress: any;
  errorMessage: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2,
    private themeService: ThemeService,
    private alertService: AlertService,
    private authenticationService: AuthenticationService,
    private accessLogServices: AccessLogServices,
    private kubaServices: KubaServices,
    private initialServices: InitialServices,
    private employeeServices: EmployeeServices,
    private cacheHandlerService: CacheHandlerService,
    @Inject(APP_BASE_HREF) private baseHref: string,
    @Inject(DOCUMENT) private document: any,
    private http: HttpClient,
    private translate: TranslateService
  ) {
    this.isPhp = <boolean>this.route.snapshot.data['phpLogin'];
    this.getIPAddress();
  }

  ngOnInit() {
    // reset login status
    sessionStorage.clear();
    this.resetTheme();
    this.initialServices.appInitializing();
    // get return url from route parameters or default to '/app'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/app';
    if (this.isPhp) {
      this.userName = '';
      this.password = '';
      this.decryptCredentialsAsync();
    }
    this.renderer.addClass(document.body.parentElement, 'loginView');
    this.renderer.addClass(document.body, 'loginView');
    let medi3 = window.location.pathname.substring(
      window.location.pathname.indexOf('medi3') + 0,
      window.location.pathname.indexOf('medi3') + 5
    );
    if (medi3 == 'medi3') {
      this.isMedi3 = true;
      this.termsAndCondition = 'Medi3 - Vilkår og betingelser';
    } else {
      this.isMedi3 = false;
      this.termsAndCondition = 'Kuba - Vilkår og betingelser';
    }
  }

  getIPAddress() {
    this.http.get(`${environment.BASE_URL}/get-ip`).subscribe((ipAdress: any) => {
      this.ipAddress = ipAdress.ip || '';
    });
  }
  resetTheme(): void {
    const themePath = ''; // or themePath = null;
    this.themeService.setTheme(ThemeId.DEFAULT);
  }

  decryptCredentialsAsync() {
    this.subscriptions.push(
      this.authenticationService
        .getDecryptedLoginCredentials(
          this.route.snapshot.params['username'],
          this.route.snapshot.params['password']
        )
        .subscribe(
          (res: any) => {
            if (res) {
              this.userName = res.Username;
              this.password = res.Password;
              if (
                this.userName !== '' &&
                this.userName !== 'Invalid' &&
                this.password !== ''
              ) {
                this.model.username = this.userName;
                this.model.password = this.password;
                this.verifyGDPRandLogin();
              } else {
                this.selfRedirect('Invalid User or Password');
              }
            }
          },
          (error) => this.selfRedirect('Invalid User or Password')
        )
    );
  }

  selfRedirect(msg: string) {
    this.alertService.error(msg);
    this.loading = false;
    this.router.navigate(['login']);
  }

  // close pop-up
  decline() {
    this.displayDialog = false;
    this.selfRedirect('GDPR Declined');
  }

  // Accept GDPR agreement
  accept() {
    this.displayDialog = false;
    this.loading = true;

    const userId = this.model.userId;
    const isTOCApproved = true;

    const url = `${environment.BASE_URL}/current-user/${userId}/${isTOCApproved}`;
    this.http.put(url, {}).subscribe(
      (response: any) => {
        this.verifyGDPRandLogin();
      },
      (error) => {
        console.error('Error updating GDPR status:', error);
        this.alertService.error('Failed to update GDPR status.');
        this.loading = false;
      }
    );
  }

  verifyGDPRandLogin(): void {
    this.loading = true;
    if (NetworkConnection.status === ConnectionStatusEnum.Offline) {
      this.isOnline = false;
    } else if (NetworkConnection.status === ConnectionStatusEnum.Online) {
      this.isOnline = true;
    }
    this.isPhp = false;

    this.subscriptions.push(
      this.authenticationService
        .login(this.model.username, this.model.password, this.ipAddress)
        .subscribe(
          (res: IdentityToken) => {
            this.model.userId = res.UserId;

            if (res && res.Role !== "" && res.Role !== null) {
              if (!res.GdprStatus) {
                this.loading = false;
                this.displayDialog = true;
              } else {
                if (res && res.Role !== '' && res.Role !== undefined) {
                  // cache clear
                  this.cacheHandlerService.clearCachedData();
                  // set userSetting for apploication when is not set
                  if (sessionStorage.getItem('userSettings') == null) {
                    let observables = [];

                    // Retrives all available languages
                    observables.push(this.kubaServices.getAllLanguages());

                    // Retrive current user language , UserProfile & UserTheme
                    observables.push(this.kubaServices.getUserCustomSetting());

                    // get and set UserBase
                    observables.push(this.kubaServices.getUserBase());

                    // get and set UserFeatures
                    observables.push(this.kubaServices.getUserFeatures());

                    // Observe above call for completion
                    this.subscriptions.push(
                      forkJoin(observables).subscribe(
                        (result) => {
                          if (result) {
                            result.forEach((data: any) => {
                              if (data['userLanguageTheme'] != null) {
                                const userLanguageTheme =
                                  data['userLanguageTheme'];
                                const userTheme = userLanguageTheme?.userTheme;
                                console.log(userLanguageTheme);

                                if (userTheme && userTheme.Stylepath) {
                                  console.log(userTheme);
                                  const themePath =
                                    this.assetloc + userTheme.Stylepath;
                                  this.themeService.setTheme(userTheme.id);
                                  this.themeService.applyTheme();
                                }
                                this.userSettings = _.assign(
                                  this.userSettings,
                                  data['userLanguageTheme']
                                );
                                this.userRole = _.assign(
                                  this.userSettings,
                                  data['userBase']
                                );
                              } else {
                                this.userSettings = _.assign(
                                  this.userSettings,
                                  data
                                );
                              }
                            });
                          }
                        },
                        (error) =>
                          console.error('Error at App Init services : ', error),
                        () => {
                          this.loading = false;
                          // setting Up Session Storage and listen to it
                          sessionStorage.setItem(
                            'userSettings',
                            JSON.stringify(this.userSettings)
                          );

                          if (sessionStorage.getItem('appSettings') == null) {
                            this.loading = false;
                            window.location.href =
                              window.location.href.replace(
                                window.location.pathname,
                                ''
                              ) +
                              '/' +
                              this.userSettings.userBase.BaseUrl;
                            this.router.navigate([this.returnUrl]);
                          } else {
                            this.router.navigate([this.returnUrl], {
                              relativeTo: this.route,
                            });
                            this.addAccessLog(); 
                          }
                        }
                      )
                    );
                  }
                } else {
                  this.loading = false;
                  this.alertService.error('Invalid User or Password');
                }
              }
            } else {
              this.loading = false;
              this.isInvalid = true;
              setTimeout((x: any) => {
                this.isInvalid = false;
              }, 4000);
            }
          },
          (error) => {
            this.handleErrorMessages(error);
          }
        )
    );
  }


  login(): void {
    this.subscriptions.push(
      this.authenticationService
        .login(this.model.username, this.model.password, this.ipAddress)
        .subscribe(
          (res) => {

          },
          (error) => {
            this.loading = false;
            this.isInvalid = true;
            setTimeout((x: any) => {
              this.isInvalid = false;
            }, 2000);
            if (error.status === 0) {
              this.alertService.error('User may not available.');
            }
            if (error.status !== 0) {
              this.alertService.error(error._body);
            }
          }
        )
    );
  }
  /**
   * access log for hit counts
   */
  addAccessLog() {
    let user = new AccessLog();
    user.UserId = BaseServices.UserId;
    user.BusinessId = BaseServices.BusinessId;
    user.IP = '';
    user.Browser = '';
    user.OS = '';
    user.ApplicationId = BaseServices.ApplicationId;
    user.Type = 'Web';
    user.LogIn = new Date().toISOString();
    this.subscriptions.push(
      this.accessLogServices.addAccessLog(user).subscribe(
        (accsess: any) => {
          if (accsess) {
            this.loading = false;
          }
        },
        (error: any) => {
          this.loading = false;
          if (error.status === 0) {
          }
        }
      )
    );
  }
  goToForgetPassword() {
    this.router.navigate(['./../forget-password'], { relativeTo: this.route });
  }

  RememberMeFunction() {
    if (this.model.rememberMe === true) {
      Cookies.set('UserName', this.model.username, { expires: 60 });
      Cookies.set('Password', this.model.password, { expires: 60 });
    } else {
      Cookies.set('UserName', this.model.username, { expires: -60 });
      Cookies.set('Password', this.model.password, { expires: -60 });
    }
  }

  handleErrorMessages(error){
    switch (error.status) {
      case 0: // Network issue or timeout
        if (error.name === 'TimeoutError') {
          this.errorMessage = this.translate.instant('REQUEST_TIMEOUT');
        } else {
          this.errorMessage = this.translate.instant('SERVER_UNAVAILABLE');
        }
        break;

      case 400: //Bad request
        this.errorMessage = this.translate.instant('WRONG_USERNAME_PASSWORD');
        break;

      case 504: // Gateway timeout
        this.errorMessage = this.translate.instant('SERVER_DOWN');
        break;

      default: // Handle unexpected errors
        this.errorMessage = this.translate.instant('SOMETHING_WENT_WRONG');
        break;
      }
      this.isInvalid = true;
      this.displayDialog = false;
      this.loading = false;
      setTimeout(() =>{
        this.isInvalid = false;
      }, 5000)
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body.parentElement, 'loginView');
    this.renderer.removeClass(document.body, 'loginView');
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}
