<div class="main-heading">
  <div class="page-actions text-right">
    <button
      type="button"
      *ngIf="editMode && featureId != 60"
      class="btn btn-primary"
      (click)="openModal(moveFilesTemplate)"
    >
      <span translate>ADD_FROM_MANUALS</span>
    </button>
    <button
      *ngIf="isShowAllFile"
      type="button"
      class="btn btn-outline-primary"
      (click)="showAllFiles()"
    >
      <span translate>SHOW_ALL_FILES</span>
    </button>
    <button
      type="button"
      *ngIf="hideButton"
      class="btn"
      [ngClass]="editMode ? 'text-danger' : 'text-success'"
      (click)="toggleEditMode()"
    >
      <span *ngIf="editMode" translate>GO_DISPLAY</span>
      <span *ngIf="!editMode" translate>GO_EDIT</span>
      <span
        class="icon ic-md"
        [ngClass]="editMode ? 'icon-view-content' : 'icon-edit-content'"
      ></span>
    </button>
  </div>
</div>
<div class="main-content">
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-sm-3">
          <app-ecpl-document-tree
            *ngIf="treeNode"
            treeId="featureDocumentsTree"
            [(ecplTreeData)]="treeNode"
            [(ecplActiveNode)]="treeActiveNode"
            [(ecplTreeCheckbox)]="editMode"
            (ecplNodeEvent)="catchEvent($event)"
            [(ecplApiResponse)]="apiResponse"
          >
          </app-ecpl-document-tree>
        </div>
        <div class="col-sm-9">
          <div *ngIf="isLar != true">
            <ecpl-document-explorer
              [(triggerUploader)]="triggerUploader"
              [uploaderConfig]="upConfig"
              [uploadedFiles]="uploadedFiles"
              (saveChanges)="saveUploaded($event)"
              (activeFolder)="setActiveNodeIntree($event)"
              [(ecplApiResponse)]="apiResponse"
              [folderList]="folderToFilter"
              (filterFolderEvents)="onFilteredFolder($event)"
            >
            </ecpl-document-explorer>
          </div>
          <div *ngIf="isLar == true">
            <ecpl-lar-viewer
              (saveChanges)="saveUploaded($event)"
              [larSrc]="uploadedFiles"
              [name]="filename"
              [mode]="editMode"
            ></ecpl-lar-viewer>
          </div>
        </div>
      </div>
      <div class="saving-loader overlay" *ngIf="showSavingLoader">
        <div class="saving-loader-message text-center">
          <img src="/assets/icons/blocks.gif" alt="" />
          <p translate>SAVING</p>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #moveFilesTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left" translate>COPY_MANUALS</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <copy-manual-files (refresh)="refresh($event)"> </copy-manual-files>
  </div>
</ng-template>
<toaster-component></toaster-component>
<div class="panel-loader" *ngIf="loading">
  <span>
    <span class="loader-icon"></span>
    <span translate>PROCESSING</span>
  </span>
</div>
