import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseServices } from '../../kuba.services';

import { ContactServices } from '../services/contact.service';
import { AdminUserService } from './../../adminuser/services/adminuser.service';
import { ContactEdit, CutomerContactsWithLogin } from './../models/contactEdit';
import { ContactType, ContactView } from './../models/contactView';
import { Roles } from 'src/app/_models';

@Injectable()
export class ContactListResolver implements Resolve<ContactView[]> {
  constructor(private backend: ContactServices) {}
  resolve(): Observable<ContactView[]> {
    return this.backend.getAll();
  }
}

@Injectable()
export class ContactEditResolver implements Resolve<ContactEdit> {
  constructor(private backend: ContactServices) {}
  resolve(route: ActivatedRouteSnapshot) {
    if (
      +BaseServices.roleId === Roles.PORTAL ||
      +BaseServices.roleId === Roles.ADMIN
    ) {
      return this.backend.getAdminPortalContactById(+route.params['cid']);
    } else {
      return this.backend.getContactById(+route.params['cid']);
    }
  }
}
@Injectable()
export class ContactTypeResolver implements Resolve<ContactType> {
  constructor(private backend: ContactServices) {}
  resolve() {
    return this.backend.getAllContactType();
  }
}
@Injectable()
export class FunctionListResolver implements Resolve<ContactType> {
  constructor(private backend: ContactServices) {}
  resolve() {
    return this.backend.getAllByFunction();
  }
}
@Injectable()
export class CountryCodeListResolver implements Resolve<ContactType> {
  constructor(private backend: AdminUserService) {}
  resolve() {
    return this.backend.getCountryCodes();
  }
}
@Injectable()
export class CustomerContactsWithLoginResolver
  implements Resolve<CutomerContactsWithLogin>
{
  constructor(private backend: ContactServices) {}
  resolve() {
    return this.backend.getAllCustContactsWithLogin();
  }
}
@Injectable()
export class CustomerContactsWithResolver
  implements Resolve<CutomerContactsWithLogin>
{
  constructor(private backend: ContactServices) {}
  resolve() {
    return this.backend.getAllCustContacts();
  }
}
@Injectable()
export class AdminContactsResolver implements Resolve<any[]> {
  constructor(private backend: ContactServices) {}
  resolve() {
    return this.backend.getAdminContacts();
  }
}
