import {
  ApprovalNotices,
  PortalRisk,
  RiskAnalysisCategory
} from './../models/riskanalysis';

import { BaseServices } from './../../kuba.services';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { environment } from 'src/environments/environment';
import {
  Riskanalysis,
  Department,
  Topic,
  RiskanalysisActionPlan,
  TaskPlan
} from '../models/riskanalysis';
import 'rxjs/add/operator/map';
import { TreeData } from './../../common/models/index';
import { Category } from 'src/app/kuba/businesses/models';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class RiskanalysisService {
  _topic: Observable<any> = null!;
  constructor(private http: HttpClient) {}
  clearCachedTopic() {
    this._topic = null!;
  }
  // list the risk analysis details
  list() {
    return this.http
      .get(environment.BASE_URL + '/riskbybusiness/' + BaseServices.BusinessId)
      .map((response: any) => {
        return response;
      });
  }
  deleteTaskPlan(Id: any) {
    return this.http
      .delete(environment.BASE_URL + '/activitydelete/' + Id)
      .map(result => result);
  }
  getAllByProj(id: number) {
    return this.http
      .get(environment.BASE_URL + '/riskanalysisbyproject/' + id)
      .map((response: any) => {
        return response;
      });
  }
  deleteRisk(id: any) {
    return this.http
      .delete(environment.BASE_URL + '/risanalysisdelete/' + id)
      .map((response: any) => {
        return response;
      });
  }
  deleteApprovalNotice(id: any) {
    return this.http
      .delete(environment.BASE_URL + '/riskanalysis/deleteapproved/' + id)
      .map((response: any) => {
        return response;
      });
  }
  // get the particular risk analysis
  getById(id: any) {
    if (id > 0) {
      let result: any = this.http
        .get(environment.BASE_URL + '/riskanalysis/' + id)
        .map((result: any) => result);
      return result;
    }
  }

  followupriskadd(data: Riskanalysis) {
    return this.http
      .post(environment.BASE_URL + '/followup/followup-riskanalysis', data)
      .map(result => result);
  }
  addPortalRisk(data: PortalRisk) {
    return this.http
      .post(environment.BASE_URL + '/riskforportal', data)
      .map(result => result);
  }
  update(data: any) {
    return this.http
      .put(environment.BASE_URL + '/riskanalysis/', data)
      .map((response: any) => {
        return response;
      });
  }
  RiskBusinessupdate(data: any) {
    return this.http
      .post(environment.BASE_URL + '/riskbusinessdepartment', data)
      .map((response: any) => {
        return response;
      });
  }

  updateRiskAnalysisAction(data: any) {
    return this.http
      .put(environment.BASE_URL + '/riskanalysis/', data)
      .map((response: any) => {
        return response;
      });
  }

  addRiskAnalysisAction(newActionPlan: any) {
    return this.http
      .post(environment.BASE_URL + '/riskanalysis', newActionPlan)
      .map(result => result);
  }

  // update department in view
  updateDept(existingCategory: Department) {
    return this.http
      .put(
        environment.BASE_URL + '/department/' + existingCategory.Id,
        existingCategory
      )
      .map(result => result);
  }

  // adding new department in view
  createDept(newCategory: Department) {
    return this.http
      .post(environment.BASE_URL + '/department/', JSON.stringify(newCategory))
      .map(result => result);
  }

  createTopic(newCategory: Topic) {
    return this.http
      .post(environment.BASE_URL + '/Save/', JSON.stringify(newCategory))
      .map(result => result);
  }
  deleteDepartement(id: number) {
    return this.http
      .delete(environment.BASE_URL + '/departmentdelete/' + id)
      .map((response: any) => {
        return response;
      });
  }

  getTopicByBusiness(id: any) {
    if (!this._topic) {
      this._topic = this.http
        .get(environment.BASE_URL + '/DeviationTopic/' + id)
        .pipe(map(result => result));
    }
    return this._topic;
  }
  getTopicByPortal(id: any) {
    if (!this._topic) {
      this._topic = this.http
        .get(environment.BASE_URL + '/DeviationTopicbyPortal/' + id)
        .pipe(map(result => result));
    }
    return this._topic;
  }
  updateTopic(existingCategory: Topic) {
    return this.http
      .put(environment.BASE_URL + '/Update/', existingCategory)
      .map(result => result);
  }
  DeleteTopic(id: any) {
    return this.http
      .delete(environment.BASE_URL + '/Delete/' + id)
      .map((response: any) => {
        return response;
      });
  }
  getByAnalysis(id: number) {
    return this.http
      .get(environment.BASE_URL + '/riskanalysistaskplan/' + id)
      .map(result => result);
  }

  addApprovalNotices(approvalNotice: ApprovalNotices[]) {
    return this.http
      .post(environment.BASE_URL + '/riskanalysis/approved', approvalNotice)
      .map(result => result);
  }
  getBusinesses() {
    return this.http
      .get(environment.BASE_URL + '/businesses')
      .map((result: any) => result);
  }
  getApprovalNoticeByRisk(id: number) {
    return this.http
      .get(environment.BASE_URL + '/approvalnoticeByRisk/' + id)
      .pipe(
        map(result => {
          console.log(
            `Result for approval notice by risk (ID: ${id}):`,
            result
          );
          return result;
        })
      );
  }

  getApprovalNoticeByBusiness() {
    let result: any = this.http
      .get(
        environment.BASE_URL + '/approvalWithRisk/' + BaseServices.BusinessId
      )
      .map((result: any) => result);
    return result;
  }

  getArchiveTree(businessId: number): Observable<TreeData[]> {
    return this.http
      .get(`${environment.BASE_URL}/archivetreedata/${businessId}`)
      .map((result: any) => result);
  }

  // add risk document from archive
  addriskdocument(riskAttachment: any) {
    return this.http
      .post(`${environment.BASE_URL}/add/riskdocument`, riskAttachment)
      .map(result => result)
      .catch(error => BaseServices.handleError(error));
  }

  getRiskAnalysisLog(analysisId: number) {
    return this.http
      .get(`${environment.BASE_URL}/riskanalysislog/${analysisId}`)
      .map((result: any) => result);
  }

  getRiskAnalysisForAdmin(applicationId: number, businessId: number) {
    return this.http
      .get(
        `${environment.BASE_URL}/riskforadmin/${applicationId}/${businessId}`
      )
      .map((result: any) => result);
  }

  getRiskAttachmentByRisk(riskId: number) {
    return this.http
      .get(`${environment.BASE_URL}/riskanalysisattachment/${riskId}`)
      .map((result: any) => result);
  }

  deleteRiskAttachment(id: number) {
    return this.http
      .delete(environment.BASE_URL + '/risanalysisattachmentdelete/' + id)
      .map((response: any) => {
        return response;
      });
  }
  getUserForAdminRisk(riskId: number) {
    return this.http
      .get(environment.BASE_URL + '/usersforadmin/' + riskId)
      .map(result => result);
  }
  checkAllocatedDepartment(departmentID: number) {
    return this.http
      .get(environment.BASE_URL + '/checkdepartmentforrisk/' + departmentID)
      .map(result => result);
  }
  checkAllocatedTopic(topicId: number) {
    return this.http
      .get(environment.BASE_URL + '/checktopicforrisk/' + topicId)
      .map(result => result);
  }

  deleteApprovalnoticeList(data: any) {
    return this.http
      .put(environment.BASE_URL + '/deleteapproved/all', data)
      .map(result => result);
  }

  portalList(businessId: number) {
    return this.http
      .get(environment.BASE_URL + '/riskbybusinessforPortal/' + businessId)
      .map((response: any) => {
        return response;
      });
  }
  getPortalRiskAnalysis(userId: number) {
    return this.http
      .get(environment.BASE_URL + '/portalriskanalysis/' + userId)
      .map(result => result);
  }

  riskAnalysisReportGenerate(
    businessId: number,
    riskanalysisId: number,
    cultureInfo: string,
    ReportJson: any,
    FeatureId: number,
    userId: number,
    isEdit: boolean
  ) {
    let dynamicType = 'application/pdf';
    let myData = JSON.stringify({ data: ReportJson });
    let url = `${environment.BASE_URL}/export-RiskanalysisPrintDetails/${businessId}/${riskanalysisId}/${cultureInfo}/${FeatureId}/${userId}/${isEdit}`;
    return Observable.create(observer => {
      let xhr = new XMLHttpRequest();
      xhr.open('POST', url, true);
      xhr.withCredentials = true;
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.responseType = 'blob';
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            let contentType = dynamicType;
            let blob = new Blob([xhr.response], { type: contentType });
            observer.next(blob);
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };
      xhr.send(myData);
    });
  }

  sendRiskMail(objMailInformation: any) {
    return this.http
      .post(environment.BASE_URL + '/sendRiskMail/', objMailInformation)
      .map(result => result);
  }

  //add category
  createCategory(newCategory: RiskAnalysisCategory) {
    return this.http
      .post(
        environment.BASE_URL + '/Category/Save/' + BaseServices.BusinessId,
        newCategory
      )
      .map(result => result);
  }

  //get category list
  getCategoryList(BusinessId: number, ApplicationId: number) {
    return this.http
      .get(
        `${environment.BASE_URL}/get/CategoryName/${ApplicationId}/${BusinessId}`
      )
      .map((result: any) => result);
  }

  //delete category by Id
  DeleteCategoryById(Id: number) {
    return this.http
      .delete(environment.BASE_URL + '/delete/Category/' + Id)
      .map(result => result);
  }

  getDepartmentsForAdmin(riskId: number) {
    return this.http.get(
      environment.BASE_URL + '/departmentforadmin/' + riskId
    );
    // .map(result => result.json());
  }
}
