import { EditDeviationComponent } from '../deviation/components/deviation-edit/deviation-edit.component';
import {
  DepartmentForAdminResolver,
  DeviationEditResolver,
} from '../deviation/services/deviation.resolver';
import {
  BusinessCacheResolver,
  CompanyNameResolver,
  RiskListResolver,
} from './../businesses/services/business.resolver.service';
import { EmployeeDocResolver } from './../employees/services/employee.resolver.service';

import { AddDeviationComponent } from './components/deviation-new.component';

import { EmployeeContactUserResolver } from './../users/services/user.resolver.service';
import { PortalActivitiesComponent } from './components/activity-portal.component';
import { MessageInboxComponent } from './components/message-inbox.component';
import { MessagenewComponent } from './components/message-new.component';
import { MessageOutboxComponent } from './components/message-outbox.component';
import { MessageToViewComponent } from './components/message-to-view.component';
import { MessageViewComponent } from './components/message-view.component';
import { FollowupMessagingComponent } from './components/messaging.component';
import {
  AllDepartmentCachedResolver,
  AllDepartmentPortalResolver,
  AllDepartmentResolver,
  ClientResolver,
  DeviationsByUserResolver,
  DeviationUserResolver,
  EmployeeContactsListResolver,
  FollowupCaseTypeResolver,
  ResponsibleForExecutionResolver,
} from './services/deviation.resolver.service';
import {
  MessageListResolver,
  MessageUserListResolver,
  MessageViewResolver,
  OutBoxMessageResolver,
} from './services/message.resolver.service';
import {
  ApprovalNoticesResolver,
  FollowupDepartmentForAdminResolver,
  RiskAnalysisAdminResolver,
  RiskAnalysisCategoryResolver,
  RiskAnalysisEditResolver,
  RiskAnalysisListResolver,
  RiskAnalysisLogResolver,
  RiskAnalysisTopicCachedResolver,
  RiskAnalysisTopicForAdminResolver,
  UserForAdminResolver,
} from './services/risk-analysis.resolver.service';

import { AuthGuard } from './../../_guards/auth.guard';
import { ActivityListComponent } from './components/activity-list.component';
import {
  ActivityEditResolver,
  ActivityListResolver,
  ActivtyListByUserResolver,
  BusinessPortalContactsResolver,
  ChecklistResolver,
  DepartmentCachedListResolver,
  RepetionListResolver,
  UserListResolver,
} from './services/activities.resolver.service';

import { Route } from '@angular/router';

import {
  DeviationListComponent,
  EditActivitiesComponent,
  FollowUpActivitiesComponent,
  FollowUpDeviationComponent,
  NewRiskAnalysisComponent,
  NewRiskPortalComponent,
  RiskAnalysisComponent,
  RiskAnalysisListComponent,
} from './components/index';
import { FollowUpsComponent } from './followups.component';

import { ArchiveTreeDataResolver } from '../archive/archive.resolver.service';
import { SjaListComponent } from '../sja-form/components/sja-list.component';
import { SjaUserformComponent } from '../sja-form/components/sja-userform.component';
import { SjaFormComponent } from '../sja-form/sja-form.component';
import {
  SJAFormListResolver,
  SJAFormResolver,
  SJATitleListResolver,
} from '../sja-form/sjaform.resolver.service';

export const followupRoutes: Route[] = [
  {
    path: 'follow-up',
    component: FollowUpsComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'messaging', redirectTo: 'messaging', pathMatch: 'full' },
      {
        path: 'messaging',
        component: FollowupMessagingComponent,
        canActivate: [AuthGuard],
        children: [
          { path: '', redirectTo: 'inbox', pathMatch: 'full' },
          {
            path: 'inbox',
            component: MessageInboxComponent,
            resolve: {
              message: MessageListResolver,
            },
          },
          {
            path: 'outbox',
            component: MessageOutboxComponent,
            resolve: {
              message: OutBoxMessageResolver,
            },
          },
        ],
      },
      {
        path: 'new',
        component: MessagenewComponent,
        resolve: {
          list: MessageUserListResolver,
        },
      },
      {
        path: 'view/:Id',
        component: MessageViewComponent,
        canActivate: [AuthGuard],
        resolve: {
          message: MessageViewResolver,
        },
      },
      {
        path: 'toview/:Id',
        component: MessageToViewComponent,
        canActivate: [AuthGuard],
        resolve: {
          message: MessageViewResolver,
        },
      },
      {
        path: 'activities',
        component: FollowUpActivitiesComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: ActivityListComponent,
            resolve: {
              list: ActivityListResolver,
              listbyuser: ActivtyListByUserResolver,
              repetition: RepetionListResolver,
              user: EmployeeContactUserResolver,
              users: UserListResolver,
              portalContacts: BusinessPortalContactsResolver,
              business: CompanyNameResolver,
              departments: AllDepartmentResolver,
            },
            canActivate: [AuthGuard],
          },
          {
            path: 'edit/:aid',
            component: EditActivitiesComponent,
            resolve: {
              detail: ActivityEditResolver,
              departments: DepartmentCachedListResolver,
              repetition: RepetionListResolver,
              Checklist: ChecklistResolver,
              user: EmployeeContactUserResolver,
            },
            canActivate: [AuthGuard],
          },
          {
            path: 'portalactivity',
            component: PortalActivitiesComponent,
            resolve: {
              repetition: RepetionListResolver,
              Checklist: ChecklistResolver,
              business: CompanyNameResolver,
            },
            canActivate: [AuthGuard],
          },
        ],
      },

      {
        path: 'deviations',
        component: FollowUpDeviationComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: DeviationListComponent,
            resolve: {
              client: ClientResolver,
              business: CompanyNameResolver,
              user: EmployeeContactUserResolver,
              topic: RiskAnalysisTopicCachedResolver,
              caseType: FollowupCaseTypeResolver,
              deviationByUser: DeviationsByUserResolver,
              department: AllDepartmentCachedResolver,
              deviationuser: DeviationUserResolver,
              departmentPortal: AllDepartmentPortalResolver,
              departments: AllDepartmentResolver,
            },
            canActivate: [AuthGuard],
          },
          {
            path: 'new',
            component: AddDeviationComponent,
            data: {
              parent: 'FOLLOWUP',
            },
            resolve: {
              topic: RiskAnalysisTopicCachedResolver,
              caseType: FollowupCaseTypeResolver,
              department: AllDepartmentCachedResolver,
              doclist: EmployeeDocResolver,
              user: EmployeeContactUserResolver,
            },
            canActivate: [AuthGuard],
          },
          {
            path: 'edit/:deviationId',
            component: EditDeviationComponent,
            resolve: {
              edit: DeviationEditResolver,
              topic: RiskAnalysisTopicCachedResolver,
              caseType: FollowupCaseTypeResolver,
              department: AllDepartmentCachedResolver,
              user: EmployeeContactUserResolver,
              adminDepartment: DepartmentForAdminResolver,
              businessUser: EmployeeContactsListResolver,
            },
          },
        ],
      },
      {
        path: 'risk-analysis',
        component: RiskAnalysisComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: RiskAnalysisListComponent,
            resolve: {
              detail: RiskAnalysisEditResolver,
              approvalNotices: ApprovalNoticesResolver,
              list: RiskAnalysisListResolver,
              departments: AllDepartmentResolver,
              adminlist: RiskAnalysisAdminResolver,
              topic: RiskAnalysisTopicCachedResolver,
              business: CompanyNameResolver,
              Risklist: RiskListResolver,
              category: RiskAnalysisCategoryResolver,
            },
          },
          {
            path: 'newrisk',
            component: NewRiskAnalysisComponent,
            resolve: {
              department: DepartmentCachedListResolver,
              topic: RiskAnalysisTopicCachedResolver,
              user: EmployeeContactUserResolver,
              category: RiskAnalysisCategoryResolver,
              business: CompanyNameResolver,
            },
          },
          {
            path: 'edit-risk/:rid',
            component: NewRiskAnalysisComponent,
            resolve: {
              detail: RiskAnalysisEditResolver,
              department: DepartmentCachedListResolver,
              topic: RiskAnalysisTopicCachedResolver,
              user: EmployeeContactUserResolver,
              log: RiskAnalysisLogResolver,
              adminTopic: RiskAnalysisTopicForAdminResolver,
              adminDepartment: FollowupDepartmentForAdminResolver,
              usersAdmin: UserForAdminResolver,
              category: RiskAnalysisCategoryResolver,
              business: CompanyNameResolver,
            },
          },
          {
            path: 'riskportal',
            component: NewRiskPortalComponent,
            resolve: {
              business: BusinessCacheResolver,
            },
          },
        ],
      },
      {
        path: 'risk-analysis',
        component: RiskAnalysisComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: RiskAnalysisListComponent,
            resolve: {
              detail: RiskAnalysisEditResolver,
              list: RiskAnalysisListResolver,
              departments: AllDepartmentResolver,
              approvalNotices: ApprovalNoticesResolver,
              business: CompanyNameResolver,
              Risklist: RiskListResolver,
              category: RiskAnalysisCategoryResolver,
            },
          },
          {
            path: 'newrisk',
            component: NewRiskAnalysisComponent,
            resolve: {
              department: DepartmentCachedListResolver,
              topic: RiskAnalysisTopicCachedResolver,
              user: ResponsibleForExecutionResolver,
              business: CompanyNameResolver,
            },
          },
          {
            path: 'edit-risk/:rid',
            component: NewRiskAnalysisComponent,
            resolve: {
              detail: RiskAnalysisEditResolver,
              department: DepartmentCachedListResolver,
              topic: RiskAnalysisTopicCachedResolver,
              user: ResponsibleForExecutionResolver,
              treeData: ArchiveTreeDataResolver,
              business: CompanyNameResolver,
            },
          },
        ],
      },
      {
        path: 'sjaform',
        component: SjaFormComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'list',
            component: SjaListComponent,
            canActivate: [AuthGuard],
            resolve: {
              business: CompanyNameResolver,
              list: SJAFormListResolver,
            },
          },
          {
            path: 'new',
            component: SjaUserformComponent,
            canActivate: [AuthGuard],
            resolve: {
              sjaTitleList: SJATitleListResolver,
            },
          },
          {
            path: 'edit/:Id',
            component: SjaUserformComponent,
            canActivate: [AuthGuard],
            resolve: {
              sjaform: SJAFormResolver,
              sjaTitleList: SJATitleListResolver,
            },
          },
        ],
      },
    ],
  },
];
