import { Module } from 'src/app/kuba/follow-ups/models/deviation';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Route, ActivatedRoute } from '@angular/router';

import { ToasterComponent } from './../../../../_directives/toaster.component';
import { HelperService } from './../../../../_services/helper.service';
import { TimetableServices } from '../../services/timetable.service';
import { Timetable } from '../../models/timetable';
import { Subscription } from 'rxjs';
import { Status } from 'src/app/_models';

@Component({
  selector: 'new-timetable',
  templateUrl: 'new-timetable.component.html',
})
export class CreateTimetableComponent implements OnInit {
  //#region variables

  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  isTimetableFormHidden = false;
  timetableAddForm: FormGroup;
  loading = false;
  parentKey: any;
  parentId: any;
  isParentEmployee: boolean;
  private subscriptions: Subscription[] = [];

  // #endregion

  //#region constructor

  /**
   * constructor
   * @param timetableService {TimetableServices}
   * @param _fb {FormBuilder}
   * @param location {Location}
   * @param route {ActivatedRoute}
   */
  constructor(
    private timetableService: TimetableServices,
    private _fb: FormBuilder,
    private location: Location,
    private route: ActivatedRoute
  ) {
    this.parentKey = route.snapshot.parent.parent.data['parent'];
    this.parentId = route.snapshot.parent.parent.params['id'];
  }

  // #endregion

  //#region page-events

  /**
   * page init
   */
  ngOnInit() {
    document.querySelector('body').classList.remove('opened');
    if (this.parentKey === 'EMPLOYEE') {
      this.isParentEmployee = true;
    } else {
      this.isParentEmployee = false;
    }
    this.timetableService.getParent(this.isParentEmployee);

    this.timetableAddForm = this._fb.group({
      EmployeeId: ['', Validators.required],
      ProjectName: [],
      TypeOfWorkId: ['', Validators.required],
      StartDate: ['', Validators.required],
      EndDate: [''],
      StartTime: ['', Validators.required],
      EndTime: ['', Validators.required],
      BreakTime: [''],
      HoursSpent: [''],
      Status: [''],
      ApprovalUserId: ['', Validators.required],
      TimeTableApproverId: [''],
      Comments: [''],
      selectedEmployee: [[]],
      selectedTypeofwork: [[]],
      selectedpprovalperson: [[]],
    });
    this.isTimetableFormHidden = true;
  }

  // #endregion

  //#region Control-events

  /**
   * saving TimeTable
   */
  saveProceed() {
    this.loading = true;
    let timetable = <Timetable>this.timetableAddForm.value;
    // format the date to mm/dd/yyyy and time to HH:MM format
    timetable.Id = 0;
    timetable.StartDate = HelperService.formatDateFilter(
      this.timetableAddForm.value.StartDate.formatted
    );
    timetable.EndDate = HelperService.formatDateFilter(
      this.timetableAddForm.value.EndDate.formatted
    );
    timetable.StartTime = HelperService.formatTime(
      this.timetableAddForm.value.StartTime
    );
    timetable.EndTime = HelperService.formatTime(
      this.timetableAddForm.value.EndTime
    );
    timetable.HoursSpent = this.timetableAddForm.value.HoursSpent;
    timetable.BusinessId = BaseServices.BusinessId;
    let val: any;
    if (this.timetableAddForm.value.ProjectName) {
      val = this.timetableAddForm.value.ProjectName.split('_');
    }
    if (this.parentKey === 'EMPLOYEE') {
      let featureId: number;
      if (val) {
        if (val[0] === 'PROJECT') {
          featureId = Module.PROJECT;
        } else if (val[0] === 'FOODSAFETY') {
          featureId = Module.FOODSAFETY;
        }
        timetable.Featurekey = val[1] ? +val[1] : this.parentId; // project or zone feature key
        timetable.FeatureId = featureId ? featureId : this.parentKey; // // project or zone feature Id
      }
    } else {
      let featureId: number;
      if (this.parentKey == 'FS') {
        featureId = Module.FOODSAFETY;
      } else if (this.parentKey == 'IC') {
        featureId = Module.INTERNALCONTROL;
      } else if (this.parentKey == 'FDV') {
        featureId = Module.FIREDEVIATION;
      } else if (this.parentKey == 'PROJECT') {
        featureId = Module.PROJECT;
      } else if (this.parentKey == 'FRAMEWORK') {
        featureId = Module.FRAMEWORKAGREEMENT;
      } else if (this.parentKey == 'SA') {
        featureId = Module.SERVICEAGREEMENT;
      } else if (this.parentKey == 'KUNDE_EL') {
        featureId = Module.Kunde_EL;
      }
      timetable.FeatureId = featureId;
      timetable.Featurekey = this.parentId;
    }
    timetable.CreatedBy = BaseServices.UserId;
    timetable.ProjectId = val ? +val[1] : 0;
    timetable.Status = Status.Open;
    if (timetable) {
      this.subscriptions.push(
        this.timetableService
          .addTimetable(timetable, this.parentKey)
          .subscribe((IsTimeTableSaved) => {
            if (IsTimeTableSaved) {
              this.toasterComponent.callToast();
              this.loading = false;
            }
          })
      );
    }
    this.resetTimeTable();
  }

  resetTimeTable() {
    this.timetableAddForm.reset();
  }
  /**
   * to go TimeTable List
   */
  gotoBack() {
    this.location.back();
  }

  // #endregion

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
  //#region methods
  // #endregion
}
