export class DeviationList {
    Id: number;
    Title: string;
    Description: string;
    BusinessId: number;
    BusinessDepartmentId: number;
    BusinessDepartmentIds: number[];
    TopicId: number;
    CaseId: number;
    Importance: number;
    Cost: number;
    Deadline: Date;
    EditedBy: string;
    ExecutionUserId: number;
    FollowupUserId: number;
    Status: string;
    ImmediateAction: string;
    CauseAnalysis: string;
    CorrectiveAction: string;
    EffectOfAction: string;
    NotifyBeforeDeadLine: number;
    IsShowtoUsers: boolean;
    IsDeviationViewRights: boolean;
    IsSendOnlyDepartment: boolean;
    CompletedDate: Date;
    CompletedBy: number;
    ManualId: number;
    ManualChkCommentsId: number;
    DeviationNumber: number;
    DeviationNumberPrefix: string;
    CreatedBy: number;
    CreatedOn: Date;
    ModifiedBy: number;
    ModifiedOn: Date
    NotificationBeforeFollowup: number;
    ImageUrl: string;
    DateDiff: number;
    StatusIcon: string;
    ProjectName: string;
    TypeOfObject: string;
    Modules: string | null;
}
