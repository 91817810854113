<div style="display: flex; align-items: center; padding-top: 1rem">
  <a (click)="gotoList()" class="btn btn-icon" style="margin-right: 10px">
    <span class="icon ic-xs icon-back" aria-hidden="true"></span>
  </a>
  <div>
    <h5 translate>BACK_LIST</h5>
  </div>
</div>
<div class="page-title"></div>

<form class="form" [formGroup]="formDeviation">
  <div class="row">
    <!-- Left column -->
    <div class="col-sm-4 section">
      <!-- <div class="form-group">
    <label class="col-form-label">
      <span translate> SCHTATUS</span>
    </label>
    <input
      type="hidden"
      formControlName="ProjectId"
      value="1"
      class="form-control"
    />
    <div>
      <input
        type="text"
        formControlName="Status"
        name="status"
        class="form-control"
        disabled
      />
    </div>
  </div> -->

      <h4 translate>DEVIATION_INFORMATION</h4>
      <div class="form-group">
        <label for="title" class="col-form-label">
          <span translate>DEPARTMENT</span>
          <span class="required">*</span>
        </label>
        <div class="input-btn-group">
          <p-multiSelect
            formControlName="Department"
            [options]="Department"
            [style]="{ width: '100%' }"
            [(ngModel)]="selectedDepartments"
            [defaultLabel]="'SELECT' | translate"
          >
            <ng-template let-status pTemplate="item">
              {{ status.label | translate }}
            </ng-template>
          </p-multiSelect>
          <button
            *ngIf="isHideGuest"
            class="btn btn-outline-primary"
            type="button"
            (click)="showDepartment()"
          >
            <i class="pi pi-plus" aria-hidden="true"></i>
          </button>
        </div>
        <control-messages
          [control]="formDeviation.controls['Department']"
        ></control-messages>
      </div>
      <div class="form-group">
        <label for="title" class="col-form-label">
          <span translate>TOPICS</span>
          <span class="required">*</span>
        </label>
        <div class="input-btn-group">
          <p-dropdown formControlName="Topics" [options]="Topics">
            <ng-template let-status pTemplate="item">
              {{ status.label | translate }}
            </ng-template>
          </p-dropdown>
          <button
            *ngIf="isHideGuest"
            type="button"
            class="btn btn-outline-primary"
            (click)="addTopic = !addTopic"
          >
            <i class="pi pi-plus" aria-hidden="true"></i>
          </button>
        </div>
        <control-messages
          [control]="formDeviation.controls['Topics']"
        ></control-messages>
      </div>
      <div class="form-group">
        <label class="col-form-label">
          <span translate>TYPE_OF_CASE</span>
          <span class="required">*</span>
        </label>
        <div class="input-btn-group">
          <p-dropdown
            formControlName="Case"
            [options]="Case"
            [style]="{ width: '100%' }"
          >
            <ng-template let-status pTemplate="item">
              {{ status.label | translate }}
            </ng-template>
          </p-dropdown>
          <button
            *ngIf="isHideGuest"
            type="button"
            class="btn btn-outline-primary"
            (click)="showCase()"
          >
            <i class="pi pi-plus" aria-hidden="true"></i>
          </button>
        </div>
        <control-messages
          [control]="formDeviation.controls['Case']"
        ></control-messages>
      </div>

      <div class="form-group">
        <label for="title" class="col-form-label">
          <span translate>TITLE</span>
          <span class="required">*</span>
        </label>
        <div>
          <input
            type="text"
            class="label-input form-control"
            formControlName="Title"
            name="title"
            class="form-control"
          />
        </div>
        <control-messages
          [control]="formDeviation.controls['Title']"
        ></control-messages>
      </div>

      <div class="form-group">
        <label for="title" class="col-form-label">
          <span translate>DESCRIPTION</span>
        </label>
        <div>
          <textarea
            class="form-control"
            formControlName="Description"
            name="Description"
          ></textarea>
        </div>
      </div>
      <div class="form-group">
        <label for="title" class="col-form-label">
          <span translate>TIME_OF_EVENT</span>
        </label>
        <div style="display: flex; justify-content: space-between">
          <span style="width: 140px">
            <my-date-picker
              name="EventDate"
              [options]="eventDateOptions"
              formControlName="EventDate"
              (dateChanged)="enableClear()"
              placeholder="{{ 'SELECT_EVENT_DATE' | translate }}"
            ></my-date-picker>
          </span>
          <span style="width: 100px">
            <p-calendar
              [timeOnly]="true"
              readonlyInput="true"
              [style]="{ width: '100%' }"
              (onSelect)="enableClear()"
              formControlName="EventTime"
              styleClass="dateTimeField"
            >
            </p-calendar>
          </span>
          <span>
            <button
              type="button"
              class="btn btn-primary"
              (click)="bindEventDate()"
            >
              <span translate>NOW</span>
            </button>
            <button
              type="button"
              class="btn btn-outline-secondary"
              [disabled]="!IsClear"
              (click)="clearEventDate()"
            >
              <span translate>CLEAR</span>
            </button>
          </span>
        </div>
      </div>
      <div class="form-group">
        <label for="title" class="col-form-label">
          <span translate>EVENT_LOCATION</span>
        </label>
        <div>
          <input
            type="text"
            class="label-input form-control"
            formControlName="EventLocation"
            name="EventLocation"
          />
        </div>
      </div>

      <div class="form-group">
        <label for="immediate Action" class="col-form-label">
          <span translate>IMMEDIATE_ACTION</span>
        </label>
        <div>
          <textarea
            pInputTextarea
            [autoResize]="true"
            formControlName="ImmediateAction"
          ></textarea>
        </div>
      </div>
      <div class="form-group">
        <div class="tab-content">
          <ecpl-document-explorer
            [uploaderConfig]="upConfig"
            [uploadedFiles]="uploadedFiles"
            (saveChanges)="saveUploaded($event)"
          >
          </ecpl-document-explorer>
        </div>
      </div>
    </div>
    <!-- <div
      *ngIf="isShowDepartmentCheckbox"
      class="form-group"
      [hidden]="isHidden"
    >
      <label class="col-form-label">
        <p-checkbox
          formControlName="deparmentOnly"
          label="{{ 'SEND_ONLY_TO_DEPARTMENT' | translate }}"
          binary="true"
        ></p-checkbox>
        <span translate></span>
      </label>
      <div>
        <small translate>IF_UNCHECKED</small>
      </div>
    </div> -->
    <!-- <div *ngIf="isShowEditorCheckbox" class="form-group checkbox">
      <label class="col-form-label">
        <input
          type="checkbox"
          formControlName="SendonlytoEditors"
          name="checkbox"
        />
        <span translate>SEND_ONLY_TO_EDITORS</span>
      </label>
    </div> -->
    <!-- Middle column -->
    <div class="col-sm-4 section" *ngIf="showMore">
      <h4 translate>MANAGE_DEVIATION</h4>
      <div [hidden]="!showMore" class="form-group">
        <div class="form-group">
          <label for="deadline" class="control-label">
            <span translate>DEADLINE</span>
            <span class="required">*</span>
          </label>
          <div>
            <my-date-picker
              name="startdate"
              [options]="startDateOptions"
              formControlName="Deadline"
              placeholder="{{ 'SELECT_DEADLINE_DATE' | translate }}"
              (dateChanged)="onDeadLineChanged($event)"
            ></my-date-picker>
          </div>
          <control-messages
            [control]="formDeviation.controls['Deadline']"
          ></control-messages>
        </div>

        <div class="form-row form-group">
          <div class="form-group flex-item">
            <label for="importance" class="control-label">
              <span translate>IMPORTANCE_FULL</span>
              <span class="required">*</span>
            </label>
            <div>
              <p-dropdown
                formControlName="Importance"
                [style]="{ width: '100%' }"
                #importance
                [options]="importances"
              ></p-dropdown>
            </div>
          </div>
          <div class="form-group flex-item">
            <label for="cost" class="control-label">
              <span translate>COST</span>
            </label>
            <div>
              <input
                type="number"
                formControlName="Cost"
                pInputText
                (keypress)="onNumber($event, 15)"
              />
            </div>
            <control-messages
              [control]="formDeviation.controls['Cost']"
            ></control-messages>
          </div>
        </div>

        <div class="form-group">
          <label for="cause Analysis" class="control-label">
            <span translate>CAUSE_ANALYSIS</span>
          </label>
          <div>
            <textarea
              pInputTextarea
              autoResize="autoResize"
              formControlName="CauseAnalysis"
            ></textarea>
          </div>
        </div>
        <div class="form-group">
          <label for="corrective Action" class="control-label">
            <span translate>CORRECTIVE_ACTION</span>
          </label>
          <div>
            <textarea
              pInputTextarea
              autoResize="autoResize"
              formControlName="CorrectiveAction"
            ></textarea>
          </div>
        </div>
        <div class="form-group">
          <label for="effect of Action" class="control-label">
            <span translate>EFFECT_OF_ACTION</span>
          </label>
          <div>
            <textarea
              pInputTextarea
              autoResize="autoResize"
              formControlName="EffectOfAction"
            ></textarea>
          </div>
        </div>
        <div class="form-group">
          <label for="comment" class="control-label">
            <span translate>COMMENT</span>
          </label>
          <div>
            <textarea
              pInputTextarea
              autoResize="autoResize"
              formControlName="Comment"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
    <!-- Right column -->
    <div class="col-sm-4 section" *ngIf="showMore">
      <h4 translate>RESPONSIBILITY_AND_NOTIFICATIONS</h4>
      <div class="form-group">
        <label for="execution" class="control-label">
          <span translate>RESPONSIBLE_FOR_EXECUTION</span>
          <span class="required">*</span>
        </label>
        <div>
          <p-dropdown
            [options]="executions"
            formControlName="Execution"
            [style]="{ width: '100%' }"
            [filter]="true"
            #exec
            (onChange)="onExecChanged($event, exec)"
          ></p-dropdown>
        </div>
        <control-messages
          [control]="formDeviation.controls['Execution']"
        ></control-messages>
      </div>
      <div class="form-group">
        <label for="notification" class="control-label">
          <span translate>NOTIFICATION_BEFORE_DEADLINE_DEVIATION</span>
        </label>
        <div>
          <p-dropdown
            [options]="followUpNotify"
            formControlName="Notification"
            [style]="{ width: '100%' }"
            [(ngModel)]="selectedFollowup"
          ></p-dropdown>
        </div>
      </div>
      <div class="form-group">
        <label for="follow-up" class="control-label">
          <span translate>RESPONSIBLE_FOR_FOLLOW_UP</span>
          <span class="required">*</span>
        </label>
        <div>
          <p-dropdown
            [options]="followUps"
            [filter]="true"
            formControlName="Followup"
            [style]="{ width: '100%' }"
            #follow
            (onChange)="onFollowChanged($event, follow)"
          >
          </p-dropdown>
        </div>
        <control-messages
          [control]="formDeviation.controls['Followup']"
        ></control-messages>
      </div>
      <div class="form-group">
        <label for="notification" class="control-label">
          <span translate>NOTIFICATION_FOR_FOLLOW_UP_DAYS</span>
        </label>
        <div>
          <p-dropdown
            [options]="followUpNotify"
            formControlName="FollowupNotification"
            [style]="{ width: '100%' }"
            [(ngModel)]="selectedFollowup"
          ></p-dropdown>
        </div>
      </div>
    </div>
  </div>
  <div class="action-btns-wrapper">
    <a class="btn btn-outline-secondary" (click)="gotoList()">
      <span class="icon ic-xs icon-back"></span>
      <span translate>BACK_LIST</span>
    </a>
    <button
      class="btn btn-outline-primary"
      (click)="ShowMore(true)"
      *ngIf="!showMore"
    >
      <span translate>MANAGE_DEVIATION_NOW</span>
    </button>
    <button
      class="btn btn-outline-secondary"
      (click)="ShowMore(false)"
      *ngIf="showMore"
    >
      <span translate>CLOSE_MANAGING</span>
    </button>
    <button
      class="btn btn-primary"
      [ngClass]="{ loading: loading }"
      (click)="saveDeviation()"
      [disabled]="!formDeviation.valid || loading"
    >
      <span *ngIf="!loading" translate>SAVE</span>
      <span *ngIf="loading" translate>SAVE_PROCESS</span>
      <span *ngIf="loading" class="loader-icon"></span>
    </button>
  </div>
</form>
<p-dialog
  header="{{ 'ADD_CASE' | translate }}"
  [(visible)]="case"
  [style]="{ width: '300px' }"
  [resizable]="false"
  [draggable]="false"
  (onHide)="onBeforeCaseDialogHide()"
>
  <form [formGroup]="caseForm">
    <div class="form-group">
      <label for="title" class="col-form-label">
        <span translate>NAME</span>
      </label>
      <div>
        <input
          type="text"
          id="title"
          class="label-input form-control"
          [(ngModel)]="selectedCaseType.Name"
          formControlName="Name"
        />
      </div>
    </div>
    <p-footer>
      <div class="ui-dialog-buttonpane ui-widget-content ui-helper- mbtm-10">
        <button
          type="button"
          class="btn btn-outline-primary"
          (click)="hidingElement = !hidingElement"
          (click)="refreshCaseType()"
        >
          <span translate>VIEW</span>
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="saveCase()"
          [disabled]="!caseForm.valid"
        >
          <span translate>SAVE</span>
        </button>
      </div>
      <label
        for="title"
        color
        class="col-form-label"
        *ngIf="isCaseAllocated"
        [ngStyle]="{ color: 'red' }"
      >
        <span translate>CANNOT_DELETE</span>
      </label>
      <label
        for="title"
        color
        class="col-form-label"
        *ngIf="isCaseErrorDisabled"
        [ngStyle]="{ color: 'red' }"
      >
        <span translate>TYPE_EXIST</span>
      </label>
      <div *ngIf="hidingElement">
        <div class="table-view">
          <p-table
            #ct
            [value]="caseType"
            [rows]="5"
            [paginator]="true"
            [pageLinks]="3"
            [rowsPerPageOptions]="[5, 10, 20]"
            [responsive]="true"
          >
            <!-- Header -->
            <ng-template pTemplate="header">
              <tr>
                <th>{{ "CASE_NAME" | translate }}</th>
                <th></th>
              </tr>
              <tr>
                <th>
                  <input
                    pInputText
                    type="text"
                    (input)="
                      ct.filter($any($event.target)?.value, 'Name', 'contains')
                    "
                    placeholder=""
                    class="p-column-filter"
                  />
                </th>
                <th></th>
              </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-Type>
              <tr>
                <td>
                  <span>{{ Type.Name }}</span>
                </td>
                <td class="col-button">
                  <button
                    type="button"
                    class="btn btn-icon"
                    title="{{ 'EDIT' | translate }}"
                    (click)="editCase(Type)"
                  >
                    <i class="icon ic-sm icon-pencil-tip"></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-icon"
                    title="{{ 'DELETE' | translate }}"
                    (click)="deleteCase(Type['Id'])"
                  >
                    <i class="icon ic-sm icon-trash"></i>
                  </button>
                </td>
              </tr>
            </ng-template>
            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" let-columns>
              <tr>
                <td colspan="2">
                  {{ "NO_RECORDS_FOUND" | translate }}
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </p-footer>
  </form>
</p-dialog>
<p-dialog
  header="{{ 'ADD_DEPARTMENT' | translate }}"
  [(visible)]="department"
  (onHide)="onBeforeCaseDialogHide()"
  [style]="{ width: '300px' }"
  [resizable]="false"
  [draggable]="false"
>
  <form [formGroup]="departmentForm">
    <div class="form-group">
      <label for="title" class="col-form-label">
        <span translate>NAME</span>
      </label>
      <div>
        <input
          type="text"
          [(ngModel)]="selectedDepartment.Name"
          class="label-input form-control"
          formControlName="Name"
        />
      </div>
    </div>
  </form>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
      <button
        type="button"
        class="btn btn-outline-primary"
        (click)="hidesElement = !hidesElement"
        (click)="viewDepartment()"
      >
        <span translate>VIEW</span>
      </button>
      <button
        type="submit"
        class="btn btn-primary"
        (click)="saveDepartment()"
        [disabled]="!departmentForm.valid"
      >
        <span translate>SAVE</span>
      </button>
    </div>
    <label
      for="title"
      color
      class="col-form-label"
      *ngIf="isDepartmentAllocated"
      [ngStyle]="{ color: 'red' }"
    >
      <span translate>CANNOT_DELETE</span>
    </label>
    <label
      for="title "
      color
      class="col-form-label"
      *ngIf="isDepartmentErrorDisabled"
      [ngStyle]="{ color: 'red' }"
    >
      <span translate>DEPARTMENT_EXIST</span>
    </label>

    <div *ngIf="hidesElement">
      <div class="table-view">
        <p-table
          #et
          [value]="departmentdetails"
          [rows]="5"
          [paginator]="true"
          [pageLinks]="3"
          [rowsPerPageOptions]="[5, 10, 20]"
          [responsive]="true"
        >
          <!-- Header -->
          <ng-template pTemplate="header">
            <tr>
              <th>{{ "DEPARTMENT_NAME" | translate }}</th>
              <th></th>
            </tr>
            <tr>
              <th>
                <input
                  pInputText
                  type="text"
                  (input)="
                    et.filter($any($event.target)?.value, 'Name', 'contains')
                  "
                  placeholder=""
                  class="p-column-filter"
                />
              </th>
              <th></th>
            </tr>
          </ng-template>
          <!-- Body Content -->
          <ng-template pTemplate="body" let-Type>
            <tr>
              <td>
                <span>{{ Type.Name }}</span>
              </td>
              <td class="col-button">
                <button
                  type="button"
                  class="btn btn-icon"
                  title="{{ 'EDIT' | translate }}"
                  (click)="editDepartment(Type)"
                >
                  <i class="icon ic-sm icon-pencil-tip"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-icon"
                  title="{{ 'DELETE' | translate }}"
                  (click)="deleteDepartment(Type)"
                >
                  <i class="icon ic-sm icon-trash"></i>
                </button>
              </td>
            </tr>
          </ng-template>
          <!-- Empty message -->
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td colspan="2">
                {{ "NO_RECORDS_FOUND" | translate }}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </p-footer>
</p-dialog>
<p-dialog
  header="{{ 'ADD_TOPIC' | translate }}"
  [(visible)]="addTopic"
  [style]="{ width: '300px' }"
  [resizable]="false"
  [draggable]="false"
>
  <form [formGroup]="topicForm">
    <div class="form-group">
      <label for="title" class="col-form-label">
        <span translate>NAME</span>
      </label>
      <div>
        <input
          type="text"
          formControlName="Name"
          #Title
          class="label-input form-control"
        />
      </div>
    </div>
    <label
      for="title"
      color
      class="col-form-label"
      *ngIf="isTopicAllocated"
      [ngStyle]="{ color: 'red' }"
    >
      <span translate>CANNOT_DELETE</span>
    </label>
    <label
      for="title "
      color
      class="col-form-label"
      *ngIf="isTopicErrorDisabled"
      [ngStyle]="{ color: 'red' }"
    >
      <span translate>TOPIC_EXIST</span>
    </label>
  </form>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
      <!-- <button type="button" class="btn btn-warning" (click)="addTopic=viewTopic=false"><span translate>Close</span></button> -->
      <button
        type="button"
        class="btn btn-outline-primary"
        (click)="viewTopic = !viewTopic"
      >
        <span translate>VIEW</span>
      </button>
      <button
        type="button"
        class="btn btn-primary"
        [disabled]="!topicForm.valid"
        (click)="saveTopic()"
      >
        <span translate>SAVE</span>
      </button>
    </div>
    <div *ngIf="viewTopic">
      <div class="table-view">
        <p-table
          #et
          [value]="editTopics"
          [rows]="10"
          [paginator]="true"
          [pageLinks]="3"
          [rowsPerPageOptions]="[5, 10, 20]"
          [responsive]="true"
        >
          <!-- Header -->
          <ng-template pTemplate="header">
            <tr>
              <th>{{ "TYPE_NAME" | translate }}</th>
              <th></th>
            </tr>
            <tr>
              <th>
                <input
                  pInputText
                  type="text"
                  (input)="
                    et.filter($any($event.target)?.value, 'Name', 'contains')
                  "
                  placeholder=""
                  class="p-column-filter"
                />
              </th>
              <th></th>
            </tr>
          </ng-template>
          <!-- Body Content -->
          <ng-template pTemplate="body" let-Type>
            <tr>
              <td>
                <span>{{ Type.Name }}</span>
              </td>
              <td class="col-button">
                <button
                  type="button"
                  class="btn btn-icon"
                  title="{{ 'EDIT' | translate }}"
                  (click)="editTopic(Type)"
                >
                  <i class="icon ic-sm icon-pencil-tip"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-icon"
                  title="{{ 'DELETE' | translate }}"
                  (click)="deleteTopic(Type.Id)"
                >
                  <i class="icon ic-sm icon-trash"></i>
                </button>
              </td>
            </tr>
          </ng-template>
          <!-- Empty message -->
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td colspan="2">
                {{ "NO_RECORDS_FOUND" | translate }}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </p-footer>
</p-dialog>
<p-confirmDialog
  header="{{ 'CONFIRMATION' | translate }}"
  icon="pi pi-question-circle"
  width="425"
  acceptLabel="{{ 'SWITCH_YES' | translate }}"
  rejectLabel="{{ 'SWITCH_NO' | translate }}"
></p-confirmDialog>
<toaster-component></toaster-component>
<div class="panel-loader" *ngIf="loading"></div>
