<div class="tab-content">
  <!-- <div class="page-title">
  
  </div> -->
  <div [formGroup]="riskDepartmentDropDown">
    <div class="row">
      <div class="col-md-3" *ngIf="isAdmin">
        <div class="form-group">
          <label for="lblDepartment" class="col-form-label">
            <span translate>CHOOSE_BUSINESS</span>
          </label>
          <div>
            <p-dropdown
              [options]="Business"
              [filter]="true"
              [style]="{ width: '160px' }"
              formControlName="businessId"
              (onChange)="onBusinessChanged($event, businessDropdown)"
              #businessDropdown
            ></p-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Approved all notices  datatable-->
  <div class="" *ngIf="!isAdmin">
    <div class="row">
      <div class="col-md-8">
        <h4 translate>RISK_ANALYSIS</h4>
        <app-riskanalysis-list-chart [riskDetails]="riskDetails">
        </app-riskanalysis-list-chart>
      </div>
      <div class="col-md-4">
        <h4 translate>CONFIRM_READ</h4>
        <!-- Table starts -->
        <p-table
          [value]="approvalNotices"
          dataKey="Name"
          #approvalTable
          expandableRows="true"
          [rows]="5"
          [paginator]="true"
          [pageLinks]="3"
          [rowsPerPageOptions]="[5, 10, 20]"
        >
          <!-- Header -->
          <ng-template pTemplate="header">
            <tr>
              <th></th>
              <th>{{ "EMPLOYEE_NAME" | translate }}</th>
              <th>{{ "STATUS" | translate }}</th>
            </tr>
          </ng-template>
          <!-- Body Content -->
          <ng-template
            pTemplate="body"
            let-risk
            let-expanded="expanded"
            let-i="rowIndex"
          >
            <tr>
              <td>
                <a (click)="approvalTable.toggleRow(risk)" class="btn btn-icon">
                  <span
                    class="icon ic-sm icon-expand-arrow"
                    aria-hidden="true"
                    *ngIf="setCollapse(i)"
                    (click)="toggleClick(i)"
                  ></span>
                  <span
                    class="icon ic-sm icon-collapse-arrow"
                    aria-hidden="true"
                    *ngIf="!setCollapse(i)"
                    (click)="toggleClickHide()"
                  ></span>
                </a>
              </td>
              <td>
                <span>{{ risk.Name }}</span>
              </td>
              <td>
                <span>{{ risk.Count }}/{{ totalIncidents }}</span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="rowexpansion" let-risk>
            <tr>
              <td colspan="3">
                <div>
                  <!-- Table starts -->
                  <p-table
                    [value]="risk.RiskAnalysis"
                    [rows]="5"
                    [showCurrentPageReport]="true"
                    currentPageReportTemplate="{{
                      'SUMMARY_TOTALRECORDS' | translate
                    }}"
                  >
                    <ng-template pTemplate="header">
                      <tr>
                        <th>{{ "INCIDENT_STRESS" | translate }}</th>
                        <th>{{ "LOCATION" | translate }}</th>
                        <th>{{ "APPROVED_DATE" | translate }}</th>
                        <th>{{ "EDITED_ON" | translate }}</th>
                      </tr>
                    </ng-template>
                    <!-- Body Content -->
                    <ng-template pTemplate="body" let-risks>
                      <tr>
                        <td>
                          <span>{{ risks.Title }}</span>
                        </td>
                        <td>
                          <span>{{ risks.GPSLocation }}</span>
                        </td>
                        <td>
                          <span
                            [style.color]="risks.ApprovedOn ? 'Green' : 'black'"
                            >{{
                              risks.ApprovedOn
                                ? (risks.ApprovedOn | date : "dd/MM/yyyy")
                                : ("UNAPPROVED" | translate)
                            }}</span
                          >
                        </td>
                        <td>
                          <span
                            [style.color]="
                              risks.ApprovedOn
                                ? risks.ModifiedOn
                                  ? 'black'
                                  : 'Green'
                                : 'red'
                            "
                            >{{ risks.ModifiedOn | date : "dd/MM/yyyy" }}</span
                          >
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <!-- Table ends -->
      </div>
    </div>
  </div>
  <!--Approved all notices  datatable ends-->
  <div class="row">
    <div class="col-md-12">
      <div class="page-title">
        <h4 translate>RISK_FACTORS</h4>
        <span class="page-actions" *ngIf="isAdministrateRiskAnalysis">
          <button
            *ngIf="!isAdmin"
            type="button"
            class="btn btn-success"
            (click)="gotoNew()"
          >
            <span class="icon ic-sm icon-add"></span>
            <span translate>NEW</span>
          </button>
        </span>
      </div>

      <div class="custom table-view mbtm-30">
        <!-- Table starts -->
        <p-table
          [tableStyle]="{ overflow: 'hidden' }"
          [value]="allIncidents"
          #riskTable
          dataKey="Category"
          expandableRows="true"
          [rows]="50"
          [paginator]="true"
          [pageLinks]="3"
          [rowsPerPageOptions]="[50, 100, 200]"
          [(selection)]="selectedRisks"
          [showCurrentPageReport]="true"
          currentPageReportTemplate="{{ 'SUMMARY_TOTALRECORDS' | translate }}"
        >
          <!-- Header -->
          <ng-template pTemplate="caption">
            <div class="ui-helper-clearfix">
              <div class="filter-controls-wrapper" style="display: none">
                <div class="row">
                  <div class="col-sm-3">
                    <div class="form-group">
                      <label for="lblDepartment" class="control-label">
                        <span translate>DEPARTMENT</span>
                      </label>
                      <div>
                        <p-dropdown
                          [options]="departments"
                          [filter]="true"
                          [style]="{ width: '160px' }"
                          (onChange)="
                            onFilterValueChanged(
                              $event,
                              departmentDropdown,
                              'department'
                            )
                          "
                          #departmentDropdown
                        ></p-dropdown>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                      <label for="lblDepartment" class="control-label">
                        <span translate>TOPICS</span>
                      </label>
                      <div>
                        <p-dropdown
                          [options]="Topics"
                          [filter]="true"
                          [style]="{ width: '160px' }"
                          (onChange)="
                            onFilterValueChanged($event, topicDropdown, 'topic')
                          "
                          #topicDropdown
                        ></p-dropdown>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                      <label class="control-label">
                        <span translate>SORTING</span>
                      </label>
                      <div>
                        <p-dropdown
                          [style]="{ width: '160px' }"
                          type="text"
                          (onChange)="
                            onFilterValueChanged(
                              $event,
                              sortingDropdown,
                              'sorting'
                            )
                          "
                          [options]="sorting"
                          #sortingDropdown
                        ></p-dropdown>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                      <label class="control-label">
                        <span translate
                          >{{ "INCIDENT_STRESS" | translate }}
                        </span>
                      </label>
                      <div>
                        <input
                          type="text"
                          pInputText
                          #dt
                          (keyup)="onIncidentChanged(dt)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 55px">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
              </th>
              <th style="width: 60px"></th>
              <th pSortableColumn="Category">
                {{ "CATEGORY" | translate }}
                <p-sortIcon field="Category"></p-sortIcon>
              </th>
              <!-- <th>{{ "RISK_FIGURE" | translate }}</th> -->
            </tr>
            <tr>
              <th></th>
              <th></th>
              <th>
                <div class="row input-filter-container">
                  <div class="col-sm">
                    <input
                      pInputText
                      type="text"
                      (input)="
                        riskTable.filter(
                          $any($event.target)?.value,
                          'Category',
                          'contains'
                        )
                      "
                      placeholder=""
                      class="form-control p-column-filter"
                    />
                  </div>
                  <div class="col-sm-1">
                    <button class="btn btn-link filter-toggle">
                      <span class="icon ic-sm icon-filter"></span>
                      <span translate>FILTER</span>
                    </button>
                  </div>
                </div>
              </th>
            </tr>
          </ng-template>
          <!-- Body Content -->
          <ng-template
            pTemplate="body"
            let-risk
            let-expanded="expanded"
            let-i="rowIndex"
          >
            <tr>
              <td style="width: 55px">
                <p-tableCheckbox id="selectedOrders" [value]="risk">
                </p-tableCheckbox>
              </td>
              <td>
                <a (click)="riskTable.toggleRow(risk)" class="btn btn-icon">
                  <span
                    class="icon ic-sm icon-expand-arrow"
                    aria-hidden="true"
                    *ngIf="setCollapse(i)"
                    (click)="toggleClick(i)"
                  ></span>
                  <span
                    class="icon ic-sm icon-collapse-arrow"
                    aria-hidden="true"
                    *ngIf="!setCollapse(i)"
                    (click)="toggleClickHideRisk()"
                  ></span>
                </a>
                {{ risk.IncidentList.length }}
              </td>
              <td>
                <span>{{ risk.Category | translate }}</span>
              </td>
              <!-- <td></td> -->
            </tr>
          </ng-template>

          <ng-template pTemplate="rowexpansion" let-risk>
            <tr>
              <td colspan="3">
                <div>
                  <!-- Table starts -->
                  <p-table
                    #td
                    [value]="risk.IncidentList"
                    dataKey="Id"
                    [responsive]="true"
                    [(selection)]="selectedRisks"
                    [rows]="5"
                  >
                    <!-- Header -->
                    <ng-template pTemplate="header">
                      <tr>
                        <th style="width: 35px">
                          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th style="width: 35px"></th>
                        <th style="width: 35px"></th>
                        <th pSortableColumn="Title">
                          {{ "INCIDENT_STRESS" | translate }}
                          <p-sortIcon field="Title"></p-sortIcon>
                        </th>
                        <th pSortableColumn="Location">
                          {{ "LOCATION" | translate }}
                          <p-sortIcon field="Location"></p-sortIcon>
                        </th>
                        <th pSortableColumn="Topics">
                          {{ "TOPICS" | translate }}
                          <p-sortIcon field="Topics"></p-sortIcon>
                        </th>
                        <th pSortableColumn="ValueText">
                          {{ "RISK_FIGURE" | translate }}
                          <p-sortIcon field="ValueText"></p-sortIcon>
                        </th>
                        <th pSortableColumn="Description">
                          {{ "WHAT_CAN_HAPPEN" | translate }}
                          <p-sortIcon field="Description"></p-sortIcon>
                        </th>
                        <th pSortableColumn="Alreadyactiontaken">
                          {{ "ACTION_ALREADY_TAKEN" | translate }}
                          <p-sortIcon field="Alreadyactiontaken"></p-sortIcon>
                        </th>
                        <th pSortableColumn="CreatedOn">
                          {{ "CREATED_ON" | translate }}
                          <p-sortIcon field="CreatedOn"></p-sortIcon>
                        </th>
                        <th pSortableColumn="ModifiedOn">
                          {{ "EDITED_ON" | translate }}
                          <p-sortIcon field="ModifiedOn"></p-sortIcon>
                        </th>
                        <th pSortableColumn="ApprovalStatus">
                          {{ "CONFIRM_READ" | translate }}
                          <p-sortIcon field="ApprovalStatus"></p-sortIcon>
                        </th>
                        <th *ngIf="!isGuest && !isAdmin">
                          {{ "OPTIONS" | translate }}
                        </th>
                      </tr>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>
                          <input
                            pInputText
                            type="text"
                            (input)="
                              td.filter(
                                $event.target.value,
                                'Title',
                                'contains'
                              )
                            "
                            placeholder=""
                            class="p-column-filter"
                          />
                        </th>
                        <th>
                          <input
                            pInputText
                            type="text"
                            (input)="
                              td.filter(
                                $event.target.value,
                                'Location',
                                'contains'
                              )
                            "
                            placeholder=""
                            class="p-column-filter"
                          />
                        </th>
                        <th>
                          <p-dropdown
                            [options]="Topics"
                            (onChange)="onTopicChange($event, dd)"
                            #dd
                            styleClass="p-column-filter"
                            placeholder="Select"
                            [filterBy]="null"
                            [showClear]="true"
                          >
                            <ng-template let-status pTemplate="item">
                              {{ status.label | translate }}
                            </ng-template>
                          </p-dropdown>
                        </th>
                        <th>
                          <p-dropdown
                            [options]="Riskdata"
                            (onChange)="onRiskChanged($event, ed)"
                            #ed
                            styleClass="p-column-filter"
                            placeholder="Select"
                            [filterBy]="null"
                            [showClear]="true"
                          >
                            <ng-template let-status pTemplate="item">
                              {{ status.label | translate }}
                            </ng-template>
                          </p-dropdown>
                        </th>
                        <th>
                          <input
                            pInputText
                            type="text"
                            (input)="
                              td.filter(
                                $event.target.value,
                                'Description',
                                'contains'
                              )
                            "
                            placeholder=""
                            class="p-column-filter"
                          />
                        </th>
                        <th>
                          <input
                            pInputText
                            type="text"
                            (input)="
                              td.filter(
                                $event.target.value,
                                'Alreadyactiontaken',
                                'contains'
                              )
                            "
                            placeholder=""
                            class="p-column-filter"
                          />
                        </th>
                        <th></th>
                        <th></th>
                        <th>
                          <p-dropdown
                            [options]="Approval"
                            (onChange)="onApprovalChange($event, fd)"
                            #fd
                            styleClass="p-column-filter"
                            placeholder="Select"
                            [filterBy]="null"
                            [showClear]="true"
                          >
                            <ng-template let-status pTemplate="item">
                              {{ status.label | translate }}
                            </ng-template>
                          </p-dropdown>
                        </th>
                        <th *ngIf="!isGuest && !isAdmin"></th>
                      </tr>
                    </ng-template>
                    <!-- Body Content -->
                    <ng-template pTemplate="body" let-risks>
                      <tr>
                        <td style="width: 55px">
                          <p-tableCheckbox id="selectedOrders" [value]="risks">
                          </p-tableCheckbox>
                        </td>
                        <td style="width: 35px">
                          <div *ngIf="risks.StatusIcon != 'null'">
                            <i class="{{ risks.StatusIcon }}"></i>
                          </div>
                        </td>
                        <td style="width: 35px">
                          <div *ngIf="risks.StatusIcon != 'null'">
                            <a
                              class="icon-task-planning"
                              href="javascript:void(0)"
                              (click)="ActionPlanDialog(risks.Id)"
                            >
                            </a>
                          </div>
                        </td>
                        <td *ngIf="isGuest || isPortal">
                          <span
                            pTooltip="{{ risks.Title }}"
                            tooltipPosition="top"
                            >{{ risks.Title }}</span
                          >
                        </td>
                        <td *ngIf="!(isGuest || isPortal)">
                          <a
                            href="javascript:void(0)"
                            (click)="RiskSchemeDialog(risks.Id)"
                            pTooltip="{{ risks.Title }}"
                            tooltipPosition="top"
                          >
                            {{ risks.Title }}
                          </a>
                        </td>
                        <td>
                          <span>{{ risks.Location }}</span>
                        </td>
                        <td>
                          <span>{{ risks.Topics }}</span>
                        </td>
                        <td>
                          <span
                            class="badge"
                            translate
                            [style.background-color]="risks.BackColor"
                            >{{ risks.ValueText }}
                            <span>{{ risks.Value }}</span>
                          </span>
                        </td>
                        <td>
                          <span>{{ risks.Description }}</span>
                        </td>
                        <td>
                          <span>{{ risks.Alreadyactiontaken }}</span>
                        </td>
                        <td>
                          <span>{{
                            risks.CreatedOn | date : "dd/MM/yyyy"
                          }}</span>
                        </td>
                        <td>
                          <span>{{
                            risks.ModifiedOn | date : "dd/MM/yyyy"
                          }}</span>
                        </td>
                        <td>
                          <span
                            [style.color]="
                              risks.ApprovalStatus == 'Approved'
                                ? 'Green'
                                : 'black'
                            "
                          >
                            {{
                              (risks.ApprovalStatus == "Approved"
                                ? "APPROVED"
                                : "UNAPPROVED"
                              ) | translate
                            }}</span
                          >
                        </td>
                        <td class="col-button" *ngIf="!isGuest && !isAdmin">
                          <a
                            [routerLink]="['./edit-risk', risks.Id]"
                            class="btn btn-icon"
                            title="{{ 'EDIT' | translate }}"
                          >
                            <i class="icon ic-sm icon-pencil-tip"></i>
                          </a>
                          <button
                            type="button"
                            class="btn btn-icon"
                            title="{{ 'DELETE' | translate }}"
                            (click)="confirm(risks.Id)"
                          >
                            <i class="icon ic-sm icon-trash"></i>
                          </button>
                        </td>
                      </tr>
                    </ng-template>
                    <!-- Empty message -->
                    <ng-template pTemplate="emptymessage" let-columns>
                      <tr>
                        <td colspan="12">
                          {{ "NO_RECORDS_FOUND" | translate }}
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                  <!-- Table ends -->
                </div>
              </td>
            </tr>
          </ng-template>
          <!-- Empty message -->
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td colspan="4">
                {{ "NO_RECORDS_FOUND" | translate }}
              </td>
            </tr>
          </ng-template>
          <!-- Footer -->
          <ng-template pTemplate="summary">
            <div class="footer-data">
              <div class="symbol-descriptions">
                <div class="symbol-desc-title">
                  <span translate>DESCRIPTION_SYMBOLS</span>
                </div>
                <div class="symbol-desc-items">
                  <span>
                    <i class="icon ic-md icon-clear-deadline"></i>
                    <span translate>ACTION_DONE</span>
                  </span>
                  <span>
                    <i class="icon ic-md icon-far-deadline"></i>
                    <span translate>ACTION_NOT_PERFORMED</span>
                  </span>
                  <span class="text-primary">
                    <i class="icon ic-md icon-task-planning"></i>
                    <span translate>RISK_ACTION</span>
                  </span>
                </div>
              </div>
            </div>
          </ng-template>
        </p-table>
        <!-- Table ends -->
      </div>
      <div class="mbtm-30 action-btns-wrapper">
        <button
          type="button"
          class="btn btn-primary"
          (click)="saveApprovalNotice()"
          *ngIf="!IsApproveButton"
        >
          <span translate>CONFIRM_READ_BUTTON</span>
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="showDialog()"
          *ngIf="IsGenerateReport || IncidentList.length > 0"
        >
          <span translate>GENERATE_REPORT</span>
        </button>
        <button
          [ngClass]="{ loading: loadingMail }"
          type="button"
          class="btn btn-warning"
          (click)="sendReportMail()"
          *ngIf="IsSendEmail"
          [disabled]="!sendMail || loadingMail"
        >
          <span translate>SEND_MAIL</span>
          <span *ngIf="loadingMail" class="loader-icon"></span>
        </button>
        <button
          type="button"
          class="btn btn-outline-primary"
          (click)="toggleStaticData()"
        >
          <span translate>TOGGLE_GUIDE</span>
        </button>
        <br />

        <span *ngIf="IsSendEmail" style="color: red" translate class="pt-1"
          >SEND_EMAIL_NOTIFICATION</span
        >

        <!-- <div *ngIf="(IsSendEmail || IncidentList.length > 0)" style="padding:5px 0 0 0; ;">
        <span translate>SEND_EMAIL_NOTIFICATION</span>
      </div> -->
      </div>
    </div>
  </div>
  <!--Risk Analysis datatable ends-->

  <!--static data component-->
  <div class="row">
    <div class="col-md-12">
      <div *ngIf="showStaticData">
        <staticdata id="staticData"></staticdata>
      </div>
    </div>
  </div>

  <!--static data component ends-->
  <!--department p-dialog box-->

  <p-dialog
    header="{{ 'ADD_DEPARTMENT' | translate }}"
    [(visible)]="addType"
    [style]="{ width: '300px' }"
    [resizable]="false"
    [draggable]="false"
    (onHide)="onBeforeRiskDepartmentDialogHide()"
  >
    <form [formGroup]="riskAnalysisDepartment">
      <div class="form-group">
        <label for="title" class="col-form-label">
          <span translate>DEPARTMENT</span>
        </label>
        <div>
          <input
            type="text"
            #Name
            class="label-input form-control"
            formControlName="department"
          />
          <span style="color: red" *ngIf="isdepartmentExists">{{
            departmentExisting
          }}</span>
          <span style="color: red" *ngIf="isNameExists">{{
            existsAlerttext
          }}</span>
        </div>
      </div>
    </form>
    <p-footer>
      <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
        <button
          type="button"
          class="btn btn-outline-primary"
          (click)="viewType = !viewType"
        >
          <span translate>VIEW</span>
        </button>
        <button
          type="button"
          class="btn btn-primary"
          [disabled]="!riskAnalysisDepartment.valid"
          (click)="saveDept()"
        >
          <span translate>SAVE</span>
        </button>
      </div>
      <div *ngIf="viewType">
        <div class="table-view">
          <!-- Table starts -->
          <p-table
            [value]="editDepartments"
            [rows]="10"
            [paginator]="true"
            [pageLinks]="3"
            [rowsPerPageOptions]="[5, 10, 20]"
          >
            <!-- Header -->
            <ng-template pTemplate="header">
              <tr>
                <th>{{ "TYPE_NAME" | translate }}</th>
                <th></th>
              </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-riskdept>
              <tr>
                <td>
                  <span>{{ riskdept.Name }}</span>
                </td>
                <td class="col-button">
                  <button
                    type="button"
                    class="btn btn-icon"
                    title="{{ 'EDIT' | translate }}"
                    (click)="editDeptType(riskdept)"
                  >
                    <i class="icon ic-sm icon-pencil-tip"></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-icon"
                    title="{{ 'DELETE' | translate }}"
                    (click)="deleteDept(riskdept)"
                  >
                    <i class="icon ic-sm icon-trash"></i>
                  </button>
                </td>
              </tr>
            </ng-template>
          </p-table>
          <!-- Table Ends -->
        </div>
      </div>
    </p-footer>
  </p-dialog>
  <p-confirmDialog
    header="{{ 'CONFIRMATION' | translate }}"
    icon="pi pi-question-circle"
    width="425"
    acceptLabel="{{ 'SWITCH_YES' | translate }}"
    rejectLabel="{{ 'SWITCH_NO' | translate }}"
  ></p-confirmDialog>
  <toaster-component></toaster-component>
  <p-dialog
    header="{{ 'NOTIFICATION' | translate }}"
    [(visible)]="showNotification"
    [style]="{ width: '600px' }"
    [resizable]="false"
    [draggable]="false"
    [responsive]="true"
    translate
  >
    <span translate>SELECT_ONERECORD</span>
  </p-dialog>
  <!--Panel Loader-->
  <p-dialog
    header="{{ 'ACTION_PLANS' | translate }}"
    [(visible)]="showActionPlan"
    [style]="{ width: '1000px' }"
    [resizable]="false"
    [draggable]="false"
    (onHide)="closePopUp()"
    [contentStyle]="{ 'min-height': '300px' }"
  >
    <p-table [value]="ActionPlanList" [rows]="5">
      <!-- Header -->
      <ng-template pTemplate="header">
        <tr>
          <th>{{ "ACTION_NAME" | translate }}</th>
          <th>{{ "DESCRIPTION" | translate }}</th>
          <th>{{ "GOAL" | translate }}</th>
          <th>{{ "STATUS" | translate }}</th>
          <th>{{ "DEADLINE" | translate }}</th>
          <th>{{ "RESPONSIBLE_PERSON" | translate }}</th>
          <th>{{ "FOLLOWUP_PERSON" | translate }}</th>
        </tr>
      </ng-template>
      <!-- Body Content -->
      <ng-template pTemplate="body" let-risk>
        <tr>
          <td>
            <span>{{ risk.Title }}</span>
          </td>
          <td>
            <span>{{ risk.Description | translate }}</span>
          </td>
          <td>
            <span>{{ risk.Goal }}</span>
          </td>
          <td>
            <span>{{ risk.StatusText | translate }}</span>
          </td>
          <td>
            <span>{{ risk.Deadline | date : "dd/MM/yyyy" }}</span>
          </td>
          <td>
            <span>{{ risk.ResponsiblePerson }}</span>
          </td>
          <td>
            <span>{{ risk.FollowupPerson }}</span>
          </td>
        </tr>
      </ng-template>
      <!-- Footer -->
      <ng-template pTemplate="summary">
        <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
          <button
            type="button"
            class="btn btn-outline-secondary"
            (click)="closePopUp()"
          >
            <span translate>CLOSE</span>
          </button>
        </div>
      </ng-template>
    </p-table>
    <!-- Table Ends -->
  </p-dialog>
  <p-dialog
    header="{{ 'RISK_SCHEME' | translate }}"
    [(visible)]="showRiskScheme"
    [modal]="true"
    [style]="{ width: '600px' }"
    [resizable]="false"
    [draggable]="false"
    translate
  >
    <form [formGroup]="riskForm">
      <div class="form-group">
        <div class="form-group">
          <label for="lblCategory" class="col-form-label">
            <span translate>CATEGORY</span>
          </label>
          <div class="input-btn-group">
            <input
              [readonly]="true"
              type="text"
              formControlName="CategoryId"
              pInputText
            />
          </div>
        </div>
        <div class="form-group">
          <label for="lblIncidentStress" class="col-form-label">
            <span translate>INCIDENT_STRESS</span>
          </label>
          <div class="input-btn-group">
            <input
              [readonly]="true"
              type="text"
              id="txtIncidentStress"
              formControlName="Title"
              pInputText
            />
          </div>
        </div>
        <div class="form-group">
          <label for="lblLocation" class="col-form-label">
            <span translate>LOCATION</span>
          </label>
          <div class="input-btn-group">
            <input
              [readonly]="true"
              type="text"
              id="txtLocation"
              formControlName="Location"
              pInputText
              name="Location"
            />
          </div>
        </div>
        <div class="form-group">
          <label for="lblTopics" class="col-form-label">
            <span translate>TOPICS</span>
          </label>
          <div class="input-btn-group">
            <p-dropdown
              [readonly]="true"
              [options]="Topics"
              type="text"
              formControlName="TopicId"
            >
            </p-dropdown>
          </div>
        </div>
        <div class="form-group">
          <label for="lblDepartment" class="col-form-label">
            <span translate>DEPARTMENT</span>
          </label>
          <div class="input-btn-group" style="display: flex">
            <p-multiSelect
              [disabled]="true"
              [options]="departments"
              formControlName="DepartmentId"
              [style]="{ width: '336px' }"
              [defaultLabel]="'CHOOSE' | translate"
            ></p-multiSelect>
          </div>
        </div>
        <div class="form-group">
          <label class="col-form-label">
            <span translate>SORTING</span>
          </label>
          <div class="input-btn-group">
            <input
              [readonly]="true"
              pInputTextarea
              type="text"
              formControlName="Sorting"
            />
          </div>
        </div>
        <div class="form-group">
          <label for="lblWhathappend" class="col-form-label">
            <span translate>WHAT_CAN_HAPPEN</span>
          </label>
          <div class="input-btn-group">
            <textarea
              [readonly]="true"
              type="text"
              id="txtariaWhatHappend"
              pInputTextarea
              formControlName="ExpectedBehaviour"
            >
            </textarea>
          </div>
        </div>
        <div class="form-group">
          <label for="lblRelatedIncident" class="col-form-label">
            <span translate>RELATED_INCIDENT</span>
          </label>
          <div class="input-btn-group">
            <input
              type="text"
              [readonly]="true"
              id="txtRelatedIncident"
              formControlName="RelatedIncident"
              pInputText
            />
          </div>
        </div>
        <div class="form-group">
          <label for="lblActionTaken" class="col-form-label">
            <span translate>ACTION_ALREADY_TAKEN</span>
          </label>
          <div class="input-btn-group">
            <textarea
              type="text"
              [readonly]="true"
              id="txtariaActionTaken"
              formControlName="Alreadyactiontaken"
              pInputTextarea
            >
            </textarea>
          </div>
        </div>
        <div class="form-group">
          <label for="lblProbability" class="col-form-label">
            <span translate>PROBABILITY_FIGURE</span>
          </label>
          <div class="input-btn-group">
            <input
              [readonly]="true"
              type="text"
              formControlName="Probability"
              pInputText
            />
          </div>
        </div>
        <div class="form-group">
          <label for="lblConsequence" class="col-form-label">
            <span translate>CONSEQUENCE_FIGURE</span>
          </label>
          <div class="input-btn-group">
            <input
              [readonly]="true"
              type="text"
              formControlName="Consequence"
              pInputText
            />
          </div>
        </div>
        <!-- <div class="form-group" *ngIf="riskDatas"> -->
        <label for="lblRisk" class="col-form-label">
          <span translate>RISK_FIGURE</span>
        </label>
        <div class="input-btn-group">
          <input
            [readonly]="true"
            type="text"
            [style.background-color]="riskData.backColor"
            pInputText
            class="ex-full-width"
            [value]="riskData.value | translate"
          />
        </div>
        <!-- </div> -->
        <div class="form-group">
          <label for="lblParticipants" class="col-form-label">
            <span translate>PARTICIPANTS</span>
          </label>
          <div class="input-btn-group">
            <input
              [readonly]="true"
              type="text"
              id="txtParticipants"
              formControlName="Participants"
              pInputText
            />
          </div>
        </div>
        <div class="pull-right">
          <button
            type="submit"
            class="btn btn-outline-primary"
            (click)="exportPdf()"
            [ngClass]="{ loadingReport: loadingReport }"
          >
            <span class="icon ic-sm icon-print"></span>
            <span *ngIf="!loadingReport" translate>PRINT_PAGE</span>
            <span *ngIf="loadingReport" translate>PROCESSING</span>
            <span *ngIf="loadingReport" class="loader-icon"></span>
          </button>
        </div>
      </div>
    </form>
  </p-dialog>
  <p-dialog
    header="{{ 'CONFIRMATION' | translate }}"
    [(visible)]="displayDialog"
    [style]="{ width: '350px' }"
    [resizable]="false"
    [draggable]="false"
    showEffect="fade"
    [modal]="true"
    [closable]="true"
  >
    <div class="confirmation-content">
      <span> {{ "APPROVAL_STATUS" | translate }}</span>
    </div>
    <div class="action-btns-wrapper">
      <div class="text-right">
        <button
          type="submit"
          class="btn btn-outline-primary"
          (click)="generateReport1(true)"
        >
          <span translate>Yes</span>
        </button>
        <button
          type="button"
          class="btn btn-outline-primary"
          (click)="generateReport1(false)"
        >
          <span translate>No</span>
        </button>
      </div>
    </div>
  </p-dialog>

  <div class="panel-loader" *ngIf="loading">
    <button
      type="submit"
      class="btn btn-primary-outline"
      (click)="exportPdf()"
      [ngClass]="{ loadingReport: loadingReport }"
    >
      <span class="icon ic-sm icon-print"></span>
      <span *ngIf="!loadingReport" translate>PRINT_PAGE</span>
      <span *ngIf="loadingReport" translate>PROCESSING</span>
      <span *ngIf="loadingReport" class="loader-icon"></span>
    </button>
  </div>
  <!--Panel Loader-->
</div>
