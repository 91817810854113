import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  Renderer2,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { ElectroService } from '../services/electro.services';
import { ElectroEnabledManuals } from '../models/electrocontract';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { Rights, Roles } from 'src/app/_models';
import { ArticleContent } from 'src/app/shared/ecpl-article-viewer/interfaces';
import {
  ElectroFeature,
  ElectroInstallatioForms,
} from '../models/electrofeatures';
import { ElectroDocumentService } from '../services/electro-document.services';
import {
  ElectroChecklistItemUpdate,
  ParentItem,
} from 'src/app/kuba/feature-documents/feature-document.models';
import { ChildItem } from 'src/app/kuba/manuals/models/manual-checklist.model';
import { isArray } from 'util';
import { ElectroOrgChartData } from '../models/electroinstallation';
import { Subject, Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from 'src/app/_services/helper.service';
import { Table } from 'primeng/table';

//#region org chart
const iframeCode = `
<!doctype html>
<html lang="en">
<head>
  <meta charset="utf-8">
  <title>UmlApp</title>
  <base href="/">

  <meta name="viewport" content="width=device-width, initial-scale=1">
	<link rel="icon" type="image/x-icon" href="favicon.ico">
	<link rel="stylesheet" type="text/css" id="appStyle" #appStyle href="./assets/styles/themes/default/style.min.css">

  <link rel="stylesheet" type="text/css" href="./assets/mxgraph/styles/grapheditor.css">
  <style>*, *:after, *:before {-webkit-box-sizing: content-box;-moz-box-sizing: content-box;box-sizing: content-box;}</style>
	<script type="text/javascript">
		// Parses URL parameters. Supported parameters are:
		// - lang=xy: Specifies the language of the user interface.
		// - touch=1: Enables a touch-style user interface.
		// - storage=local: Enables HTML5 local storage.
        // - chrome=0: Chromeless mode.
        var universalGraph;
		var urlParams = (function(url)
		{
			var result = new Object();
			var idx = url.lastIndexOf('?');
	
			if (idx > 0)
			{
				var params = url.substring(idx + 1).split('&');
				
				for (var i = 0; i < params.length; i++)
				{
					idx = params[i].indexOf('=');
					
					if (idx > 0)
					{
						result[params[i].substring(0, idx)] = params[i].substring(idx + 1);
					}
				}
			}
			
			return result;
		})(window.location.href);
	
		// Default resources are included in grapheditor resources
		mxLoadResources = false;
	</script>
	<script type="text/javascript" src="./assets/mxgraph/js/Init.js"></script>
	<script type="text/javascript" src="./assets/mxgraph/jscolor/jscolor.js"></script>
	<script type="text/javascript" src="./assets/mxgraph/sanitizer/sanitizer.min.js"></script>
	<script type="text/javascript" src="./assets/mxgraph/src/js/mxClient.js"></script>
	<script type="text/javascript" src="./assets/mxgraph/js/EditorUi.js"></script>
	<script type="text/javascript" src="./assets/mxgraph/js/Editor.js"></script>
	<script type="text/javascript" src="./assets/mxgraph/js/Sidebar.js"></script>
	<script type="text/javascript" src="./assets/mxgraph/js/Graph.js"></script>
	<script type="text/javascript" src="./assets/mxgraph/js/Shapes.js"></script>
	<script type="text/javascript" src="./assets/mxgraph/js/Actions.js"></script>
	<script type="text/javascript" src="./assets/mxgraph/js/Menus.js"></script>
	<script type="text/javascript" src="./assets/mxgraph/js/Format.js"></script>
	<script type="text/javascript" src="./assets/mxgraph/js/Toolbar.js"></script>
	<script type="text/javascript" src="./assets/mxgraph/js/Dialogs.js"></script>
</head>
<body>
text
  <script type="text/javascript">
		// Extends EditorUi to update I/O action states based on availability of backend
		(function()
		{
			var bundle = mxResources.getDefaultBundle(RESOURCE_BASE, mxLanguage) ||
				mxResources.getSpecialBundle(RESOURCE_BASE, mxLanguage);

			// Fixes possible asynchronous requests
			mxUtils.getAll([bundle, STYLE_PATH + '/default.xml'], function(xhr)
			{
				// Adds bundle text to resources
				mxResources.parse(xhr[0].getText());
				
				// Configures the default graph theme
				var themes = new Object();
				themes[Graph.prototype.defaultThemeName] = xhr[1].getDocumentElement(); 
				
				// Main
                new EditorUi(new Editor(urlParams['chrome'] == '0', themes));
                var chartXmlData = JSON.parse(sessionStorage.getItem('orgchartxml'));
                if(chartXmlData != null && chartXmlData.length < MAX_REQUEST_SIZE)
                {
                    uploadXML(chartXmlData);
                }
			}, function()
			{
				document.body.innerHTML = '<center style="margin-top:10%;">Error loading resource files. Please check browser console.</center>';
			});
        })();
        function uploadXML(xml){
		let doc = mxUtils.parseXml(xml);
		let codec = new mxCodec(doc);
		codec.decode(doc.documentElement, universalGraph.getModel());
		let elt = doc.documentElement.firstChild;
		let cells = [];
		while (elt != null)
		{
			let cell = codec.decode(elt)
			if(cell != undefined){
				if(cell.id != undefined && cell.parent != undefined && (cell.id == cell.parent)){
					elt = elt.nextSibling;
					continue;
				}
				cells.push(cell);
			}
			elt = elt.nextSibling;
		}
		universalGraph.addCells(cells);
        }
	</script>
</body>
</html>`;
//#endregion
@Component({
  selector: 'edit-print-manual',
  templateUrl: 'edit-print-manual.component.html',
  styleUrls: ['electro-organization.component.css'],
})
export class EditPrintManualComponent implements OnInit {
  manualsList: any;
  rowId: number = null;
  isCollapsed = false;
  selectedManual: any = [];
  selectedChapter: any = [];
  selectedSubModules: any = [];
  enabledManuals: any;
  isLeader = true;
  editDoc: ArticleContent;
  editFeatureId: number;
  manualType: string;
  featureKey: number;
  isSvgchart: boolean;
  orgchartData: ElectroOrgChartData;
  chartxml: any;
  svgchart: any;
  EditedOn: Date;
  data$: Subject<string> = new Subject();
  editMode = true;
  isiFrameEnabled: boolean;
  isEditContent: boolean;
  isEditPrintManual: boolean;
  defaultManuals: any[];
  pageType: number;
  private subscriptions: Subscription[] = [];
  @ViewChild('iframe', { static: false }) iframe: ElementRef;
  @ViewChild('manualTable', { static: false }) manualTable: Table;
  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  @ViewChild('hiddenAction', { static: false }) hiddenAction: ElementRef;
  showLoader: boolean;
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private electroDocumentServices: ElectroDocumentService,
    private electroServices: ElectroService,
    private renderer: Renderer2,
    private domSanitizer: DomSanitizer
  ) {
    //isLeader is set false for "Guests" and "Users" without SERVICE_LEADER_ELECTRO rights.
    if (+BaseServices.roleId === Roles.GUEST) {
      this.isLeader = false;
    }
    let currentUserRole = BaseServices.UserRole;
    let userRightsId = Rights.SERVICE_LEADER_ELECTRO;
    if (+BaseServices.roleId === Roles.USER) {
      this.isLeader = BaseServices.checkUserRights(
        userRightsId,
        currentUserRole
      );
    }
  }

  ngOnInit() {
    if (this.route.snapshot.url[0].path === 'editcontent') {
      this.isEditPrintManual = false;
      this.pageType = 1;
    }
    if (this.route.snapshot.url[0].path === 'manual') {
      this.isEditPrintManual = true;
      this.pageType = 2;
    }
    localStorage.setItem('apibaseUrl', environment.BASE_URL);
    this.manualsList = this.route.snapshot.data['manuals'];
    this.enabledManuals = this.route.snapshot.data['enabledManuals'];
    this.featureKey = this.route.parent.snapshot.params['cid'];
    if (!this.isEditPrintManual) {
      this.defaultManuals = this.manualsList.filter(
        (x) => x.ParentDocumentId == 4 && x.DocumentType == 'OVERVIEW'
      );
      this.manualsList = this.manualsList.filter(
        (x) => !(x.ParentDocumentId == 4 && x.DocumentType == 'OVERVIEW')
      );
    }
    if (this.enabledManuals) {
      //  Live issue fixes
      // this.manualTable.reorderableColumns = false;
      // this.manualTable.sortableRowGroup = false;
      this.bindEnabledManuals(this.enabledManuals);
    } else {
      if (this.isEditPrintManual) {
        this.manualsList = [];
      }
    }
  }
  /**
   * row index for page no.
   * @param row
   */
  /* Commented for KUBA 4.0 initial version */
  // index(row) {
  // let b = this.manualTable.findExpandedRowGroupIndex(row);
  // let a = this.manualTable.findExpandedRowIndex(row);
  // return a;
  // }
  /**
   * bind enabled manuals and chapter
   * @param enabledManuals
   */
  bindEnabledManuals(enabledManuals: any) {
    let ManualsJson = enabledManuals.EnabledManuals;
    if (ManualsJson) {
      JSON.parse(ManualsJson).forEach((sm) => {
        this.manualsList.forEach((m) => {
          if (
            sm.documentNumber == m.DocumentNumber &&
            sm.isDocument == m.IsDocument
          ) {
            this.selectedManual.push(m);
            //if all the documents belonging to parent gets selected select the parent checkobox
            if (
              this.selectedManual.filter(
                (x) => x.ParentDocumentId === m.ParentDocumentId
              ).length ===
                this.manualsList.filter(
                  (x) => x.ParentDocumentId === m.ParentDocumentId
                ).length &&
              this.selectedChapter.indexOf(m.ParentDocumentId) === -1
            ) {
              this.selectedChapter.push(m.ParentDocumentId);
            }
          }
        });
      });
    }
    if (this.isEditPrintManual) {
      this.selectedManual = this.selectedManual.concat(
        this.manualsList.filter((x) => x.IsContractReport)
      );
      this.manualsList = this.manualsList;
      this.manualsList = this.selectedManual
        .sort((a, b) => (a.DocumentNumber < b.DocumentNumber ? -1 : 1))
        .sort((a, b) => (a.ParentDocumentId < b.ParentDocumentId ? -1 : 1));
      this.selectedManual = [];
      this.selectedChapter = [];
    }
  }

  /**
   *  set collapse value
   * @param i {number}
   */
  public setCollapse(i: number): boolean {
    let isCollapsed = i === this.rowId;
    return !isCollapsed;
  }

  /**
   * toggle expand icon click
   * @param i {number}
   */
  toggleClick(i: number): void {
    this.rowId = i;
    this.isCollapsed = !this.isCollapsed;
  }

  /**
   * toggle collapse icon click
   */
  toggleClickHide(): void {
    this.rowId = null;
  }

  /**
   * row select event for document manual selection.
   * @param e
   */
  onRowSelect(e) {
    //if all the documents belonging to parent gets selected select the parent checkbox
    if (
      this.selectedManual.filter(
        (x) => x.ParentDocumentId === e.data.ParentDocumentId
      ).length ===
      this.manualsList.filter(
        (x) => x.ParentDocumentId === e.data.ParentDocumentId
      ).length
    ) {
      this.selectedChapter.push(e.data.ParentDocumentId);
    } else {
      if (this.selectedChapter.indexOf(e.data.ParentDocumentId) !== -1) {
        this.selectedChapter.splice(
          this.selectedChapter.indexOf(e.data.ParentDocumentId),
          1
        );
      }
    }
    // let dist = this.selectedManual.find(x => x.DocumentNumber === "IKK-040503");
    // if(e.data.Id == ElectroInstallatioForms.MEASURE_PROTOCOL){
    //     let mpIndex = this.selectedManual.indexOf(this.selectedManual.find(x => x.DocumentNumber === "IKK-040507"));
    //     if(!(dist)){
    //         this.selectedManual.splice(mpIndex,1);
    //         alert(this.translate.instant('DISTRIBUTION_MUST_BE_ENABLED'));
    //     }
    // }
    // else if(e.data.Id == ElectroInstallatioForms.ELECTRICAL_COURSE_OVERVIEW){
    //     let coIndex = this.selectedManual.indexOf(this.selectedManual.find(x => x.DocumentNumber === "IKK-040504"));
    //     if(!(dist)){
    //         this.selectedManual.splice(coIndex,1);
    //         alert(this.translate.instant('DISTRIBUTION_MUST_BE_ENABLED'));
    //     }
    // }
  }

  /**
   * row unselect event for document manual selection.
   * @param e
   */
  onRowUnselect(e) {
    if (this.selectedChapter.indexOf(e.data.ParentDocumentId) !== -1) {
      this.selectedChapter.splice(
        this.selectedChapter.indexOf(e.data.ParentDocumentId),
        1
      );
    }
    if (e.data.Id == ElectroInstallatioForms.ELECTRICAL_DISTRIBUTION_OVERVIEW) {
      let dist = this.selectedManual.find(
        (x) => x.DocumentNumber === 'IKK-040503'
      );
      let mpIndex = this.selectedManual.indexOf(
        this.selectedManual.find((x) => x.DocumentNumber === 'IKK-040507')
      );
      if (!dist && mpIndex > -1) {
        this.selectedManual.splice(mpIndex, 1);
      }
      let coIndex = this.selectedManual.indexOf(
        this.selectedManual.find((x) => x.DocumentNumber === 'IKK-040504')
      );
      if (!dist && coIndex > -1) {
        this.selectedManual.splice(coIndex, 1);
      }
    }
  }

  /**
   * header toggle event to reflect on document selection.
   * @param event
   */
  onHeaderCheckboxToggle(event) {
    if (event.checked) {
      this.manualsList.forEach((chapter) => {
        if (this.selectedChapter.indexOf(chapter.ParentDocumentId) === -1) {
          this.selectedChapter.push(chapter.ParentDocumentId); // push documents belonging to the parent into selectedchapter
        }
      });
    } else {
      this.selectedChapter = [];
    }
  }

  /**
   * chapter selection event to reflect on document selection.
   * @param parentId
   * @param chapterStatus
   */
  onChapterSelected(parentId, chapterStatus) {
    if (chapterStatus.checked) {
      this.selectedChapter.push(parentId);
      this.manualsList.forEach((chapter) => {
        if (chapter.ParentDocumentId == parentId) {
          if (this.selectedManual.indexOf(chapter) === -1) {
            this.selectedManual.push(chapter); // push documents belonging to the parent into selectedManual
          }
        }
      });
    } else {
      if (this.selectedChapter.indexOf(parentId) !== -1) {
        this.selectedChapter.splice(this.selectedChapter.indexOf(parentId), 1);
      }
      this.manualsList.forEach((chapter) => {
        if (chapter.ParentDocumentId == parentId) {
          if (this.selectedManual.indexOf(chapter) !== -1) {
            this.selectedManual.splice(this.selectedManual.indexOf(chapter), 1);
          }
        }
      });
    }
  }

  /**
   * save enabled manuals.
   */
  saveChanges() {
    this.selectedManual = this.selectedManual.concat(this.defaultManuals);
    let selectedManualList = [];
    //collecting enabled manuals as object
    this.selectedManual.forEach((m) => {
      let manual = {
        id: m.Id,
        documentNumber: m.DocumentNumber,
        name: m.TranslationKey,
        featureId: m.FeatureId,
        isDocument: m.IsDocument,
      };
      selectedManualList.push(manual);
    });
    let enabledManual = new ElectroEnabledManuals();
    if (this.enabledManuals) {
      enabledManual.Id = this.enabledManuals.Id;
      enabledManual.CreatedOn = this.enabledManuals.CreatedOn;
      enabledManual.CreatedBy = this.enabledManuals.CreatedBy;
    }
    enabledManual.ElectroContractId = this.route.snapshot.parent.params['cid'];
    enabledManual.Status = 1;
    enabledManual.EnabledManuals = JSON.stringify(selectedManualList);
    this.subscriptions.push(
      this.electroServices
        .saveEnabledManuals(enabledManual)
        .subscribe((result) => {
          if (result) {
            this.toasterComponent.callToast();
            this.router.navigate(
              [
                `./../../../details/${enabledManual.ElectroContractId}/inspection`,
                { Id: enabledManual.ElectroContractId },
                'installer-insp',
              ],
              { relativeTo: this.route }
            );
          }
        })
    );
  }

  /**
   * document edit click event.
   * @param manual
   */
  openDocument(manual, isEdit) {
    this.editMode = isEdit;
    this.editFeatureId = manual.FeatureId;
    this.editDoc = JSON.parse(manual.Content);
    if (manual.TranslationKey === 'FACTS_ABOUT_THE_COMPANY') {
      this.manualType = 'factform';
      this.cdr.detectChanges();
      this.triggerFalseClick();
    } else if (manual.TranslationKey === 'ORGANIZATIONAL_CHART') {
      this.manualType = 'orgchart';
      this.openiframe(
        this.editDoc.article.orgChartData,
        this.editDoc.article.modifiedBy
      );
    } else if (
      manual.FeatureId == ElectroFeature.ORGANIZATION_AND_RESPONSIBILTIES ||
      manual.FeatureId == ElectroFeature.INTERNAL_CONTROL_SYSTEM ||
      manual.FeatureId == ElectroFeature.INSPECTION_REPORT_TEMPLATES
    ) {
      this.manualType =
        manual.FeatureId == ElectroFeature.INSPECTION_REPORT_TEMPLATES
          ? 'checklist'
          : 'article';
      this.cdr.detectChanges();
      this.triggerFalseClick();
    } else {
      switch (manual.TranslationKey) {
        case 'CONTRACT_FOR_INTERNAL_INSPECTION_OF_ELECTRICAL_INSTALLATIONS':
          this.router.navigate(
            ['./../contracts', { Id: this.featureKey }, 'control'],
            { relativeTo: this.route }
          );
          break;
        case 'CONTRACT_FOR_CLOSING_AND_CORRECTION_OF_DEVIATIONS':
          this.router.navigate(
            ['./../contracts', { Id: this.featureKey }, 'deviation'],
            { relativeTo: this.route }
          );
          break;
        case 'INSTALLATION_FORM_OVERVIEW':
          this.router.navigate(
            ['./../inspection', { Id: this.featureKey }, 'form-overview'],
            { relativeTo: this.route }
          );
          break;
        case 'FORMS':
          this.router.navigate(
            ['./../inspection', { Id: this.featureKey }, 'forms'],
            { relativeTo: this.route }
          );
          break;
        case 'INSTALLER_INSPECTION_OVERVIEW':
          this.router.navigate(
            ['./../inspection', { Id: this.featureKey }, 'installer-insp'],
            { relativeTo: this.route }
          );
          break;
        case 'SELF_INSPECTION_OVERVIEW':
          this.router.navigate(
            ['./../inspection', { Id: this.featureKey }, 'self-insp'],
            { relativeTo: this.route }
          );
          break;
        case 'DEVIATION_OVERVIEW':
          this.router.navigate(
            ['./../inspection', { Id: this.featureKey }, 'dev-overview'],
            { relativeTo: this.route }
          );
          break;
        case 'REPORT_TEMPLATES':
          this.router.navigate(
            ['./../inspection', { Id: this.featureKey }, 'report-temp'],
            { relativeTo: this.route }
          );
          break;
        case 'LAWS_AND_REGULATION_OVERVIEW':
          this.router.navigate(['./../lar', { Id: this.featureKey }], {
            relativeTo: this.route,
          });
          break;
        case 'ARCHIVE':
          this.router.navigate(['./../archive', { Id: this.featureKey }], {
            relativeTo: this.route,
          });
          break;
      }
    }
  }

  /**
   * article and checklist false triggers.
   */
  triggerFalseClick() {
    let el: HTMLElement = this.hiddenAction.nativeElement.querySelector('a');
    el.click();
  }

  //#region checklist sec and item binding functions.
  private checklistSaveObj(model, modifiedBy) {
    let listModel = new ElectroChecklistItemUpdate();
    listModel.title = model.item.checkList.title;
    listModel.userId = BaseServices.UserId;
    listModel.parentItems = [];
    listModel.ModifiedBy = modifiedBy;
    if (model.item.checkList.sections.length > 0) {
      model.item.checkList.sections.forEach((ParentItem) => {
        let section = this.checklistSecObj(ParentItem);
        listModel.parentItems.push(section);
      });
    }

    return listModel;
  }
  private checklistSecObj(obj) {
    let section = new ParentItem();
    section.parentItemId = obj.id; // Id
    section.parentId = 0;
    section.parentItemPoint = obj.position;
    section.parentItemTitle = obj.title;
    section.parentItemStatus = obj.status;
    section.childItems = this.checklistItemObj(obj.items);
    return section;
  }
  private checklistItemObj(objItem) {
    let items: ChildItem[] = [];
    if (isArray(objItem)) {
      objItem.forEach((item: any) => {
        items.push({
          childItemId: item.id,
          childItemTitle: item.title,
          childItemPoint: item.position,
          isView: item.status,
        });
      });
    }
    return items;
  }
  //#endregion
  // emitter for save from Document Explorer
  saveUploaded(event: any) {
    switch (event.eventName) {
      case 'SaveArticle':
        this.catchEvent(event);
        break;
      case 'NewArticle':
        this.catchEvent(event);
        break;
      case 'NewChecklist':
        this.catchEvent(event);
        break;
      case 'SaveChecklist':
        this.catchEvent(event);
        break;
      default:
        break;
    }
  }

  /**
   * document event catch function.
   * @param event
   */
  catchEvent(event): void {
    switch (event.eventName) {
      case 'NewArticle':
        this.subscriptions.push(
          this.electroDocumentServices
            .newArticle(event, this.editFeatureId)
            .subscribe(
              (response) => {
                this.subscriptions.push(
                  this.electroDocumentServices
                    .newArticleText(
                      response.Id,
                      event,
                      true,
                      0,
                      this.editFeatureId,
                      this.featureKey
                    )
                    .subscribe((result) => {
                      this.BindDocument();
                      this.toasterComponent.callToast();
                    })
                );
              },
              (error) => console.log('New article error : ', error),
              () => console.log('new article Complete')
            )
        );
        break;
      case 'SaveArticle':
        let version = event.item.articleContent.article.version;
        if (+BaseServices.roleId == Roles.ADMIN) {
          this.subscriptions.push(
            this.electroDocumentServices
              .saveArticle(event, this.editFeatureId)
              .subscribe(
                (response) => {
                  this.subscriptions.push(
                    this.electroDocumentServices
                      .newArticleText(
                        response.Id,
                        event,
                        false,
                        version,
                        this.editFeatureId,
                        this.featureKey
                      )
                      .subscribe((result) => {
                        this.BindDocument();
                        this.toasterComponent.callToast();
                      })
                  );
                },
                (error) => console.log('Save article error : ', error),
                () => console.log('save article Complete')
              )
          );
        } else {
          this.subscriptions.push(
            this.electroDocumentServices
              .newArticleText(
                event.item.articleId,
                event,
                false,
                version,
                this.editFeatureId,
                this.featureKey
              )
              .subscribe((result) => {
                this.BindDocument();
                this.toasterComponent.callToast();
              })
          );
        }
        break;
      case 'NewChecklist':
        this.subscriptions.push(
          this.electroDocumentServices
            .createChecklist(this.editFeatureId, this.featureKey, event)
            .subscribe(
              (response) => {
                let checklistId = response.Id;
                let modifiedBy = response.modifiedBy;
                let listModel = this.checklistSaveObj(event, 0);
                this.subscriptions.push(
                  this.electroDocumentServices
                    .saveChecklist(checklistId, listModel, modifiedBy)
                    .subscribe(
                      (response) => {
                        this.BindDocument();
                        this.toasterComponent.callToast();
                      },
                      (error) =>
                        console.log('Save checklistitem error : ', error),
                      () => console.log('save checklistitem Complete')
                    )
                );
              },
              (error) => console.log('Save checklist error : ', error),
              () => console.log('save checklist Complete')
            )
        );
        break;
      case 'SaveChecklist':
        let tempchecklistId = event.item.checkList.id;
        let templistModel = this.checklistSaveObj(event, BaseServices.UserId);
        let templistModifiedBy = event.item.checkList.modifiedBy;
        this.electroDocumentServices
          .saveChecklist(tempchecklistId, templistModel, templistModifiedBy)
          .subscribe(
            (response) => {
              this.BindDocument();
              this.toasterComponent.callToast();
            },
            (error) => console.log('Save checklistitem error : ', error),
            () => console.log('save checklistitem Complete')
          );
        break;
      case 'EditChecklist':
        let checklistId = event.item.checkList.id;
        let listModel = this.checklistSaveObj(event, BaseServices.UserId);
        let modifiedBy = event.item.checkList.modifiedBy;
        this.subscriptions.push(
          this.electroDocumentServices
            .saveChecklist(checklistId, listModel, modifiedBy)
            .subscribe(
              (response) => {
                this.BindDocument();
                this.toasterComponent.callToast();
              },
              (error) => console.log('Save checklist error : ', error),
              () => console.log('save checklist Complete')
            )
        );

        break;
      default:
        break;
    }
  }

  /**
   * bind document list.
   */
  BindDocument() {
    //this.showLoader = true;
    this.manualsList = [];
    this.subscriptions.push(
      this.electroServices
        .getElectroManualByContractId(this.featureKey, this.pageType)
        .subscribe((response) => {
          if (response) {
            this.manualsList = response;
            this.bindEnabledManuals(this.enabledManuals);
          }
        })
    );
  }

  //#region graph
  sendData() {
    this.data$.next('From parent to iframe');
  }

  private setIframeReady(iframe: ElementRef): void {
    const win: Window = iframe.nativeElement.contentWindow;

    // Pass the variable from parent to iframe
    win['dataFromParent'] = this.data$;

    const doc: Document = win.document;
    doc.open();
    doc.write(iframeCode);
    doc.close();
  }

  openiframe(orgchartDetails: any, EditorName: string) {
    this.orgchartData = new ElectroOrgChartData();
    this.orgchartData.DocumentNumber = orgchartDetails.documentNumber;
    this.orgchartData.Version = orgchartDetails.version;
    this.orgchartData.EditorName = EditorName;
    this.EditedOn = orgchartDetails.modifiedOn;
    if (this.editMode) {
      let BusinessId = BaseServices.BusinessId;
      let ApplicationId = BaseServices.ApplicationId;
      let UserId = BaseServices.UserId;

      if (orgchartDetails.id === 0) {
        this.orgchartData.BusinessId = BusinessId;
        this.orgchartData.ApplicationId = ApplicationId;
        this.orgchartData.ElectroContractId = this.featureKey;
        this.orgchartData.Status = 1;
        this.orgchartData.CreatedBy = UserId;
      } else {
        this.orgchartData.ModifiedBy = UserId;
        if (orgchartDetails.modifiedOn) {
          let date = HelperService.formatInputDate(orgchartDetails.modifiedOn)
            .date.day;
          let mnth = HelperService.formatInputDate(orgchartDetails.modifiedOn)
            .date.month;
          let year = HelperService.formatInputDate(orgchartDetails.modifiedOn)
            .date.year;
          this.orgchartData.ArticleEditedDate =
            year +
            '-' +
            (mnth < 10 ? '0' + mnth : mnth) +
            '-' +
            (date < 10 ? '0' + date : date);
        }
      }
      this.orgchartData.Id = orgchartDetails.id;
      sessionStorage.setItem('orgchartData', JSON.stringify(this.orgchartData));
      this.chartxml = orgchartDetails.chartXmlData;
      sessionStorage.setItem('orgchartxml', JSON.stringify(this.chartxml));
      this.isiFrameEnabled = true;
      this.renderer.addClass(document.body, 'modal-open');
      this.setIframeReady(this.iframe);
    } else {
      this.isiFrameEnabled = true;
      this.isSvgchart = orgchartDetails.chartSvgData ? true : false;
      let svgchartURL = orgchartDetails.chartSvgData;
      this.svgchart =
        this.domSanitizer.bypassSecurityTrustResourceUrl(svgchartURL);
    }
  }
  hideModal() {
    this.renderer.removeClass(document.body, 'modal-open');
    this.isiFrameEnabled = false;
    if (this.editMode) {
      this.BindDocument();
    }
  }
  //#endregion

  /**
   * download manual as pdf
   */
  downloadPDF() {
    if (this.selectedManual ? this.selectedManual.length : false) {
      this.showLoader = true;
      let ContractNumber = localStorage.getItem('contractNumber');
      let type = 'pdf';
      let appSettings = JSON.parse(sessionStorage.getItem('appSettings'));
      let languageId = appSettings.Language.LanguageId;
      let CultureInfo = sessionStorage.getItem('languageMode');
      this.subscriptions.push(
        this.electroDocumentServices
          .generateManualReport(
            this.selectedManual,
            this.featureKey,
            CultureInfo
          )
          .subscribe(
            (blob) => {
              this.showLoader = false;
              let link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = `${
                ContractNumber + '-' + this.translate.instant('MANUAL_REPORT')
              }.${type.toLowerCase()}`;
              link.click();
            },
            (error) => {
              this.showLoader = false;
              alert('Export not downloaded');
            }
          )
      );
    } else {
      alert(this.translate.instant('ELECTRO_PRINT_MANUAL_ALERT'));
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}
