<div class="page-title">
  <span translate>TIME_TABLE</span>
  <span class="page-actions pull-right" *ngIf="isNotGuestLogin">
    <a [routerLink]="['../new']" class="btn btn-success">
      <span class="icon ic-sm icon-add"></span>
      <span translate>NEW</span>
    </a>
  </span>
</div>
<div class="table-view">
  <!-- Table starts -->
  <p-table
    #timetableTable
    [value]="filteredTimeTable | async"
    [rows]="10"
    [paginator]="true"
    [pageLinks]="3"
    [rowsPerPageOptions]="[5, 10, 20]"
    exportFilename="TimeTableList"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="{{ 'SUMMARY_TOTALRECORDS' | translate }}"
  >
    <!-- Caption -->
    <ng-template pTemplate="caption">
      <div class="ui-helper-clearfix">
        <div class="filter-controls-wrapper" style="display: none">
          <div [formGroup]="filterForm" class="row">
            <div class="col-sm-3">
              <div class="form-group">
                <label for="department" class="col-form-label">
                  <span translate>FROM_DATE</span>
                </label>
                <div>
                  <my-date-picker
                    
                    name="FromDate"
                    [options]="fromDatePickerOption"
                    (dateChanged)="onFromDateChanged($event)"
                    [formControl]="this.filterForm.get('fromDate')"
                    placeholder="{{ 'ENTER_THE_START_DATE' | translate }}"
                  ></my-date-picker>
                </div>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label for="department" class="col-form-label">
                  <span translate>END_DATE</span>
                </label>
                <div>
                  <my-date-picker
                    name="enddate"
                    [options]="endDatePickerOption"
                    (dateChanged)="onEndDateChanged($event)"
                    [formControl]="this.filterForm.get('endDate')"
                    placeholder="{{ 'ENTER_STOP_DATE' | translate }}"
                    required
                  ></my-date-picker>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="table-options">
          <div class="pull-right">
            <div class="action-btns-group">
              <button class="btn p-3 filter-toggle">
                <span class="icon ic-sm icon-filter"></span>
                <span translate>FILTER</span>
              </button>
            </div>
            <div class="action-btns-group">
              <kuba-export
                [reportData]="
                  timetableTable.filteredValue || timetableTable.value
                "
                [additionalData]="additionalData"
              ></kuba-export>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <!-- Footer -->
    <ng-template pTemplate="summary">
      <div class="footer-data">
        <div>
          <strong translate *ngIf="totalHoursSpent != null"
            >TOTAL_HOURS_SPENT</strong
          >: {{ totalHoursSpent }}
        </div>
      </div>
    </ng-template>
    <!-- Header -->
    <ng-template pTemplate="header">
      <tr>
        <th *ngIf="isNotSubContractorAndContacts">
          {{ "EMPLOYEE_NAME" | translate }}
        </th>
        <th *ngIf="isSubContractorAndContacts">
          {{ "EMPLOYEE_NAME" | translate }}
        </th>
        <th pSortableColumn="ProjectWorkTypeName">
          {{ "TYPE_OF_WORK" | translate }}
          <p-sortIcon field="ProjectWorkTypeName"></p-sortIcon>
        </th>
        <th pSortableColumn="StartDate">
          {{ "START_DATE" | translate }}
          <p-sortIcon field="StartDate"></p-sortIcon>
        </th>
        <th pSortableColumn="EndDate">
          {{ "END_DATE" | translate }} <p-sortIcon field="EndDate"></p-sortIcon>
        </th>
        <th pSortableColumn="StartTime">
          {{ "START_TIME" | translate }}
          <p-sortIcon field="StartTime"></p-sortIcon>
        </th>
        <th pSortableColumn="EndTime">
          {{ "END_TIME" | translate }} <p-sortIcon field="EndTime"></p-sortIcon>
        </th>
        <th pSortableColumn="HoursSpent">
          {{ "HOURS_SPENT" | translate }}
          <p-sortIcon field="HoursSpent"></p-sortIcon>
        </th>
        <th pSortableColumn="StatusText">
          {{ "STATUS" | translate }}
          <p-sortIcon field="StatusText"></p-sortIcon>
        </th>
        <th *ngIf="isFoodsafetyOrProject">{{ "SOURCE" | translate }}</th>
        <th>{{ "OPTIONS" | translate }}</th>
      </tr>
      <tr>
        <th>
          <p-dropdown
            width="118px"
            [options]="employees"
            (onChange)="onEmpChanged($event, ed)"
            #ed
            styleClass="p-column-filter"
            placeholder=""
            [showClear]="true"
            appendTo="body"
          >
            <ng-template let-eployees pTemplate="item">
              {{ eployees.label | translate }}
            </ng-template>
          </p-dropdown>
        </th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th *ngIf="isFoodsafetyOrProject">
          <p-dropdown
            width="118px"
            [options]="sources"
            (onChange)="onSourceChanged($event, sourceDropdown)"
            #sourceDropdown
            styleClass="p-column-filter"
            placeholder=""
            appendTo="body"
            [showClear]="true"
          >
            <ng-template let-eployees pTemplate="item">
              {{ eployees.label | translate }}
            </ng-template>
          </p-dropdown>
        </th>
        <th></th>
      </tr>
    </ng-template>
    <!-- Body Content -->
    <ng-template pTemplate="body" let-timetable>
      <tr>
        <td *ngIf="isNotSubContractorAndContacts">
          <span>{{ timetable.EmployeeName }}</span>
        </td>
        <td *ngIf="isSubContractorAndContacts">
          <span>{{ timetable.EmployeeName }}</span>
        </td>
        <td>
          <a [routerLink]="['./../edit', timetable.Id]">{{
            timetable.ProjectWorkTypeName
          }}</a>
        </td>
        <td>
          <span>{{ timetable.StartDate | date : "dd/MM/yyyy" }}</span>
        </td>
        <td>
          <span *ngIf="timetable.EndDate !== null">{{
            timetable.EndDate | date : "dd/MM/yyyy"
          }}</span>
        </td>
        <td>
          <span>{{ timetable.StartTime | fomatTime }}</span>
        </td>
        <td>
          <span *ngIf="timetable.EndTime !== null">{{
            timetable.EndTime | fomatTime
          }}</span>
        </td>
        <td>
          <span *ngIf="timetable.HoursSpent !== null">{{
            timetable.HoursSpent
          }}</span>
        </td>
        <td>
          <span
            *ngIf="timetable.StatusText != null && timetable.StatusText != ''"
            >{{ timetable.StatusText.toUpperCase() | translate }}</span
          >
        </td>
        <td *ngIf="isFoodsafetyOrProject">
          <span>{{ timetable.Source | translate }}</span>
        </td>
        <td class="col-button" *ngIf="isNotGuestAndUserLogin">
          <button
            type="button"
            class="btn btn-icon"
            title="{{ 'DELETE' | translate }}"
            (click)="deleteTimetable(timetable.Id)"
          >
            <i class="icon ic-sm icon-trash"></i>
          </button>
        </td>
      </tr>
    </ng-template>
    <!-- Empty message -->
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td colspan="9">
          {{ "NO_RECORDS_FOUND" | translate }}
        </td>
      </tr>
    </ng-template>
  </p-table>
  <!-- Table ends -->
</div>
<p-confirmDialog
  header="{{ 'CONFIRMATION' | translate }}"
  icon="pi pi-question-circle"
  width="425"
  acceptLabel="{{ 'SWITCH_YES' | translate }}"
  rejectLabel="{{ 'SWITCH_NO' | translate }}"
></p-confirmDialog>
<toaster-component></toaster-component>
