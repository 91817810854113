import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
  HttpEvent,
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from '../_services';
import { IdentityToken } from '../_models/identityToken';

@Injectable()
export class Interceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<IdentityToken | null> = new BehaviorSubject<IdentityToken | null>(null);
  private readonly LOGO_ID = 'ab064fd4-9b4a-4e70-972d-dafdaceb2aba';

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  /**
   * Intercepts HTTP requests to add credentials, CSRF tokens, and handle 401 Unauthorized errors.
   *
   * @param req The outgoing HTTP request.
   * @param next The next interceptor in the chain.
   * @returns An observable that emits the HTTP event of the handled request.
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request to add default headers.
    const clonedRequest = this.addDefaultHeaders(req);

    return next.handle(clonedRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          return this.handle401Error(clonedRequest, next);
        }
        return throwError(() => error);
      })
    );
  }

  /**
   * Adds default headers to the request, including credentials, CSRF token (if available),
   * content type (when needed), and a fixed Logo-Id.
   *
   * @param req The original HTTP request.
   * @returns The cloned request with additional headers.
   */
  private addDefaultHeaders(req: HttpRequest<any>): HttpRequest<any> {
    const csrfToken = this.authenticationService.getCsrfToken();
    const isFormData = req.body instanceof FormData || req.body instanceof Blob;

    const headers: { [header: string]: string } = {
      'Logo-Id': this.LOGO_ID,
      ...(csrfToken ? { 'X-XSRF-TOKEN': csrfToken } : {}),
      ...(!isFormData ? { 'Content-Type': 'application/json' } : {})
    };

    return req.clone({
      withCredentials: true,
      setHeaders: headers,
    });
  }

  /**
   * Handles HTTP 401 Unauthorized errors by attempting to refresh the access token.
   * If the token is successfully refreshed, the original request is retried.
   * If the token refresh fails, the user is logged out and the error is propagated.
   *
   * @param req The original HTTP request that resulted in a 401 error.
   * @param next The next interceptor in the chain.
   * @returns An observable emitting the HTTP event of the retried request or an error.
   */
  private handle401Error(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authenticationService.refreshToken().pipe(
        finalize(() => (this.isRefreshing = false)),
        switchMap((newToken: IdentityToken) => {
          this.refreshTokenSubject.next(newToken);
          const updatedRequest = this.addAuthHeaders(req, newToken.CsrfToken);
          return next.handle(updatedRequest);
        }),
        catchError((err) => {
          console.error('Token refresh failed, logging out.', err);
          this.authenticationService.logout();
          return throwError(() => err);
        })
      );
    } else {
      // If a token refresh is already in progress, wait until it completes
      return this.refreshTokenSubject.pipe(
        filter((token): token is IdentityToken => token != null),
        take(1),
        switchMap((identityToken: IdentityToken) => {
          const updatedRequest = this.addAuthHeaders(req, identityToken.CsrfToken);
          return next.handle(updatedRequest);
        }),
        catchError((err) => {
          this.authenticationService.logout();
          return throwError(() => err);
        })
      );
    }
  }

  /**
   * Clones the request and adds authentication headers after a token refresh.
   *
   * @param req The original HTTP request.
   * @param csrfToken The new CSRF token to be added.
   * @returns The cloned request with updated headers.
   */
  private addAuthHeaders(req: HttpRequest<any>, csrfToken: string): HttpRequest<any> {
    const isFormData = req.body instanceof FormData || req.body instanceof Blob;
    const authHeaders: { [header: string]: string } = {
      'Logo-Id': this.LOGO_ID,
      ...(csrfToken ? { 'X-XSRF-TOKEN': csrfToken } : {}),
      ...(!isFormData ? { 'Content-Type': 'application/json' } : {})
    };

    return req.clone({
      withCredentials: true,
      setHeaders: authHeaders,
    });
  }
}
