import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  /**
   * Format date to string and keep the local timezone
   * @param date The Date object to format
   * @returns A string representation of the date in the format **'YYYY-MM-DD'**
   */
  public formatDateToString(date: Date){
    return moment(date).format('YYYY-MM-DD');
  }

  // public formatDateTime(date: Date) { }

  // public formatTime(date: Date) { }


  

}
