import { BaseServices } from './../../kuba.services';
import { UserService } from './../../users/services/user.service';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaderResponse } from '@angular/common/http';
import { Client, User } from '../models/client';
import { ClientContact } from '../models/clientContact';

@Injectable()
export class ClientServices {

    constructor(private http: HttpClient,
        private userService: UserService
        ) {
    }

    getAllClientsByBusiness() {
        return this.http.get<any[]>(environment.BASE_URL + '/client/getall/' + BaseServices.BusinessId, )
            .map(result => (result) ? result : null!);
    }

    getAllClients() {
        return this.http.get<any[]>(environment.BASE_URL + '/client/list/' + BaseServices.BusinessId, )
            .map(result => (result) ? result : null!);
    }

    getClientById(id: number) {
        return this.http.get<any>(environment.BASE_URL + '/client/getclientinfobyid/' + id, )
            .map(result => result);
    }
    createClient(client:any) {

        return this.http.post(environment.BASE_URL + '/client/SaveAdditional', client, )
            .map(result => result);
    }
    updateClient(client: Client) {
        return this.http.put(environment.BASE_URL + '/client/UpdateAdditional', client, )
            .map(result => result);
    }
    deleteById(id: number) {
        return this.http.delete(environment.BASE_URL + '/client/client/' + id, )
            .toPromise()
            .then((response: any) => { return response });
    }
    getAllTypeOfclient() {
        return this.http.get(environment.BASE_URL + '/contacttypes/contactTypes', )
            .map(result => result);
    }
    getContactType() {
        return this.http.get(environment.BASE_URL + '/contacttypes/contactTypes', )
            .map(result => result);
    }
    getCountryCode() {
        return this.http.get<any>(environment.BASE_URL + '/countrycode/countrycodes', )
            .map(result => result);
    }
    getClientContact() {
        return this.http.get<any>(environment.BASE_URL + '/clientcontact/clientcontacts', )
            .map(result => result);
    }
    getClientContactByContactId(id: number) {
        return this.http.get<any>(environment.BASE_URL + '/clientcontact/clientcontactsbyContact/' + id, )
            .map(result => result);
    }
    getClientContactById(id: number) {
        return this.http.get(environment.BASE_URL + '/clientcontact/clientcontacts/' + id, )
            .map(result => result);
    }
    addClientContact(contact: ClientContact) {
        return this.http.post(environment.BASE_URL + '/clientcontact/clientcontact', contact, )
            .map(result => result);
    }
    updateClientContact(contact: ClientContact) {
        return this.http.put(environment.BASE_URL + '/clientcontact/clientcontact', contact, )
            .map(result => result);
    }
    deleteClientContact(id: number) {
        return this.http.delete(environment.BASE_URL + '/clientcontact/clientcontact/' + id, )
            .map(result => result)
            .catch(this.handleError);
    }
    statusUpdate(id: any, status: any) {
        return this.http.put(environment.BASE_URL + '/client/clients/' + id, status, )
            .map(result => result);
    }
    deleteClient(ids: number[]) {
        return this.http.put(environment.BASE_URL + '/client/clients', ids, )
            .map(result => result)
            .catch(this.handleError);
    }
    getClientContactUser(featureKey: number): Observable<User> {
        return this.http.get<any>(`${environment.BASE_URL}/clientcontact/clientcontactuser/${featureKey}`, )
            .map(result => result);
    }
    getClientUser(featureKey: number): Observable<User> {
        return this.http.get<any>(`${environment.BASE_URL}/clientcontact/clientuser/${featureKey}`, )
            .map(result => result);
    }

    addUser(data: User) {
        return this.http.post(environment.BASE_URL + '/user', data, )
            .map(result => result);
    }
    updateUser(data:any) {
        return this.userService.updateUser(data.Id, data).map(result => result);
    }
    getUserById(Id:any) {
        return this.userService.getUsersById(Id);
    }
    getModuleList(clientId:any) {
        return this.http.get<any>(environment.BASE_URL + '/client/clientmodule/' + clientId, )
            .map(result => result);
    }
    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }
}
