import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { Toast } from 'angular2-toaster';
import { Location } from '@angular/common';

import { CacheHandlerService } from './../../cached.services';
import { FeaturesBaseServices } from './../../features/services/features-base.services';
import { FeaturesComponent } from './../../features/components/features.component';
import { BaseServices, KubaServices } from '../../kuba.services';
import { FeatureBase, Feature } from './../../features/models/features-base.model';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { BusinessEdit } from './../models/businessEdit';
import { BusinessServices } from './../services/business.services';
import { BusinessFeatureService } from './../services/businessFeatures.service';
import * as _ from 'lodash';
import { FeatureKey, Application, Roles, Rights } from './../../../_models/feature';
import {
    UploadInput,
    UploadFile,
    humanizeBytes,
    UploaderOptions
} from 'ngx-uploader';
import { UserRole } from '../../follow-ups/models/deviation'; 
import { Subscription } from 'rxjs';

@Component({
    selector: 'business-features',
    templateUrl: 'business-features.component.html'
})
export class BusinessFeaturesComponent implements OnInit {
    @ViewChild(ToasterComponent, {static: false}) toasterComponent: ToasterComponent;
    @ViewChild(FeaturesComponent, {static: false}) featuresComponent: FeaturesComponent;
    featureBase: FeatureBase;
    businessDetails: BusinessEdit;
    businessLogo: string;
    isFileChoosed = false;
    files: UploadFile[];
    uploadInput: EventEmitter<UploadInput>;
    humanizeBytes: Function;
    dragOver: boolean;
    options: UploaderOptions;
    remoteWindow: Window;
    public businessId: number;
    isPortalExists = false;
    roleId: number;
    isAdmin: boolean;
    isPortal: boolean;
    isPortalBusiness: boolean;
    businessInfo: any;
    features: any = [];
    toast: Toast = {
        type: 'success',
        title: 'Business Features',
        body: 'Data saved sucessfully'
    };
    supplierChecked : boolean; //KW-653  
    private subscriptions: Subscription[] = []; 
   
    constructor(
        private route: ActivatedRoute,
        public featureService: BusinessFeatureService,
        private location: Location,
        public businessServices: BusinessServices,
        public featuresBaseServices: FeaturesBaseServices,
        private cacheHandlerService: CacheHandlerService,
        private kubaService: KubaServices        
    ) {
        this.businessInfo = this.route.snapshot.data['list'];
        this.options = { concurrency: 1, allowedContentTypes: ['image/png', 'image/jpeg', 'image/gif'] };
        this.files = [];
        this.uploadInput = new EventEmitter<UploadInput>();
        this.humanizeBytes = humanizeBytes;
        this.roleId = +BaseServices.roleId;
    }

    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.isAdmin = (+BaseServices.roleId === UserRole.ADMIN) ? true : false;
        this.isPortal = (+BaseServices.roleId === UserRole.PORTAL) ? true : false;
        this.businessId = this.route.snapshot.parent?.params['bid'];
        if (this.businessId) {
            this.getBusinessDetails();
        }
    }

    getBusinessDetails() {        
        this.subscriptions.push(this.businessServices.getIsPortalBusiness(this.businessId).subscribe((result1:any) =>{
            if(result1.length > 0)
            {
            this.isPortalBusiness = true;
            }
        }));
        this.subscriptions.push(this.businessServices.getById(this.businessId).subscribe(result => {
            if (result) {
                if (+result.BusinessPortalId > 0) {
                    this.isPortalExists = true;
                }
                this.featureBase = BaseServices.getUserFeatures();                
                let featureBaseClone = BaseServices.getUserFeatures(); 
                let template = <any>JSON.parse(featureBaseClone.FeaturesTemplate!);
                
                if(!this.isPortalBusiness)
                {
                    let dashBorad = template.find((x:any)=>x.id == 1);
                    let dasIndex = template.indexOf(dashBorad);
                    if(dashBorad && dashBorad.children){
                        let pLink = dashBorad.children.find((x:any)=>x.id == 195);
                        let dasIndex1 = dashBorad.children.indexOf(pLink);
                        if(dasIndex1 > -1){
                            template[dasIndex].children.splice(dasIndex1,1);
                        }
                    }               
                }
                this.featureBase.FeaturesTemplate = JSON.stringify(
                    template.filter((x: any) => (x.navigationRoles.indexOf('B') > -1 && x.IsFeature.indexOf('F') > -1))
                );
                this.businessDetails = result;
                this.featureBase.Features = this.businessDetails.Features;
                this.featureBase.Type = 'business';
                this.featureBase.Details = this.businessDetails;
            }
        }));
    }

    saveFeature() {
        let features = <Feature[]>this.featuresComponent.features;
        let defaultFeatures = this.featuresComponent.filterEmployeeKey[1];
        let myPageFeatures = features.filter(x => { return x.id == FeatureKey.MY_PAGE; })[0];
        let employeeFeatures = this.featuresComponent.filterEmployeeKey[0];    
        let conTrue: any =false;  
        if (myPageFeatures) {
            if (myPageFeatures.id == FeatureKey.MY_PAGE && employeeFeatures.id == FeatureKey.EMPLOYEES) {
                employeeFeatures.checked = myPageFeatures.checked;
                employeeFeatures.access = myPageFeatures.access;
            }
            myPageFeatures.children.forEach(mypage => {
                employeeFeatures.children.forEach((emp:any) => {
                    if (emp.id === FeatureKey.EMPLOYEE_COURSE_DETAILS && mypage.id === FeatureKey.COURSE_DETAILS) {
                        emp.checked = mypage.checked;
                        emp.access = mypage.access;
                        return conTrue;
                    }
                    else if (emp.id === FeatureKey.EMPLOYEE_SICKNESS_ABSENCE && mypage.id === FeatureKey.SICKNESS_ABSENCE) {
                        emp.checked = mypage.checked;
                        emp.access = mypage.access;
                        return conTrue;
                    }
                    else if (emp.id === FeatureKey.EMPLOYEE_LEAVE && mypage.id === FeatureKey.LEAVE) {
                        emp.checked = mypage.checked;
                        emp.access = mypage.access;
                        return conTrue;
                    }
                    else if (emp.id === FeatureKey.EMPLOYEE_TIMETABLE && mypage.id === FeatureKey.TIME_TABLE) {
                        emp.checked = mypage.checked;
                        emp.access = mypage.access;
                        return conTrue;
                    }
                    else if (emp.id === FeatureKey.EMPLOYEE_FIRST_OF_KIN && mypage.id === FeatureKey.FIRST_OF_KIN) {
                        emp.checked = mypage.checked;
                        emp.access = mypage.access;
                        return conTrue;
                    }
                    else if (emp.id === FeatureKey.EMPLOYEE_WORK_EXPERIENCE && mypage.id === FeatureKey.WORK_EXPERIENCE) {
                        emp.checked = mypage.checked;
                        emp.access = mypage.access;
                        return conTrue;
                    }
                })
            })
            defaultFeatures.access = true;
            defaultFeatures.checked = true;
            features.push(employeeFeatures, defaultFeatures);
        }
        features = this.setCheckedValue(features);
        this.businessDetails.Features = `${JSON.stringify(features)}`;
        this.subscriptions.push(this.featureService
            .updateBusinessFeature(this.businessId, this.businessDetails)
            .subscribe(result => {
                this.toasterComponent.callToast();
                // Call method in kuba component to reload the navigation component
                this.kubaService.reloadNavigationComponent();
                let currentUserRole = BaseServices.UserRole;
                if (currentUserRole !== 'Admin' && currentUserRole !== 'Portal' &&
                    currentUserRole !== 'Super' && currentUserRole !== 'Developer') {
                    this.getBusinessDetails();
                    this.featureBase.Features = `${JSON.stringify(features)}`;
                    let featuresAccess = FeaturesBaseServices.getFeatureDetails(this.featureBase);
                    this.featuresBaseServices.setFeatures(featuresAccess);
                    // to replace business id in our company
                    this.features = featuresAccess;
                    let index = _.findIndex(this.features, ['translationKey', 'OUR_COMPANY']);
                    if (index >= 0) {
                        this.features[index].linkText = _.replace(this.features[index].linkText, '%s', this.businessId.toString());
                    }
                } 
                //KW-653 - Start
                let supplierControlFeatures = features.filter(x => { return x.id == FeatureKey.SUPPLIERS; })[0];
                this.supplierChecked = supplierControlFeatures.checked;
                //KW-653 - End
            }));
    }

    setCheckedValue(featuresTemplate: Feature[]) {
        _.each(featuresTemplate, (data) => {
            data.checked = (data.checked == null) ? true : data.checked;
            if (this.roleId === 1 || this.roleId === 2) {
                data.access = (data.checked == null) ? true : data.checked;
            }
            if (data.children && data.children.length > 0) {
                this.setCheckedValue(data.children);
            }
            if (BaseServices.roleId === '1' || BaseServices.roleId === '2') {
                data.checked = ((data.IsDefault) && (data.name != 'Contacts')) ? true : data.checked;
            }
        });
        return featuresTemplate;
    }

    gotoList() {
        this.location.back()
    }

    /**
     * cancel upload
     * @param id
     */
    cancelUpload(id: string): void {
        this.uploadInput.emit({ type: 'cancel', id: id });
    }

    /**
     * removing uploaded file
     * @param id
     */
    removeFile(id: string): void {
        this.uploadInput.emit({ type: 'remove', id: id });
    }

    removeAllFiles(): void {
        this.uploadInput.emit({ type: 'removeAll' });
    }

    /**
     * r mode chage
     * @param event {any}
     * @param business {any}
     */
    rModeCheckFeature() {
        let business = this.businessInfo.filter((x:any) => x.Id === +this.businessId);
        if (this.remoteWindow) {
            this.remoteWindow.close();
        }
        this.cacheHandlerService.clearCachedData();
        let businessSetting = { 'BusinessName': business[0].CompanyName, 'BusinessLogo': business[0].BusinessLogo };
        sessionStorage.setItem('RModeBusiness', JSON.stringify(businessSetting));
        sessionStorage.setItem('RModeBusinessId', business[0].Id);
        let windowOrigin = window.location.origin;
        let windowControl = 'location=yes,width=' + screen.availWidth + ', height = ' + screen.availHeight
            + ', scrollbars = yes, status = yes';
        this.remoteWindow = window.open(windowOrigin + '/ik/app/business/' + business[0].Id + '/dashboard', 'Remote', windowControl)!;
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
}
