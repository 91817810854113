export class DeclarationOfConformity {
    Id: number;
    ProjectId: number;
    IsInConvenience: string;
    IsTasksatisfactorily: string;
    IsAdequatequalifications: string;
    IsToolsAvailable: string;
    IsRefprocedure1: string;
    IsRefprocedure2: string;
    IsRefprocedure3: string;
    IsMinutecheckdisconnected: string;
    IsMinutecheckundisconnected: string;
    IsMinutecheckclosetoundisconnected: string;
    RiskAnalysisDate: string;
    RiskAnalysisSignature: string;
    IsCheckFinalInspection: string;
    IsCheckFinalInspectionBelow: string;
    IsContactProtection: string;
    ContactProAcc: string;
    ContactProComments: string;
    ISGrounding: string;
    GroundingAcc: string;
    GroundingComments: string;
    IsMarking: string;
    MarkingAcc: string;
    MarkingCommnets: string;
    IsOptionsAndSettings: string;
    OptionsAndSettingsAcc: string;
    OptionsAndSettingsComments: string;
    ISAttachedConductor: string;
    AttachedConductorAcc: string;
    AttachedConductorComments: string;
    IsMeasurementsAndTests: string;
    MeasurementsAndTestsAcc: string;
    MeasurementsAndTestsComments: string;
    IsInsulationMeasurement: string;
    InsulationMeasurementAcc: string;
    InsulationMeasurementCommnets: string;
    IsEarthleakCircuit: string;
    EarthleakCircuitAcc: string;
    EarthleakCircuitComments: string;
    IsSafetyCircuits: string;
    SafetyCircuitsAcc: string;
    SafetyCircuitsComments: string;
    IsCableRoutings: string;
    CableRoutingsAcc: string;
    CableRoutingsCommnets: string;
    IsDocumentation: string;
    DocumentationAcc: string;
    DocumentationCommnets: string;
    InstallationDate: string;
    InstallationSignature: string;
    IsNewFacilities: string;
    IsExtension: string;
    IsChange: string;
    ISUsednorms: string;
    IsNEK: string;
    IsOthers: string;
    Others: string;
    ShortDescription: string;
    ConformityCompletedDate: string;
    ConformityCompletedSignature: string;
    CommentsToFacilities: string;
    CreatedBy: number;
    CreatedOn: string;
    ModifiedBy: number;
    ModifiedOn: string;
    BusinessId: number;
}
