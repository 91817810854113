import { EmployeeDocumentNode } from './employeeDocument';
export abstract class Common {
    Id: number;
    BusinessId: number;
    Status: string;
}

export class Employee extends Common {
    TypeOfEmployeeId: number;
    Number: string;
    HSE_Cardnumber: string;
    Name: string;
    Dob: string;
    EmployeePositionId: number;
    Percentage: number;
    BusinessDepartmentId: number;
    BusinessDepartmentIds: number[];
    BusinessDeptId: number;
    SubDepartmentId: number;
    DepartmentLead: boolean;
    Address: string;
    ZipOrCity: string;
    Location: string;
    Mobile: string;
    CountryCode: number;
    OtherCode: string;
    Telephone: string;
    Fax: string;
    Email: string;
    EmploymentFromDate: string;
    EmploymentEndDate: string;
    IsEmailNewDeviation: boolean;
    NotifyMyDeviationsChange: boolean;
    IsShowToGuest: boolean;
    IsMainContact: boolean;
    IsApprovalNotice: boolean;
    ApprovalDateTime: any;
    ShowInDashboard: boolean;
    ContactFunctionalAreaId: number;
    Avatar: string;
    IsSms: boolean;
    IsEmail: boolean;
    Note: string;
    NotifySuggestion: boolean;
    CreatedBy: number;
    CreatedOn: Date;
    ModifiedBy: number;
    ModifiedOn: string;
    Type: number;
    DepatmentLead: string;
    BusinessDepartment: BusinessDepartment;
    DepartmentIdRef: string;
    ParentId: string;
    EmployeeDocument: EmployeeDocumentNode[];
    ApplicationId: number;
    PortalId: number;

}

export class EmployeeType extends Common {
    Type: string;
}
export class EmployeePosition extends Common {
    Name: string;
    PortalId: number;
    CreatedBy: number;
    ModifiedBy: number;
}
export class BusinessDepartment extends Common {
    Name: string;
}
export class EmployeeRights {
    id: number;
    name: string;
    isChecked: boolean;
    rightsType: number;
}
export class Timetable {
    Id: number;
    EmployeeId: number;
    TypeOfWorkId: number;
    StartDate: Date;
    StartTime: Date;
    Month: string;
    Year: string;
    EndDate: Date;
    EndTime: Date;
    HoursSpent: Date;
    ApprovalUserId: number;
    TimeTableApproverId:number[];
    Comments: string;
    Status: number;
    ModifiedBy: number;
    ProjectWorkTypeName: string;
    EmployeeName: string;
    ModifierName: string;
    ProjectName: string;
    ApprovalDate: Date;
    BreakTime: string;
}

export class SubDepartment extends Common {
    Name: string;
    ParentId: number;
    CreatedBy: number;
    ModifiedBy: number;
    CreatedOn: Date;
    ModifiedOn: Date;
}

export class EmployeeDocument {
    Id: number;
    EmployeeId: number;
    FileName: string;
    FilePath: string;
    FileTypeId: string;
    IsPrivateDocument: boolean;
    IsCommonDocument: boolean;
    Status: number;
    CreatedBy: number;
    CreatedOn: Date;
    ModifiedBy: number;
    ModifiedOn: Date;
}

