import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxUploaderModule } from 'ngx-uploader';
import { FileWindowComponent } from './file-window.component';
import { StatusBarComponent } from './status-bar.component';
import { ExplorerComponent } from './explorer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchPipe, SearchByUserPipe } from '../_helpers/pipes';
import { EcplImageViewerModule } from '../ecpl-image-viewer/ecpl-image-viewer.module';
import { EcplArticleViewerModule } from '../ecpl-article-viewer/ecpl-article-viewer.module';
import { EcplChecklistViewerModule } from '../ecpl-checklist-viewer/ecpl-checklist-viewer.module';
import { MyDatePickerModule } from 'mydatepicker';
import { RouterModule } from '@angular/router';
import { KubaBulkReportService } from './file-window.service';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxPaginationModule } from 'ngx-pagination';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { EcplPdfViewerModule } from '../ecpl-pdf-viewer';
import { EcplOnlyofficeViewerModule } from '../ecpl-onlyoffice-viewer';
import { EcplLarViewerModule } from '../ecpl-lar-viewer';

@NgModule({
  declarations: [FileWindowComponent, StatusBarComponent, ExplorerComponent, SearchPipe, SearchByUserPipe],
  imports: [
    CommonModule,
    EcplPdfViewerModule,
    EcplOnlyofficeViewerModule,
    EcplImageViewerModule,
    EcplArticleViewerModule,
    EcplChecklistViewerModule,
    EcplLarViewerModule,
    NgxUploaderModule,
    FormsModule,
    RouterModule,
    DialogModule,
    InputTextModule,
    ButtonModule,
    ReactiveFormsModule,
    ClipboardModule,
    MyDatePickerModule,
    NgxPaginationModule,
    DropdownModule,
    TranslateModule
  ],
  exports: [FileWindowComponent, StatusBarComponent, ExplorerComponent],
  providers: [KubaBulkReportService]
})
export class EcplDocumentExplorerModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: EcplDocumentExplorerModule
    };
  }
}
