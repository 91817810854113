import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

type Theme = 'medi3-theme.css' | 'el-theme.css' | 'moelven-theme.css' | '';

export enum ThemeId {
  DEFAULT = 1,
  EL = 7,
  MOELVEN = 8,
  MEDI3 = 9,
}

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private styleLink: HTMLLinkElement | null = null;

  setTheme(themeId: ThemeId): void {
    const themeUrl = this.getThemeUrlById(themeId);

    if (!this.styleLink) {
      this.styleLink = document.createElement('link');
      this.styleLink.id = 'appStyle';
      this.styleLink.rel = 'stylesheet';
      this.styleLink.type = 'text/css';
    }

    if (!themeUrl) {
      this.styleLink.href = '';
    } else {
      const timeNow = (Date.now() / 1000) | 1;
      this.styleLink.href = themeUrl + '?ver=' + timeNow;
    }
  }

  applyTheme(): void {
    if (this.styleLink) {
      if (document.head.contains(this.styleLink)) {
        document.head.removeChild(this.styleLink);
      }
      document.head.appendChild(this.styleLink);
    }
  }

  private getThemeUrlById(themeId: ThemeId): Theme {
    switch (themeId) {
      case ThemeId.DEFAULT:
        return '';
      case ThemeId.MEDI3:
        return 'medi3-theme.css';
      case ThemeId.EL:
        return 'el-theme.css';
      case ThemeId.MOELVEN:
        return 'moelven-theme.css';

      default:
        return '';
    }
  }
}
