import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import * as _ from 'lodash';
import { Zone } from './../../foodsafety/models/zone';
import { Department } from './../../deviation/models/deviationDepartment';
import { FoodSafety } from './../../foodsafety/models/foodsafety';
import { environment } from 'src/environments/environment';

import { BaseServices } from './../../kuba.services';
import { RegistrationType } from './../../Registration/models/registration-type';
import { FoodSafetyManualCompleteChecklist } from '../../shared/models/complete-checklist';
import { HttpClient } from '@angular/common/http';
import { ObjectType } from '../../foodsafety/models/TypeObject';
import { AuthenticationService } from 'src/app/_services';

@Injectable()
export class KubaControlService {
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) {}

  getAllByBusinessId(bid: number) {
    return this.http
      .get(environment.BASE_URL + '/kubacontrol/kuba-controls/' + bid)
      .map((result: any) => result);
  }

  getFoodsafetyById(id: number) {
    return this.http
      .get(environment.BASE_URL + '/kubacontrol/kuba-control/' + id)
      .map((result: any) => result);
  }
  getZoneName(businessId: number) {
    return this.http
      .get(environment.BASE_URL + '/kubacontrol/zones/' + businessId)
      .map((result: any) => result);
  }
  // get type of object
  getTypeOfObject(businessId: number) {
    return this.http
      .get(environment.BASE_URL + '/kubacontrol/typeOfObject/' + businessId)
      .map((result: any) => result);
  }

  // add  the department
  addDepartment(data) {
    let result: Department;
    return this.http
      .post(environment.BASE_URL + '/department', data)
      .map(result => result);
  }
  // update  the department
  updateDepartment(department: Department) {
    let result: Department;
    return this.http
      .put(environment.BASE_URL + '/department/' + department.Id, department)
      .map(result => result);
  }
  // delete  the department
  deleteDepartment(Id: number) {
    let result: boolean;
    return this.http.delete(environment.BASE_URL + '/departmentdelete/' + Id);
  }
  getObject(businessId: number) {
    return this.http
      .get(environment.BASE_URL + '/kubacontrol/typeOfObject/' + businessId)
      .map(result => result);
  }

  updateObject(objectType) {
    let result: ObjectType;
    return this.http
      .put(
        environment.BASE_URL + '/kubacontrol/typeOfObject/' + objectType.Id,
        objectType
      )
      .map(result => result);
  }

  addObject(objectType) {
    let result: ObjectType;
    return this.http
      .post(environment.BASE_URL + '/kubacontrol/typeOfObject', objectType)
      .map(result => result);
  }

  deleteObject(Id: number) {
    let result: boolean;
    return this.http.delete(
      environment.BASE_URL + '/kubacontrol/typeOfObject/' + Id
    );
  }

  checkAllocatedObjectType(departmentID: number) {
    return this.http
      .get(
        environment.BASE_URL +
          '/kubacontrol/checkObjectTypeforkubacontrol/' +
          departmentID
      )
      .map(result => result);
  }

  getZone(businessId: number) {
    return this.http
      .get(environment.BASE_URL + '/kubacontrol/zones/' + businessId)
      .map(result => result);
  }
  addZone(zone) {
    let result: Zone;
    return this.http
      .post(environment.BASE_URL + '/kubacontrol/zone', zone)
      .map(result => result);
  }
  updateZone(zone) {
    let result: Zone;
    return this.http
      .put(environment.BASE_URL + '/kubacontrol/zone/' + zone.Id, zone)
      .map(result => result);
  }
  checkAllocatedDepartment(departmentID: number) {
    return this.http
      .get(
        environment.BASE_URL + '/checkdepartmentforfoodsafety/' + departmentID
      )
      .map(result => result);
  }
  checkAllocatedZone(departmentID: number) {
    return this.http
      .get(
        environment.BASE_URL +
          '/kubacontrol/checkzoneforkubacontrol/' +
          departmentID
      )
      .map(result => result);
  }
  deleteZone(Id: number) {
    let result: boolean;
    return this.http.delete(environment.BASE_URL + '/kubacontrol/zone/' + Id);
  }

  createFoodsafety(foodSafety: FoodSafety) {
    return this.http
      .post(environment.BASE_URL + '/kubacontrol/kuba-control', foodSafety)
      .map(result => result);
  }

  updateFoodsafety(foodSafety: FoodSafety) {
    return this.http
      .put(environment.BASE_URL + '/kubacontrol/kuba-control/', foodSafety)
      .map(result => result);
  }

  deleteFoodSafety(id: number) {
    let result: boolean;
    return this.http.delete(
      environment.BASE_URL + '/kubacontrol/kuba-control/' + id
    );
  }

  getFoodsafetyFeatures(businessId: number) {
    let featureId: number;
    let workingModule = JSON.parse(sessionStorage.getItem('workingModule'));
    featureId = workingModule.id;
    return this.http
      .get(environment.BASE_URL + '/business/' + businessId)
      .map((result: any) => {
        let feature = result.Features;
        let foodsafetyAdditionalFeature = _.find(JSON.parse(feature), {
          id: featureId,
          access: true
        });
        return foodsafetyAdditionalFeature;
      });
  }

  createCompleteChecklist(
    FoodSafetyManualCompleteChecklist: FoodSafetyManualCompleteChecklist
  ) {
    return this.http
      .post(
        environment.BASE_URL + '/kubacontrol/CompleteAllChecklist',
        FoodSafetyManualCompleteChecklist
      )
      .map((response: Response) => {
        return response;
      });
  }

  // update registration Type
  updateRegType(existingCategory: RegistrationType, key: number) {
    return this.http
      .put(
        `${environment.BASE_URL}/kubacontrol/registrationtype`,
        existingCategory
      )
      .map(result => result);
  }

  // adding registration Type
  createRegType(newCategory: RegistrationType, key: number) {
    return this.http
      .post(`${environment.BASE_URL}/kubacontrol/registrationtype`, newCategory)
      .map(result => result);
  }

  getFoodByUser(Id: number) {
    return this.http
      .get(environment.BASE_URL + '/kubacontrol/getuserKC/' + Id)
      .map((result: any) => result);
  }

  getZonesbyBusiness() {
    return this.http
      .get(
        environment.BASE_URL +
          '/kubacontrol/getzonesbybusiness/' +
          BaseServices.BusinessId
      )
      .map((result: any) => result);
  }

  getFSDailyTaskList(fsId: number) {
    return this.http
      .get(environment.BASE_URL + '/kubacontrol/dailytask/list/' + fsId)
      .map((result: any) => result);
  }

  foodSafetyAuditList(featureKey: number, minDate, maxDate, isShiftRight) {
    return this.http
      .get(
        environment.BASE_URL +
          `/kubacontrol/foodsafetyauditlist/${featureKey}/${minDate}/${maxDate}/${isShiftRight}`
      )
      .map((result: any) => result);
  }

  saveHazardSectionsAndSubSections(hazardAnalysis) {
    return this.http
      .post(
        environment.BASE_URL + '/kubacontrol/hazardAnalysis/save',
        hazardAnalysis
      )
      .map(result => result);
  }

  getHazardAnalysis(foodSafetyId: number) {
    return this.http
      .get(
        environment.BASE_URL + '/kubacontrol/gethazardAnalysis/' + foodSafetyId
      )
      .map((result: any) => result);
  }

  deleteHazardSection(Id: number) {
    return this.http
      .delete(environment.BASE_URL + '/kubacontrol/deletehazardsection/' + Id)
      .map(result => result);
  }

  deleteHazardSubSection(Id: number) {
    return this.http
      .delete(
        environment.BASE_URL + '/kubacontrol/deletehazardsubsection/' + Id
      )
      .map(result => result);
  }

  foodSafetyAuditDateList(featureKey: number) {
    return this.http
      .get(environment.BASE_URL + '/kubacontrol/getdatelist/' + featureKey)
      .map((result: any) => result);
  }

  exportFSHazardAnalysis(appId: number, bId: number, FSId: number, CulInfo) {
    let dynamicType = 'application/pdf';
    let url = `${environment.BASE_URL}/kubacontrol/exportHazardAnalysis/${appId}/${bId}/${FSId}/${CulInfo}`;
    let crfToken = this.authenticationService.getCsrfToken();
    return Observable.create(observer => {
      let xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.withCredentials = true;
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('Logo-Id', 'ab064fd4-9b4a-4e70-972d-dafdaceb2aba');
      xhr.setRequestHeader('X-XSRF-TOKEN', crfToken);
      xhr.responseType = 'blob';
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            let contentType = dynamicType;
            let blob = new Blob([xhr.response], { type: contentType });
            observer.next(blob);
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };
      xhr.send();
    });
  }

  exportFSAudit(
    cultureInfo: string,
    featureId: number,
    BusinessId,
    UserId,
    auditData,
    zoneId: number,
    departmentName
  ) {
    let dynamicType = 'application/pdf';
    let myData = JSON.stringify({ data: auditData });
    let url = `${environment.BASE_URL}/export-FSAudit/${cultureInfo}/${featureId}/${BusinessId}/${UserId}/${zoneId}/${departmentName}`;
    return Observable.create(observer => {
      let xhr = new XMLHttpRequest();
      xhr.open('POST', url, true);
      xhr.withCredentials = true;
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.responseType = 'blob';
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            let contentType = dynamicType;
            let blob = new Blob([xhr.response], { type: contentType });
            observer.next(blob);
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };
      xhr.send(myData);
    });
  }

  exportKCAudit(
    cultureInfo: string,
    featureId: number,
    BusinessId,
    UserId,
    auditData,
    zoneId: string,
    departmentName
  ) {
    let dynamicType = 'application/pdf';
    let myData = JSON.stringify({ data: auditData });
    let url = `${environment.BASE_URL}/export-KCAudit/${cultureInfo}/${featureId}/${BusinessId}/${UserId}/${zoneId}/${departmentName}`;
    return Observable.create(observer => {
      let xhr = new XMLHttpRequest();
      xhr.open('POST', url, true);
      xhr.withCredentials = true;
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.responseType = 'blob';
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            let contentType = dynamicType;
            let blob = new Blob([xhr.response], { type: contentType });
            observer.next(blob);
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };
      xhr.send(myData);
    });
  }

  getFSZoneName(fsId: number) {
    return this.http
      .get(environment.BASE_URL + '/kubacontrol/getzonename/' + fsId)
      .map(result => result);
  }

  getCompleteTempControlPDF(
    featureId: number,
    featureKey: number,
    tempControlChecklistId: number,
    completeChecklistId: number
  ) {
    return this.http
      .get(
        environment.BASE_URL +
          '/kubacontrol/getfstempcontrolpdf/' +
          featureId +
          '/' +
          featureKey +
          '/' +
          tempControlChecklistId +
          '/' +
          completeChecklistId
      )
      .map(result => result);
  }

  exportFSAuditForTempControl(
    cultureInfo: string,
    featureId: number,
    BusinessId,
    UserId,
    ChecklistId,
    CompleteChecklistId,
    dataList
  ) {
    let dynamicType = 'application/pdf';
    let myData = JSON.stringify({ data: dataList });
    let url = `${environment.BASE_URL}/export-FSAuditTempConrtol/${cultureInfo}/${featureId}/${BusinessId}/${UserId}/${ChecklistId}/${CompleteChecklistId}`;
    return Observable.create(observer => {
      let xhr = new XMLHttpRequest();
      xhr.open('POST', url, true);
      xhr.withCredentials = true;
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.responseType = 'blob';
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            let contentType = dynamicType;
            let blob = new Blob([xhr.response], { type: contentType });
            observer.next(blob);
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };
      xhr.send(myData);
    });
  }

  exportFSAuditForDailyTask(activityId: number, cultureInfo: string) {
    let dynamicType = 'application/pdf';
    let myData = JSON.stringify({ data: activityId });
    let url = `${environment.BASE_URL}/export-FSAuditDailyTask/${activityId}/${cultureInfo}`;
    return Observable.create(observer => {
      let xhr = new XMLHttpRequest();
      xhr.open('POST', url, true);
      xhr.withCredentials = true;
      xhr.setRequestHeader('Content-Type', 'application/json');
      // set authorization header
      xhr.responseType = 'blob';
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            let contentType = dynamicType;
            let blob = new Blob([xhr.response], { type: contentType });
            observer.next(blob);
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };
      xhr.send(myData);
    });
  }

  deleteHazardForm(foodSafetyId: number) {
    return this.http
      .delete(
        environment.BASE_URL + '/kubacontrol/deletehazardform/' + foodSafetyId
      )
      .map(result => result);
  }

  saveReasonForLateTaskCompletion(data) {
    return this.http
      .post(environment.BASE_URL + '/kubacontrol/saveReason', data)
      .map(result => result);
  }

  getAuditInformation(Id: number, Type: string) {
    return this.http
      .get(
        `${environment.BASE_URL}/kubacontrol/foodsafetyauditDetails/${Id}/${Type}`
      )
      .map(result => result);
  }

  toDoList(featureKey: number) {
    return this.http
      .get(
        `${environment.BASE_URL}/kubacontrol/ToDoList/${BaseServices.BusinessId}/${featureKey}`
      )
      .map((result: any) => result);
  }

  saveDocs(documents, zoneId) {
    return this.http
      .post(
        environment.BASE_URL +
          '/kubacontrol/ToDoList/' +
          zoneId +
          '/private-docs',
        documents
      )
      .map((result: any) => result);
  }

  getAllApprovedForKubaControlToDoList(featureKey: number) {
    return this.http
      .get(
        `${environment.BASE_URL}/kubacontrol/ToDoList/approval/${BaseServices.BusinessId}/${featureKey}`
      )
      .map((result: any) => result);
  }

  getTodoDocumnet(featureKey: number, userId: number) {
    return this.http
      .get(
        `${environment.BASE_URL}/kubacontrol/ToDoDocument/${BaseServices.BusinessId}/${featureKey}/${userId}`
      )
      .map((result: any) => result);
  }
  updateApprovel(document, zoneId) {
    return this.http
      .put(
        environment.BASE_URL +
          '/kubacontrol/Approval/private-doc/' +
          zoneId +
          '/' +
          BaseServices.userLanguageId,
        document
      )
      .map((result: any) => result);
  }

  delete(ZoneId, id: number) {
    return this.http
      .delete(
        environment.BASE_URL + '/kubacontrol/private-doc/' + ZoneId + '/' + id
      )
      .map((result: any) => result);
  }

  /**
   * delete documents
   * @param Id {any}
   */
  deleteEmployeeDocument(Id: any) {
    return this.http
      .delete(
        environment.BASE_URL + '/kubacontrol/delete-employee-document/' + Id
      )
      .map((result: any) => result);
  }
}
