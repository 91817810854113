import { Component, OnInit, Input, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
  FormArray,
} from '@angular/forms';
import { DatePipe, Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { UserRole } from './../../follow-ups/models/deviation';
import { EmployeeServices } from './../services/employee.services';
import { Options } from 'src/app/_models/options';
import { EmployeePrivateDocumentServices } from './../services/employeeprivatedocument.service';
import { UploaderConfig } from './../../../shared/ecpl-document-explorer/uploader-config';
import { Rights } from './../../../_models/feature';
import { KubaServices } from './../../kuba.services';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { environment } from 'src/environments/environment';
import { ValidationService } from './../../shared/services/validation.service';
import { ToasterComponent } from './../../../_directives/toaster.component';
import { HelperService, PdfSetting } from './../../../_services/helper.service';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { EmployeeSicknessServices } from '../services/employeesickness.service';
import { IMyDpOptions, IMyDateModel, IMyDate } from 'mydatepicker';
import {
  EmployeeSicknessAbscence,
  SicknessAbsenceDocumentDto,
  SicknessAbsenceBusinessAbsenceCodeDto,
  SicknessAbsenceFollowUp,
  Sickness,
} from './../models/employeeSicknessAbscence';
import { EmployeeDocument } from 'src/app/kuba/employees/models';
import { EmployeeLeaveService } from '../services/employeeleave.service';
import { Subscription } from 'rxjs';
import { switchMap, filter, map } from 'rxjs/operators';

@Component({
  moduleId: module.id,
  selector: 'sickness-edit',
  templateUrl: 'employee-sickness-edit.component.html',
})
export class SicknessEditComponent implements OnInit {
  isUserOrNot: boolean = false;
  //#region variables
  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  IsShowPercentage = false;
  upConfig: UploaderConfig;
  @Input() sicknessForm: FormGroup;
  additionalData: any;
  additionalDataForUpload: any;
  persons: SelectItem[];
  approvePersons: any;
  uploadedFiles = [];
  loading = false;
  newUploadedFiles: any[] = [];
  ResponsibleExecutionType: SelectItem[];
  ResponsibleFollowUpType: SelectItem[];
  sicknessId: number;
  employeeId: number;
  IsHide = true;
  showFollowUp = true;
  IsVisible = true;
  documents: EmployeeDocument[];
  value: boolean;
  percentageValues: SelectItem[] = [];
  sicknessobject: EmployeeSicknessAbscence[];
  sicknessAbsenceFollowUp: SicknessAbsenceFollowUp[] = [];
  sicknessFollowUpForm = new FormArray([]);
  employeeSickness: any = new EmployeeSicknessAbscence();
  categories: any[];
  dialogContent: string;
  showCommonModal: boolean;
  isShowWeekend = false;
  StopAbsencePayment: any;
  isGrid = false;
  isUser = false;
  totalDates: any = [];
  formattedTotalDates: any = [];
  isApprovalPerson: boolean;
  locale = this.translator.currentLang ? this.translator.currentLang : 'en';
  options = [
    new Options(8, 'OPEN'),
    new Options(6, 'APPROVED'),
    new Options(10, 'REJECTED'),
  ];
  leaveApprovalPerson: number;
  statusChangeByUser: boolean;
  leaveSaveByUser = false;
  isNotUser: boolean;
  editOwnStatus: boolean;
  isChildage = false;
  Other = false;
  workingModule: any;
  userRights = false;
  IsShowHours = false;
  HoursValues: SelectItem[];
  Time: number;
  TimeList = [
    '0.5',
    '1.0',
    '1.5',
    '2.0',
    '2.5',
    '3.0',
    '3.5',
    '4.0',
    '4.5',
    '5.0',
    '5.5',
    '6.0',
    '6.5',
    '7.0',
    '7.5',
  ];
  TotalOpenHours: any;
  TotalApprovedHours: any;
  TotalRejectedHours: any;
  isPortal: boolean;
  calculateNoOfDay: any;
  OpenNoOfDay: any;
  ApprovedNoOfDay: any;
  RejectedNoOfDay: any;
  startDate: any;
  endDate: any;
  holidayDateFormat: any[] = [];
  private subscriptions: Subscription[] = [];

  //#endregion

  //#region setting calendar option
  /**
   * Date picker configuration option
   */
  public startDateOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
    disableWeekends: !this.isShowWeekend,
    editableDateField: false,
    openSelectorOnInputClick: true,
    firstDayOfWeek: 'su',
    satHighlight: true,
    height: '26px',
    selectionTxtFontSize: '14px',
    todayBtnTxt: this.translate.instant('TODAY'),
    dayLabels: {
      su: this.translate.instant('SUN'),
      mo: this.translate.instant('MON'),
      tu: this.translate.instant('TUE'),
      we: this.translate.instant('WED'),
      th: this.translate.instant('THU'),
      fr: this.translate.instant('FRI'),
      sa: this.translate.instant('SAT'),
    },
    monthLabels: {
      1: this.translate.instant('JANUARY'),
      2: this.translate.instant('FEBRUARY'),
      3: this.translate.instant('MARCH'),
      4: this.translate.instant('APRIL'),
      5: this.translate.instant('MAY'),
      6: this.translate.instant('JUNE'),
      7: this.translate.instant('JULY'),
      8: this.translate.instant('AUGUST'),
      9: this.translate.instant('SEPTEMBER'),
      10: this.translate.instant('OCTOBER'),
      11: this.translate.instant('NOVEMBER'),
      12: this.translate.instant('DECEMBER'),
    },
    disableDays: this.getHolidayList(),
  };
  public endDateOptions: IMyDpOptions = this.startDateOptions;
  Approver: any;
  ApproverRights: boolean = true;
  isEditor: boolean;
  isGuest: boolean;
  isAdmin: boolean;
  userId: number;
  ApprovalPermission: boolean = false;
  //#endregion

  //#region calendar validation
  /**
   * validation
   * @param group {FormGroup}
   */
  private static sicknessAbsenceValidation(group: FormGroup) {
    let errorCode;
    errorCode = ValidationService.startDateLessEqualEndDate(group);
    return errorCode;
  }
  //#endregion

  //#region constructors
  /**
   * constructor
   * @param fb {fb}
   * @param employeeSicknessServices {employeeSicknessServices}
   * @param location {location}
   * @param route {route}
   * @param router {router}
   * @param confirmationService {confirmationService}
   * @param translator {translator}
   * @param kubaServices {kubaServices}
   */
  constructor(
    private fb: FormBuilder,
    public employeeSicknessServices: EmployeeSicknessServices,
    protected location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    private translator: TranslateService,
    private kubaServices: KubaServices,
    private translate: TranslateService,
    private employeeServices: EmployeeServices,
    public employeeLeaveService: EmployeeLeaveService
  ) {
    this.categories = this.route.snapshot.data['sickness'];
    this.subscriptions.push(
      this.kubaServices.getLanguage$.subscribe((lang) => {
        this.locale = lang;
      })
    );
    this.subscriptions.push(
      this.router.routerState.root.queryParams.subscribe((params) => {
        if (params['emp']) {
          this.employeeId = +params['emp'];
        } else {
          this.employeeId = +route.snapshot.params['eid'];
        }
      })
    );

    this.initForm();
  }
  //#endregion

  //#region page-events
  /**
   * page load event binding sickness table and binding event
   */
  ngOnInit() {
    document.querySelector('body').classList.remove('opened');
    // TODO: need to send dynamic values
    this.isNotUser = +BaseServices.roleId === UserRole.USER ? false : true;
    this.isUserOrNot = +BaseServices.roleId === UserRole.USER ? true : false;
    this.isEditor = +BaseServices.roleId === UserRole.EDITOR ? true : false;
    this.isGuest = +BaseServices.roleId === UserRole.GUEST ? true : false;

    this.additionalDataForUpload = {
      ApplicationId: BaseServices.ApplicationId,
      BusinessId: BaseServices.BusinessId,
      EmployeeId: this.employeeId,
      IsPrivateDocument: true,
      Status: '1',
      CreatedBy: BaseServices.UserId,
      CreatedOn: '',
      ModifiedBy: BaseServices.UserId,
      ModifiedOn: '',
    };

    this.isAdmin =
      BaseServices.userSettings.userProfile.RoleId === UserRole.ADMIN
        ? true
        : false;

    if (this.isAdmin == true) {
      this.employeeServices
      .getUserByEmployeeId(this.employeeId)
      .pipe(
        filter((id: number) => id > 0), // Only proceed if id is greater than 0
        switchMap((id: number) => {
          this.userId = id;
          return this.kubaServices.getUserFeaturesByUserId(
            this.userId,
            +BaseServices.BusinessId,
            +BaseServices.ApplicationId,
            +BaseServices.PortalId,
            +BaseServices.roleId
          );
        }),
        map((rights) => JSON.parse(rights)),
        map((userRights) => {
          const approver = userRights?.filter(
            (x: { name: string }) => x.name === 'APPROVE_ABSENCE'
          );
          return approver && approver.length > 0 && approver[0].isChecked === true;
        })
      )
      .subscribe((hasApproverRights: boolean) => {
        this.ApproverRights = hasApproverRights;
      });
    } else if (this.isUserOrNot == true || this.isEditor == true) {
      let userRights = BaseServices.getUserRights();
      userRights = JSON.parse(userRights);
      if (userRights != null) {
        this.Approver = userRights.filter((x) => x.name == 'APPROVE_ABSENCE');
      }
      if (
        this.Approver != null &&
        this.Approver.length > 0 &&
        this.Approver[0].isChecked === true
      ) {
        this.ApproverRights = true;
      } else {
        this.ApproverRights = false;
      }
    }
    if (this.isGuest == true) {
      this.ApproverRights = false;
    }
    this.upConfig = {
      title: 'UPLOAD_DOCUMENT',
      titleAsLabel: true,
      editMode: true,
      windowedHeight: false,
      viewSwitch: false,
      showUserMeta: false,
      showSearchFilter: false,
      showUploadButton: true,
      showDeleteButton: true,
      uploaderUri:
        environment.BASE_URL + '/file/upload/business/employee/private-docs',
      addtionalData: this.additionalDataForUpload,
    };
    if (this.route.snapshot.data['parent']) {
      this.bindDropDowns();
      if (this.route.snapshot.data['parent'] === 'EMPLOYEE') {
        this.initForm();
        this.leaveSaveByUser = true;
        this.isGrid = false;
      } else if (this.route.snapshot.data['parent'] === 'SICKNESS') {
        let data = this.route.snapshot.data['editSickness'];
        data.Status = data.Status && data.Status === 7 ? 8 : data.Status;
        data.Status = data.Status.toString();
        if (data) {
          this.initForm(data);
          this.leaveApprovalPerson = data.ApprovalPerson
            ? data.ApprovalPerson
            : 0;
          this.bindControls(data);
          let currentUserRole = BaseServices.UserRole;
          let userRightsId = Rights.APPROVE_ABSENSE;
          let rights = BaseServices.checkUserRights(
            userRightsId,
            currentUserRole
          );
          if (rights === true) {
            this.isApprovalPerson = false;
          } else {
            this.isApprovalPerson = true;
          }
          this.approvalPermission(data);
          this.isGrid = true;

          this.workingModule = JSON.parse(
            sessionStorage.getItem('workingModule')!
          );

          let role = BaseServices.UserRole;
          if (role == 'Editor') {
            this.isApprovalPerson = false;
          }
          if (role === 'User') {
            if (this.workingModule.id == 8) {
              if (
                data.ApprovalPersonList.some(
                  (e: any) => e === BaseServices.UserId
                )
              ) {
                this.userRights = false;
              } else {
                this.userRights = true;
              }
            }
          }
        }
      }
      if (BaseServices.roleId === '5') {
        this.isUser = true;
      }

      let docList = this.route.snapshot.data['doclist'];

      if (docList && docList.length > 0) {
        this.uploadedFiles = this.route.snapshot.data['doclist'];
      }
      if (this.employeeId) {
        this.subscriptions.push(
          this.employeeSicknessServices
            .getEmployeeSicknessByEmployeeId(this.employeeId)
            .subscribe((result: any) => {
              this.sicknessobject = result;
              this.TotalOpenHours = '0.0';
              this.TotalApprovedHours = '0.0';
              this.TotalRejectedHours = '0.0';
              let openHours = 0;
              let approvedHours = 0;
              let rejectedHours = 0;
              this.sicknessobject.forEach((x: any) => {
                if (x.TotalHours != null && x.TotalHours != 0.0) {
                  let TotalHours: number;
                  TotalHours = parseFloat(x.TotalAbsenceHours);
                  x.TotalAbsenceHours = TotalHours.toFixed(1);
                  if (x.StatusText == 'OPEN' && x.TotalAbsenceHours != null) {
                    openHours += x.TotalHours;
                  } else if (
                    x.StatusText == 'APPROVED' &&
                    x.TotalAbsenceHours != null
                  ) {
                    approvedHours += x.TotalHours;
                  } else if (
                    x.StatusText == 'REJECTED' &&
                    x.TotalAbsenceHours != null
                  ) {
                    rejectedHours += x.TotalHours;
                  }
                }
              });
              this.TotalOpenHours = openHours.toFixed(1);
              this.TotalApprovedHours = approvedHours.toFixed(1);
              this.TotalRejectedHours = rejectedHours.toFixed(1);
              this.sicknessPercentage(result);
            })
        );
      }
      // pre-populate the percentage dropdown
      this.subscriptions.push(
        this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
          this.percentageValues.push({ label: val.SELECT, value: null });
          for (let n = 10; n <= 100; n += 10) {
            this.percentageValues.push({ label: n + '%', value: n });
          }
        })
      );
    }
    

    this.subscriptions.push(
      this.translate.stream('FILENAME').subscribe((val) => {
        this.additionalData = {
          fileName: val.SICKNESS_ABSENCE_LIST,
          header: 'Sickness Absence List',
          businessId: BaseServices.BusinessId,
          cultureInfo: BaseServices.userCultureInfo(),
          columnNames: [
            { title: 'Employee Name', dataKey: 'EmployeeName' },
            { title: 'Start Date', dataKey: 'StartDateExp' },
            { title: 'End Date', dataKey: 'EndDateExp' },
            { title: 'No Of Days', dataKey: 'NoOfDays' },
            { title: 'Hours', dataKey: 'TotalAbsenceHours' },
            { title: 'Status', dataKey: 'StatusText' },
            { title: 'Reason', dataKey: 'Reason' },
            { title: 'Approval Person', dataKey: 'ApprovalPersonName' },
            { title: 'Date Approval', dataKey: 'AprDate' },
            { title: 'Description', dataKey: 'Description' },
          ],
        };
      })
    );
    this.workingModule = JSON.parse(sessionStorage.getItem('workingModule'));
    this.HoursValues = [];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.HoursValues.push({ label: val.SELECT, value: null });
        for (let time of this.TimeList) {
          this.HoursValues.push({ label: time, value: +time });
        }
      })
    );
    this.holidayDateFormat = this.getHolidayWithDateFormat();
  }
  /**
   * give rights to save
   * @param data {any}
   */
  bindControls(data: any) {
    // edit employee and login user is same can change information except status
    if (data.ApprovalPerson === BaseServices.UserId) {
      this.editOwnStatus = true;
      if (data.EmployeeId === BaseServices.FeatureKey) {
        this.leaveSaveByUser = true;
      }
    } else if (data.EmployeeId === BaseServices.FeatureKey) {
      this.editOwnStatus = false;
      this.leaveSaveByUser = true;
    } else {
      this.editOwnStatus = false;
      this.leaveSaveByUser = false;
    }
  }

  //#endregion

  formatDate(stringDate: any) {
    let replaceDate = stringDate.replace('/', '-').replace('/', '-');
    let splitDate = replaceDate.split('-');
    let formattedDate: any;
    let date = splitDate[0];
    let month = splitDate[1];
    let year = splitDate[2];
    return (formattedDate = month + '/' + date + '/' + year);
  }

approvalPermission(data){
  if(this.isUserOrNot == true){
    this.ApproverRights = data.ApprovalPersonList.some(personId => personId === BaseServices.UserId);
            this.ApprovalPermission = !this.ApproverRights;
            this.upConfig.showDeleteButton = this.ApproverRights;
            this.upConfig.showUploadButton = this.ApproverRights;
            // Set disabled state for each control in the FormArray
            const checkboxArray = this.sicknessForm.get('checkboxProperties') as FormArray;
            checkboxArray.controls.forEach(control => {
              if (this.ApprovalPermission) {
                control.get('checked')?.disable();
              } else {
                control.get('checked')?.enable();
              }
            });
  }else{
    this.upConfig.showDeleteButton = true;
    this.upConfig.showUploadButton = true;
  }
}


  //#region control-events
  /**
   * Event handler for Start date
   * @param event {IMyDateModel}
   */
  onStartDateChanged(event: IMyDateModel) {
    this.endDateOptions = this.setDateInput(
      this.endDateOptions,
      event,
      'startDate'
    );
    let startDate: Date = event.jsdate
      ? new Date(event.jsdate.getTime())
      : null!;
    let fromDate: Date = event.jsdate
      ? new Date(event.jsdate.getTime())
      : null!;
    this.startDate = startDate;
    this.CalculateHours();
    let sicknessForm = this.sicknessForm.value;
    let endDateValue = sicknessForm.dates.EndDate;
    let toDate: Date =
      endDateValue && endDateValue.jsdate
        ? new Date(endDateValue.jsdate.getTime())
        : null!;
    // formatting the date array
    if (fromDate && toDate) {
      this.totalDates = this.getDates(fromDate, toDate);
      this.totalDates.forEach((element: any) => {
        this.formattedTotalDates.push(
          HelperService.formatDateForFilter(element)
        );
      });
    }
    if (this.sicknessId > 0) {
      let stringDate = endDateValue.formatted;
      let endDate = new Date(this.formatDate(endDateValue.formatted));
      this.totalDates = this.getDates(fromDate, endDate);
      this.totalDates.forEach((element: any) => {
        this.formattedTotalDates.push(
          HelperService.formatDateForFilter(element)
        );
      });
      this.setNoOfDays(startDate, endDate);
    } else {
      let endDate: Date =
        endDateValue && endDateValue.jsdate
          ? new Date(endDateValue.jsdate.getTime())
          : null!;
      this.setNoOfDays(startDate, endDate);
    }
  }
  /**
   * getting date array
   */
  getDates(fromDate: any, toDate: any) {
    let arr = [];
    let dt: any;
    for (arr = [], dt = fromDate; dt <= toDate; dt.setDate(dt.getDate() + 1)) {
      arr.push(new Date(dt));
    }
    return arr;
  }

  /**
   * Event handler for End date
   * @param event {IMyDateModel}
   */
  onEndDateChanged(event: IMyDateModel) {
    this.startDateOptions = this.setDateInput(
      this.startDateOptions,
      event,
      'endDate'
    );
    let endDate: Date = event.jsdate ? new Date(event.jsdate.getTime()) : null!;
    let toDate: Date = event.jsdate ? new Date(event.jsdate.getTime()) : null!;
    this.endDate = endDate;
    this.CalculateHours();
    let startDateValue = this.sicknessForm.value.dates.StartDate;
    let fromDateValue = this.sicknessForm.value.dates.StartDate;
    let fromDate: Date =
      fromDateValue && fromDateValue.jsdate
        ? new Date(fromDateValue.jsdate.getTime())
        : null!;
    if (fromDate && toDate) {
      this.totalDates = this.getDates(fromDate, toDate);
      this.totalDates.forEach((element: any) => {
        this.formattedTotalDates.push(
          HelperService.formatDateForFilter(element)
        );
      });
    }
    if (this.sicknessId > 0) {
      let startDate = new Date(this.formatDate(fromDateValue.formatted));
      this.totalDates = this.getDates(startDate, toDate);
      this.totalDates.forEach((element: any) => {
        this.formattedTotalDates.push(
          HelperService.formatDateForFilter(element)
        );
      });
      // re-asigning again because startdate is changing after getting total dates
      let firstDate = new Date(this.formatDate(fromDateValue.formatted));
      this.setNoOfDays(firstDate, endDate);
    } else {
      let startDate: Date =
        startDateValue && startDateValue.jsdate
          ? new Date(startDateValue.jsdate.getTime())
          : null!;
      this.setNoOfDays(startDate, endDate);
    }
  }
  /**
   * document upload
   * @param event{any}
   */
  onUploadDoc(event: any) {
    for (let file of event.files) {
      this.uploadedFiles = [];
      let doc = new SicknessAbsenceDocumentDto();
      doc.OriginalFileName = file.name;
      doc.EmployeeId = this.employeeId;
      doc.FileTypeId = '1';
      doc.Status = '1';
      this.uploadedFiles.push(doc);
    }
  }

  sicknessByEmpId() {
    this.subscriptions.push(
      this.employeeSicknessServices
        .getEmployeeSicknessByEmployeeId(this.employeeId)
        .subscribe((sicknessList: any) => {
          if (sicknessList) {
            this.sicknessobject = [];
            let list = sicknessList.filter((x: any) => x.Status !== 0);
            this.sicknessobject.push(...list);
            this.sicknessobject.forEach((x) => {
              x.StartDate = HelperService.formatDate(x.StartDate);
              x.EndDate = HelperService.formatDate(x.EndDate);
            });
            this.TotalOpenHours = '0.0';
            this.TotalApprovedHours = '0.0';
            this.TotalRejectedHours = '0.0';
            let openHours = 0;
            let approvedHours = 0;
            let rejectedHours = 0;
            this.sicknessobject.forEach((x: any) => {
              if (x.TotalHours != null) {
                let TotalHours: number;
                TotalHours = parseFloat(x.TotalAbsenceHours);
                x.TotalAbsenceHours = TotalHours.toFixed(1);
                if (x.StatusText == 'OPEN' && x.TotalAbsenceHours != null) {
                  openHours += x.TotalHours;
                } else if (
                  x.StatusText == 'APPROVED' &&
                  x.TotalAbsenceHours != null
                ) {
                  approvedHours += x.TotalHours;
                } else if (
                  x.StatusText == 'REJECTED' &&
                  x.TotalAbsenceHours != null
                ) {
                  rejectedHours += x.TotalHours;
                }
              }
            });
            this.TotalOpenHours = openHours.toFixed(1);
            this.TotalApprovedHours = approvedHours.toFixed(1);
            this.TotalRejectedHours = rejectedHours.toFixed(1);
          }
        })
    );
  }

  /**
   * save and update employee sickness data
   */
  // Define a function to convert hours into days
  hoursToDays(hours: number): number {
    const hoursInADay = 8; // Assuming there are 8 hours in a day
    return hours / hoursInADay;
  }

  approvalIncrement: number = 0;
  onSubmit() {
    let sampleList;
    let approverArray: any = [];
    this.loading = true;
    let employee = this.sicknessForm.value;
    const startDateString = employee.dates.StartDate.formatted;
    const endDateString = employee.dates.EndDate.formatted;
    // Parse date strings into Date objects
    const startDateParts = startDateString.split('/');
    const endDateParts = endDateString.split('/');
    // The month is zero-based in JavaScript Date object, so subtract 1
    const startDate = new Date(
      parseInt(startDateParts[2]),
      parseInt(startDateParts[1]) - 1,
      parseInt(startDateParts[0])
    );
    const endDate = new Date(
      parseInt(endDateParts[2]),
      parseInt(endDateParts[1]) - 1,
      parseInt(endDateParts[0])
    );
    // Calculate the difference in milliseconds
    const timeDifference = endDate.getTime() - startDate.getTime();
    // Convert milliseconds to days
    const totalDays =
      Math.abs(Math.round(timeDifference / (1000 * 60 * 60 * 24))) + 1;
    if (this.sicknessForm.valid) {
      if (this.approvePersons) {
        if (employee.ApprovalPersonList != null) {
          employee.ApprovalPersonList.forEach((element: any) => {
            if (
              this.approvePersons.filter((x: any) => x.Id === element) !=
                null &&
              this.approvePersons.filter((x: any) => x.Id === element) !=
                undefined &&
              this.approvePersons.filter((x: any) => x.Id === element).length >
                0
            ) {
              sampleList = this.approvePersons.filter(
                (x: any) => x.Id === element
              )[0].Email;
              this.employeeSickness.ApprovalPersonMailList.push(sampleList);
              approverArray.push(element);
            }
          });
          employee.ApprovalPersonList = approverArray;
        } else {
          this.employeeSickness.ApprovalPersonMail = this.approvePersons.filter(
            (x: any) => x.Id === employee.ApprovalPerson
          )[0].Email;
        }
      }
      this.employeeSickness.Id = this.sicknessId ? this.sicknessId : 0;
      this.employeeSickness.EmployeeId = this.employeeId;
      this.employeeSickness.BusinessId = BaseServices.BusinessId;
      this.employeeSickness.Status = employee.Status;
      this.employeeSickness.TotalHours = this.IsShowHours
        ? employee.TotalHours
        : null;
      if (this.IsShowHours) {
        this.employeeSickness.NoOfDays = this.hoursToDays(employee.TotalHours);
      } else {
        this.employeeSickness.NoOfDays = this.IsShowPercentage
          ? (employee.PercentageAbsence / 100) * totalDays
          : totalDays;
      }
      this.employeeSickness.ApprovalPerson = null!;
      this.employeeSickness.ApprovalPersonList = employee.ApprovalPersonList;
      this.employeeSickness.Description = employee.Description;
      this.employeeSickness.LanguageId = parseInt(
        sessionStorage.getItem('languageId')!
      );
      this.employeeSickness.IsShowWeekend = this.isShowWeekend
        ? 'true'
        : 'false';
      this.employeeSickness.ChildsAge = employee.ChildsAge;
      this.employeeSickness.OtherReason = employee.OtherReason;
      this.employeeSickness.PercentageAbsence = this.IsShowPercentage
        ? employee.PercentageAbsence
        : null;
      this.employeeSickness.Hours = this.IsShowHours ? employee.Hours : null;
      this.employeeSickness.StartDate = employee.dates.StartDate
        ? HelperService.formatDateFilter(employee.dates.StartDate.formatted)
        : null!;
      this.employeeSickness.EndDate = employee.dates.EndDate
        ? HelperService.formatDateFilter(employee.dates.EndDate.formatted)
        : null!;
      this.employeeSickness.SicknessAbsenceDocumentDto = this.newUploadedFiles;
      this.employeeSickness.SicknessAbsenceBusinessAbsenceCodeDto = [];
      employee.checkboxProperties.forEach((x: any) => {
        if (x.checked) {
          let sicknessCode = new SicknessAbsenceBusinessAbsenceCodeDto();
          sicknessCode.EmployeeSicknessAbsenceId = this.sicknessId
            ? this.sicknessId
            : 0;
          sicknessCode.BusinessAbsenceCodeId = x.key;
          this.employeeSickness.SicknessAbsenceBusinessAbsenceCodeDto.push(
            sicknessCode
          );
        }
      });
      if (this.sicknessId > 0) {
        this.employeeSickness.Status = employee.Status;
        this.employeeSickness.ModifiedBy = BaseServices.UserId;
        this.employeeSickness.StopAbsencePayment = this.StopAbsencePayment
          ? this.StopAbsencePayment.formatted
          : null;
        this.subscriptions.push(
          this.employeeSicknessServices
            .update(this.sicknessId, this.employeeSickness, BaseServices.UserId)
            .subscribe((sicknessObject: any) => {
              if (sicknessObject) {
                this.employeeId = sicknessObject.EmployeeId;
                this.toasterComponent.callToast();
                this.loading = false;
                this.sicknessByEmpId();

                // Checks if the user has rights to see the business absence list
                if (this.checkApproveAbsenceRights()) {
                  this.router.navigate([`./../../../sickness/list`], {
                    relativeTo: this.route,
                  });
                } else {
                  this.router.navigate(
                    [`./../../../sickness/edit/${this.employeeId}`],
                    {
                      relativeTo: this.route,
                    }
                  );
                }
              }
            })
        );
      } else {
        this.employeeSickness.CreatedBy = BaseServices.UserId;
        this.subscriptions.push(
          this.employeeSicknessServices
            .add(this.employeeSickness)
            .subscribe((sicknessObject) => {
              if (sicknessObject) {
                this.toasterComponent.callToast();
                this.loading = false;
                this.sicknessByEmpId();
              }
            })
        );
      }
    }
    this.clear();
  }

  /**
   * Checks if the user has the rights to approve absences / see the business absence list
   * @returns
   */
  checkApproveAbsenceRights(): boolean {
    // Number 10 = Approve absence
    return BaseServices.checkUserRights(10, BaseServices.UserRole);
  }

  /**
   * file upload events
   * @param event {any}
   */
  saveUploaded(event: any) {
    if (event) {
      let eventType = event.eventName;

      switch (eventType) {
        case 'DeleteDefaultFile':
          if (event.item.data.id === 0) {
            // remove unsaved attachment
            this.newUploadedFiles = this.newUploadedFiles.filter(
              (x: any) => x.Path !== event.item.data.path
            );
            this.uploadedFiles = this.uploadedFiles.filter(
              (x: any) => x.path !== event.item.data.path
            );
            this.toasterComponent.callToastDlt();
          } else {
            // remove existing attachement
            this.subscriptions.push(
              this.employeeServices
                .deleteEmployeeDocument(event.item.data.id)
                .subscribe((result: any) => {
                  if (result) {
                    // remove from display list
                    this.uploadedFiles = this.uploadedFiles.filter(
                      (x: any) => x.path !== event.item.data.path
                    );
                    this.toasterComponent.callToastDlt();
                  }
                })
            );
          }
          break;
        case 'remove':
          this.uploadedFiles = this.uploadedFiles.filter(
            (x: any) => x.id !== +event.id
          );
          break;
        default:
          let keyBindings = {
            Originalname: 'OriginalFileName',
            FileTypeId: 'FileType',
          };
          let sicknessDoc = Object.assign(
            {},
            event,
            this.additionalDataForUpload
          );
          sicknessDoc.id = 0;
          sicknessDoc.createdBy = BaseServices.UserId;
          sicknessDoc.status = 1;
          this.newUploadedFiles.push(
            HelperService.switchKeysCase(sicknessDoc, keyBindings)
          );
          break;
      }
    }
  }
  /**
   * delete employee leave
   * @param sicknessobject {any}
   */
  delete(sicknessobject: any) {
    this.confirmationService.confirm({
      message: this.translate.instant('DELETE_THIS_RECORD'),
      accept: () => {
        this.subscriptions.push(
          this.employeeSicknessServices
            .deleteEmployeeSickness(sicknessobject)
            .subscribe(() => {
              this.toasterComponent.callToastDlt();
              this.sicknessByEmpId();
            })
        );
      },
    });
  }

  /**
   * add and update sickness followup
   */
  addSicknessFollowUp() {
    let sicknessObj = new Sickness();
    let formData = this.sicknessForm.value.sicknessFollowUpForm;
    let keyBindings = {
      Originalname: 'OriginalFileName',
      FileTypeId: 'FileType',
    };
    this.newUploadedFiles.forEach((file: any) => {
      let employeeDoc = Object.assign({}, file, this.additionalDataForUpload);
      employeeDoc.id = 0;
      employeeDoc.status = 1;
      employeeDoc.createdBy = BaseServices.UserId;
      this.employeeSickness.SicknessAbsenceDocumentDto.push(
        HelperService.switchKeysCase(employeeDoc, keyBindings)
      );
    });
    formData.forEach((index: any) => {
      if (index < this.sicknessAbsenceFollowUp.length) {
        // update change
        this.sicknessAbsenceFollowUp[index].EmployeeSicknessAbsenceId = this
          .sicknessId
          ? this.sicknessId
          : 0;
        this.sicknessAbsenceFollowUp[index].Deadline =
          this.sicknessForm.value.sicknessFollowUpForm[
            index
          ].DeadLine.formatted;
        this.sicknessAbsenceFollowUp[index].ResponsibleforExecution =
          this.sicknessForm.value.sicknessFollowUpForm[index].Execution;
        this.sicknessAbsenceFollowUp[index].ResponsibleforFollowup =
          this.sicknessForm.value.sicknessFollowUpForm[index].FollowUp;
        this.sicknessAbsenceFollowUp[index].FollowUpTypeId = index + 1;
        sicknessObj.SicknessAbsenceFollowUp = this.sicknessAbsenceFollowUp;
        this.employeeSickness.SicknessAbsenceDocumentDto = [];
        sicknessObj.SicknessAbsenceDocumentDto = this.newUploadedFiles;
      } else {
        // add new
        let newSicknessFollowUp = new SicknessAbsenceFollowUp();
        newSicknessFollowUp.EmployeeSicknessAbsenceId = this.sicknessId
          ? this.sicknessId
          : 0;
        newSicknessFollowUp.Deadline =
          this.sicknessForm.value.sicknessFollowUpForm[
            index
          ].DeadLine.formatted;
        newSicknessFollowUp.ResponsibleforExecution = this.sicknessForm.value
          .sicknessFollowUpForm[index].Execution
          ? this.sicknessForm.value.sicknessFollowUpForm[index].Execution
          : 0;
        newSicknessFollowUp.ResponsibleforFollowup = this.sicknessForm.value
          .sicknessFollowUpForm[index].FollowUp
          ? this.sicknessForm.value.sicknessFollowUpForm[index].FollowUp
          : 0;
        newSicknessFollowUp.FollowUpTypeId = index + 1;
        this.sicknessAbsenceFollowUp.push(newSicknessFollowUp);
        sicknessObj.SicknessAbsenceFollowUp = this.sicknessAbsenceFollowUp;
        sicknessObj.SicknessAbsenceDocumentDto = this.newUploadedFiles;
      }
    });
    this.onSubmit();
  }
  /**
   *
   * @param id {any}
   */
  bindDocuments(id: any) {
    this.subscriptions.push(
      this.employeeSicknessServices
        .getallDocuments(id)
        .subscribe((res: any) => {
          this.documents = res;
        })
    );
  }

  reasonChecked(e: any, value: any) {
    if (e.target.checked) {
      this.sicknessForm.get('selectedItems')!.markAsTouched();
    }
    if (value == 'CHILDREN_SICKNESS') {
      this.isChildage = e.target.checked ? true : false;
    } else if (value == 'OTHERS') {
      this.Other = e.target.checked ? true : false;
    }
  }
  
  onNumberChange(e: KeyboardEvent, limitNumber: number) {
    // Prevent decimal input and limit the input to numbers only
    const inputChar = e.key;

    // Prevent decimal points and non-numeric characters
    if (!/^[0-9]$/.test(inputChar)) {
      e.preventDefault();
      return;
    }
  
    // Check if the entered value exceeds the limit
    const inputValue = (e.target as HTMLInputElement).value;
    if (+inputValue > limitNumber) {
      e.preventDefault();
    }
  }
  //#endregion

  //#region methods
  initForm(data?: any) {
    let startDate = null;
    let endDate;
    let noOfDays = 0;
    let TotalHours = 0;
    let ApprovalPerson;
    let ApprovalPersonList: any = [];
    let Description = '';
    let ChildsAge = '';
    let OtherReason = '';
    let PercentageAbsence = '';
    let Hours = 0;
    let selectedItems = null;
    let group: any = [];
    let reasonData: any = [];
    let status = '8';
    let defaultCodes = [
      'SICKNESS',
      'SICKNESS_MED',
      'WORK_ISSUES',
      'CHILDREN_SICKNESS',
      'PAID_LEAVE',
      'UNPAID_LEAVE',
      'CHILDREN_CARE_SICKNESS',
      'OTHERS',
    ];
    let count = 0;

    this.categories.forEach((l) => {
      let isChecked = false;
      if (data) {
        isChecked =
          data.Reason.split(',').findIndex((x: any) => x === l.Code) > -1;
        if (!isChecked) {
          isChecked =
            data.Reason.split(',').findIndex(
              (x: any) => x === defaultCodes[count]
            ) > -1;
          if (isChecked) {
            l.Code = defaultCodes[count];
          }
        }
        if (l.Description == 'CHILDREN_SICKNESS') {
          this.isChildage = isChecked ? true : false;
        } else if (l.Description == 'OTHERS') {
          this.Other = isChecked ? true : false;
        }
      }
      reasonData.push({
        key: l.Code,
        name: l.Description,
        checked: isChecked,
      });
      group.push(
        new FormGroup({
          key: new FormControl(l.Code),
          name: new FormControl(l.Description),
          checked: new FormControl(isChecked),
        })
      );
      count++;
    });

    let allCategories: FormArray = new FormArray(group);
    this.uploadedFiles = [];

    if (data) {
      this.IsShowPercentage = data.PercentageAbsence ? true : false;
      this.IsShowHours = data.Hours ? true : false;
      this.sicknessId = data.Id;
      this.employeeId = data.EmployeeId;
      this.bindControls(data);
      noOfDays = data.NoOfDays ? data.NoOfDays : 0;
      TotalHours = data.TotalHours ? data.TotalHours : 0;
      ChildsAge = data.ChildsAge ? data.ChildsAge : '';  
      OtherReason = data.OtherReason ? data.OtherReason : '';
      // Start Date Input and Date Range set
      status = data.Status;

      this.workingModule = JSON.parse(sessionStorage.getItem('workingModule'));
      if (this.workingModule.children[1].id == 118) {
        status = data.Status.toString();
      } else {
        status = data.Status.toString();
      }
      this.sicknessForm.get('Status').patchValue(status);

      startDate = data.StartDate
        ? HelperService.formatInputDate(data.StartDate)
        : null;

      let formatOptionDate: Date = HelperService.formatDate(data.StartDate)
        ? new Date(HelperService.formatDate(data.StartDate))
        : null!;

      this.endDateOptions = HelperService.setDateRange(
        this.endDateOptions,
        formatOptionDate,
        'startDate'
      );

      // End Date Input and Date Range set
      endDate = data.EndDate
        ? HelperService.formatInputDate(data.EndDate)
        : null;

      let enddate: Date = data.EndDate ? new Date(data.EndDate) : null!;

      if (data.EmployeeId !== BaseServices.FeatureKey) {
        this.startDateOptions = HelperService.setDateRange(
          this.startDateOptions,
          enddate,
          'startDate'
        );
      }

      ApprovalPerson = data.ApprovalPersonList ? data.ApprovalPersonList : null;
      ApprovalPersonList = data.ApprovalPersonList;
      Description = data.Description ? data.Description : '';
      PercentageAbsence = data.PercentageAbsence ? data.PercentageAbsence : '';
      Hours = data.Hours ? data.Hours : '';
      this.isShowWeekend = data.IsShowWeekend === true ? true : false;
      selectedItems = this.mapItems(reasonData);

      if (data.SicknessAbsenceDocumentDto[0] !== null) {
        let doc = data.SicknessAbsenceDocumentDto.filter(
          (x: any) => x.Status === 1
        );
        doc.forEach((element: any) => {
          this.uploadedFiles.push(
            HelperService.switchKeysCase(element, null, 'L')
          );
        });
      }
    }

    let sicknessFollowUpProperties: FormArray | any = new FormArray([]);

    // Initialize the form with all the data
    this.sicknessForm = this.fb.group({
      NoOfDays: new FormControl(noOfDays),
      TotalHours: new FormControl(TotalHours),
      ApprovalPerson: new FormControl(ApprovalPerson),
      ApprovalPersonList: new FormControl(ApprovalPersonList, Validators.required),
      Description: new FormControl(Description),
      ChildsAge: new FormControl(ChildsAge, [  
        Validators.min(1),
        Validators.max(12),
        Validators.pattern('^[1-9][0-9]?$')
      ]),
      OtherReason: new FormControl(OtherReason),
      Status: new FormControl(status),
      PercentageAbsence: new FormControl(PercentageAbsence),
      Hours: new FormControl(Hours),
      dates: new FormGroup(
        {
          StartDate: startDate
            ? new FormControl(
                { date: startDate.date, formatted: startDate.formatted },
                Validators.required
              )
            : new FormControl(null, Validators.required),
          EndDate: endDate
            ? new FormControl(
                { date: endDate.date, formatted: endDate.formatted },
                Validators.required
              )
            : new FormControl(null, Validators.required),
        },
        SicknessEditComponent.sicknessAbsenceValidation
      ),
      checkboxProperties: allCategories,
      sicknessFollowUpForm: sicknessFollowUpProperties,
      IsShowWeekend: this.isShowWeekend,
      selectedItems: new FormControl(selectedItems, Validators.required),
    });


    this.subscriptions.push(
      allCategories.valueChanges.subscribe((v) => {
        this.sicknessForm.controls['selectedItems'].setValue(this.mapItems(v));
      })
    );
}

  get userFormGroups() {
    return this.sicknessForm.controls['checkboxProperties'] as FormArray;
  }
  /**
   * show sickness click event
   */
  showSicknessFollowUp() {
    this.IsHide = false;
    this.sicknessFollowUp();
  }

  /**
   * follow plan click event
   */
  showFollowUpPlan(count: number) {
    for (let index = 0; index < count; index++) {
      this.sicknessFollowUp();
    }
    this.showFollowUp = false;
  }

  /**
   * binding approval person dropdown
   */
  bindDropDowns() {
    this.persons = [];
    this.ResponsibleExecutionType = [];
    this.ResponsibleFollowUpType = [];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.persons = [];
        this.approvePersons = this.route.snapshot.data['approvalperson'];
        if (this.approvePersons) {
          this.approvePersons.forEach((user: any) => {
            let rights = HelperService.validateJson(user.Rights);
            let hasAccess = HelperService.ApprovalPersonRightsCheck(
              rights,
              Rights.APPROVE_ABSENSE
            );
            if (hasAccess) {
              this.persons.push({ label: user.Name, value: user.Id });
            }
            this.ResponsibleExecutionType.push({
              label: user.Name,
              value: user.Id,
            });
            this.ResponsibleFollowUpType.push({
              label: user.Name,
              value: user.Id,
            });
          });
        }
      })
    );
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.ResponsibleExecutionType = [];
        this.ResponsibleExecutionType.push({ label: val.SELECT, value: null });
      })
    );
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.ResponsibleFollowUpType = [];
        this.ResponsibleFollowUpType.push({ label: val.SELECT, value: null });
      })
    );
  }

  /**
   * Method for setting date range
   * @param dateConfig
   * @param event
   * @param type
   */
  setDateInput(
    dateConfig: IMyDpOptions,
    event: IMyDateModel,
    type: string
  ): IMyDpOptions {
    let date: Date = event.jsdate ? new Date(event.jsdate.getTime()) : null!;
    let dateOptions = HelperService.setDateRange(dateConfig, date, type);
    return dateOptions;
  }
  /**
   * comparing two arrays to get count
   */
  getCount(holidays: any, totalDays: any) {
    let count: any;
    let filteredHolidays = [];
    holidays.forEach((element: any) => {
      let date = new Date(element);
      count = totalDays.filter((element1: any) => element1 === element);
      if (count.length > 0 && date.getDay() != 0 && date.getDay() != 6) {
        filteredHolidays.push(count);
      }
    });
    return filteredHolidays.length;
  }
  /**
   * calculating no of days
   * @param startDate
   * @param endDate
   */
  setNoOfDays(startDate: any, endDate: any) {
    let formattedHolidays: any = [];
    let holidays: any;
    let holidaysCount: any;
    // getting business holidays
    holidays = this.route.snapshot.data['holiday'];
    if (holidays) {
      holidays.forEach((element: any) => {
        formattedHolidays.push(
          HelperService.formatDateForFilter(element.HolidayDate)
        );
      });
    }
    if (startDate && endDate) {
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(0, 0, 0, 0);
      // The number of milliseconds in one day
      let ONE_DAY = 1000 * 60 * 60 * 24;

      // Convert both dates to milliseconds
      let startDate_ms = startDate.getTime();
      let endDate_ms = endDate.getTime();

      // Calculate the difference in milliseconds
      let difference_ms = Math.abs(startDate_ms - endDate_ms);
      let totalDays = Math.round(difference_ms / ONE_DAY) + 1;
      if (startDate !== endDate) {
        let checkStartDate = startDate;
        let countReduceLeave = 0;
        if (!this.isShowWeekend) {
          for (let i = 0; i < totalDays; i++) {
            if (checkStartDate <= endDate) {
              if (
                checkStartDate.getDay() === 6 ||
                checkStartDate.getDay() === 0
              ) {
                countReduceLeave = countReduceLeave + 1;
              }
              checkStartDate.setDate(checkStartDate.getDate() + 1);
            }
          }
        }

        if (formattedHolidays && this.formattedTotalDates) {
          holidaysCount = this.getCount(
            formattedHolidays,
            this.formattedTotalDates
          );
        }
        if (holidaysCount > 0) {
          totalDays = totalDays - countReduceLeave;
          totalDays = totalDays - holidaysCount;
        } else {
          totalDays = totalDays - countReduceLeave;
        }
      }
      let ftpercent = 1.0;
      let sickness = this.sicknessForm.value;
      if (this.IsShowPercentage && sickness.PercentageAbsence !== '') {
        switch (sickness.PercentageAbsence) {
          case 10:
            ftpercent = 0.1;
            break;
          case 20:
            ftpercent = 0.2;
            break;
          case 30:
            ftpercent = 0.3;
            break;
          case 40:
            ftpercent = 0.4;
            break;
          case 50:
            ftpercent = 0.5;
            break;
          case 60:
            ftpercent = 0.6;
            break;
          case 70:
            ftpercent = 0.7;
            break;
          case 80:
            ftpercent = 0.8;
            break;
          case 90:
            ftpercent = 0.9;
            break;
          default:
            ftpercent = 1;
            break;
        }
      }
      totalDays = totalDays * ftpercent;
      if (totalDays > 0) {
        this.sicknessForm.get('NoOfDays')!.patchValue(totalDays);
      }
    }
    // refreshing formattedTotalDates
    this.formattedTotalDates = [];
  }

  /**
   * getting single employee sickness data for edit
   * @param sicknessid
   */
  fetchEmployeeSickness(sicknessid: any) {
    this.IsVisible = false;
    this.subscriptions.push(
      this.employeeSicknessServices
        .getById(sicknessid)
        .subscribe((result: any) => {
          this.sicknessId = result.Id;
          if (result) {
            this.initForm(result);            
            this.approvalPermission(result);
          }
        })
    );
  }

  mapItems(items: any) {
    let selectedItems = items
      .filter((l: any) => l.checked)
      .map((l: any) => l.key);
    if (selectedItems.length) {
      this.sicknessForm.get('selectedItems')!.markAsTouched();
    }
    return selectedItems.length ? selectedItems : null;
  }

  gotoList() {
    this.location.back();
  }

  /**
   * clearing the form values
   */
  clear() {
    this.sicknessId = 0;
    this.isShowWeekend = false;
    this.IsShowPercentage = false;
    this.IsShowHours = false;
    this.initForm();
    this.sicknessFollowUp();
    this.uploadedFiles = [];
    this.newUploadedFiles = [];
    this.editOwnStatus = false;
    if (this.route.snapshot.data['parent'] === 'EMPLOYEE') {
      this.leaveSaveByUser = true;
    } else {
      this.leaveSaveByUser = false;
    }
  }

  /**
   * Method used to show weekend in calender and changes in calculation
   */
  changeSettings() {
    // Disable/enable weekends for Start Date
    let startCopy = HelperService.getCopyOfDatePickerOptions(
      this.startDateOptions
    );
    startCopy.disableWeekends = !this.isShowWeekend;
    this.startDateOptions = startCopy;

    // Disable/enable weekends for End Date
    let endCopy = HelperService.getCopyOfDatePickerOptions(this.endDateOptions);
    endCopy.disableWeekends = !this.isShowWeekend;
    this.endDateOptions = endCopy;

    // Rechange calculation
    this.calculationDays();
    this.CalculateHours();
  }
  /**
   * days calculation for list
   */
  calculationDays() {
    let sicknessForm = this.sicknessForm.value;
    let startDateValue = this.sicknessForm.value.dates.StartDate;
    let endDateValue = this.sicknessForm.value.dates.EndDate;
    let startDate: Date =
      startDateValue && startDateValue.jsdate
        ? new Date(startDateValue.jsdate.getTime())
        : null!;
    let endDate: Date =
      endDateValue && endDateValue.jsdate
        ? new Date(endDateValue.jsdate.getTime())
        : null!;
    if (startDate && endDate) {
      this.totalDates = this.getDates(startDate, endDate);
      this.totalDates.forEach((element: any) => {
        this.formattedTotalDates.push(
          HelperService.formatDateForFilter(element)
        );
      });
    }
    if (this.sicknessId > 0 && startDateValue && endDateValue) {
      let startingDate = new Date(this.formatDate(startDateValue.formatted));
      let endingDate = new Date(this.formatDate(endDateValue.formatted));
      if (startingDate && endingDate) {
        this.totalDates = this.getDates(startingDate, endingDate);
        this.totalDates.forEach((element: any) => {
          this.formattedTotalDates.push(
            HelperService.formatDateForFilter(element)
          );
        });
      }
      // re-assigning again because dates are changing after formatting dates
      let date1 = new Date(this.formatDate(startDateValue.formatted));
      let date2 = new Date(this.formatDate(endDateValue.formatted));
      this.setNoOfDays(date1, date2);
    } else {
      let firstDate: Date =
        startDateValue && startDateValue.jsdate
          ? new Date(startDateValue.jsdate.getTime())
          : null!;
      let secondDate: Date =
        endDateValue && endDateValue.jsdate
          ? new Date(endDateValue.jsdate.getTime())
          : null!;
      this.setNoOfDays(firstDate, secondDate);
    }
  }

  /**
   * setting values for sickness followup formarray
   * @param data
   */
  sicknessFollowUp(data?: any) {
    let DeadLine = '';
    let Execution = '';
    let FollowUp = '';

    (<FormArray>this.sicknessForm.controls['sicknessFollowUpForm']).push(
      new FormGroup({
        DeadLine: new FormControl(DeadLine),
        Execution: new FormControl(Execution),
        FollowUp: new FormControl(FollowUp),
      })
    );
  }
  get sicknessFormGroups() {
    return this.sicknessForm.get('sicknessFollowUpForm') as FormArray;
  }
  get checkboxFormGroups() {
    return this.sicknessForm.get('checkboxProperties') as FormArray;
  }

  getContent(index: any) {
    let content = '';
    switch (index) {
      case 0:
        content =
          'Arbeidsmiljøloven stiller krav om utarbeidelse av oppfølgingsplan for tilbakeføring til arbeid i  forbindelse med ulykke, sykdom, slitasje eller lignende, med mindre dette er åpenbart unødvendig.Arbeidet med oppfølgingsplanen skal starte så tidlig som mulig, og planen skal være utarbeidet senest når arbeidstaker harvært helt eller delvis borte fra arbeidet i fire uker.Planen skal  inneholde en vurdering av arbeidstakers arbeidsoppgaver og arbeidsevne. (mulighet for å laste ned «Skjema oppfølgingsplan» via en knapp) Skjemaet kan fylles ut – lagres på egen maskin og så lastes opp under det samme sykefraværet.';
        break;
      case 1:
        content =
          'I henhold til arbeidsmiljøloven § 4-6 skal arbeidsgiver innkalle arbeidstaker til dialogmøte senest innen syv uker etter at arbeidstaker har vært helt borte fra arbeidet                                                              som følge av ulykke, sykdom, slitasje eller lignende, med mindre dette er åpenbart unødvendig. Møtet skal også avholdes for arbeidstakere som er gradert sykmeldt hvis                                                                 arbeidsgiver, arbeidstaker eller sykmelder mener det er hensiktsmessig.';
        break;
      case 2:
        content =
          'Sørge for å sende oppdatert plan til NAV før dialogmøte 2, og ellers når NAV ber om det';
        break;
      case 3:
        content =
          'NAV skal, senest når sykmeldingen har vart 26 uker, avholde et dialogmøte mellom den sykmeldte arbeidstakeren og arbeidsgiver (dialogmøte 2) unntatt når et slikt                                                            møte antas å være åpenbart unødvendig.';
        break;
    }
    return content;
  }
  getTitle(index: any) {
    let content = '';
    switch (index) {
      case 0:
        content = 'Follow up plan';
        break;
      case 1:
        content = 'Dialog Meeting 1 Work Place';
        break;
      case 2:
        content = 'Reports to NAV';
        break;
      case 3:
        content = 'Dialog Meeting 2 Work Place';
        break;
    }
    return content;
  }

  showDialog(popUpNumber: number) {
    switch (popUpNumber) {
      case 1:
        this.dialogContent = `
                                <p> Når skal det innkalles til dialogmøte?</p>
                                <ul>
                                <li>Møtet skal gjennomføres senest når en arbeidstaker har vært sykmeldt i syv uker.
                                Så langt det er mulig bør møtet avtales i god tid, og i samarbeid med de som skal
                                delta.</li>
                                </ul>
                                <p>
                                Hvem skal innkalles?</p>
                                <ul>
                                <li>Arbeidstaker</li>
                                <li>Sykmelder dersom arbeidsgiver og arbeidstaker, eller arbeidstakeren alene dersom
                                han ønsker det.</li>
                                <li>Verneombud eller annen tillitsvalgt dersom arbeidstakeren ønsker det.</li>
                                <li>Hvis arbeidstaker eller arbeidsgiver mener det er behov</li>
                                <li>for det, kan man invitere andre relevante deltakere, som for</li>
                                <li>eksempel bedriftshelsetjenesten eller NAV.</li>
                                </ul>
                                <p>
                                Hvilken kontakt bør det være mellom arbeidsgiver og arbeidstaker i forkant av møtet?</p>
                                <ul>
                                <li>Arbeidsgiver og arbeidstaker bør ha tenkt gjennom hva de trenger å snakke om, og
                                om mulig ha formidlet dette til hverandre i forkant av møtet.</li>
                                <li>Oppfølgingsplan skal utarbeides i samarbeid mellom arbeidsgiver og arbeidstaker
                                senest når arbeidstaker har vært sykmeldt i fire uker.</li>
                                </ul>
                                <p>
                                Hvilken kontakt bør det være mellom arbeidsgiver og sykmelder i forkant av møtet?</p>
                                <ul>
                                <li>Avtale tid, sted og møteform for dialogmøte.</li>
                                <li>Oppfølgingsplanen skal formidles sykmelder når den er utarbeidet.</li>
                                </ul>`;
        break;
      case 2:
        this.dialogContent = `
                <p>Hvor skal møtet avholdes?</p>
                <ul>
                <li>Dialogmøtet skal fortrinnsvis holdes på arbeidsplassen, men det er viktig at alle
                parter viser fleksibilitet. For at det skal være praktisk mulig for sykmelder å
                delta, kan det for eksempel være en løsning å legge møtet til sykmelderens kontor.
                Det kan også være en løsning at sykmelder deltar i møtet per telefon eller videokonferanse.
                </li>
                </ul>
                <p>
                Hva skal være tema?</p>
                <ul>
                <li>Arbeidsgiver og arbeidstaker skal gjennomgå og arbeide videre med oppfølgingsplanen,
                herunder avklare hva arbeidstakeren kan gjøre på arbeidsplassen og hvilke tilretteleggingstiltak
                som er mulige.</li>
                <li>Det er viktig at det fokuseres på hva arbeidstaker kan gjøre av arbeidsoppgaver.
                Den sykmeldtes diagnose skal ikke være tema for møtet.</li>
                <li>Dersom arbeidstaker har vært helt sykmeldt frem til dialogmøtet, er hovedformålet
                med møtet å redegjøre for de tilretteleggingstiltak og endringer i arbeidstakers
                arbeidsoppgaver som vil være nødvendig for om mulig, sikre hel eller delvis gjenopptakelse
                av arbeidet.</li>
                <li>Eksempler på viktige spørsmål i dialogmøtet kan være; hvilke oppgaver har virksomheten
                som arbeidstaker kan utføre på tross av sine plager? Er det behov for ergonomisk
                tilpasning eller tekniske hjelpemidler i forbindelse med tilretteleggingen? Kan
                arbeidsoppgavene fordeles på annen måte en periode? Er det organisatoriske grep
                som kan gjøres? Bør arbeidstaker skjermes for spesielle arbeidsbelastninger eller
                oppgaver en periode?</li>
                <li>Arbeidsgiver og arbeidstaker bør vurdere behov for bidrag fra andre. </li>
                <li>I de virksomheter som har bedriftshelsetjeneste, bør det vurderes om bedriftshelsetjenesten
                kan bidra i oppfølgingsarbeidet. </li>
                <li>Arbeidsgiver og arbeidstaker bør diskutere videre oppfølging for å sikre tilbakeføring
                til arbeid. Det er viktig å bli enige om et mål i form av forslag til dato for tilbakeføring
                i arbeid, gradvis opptrapping av aktivitet osv. </li>
                </ul>`;
        break;
      case 3:
        this.dialogContent = `
                <ul>
                <li>Arbeidsgiver og arbeidstaker skal oppdatere oppfølgingsplanen etter avholdt dialogmøte.
                Planen skal inneholde hva som er gjort i sykefraværsperioden, herunder hvilken tilrettelegging
                som er prøvd og hva arbeidstaker og arbeidsgiver har blitt enige om.</li>
                <li>Arbeidsgiver skal kunne dokumentere hvordan bestemmelsene om at arbeidsgiver og
                dialogmøte har vært fulgt opp, herunder hvem som har vært innkalt til og har deltatt
                i dialogmøte.</li></ul>`;
        break;
      case 4:
        this.dialogContent = `
                <ul>
                <li>Arbeidstaker, arbeidsgiver, sykmelder eller NAV kan kreve at det avholdes et slikt
                møte på et tidligere tidspunkt. Dette kan for eksempel være aktuelt dersom arbeidsgiver
                og arbeidstaker tidlig vurderer at alle muligheter for tilrettelegging på arbeidsplassen
                er uttømt, og at det derfor kan være hensiktsmessig med arbeidsrettede tiltak i
                regi av NAV. Dersom arbeidsgiver, arbeidstaker eller Folketrygdloven § 8-7a Arbeidsmiljøloven
                § 4-6 Både plikter og muligheter Å finne løsninger sammen Tilretteleggingspliktens
                omfang Sjekklister Lovverket Hvem kan bistå 54 sykmelder krever et slikt møte, skal
                NAV innkalle til og gjennomføre dette. I innkallingen skal det opplyses om arbeidstakers,
                arbeidsgivers og sykmelderens plikter. Sykmelder og annet helsefaglig personell
                skal være med hvis NAV anser det som hensiktsmessig. Dersom det foreligger ekstraordinære
                forhold knyttet til sykmelderens arbeidssituasjon, kan sykmelder fritas fra plikten
                til å delta. </li>
                <br />
                <li>Formålet med dette møtet er at partene og NAV skal møtes til en felles gjennomgang
                av situasjonen. Tema og innhold i møtet vil være det samme som ved sju ukers møtet,
                blant annet gjennomgang og videre arbeid med oppfølgingsplanen, avklaring av hva
                arbeidstakeren kan gjøre på arbeidsplassen og hvilke tilretteleggingstiltak som
                er aktuelle. Møtet vil fungere som en arena for planlegging og avklaring av det
                videre løpet i forbindelse med arbeidstakers sykdom. NAV har ansvar for å stille
                spørsmål til partene om hvilken løsning de ser for seg og hva som skal til for å
                gjennomføre denne. Nav kan også bistå med kompetanse om aktuelle tiltak og muligheter.
                Dersom tilrettelegging på arbeidsplassen ikke gir resultater og medisinske grunner
                tilsier at arbeidstaker har evne til å klare annet arbeid, skal det vurderes arbeidsrettede
                tiltak eller rehabilitering. NAV skal så tidlig som mulig vurdere behovet for slike
                tiltak. Før utløpet av sykepengeperioden ved ett års fravær, skal det igjen vurderes
                om arbeidsrettede tiltak skal prøves hvis tiltak på arbeidsplassen ikke fører fram.
                Arbeidstaker, arbeidsgiver, sykmelder eller NAV kan kreve at det avholdes et dialogmøte
                3 ved behov.</li>
                </ul>`;
        break;
      default:
        break;
    }
    this.showCommonModal = true;
  }
  CalculateHours() {
    let sickness = this.sicknessForm;
    let startDateValue = this.startDate;
    let endDateValue = this.endDate;
    let selectedTime;
    let startDate: Date = startDateValue
      ? new Date(startDateValue.getTime())
      : null!;
    let endDate: Date =
      endDateValue && endDateValue ? new Date(endDateValue.getTime()) : null!;
    if (startDate && endDate) {
      if (this.isShowWeekend == true) {
        this.totalDates = this.getDates(startDate, endDate);
      } else {
        this.totalDates = this.getDatesWithoutWeekEnd(startDate, endDate);
      }
      selectedTime = this.sicknessForm.value.Hours;
      if (selectedTime != null) {
        selectedTime = this.totalDates.length * selectedTime;
        this.sicknessForm.get('TotalHours')!.patchValue(selectedTime);
      }
    }
  }

  sicknessPercentage(sicknessList: any) {
    let totalNumOfdays = 0;
    let numOfdays = 0;
    let totalOpenNumOfdays = 0;
    let openNumOfdays = 0;
    let totalRejectedNumOfdays = 0;
    let rejectedNumOfdays = 0;
    let totalApprovedNumOfdays = 0;
    let approvedNumOfdays = 0;
    sicknessList.forEach((e: any) => {
      let noOfDays;
      let percentageAbsenceOnly;
      let openNoOfDays;
      let rejectedNoOfDays;
      let approvedNoOfDays;
      let startDate = HelperService.formatDate(e.StartDate);
      let endDate = HelperService.formatDate(e.EndDate);
      if (e.PercentageAbsence != null) {
        noOfDays = e.NoOfDays + ' (' + e.PercentageAbsence + '%)';
        percentageAbsenceOnly = ' (' + e.PercentageAbsence + '%)';
        numOfdays += e.NoOfDays;
        totalNumOfdays += (230 * e.PercentageAbsence) / 100;
      } else {
        noOfDays = e.NoOfDays + ' (' + '100' + '%)';
        percentageAbsenceOnly = ' (' + '100' + '%)';
        totalNumOfdays += 230;
        numOfdays += e.NoOfDays;
      }
      if (e.StatusText == 'OPEN') {
        openNoOfDays = e.NoOfDays + ' (' + '100' + '%)';
        totalOpenNumOfdays += 230;
        openNumOfdays += e.NoOfDays;
      } else if (e.StatusText == 'REJECTED') {
        rejectedNoOfDays = e.NoOfDays + ' (' + '100' + '%)';
        totalRejectedNumOfdays += 230;
        rejectedNumOfdays += e.NoOfDays;
      } else if (e.StatusText == 'APPROVED') {
        approvedNoOfDays = e.NoOfDays + ' (' + '100' + '%)';
        totalApprovedNumOfdays += 230;
        approvedNumOfdays += e.NoOfDays;
      }
      // e.NofDaysWithPercentageAbsence = noOfDays;
      e.NofDaysWithPercentageAbsence = percentageAbsenceOnly;

      return (e.StartDate = startDate), (e.EndDate = endDate);
    });
    this.calculateNoOfDay =
      numOfdays.toFixed(2) +
      ' (' +
      ((numOfdays / totalNumOfdays) * 100).toFixed(2) +
      '%)';
    this.OpenNoOfDay = openNumOfdays.toFixed(2);
    this.ApprovedNoOfDay = approvedNumOfdays.toFixed(2);
    this.RejectedNoOfDay = rejectedNumOfdays.toFixed(2);
  }
  getDatesWithoutWeekEnd(fromDate: any, toDate: any) {
    let arr = [];
    let dt: any;
    for (arr = [], dt = fromDate; dt <= toDate; dt.setDate(dt.getDate() + 1)) {
      if (dt.getDay() != 6 && dt.getDay() != 0) {
        if (this.getDatesWithoutHolidays(dt)) arr.push(new Date(dt));
      }
    }
    return arr;
  }

  getDatesWithoutHolidays(date: Date) {
    if (
      this.holidayDateFormat != null &&
      this.holidayDateFormat.length > 0 &&
      date != null
    ) {
      var holidays = this.holidayDateFormat.filter(
        (x: any) =>
          x.getDate() == date.getDate() &&
          x.getMonth() == date.getMonth() &&
          x.getFullYear() == date.getFullYear()
      );
      if (holidays.length > 0) return false;
    }
    return true;
  }

  getHolidayList() {
    let result: IMyDate[] = [];
    this.subscriptions.push(
      this.employeeLeaveService
        .getAllHolidays(BaseServices.BusinessId)
        .subscribe((hList) => {
          hList.forEach((element) => {
            var hDate = new Date(element['HolidayDate']);
            var hDateFormatted = {
              day: hDate.getDate(),
              month: hDate.getMonth() + 1,
              year: hDate.getFullYear(),
            };
            result.push(hDateFormatted);
          });
        })
    );
    return result;
  }

  getHolidayWithDateFormat() {
    let result: any[] = [];
    this.subscriptions.push(
      this.employeeLeaveService
        .getAllHolidays(BaseServices.BusinessId)
        .subscribe((hList) => {
          hList.forEach((element) => {
            var hDate = new Date(element['HolidayDate']);
            result.push(hDate);
          });
        })
    );
    return result;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
  //#endregion
}
