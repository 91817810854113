import { CountryCodeResolver } from './../employees/services/employeeWorkExperience.resolver.service';
import { CustomizeHomePageService } from './../customize-homepage/customize-homepage.service';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgxUploaderModule } from 'ngx-uploader';
import { EmployeeCourseServices } from './../employees/services/employeecourse.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  BusinessLinksEditResolver,
  BusinessLinksListResolver,
} from './services/businessLinks.resolver.service';
import { BusinessHomepageService } from './services/businessHomepage.service';
import { BusinessLinksService } from './services/businessLinks.service';
import { ToasterService } from 'angular2-toaster';
import { BusinessAbsenceService } from './services/businessAbsenceService';
import { BusinessSettingService } from './services/businessSetting.service';
import { FeaturesBaseServices } from './../features/services/features-base.services';
import {
  BusinessListResolver,
  BusinessEditResolver,
  BusinessAgreementResolver,
  BusinessEquipmentResolver,
  BusinessAbsenceCodeByBusinessResolver,
  UserDropDownResolver,
  LanguageResolver,
  ThemeResolver,
  SettingByBusinessResolver,
  HseManagerResolver,
  PortalContactResolver,
  UserByBusinessResolver,
  BusinessCourseResolverByBusiness,
  BusinessCourseEditResolver,
  BusinessCourseListResolver,
  EmployeeContactUserResolver,
  EmployeeCountResolver,
  CompanyNameResolver,
  BusinessUserEditResolver,
  BusinessCategoryResolver,
  UserDropDownResolverForBusiness,
  PortalBusinessResolver,
  BusinessLogListResolver,
  PortalContactListResolver,
  BusinessCacheResolver,
  BusinessDepartmentResolver,
  BusinessDataResolver,
  LicenseCategoryResolver,
  BusinessDataFeatureResolver,
  BusinessUsersCountResolver,
  BusinessEmployeesCountResolver,
  HavePersonalDelegateResolver,
  EmployeeOnlyResolver,
  HseAdviserResolver,
} from './services/business.resolver.service';

import { AppSharedModule } from './../shared/appShared.module';
import { BusinessServices } from './services/business.services';
import { BusinessCategoryService } from './services/businessCategory.service';
import { BusinessEquipmentService } from './services/businessEquipment.service';
import { BusinessesComponent } from './businesses.component';
import { UserModule } from './../users/user.module';
import {
  BusinessListComponent,
  BusinessWizardComponent,
  BusinessDetailsComponent,
  BusinessUsersComponent,
  BusinessFeaturesComponent,
  BusinessHomepageComponent,
  BusinessLicenseComponent,
  BusinessSettingsComponent,
  BusinessAbsenceCodesComponent,
  BusinessEquipmentsComponent,
  BusinessUsersEditComponent,
  BusinessLinksComponent,
  BusinessCourseListComponent,
  BusinessLogComponent,
} from './components/';
import { BusinessSignedService } from './services/businessSigned.service';
import { BusinessSignedUsersListResolver } from './services/businessSigned.resolver.service';
import { ApplicationDefaultVideoResolver } from '../customize-homepage/customize-homepage.resolver';
import { KubaExportModule } from './../shared/kuba-exports/kuba-export.module';
import { BusinessLogService } from './services/businessLog.service';
import { HttpClientModule } from '@angular/common/http';
import { TableModule } from 'primeng/table';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { CheckboxModule } from 'primeng/checkbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { TooltipModule } from 'primeng/tooltip';
import { CalendarModule } from 'primeng/calendar';
import { EditorModule } from 'primeng/editor';
import { ContextMenuModule } from 'primeng/contextmenu';
import { MenuModule } from 'primeng/menu';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { TabMenuModule } from 'primeng/tabmenu';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MyDatePickerModule } from 'mydatepicker';
import { BusinessFeatureListResolver } from '../license-agreement/services/license-agreement.resolver';
import { BusinessSignComponent } from './components/business-sign.component';
import { FeaturesAndRightsComponent } from '../shared/components/featuresAndRights.component';
import { EcplDocumentExplorerModule } from '../../shared/ecpl-document-explorer';
import { EcplTabNavigationModule } from '../../shared/ecpl-tab-navigation/ecpl-tab-navigation.module';
import { DateRangeWithModule } from '../shared/directives/date-range/date-range-with.module';


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    FormsModule,
    AppSharedModule,
    TranslateModule,
    UserModule,
    MyDatePickerModule,
    ConfirmDialogModule,
    DialogModule,
    TableModule,
    CheckboxModule,
    MultiSelectModule,
    TooltipModule,
    CalendarModule,
    EditorModule,
    ContextMenuModule,
    MenuModule,
    DropdownModule,
    InputTextareaModule,
    InputTextModule,
    TranslateModule,
    RouterModule,
    ButtonModule,
    TabMenuModule,
    NgxUploaderModule,
    InputSwitchModule,
    EcplDocumentExplorerModule,
    KubaExportModule,
    EcplTabNavigationModule,
    DateRangeWithModule
  ],
  exports: [
    BusinessesComponent,
    BusinessListComponent,
    BusinessWizardComponent,
    BusinessDetailsComponent,
    BusinessUsersComponent,
    BusinessFeaturesComponent,
    BusinessHomepageComponent,
    BusinessLicenseComponent,
    BusinessSettingsComponent,
    BusinessEquipmentsComponent,
    BusinessAbsenceCodesComponent,
    BusinessUsersEditComponent,
    BusinessLinksComponent,
    FeaturesAndRightsComponent,
    BusinessCourseListComponent,
    BusinessSignComponent,
    BusinessLogComponent,
  ],
  declarations: [
    BusinessesComponent,
    BusinessListComponent,
    BusinessWizardComponent,
    BusinessDetailsComponent,
    BusinessUsersComponent,
    BusinessFeaturesComponent,
    BusinessHomepageComponent,
    BusinessLicenseComponent,
    BusinessSettingsComponent,
    BusinessEquipmentsComponent,
    BusinessAbsenceCodesComponent,
    BusinessUsersEditComponent,
    BusinessLinksComponent,
    FeaturesAndRightsComponent,
    BusinessCourseListComponent,
    BusinessSignComponent,
    BusinessLogComponent,
  ],

  providers: [
    BusinessServices,
    BusinessCategoryService,
    BusinessEquipmentService,
    BusinessSettingService,
    BusinessAbsenceService,
    BusinessListResolver,
    BusinessEditResolver,
    BusinessAgreementResolver,
    BusinessLinksListResolver,
    BusinessLinksEditResolver,
    ToasterService,
    BusinessLinksService,
    BusinessHomepageService,
    EmployeeCourseServices,
    BusinessEquipmentResolver,
    BusinessAbsenceCodeByBusinessResolver,
    UserDropDownResolver,
    LanguageResolver,
    ThemeResolver,
    HseAdviserResolver,
    SettingByBusinessResolver,
    HseManagerResolver,
    PortalContactResolver,
    UserByBusinessResolver,
    BusinessUserEditResolver,
    BusinessCategoryResolver,
    BusinessSignedService,
    BusinessSignedUsersListResolver,
    UserDropDownResolverForBusiness,
    FeaturesBaseServices,
    CustomizeHomePageService,
    ApplicationDefaultVideoResolver,
    CountryCodeResolver,
    PortalBusinessResolver,
    BusinessLogService,
    BusinessLogListResolver,
    PortalContactListResolver,
    EmployeeContactUserResolver,
    BusinessCourseEditResolver,
    BusinessCourseListResolver,
    BusinessCourseResolverByBusiness,
    EmployeeCountResolver,
    CompanyNameResolver,
    BusinessCacheResolver,
    BusinessDepartmentResolver,
    BusinessDataResolver,
    LicenseCategoryResolver,
    BusinessDataFeatureResolver,
    BusinessUsersCountResolver,
    BusinessEmployeesCountResolver,
    HavePersonalDelegateResolver,
    BusinessFeatureListResolver,
    EmployeeOnlyResolver,
  ],
})
export class BusinessesModule {}
