import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { BusinessPortalEdit } from './../models';
import { HttpClient, HttpHeaderResponse } from '@angular/common/http';

@Injectable()
export class BusinessPortalService {
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<any>(environment.BASE_URL + '/business-portals', )
            .map((response: any) => { return response });
    }

    updatePortalUserId(portalId: number, portalUserId: number) {
        return this.http.put<HttpHeaderResponse>(environment.BASE_URL + '/portaluser/' + portalId, portalUserId, )
            .map((response: HttpHeaderResponse) => { return response });
    }
    updatePortalFeature(portalId: number, businessPortalEdit: BusinessPortalEdit) {
        return this.http.put<HttpHeaderResponse>(`${environment.BASE_URL}/business-portal-features/${portalId}`, businessPortalEdit, )
            .map((response: HttpHeaderResponse) => { return response });
    }
    deletePortal(portalId: number) {
        return this.http.put<HttpHeaderResponse>(`${environment.BASE_URL}/portal-delete/${portalId}`, {})
            .map((response: HttpHeaderResponse) => { return response });
    }


    getById(id: number) {
        return this.http.get<any>(environment.BASE_URL + '/business-portal/' + id, )
            .map(result => result);
    }

    add(businessPortalEdit: BusinessPortalEdit) {
        return this.http.post<HttpHeaderResponse>(environment.BASE_URL + '/business-portal/', businessPortalEdit, )
            .map((response: HttpHeaderResponse) => { return response });
    }

    update(portalId: number, businessPortalEdit: BusinessPortalEdit) {
        return this.http.put<HttpHeaderResponse>(environment.BASE_URL + '/business-portal/' + portalId, businessPortalEdit, )
            .map((response: HttpHeaderResponse) => { return response });
    }

    checkPortalTilte(pTitle: string) {
        return this.http.get<HttpHeaderResponse>(environment.BASE_URL + '/business-portal/checkPortal/' + pTitle, )
            .map((response: HttpHeaderResponse) => { return response });
    }

    getUserById(id: number) {
        return this.http.get<HttpHeaderResponse>(environment.BASE_URL + '/portal-user/' + id, )
            .map((response: HttpHeaderResponse) => { return response });
    }

    getPortalFeatures(id: number) {
        return this.http.get(environment.BASE_URL + '/business-portal/' + id, )
            .map(result => result);
    }

    /**
     * get user list by portal manager
     * @param portalManagerId
     */
    getPortalUsersByPortalManagerId(portalManagerId: number) {
        return this.http.get<any>(`${environment.BASE_URL}/getPortalUserByPortalId/${portalManagerId}`, )
            .map((response: any) => { return response });
    }

    /**
   * get portal business list by portal manager
   * @param portalManagerId
   */
    getPortalbusList(portalManagerId: number) {
        return this.http.get<any>(`${environment.BASE_URL}/getPortalManagerBusinessList/${portalManagerId}`, )
            .map((response: any) => { return response });
    }

    /**
     *
     * @param UserId
     */
    getPortalUserDetail(UserId: number, portalManagerId: number) {
        return this.http.get<HttpHeaderResponse>(`${environment.BASE_URL}/getPortalUserDetailById/${UserId}/${portalManagerId}`, )
            .map((response: HttpHeaderResponse) => { return response });
    }
    /**
     * delete portal user
     * @param id
     */
    deleteAdminUser(id: number) {
        return this.http.delete<HttpHeaderResponse>(environment.BASE_URL + '/deletePortalUser/' + id, )
            .map((response: HttpHeaderResponse) => { return response });
    }
}
