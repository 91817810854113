import { Injectable } from '@angular/core';
import { BaseServices } from '../../kuba.services';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs/Subject';
import { HttpClient, HttpHeaderResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class DashboardService {
  private getNews = new Subject<any>();
  getNews$ = this.getNews.asObservable();
  constructor(private http: HttpClient) {}

  getNewsData(data: string) {
    this.getNews.next(data);
  }

  getFutureActivityByBusiness(id: number) {
    return this.http
      .get<any>(`${environment.BASE_URL}/dashboard-activities/${id}`)
      .pipe(map(result => result));
  }
  getFutureAdminActivityByBusiness() {
    return this.http
      .get<any>(`${environment.BASE_URL}/dashboard-admin-activities`)
      .pipe(map(result => result));
  }
  getDeviationByBusiness(businessId: number) {
    return this.http
      .get<any>(environment.BASE_URL + '/dashboard-deviations/' + businessId)
      .pipe(map(result => result));
  }
  getDeviationByUser() {
    return this.http
      .get<any>(environment.BASE_URL + '/dashboard-deviation-user')
      .pipe(map(result => result));
  }
  getDeviationByUserBasedOnDept() {
    return this.http
      .get(environment.BASE_URL + '/dashboard-userdeviationbasedondepartment')
      .pipe(map(result => result));
  }
  getDeviationByUserBasedOnAdministrateRights(businessId: any) {
    return this.http
      .get(
        environment.BASE_URL +
          '/dashboard-userdeviations-administraterights/' +
          businessId
      )
      .pipe(map(result => result));
  }
  getAdminDeviation(businessId: number) {
    return this.http
      .get<any>(
        environment.BASE_URL + '/dashboard-admin-deviations/' + businessId
      )
      .pipe(map(result => result));
  }
  getRiskAnalysis(businessId: number) {
    return this.http
      .get<any>(environment.BASE_URL + '/dashboard-riskanalysis/' + businessId)
      .pipe(
        map(response => {
          return response;
        })
      );
  }
  getInternalNews() {
    return this.http.get<any[]>(
      environment.BASE_URL +
        '/dashboard-internalnews/' +
        BaseServices.BusinessId
    );
  }
  getAdminNews() {
    return this.http.get<any[]>(environment.BASE_URL + '/dashboard-adminnews/');
  }
  getBusiness(id: number) {
    return this.http.get<any>(
      environment.BASE_URL + '/dashboard-business/' + id
    );
  }
  getBusinessFunction(id: number) {
    return this.http
      .get<any>(environment.BASE_URL + '/dashboard-function/' + id)
      .pipe(
        map(response => {
          return response;
        })
      );
  }
  getUserHits(type: string) {
    return this.http
      .get<any>(environment.BASE_URL + '/dashboard-loggeduser/' + type)
      .pipe(
        map(response => {
          return response;
        })
      );
  }
  getAboutKuba(id: number) {
    return this.http
      .get<any[]>(environment.BASE_URL + '/dashboard-aboutKuba/' + id)
      .pipe(
        map(response => {
          return response;
        })
      );
  }
  getcalendarActivity(id: number) {
    return this.http
      .get<any>(
        environment.BASE_URL +
          '/dashboard-calendar/' +
          id +
          '/' +
          BaseServices.roleId
      )
      .pipe(
        map(response => {
          return response;
        })
      );
  }
  getRModeFeature() {
    return this.http
      .get<HttpHeaderResponse>(
        environment.BASE_URL + '/rmode-feature/' + BaseServices.BusinessId
      )
      .pipe(
        map((response: HttpHeaderResponse) => {
          return response;
        })
      );
  }

  getAboutKubaIk(id: number) {
    return this.http
      .get<any>(environment.BASE_URL + '/ownpage-aboutKuba/' + id)
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  getLinksByAppAndBusiness(bid: number) {
    return this.http.get<any[]>(
      environment.BASE_URL + '/dashboard-linklist/' + bid
    );
  }
  getIsPortalBusiness(bid: number) {
    return this.http
      .get(environment.BASE_URL + '/dashboard-IsPortalBusiness/' + bid)
      .pipe(map(result => result));
  }
  getPortalLinks(bid: number) {
    return this.http.get<any>(
      environment.BASE_URL + '/dashboard-portallinklist/' + bid
    );
  }
}
