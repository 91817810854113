import { DepartmentResolver } from './../deviation/services/deviation.resolver';
import {
  FoodSafetyListResolver,
  FoodSafetyEditResolver,
  ZoneListResolver,
  FoodsafetyFeaturesResolver,
  FoodSafetyListByUserResolver,
  FoodSafetyZonesResolver,
  FSDailyTaskListResolver,
  FSAuditListResolver,
  HazardAnalysisListResolver,
  FSAuditDateListResolver,
  CoolingListResolver,
  CoolingProductsListResolver,
} from './services/foodsafety.resolver';
import { CreateFoodSafetyDeviceComponent } from './components/new-device/new-device.component';
import { FoodSafetyDeviceListComponent } from './components/device-list/device-list.component';
import { FoodSafetyListComponent } from './components/foodsafety-list/foodsafety-list.component';
import { FoodsafetyViewComponent } from './components/foodsafety-view/foodsafety-view.component';
import { CreateFoodSafetyComponent } from './components/new-foodsafety/new-foodsafety.component';
import { FoodSafetyComponent } from './foodsafety.component';
import { FoodSafetyServices } from './services/foodsafety.services';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { EcplTabNavigationModule } from 'src/app/shared/ecpl-tab-navigation/ecpl-tab-navigation.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppSharedModule } from './../shared/appShared.module';
import { RouterModule } from '@angular/router';
import { KubaExportModule } from './../shared/kuba-exports/kuba-export.module';
import { FoodHeaderListComponent } from './components/foodsafety-list/foodsafety-header.component';
import { DailyTaskComponent } from './components/daily-task/daily-task.component';
import { HazardAnalysisComponent } from './components/hazard-analysis/hazard-analysis.component';
import { FSAuditComponent } from './components/audit/fs-audit.component';
import { FSTaskManagerListComponent } from './components/task-manager/fs-taskmanager-list.component';
import { FSTaskManagerFormComponent } from './components/task-manager/fs-taskmanager-form.component';
import { EcplDocumentExplorerModule } from 'src/app/shared/ecpl-document-explorer';
import { NgxPaginationModule } from 'ngx-pagination';
import { EcplChecklistViewerModule } from 'src/app/shared/ecpl-checklist-viewer';
import { EcplImageViewerModule } from 'src/app/shared/ecpl-image-viewer';
import { EcplConfirmViewerModule } from 'src/app/shared/ecpl-confirm-viewer';
import { EcplOnlyofficeViewerModule } from 'src/app/shared/ecpl-onlyoffice-viewer';
import { EcplPdfViewerModule } from 'src/app/shared/ecpl-pdf-viewer';
import { HttpClientModule } from '@angular/common/http';
import { TableModule } from 'primeng/table';
import { SharedModule } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { EditorModule } from 'primeng/editor';
import { ContextMenuModule } from 'primeng/contextmenu';
import { MenuModule } from 'primeng/menu';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ButtonModule } from 'primeng/button';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TooltipModule } from 'primeng/tooltip';
import { CoolingListComponent } from './components/cooling/cooling-list/cooling-list.component';
import { DropdownModule } from 'primeng/dropdown';
import { PdfViewerModule   } from 'ng2-pdf-viewer';

@NgModule({
  imports: [
    BrowserModule,
    PdfViewerModule,
    HttpClientModule,
    TableModule,
    SharedModule,
    FormsModule,
    CalendarModule,
    EditorModule,
    ContextMenuModule,
    MenuModule,
    DialogModule,
    ReactiveFormsModule,
    DropdownModule,
    InputTextareaModule,
    InputTextModule,
    TranslateModule,
    AppSharedModule,
    RouterModule,
    ButtonModule,
    EcplTabNavigationModule,
    KubaExportModule,
    EcplDocumentExplorerModule,
    InputSwitchModule,
    NgxPaginationModule,
    EcplChecklistViewerModule,
    EcplImageViewerModule,
    EcplConfirmViewerModule,
    EcplOnlyofficeViewerModule,
    EcplPdfViewerModule,
    TooltipModule,
  ],
  declarations: [
    CreateFoodSafetyComponent,
    FoodSafetyListComponent,
    FoodSafetyComponent,
    FoodSafetyDeviceListComponent,
    CreateFoodSafetyDeviceComponent,
    FoodsafetyViewComponent,
    FoodHeaderListComponent,
    DailyTaskComponent,
    HazardAnalysisComponent,
    FSAuditComponent,
    FSTaskManagerListComponent,
    FSTaskManagerFormComponent,
    CoolingListComponent,
  ],
  providers: [
    FoodSafetyServices,
    FoodSafetyListResolver,
    FoodSafetyEditResolver,
    DepartmentResolver,
    ZoneListResolver,
    FoodsafetyFeaturesResolver,
    FoodSafetyListByUserResolver,
    FoodSafetyZonesResolver,
    FSDailyTaskListResolver,
    FSAuditListResolver,
    HazardAnalysisListResolver,
    FSAuditDateListResolver,
    CoolingListResolver,
    CoolingProductsListResolver,
  ],
  bootstrap: [
    CreateFoodSafetyComponent,
    FoodSafetyListComponent,   
    FoodSafetyComponent,
    FoodSafetyDeviceListComponent,
    CreateFoodSafetyDeviceComponent,
    FoodsafetyViewComponent,
  ],
})
export class FoodSafetyModule {}
