import { Topic } from './../../../follow-ups/models/riskanalysis';
import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { ConfirmationService, SelectItem } from 'primeng/api';

import 'rxjs/Rx';
import { BusinessDepartmentService } from './../../../business-department.services';
import { DeviationShared } from '../../models/';
import { DeviationServices } from '../../services/deviation.service';
import { ToasterComponent } from './../../../../_directives/toaster.component';
import { HelperService } from './../../../../_services/helper.service';
import { UploaderConfig } from './../../../../shared/ecpl-document-explorer/uploader-config';
import { BaseServices } from './../../../kuba.services';
import { deviationCaseType } from './../../models/deviationCaseType';
import { Department } from './../../models/deviationDepartment';
import { TranslateService } from '@ngx-translate/core';
import { Rights } from 'src/app/_models';
import { IMyDateModel, IMyDpOptions } from 'mydatepicker';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs/Rx';

@Component({
  selector: 'deviation',
  templateUrl: 'deviation-new.component.html',
  styleUrls: ['deviation-new.component.scss'],
})
export class AddDeviationComponent implements OnInit {
  //#region variables
  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  aDeviation: any = {};
  isHidden: boolean;
  value: any;
  data: any;
  caseForm: FormGroup;
  departmentForm: FormGroup;
  department = false;
  deptId: number;
  caseId: number;
  case = false;
  formDeviation: FormGroup;
  Department: SelectItem[];
  selectedDepartments: any[] = [];
  Case: SelectItem[];
  Topics: SelectItem[];
  departmentdetails: Department[];
  caseType: deviationCaseType[];
  selectedDepartment = new Department();
  selectedCaseType = new deviationCaseType();
  parentId: number;
  parentKey: any;
  upConfig: UploaderConfig;
  uploadedFiles: any[] = [];
  additionalData: any;
  newDocumentAdded: any = [];
  isCaseErrorDisabled: boolean;
  hidingElement = false;
  hidesElement = false;
  isDepartmentErrorDisabled: boolean;
  isCaseAllocated = false;
  isDepartmentAllocated = false;
  topicForm: FormGroup;
  topicId: number;
  editTopics: any = [];
  isTopicAllocated = false;
  isTopicErrorDisabled = false;
  addTopic: boolean;
  viewTopic: boolean;
  loading = false;
  additionalFeature: any;
  featureId: any;
  isHideGuest = true;
  isHideUser = true;
  isShowSaveButton: boolean;
  IsClear = false;
  isFoodSafety = false;
  showMore = false;
  isShowDepartmentCheckbox: boolean;
  private subscriptions: Subscription[] = [];
  isShowEditorCheckbox: boolean;
  importances: SelectItem[];
  currentDeadLine: Date;
  currentDate: Date;
  executions: SelectItem[];
  followUps: SelectItem[];
  followUpNotify: any;
  followLabel: any;
  execLabel: any;
  selectedFollowup: string;

  /**
   * Date picker configuration option
   */
  public defaultDateOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
    editableDateField: false,
    openSelectorOnInputClick: true,
    firstDayOfWeek: 'su',
    satHighlight: true,
    inline: false,
    height: '32px',
    selectionTxtFontSize: '14px',
    disableSince: this.getCurrentDate(),
    todayBtnTxt: this.translate.instant('TODAY'),
    dayLabels: {
      su: this.translate.instant('SUN'),
      mo: this.translate.instant('MON'),
      tu: this.translate.instant('TUE'),
      we: this.translate.instant('WED'),
      th: this.translate.instant('THU'),
      fr: this.translate.instant('FRI'),
      sa: this.translate.instant('SAT'),
    },
    monthLabels: {
      1: this.translate.instant('JANUARY'),
      2: this.translate.instant('FEBRUARY'),
      3: this.translate.instant('MARCH'),
      4: this.translate.instant('APRIL'),
      5: this.translate.instant('MAY'),
      6: this.translate.instant('JUNE'),
      7: this.translate.instant('JULY'),
      8: this.translate.instant('AUGUST'),
      9: this.translate.instant('SEPTEMBER'),
      10: this.translate.instant('OCTOBER'),
      11: this.translate.instant('NOVEMBER'),
      12: this.translate.instant('DECEMBER'),
    },
  };

  /**
   * Date picker configuration option
   */
  public startDateOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
    editableDateField: false,
    openSelectorOnInputClick: true,
    firstDayOfWeek: 'su',
    satHighlight: true,
    height: '29px',
    selectionTxtFontSize: '13px',
    todayBtnTxt: this.translate.instant('TODAY'),
    dayLabels: {
      su: this.translate.instant('SUN'),
      mo: this.translate.instant('MON'),
      tu: this.translate.instant('TUE'),
      we: this.translate.instant('WED'),
      th: this.translate.instant('THU'),
      fr: this.translate.instant('FRI'),
      sa: this.translate.instant('SAT'),
    },
    monthLabels: {
      1: this.translate.instant('JANUARY'),
      2: this.translate.instant('FEBRUARY'),
      3: this.translate.instant('MARCH'),
      4: this.translate.instant('APRIL'),
      5: this.translate.instant('MAY'),
      6: this.translate.instant('JUNE'),
      7: this.translate.instant('JULY'),
      8: this.translate.instant('AUGUST'),
      9: this.translate.instant('SEPTEMBER'),
      10: this.translate.instant('OCTOBER'),
      11: this.translate.instant('NOVEMBER'),
      12: this.translate.instant('DECEMBER'),
    },
    // disableUntil: this.getCurrentDate()
    disableUntil: HelperService.formatInputDate(
      new Date().setDate(new Date().getDate() - 1)
    ).date,
  };
  /**
   * Date picker configuration option
   */
  public eventDateOptions: IMyDpOptions = this.defaultDateOptions;
  //#endregion
  /**
   * constructor
   * @param router {{Router}}
   * @param route {{ActivatedRoute}}
   * @param formBuilder {{FormBuilder}}
   * @param deviationservice {{DeviationServices}}
   * @param location {{Location}}
   * @param confirmationService {{ConfirmationService}}
   */
  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private deviationservice: DeviationServices,
    private location: Location,
    private confirmationService: ConfirmationService,
    private businessDepartmentService: BusinessDepartmentService,
    private translate: TranslateService,
    private datePipe: DatePipe
  ) {
    this.parentId = this.route.snapshot.parent.parent.params['id'];
    this.parentKey = this.route.snapshot.parent.parent.data['parent'];
    this.additionalFeature =
      this.route.snapshot.parent.parent.data['additionalFeatures'];
    if (this.additionalFeature != undefined || this.additionalFeature != null) {
      this.featureId = this.additionalFeature['id'];
    }
    if (this.parentKey == 'FS') this.isFoodSafety = true;
    if (this.isFoodSafety) {
      this.formDeviation = this.formBuilder.group({
        Title: ['', Validators.required],
        Description: [''],
        Status: [this.translate.instant('NEW_ISSUE')],
        Case: [''],
        Department: [''],
        Topics: [''],
        user: [''],
        deparmentOnly: [0],
        ProjectId: ['1'],
        ImmediateAction: [''],
        EventLocation: [''],
        EventDate: [''],
        EventTime: [''],
        SendonlytoEditors: [0],
        Importance: [''],
        Cost: [],
        Deadline: [],
        Execution: [],
        Followup: [],
        CauseAnalysis: [''],
        CorrectiveAction: [''],
        EffectOfAction: [''],
        Comment: [''],
        Notification: [],
        FollowupNotification: [],
      });
    } else {
      this.formDeviation = this.formBuilder.group({
        Title: ['', Validators.required],
        Description: [''],
        Status: [this.translate.instant('NEW_ISSUE')],
        Case: ['', Validators.required],
        Department: ['', Validators.required],
        Topics: ['', Validators.required],
        user: [''],
        deparmentOnly: [0],
        ProjectId: ['1'],
        ImmediateAction: [''],
        EventLocation: [''],
        EventDate: [''],
        EventTime: [''],
        SendonlytoEditors: [0],
        Importance: [''],
        Cost: [],
        Deadline: [],
        Execution: [],
        Followup: [],
        CauseAnalysis: [''],
        CorrectiveAction: [''],
        EffectOfAction: [''],
        Comment: [''],
        Notification: [],
        FollowupNotification: [],
      });
    }
    this.caseForm = this.formBuilder.group({
      Name: [
        '',
        [Validators.required, ValidationService.noWhitespaceValidator],
      ],
    });
    this.departmentForm = this.formBuilder.group({
      Name: [
        '',
        [Validators.required, ValidationService.noWhitespaceValidator],
      ],
    });
    this.topicForm = this.formBuilder.group({
      Name: [
        '',
        [Validators.required, ValidationService.noWhitespaceValidator],
      ],
    });
    switch (this.parentKey) {
      case 'FDV':
        this.isHidden = false;
        break;
      case 'PROJECT':
        this.isHidden = true;
        break;
    }
    if (+BaseServices.roleId === 4) {
      this.isHideGuest = false;
    }
    let currentUserRole = BaseServices.UserRole;
    let userRightsId = Rights.ADMINISTRATE_DEVIATION;
    if (+BaseServices.roleId === 5) {
      this.isHideGuest = BaseServices.checkUserRights(
        userRightsId,
        currentUserRole
      );
    }

    if (this.featureId == 20) {
      this.isShowDepartmentCheckbox = false;
      this.isShowEditorCheckbox = false;
    } else {
      this.isShowDepartmentCheckbox = true;
      this.isShowEditorCheckbox = true;
    }
  }

  /**
   * page init dropdown bind
   */
  ngOnInit(): void {
    document.querySelector('body').classList.remove('opened');
    this.additionalData = {
      ApplicationId: BaseServices.ApplicationId,
      BusinessId: BaseServices.BusinessId,
      ParentId: this.parentId,
      ChildId: 0,
      Status: '1',
      CreatedOn: new Date(),
      ModifiedBy: BaseServices.UserId,
      ModifiedOn: new Date(),
      ModuleName: 'Deviation',
    };
    this.upConfig = {
      title: 'UPLOAD',
      titleAsLabel: true,
      editMode: true,
      windowedHeight: false,
      viewSwitch: false,
      showUserMeta: false,
      showSearchFilter: false,
      showUploadButton: true,
      showDeleteButton: true,
      uploaderUri: `${environment.BASE_URL}/${BaseServices.apiRoute(
        this.parentKey
      )}/file-upload`,
      addtionalData: this.additionalData,
    };
    let topic = this.route.snapshot.data['topic'];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.Topics = [];
        this.Topics.push({ label: val.SELECT, value: '' });
        if (topic) {
          topic.forEach((element: any) => {
            this.Topics.push({ label: element.Name, value: element.Id });
          });
        }
      })
    );

    this.bindCaseDropdown();
    this.bindDeptDropdown();
    this.bindTopicDropDown();
    this.bindImportanceDropdown();
    this.bindFormDropdown();
  }

  /**
   * save uploaded file
   */
  saveUploaded(event: any) {
    if (event) {
      let eventType = event.eventName;
      let keyBindings = {
        Originalname: 'OriginalFileName',
        FileTypeId: 'FileType',
      };
      let deviationDoc = Object.assign({}, event, this.additionalData);
      switch (eventType) {
        case 'DeleteDefaultFile':
          this.uploadedFiles = this.uploadedFiles.filter(
            (x: any) => x.path !== event.item.data.path
          );
          if (event.item.data.id === 0) {
            this.newDocumentAdded = this.newDocumentAdded.filter(
              (x: any) => x.Path !== event.item.data.path
            );
          } else {
            deviationDoc.id = event.item.data.id;
            deviationDoc.createdBy = BaseServices.UserId;
            deviationDoc.status = 2;
            this.newDocumentAdded.push(
              HelperService.switchKeysCase(deviationDoc, keyBindings)
            );
          }
          break;
        default:
          deviationDoc.id = 0;
          deviationDoc.createdBy = BaseServices.UserId;
          deviationDoc.status = 1;
          this.newDocumentAdded.push(
            HelperService.switchKeysCase(deviationDoc, keyBindings)
          );
          break;
      }
    }
  }
  /**
   * bind case dropdown
   */
  bindCaseDropdown() {
    this.data = this.route.snapshot.data['caseType'];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.Case = [];
        this.Case.push({ label: val.SELECT, value: null });
        if (this.data) {
          this.data.forEach((element: any) => {
            this.Case.push({ label: element.Name, value: element.Id });
          });
        }
      })
    );
  }

  /**
   * bind department dropdown
   */
  bindDeptDropdown() {
    this.Department = [];
    let departments = this.route.snapshot.data['department'];
    let department = _.filter(departments, { ParentId: null });
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.Department = [];
        this.Department
          .push
          // { label: val.SELECT, value: null }
          ();
        if (department) {
          department.forEach((departmentList: any) => {
            if (departmentList.Id) {
              let subDepartment = _.filter(departments, {
                ParentId: departmentList.Id,
              });
              if (subDepartment.length > 0) {
                subDepartment.forEach((subDepartmentList: Department) => {
                  departmentList.Name += ' (' + subDepartmentList.Name + ')';
                });
              }
            }
            this.Department.push({
              label: departmentList.Name,
              value: departmentList.Id,
            });
          });
        }
      })
    );
  }
  /**
   * bind topic dropdown
   */
  bindTopicDropDown() {
    this.subscriptions.push(
      this.deviationservice
        .getAllTopics(BaseServices.BusinessId)
        .subscribe((topic: any) => {
          if (topic) {
            this.subscriptions.push(
              this.translate.stream('SELECT_DROPDOWN').subscribe((val: any) => {
                this.Topics = [];
                this.Topics.push({ label: val.SELECT, value: '' });
                topic.forEach((element: any) => {
                  this.Topics.push({ label: element.Name, value: element.Id });
                });
                this.editTopics = topic;
              })
            );
          }
        })
    );
  }
  /**
   * bind case dropdown
   */
  refreshCaseType() {
    this.isCaseErrorDisabled = false;
    this.subscriptions.push(
      this.deviationservice.getAllCaseType().subscribe((cases: any) => {
        this.subscriptions.push(
          this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
            this.Case = [];
            this.Case.push({ label: val.SELECT, value: null });
            if (cases) {
              cases.forEach((caseValue: any) => {
                this.Case.push({
                  label: caseValue.Name,
                  value: caseValue.Id,
                });
              });
            }
          })
        );
        this.caseType = cases;
      })
    );
  }

  /**
   * get dropdown values for editing
   * @param casetype
   */
  editCase(casetype: any) {
    this.selectedCaseType = casetype;
    this.caseId = this.selectedCaseType.Id;
  }

  /**
   * display case dropdown
   */
  showCase() {
    this.case = true;
  }

  /**
   * // save case dropdown
   */
  saveCase() {
    let casetype = new deviationCaseType();
    casetype.Id = this.caseId ? this.caseId : 0;
    casetype.Name = this.selectedCaseType.Name;
    casetype.Status = '1';
    casetype.BusinessId = BaseServices.BusinessId;
    casetype.ApplicationId = BaseServices.ApplicationId;
    if (casetype.Id > 0) {
      this.subscriptions.push(
        this.deviationservice
          .updateCaseType(casetype)
          .subscribe((caseType: any) => {
            if (caseType) {
              this.case = false;
              this.toasterComponent.callToast();
              this.refreshCaseType();
              this.formDeviation.get('Case').patchValue(caseType.Id);
              this.caseId = 0;
            }
          })
      );
    } else {
      this.subscriptions.push(
        this.deviationservice
          .checkTypecaseExist(casetype.Name)
          .subscribe((result: any) => {
            if (result.Isavailable === true) {
              this.isCaseErrorDisabled = true;
            } else {
              this.subscriptions.push(
                this.deviationservice
                  .createCaseType(casetype)
                  .subscribe((caseType: any) => {
                    if (caseType) {
                      this.case = false;
                      this.toasterComponent.callToast();
                      this.refreshCaseType();
                      this.formDeviation.get('Case').patchValue(caseType.Id);
                    }
                  })
              );
            }
          })
      );
    }
    this.clearCase();
  }
  clearCase() {
    this.selectedCaseType.Id = 0;
    this.caseForm = this.formBuilder.group({
      Name: [''],
    });
  }
  /**
   * delete case dropdown
   * @param selectedCaseType
   */
  deleteCase(selectedCaseType: any) {
    this.subscriptions.push(
      this.deviationservice
        .checkDepartmentExist(selectedCaseType)
        .subscribe((result: any) => {
          if (result) {
            this.isCaseAllocated = true;
            setTimeout(
              function () {
                this.isCaseAllocated = false;
              }.bind(this),
              3000
            );
          } else {
            this.confirmationService.confirm({
              message: this.translate.instant('DELETE_THIS_RECORD'),
              accept: () => {
                this.subscriptions.push(
                  this.deviationservice
                    .deleteType(selectedCaseType)
                    .subscribe((result) => {
                      if (result) {
                        this.refreshCaseType();
                        this.toasterComponent.callToastDlt();
                      }
                    })
                );
              },
            });
          }
        })
    );
  }
  /**
   * edit topic
   * @param Topic {{any}}
   */
  editTopic(topic: any) {
    this.topicId = topic.Id;
    let topicControl = {
      Name: topic.Name,
    };
    (<FormGroup>this.topicForm).setValue(topicControl, { onlySelf: true });
  }

  /**
   * delete type of topic
   * @param deleteTopic {{any}}
   */
  deleteTopic(deleteTopic: any) {
    this.subscriptions.push(
      this.deviationservice
        .checkTopicForDeviation(deleteTopic)
        .subscribe((result: any) => {
          if (result) {
            this.isTopicAllocated = true;
            setTimeout(
              function () {
                this.isTopicAllocated = false;
              }.bind(this),
              3000
            );
          } else {
            this.confirmationService.confirm({
              message: this.translate.instant('DELETE_THIS_RECORD'),
              accept: () => {
                this.subscriptions.push(
                  this.deviationservice
                    .deleteTopics(deleteTopic)
                    .subscribe((isTopicDeleted) => {
                      if (isTopicDeleted) {
                        this.fetchTopic();
                        this.toasterComponent.callToastDlt();
                      }
                    })
                );
              },
            });
          }
        })
    );
  }
  /**
   * Fetch the topics
   */
  fetchTopic() {
    this.isTopicErrorDisabled = false;
    this.subscriptions.push(
      this.deviationservice
        .getAllTopics(BaseServices.BusinessId)
        .subscribe((topic: any) => {
          this.subscriptions.push(
            this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
              this.Topics = [];
              this.Topics.push({ label: val.SELECT, value: null });
              if (topic) {
                topic.forEach((topicValue: any) => {
                  this.Topics.push({
                    label: topicValue.Name,
                    value: topicValue.Id,
                  });
                });
              }
            })
          );
          this.editTopics = topic;
        })
    );
  }
  /**
   * save topic
   */
  saveTopic() {
    let topic = new Topic();
    topic.Id = this.topicId ? this.topicId : 0;
    topic.Name = this.topicForm.value.Name;
    topic.Status = '1';
    topic.BusinessId = BaseServices.BusinessId;
    if (topic.Id > 0) {
      this.subscriptions.push(
        this.deviationservice
          .updateTopics(topic)
          .subscribe((topicEdited: any) => {
            if (topicEdited) {
              this.toasterComponent.callToast();
              this.addTopic = false;
              this.fetchTopic();
              this.formDeviation.get('Topics').patchValue(topicEdited.Id);
              this.topicId = 0;
            } else {
              this.isTopicErrorDisabled = true;
              setTimeout(
                function () {
                  this.isTopicErrorDisabled = false;
                }.bind(this),
                3000
              );
            }
          })
      );
    } else {
      this.isTopicErrorDisabled = false;
      this.subscriptions.push(
        this.deviationservice.addTopics(topic).subscribe((caseType: any) => {
          if (caseType) {
            this.toasterComponent.callToast();
            this.addTopic = false;
            this.fetchTopic();
            this.formDeviation.get('Topics').patchValue(caseType.Id);
          } else {
            this.isTopicErrorDisabled = true;
            setTimeout(
              function () {
                this.isTopicErrorDisabled = false;
              }.bind(this),
              3000
            );
          }
        })
      );
      this.topicForm.reset();
    }
  }
  /**
   * get all department dropdown list
   */

  viewDepartment() {
    this.isDepartmentErrorDisabled = false;
    this.subscriptions.push(
      this.businessDepartmentService
        .getCachedBusinessDepartment()
        .subscribe((department) => {
          if (department) {
            this.subscriptions.push(
              this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
                this.Department = [];
                this.Department.push({ label: val.SELECT, value: null });
                if (department) {
                  department.forEach((caseValue: any) => {
                    this.Department.push({
                      label: caseValue.Name,
                      value: caseValue.Id,
                    });
                  });
                }
              })
            );
            this.departmentdetails = department;
          }
        })
    );
  }

  /**
   * get department values for edit
   * @param deptdetails
   */
  editDepartment(deptdetails: any) {
    this.deptId = this.selectedDepartment.Id;
    this.selectedDepartment = deptdetails;
  }

  /**
   * display department dropdown
   */
  showDepartment() {
    this.department = true;
  }

  /**
   * save department dropdown
   */
  saveDepartment() {
    let deptdetails = new Department();
    deptdetails.BusinessId = BaseServices.BusinessId;
    deptdetails.ApplicationId = BaseServices.ApplicationId;
    deptdetails.Id = this.deptId ? this.deptId : 0;
    deptdetails.Name = this.selectedDepartment.Name;
    deptdetails.Status = '1';
    if (deptdetails.Id > 0) {
      deptdetails.ModifiedBy = BaseServices.UserId;
      this.subscriptions.push(
        this.deviationservice
          .updateDepartment(deptdetails)
          .subscribe((department: any) => {
            if (department) {
              this.clearCachedDepartment();
              this.department = false;
              this.toasterComponent.callToast();
              this.viewDepartment();
              this.bindDeptDropdown();
              this.formDeviation.get('Department').patchValue(department.Id);
              this.selectedDepartments = department.name;
              this.deptId = 0;
            }
          })
      );
    } else {
      this.clearCachedDepartment();
      deptdetails.Id = 0;
      deptdetails.CreatedBy = BaseServices.UserId;
      this.subscriptions.push(
        this.deviationservice
          .checkDepartmentExist(deptdetails.Name)
          .subscribe((result: any) => {
            if (result.Isavailable === true) {
              this.isDepartmentErrorDisabled = true;
            } else {
              this.subscriptions.push(
                this.deviationservice
                  .addDepartment(deptdetails)
                  .subscribe((department: any) => {
                    if (department) {
                      this.department = false;
                      this.toasterComponent.callToast();
                      this.viewDepartment();
                      this.bindDeptDropdown();
                      this.formDeviation
                        .get('Department')
                        .patchValue(department.Id);
                      this.selectedDepartments = department.name;
                    }
                  })
              );
            }
          })
      );
    }
    this.clearDepartment();
  }
  /**
   * clear popup value
   */
  clearDepartment() {
    this.selectedDepartment.Id = 0;
    this.departmentForm = this.formBuilder.group({
      Name: [''],
    });
  }
  /**
   * cache clear of department
   */
  clearCachedDepartment() {
    this.businessDepartmentService.clearCachedAllDepartment();
  }
  /**
   * department dropdown delete
   * @param selectedDepartment
   */
  deleteDepartment(selectedDepartment: Department) {
    if(selectedDepartment.BusinessId > 0){
      this.subscriptions.push(
        this.deviationservice
          .checkDepartmentExist(selectedDepartment.Name)
          .subscribe((result: any) => {
            if (result) {
              this.clearCachedDepartment();
              this.isDepartmentAllocated = true;
              setTimeout(
                function () {
                  this.isDepartmentAllocated = false;
                }.bind(this),
                3000
              );
            } else {
              this.confirmationService.confirm({
                message: this.translate.instant('DELETE_THIS_RECORD'),
                accept: () => {
                  this.subscriptions.push(
                    this.deviationservice
                      .deleteDepartment(selectedDepartment.Id)
                      .subscribe((result) => {
                        if (result) {
                          this.viewDepartment();
                          this.bindDeptDropdown();
                          this.toasterComponent.callToastDlt();
                        }
                      })
                  );
                },
              });
            }
          })
      );
    }
  }

  /**
   * close popup
   */
  onBeforeCaseDialogHide() {
    this.isCaseErrorDisabled = false;
    this.caseForm.reset();
    this.hidingElement = false;
    this.hidesElement = false;
    this.isDepartmentErrorDisabled = false;
  }

  /**
   * go back to list
   */
  gotoList() {
    this.location.back();
  }

  /**
   * saving deviation list
   */
  saveDeviation() {
    let appSettings = JSON.parse(sessionStorage.getItem('appSettings'));
    this.loading = true;
    let formData = this.formDeviation.value;
    let deviation = new DeviationShared();
    deviation.Title = formData.Title;
    deviation.Description = formData.Description;
    deviation.Status = '7';
    deviation.FeatureKey = this.parentId;
    deviation.CaseId = formData.Case;
    deviation.IsShowtoUsers = formData.user;
    deviation.IsSendOnlyDepartment =
      this.featureId == 20
        ? (formData.deparmentOnly = 0)
        : formData.deparmentOnly;
    deviation.BusinessDepartmentIds = formData.Department;
    deviation.TopicId = formData.Topics;
    deviation.CreatedBy = BaseServices.UserId;
    deviation.CreatedOn = HelperService.formatDate(new Date());
    deviation.DeviationAttachment = this.newDocumentAdded;
    deviation.IsShowtoUsers = formData.user;
    deviation.ApplicationId = BaseServices.ApplicationId;
    deviation.LanguageId = appSettings.Language.Id;
    deviation.BusinessId = BaseServices.BusinessId;
    deviation.FeatureId = this.featureId;
    deviation.ImmediateAction = formData.ImmediateAction;
    deviation.CultureInfo = sessionStorage.getItem('languageMode');
    deviation.EventDate = formData.EventDate
      ? HelperService.formatDateFilter(formData.EventDate.formatted)
      : null;
    deviation.EventTime = formData.EventTime
      ? HelperService.formatTime(formData.EventTime)
      : null;
    deviation.EventLocation = formData.EventLocation;
    deviation.SendonlytoEditors = formData.SendonlytoEditors;
    deviation.Importance = formData.Importance;
    deviation.Comment = formData.Comment;
    deviation.ExecutionUserId = formData.Execution;
    deviation.FollowupUserId = formData.Followup;
    deviation.ImmediateAction = formData.ImmediateAction;
    deviation.CauseAnalysis = formData.CauseAnalysis;
    deviation.CorrectiveAction = formData.CorrectiveAction;
    deviation.EffectOfAction = formData.EffectOfAction;
    deviation.NotifyBeforeDeadLine = formData.Notification;
    deviation.NotificationBeforeFollowup = formData.FollowupNotification;
    deviation.Cost = formData.Cost;
    deviation.Deadline = formData.Deadline
      ? HelperService.formatDateFilter(formData.Deadline.formatted)
      : null;
    this.subscriptions.push(
      this.deviationservice
        .addDeviation(deviation, this.parentKey)
        .subscribe((result) => {
          if (result) {
            this.loading = false;
            this.toasterComponent.callToast();
            this.gotoList();
          }
        })
    );
  }

  /**
   * get current date
   */
  getCurrentDate() {
    let dateFormat = new Date();
    return {
      year: dateFormat.getFullYear(),
      month: dateFormat.getMonth() + 1,
      day: dateFormat.getDate() + 1,
    };
  }

  /**
   * bind evnet date and time
   */
  bindEventDate() {
    let dateFormat = new Date();
    this.formDeviation.controls['EventDate'].setValue(
      HelperService.formatInputDate(new Date())
    );
    this.formDeviation.controls['EventTime'].setValue(
      this.datePipe.transform(dateFormat, 'HH:mm')
    );
    this.IsClear = true;
  }

  /**
   * clear evnet date and time
   */
  clearEventDate() {
    this.formDeviation.controls['EventDate'].setValue(null);
    this.formDeviation.controls['EventTime'].setValue(null);
    this.IsClear = false;
  }

  /**
   * enable clear button
   */
  enableClear() {
    this.IsClear = true;
  }

  bindImportanceDropdown() {
    this.subscriptions.push(
      this.translate.stream('RISKFIGURE_DROPDOWN').subscribe((val) => {
        this.importances = [];
        this.importances.push(
          { label: val.SELECT, value: null },
          { label: val.LOW, value: 1 },
          { label: val.MEDIUM, value: 2 },
          { label: val.HIGH, value: 3 }
        );
      })
    );
  }

  /**
   * cost key press event
   * @param e {e}
   * @param limitNumber {limitNumber}
   */
  onNumber(e: any, limitNumber: any) {
    const limit = limitNumber;
    const dots = e.target.value.indexOf('.');
    let charCode = e.which ? e.which : e.keyCode;
    if (
      e.target.value.length === limit ||
      dots > 1 ||
      (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46)
    ) {
      e.preventDefault();
    }
  }

  onDeadLineChanged(event: IMyDateModel) {
    this.currentDeadLine = event.jsdate
      ? new Date(event.jsdate.getTime())
      : null;
    this.currentDate = new Date();
    this.formDeviation['controls']['Notification'].setValue(0);
  }

  bindFormDropdown() {
    let responsiblePerson = this.route.snapshot.data['user'];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.executions = [];
        this.executions.push({ label: val.SELECT, value: null });
        if (responsiblePerson) {
          responsiblePerson.forEach((followupExecution: any) => {
            this.executions.push({
              label: followupExecution.Name,
              value: followupExecution.Id,
            });
          });
        }
      })
    );
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.followUps = [];
        this.followUps.push({ label: val.SELECT, value: null });
        if (responsiblePerson) {
          responsiblePerson.forEach((followupExecution: any) => {
            this.followUps.push({
              label: followupExecution.Name,
              value: followupExecution.Id,
            });
          });
        }
      })
    );

    this.followUpNotify = [];
    for (let n = 0; n <= 14; n++) {
      if (n === 0) {
        this.followUpNotify.push({
          label: this.translate.instant('ON_DEADLINE_DATE'),
          value: n,
        });
      } else {
        this.followUpNotify.push({ label: n.toString(), value: n });
      }
    }
    this.followUpNotify.push({ label: this.translate.instant('TREE_WEEKS'), value: 21 }, { label: this.translate.instant('ONE_MONTH'), value: 30 });
 
  }

  /**
   * followup dropdown change event
   * @param e
   * @param followupDropdown
   */

  onFollowChanged(e: any, follow: any) {
    if (e.value) {
      this.followLabel = follow.selectedOption.label;
    }
  }

  /**
   * execution dropdown change eventF
   * @param e
   * @param executionDropdown
   */

  onExecChanged(e: any, exec: any) {
    if (e.value) {
      this.execLabel = exec.selectedOption.label;
    }
  }

  ShowMore(data: boolean) {
    if (data === true) {
      this.showMore = true;
      if (this.formDeviation.controls['Importance']) {
        this.formDeviation.controls['Importance'].setValidators(
          Validators.required
        );
        this.formDeviation.controls['Importance'].updateValueAndValidity();
      }
      if (this.formDeviation.controls['Deadline']) {
        this.formDeviation.controls['Deadline'].setValidators(
          Validators.required
        );
        this.formDeviation.controls['Deadline'].updateValueAndValidity();
      }
      if (this.formDeviation.controls['Execution']) {
        this.formDeviation.controls['Execution'].setValidators(
          Validators.required
        );
        this.formDeviation.controls['Execution'].updateValueAndValidity();
      }
      if (this.formDeviation.controls['Followup']) {
        this.formDeviation.controls['Followup'].setValidators(
          Validators.required
        );
        this.formDeviation.controls['Followup'].updateValueAndValidity();
      }
    } else {
      this.showMore = false;
      if (this.formDeviation.controls['Department']) {
        this.formDeviation.controls['Department'].setValidators(
          Validators.required
        );
        this.formDeviation.controls['Department'].updateValueAndValidity();
      }
      if (this.formDeviation.controls['Topic']) {
        this.formDeviation.controls['Topic'].setValidators(Validators.required);
        this.formDeviation.controls['Topic'].updateValueAndValidity();
      }
      if (this.formDeviation.controls['TypeCase']) {
        this.formDeviation.controls['TypeCase'].setValidators(
          Validators.required
        );
        this.formDeviation.controls['TypeCase'].updateValueAndValidity();
      }
      if (this.formDeviation.controls['Title']) {
        this.formDeviation.controls['Title'].setValidators(Validators.required);
        this.formDeviation.controls['Title'].updateValueAndValidity();
      }
      if (this.formDeviation.controls['Followup']) {
        this.formDeviation.controls['Followup'].clearValidators();
        this.formDeviation.controls['Followup'].updateValueAndValidity();
      }
      if (this.formDeviation.controls['Execution']) {
        this.formDeviation.controls['Execution'].clearValidators();
        this.formDeviation.controls['Execution'].updateValueAndValidity();
      }
      if (this.formDeviation.controls['Deadline']) {
        this.formDeviation.controls['Deadline'].clearValidators();
        this.formDeviation.controls['Deadline'].updateValueAndValidity();
      }
      if (this.formDeviation.controls['Importance']) {
        this.formDeviation.controls['Importance'].clearValidators();
        this.formDeviation.controls['Importance'].updateValueAndValidity();
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}
