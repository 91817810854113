import { BusinessServices } from 'src/app/kuba/businesses/services/business.services';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

import { BusinessDepartmentService } from './../../business-department.services';
import { BusinessDepartment, Employee, EmployeePosition } from '../models/';
import { BaseServices } from './../../kuba.services';
import { Responsibilty } from './../../projects/models/responsibility';
import { Timetable } from './../../time-table/models/timetable';
import { BusinessCourse } from './../models/employeeCourse';
import { EmployeeSicknessAbscence } from './../models/employeeSicknessAbscence';
import { EmployeeServices } from './employee.services';
import { EmployeeCourseServices } from './employeecourse.service';
import { EmployeeLeaveService } from './employeeleave.service';
import { EmployeeSicknessServices } from './employeesickness.service';
import { tap } from 'rxjs/operators';
import { Roles } from 'src/app/_models';

@Injectable()
export class EmployeeListResolver implements Resolve<Employee[]> {
  constructor(private backend: EmployeeServices) {}
  resolve(): Observable<Employee[]> {
    // Quick fix for getting portal employees as portal user
    if (BaseServices.roleId === '2') {
      return null;
      //return this.backend.portalList(BaseServices.PortalId);
    }
    // Regular list (non-portal)
    return this.backend.list(BaseServices.BusinessId);
  }
}
@Injectable()
export class PortalEmployeeListResolver implements Resolve<Employee[]> {
  constructor(private backend: EmployeeServices) {}
  resolve(): Observable<Employee[]> {
    if (BaseServices.roleId === '2') {
      return this.backend.portalList(BaseServices.PortalId);
    }
    return null!;
  }
}
@Injectable()
export class EmployeeEditResolver implements Resolve<Employee> {
  constructor(private backend: EmployeeServices) {}
  resolve(route: ActivatedRouteSnapshot) {
    if (+route.params['eid'] > 0) {
      return this.backend.getById(+route.params['eid']);
    } else {
      return null!;
    }
  }
}

@Injectable()
export class EmployeeSubDepartmentResolver implements Resolve<Employee> {
  departmentId: number;
  constructor(private backend: EmployeeServices) {}
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getSubDepartmentByEmployee(+route.params['eid']);
  }
}

@Injectable()
export class EmployeeSicknessListResolver
  implements Resolve<EmployeeSicknessAbscence[]>
{
  constructor(private backend: EmployeeSicknessServices) {}
  resolve(): Observable<EmployeeSicknessAbscence[]> {
    if (BaseServices.roleId === '2') {
      return this.backend.portalList(BaseServices.UserId);
    } else {
      return this.backend.list(BaseServices.BusinessId);
    }
  }
}
@Injectable()
export class PortalEmployeeSicknessListResolver
  implements Resolve<EmployeeSicknessAbscence[]>
{
  constructor(private backend: EmployeeSicknessServices) {}
  resolve(): Observable<EmployeeSicknessAbscence[]> {
    if (BaseServices.roleId === '2') {
      return this.backend.portalList(BaseServices.UserId);
    }
    return null!;
  }
}

@Injectable()
export class EmployeeSicknessByIdResolver
  implements Resolve<EmployeeSicknessAbscence[]>
{
  constructor(private backend: EmployeeSicknessServices) {}
  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<EmployeeSicknessAbscence[]> {
    if (+route.params['sid'] > 0) {
      let res: any = this.backend.getById(+route.params['sid']);
      return res;
    } else {
      return null!;
    }
  }
}
// Course Edit
@Injectable()
export class CourseEditResolver implements Resolve<Employee> {
  constructor(private backend: EmployeeCourseServices) {}
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getById(+route.params['eid']);
  }
}
// Course list
@Injectable()
export class CourseListResolver implements Resolve<Employee> {
  constructor(private backend: EmployeeCourseServices) {}
  resolve() {
    if (BaseServices.roleId !== '2') {
      return this.backend.list(BaseServices.BusinessId);
    } else {
      return null!;
    }
  }
}
// Contract list
@Injectable()
export class CourseListContractResolver implements Resolve<Employee> {
  constructor(private backend: EmployeeCourseServices) {}
  resolve() {
    if (BaseServices.roleId !== '2') {
      return this.backend.listcontract(BaseServices.BusinessId);
    } else {
      return null!;
    }
  }
}
// Course list by user
@Injectable()
export class PortalCourseListResolver implements Resolve<Employee> {
  constructor(private backend: EmployeeCourseServices) {}
  resolve() {
    if (BaseServices.roleId === '2') {
      return this.backend.portalList(BaseServices.UserId);
    } else {
      return null!;
    }
  }
}
// Course list by user
@Injectable()
export class PortalContractListResolver implements Resolve<Employee> {
  constructor(private backend: EmployeeCourseServices) {}
  resolve() {
    if (BaseServices.roleId === '2') {
      return this.backend.portalListContract(BaseServices.UserId);
    } else {
      return null!;
    }
  }
}
@Injectable()
export class ResponsiblePersonResolver implements Resolve<Responsibilty[]> {
  constructor(private backend: EmployeeCourseServices) {}
  resolve() {
    return this.backend.getAllByResp(BaseServices.BusinessId);
  }
}
@Injectable()
export class BusinessCourseResolver implements Resolve<BusinessCourse[]> {
  constructor(private backend: EmployeeCourseServices) {}
  resolve(route: ActivatedRouteSnapshot) {
    let url = route.url[0].path;
    let IsEmployeeCourse;
    if (url == 'contractlist' || url == 'editContract') {
      IsEmployeeCourse = false;
    } else {
      IsEmployeeCourse = true;
    }
    if (+BaseServices.roleId === Roles.PORTAL) {
      return this.backend.getAllByPortalCourseName(
        +BaseServices.PortalId,
        IsEmployeeCourse
      );
    } else {
      return this.backend.getAllByCourseName(
        +BaseServices.BusinessId,
        IsEmployeeCourse
      );
    }
  }
}

// COntract Resolver
@Injectable()
export class BusinessContractResolver implements Resolve<BusinessCourse[]> {
  constructor(private backend: EmployeeCourseServices) {}
  resolve() {
    return this.backend.getAllByContractName(BaseServices.BusinessId).pipe(
      tap(data => {
        console.log(data);
      })
    );
  }
}
@Injectable()
export class PortalCourseResolver implements Resolve<BusinessCourse[]> {
  id: number;
  portalId: number;
  private subscriptions: Subscription[] = [];
  constructor(
    private backend: EmployeeCourseServices,
    private businessService: BusinessServices
  ) {}
  resolve(route: ActivatedRouteSnapshot) {
    this.subscriptions.push(
      this.businessService
        .getBusinessCreator(BaseServices.BusinessId)
        .subscribe((portal: any) => {
          this.portalId = portal;
        })
    );
    let url = route.url[0].path;
    let IsEmployeeCourse;
    if (url == 'contractlist') {
      IsEmployeeCourse = false;
    } else if (url == 'editContract') {
      IsEmployeeCourse = false;
    } else {
      IsEmployeeCourse = true;
    }
    if (this.portalId > 0) {
      this.subscriptions.push(
        this.backend
          .getUserIdFromPortal(this.portalId)
          .subscribe((userId: any) => {
            this.id = userId;
          })
      );
      if (this.id > 0) {
        let getPortal: any = this.backend.getPortalCourse(
          this.id,
          IsEmployeeCourse
        );
        return getPortal;
      }
    } else {
      return this.backend.getPortalCourse(
        BaseServices.UserId,
        IsEmployeeCourse
      );
    }
  }
  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}
@Injectable()
export class EmployeeTimeTableResolver implements Resolve<Timetable[]> {
  constructor(private backend: EmployeeServices) {}
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getTimeTableByEmployeeId(+route.params['eid']);
  }
}

@Injectable()
export class EmployeeNameResolver implements Resolve<any[]> {
  constructor(private backend: EmployeeServices) {}
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getemployeename();
  }
}
@Injectable()
export class DepartmentDropDownResolver
  implements Resolve<BusinessDepartment[]>
{
  constructor(private backend: BusinessDepartmentService) {}
  resolve() {
    return this.backend.getCachedBusinessDepartment();
  }
}
@Injectable()
export class EmployeeDocResolver implements Resolve<any> {
  constructor(private backend: EmployeeServices) {}
  resolve(route: ActivatedRouteSnapshot) {
    if (+route.params['eid'] > 0) {
      let listDoc: any = this.backend.listDoc(+route.params['eid']);
      return listDoc;
    }
  }
}
@Injectable()
export class PositionDropDownResolver implements Resolve<EmployeePosition[]> {
  constructor(private backend: EmployeeServices) {}
  resolve() {
    return this.backend.getAllByPosition(BaseServices.BusinessId);
  }
}
@Injectable()
export class EmployeeDropDownResolver implements Resolve<any> {
  constructor(private backend: EmployeeServices) {}
  resolve() {
    return this.backend.getAllByType(BaseServices.BusinessId);
  }
}
@Injectable()
export class EmployeeLeaveResolver implements Resolve<any> {
  constructor(private backend: EmployeeLeaveService) {}
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getEmployeeLeavesByEmployeeId(+route.params['eid']);
  }
}

@Injectable()
export class EmployeeActiveStatusResolver implements Resolve<any> {
  constructor(private backend: EmployeeServices) {}
  resolve() {
    if (BaseServices.roleId !== '2') {
      return this.backend.getActiveEmployees(BaseServices.BusinessId);
    }
    return null;
  }
}
@Injectable()
export class EmployeeInActiveStatusResolver implements Resolve<any> {
  constructor(private backend: EmployeeServices) {}
  resolve() {
    return this.backend.getInActiveEmployees(BaseServices.BusinessId);
  }
}

@Injectable()
export class BusinessAbsenceCodesBusinessResolver implements Resolve<any> {
  constructor(private backend: EmployeeSicknessServices) {}
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getSicknessAbsenceCode(BaseServices.BusinessId);
  }
}
@Injectable()
export class UserDetailsResolver implements Resolve<any> {
  constructor(private backend: BusinessServices) {}
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getByUserId(BaseServices.UserId);
  }
}
@Injectable()
export class EmployeeBusinessDataResolver implements Resolve<any> {
  constructor(private backend: BusinessServices) {}
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getById(BaseServices.BusinessId);
  }
}
