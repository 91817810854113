<form [formGroup]="userForm">
  <div class="form">
    <div class="row">
      <div class="col-sm-6">
        <span class="page-title title-equal-space" translate
          >COMMON_DETAILS</span
        >
        <div class="form-group" *ngIf="userForm.controls['Name']">
          <label class="" for="Name">
            <span translate>NAME</span>
            <span class="required">*</span>
          </label>
          <div class="">
            <input
              type="text"
              pInputText
              formControlName="Name"
              id="Name"
              required
            />
          </div>
          <control-messages
            [control]="userForm.controls['Name']"
          ></control-messages>
        </div>
        <div class="form-group" *ngIf="userForm.controls['Username']">
          <label class="" for="Username">
            <span translate>USERNAME</span>
            <span class="required">*</span>
          </label>
          <div class="">
            <input
              type="text"
              pInputText
              formControlName="Username"
              id="Username"
              required
            />
          </div>
          <control-messages
            [control]="userForm.controls['Username']"
          ></control-messages>
        </div>
        <div class="form-group" *ngIf="userForm.controls['RoleId']">
          <label class="" for="RoleId">
            <span translate>ADDITIONAL_ROLE</span>
          </label>
          <div class="">
            <p-dropdown
              formControlName="RoleId"
              id="RoleId"
              [options]="Roles"
              [style]="{ width: '100%' }"
            >
            </p-dropdown>
          </div>
        </div>
        <div class="form-group" *ngIf="userForm.controls['OwnerOfAccount']">
          <label class="" for="Name">
            <span translate>OWNER_ACCOUNT</span>
          </label>
          <div class="">
            <input
              type="Name"
              pInputText
              id="Name"
              formControlName="OwnerOfAccount"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="" for="Mobile">
            <span translate>MOBILE</span>
            <span class="required">*</span>
          </label>
          <div class="phone-field">
            <p-dropdown
              [options]="mobileCodes"
              formControlName="CountryCode"
              [filter]="true"
              [style]="{ width: '110px' }"
            >
            </p-dropdown>
            <input
              type="number"
              pInputText
              formControlName="Mobile"
              (keypress)="onNumberChange($event, 20)"
            />
          </div>
          <control-messages
            [control]="userForm.controls['Mobile']"
          ></control-messages>
        </div>
        <div class="form-group" *ngIf="userForm.controls['Email']">
          <label class="" for="Email">
            <span translate>EMAIL</span>
            <span class="required">*</span>
          </label>
          <div class="">
            <input
              type="text"
              pInputText
              formControlName="Email"
              id="Email"
              required
            />
          </div>
          <control-messages
            [control]="userForm.controls['Email']"
          ></control-messages>
        </div>
        <div formGroupName="newPasswordValidation" *ngIf="!isExistingUser">
          <div class="form-group">
            <label class="" for="Password">
              <span translate>PASSWORD</span>
              <span class="required">*</span>
            </label>
            <div>
              <input
                type="password"
                pInputText
                formControlName="Password"
                id="Password"
                required
              />
            </div>
            <control-messages
              [control]="userForm.controls['Password']"
            ></control-messages>
          </div>
          <div class="form-group">
            <label class="" for="RepeatPassword">
              <span translate>REPEAT_PASSWORD</span>
              <span class="required">*</span>
            </label>
            <div class="">
              <input
                type="password"
                pInputText
                formControlName="ConfirmPassword"
              />
            </div>
            <control-messages
              [control]="userForm.controls['ConfirmPassword']"
            ></control-messages>
          </div>
          <control-messages
            [control]="userForm.controls['newPasswordValidation']"
          ></control-messages>
        </div>
      </div>
      <div class="col-sm-6">
        <div formArrayName="portalUserRights">
          <div class="">
            <span class="page-title title-equal-space" translate
              >USER_RIGHTS</span
            >
            <div
              class="checkbox"
              *ngFor="
                let def of userForm.controls['portalUserRights']['controls'];
                let k = index
              "
            >
              <div>
                <label>
                  <span formGroupName="{{ k }}">
                    <input
                      type="checkbox"
                      [ngModel]="portalInitialRightsData[k].isChecked"
                      formControlName="{{ portalInitialRightsData[k].id }}"
                      (change)="
                        onRightsCheckboxChange(
                          $event,
                          portalInitialRightsData[k].id
                        )
                      "
                    />
                    {{ portalInitialRightsData[k].name | translate }}
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
