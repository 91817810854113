import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { Observable } from 'rxjs';

import { TreeData } from '../../common/models';
import { KubaGeneralService } from './../../kuba-general.services';
import { BaseServices } from './../../kuba.services';
import { ManualService } from './manual.services';
import { BusinessServices } from '../../businesses/services/business.services';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class ManualListResolver implements Resolve<any[]> {
  constructor(private backend: ManualService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
    let levelId = 0;
    let levelKey = 0;
    switch (BaseServices.UserRole) {
      case 'Admin':
        levelId = 1;
        levelKey = BaseServices.ApplicationId;
        break;
      case 'Portal':
        levelId = 2;
        levelKey = +BaseServices.PortalId;
        break;
      case 'Editor':
        levelId = 3;
        levelKey = BaseServices.BusinessId;
        break;
      case 'User':
        levelId = 3;
        levelKey = BaseServices.BusinessId;
        break;
      case 'Guest':
        levelId = 3;
        levelKey = BaseServices.BusinessId;
        break;
    }
    if (BaseServices.UserRole === 'Admin') {
      let resut: any = this.backend.getCommonManuallist(
        BaseServices.ApplicationId
      )!;
      return resut;
    } else if (BaseServices.UserRole === 'Portal') {
      let result: any = this.backend.getCommonManuallistByPortalLogin(
        +BaseServices.PortalId
      )!;
      return result;
    } else if (
      BaseServices.UserRole === 'Editor' ||
      BaseServices.UserRole === 'User' ||
      BaseServices.UserRole === 'Guest'
    ) {
      let result: any = this.backend.getCommonManuallistByBusinessLogin(
        BaseServices.BusinessId
      )!;
      return result;
    }
    return null!;
  }
}

@Injectable()
export class PortalManualListResolver implements Resolve<any[]> {
  constructor(private backend: ManualService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
    let levelId = 2; // Portal level
    if (BaseServices.UserRole === 'Admin') {
      return this.backend.getPortalManuallist(BaseServices.ApplicationId);
    }
    return null!;
  }
}

@Injectable()
export class BusinessManualListResolver implements Resolve<any[]> {
  constructor(private backend: ManualService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
    let levelId = 3; // Business level
    if (BaseServices.UserRole === 'Admin') {
      return this.backend.getBusinessManuallist(BaseServices.ApplicationId);
    }
    return null!;
  }
}

@Injectable()
export class AllManualListResolver implements Resolve<any[]> {
  constructor(private backend: ManualService) {}
  resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
    if (BaseServices.UserRole === 'Admin') {
      return this.backend.getAllManuallist(BaseServices.ApplicationId);
    }
    return null!;
  }
}

@Injectable()
export class ManualTreeDataResolver implements Resolve<any[]> {
  constructor(
    private backend: ManualService,
    private businessService: BusinessServices
  ) {}

  /**
   * Resolves the tree for the manual
   * @param route Route with data necessary for the request
   * @returns Observable with a the manual's tree
   */
  resolve(route: ActivatedRouteSnapshot): Observable<TreeData[]> {
    let manualId = +route.params['mid'];
    let businessId = +route.params['mBusinessId'];
    let portalId = +route.params['mPortalId'];

    let userRole = BaseServices.UserRole;
    let appId = BaseServices.ApplicationId;
    if (userRole === 'Admin') {
      return this.backend.getManualTree(appId, manualId, portalId, businessId);
    }

    if (userRole === 'Portal') {
      return this.backend.getManualTreePortalLevel(
        manualId,
        appId,
        portalId,
        businessId
      );
    }
    // Business level manual
    else {
      /*
        Gets portal Id in case of the business being in a portal.
        Portal Id does not seems to be known by the frontend, even if the business belongs to a portal
        */
      return this.businessService.getBusinessPortalId(businessId).pipe(
        switchMap((businessPortalId) => {
          if (userRole === 'Editor') {
            return this.backend.getManualTreeBusinessLevel(
              manualId,
              appId,
              businessPortalId,
              businessId,
              false
            );
          } else {
            return this.backend.getManualTree(
              appId,
              manualId,
              businessPortalId,
              businessId
            );
          }
        })
      );
    }
  }
}

@Injectable()
export class ChoosePortalResolver implements Resolve<any[]> {
  constructor(private kubaGeneralService: KubaGeneralService) {}
  resolve(route: ActivatedRouteSnapshot): Observable<SelectItem[]> {
    return this.kubaGeneralService.getPortalList();
  }
}

@Injectable()
export class ChooseBusinessResolver implements Resolve<any[]> {
  constructor(private kubaGeneralService: KubaGeneralService) {}
  resolve(route: ActivatedRouteSnapshot): Observable<SelectItem[]> {
    return this.kubaGeneralService.getBusinessList();
  }
}

@Injectable()
export class ManualDataResolver implements Resolve<any[]> {
  constructor(private backend: ManualService) {}
  resolve(route: ActivatedRouteSnapshot): Observable<SelectItem[]> {
    let manualId = +route.params['mid'];
    return this.backend.getManualById(manualId);
  }
}

@Injectable()
export class ChecklistTemplateResolver implements Resolve<any[]> {
  constructor(private backend: ManualService) {}
  resolve(route: ActivatedRouteSnapshot): Observable<SelectItem[]> {
    let manualId = +route.params['mid'];
    return this.backend.getChecklistTemplates(manualId);
  }
}

@Injectable()
export class BusinessModeReslover implements Resolve<any[]> {
  constructor(private backend: ManualService) {}
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getBusinessMode(BaseServices.BusinessId);
  }
}
@Injectable()
export class AdminUserResolver implements Resolve<any[]> {
  constructor(private backend: ManualService) {}
  resolve() {
    if (+BaseServices.roleId === 1 || +BaseServices.roleId === 2) {
      return this.backend.getAdminUser();
    }
    return null!;
  }
}

@Injectable()
export class ApprovedManualResolver implements Resolve<any[]> {
  constructor(private backend: ManualService) {}
  resolve() {
    if (+BaseServices.roleId > 2) {
      return this.backend.getAdminUser();
    }
    return null!;
  }
}
