import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { BaseServices } from './../../kuba.services';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class EditorContactsServices {

    constructor(private http: HttpClient) {
    }

    contactList() {
        return this.http
            .get<any>(environment.BASE_URL + '/contacts', )
            .map(result => result);
    }
    contactById(id: number) {
        return this.http
            .get(
            environment.BASE_URL + '/contact/' + id,

            )
            .map((result: any) => result);
    }
    saveContact(data: any) {
        return this.http
            .post(
            environment.BASE_URL + '/contact',
            data,

            )
            .map(result => result);
    }
    updateContact(data: any) {
        return this.http
            .put(
            environment.BASE_URL + '/contact',
            data,

            )
            .map(result => result);
    }
    statusUpdate(id: number, status: number) {
        return this.http
            .put(
            `${environment.BASE_URL}/contact/${id}/${status}`,
              {}
            )
            .map(result => result);
    }
    deleteAll(Ids: any) {
        return this.http
            .put(
            environment.BASE_URL + '/contacts',
            Ids,

            )
            .map(result => result);
    }
    getContactFunctions() {
        return this.http
            .get(
            environment.BASE_URL + '/contactfunctions/contactFunctions',

            )
            .map(result => result);
    }
    saveContactFunction(data: any) {
        return this.http
            .post(
            environment.BASE_URL + '/contacFunction',
            data,

            )
            .map(result => result);
    }
    updateContactFunction(data: any) {
        return this.http
            .put(
            environment.BASE_URL + '/contactfunction',
            data,

            )
            .map(result => result);
    }
    deleteContactFunction(id: number) {
        return this.http
            .delete(
            environment.BASE_URL + '/contactfunctions/' + id,

            )
            .map(result => result);
    }
    getContactByBusiness(businessId: number) {
        return this.http
            .get<any>(
            environment.BASE_URL +
            '/customercontactbybusiness/' +
            businessId,

            )
            .map(result => result);
    }
    getContactUser(featureKey: number) {
        return this.http
            .get(
            environment.BASE_URL + '/contactUser/' + featureKey,

            )
            .map(result => result);
    }
    checkEmail(email: string) {
        return this.http
            .get(
            environment.BASE_URL + '/check-email/' + email,

            )
            .map(result => result);
    }

    getKubaNorgeAs() {
        return this.http
            .get(
            environment.BASE_URL + '/KubaNorge',

            )
            .map(result => result);
    }
}
