import { forgetPasswordRoutes } from './forget-password/forget-password.routing';

import { AppComponent } from './app.component';
import { Route } from '@angular/router';
import { loginRoutes } from './login/login.routing';
import { NotFoundComponent } from './shared/common/not-found/not-found.component';
import { ErrorPageComponent } from './shared/common/error-page/error-page.component';
import { kubaRoutes } from './kuba/kuba.routing';

export const appRoutes: Route[] = [
  {
    path: '',
    children: [
      {
        path: '',
        component: AppComponent,
        children: [
          ...kubaRoutes,
          ...loginRoutes,
          ...forgetPasswordRoutes,
        ],
      },
      {
        path: 'not-found',
        component: NotFoundComponent,
      },
      {
        path: 'error-page/:code',
        component: ErrorPageComponent,
      },
    ],
  },
];
