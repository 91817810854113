import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { BaseServices } from './../../kuba.services';
import {
  ApprovalPerson,
  Employees,
  Project,
  Timetable,
  TypeOfWork,
} from './../models/timetable';
import { TimetableServices } from './timetable.service';

@Injectable()
export class TimeTableListResolver implements Resolve<Timetable[]> {
  constructor(private backend: TimetableServices) {}
  resolve(route: ActivatedRouteSnapshot): Observable<Timetable[]> {
    return this.backend.getAllByEach(
      +route.params['Id'],
      route.parent.parent.data['parent']
    );
  }
}

@Injectable()
export class TimeTableEditResolver implements Resolve<Timetable> {
  constructor(private backend: TimetableServices) {}
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getById(+route.params['tid']);
  }
}

@Injectable()
export class EmployeeDropdownResolver implements Resolve<Employees> {
  constructor(private backend: TimetableServices) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getEmpByBusinessId(BaseServices.BusinessId);
  }
}
@Injectable()
export class TypeOfWorkDropdownResolver implements Resolve<TypeOfWork> {
  constructor(private backend: TimetableServices) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getTypeofworkbyBusinessId(BaseServices.BusinessId);
  }
}
@Injectable()
export class ApprovalPersonDropdownResolver implements Resolve<ApprovalPerson> {
  constructor(private backend: TimetableServices) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getallApprovalPerson();
  }
}

@Injectable()
export class EmployeeContactUserResolver implements Resolve<ApprovalPerson> {
  constructor(private backend: TimetableServices) {}
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getEmployeeContactUser();
  }
}
@Injectable()
export class ProjectDropdownResolver implements Resolve<Project> {
  constructor(private backend: TimetableServices) {}
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getProByBusinessId(BaseServices.BusinessId);
  }
}
