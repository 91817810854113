import { APP_BASE_HREF, CommonModule, DatePipe } from '@angular/common';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HttpClientJsonpModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ThemeService } from './theme.service';
import { AlertComponent } from './_directives/alert.component';
import { PanelTabComponent } from './_directives/panel-tabs';
import { AuthGuard, AdminTabGuard, AdminPortalTabGuard } from './_guards';
import { AppSharedModule } from './kuba/shared/appShared.module';
import { LoginModule } from './login/login.module';
import { appRoutes } from './app.routing';
import { ErrorPageComponent } from './shared/common/error-page/error-page.component';
import { NotFoundComponent } from './shared/common/not-found/not-found.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { InitialServices } from './_services/initial.services';
import { KubaServices } from './kuba/kuba.services';
import { AuthenticationService } from './_services/authentication.service';
import { AlertService } from './_services/alert.service';
import { getBaseLocation } from './shared/common/common-functions.util';
import { EcplDocumentExplorerModule } from './shared/ecpl-document-explorer';
import { ForgetPasswordModule } from './forget-password/forget-password.module';
import { ToasterModule } from 'angular2-toaster';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { KubaModule } from './kuba/kuba.module';

// Shared Components/Directives/Services
// Unathurized components
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, `assets/i18n/`, '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    AlertComponent,
    NotFoundComponent,
    ErrorPageComponent,
    PanelTabComponent,
  ],
  exports: [PanelTabComponent, TranslateModule],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppSharedModule,
    RouterModule.forRoot(appRoutes),
    LoginModule,
    KubaModule,
    ToasterModule,
    ForgetPasswordModule,
    EcplDocumentExplorerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    AuthGuard,
    AdminTabGuard,
    MessageService,
    AdminPortalTabGuard,
    AlertService,
    AuthenticationService,
    InitialServices,
    KubaServices,
    DatePipe,
    {
      provide: APP_BASE_HREF,
      useFactory: getBaseLocation,
    },
    ConfirmationService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
