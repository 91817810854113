export * from './activities.component';
export * from './deviation.component';
export * from './deviation-list.component';
export * from './meetingCalender.component';
export * from './department.component';
export * from './topics.component';
export * from './upload-attachment.component';
export * from './activity-edit.component';
export * from './riskanalysis.component';
export * from './riskanalysis-list.component';
export * from './riskanalysis-new.component';
export * from './activity-list.component';
export * from './viewlog.component';
export * from './staticdata.component';
export * from './deviation-new.component';
export * from './risk-new-portal.component';
