<div class="tab-content">
    <div class="page-title">
        <span translate>PRODUCTION</span>
    </div>
    <form [formGroup]="foodSafetyProductionForm">
        <div class="form-inline" class="mbtm-20">
            <div class="row">
                <div class="col-sm-3">
                    <div class="form-group">
                        <label class="col-form-label" translate>PRODUCTION_TYPES</label>
                        <span class="required">*</span>
                        <div>
                            <p-dropdown [options]="typesOfProduction" formControlName="ProductionType"
                                [style]="{'width':'160px'}" [disabled]="isEditMode">
                            </p-dropdown>
                            <button class="btn btn-outline-primary" type="button" (click)="addType = !addType">
                                <i class="pi pi-plus" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="form-group">
                        <label for="description" class="col-form-label" translate>QUANTITY
                            <span class="required">*</span>
                        </label>
                        <div>
                            <input type="number" (keypress)="onNumberChange($any($event))" class="form-control"
                                formControlName="Quantity" min="1" step="1" oninput="validity.valid||(value='');"
                                no-float>
                        </div>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div [hidden]="true">
                        <input type="text" formControlName="ProductDeliveryId">
                    </div>
                    <div class="form-group">
                        <label for="description" class="col-form-label" translate>SELECT_CLIENT
                            <span class="required">*</span>
                        </label>
                        <div>
                            <p-dropdown [options]="clients" formControlName="Client" [style]="{'width':'160px'}"
                                [disabled]="isEditMode">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="form-group">
                        <label for="description" class="col-form-label" translate>DELIVERY_DATE
                            <span class="required">*</span>
                        </label>
                        <div>
                            <p-calendar formControlName="DeliveryDate" dateFormat="mm/dd/yy" [disabled]="isEditMode">
                            </p-calendar>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mbtm-20" formArrayName="receptionArray"
            *ngIf="foodSafetyProductionForm['controls']['ProductionType'].value > 0 && foodSafetyProductionForm['controls']['Client'].value > 0">
            <div *ngFor="let Properties of foodSafetyProductionForm['controls']['receptionArray']['controls']; let i=index"
                class="sub-panel">
                <div class="sub-panel-title">
                    <span> {{'RECEPTION' | translate}} {{ i + 1}}</span>
                    <span class="sub-panel-actions pull-right"
                        *ngIf="foodSafetyProductionForm['controls']['receptionArray']['controls'].length >1">
                        <a class="btn-icon text-danger" href="javascript:void(0);" (click)="removeProperties(i)">
                            <span class="icon icon-sm icon-delete"></span>
                        </a>
                    </span>
                </div>
                <div class="sub-panel-content" [formGroupName]="i">
                    <div class="form-inline">
                        <div class="row">
                            <div class="col-sm-3">
                                <div class="form-group">
                                    <label>
                                        <span translate>RECEPTION_NUMBER</span>
                                        <span class="required">*</span>
                                    </label>
                                    <div>
                                        <p-dropdown [options]="recptDetails" formControlName="ReceptionNo"
                                            [style]="{'width':'150px'}" (onChange)="setQuantityAndMeasurement($event,i)"
                                            [disabled]="isEditMode"></p-dropdown>
                                    </div>
                                    <small *ngIf="Properties['controls']['ReceptionNo'].hasError('uniqueBy')"
                                        class="text-danger">
                                        <br> Can't assign same reception
                                    </small>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group" formGroupName="Quantities">
                                    <label>
                                        <span translate>QUANTITY_LEFT</span>
                                    </label>
                                    <div> <input type="text" class="form-control" formControlName="RemainingQuantity"
                                            [attr.disabled]="true"> </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group">
                                    <label>
                                        <span translate>PRODUCTION_MEASURES</span>
                                    </label>
                                    <div> <input type="text" class="form-control" formControlName="Measurement"
                                            [attr.disabled]="true"></div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group" formGroupName="Quantities">
                                    <label>
                                        <span translate>SELECTED_QUANTITY</span>
                                        <span class="required">*</span>
                                    </label>
                                    <div> <input type="text" pInputText
                                            pTooltip="Selected Quantity should be less than Remaining Quantity"
                                            class="form-control" formControlName="SelectedQuantity"
                                            [attr.disabled]="isEditMode ? '': null"> </div>
                                    <control-messages [control]="Properties['controls'].Quantities"></control-messages>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mbtm-20"
            *ngIf="foodSafetyProductionForm['controls']['ProductionType'].value > 0 && foodSafetyProductionForm['controls']['Client'].value > 0">
            <a (click)="addReception()" *ngIf="!isEditMode" href="javascript:void(0);" class="btn btn-outline-primary"
                [class.disabled]="!foodSafetyProductionForm['controls']['receptionArray'].valid">
                <i class="icon ic-sm icon-add"></i>
                <span translate>ADD_ANOTHER_PROPERTY</span>
            </a>
        </div>
        <div class="action-btns-wrapper">
            <button class="btn btn-outline-secondary" type="submit" (click)="gotoList()">
                <span class="icon ic-xs icon-back"></span>
                <span translate>BACK_LIST</span>
            </button>
            <button class="btn btn-primary" type="submit" (click)="submitForm(false)"
            [disabled]="!foodSafetyProductionForm.valid || !foodSafetyProductionForm['controls']['receptionArray'].valid">
            <span translate>SAVE</span>
        </button>
        <button class="btn btn-primary" type="submit" (click)="submitForm(true)"
            [disabled]="!foodSafetyProductionForm.valid || !foodSafetyProductionForm['controls']['receptionArray'].valid">
            <span translate>SAVE_CREATE_NEW</span>
        </button>        
        </div>
    </form>
</div>
<p-dialog header="{{'PRODUCTION_TYPES' | translate}}" [modal]="true" 
[style]="{width: '350px'}" [resizable]="false" [draggable]="false" [(visible)]="addType">
    <form [formGroup]="productionForm">
        <div class="form-group">
            <label for="title" class="col-form-label">
                <span translate>PRODUCTION_TYPES</span>
                <span class="required">*</span>
            </label>
            <div>
                <input type="text" class="label-input form-control" formControlName="Name">
                <span style="color:red" *ngIf="isNameExists">{{existsAlerttext}}</span>
            </div>
        </div>
    </form>
    <p-footer>
        <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
            <button type="button" class="btn btn-outline-primary" (click)="viewType = !viewType">
                <span translate>VIEW</span>
            </button>
            <button type="button" class="btn btn-primary" (click)="saveProdType()" [disabled]="productionForm.invalid">
                <span translate>SAVE</span>
            </button>
        </div>
        <div *ngIf="viewType">
            <div class="table-view">
                <p-table #dt [(selection)]="selectedProdType" [value]="prodTypesList" [rows]="5" [paginator]="true"
                    [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]">
                    <!-- Header -->
                    <ng-template pTemplate="header">
                        <tr>
                            <th>{{'PROD_TYPE' | translate}}</th>
                            <th></th>
                        </tr>
                        <tr>
                            <th>
                                <input pInputText type="text"
                                    (input)="dt.filter($any($event.target)?.value, 'Name', 'contains')" placeholder=""
                                    class="p-column-filter">
                            </th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <!-- Body Content -->
                    <ng-template pTemplate="body" let-prodType>
                        <tr>
                            <td>
                                <span>{{prodType.Name}}</span>
                            </td>
                            <td class="col-button">
                                <button type="button" class="btn btn-icon" title="{{'EDIT'|translate}}"
                                    (click)="editProdType(prodType)">
                                    <i class="icon ic-sm icon-pencil-tip"></i>
                                </button>
                                <button type="button" class="btn btn-icon" title="{{'DELETE'|translate}}"
                                    (click)="deleteProdType(prodType)">
                                    <i class="icon ic-sm icon-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </ng-template>
                    <!-- Empty message -->
                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td colspan="2">
                                {{'NO_RECORDS_FOUND'|translate}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <!-- Table Ends -->
            </div>
        </div>
    </p-footer>
</p-dialog>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
    acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
<router-outlet></router-outlet>
<toaster-component></toaster-component>