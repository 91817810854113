import { Component, OnInit, ViewChild } from '@angular/core';
import { ToasterComponent } from './../../../_directives/toaster.component';
import { ActivatedRoute, Params } from '@angular/router';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { ReceptionControlService } from './../services/reception-control.service';
import { ConfirmationService } from 'primeng/api';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { HelperService } from '../../../_services/helper.service';
import { TranslateService } from '@ngx-translate/core';
import { FeatureKey } from 'src/app/_models';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
@Component({
    selector: 'reception-control',
    templateUrl: 'reception-control-list.component.html'
})
export class ReceptionControlListComponent implements OnInit {
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    @ViewChild('dt',{static:false}) dt: Table;
    //#region variable
    KindOfGoods: any;
    Supplier: any;
    foodSafetyId: number;
    receptionCntrl: any[];
    parentKey: any;
    parentId: any;
    startDate: Date;
    endDate: Date;
    selectedReception: any = [];
    supplierList: any;
    goodsTypeData: any;
    ReceptionControlForm: FormGroup;
    additionalData: any;
    loading = false;
    isProductionEnabled = false;
    /**
    * Date picker configuration option
    */
    public startDateOptions: IMyDpOptions = {
        dateFormat: 'mm/dd/yyyy',
        editableDateField: false,
        openSelectorOnInputClick: true,
        firstDayOfWeek: 'su',
        satHighlight: true,
        height: '32px',
        selectionTxtFontSize: '14px',
        todayBtnTxt: this.translate.instant('TODAY'),
        dayLabels: {
            su: this.translate.instant('SUN'),
            mo: this.translate.instant('MON'),
            tu: this.translate.instant('TUE'),
            we: this.translate.instant('WED'),
            th: this.translate.instant('THU'),
            fr: this.translate.instant('FRI'),
            sa: this.translate.instant('SAT')
        },
        monthLabels: {
            1: this.translate.instant('JANUARY'),
            2: this.translate.instant('FEBRUARY'),
            3: this.translate.instant('MARCH'),
            4: this.translate.instant('APRIL'),
            5: this.translate.instant('MAY'),
            6: this.translate.instant('JUNE'),
            7: this.translate.instant('JULY'),
            8: this.translate.instant('AUGUST'),
            9: this.translate.instant('SEPTEMBER'),
            10: this.translate.instant('OCTOBER'),
            11: this.translate.instant('NOVEMBER'),
            12: this.translate.instant('DECEMBER')
        }
    };
    public endDateOptions: IMyDpOptions = this.startDateOptions;
    private subscriptions: Subscription[] = [];
    //#endregion

    //#region constructor
    /**
     * constructor
     * @param receptionControlService {ReceptionControlService}
     * @param route {ActivatedRoute}
     */
    constructor(public receptionControlService: ReceptionControlService,
        private route: ActivatedRoute,
        private confirmationService: ConfirmationService,
        private _fb: FormBuilder,
        private translate: TranslateService
    ) {
        this.parentId = route.snapshot.parent.parent.params['id'];
        this.parentKey = route.snapshot.parent.parent.data['parent'];
        if (route.snapshot.data['list']) {
            this.receptionCntrl = route.snapshot.data['list'];
        }
        this.subscriptions.push(this.translate.stream('FILENAME').subscribe(val => {
            this.additionalData = {
                fileName: val.RECEPTION_CONTROL,
                header: 'Reception control',
                businessId: BaseServices.BusinessId,
                cultureInfo: BaseServices.userCultureInfo(),
                columnNames: [
                    { title: 'No', dataKey: 'No' },
                    { title: 'Supplier', dataKey: 'Supplier' },
                    { title: 'Kind Of Goods', dataKey: 'KindOfGoods' },
                    { title: 'Received', dataKey: 'Received' },
                    { title: 'Quantity Left', dataKey: 'QtyLeft' },
                    { title: 'Reception Number', dataKey: 'ReceptionNo' },
                    { title: 'Wastage %', dataKey: 'Wastage%' },
                    { title: 'Status', dataKey: 'StatusText' },

                ]
            };
        }));

    }
    //#endregion

    //#region page-event
    /**
     * initialization
     */
    ngOnInit() {
        // To hide side menu bar
        document.querySelector("body").classList.remove("opened");
        this.ReceptionControlForm = this._fb.group({
            StartDate: [''],
            EndDate: ['']
        });
        this.subscriptions.push(this.route.params.subscribe((params: Params) => {
            this.foodSafetyId = + params['Id'];
        }));
        this.supplierList = [];
        let supplierData = this.route.snapshot.data['suppliers'];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.supplierList = [];
            this.supplierList.push(
                { label: val.SELECT, value: null }
            );
            if (supplierData) {
                supplierData.forEach(element => {
                    this.supplierList.push({ label: element.Name, value: `${element.Id}` });
                });
            }
        }));
        this.goodsTypeData = [];
        let goodsTypeList = this.route.snapshot.data['goodsType'];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.goodsTypeData = [];
            this.goodsTypeData.push(
                { label: val.SELECT, value: null }
            );
            if (goodsTypeList) {
                goodsTypeList.forEach(element => {
                    this.goodsTypeData.push({ label: element.Name, value: `${element.Id}` });
                });
            }
        }));
        let allAdditionalFeatures = JSON.parse(sessionStorage.getItem('workingModule')).children;
        allAdditionalFeatures.forEach(element => {
            if (element.id === FeatureKey.PRODUCTION && element.checked == true) {
               this.isProductionEnabled = true;
            }
        });
    }
    //#endregion
    //#region methods
    saveReception(receptionData) {
        this.loading = true;
        receptionData.Status = receptionData.Status == 8 ? 11: 8;
        this.subscriptions.push(this.receptionControlService.getById(this.parentId, receptionData.Id).subscribe(res => {
            res.Status = receptionData.Status;
            this.subscriptions.push(this.receptionControlService.addReceptionControl(res).subscribe((isAdded: any) => {
                this.bindReceptionTable();
                this.loading = false;
                this.toasterComponent.callToast();
            }));
        }));
    }
    /**
     * to filter employee in list
     * @param e
     * @param empDropdown
     */
     onEmpChanged(e: any, empDropdown: any) {
        if (e.value) {
            // custom filter datatable
            this.dt.filter(
                empDropdown.selectedOption.label,
                'KindOfGoods',
                ''
            );
            this.KindOfGoods = e.value;
        } else {
            this.dt.reset();
        }
    }
    /**
     * to filter employee in list
     * @param e
     * @param subDropdown
     */
     onSubChanged(e: any, subDropdown: any) {
        if (e.value) {
            // custom filter datatable
            this.dt.filter(
                subDropdown.selectedOption.label,
                'Supplier',
                ''
            );
            this.Supplier = e.value;
        } else {
            this.dt.reset();
        }
    }
    /**
     * Bind the reception details
     */
    bindReceptionTable() {
        this.subscriptions.push(this.receptionControlService.getAll(this.foodSafetyId).subscribe(result => {
            if (result) {
                this.receptionCntrl = result;
            }
        }));
    }
    //#endregion

    //#region methods
    /**
     * calculation for wastage
     * @param receptionCntrl {any}
     * @param i {number}
     */
    wastageCalculation(receptionCntrl: any, i: number) {
        if (receptionCntrl[i].Status === '11') {
            let wastage = 0;
            let quantity = receptionCntrl[i].Received;
            let remainingQuantity = receptionCntrl[i].QtyLeft;
            wastage = remainingQuantity * (100 / quantity);
            let result:any = wastage.toFixed(2) + '%';
            return result;
        }
    }
    /**
     *  multiple delete
     */

    multipleDelete() {
        let receptionIds: any = [];
        if (this.selectedReception.length > 0) {
            this.selectedReception.forEach((client: any) => {
                receptionIds.push(client.Id);
            });
            this.confirmationService.confirm({
                message: this.translate.instant('DELETE_SELECTED'),
                accept: () => {
                    this.subscriptions.push(this.receptionControlService.deleteReception(receptionIds).subscribe((isReceptionDeleted: any) => {
                        if (isReceptionDeleted) {
                            this.bindReceptionTable();
                            this.toasterComponent.callToastDlt();
                        }
                    }));
                    this.selectedReception = [];
                },
                reject: () => { }
            });
        } else {
            let alertmessage = this.translate.instant('PLEASE SELECT ATLEAST ONE RECEPTION TO DELETE');
            alert(alertmessage);
        }

    }
    /**
    * start date change event
    * @param event {IMyDateModel}
    */
    onStartDateChanged(event: IMyDateModel) {
        this.endDateOptions = this.setDateInput(this.endDateOptions, event, 'startDate');
        this.startDate = event.jsdate;
        this.receptionControlDateRangeFilter(this.startDate, this.endDate);
    }
    /**
     * End date change event
     * @param event {IMyDateModel}
     */
    onEndDateChanged(event: IMyDateModel) {
        let date: Date = event.jsdate ? new Date(event.jsdate.getTime()) : null;
        this.startDateOptions = this.setDateInput(this.startDateOptions, event, 'endDate');
        if (!date) {
            let copyConfig: IMyDpOptions = HelperService.getCopyOfDatePickerOptions(this.startDateOptions);
            this.startDateOptions = copyConfig;
        }
        this.endDate = event.jsdate;
        this.receptionControlDateRangeFilter(this.startDate, this.endDate);
    }
    /**
      * Method for setting date range
      * @param dateConfig {IMyDpOptions}
      * @param event{IMyDateModel}
      * @param type  {string}
      */
    setDateInput(dateConfig: IMyDpOptions, event: IMyDateModel, type: string): IMyDpOptions {
        let date: Date = event.jsdate ? new Date(event.jsdate.getTime()) : null;
        let dateOptions = HelperService.setDateRange(dateConfig, date, type);
        return dateOptions;
    }
    /**
    * TimeTable DateRange Filter
    * @param StartDate {any}
    * @param EndDate {any}
    */
    receptionControlDateRangeFilter(StartDate: any, EndDate: any) {
        let startDate = StartDate ? StartDate : new Date('1800-01-01');
        let endDate = EndDate ? EndDate : new Date();
        this.receptionCntrl = [];
        let list = this.route.snapshot.data['list'];
        if (list) {
            list = list.filter(x => {
                return (
                    new Date(x.Date) >= startDate &&
                    new Date(x.Date) <= endDate
                );
            });
            this.receptionCntrl.push(...list);
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
    //#endregion
}
