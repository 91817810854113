import { filter } from 'rxjs/operator/filter';
import { BaseServices } from '../../kuba.services';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';

import { Module } from './../../follow-ups/models/deviation';
import { KubaServices } from './../../kuba.services';
import { BusinessServices } from './../services/business.services';
import { element } from 'protractor';
import { FeaturesBaseServices } from '../../features/services/features-base.services';
import { Application, Roles, FeatureKey } from 'src/app/_models/feature';
import { Feature } from '../../features/models/features-base.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'businesses-wizard',
  templateUrl: 'business-wizard.component.html',
  styleUrls: ['business-wizard.component.css'],
})
export class BusinessWizardComponent implements OnInit {
  OurCompanyTabs: any;
  wizard: any;
  newBusiness: boolean;
  CompanyName: any;
  pageTitle: string;
  additionalFeatures: any;
  enabledAdditionalFeatures: any;
  fId: number;
  private subscriptions: Subscription[] = [];
  private language: string = this.translator.currentLang
    ? this.translator.currentLang
    : 'en';
  constructor(
    private activatedRoute: ActivatedRoute,
    private businessServices: BusinessServices,
    private kubaServices: KubaServices,
    private router: Router,
    private translator: TranslateService,
    public featuresBaseServices: FeaturesBaseServices
  ) {
    this.subscriptions.push(
      this.kubaServices.getLanguage$.subscribe((lang) => {
        if (lang) {
          this.language = lang;
        }
      })
    );

    if (BaseServices.ApplicationId === 3 || BaseServices.ApplicationId === 1) {
      this.OurCompanyTabs = [
        {
          id: 6,
          Title: 'Business',
          DefaultText: 'Our Company',
          LinkText: 'business/edit/1/details',
          Description: 'Business information',
          AdminNavigation: false,
          PortalNavigation: false,
          BusinessNavigation: true,
          AdditionFeatures: [
            {
              id: 1,
              Title: 'Details',
              DefaultText: 'Details',
              LinkText: 'details',
              Description: '',
              ParentId: '',
              AdminNavigation: true,
              PortalNavigation: true,
              BusinessNavigation: true,
              AdditionFeatures: false,
              FeatureFolders: false,
              Depends: false,
              Widgets: false,
              CustomText: '',
              Position: 1,
              translationKey: 'DETAIL',
            },
            {
              id: 5,
              Title: 'License',
              DefaultText: 'License Agreement',
              LinkText: 'license',
              Description: '',
              AdminNavigation: true,
              PortalNavigation: true,
              BusinessNavigation: true,
              AdditionFeatures: false,
              FeatureFolders: false,
              Widgets: false,
              Depends: ['FireDeviations'],
              CustomText: '',
              Position: 5,
              translationKey: 'LICENSE_AGREEMENT',
            },
            {
              id: 3,
              Title: 'Features',
              DefaultText: 'Features',
              LinkText: 'features',
              Description: '',
              AdminNavigation: true,
              PortalNavigation: true,
              BusinessNavigation: true,
              AdditionFeatures: false,
              FeatureFolders: false,
              Depends: false,
              Widgets: false,
              CustomText: '',
              Position: 3,
              translationKey: 'FEATURES',
            },
            {
              id: 6,
              Title: 'Settings',
              DefaultText: 'Settings',
              LinkText: 'settings',
              Description: '',
              AdminNavigation: true,
              PortalNavigation: true,
              BusinessNavigation: true,
              AdditionFeatures: false,
              FeatureFolders: false,
              Widgets: false,
              Depends: false,
              CustomText: '',
              Position: 6,
              translationKey: 'SETTING',
            },

            {
              id: 2,
              Title: 'Users',
              DefaultText: 'Users',
              LinkText: 'user/list',
              Description: '',
              ParentId: '',
              AdminNavigation: true,
              PortalNavigation: true,
              BusinessNavigation: true,
              AdditionFeatures: false,
              FeatureFolders: false,
              Depends: false,
              Widgets: false,
              CustomText: '',
              Position: 2,
              translationKey: 'USERS',
            },

            // {
            //     'id': 10,
            //     'Title': 'Absence Codes',
            //     'DefaultText': 'Absence Codes',
            //     'LinkText': 'absence-codes',
            //     'Description': '',
            //     'AdminNavigation': true,
            //     'PortalNavigation': true,
            //     'BusinessNavigation': true,
            //     'AdditionFeatures': false,
            //     'FeatureFolders': false,
            //     'Widgets': false,
            //     'Depends': false,
            //     'CustomText': '',
            //     'Position': 10,
            //     'translationKey': 'ABSENCE_CODES',
            // },
            {
              id: 12,
              Title: 'Signed',
              DefaultText: 'Signed',
              LinkText: 'signed',
              Description: '',
              AdminNavigation: true,
              PortalNavigation: true,
              BusinessNavigation: true,
              AdditionFeatures: false,
              FeatureFolders: false,
              Widgets: false,
              Depends: false,
              CustomText: '',
              Position: 12,
              translationKey: 'SIGNED',
            },
            {
              id: 12,
              Title: 'Links',
              DefaultText: 'Links',
              LinkText: 'links',
              Description: '',
              AdminNavigation: true,
              PortalNavigation: true,
              BusinessNavigation: true,
              AdditionFeatures: false,
              FeatureFolders: false,
              Widgets: false,
              Depends: false,
              CustomText: '',
              Position: 12,
              translationKey: 'LINKS',
            }
          ],
          Depends: false,
          FeatureFolders: false,
          Widgets: false,
          CustomText: '',
          Position: 6,
        },
      ];
    } else {
      this.OurCompanyTabs = [
        {
          id: 6,
          Title: 'Business',
          DefaultText: 'Our Company',
          LinkText: 'business/edit/1/details',
          Description: 'Business information',
          AdminNavigation: false,
          PortalNavigation: false,
          BusinessNavigation: true,
          AdditionFeatures: [
            {
              id: 1,
              Title: 'Details',
              DefaultText: 'Details',
              LinkText: 'details',
              Description: '',
              ParentId: '',
              AdminNavigation: true,
              PortalNavigation: true,
              BusinessNavigation: true,
              AdditionFeatures: false,
              FeatureFolders: false,
              Depends: false,
              Widgets: false,
              CustomText: '',
              Position: 1,
              translationKey: 'DETAIL',
            },
            {
              id: 6,
              Title: 'Settings',
              DefaultText: 'Settings',
              LinkText: 'settings',
              Description: '',
              AdminNavigation: true,
              PortalNavigation: true,
              BusinessNavigation: true,
              AdditionFeatures: false,
              FeatureFolders: false,
              Widgets: false,
              Depends: false,
              CustomText: '',
              Position: 6,
              translationKey: 'SETTING',
            },
            {
              id: 3,
              Title: 'Features',
              DefaultText: 'Features',
              LinkText: 'features',
              Description: '',
              AdminNavigation: true,
              PortalNavigation: true,
              BusinessNavigation: true,
              AdditionFeatures: false,
              FeatureFolders: false,
              Depends: false,
              Widgets: false,
              CustomText: '',
              Position: 3,
              translationKey: 'FEATURES',
            },
            {
              id: 2,
              Title: 'Users',
              DefaultText: 'Users',
              LinkText: 'user/list',
              Description: '',
              ParentId: '',
              AdminNavigation: true,
              PortalNavigation: true,
              BusinessNavigation: true,
              AdditionFeatures: false,
              FeatureFolders: false,
              Depends: false,
              Widgets: false,
              CustomText: '',
              Position: 2,
              translationKey: 'USERS',
            },
            {
              id: 5,
              Title: 'License',
              DefaultText: 'License Agreement',
              LinkText: 'license',
              Description: '',
              AdminNavigation: true,
              PortalNavigation: true,
              BusinessNavigation: true,
              AdditionFeatures: false,
              FeatureFolders: false,
              Widgets: false,
              Depends: ['FireDeviations'],
              CustomText: '',
              Position: 5,
              translationKey: 'LICENSE_AGREEMENT',
            },

            // {
            //     'id': 10,
            //     'Title': 'Absence Codes',
            //     'DefaultText': 'Absence Codes',
            //     'LinkText': 'absence-codes',
            //     'Description': '',
            //     'AdminNavigation': true,
            //     'PortalNavigation': true,
            //     'BusinessNavigation': true,
            //     'AdditionFeatures': false,
            //     'FeatureFolders': false,
            //     'Widgets': false,
            //     'Depends': false,
            //     'CustomText': '',
            //     'Position': 10,
            //     'translationKey': 'ABSENCE_CODES',
            // },
            {
              id: 12,
              Title: 'Signed',
              DefaultText: 'Signed',
              LinkText: 'signed',
              Description: '',
              AdminNavigation: true,
              PortalNavigation: true,
              BusinessNavigation: true,
              AdditionFeatures: false,
              FeatureFolders: false,
              Widgets: false,
              Depends: false,
              CustomText: '',
              Position: 12,
              translationKey: 'SIGNED',
            },
            {
              id: 12,
              Title: 'Links',
              DefaultText: 'Links',
              LinkText: 'links',
              Description: '',
              AdminNavigation: true,
              PortalNavigation: true,
              BusinessNavigation: true,
              AdditionFeatures: false,
              FeatureFolders: false,
              Widgets: false,
              Depends: false,
              CustomText: '',
              Position: 12,
              translationKey: 'LINKS',
            }
          ],
          Depends: false,
          FeatureFolders: false,
          Widgets: false,
          CustomText: '',
          Position: 6,
        },
      ];
    }

    this.fId = Module.OURCOMPANY;
    if (
      sessionStorage.getItem('workingModule') &&
      JSON.parse(sessionStorage.getItem('workingModule')!).name ===
        'Our Company'
    ) {
      let allAdditionalFeatures = JSON.parse(
        sessionStorage.getItem('workingModule')!
      ).children;
      this.enabledAdditionalFeatures = allAdditionalFeatures.filter(
        (element: any) => element.checked || element.IsDefault
      );
      if (this.enabledAdditionalFeatures.length > 0) {
        this.router.navigate(
          [this.enabledAdditionalFeatures[0].linkText, { Id: this.fId }],
          { relativeTo: this.activatedRoute }
        );
      }
    }
  }

  ngOnInit() {
    document.querySelector('body').classList.remove('opened');
    this.newBusiness = true;

    // this.features = (BaseServices.getUserFeatures()); // temporarily commented
    // TODO: This needs to be dynamic
    let wizardNav: any = _.find(this.OurCompanyTabs, { id: 6 })[
      'AdditionFeatures'
    ];

    if (
      (BaseServices.ApplicationId === Application.DIN_HMS ||
        BaseServices.ApplicationId === Application.IK) &&
      BaseServices.roleId === '2'
    ) {
      let featuresBaseDetails = FeaturesBaseServices.getFeatureDetails(
        BaseServices.getUserFeatures()
      );
    }

    let roleId = +BaseServices.roleId;
    let roleFilter = [1, 2, 3];
    if (
      (BaseServices.ApplicationId === Application.DIN_HMS ||
        BaseServices.ApplicationId === Application.IK ||
        BaseServices.ApplicationId === Application.UNIPRO) &&
      +BaseServices.roleId !== Roles.ADMIN &&
      +BaseServices.roleId !== Roles.PORTAL
    ) {
      wizardNav = wizardNav.filter((x: any) => x.id !== 5); // #KW-176
    }

    let businessFeatures = BaseServices.userFeatures;
    let FDVFeature = [];
    let DashBoardFeature = [];
    let MyPageFeature = [];
    let EmployeeFeature = [];
    let OurCompany = [];
    let DashLinksFeature = [];
    let DashIntnlTvFeature: any = [];
    let MyPageAbsnceFeature: any = [];
    let EmployeeAbsenceFeature: any = [];
    let OurCompanyLinks = [];
    if (+this.activatedRoute.snapshot.params['bid']) {
      if (
        window.name === 'Remote' ||
        BaseServices.roleId === '1' ||
        BaseServices.roleId === '2' ||
        BaseServices.roleId === '3'
      ) {
        let result = this.activatedRoute.snapshot.data['businessdata'];
        if (result && result.Features) {
          businessFeatures = <any>JSON.parse(result.Features);
          FDVFeature = businessFeatures.filter(
            (x: any) => x.id === FeatureKey.FDV
          );
          DashBoardFeature = businessFeatures.filter(
            (x: any) => x.id === FeatureKey.DASHBOARD
          );
          MyPageFeature = businessFeatures.filter(
            (x: any) => x.id === FeatureKey.MY_PAGE
          );
          EmployeeFeature = businessFeatures.filter(
            (x: any) => x.id === FeatureKey.EMPLOYEES
          );
          OurCompany = businessFeatures.filter(
            (x: any) =>
              x.translationKey === 'BUSINESS_LIST' ||
              x.translationKey === 'OUR_COMPANY'
          );
          if (
            DashBoardFeature &&
            DashBoardFeature[0] &&
            DashBoardFeature[0].children > 0
          ) {
            DashLinksFeature = DashBoardFeature[0].children.filter(
              (x: any) => x.name === 'Links'
            );
          }
          if (OurCompany && OurCompany.length > 0) {
            OurCompanyLinks = OurCompany[0].children.filter(
              (x: any) => x.id === 117
            );
          }
          if (
            MyPageFeature.length > 0 &&
            MyPageFeature[0].children != null &&
            MyPageFeature[0].children > 0
          ) {
            MyPageAbsnceFeature = MyPageFeature[0].children.filter(
              (x: any) => x.name === 'Sickness Absence'
            );
          }
          if (OurCompanyLinks.length ? !OurCompanyLinks[0].checked : false) {
            wizardNav = wizardNav.filter((x: any) => x.id != 12);
          }
          if (EmployeeFeature[0].children != null) {
            if (
              EmployeeFeature.length > 0 &&
              EmployeeFeature[0].children.length > 0
            ) {
              EmployeeAbsenceFeature = EmployeeFeature[0].children.filter(
                (x: any) => x.translationKey === 'EMPLOYEE_SICKNESS'
              );
            }
            if (
              EmployeeAbsenceFeature.length
                ? !EmployeeAbsenceFeature[0].checked
                : false
            ) {
              wizardNav = wizardNav.filter((x: any) => x.id != 10);
            }
          } else {
            wizardNav = wizardNav.filter((x: any) => x.id != 10);
          }
        } else {
          wizardNav = wizardNav.filter((x: any) => x.id != 10);
          wizardNav = wizardNav.filter((x: any) => x.id != 12);
        }
      } else if (businessFeatures) {
        FDVFeature = businessFeatures.filter(
          (x: any) => x.id === FeatureKey.FDV
        );
        DashBoardFeature = businessFeatures.filter(
          (x: any) => x.id === FeatureKey.DASHBOARD
        );
        MyPageFeature = businessFeatures.filter(
          (x: any) => x.id === FeatureKey.MY_PAGE
        );
        EmployeeFeature = businessFeatures.filter(
          (x: any) => x.id === FeatureKey.EMPLOYEES
        );
        OurCompany = businessFeatures.filter(
          (x: any) =>
            x.translationKey === 'BUSINESS_LIST' ||
            x.translationKey === 'OUR_COMPANY'
        );
        if (DashBoardFeature) {
          DashLinksFeature = DashBoardFeature[0].children.filter(
            (x: any) => x.name === 'Links'
          );
        }
        if (OurCompany && OurCompany.length > 0) {
          OurCompanyLinks = OurCompany[0].children.filter(
            (x: any) => x.id === 117
          );
        }
        if (
          EmployeeFeature.length > 0 &&
          EmployeeFeature[0].children.length > 0
        ) {
          EmployeeAbsenceFeature = EmployeeFeature[0].children.filter(
            (x: any) => x.translationKey === 'EMPLOYEE_SICKNESS'
          );
        }
        if (MyPageAbsnceFeature)
          MyPageAbsnceFeature = MyPageFeature[0].children.filter(
            (x: any) => x.name === 'Sickness Absence'
          );
      }
    } else if (businessFeatures) {
      FDVFeature = businessFeatures.filter((x: any) => x.id === FeatureKey.FDV);
      DashBoardFeature = businessFeatures.filter(
        (x: any) => x.id === FeatureKey.DASHBOARD
      );
      MyPageFeature = businessFeatures.filter(
        (x: any) => x.id === FeatureKey.MY_PAGE
      );
      EmployeeFeature = businessFeatures.filter(
        (x: any) => x.id === FeatureKey.EMPLOYEES
      );
      OurCompany = businessFeatures.filter(
        (x: any) =>
          x.translationKey === 'BUSINESS_LIST' ||
          x.translationKey === 'OUR_COMPANY'
      );
      if (DashBoardFeature) {
        DashLinksFeature = DashBoardFeature[0].children.filter(
          (x: any) => x.name === 'Links'
        );
      }
      if (OurCompany && OurCompany.length > 0) {
        OurCompanyLinks = OurCompany[0].children.filter(
          (x: any) => x.name === 'Links'
        );
      }
      if (
        MyPageAbsnceFeature.length ? !MyPageAbsnceFeature[0].checked : false
      ) {
        wizardNav = wizardNav.filter((x: any) => x.id != 10);
      }
      if (
        EmployeeAbsenceFeature.length
          ? !EmployeeAbsenceFeature[0].checked
          : false
      ) {
        wizardNav = wizardNav.filter((x: any) => x.id != 10);
      }
      if (DashLinksFeature.length ? !DashLinksFeature[0].checked : false) {
        wizardNav = wizardNav.filter((x: any) => x.id != 12);
      }
      if (OurCompany && OurCompany.length > 0) {
        OurCompanyLinks = OurCompany[0].children.filter(
          (x: any) => x.id === 117
        );
      }
      if (OurCompanyLinks[0].checked === true) {
        wizardNav = wizardNav.filter((x: any) => x.id != 12);
      }

      if (
        EmployeeFeature.length > 0 &&
        EmployeeFeature[0].children.length > 0
      ) {
        EmployeeAbsenceFeature = EmployeeFeature[0].children.filter(
          (x: any) => x.translationKey === 'EMPLOYEE_SICKNESS'
        );
      }

      if (EmployeeAbsenceFeature[0].checked === true) {
        wizardNav = wizardNav.filter((x: any) => x.id != 10);
      }
      if (FDVFeature.length ? !FDVFeature[0].checked : false) {
        wizardNav = wizardNav.filter((x: any) => x.id != 7);
      }
    } else if (businessFeatures) {
      FDVFeature = businessFeatures.filter((x: any) => x.id === FeatureKey.FDV);
      DashBoardFeature = businessFeatures.filter(
        (x: any) => x.id === FeatureKey.DASHBOARD
      );
      MyPageFeature = businessFeatures.filter(
        (x: any) => x.id === FeatureKey.MY_PAGE
      );
      EmployeeFeature = businessFeatures.filter(
        (x: any) => x.id === FeatureKey.EMPLOYEES
      );
      OurCompany = businessFeatures.filter(
        (x: any) => x.id === FeatureKey.EDIT_BUSINESS
      );
      if (DashBoardFeature) {
        DashLinksFeature = DashBoardFeature[0].children.filter(
          (x: any) => x.id === 6
        );
      }
      if (OurCompany && OurCompany.length > 0) {
        OurCompanyLinks = OurCompany[0].children.filter(
          (x: any) => x.id === 117
        );
      }
      if (MyPageFeature.length > 0)
        MyPageAbsnceFeature = MyPageFeature[0].children.filter(
          (x: any) => x.name === 'Sickness Absence'
        );

      if (
        EmployeeFeature.length > 0 &&
        EmployeeFeature[0].children.length > 0
      ) {
        EmployeeAbsenceFeature = EmployeeFeature[0].children.filter(
          (x: any) => x.translationKey === 'EMPLOYEE_SICKNESS'
        );
      }
    }
    if (MyPageAbsnceFeature.length ? !MyPageAbsnceFeature[0].checked : false) {
      wizardNav = wizardNav.filter((x: any) => x.id != 10);
    }
    if (
      EmployeeAbsenceFeature.length ? !EmployeeAbsenceFeature[0].checked : false
    ) {
      wizardNav = wizardNav.filter((x: any) => x.id != 10);
    }
    if (DashLinksFeature.length ? !DashLinksFeature[0].checked : false) {
      wizardNav = wizardNav.filter((x: any) => x.id != 12);
    }
    if (OurCompanyLinks.length ? !OurCompanyLinks[0].checked : false) {
      wizardNav = wizardNav.filter((x: any) => x.id != 12);
    }
    if (
      EmployeeAbsenceFeature.length ? !EmployeeAbsenceFeature[0].checked : false
    ) {
      wizardNav = wizardNav.filter((x: any) => x.id != 10);
    }
    if (DashIntnlTvFeature.length ? !DashIntnlTvFeature[0].checked : false) {
      wizardNav = wizardNav.filter((x: any) => x.id != 11);
    }
    if (FDVFeature.length ? !FDVFeature[0].checked : false) {
      wizardNav = wizardNav.filter((x: any) => x.id != 7);
    }
    if (DashLinksFeature.length ? !DashLinksFeature[0].checked : false) {
      wizardNav = wizardNav.filter((x: any) => x.id != 12);
    }
    if (FDVFeature.length ? !FDVFeature[0].checked : false) {
      wizardNav = wizardNav.filter((x: any) => x.id != 7);
    }
    if (roleFilter.indexOf(roleId) > -1) {
      this.wizard = wizardNav;
    } else {
      this.wizard = wizardNav.filter((element: any) => element.id === 2); // restrict to see other tabs for user
    }

    if (BaseServices.ApplicationId == 5) {
      this.wizard = this.wizard.filter(
        (x: any) => x.id != 7 && x.id != 10 && x.id != 12
      ); //remove Ansence code, and digital signature tabs for medi3 platform
    }

    if (BaseServices.ApplicationId == 5) {
      this.wizard = this.wizard.filter((x) => x.id != 10 && x.id != 12); //remove  Absence code and digital signature tabs for medi3 platform
    }

    if (BaseServices.ApplicationId == 7) {
      this.wizard = this.wizard.filter((x) => x.id != 5); // remove License Agreement from Unipro platform
    }

    if (BaseServices.ApplicationId != null) {
      this.wizard = this.wizard.filter((x: any) => x.Title != 'Signed');
    }

    this.subscriptions.push(
      this.activatedRoute.params.subscribe((params: Params) => {
        let bid = +params['bid'];
        if (bid) {
          this.subscriptions.push(
            this.businessServices.getById(bid).subscribe((res) => {
              this.CompanyName = res.CompanyName;
            })
          );
          this.newBusiness = false;
        } else {
          this.CompanyName = 'NEW_BUSINESS';
        }
      })
    );
  }

  /**
   * recursive search in a nested array
   * @param data {array}
   * @param featureId {number}
   */
  flattenFind(data: any, key: any) {
    let checkFeature = <Feature>_(data)
      .thru((parent) => {
        return _.union(parent, _.map(parent, 'children'));
      })
      .flatten()
      .find({ id: +key });
    if (checkFeature !== undefined) {
      return checkFeature.checked;
    } else {
      return false;
    }
  }
  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}
