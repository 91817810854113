<div class="main-content">
    <div class="main-heading">
        <span translate>ADMIN_USER_LIST</span>
        <span class="page-actions">
            <a routerLink="new" [routerLink]="['./../new']" class="btn btn-success" routerLinkActive="active">
                <span class="icon ic-sm icon-add"></span>
                <span translate>NEW</span>
            </a>
        </span>
    </div>
    <div class="card">
        <div class="card-body">
            <!-- Table component for user list -->
            <div class="table-view">
                <!-- Table starts -->
                <p-table #dt [value]="users" [rows]="10" [paginator]="true" [pageLinks]="3"
                  [rowsPerPageOptions]="[5,10,20]" exportFilename="Contact List"
                  [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}">
                    <!-- Caption -->
                    <ng-template pTemplate="caption">
                        <div class="ui-helper-clearfix">
                          <div class="table-options">
                            <div class="pull-right">
                              <kuba-export
                                [reportData]="dt.filteredValue || dt.value"
                                [additionalData]="additionalData"
                              ></kuba-export>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    <!-- Header -->
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="Name">{{'NAME'|translate}}</th>
                            <th>{{'USER_NAME'|translate}}</th>
                            <th pSortableColumn="Mobile">{{'MOBILE'|translate}}</th>
                            <th pSortableColumn="Email">{{'EMAIL'|translate}}</th>
                            <th pSortableColumn="CreatorName">{{'CREATOR_NAME'|translate}}</th>
                            <th>{{'OPTIONS'| translate}}</th>
                        </tr>
                        <tr>
                            <th></th>
                            <th>
                                <input pInputText type="text"
                                  (input)="dt.filter($any($event.target)?.value, 'Username', 'contains')" placeholder=""
                                  class="p-column-filter">
                            </th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <!-- Body Content -->
                    <ng-template pTemplate="body" let-adminuser>
                        <tr>
                            <td><span>{{adminuser.Name}}</span></td>
                            <td><span>{{adminuser.Username}}</span></td>
                            <td><span>{{adminuser.Mobile}}</span></td>
                            <td><span>{{adminuser.Email}}</span></td>
                            <td><span>{{adminuser.CreatorName}}</span></td>
                            <td class="col-button">
                                <a [routerLink]="['./../edit',adminuser.Id]" class="btn btn-icon">
                                    <i class="icon ic-sm icon-pencil-tip"></i>
                                </a>
                                <button type="button" (click)="delete(adminuser.Id)" class="btn btn-icon"
                                  title="{{'DELETE' | translate}}">
                                    <i class="icon ic-sm icon-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </ng-template>
                    <!-- Empty message -->
                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td colspan="6">
                                {{'NO_RECORDS_FOUND'|translate}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <!-- Table ends -->
            </div>
        </div>
    </div>
</div>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" width="425" acceptLabel="{{'SWITCH_YES'|translate}}"
  rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
<toaster-component></toaster-component>