import 'rxjs/add/operator/toPromise';
import 'rxjs/Rx';

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperService } from 'src/app/_services/helper.service';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { ConfirmationService } from 'primeng/api';

import { ToasterComponent } from './../../../_directives/toaster.component';
import { PdfSetting } from './../../../_services/helper.service';
import { VehicleServices } from './../services/vehicle.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
    templateUrl: 'vehicleList.component.html'
})
export class VehicleListComponent implements OnInit {
    @ViewChild(ToasterComponent, { static: false }) toasterComponent: ToasterComponent;
    Vehicles = [];
    additionalData: any;
    isHideDeleteIcon = true;
    guestLogin = true;
    isUser = true;
    private subscriptions: Subscription[] = [];
    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private vehicleSvc: VehicleServices,
        private confirmationService: ConfirmationService,
        private translate: TranslateService
    ) {
        this.additionalData = {
            fileName: 'Vehicle List',
            header: 'Vehicle List',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
                { title: 'Vehicle Name', dataKey: 'VehicleName' },
                { title: 'Brand', dataKey: 'BrandName' },
                { title: 'Model', dataKey: 'ModelName' },
                { title: 'Registration No', dataKey: 'RegistrationNo' },
                { title: 'Registration Year', dataKey: 'RegistrationYear' },
                { title: 'Type of Vehicles', dataKey: 'VehicleTypeName' },
                { title: 'Department', dataKey: 'DepartmentName' }
            ]
        };
    }

    ngOnInit(): void {
        document.querySelector("body").classList.remove("opened");
        this.isUser = (BaseServices.roleId === '5');
        document.getElementById('fleetControl').style.display = 'none';
        this.Vehicles = this.route.snapshot.data['list'];
        if (this.Vehicles) {
            this.imageLoad(this.Vehicles);
        }

        if (BaseServices.UserRole === 'Guest') {
            this.isHideDeleteIcon = false;
        } else if (BaseServices.UserRole === 'User') {
            this.isHideDeleteIcon = false;
        } else {
            this.isHideDeleteIcon = true;
        }
    }
    deleteVehicle(id: number) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.vehicleSvc.deleteVehicle(id).subscribe(result => {
                    if (result) {
                        this.toasterComponent.callToast();
                        this.bindGrid();
                    }
                }));
            }
        });
    }

    bindGrid() {
        this.subscriptions.push(this.vehicleSvc.getAllVehicles().subscribe(result => {
            if (result) {
                this.imageLoad(result);
            }
        }));
    }
    exportPdf() {
        let pdfSetting = new PdfSetting();
        pdfSetting.date = HelperService.formatDate(new Date());
        pdfSetting.businessName = BaseServices.BusinessName;
        pdfSetting.pageHeader = 'VehiclesDetails';
        let columns = [
            { title: 'Vehicle Name', dataKey: 'Name' },
            { title: 'Brand', dataKey: 'BrandName' },
            { title: 'Model', dataKey: 'ModelName' },
            { title: 'Registration Number', dataKey: 'RegistrationNo' },
            { title: 'Registration Year', dataKey: 'RegistrationYear' },
            { title: 'Type Of Vehicles', dataKey: 'VehicleTypeName' },
            { title: 'Department', dataKey: 'DepartmentName' }
        ];
        HelperService.generatePdf(
            this.Vehicles,
            columns,
            'Vehicles',
            pdfSetting,
            'l'
        );
    }
    imageLoad(data: any) {
        this.Vehicles = [];
        data.forEach(element => {
            element.VehicleTracking =
                'icon ic-sm icon-shopping-cart';
            data = element;
            this.Vehicles.push(data);
        });
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
interface MyWindow extends Window {
    vehicleTracking(): void;
}
declare var window: MyWindow;
