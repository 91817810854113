<div class="main-content">
  <div class="main-heading">
    <strong>
      <span translate>ADD_EDIT_CONTACT</span>
    </strong>
  </div>
  <div class="card">
    <div class="card-body">
      <form [formGroup]="contactForm">
        <div class="row">
          <div class="col-sm-6">
            <div class="page-title">
              <span translate>CONTACT</span>
            </div>
            <div class="form-group">
              <label for="Type">
                <span translate>TYPE_OF_CONTACT</span>
                <span class="required">*</span>
              </label>
              <div>
                <p-dropdown
                  formControlName="ContactTypeId"
                  [style]="{ width: '150px' }"
                  [options]="typeOfContact"
                >
                </p-dropdown>
              </div>
              <control-messages
                [control]="contactForm.controls['TypeOfEmployee']"
              ></control-messages>
            </div>
            <div class="form-group">
              <label for="Type">
                <span translate>NAME</span>
                <span class="required">*</span>
              </label>
              <div>
                <input
                  type="text"
                  maxlength="100"
                  pInputText
                  formControlName="Name"
                />
              </div>
              <control-messages
                [control]="contactForm.controls['Name']"
              ></control-messages>
            </div>
            <div class="form-group">
              <label for="Address">
                <span translate>ADDRESS</span>
              </label>
              <div>
                <textarea
                  id="Address"
                  maxlength="250"
                  name="Address"
                  pInputTextarea
                  formControlName="Address"
                  [autoResize]="true"
                ></textarea>
              </div>
            </div>
            <div class="form-group-25_75">
              <div class="form-group">
                <label for="ZipOrCity">
                  <span translate>ZIP_CODE</span>
                </label>
                <div>
                  <input
                    type="number"
                    pInputText
                    onpaste="return false;"
                    formControlName="Zipcode"
                    (input)="onMobileNumber($event, 20)"
                  />
                </div>
              </div>
              <div class="form-group">
                <label for="Location">
                  <span translate>LOCATION</span>
                </label>
                <div>
                  <input
                    type="text"
                    maxlength="100"
                    pInputText
                    formControlName="Location"
                    id="Location"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="Mobile">
                <span translate>MOBILE</span>
              </label>
              <div class="phone-field">
                <p-dropdown
                  [options]="CountryCode"
                  formControlName="CountryCode"
                  filter="true"
                  [style]="{ width: '110px' }"
                >
                </p-dropdown>
                <input
                  style="width: 67%"
                  type="number"
                  pInputText
                  onpaste="return false;"
                  formControlName="Mobile"
                  (keypress)="onMobileNumber($event, 20)"
                />
                <button
                  class="btn btn-link"
                  type="button"
                  (click)="showDialog()"
                >
                  <span class="icon ic-sm icon-043"></span>
                </button>
              </div>
            </div>
            <div class="form-group">
              <label for="Email">
                <span translate>EMAIL</span>
                <span class="required">*</span>
              </label>
              <div>
                <input type="text" pInputText formControlName="Email" />
              </div>
              <control-messages
                [control]="contactForm.controls['Email']"
              ></control-messages>
            </div>

            <div class="form-group">
              <div class="mb-2">
                <p-checkbox
                  label="{{ 'NOTIFICATION_SMS' | translate }}"
                  formControlName="NotificationBySms"
                  [binary]="true"
                ></p-checkbox>
              </div>

              <div class="mb-2">
                <p-checkbox
                  label="{{ 'NOTIFICATION_EMAIL' | translate }}"
                  formControlName="NotificationByEmail"
                  [binary]="true"
                ></p-checkbox>
              </div>
              <div class="mb-2">
                <p-checkbox
                  label="{{ 'SHOW_DASHBOARD' | translate }}"
                  [binary]="true"
                  formControlName="showDashboard"
                ></p-checkbox>
              </div>
              <div class="mb-2">
                <p-checkbox
                  label="{{ 'SHOW_GUEST' | translate }}"
                  [binary]="true"
                  [binary]="true"
                  formControlName="ShowToGuest"
                ></p-checkbox>
              </div>
              <div class="mb-2">
                <p-checkbox
                  label="{{ 'RECEIVE_NEW_DEVIATION' | translate }}"
                  [binary]="true"
                  formControlName="IsEmailNewDeviation"
                ></p-checkbox>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="page-title">
              <span translate>WORK_PLACE</span>
            </div>
            <div class="form-group">
              <label for="Type">
                <span translate>POSITION</span>
              </label>
              <div>
                <input
                  type="text"
                  maxlength="100"
                  pInputText
                  formControlName="Position"
                />
              </div>
            </div>
            <div class="form-group">
              <label for="Company">
                <span translate>COMPANY</span>
              </label>
              <div>
                <input
                  type="text"
                  maxlength="100"
                  pInputText
                  formControlName="Company"
                />
              </div>
            </div>
            <div class="form-group">
              <label for="Address">
                <span translate>ADDRESS</span>
              </label>
              <div>
                <textarea
                  id="Address"
                  maxlength="250"
                  name="Address"
                  pInputTextarea
                  formControlName="workAddress"
                  [autoResize]="true"
                ></textarea>
              </div>
            </div>
            <div class="form-group-25_75">
              <div class="form-group">
                <label for="ZipOrCity">
                  <span translate>ZIP_CODE</span>
                </label>
                <div>
                  <input
                    type="text"
                    pInputText
                    formControlName="workZipcode"
                    (keypress)="onMobileNumber($event, 20)"
                  />
                </div>
              </div>
              <div class="form-group">
                <label for="Location">
                  <span translate>LOCATION</span>
                </label>
                <div>
                  <input
                    type="text"
                    maxlength="100"
                    pInputText
                    formControlName="workLocation"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="Website">
                <span translate>WEBSITE</span>
              </label>
              <div>
                <input
                  type="text"
                  maxlength="100"
                  pInputText
                  formControlName="Website"
                />
              </div>
            </div>
            <div class="page-title">
              <span translate>COMMENT</span>
            </div>
            <div class="form-group">
              <label>
                <span translate>NOTE</span>
              </label>
              <div>
                <textarea
                  type="text"
                  maxlength="250"
                  pInputText
                  formControlName="Note"
                ></textarea>
              </div>
            </div>
            <div class="form-group">
              <label>
                <span translate>FUNCTION</span>
                <span class="required">*</span>
              </label>
              <div class="input-btn-group">
                <p-dropdown
                  [filter]="true"
                  [options]="functions | dropdownSelectTranslate"
                  formControlName="Function"
                  [style]="{ width: '100%' }"
                >
                </p-dropdown>
                <button
                  class="btn btn-outline-primary"
                  type="button"
                  (click)="addFunction = !addFunction"
                  *ngIf="!isPortalCreated && this.isEditor"
                >
                  <i class="pi pi-plus" aria-hidden="true"></i>
                </button>
              </div>
              <control-messages
                [control]="contactForm.controls['Function']"
              ></control-messages>
            </div>
          </div>
        </div>
        <div class="action-btns-wrapper">
          <button class="btn btn-outline-secondary" (click)="gotoList()">
            <span class="icon ic-sm icon-back"></span>
            <span translate>BACK_LIST</span>
          </button>
          <button
            type="button"
            *ngIf="!isPortalCreated"
            class="btn btn-primary addbuton"
            [disabled]="!contactForm.valid"
            (click)="submitForm(true)"
          >
            <span translate>SAVE</span>
          </button>
          <ng-template [ngIf]="isHideSaveUserButton">
            <button
              type="button"
              *ngIf="!isPortalCreated"
              type="button"
              class="btn btn-primary addbuton"
              [disabled]="!contactForm.valid"
              (click)="saveUserAndProceed()"
            >
              <span translate>SAVE_NEW_USER</span>
            </button>
          </ng-template>
          <ng-template [ngIf]="canViewUser">
            <button
              type="button"
              *ngIf="!isPortalCreated"
              type="button"
              class="btn btn-primary addbuton"
              (click)="saveUserAndProceed()"
            >
              <span translate>EDIT_USER</span>
            </button>
          </ng-template>
        </div>
      </form>
    </div>
  </div>
</div>

<p-dialog
  header="{{ 'FUNCTION' | translate }}"
  (onHide)="onFuctionFormHide()"
  [(visible)]="addFunction"
  width="300"
  [responsive]="true"
>
  <form [formGroup]="functionForm">
    <div class="form-group">
      <label for="title" class="col-form-label">
        <span translate>NAME</span>
      </label>
      <div>
        <input
          type="text"
          #title
          class="label-input form-control"
          maxlength="100"
          [(ngModel)]="selectedFunction.Name"
          formControlName="Name"
        />
        <span style="color: red" *ngIf="isNameExists">{{
          existsAlerttext
        }}</span>
        <span style="color: red" *ngIf="isfunctionExists">{{
          functionExisting
        }}</span>
      </div>
    </div>
    <p-footer>
      <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
        <button
          type="button"
          class="btn btn-outline-primary"
          (click)="viewFunction = !viewFunction"
        >
          <span translate>VIEW</span>
        </button>
        <button
          type="button"
          class="btn btn-primary"
          [disabled]="!functionForm.valid"
          (click)="saveFunction()"
        >
          <span translate>SAVE</span>
        </button>
      </div>
      <div *ngIf="viewFunction">
        <div class="table-view">
          <!-- Table starts -->
          <p-table
            #dt
            [rows]="5"
            [value]="editFunction"
            [paginator]="true"
            [pageLinks]="3"
            [rowsPerPageOptions]="[5, 10, 20]"
            [responsive]="true"
          >
            <!-- Header -->
            <ng-template pTemplate="header">
              <tr>
                <th>{{ "FUNCTION_NAME" | translate }}</th>
                <th></th>
              </tr>
              <tr>
                <th>
                  <input
                    pInputText
                    type="text"
                    (input)="
                      dt.filter($any($event.target)?.value, 'Name', 'contains')
                    "
                    placeholder=""
                    class="p-column-filter"
                  />
                </th>
                <th></th>
              </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-categoryFunction>
              <tr>
                <td>
                  <span>{{ categoryFunction.Name }}</span>
                </td>
                <td>
                  <button
                    type="button"
                    class="btn btn-icon"
                    title="{{ 'EDIT' | translate }}"
                    (click)="editFunctions(categoryFunction)"
                  >
                    <i class="icon ic-sm icon-pencil-tip"></i>
                  </button>
                </td>
              </tr>
            </ng-template>
            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" let-columns>
              <tr>
                <td colspan="2">
                  {{ "NO_RECORDS_FOUND" | translate }}
                </td>
              </tr>
            </ng-template>
          </p-table>
          <!-- Table ends -->
        </div>
      </div>
    </p-footer>
  </form>
</p-dialog>
<p-dialog
  header="{{ 'ADD_EDIT_USER' | translate }}"
  [(visible)]="addCategory"
  width="1100"
  [responsive]="true"
>
  <form [formGroup]="userForm">
    <div class="row">
      <div class="col-sm-5">
        <div class="form">
          <div class="form-group" *ngIf="userForm.controls['Name']">
            <label for="Name">
              <span translate>NAME</span>
              <span class="required">*</span>
            </label>
            <div>
              <input
                type="text"
                pInputText
                maxlength="100"
                formControlName="Name"
                id="Name"
                required
              />
            </div>
            <control-messages
              [control]="userForm.controls['Name']"
            ></control-messages>
          </div>
          <div class="form-group" *ngIf="userForm.controls['Username']">
            <label for="Username">
              <span translate>USERNAME</span>
              <span class="required">*</span>
            </label>
            <div class="input-btn-group">
              <input
                type="text"
                pInputText
                maxlength="100"
                formControlName="Username"
                id="Username"
                required
              />
              <button
                class="btn btn-outline-primary"
                (click)="refreshUsername()"
                type="button"
              >
                <i class="pi pi-refresh" aria-hidden="true"></i>
              </button>
            </div>
            <control-messages
              [control]="userForm.controls['Username']"
            ></control-messages>
            <div>
              <a
                routerLinkActive="active"
                class="btn btn-link"
                (click)="clearUserName()"
                translate
                >OTHER_NAME</a
              >
            </div>
          </div>
          <div class="form-group">
            <label for="RoleId">
              <span translate>ADDITIONAL_ROLE</span>
            </label>
            <div>
              <p-dropdown
                formControlName="RoleId"
                [options]="role"
                [style]="{ width: '100%' }"
                (onChange)="onRoleDropDownChanged($event)"
              ></p-dropdown>
            </div>
          </div>
          <div class="form-group">
            <label for="Name">
              <span translate>OWNER_ACCOUNT</span>
            </label>
            <div>
              <input
                type="Name"
                pInputText
                formControlName="Name"
                id="Name"
                readonly
              />
            </div>
          </div>
          <div class="form-group">
            <label for="Mobile">
              <span translate>MOBILE</span>
            </label>
            <div class="phone-field">
              <p-dropdown
                [options]="CountryCode"
                formControlName="CountryCode"
                [filter]="true"
                [style]="{ width: '120px' }"
              >
              </p-dropdown>
              <input
                type="number"
                (keypress)="onMobileNumber($event, 20)"
                pInputText
                formControlName="Mobile"
                id="Mobile"
              />
            </div>
          </div>
          <div class="form-group" *ngIf="userForm.controls['Email']">
            <label for="Email">
              <span translate>EMAIL</span>
              <span class="required">*</span>
            </label>
            <div>
              <input
                type="text"
                maxlength="100"
                pInputText
                formControlName="Email"
                id="Email"
                required
              />
            </div>
            <control-messages
              [control]="userForm.controls['Email']"
            ></control-messages>
          </div>
          <div formGroupName="passwordValidation">
            <div class="form-group" *ngIf="isNewUser">
              <label for="Password">
                <span translate>PASSWORD</span>
                <span class="required">*</span>
              </label>
              <div>
                <input
                  type="password"
                  pInputText
                  formControlName="Password"
                  id="Password"
                  required
                />
              </div>
              <control-messages
                [control]="userForm.controls['Password']"
              ></control-messages>
            </div>
            <div class="form-group" *ngIf="isNewUser">
              <label for="RepeatPassword">
                <span translate>REPEAT_PASSWORD</span>
                <span class="required">*</span>
              </label>
              <div>
                <input
                  type="password"
                  pInputText
                  id="RepeatPassword"
                  formControlName="ConfirmPassword"
                />
              </div>
            </div>
            <control-messages
              [control]="userForm.controls['passwordValidation']"
            ></control-messages>
          </div>
          <div *ngIf="israndomPassword">
            <label>
              <span translate>YOUR_RANDOM_PASSWORD</span>:
              <span style="color: green">{{ showPassword }}</span>
            </label>
          </div>
        </div>
      </div>
      <div class="col-sm-7">
        <div formArrayName="userCategories" *ngIf="editedUser">
          <div class="row">
            <div class="col-sm-4">
              <span class="page-title" translate>USER_RIGHTS</span>
              <div
                *ngFor="
                  let def of userForm.controls['userCategories']['controls'];
                  let i = index
                "
              >
                <div *ngIf="userRightsData[i].rightsType === 1">
                  <label>
                    <span formGroupName="{{ i }}">
                      <input
                        type="checkbox"
                        formControlName="{{ userRightsData[i].id }}"
                        (change)="
                          onRightsCheckboxChange(
                            $event,
                            userRightsData[i].id,
                            'USER'
                          )
                        "
                      />
                      {{ userRightsData[i].name | translate }}
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <span class="page-title" translate>EMPLOYEE_RIGHTS</span>
              <div
                *ngFor="
                  let def of userForm.controls['userCategories']['controls'];
                  let i = index
                "
              >
                <div *ngIf="userRightsData[i].rightsType === 2">
                  <label>
                    <span formGroupName="{{ i }}">
                      <input
                        type="checkbox"
                        formControlName="{{ userRightsData[i].id }}"
                        (change)="
                          onRightsCheckboxChange(
                            $event,
                            userRightsData[i].id,
                            'USER'
                          )
                        "
                      />
                      {{ userRightsData[i].name | translate }}
                    </span>
                  </label>
                </div>
              </div>
              <span class="page-title" translate>DEVIATION_RIGHTS</span>
              <div
                *ngFor="
                  let def of userForm.controls['userCategories']['controls'];
                  let i = index
                "
              >
                <div *ngIf="userRightsData[i].rightsType === 3">
                  <label>
                    <span formGroupName="{{ i }}">
                      <input
                        type="checkbox"
                        formControlName="{{ userRightsData[i].id }}"
                        (change)="
                          onRightsCheckboxChange(
                            $event,
                            userRightsData[i].id,
                            'USER'
                          )
                        "
                      />
                      {{ userRightsData[i].name | translate }}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div formArrayName="guestCategories" *ngIf="editedGuest">
          <div class="row">
            <div class="col-sm-4">
              <span class="page-title" translate>USER_RIGHTS</span>
              <div
                class="checkbox"
                *ngFor="
                  let def of userForm.controls['guestCategories']['controls'];
                  let j = index
                "
              >
                <div *ngIf="guestRightsData[j].rightsType === 1">
                  <label>
                    <span formGroupName="{{ j }}">
                      <input
                        type="checkbox"
                        formControlName="{{ guestRightsData[j].id }}"
                        (change)="
                          onRightsCheckboxChange(
                            $event,
                            guestRightsData[j].id,
                            'GUEST'
                          )
                        "
                      />
                      {{ guestRightsData[j].name | translate }}
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div class="col-sm-4" *ngIf="!hideEmployeeRights">
              <span class="page-title" translate>EMPLOYEE_RIGHTS</span>
              <div
                class="checkbox"
                *ngFor="
                  let def of userForm.controls['guestCategories']['controls'];
                  let j = index
                "
              >
                <div *ngIf="guestRightsData[j].rightsType === 2">
                  <label>
                    <span formGroupName="{{ j }}">
                      <input
                        type="checkbox"
                        formControlName="{{ guestRightsData[j].id }}"
                        (change)="
                          onRightsCheckboxChange(
                            $event,
                            guestRightsData[j].id,
                            'GUEST'
                          )
                        "
                      />
                      {{ guestRightsData[j].name | translate }}
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <span class="page-title" translate>DEVIATION_RIGHTS</span>
              <div
                class="checkbox"
                *ngFor="
                  let def of userForm.controls['guestCategories']['controls'];
                  let j = index
                "
              >
                <div *ngIf="guestRightsData[j].rightsType === 3">
                  <label>
                    <span formGroupName="{{ j }}">
                      <input
                        type="checkbox"
                        formControlName="{{ guestRightsData[j].id }}"
                        (change)="
                          onRightsCheckboxChange(
                            $event,
                            guestRightsData[j].id,
                            'GUEST'
                          )
                        "
                      />
                      {{ guestRightsData[j].name | translate }}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          formArrayName="editorCategories"
          class="form-group"
          *ngIf="editedEditor"
        >
          <div class="row">
            <div class="col-sm-6">
              <span class="page-title" translate>USER_RIGHTS</span>
              <div
                class="checkbox"
                *ngFor="
                  let def of userForm.controls['editorCategories']['controls'];
                  let k = index
                "
              >
                <div *ngIf="editorRightsData[k].rightsType === 1">
                  <label>
                    <span formGroupName="{{ k }}">
                      <input
                        type="checkbox"
                        formControlName="{{ editorRightsData[k].id }}"
                        (change)="
                          onRightsCheckboxChange(
                            $event,
                            editorRightsData[k].id,
                            'EDITOR'
                          )
                        "
                      />
                      {{ editorRightsData[k].name | translate }}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
      <button
        *ngIf="isNewUser"
        class="btn btn-outline-primary"
        type="button"
        (click)="generateRandomPassword()"
      >
        <span translate>RANDOM_PASSWORD</span>
      </button>
      <button
        class="btn btn-primary"
        type="button"
        (click)="saveAndClose()"
        [disabled]="!userForm.valid"
      >
        <span translate>SAVE_CLOSE</span>
      </button>
      <button
        class="btn btn-primary"
        type="button"
        (click)="saveAndSendSMS()"
        [disabled]="!userForm.valid || !isMobile"
      >
        <span translate>SAVE_AND_SENDSMS</span>
      </button>
    </div>
  </p-footer>
</p-dialog>
<p-dialog
  header="{{ 'INFO' | translate }}"
  [(visible)]="dialogDisplay"
  [modal]="true"
  width="300"
>
  <span translate>
    PLEASE_ADD_YOUR_MOBILE_NUMBER_TO_RECEIVE_USERNAME_AND_PASSWORD
  </span>
</p-dialog>
<toaster-component></toaster-component>
<p-confirmDialog
  header="{{ 'CONFIRMATION' | translate }}"
  icon="pi pi-question-circle"
  width="425"
  acceptLabel="{{ 'SWITCH_YES' | translate }}"
  rejectLabel="{{ 'SWITCH_NO' | translate }}"
></p-confirmDialog>
<p-dialog
  header="{{ 'RESET_USERNAME' | translate }}"
  (onHide)="close()"
  [(visible)]="userNameValidation"
  width="300"
  [responsive]="true"
>
  <span class="text-danger" translate>USERNAME_EXIST</span>
</p-dialog>
<p-dialog
  header="{{ 'RESET_PASSWORD' | translate }}"
  (onHide)="closeResetPassword()"
  [(visible)]="showPasswordResetDialog"
  width="300"
  [responsive]="true"
>
  <form [formGroup]="updatePasswordForm">
    <div formGroupName="passwordValidation">
      <div class="form-group" *ngIf="!isNewUser">
        <label for="RepeatPassword">
          <span translate>NEW_PASSWORD</span>
          <span class="required">*</span>
        </label>
        <div>
          <input
            type="password"
            pInputText
            id="NewPassword"
            formControlName="Password"
          />
        </div>
      </div>
      <div class="form-group" *ngIf="!isNewUser">
        <label for="RepeatPassword">
          <span translate>CONFIRM_PASSWORD</span>
          <span class="required">*</span>
        </label>
        <div>
          <input
            type="password"
            pInputText
            id="ConfirmPassword"
            formControlName="ConfirmPassword"
            required
          />
        </div>
      </div>
      <div *ngIf="passwordValidation">
        <span class="text-danger">YOUR_PASSWORD_DONOT_MATCH</span>
      </div>
      <control-messages
        [control]="updatePasswordForm.controls['passwordValidation']"
      ></control-messages>
    </div>
    <button
      class="btn btn-outline-primary"
      (click)="resetPassWord()"
      [disabled]="!updatePasswordForm.valid"
    >
      <span translate>SAVE</span>
    </button>
  </form>
</p-dialog>
