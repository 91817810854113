import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { AccessLog } from '../models/accesslog';
import { BaseServices } from './../../kuba.services';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { catchError, map, timeout } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AccessLogServices {

    constructor(private http: HttpClient) {}
    getAllAccessLog() {
        return this.http
            .get<any>(environment.BASE_URL + '/accesslog',);
    }
    getAccessLogById(id: number) {
        return this.http
            .get<any>(
                environment.BASE_URL + '/accesslog/' + id, ).pipe(map((result: any) => result));
    }
    addAccessLog(accessLog: any) {
        return this.http
            .post(
                environment.BASE_URL + '/accesslog',
                accessLog,
            ).pipe(map((result:any) => result));
    }

    
    getAllBusiness() {
        return this.http
            .get(
                environment.BASE_URL + '/businesses', ).pipe(map((result:any) => result));
    }
    updateAccessLog(accessLog: AccessLog) {
        return this.http
            .put(
                environment.BASE_URL + '/client/client',
                accessLog, ).pipe(map((result:any) => result));
    }
    getAllUsers() {
        return this.http
            .get(environment.BASE_URL + '/users', ).pipe(map((result:any) => result));
    }
}
