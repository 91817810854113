import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router, Params } from '@angular/router';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl
} from '@angular/forms';

import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { BusinessList, BusinessAgreement } from './../models/businessList';
import { BaseServices } from './../../kuba.services';
import { Category } from './../models/category';
import { ValidationService } from './../../shared/services/validation.service';
import { BusinessEdit } from '../models/index';
import { BusinessServices } from '../services/business.services';
import { BusinessCategoryService } from '../services/businessCategory.service';
import { TranslateService } from '@ngx-translate/core';
import { Application } from 'src/app/_models/feature';
import { FeaturesBaseServices } from '../../features/services/features-base.services';
import { Feature } from '../../features/models/features-base.model';
import * as _ from 'lodash';
import { HelperService } from 'src/app/_services/helper.service';
import { Table } from 'primeng/table';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';

let jsPDF = require('jspdf');

require('jspdf-autotable');

@Component({
  selector: 'business-details',
  templateUrl: 'business-details.component.html'
})
export class BusinessDetailsComponent implements OnInit {
  //#region variables
  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  @ViewChild('categoryTable', { static: false }) categoryTable: Table;
  businessDetailForm: FormGroup;
  categoryPopup: FormGroup;
  OrgnizationNumber: string;
  cachedOrganizationNumber: string;
  categoryId: number;
  businesses: BusinessList[];
  businessEdit: BusinessEdit;
  businessId: number;
  statues: SelectItem[];
  vcategories: SelectItem[];
  editCategories: any = [];
  category = new Category();
  selectedCategory = new Category();
  business: any;
  bcategories: any;
  Available: any;
  Unavailable: any;
  OrganizationNumber: any;
  orgNumStatus: any;
  message: any;
  addCategory = false;
  isNewBusiness = false;
  isReadOnly = false;
  IsKeypUp = true;
  viewCategory = false;
  visability = false;
  messagevisability = false;
  orgNumberNotValid = false;
  isNumberExists = false;
  isNumberNotExists = false;
  existsAlerttext: string;
  isNameExists: boolean;
  isAleadyUse: boolean;
  loadingReport = false;
  loading: boolean;
  isDinHMS = false;
  isEditBusiness = false;
  isHideLicenseInformation = false;
  isAdmin = false;
  licenseAgreementDetail: BusinessAgreement;
  private subscriptions: Subscription[] = [];
  //#endregion

  //#region constructors
  /**
   * constructor
   * @param route {ActivatedRoute}
   * @param router {Router}
   * @param route {ActivatedRoute}
   * @param _location {Location}
   * @param _fb {FormBuilder}
   * @param businessService {BusinessService}
   * @param businessCategoryService {BusinessCategoryService}
   * @param confirmationService {ConfirmationService}
   */
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _location: Location,
    private _fb: FormBuilder,
    private businessService: BusinessServices,
    private businessCategoryService: BusinessCategoryService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    public featuresBaseServices: FeaturesBaseServices,
    private businessagrremnt: BusinessServices
  ) {
    businessService.clearCachedBusinessService();
    this.businessDetailForm = this._fb.group({
      category: ['', [Validators.required, Validators.nullValidator]],
      orgNumber: [
        '',
        [
          Validators.required,
          Validators.nullValidator,
          ValidationService.checkOrganizationNumber
        ],
        this.isOrgNumberUnique.bind(this) // async Validator passed as 3rd parameter
      ],
      companyName: [
        '',
        [Validators.required, ValidationService.noWhitespaceValidator]
      ],
      companyNumber: ['', [Validators.nullValidator]],
      aboutBusiness: [''],
      employeeCount: [''],
      address: [
        '',
        [Validators.required, ValidationService.noWhitespaceValidator]
      ],
      zipCode: [
        '',
        [Validators.required, ValidationService.noWhitespaceValidator]
      ],
      telePhone: [
        '',
        [Validators.required, ValidationService.noWhitespaceValidator]
      ],
      email: [
        '',
        [
          Validators.required,
          ValidationService.emailValidator,
          Validators.nullValidator
        ]
      ],
      status: [1, [Validators.required, Validators.nullValidator]]
    });

    this.categoryPopup = this._fb.group({
      Name: ['', [Validators.required, ValidationService.noWhitespaceValidator]]
    });
  }
  //#endregion

  //#region page-events
  /**
   * page load event
   */
  ngOnInit() {
    document.querySelector('body').classList.remove('opened');
    localStorage.setItem('detailsInfo', null!);
    this.isNewBusiness = false;
    this.businessEdit = new BusinessEdit();
    this.subscriptions.push(
      this.route.parent.params.subscribe((params: Params) => {
        let bid = params['bid'];
        this.businessId = +bid;
        if (this.businessId > 0) {
          if (BaseServices.roleId === '3') {
            this.isReadOnly = true;
          }
          if (BaseServices.AbsoluteroleId === '1') {
            this.isAdmin = true;
          }
          console.log(this.isAdmin);
          this.isNewBusiness = true;
          this.isEditBusiness = true;
          let list = this.route.parent!.snapshot.data['employeeCount'];
          let employeeCount: any = list?.filter((x: any) => x.Status === 1);
          let result = this.route.parent!.snapshot.data['detail'];
          if (result) {
            this.cachedOrganizationNumber = result.OrganizationNumber;
            this.businessEdit = result;
            this.business = {
              category: result.BusinessCategoryId,
              orgNumber: result.OrganizationNumber,
              companyName: result.CompanyName,
              companyNumber: result.CompanyNumber,
              aboutBusiness: result.AboutBusiness,
              address: result.Address,
              zipCode: result.Zipcode,
              telePhone: result.Telephone,
              email: result.Email,
              status: result.Status,
              employeeCount: employeeCount.length
            };

            (<FormGroup>this.businessDetailForm).setValue(this.business, {
              onlySelf: true
            });
          }
        }
      })
    );
    this.subscriptions.push(
      this.translate.stream('SELECT_STATUS_DROP').subscribe(val => {
        this.statues = [];
        this.statues.push(
          { label: val.INACTIVE, value: 2 },
          { label: val.ACTIVE, value: 1 }
        );
      })
    );
    this.bindCategory();

    if (
      BaseServices.ApplicationId === Application.DIN_HMS ||
      BaseServices.ApplicationId === Application.IK
    ) {
      this.isDinHMS = true;
    }
  }
  //#endregion

  //#region control-events
  /**
   * validation for organization number
   * @param control
   */
  isOrgNumberUnique(control: FormControl) {
    const q = new Promise(resolve => {
      setTimeout(() => {
        if (
          control.value === '' ||
          control.value === this.cachedOrganizationNumber
        ) {
          resolve(null);
        } else {
          this.subscriptions.push(
            this.businessService.getOrganizationNumber(control.value).subscribe(
              x => {
                if (!x) {
                  resolve(null);
                } else {
                  resolve({ isOrgNumberUnique: true });
                }
              },
              () => {
                resolve({ isOrgNumberUnique: true });
              }
            )
          );
        }
      }, 1000);
    });
    return q;
  }
  //#endregion

  //#region methods
  /**
   * bind category dropdown
   */
  bindCategory() {
    this.editCategories = [];

    if (this.businessId > 0) {
      this.bcategories = this.route.parent!.snapshot.data['category'];
    } else {
      this.bcategories = this.route.snapshot.data['category'];
    }
    if (this.bcategories) {
      this.vcategories = [];
      this.subscriptions.push(
        this.translate.stream('SELECT_CATEGORY_DROP').subscribe(val => {
          this.vcategories.push({ label: val.SELECT_CATEGORY, value: null });
        })
      );
      this.bcategories.forEach((category: any) => {
        this.vcategories.push({ label: category.Name, value: category.Id });
        this.editCategories.push({ Name: category.Name, Id: category.Id });
      });
    }
  }

  /**
   * reset category table
   */
  resetCategory() {
    this.subscriptions.push(
      this.businessCategoryService.getAllCategories().subscribe(result => {
        this.bcategories = result;
        this.vcategories = [];
        if (this.bcategories) {
          this.bcategories.forEach((category: any) => {
            this.vcategories.push({ label: category.Name, value: category.Id });
          });
        }
        this.editCategories = result;
      })
    );
  }

  /**
   * save and update business detail
   */
  saveChanges() {
    this.loading = true;
    if (this.businessDetailForm.valid) {
      this.businessEdit.BusinessCategoryId =
        this.businessDetailForm.value.category;
      this.businessEdit.OrganizationNumber =
        this.businessDetailForm.value.orgNumber;
      this.businessEdit.CompanyName = this.businessDetailForm.value.companyName;
      this.businessEdit.CompanyNumber =
        this.businessDetailForm.value.companyNumber;
      this.businessEdit.AboutBusiness =
        this.businessDetailForm.value.aboutBusiness;
      this.businessEdit.Address = this.businessDetailForm.value.address;
      this.businessEdit.Zipcode = this.businessDetailForm.value.zipCode;
      this.businessEdit.Telephone = this.businessDetailForm.value.telePhone;
      this.businessEdit.Email = this.businessDetailForm.value.email;
      this.businessEdit.Status = 1;
      this.businessEdit.EmployeeCount =
        this.businessDetailForm.value.employeeCount;
      this.businessEdit.ApplicationId = BaseServices.ApplicationId;
      this.businessEdit.Id = this.businessId ? this.businessId : 0;
      if (this.businessId > 0) {
        // edit business
        this.businessEdit.ModifiedBy = BaseServices.UserId;
        this.subscriptions.push(
          this.businessService
            .update(this.businessId, this.businessEdit)
            .subscribe(result => {
              if (result) {
                this.toasterComponent.callToast();
                this.loading = false;
              }
            })
        );
      } else {
        // new business
        this.businessEdit.CreatedBy = BaseServices.UserId;
        if (BaseServices.roleId === '2') {
          // portal
          this.businessEdit.BusinessPortalId = BaseServices.PortalId;
        }
        this.subscriptions.push(
          this.businessService
            .create(this.businessEdit)
            .subscribe((result: any) => {
              if (result) {
                this.businessId = result.Id;
                this.toasterComponent.callToast();
                if (this.isHideLicenseInformation) {
                  this.bindDefaultLicenseAgreementDetails(result.Id, false);
                }
              }
            })
        );
      }
    }
  }

  /**
   * save and update business detail and goto next tab
   */
  saveNextChanges() {
    this.loading = true;
    if (this.businessDetailForm.valid) {
      this.businessEdit.BusinessCategoryId =
        this.businessDetailForm.value.category;
      this.businessEdit.OrganizationNumber =
        this.businessDetailForm.value.orgNumber;
      this.businessEdit.CompanyName = this.businessDetailForm.value.companyName;
      this.businessEdit.CompanyNumber =
        this.businessDetailForm.value.companyNumber;
      this.businessEdit.AboutBusiness =
        this.businessDetailForm.value.aboutBusiness;
      this.businessEdit.Address = this.businessDetailForm.value.address;
      this.businessEdit.Zipcode = this.businessDetailForm.value.zipCode;
      this.businessEdit.Telephone = this.businessDetailForm.value.telePhone;
      this.businessEdit.Email = this.businessDetailForm.value.email;
      this.businessEdit.Status = 1;
      this.businessEdit.EmployeeCount =
        this.businessDetailForm.value.employeeCount;
      this.businessEdit.ApplicationId = BaseServices.ApplicationId;
      this.businessEdit.Id = this.businessId ? this.businessId : 0;
      if (this.businessId > 0) {
        // edit business
        this.businessEdit.ModifiedBy = BaseServices.UserId;
        if (this.isDinHMS) {
          if (this.business.roleId === 3) {
            this.router.navigate([`../license`], { relativeTo: this.route });
          } else {
            localStorage.setItem(
              'detailsInfo',
              JSON.stringify(this.businessEdit)
            );
            this.router.navigate([`../features`], { relativeTo: this.route });
          }
        } else {
          this.subscriptions.push(
            this.businessService
              .update(this.businessId, this.businessEdit)
              .subscribe((result: any) => {
                if (result) {
                  this.toasterComponent.callToast();
                  setTimeout(() => {
                    this.loading = false;
                    if (!this.isNewBusiness) {
                      this.router.navigate(
                        [`./../../edit/${result.Id}/settings`],
                        { relativeTo: this.route }
                      );
                    } else {
                      if (this.isHideLicenseInformation) {
                        this.router.navigate([`../features`], {
                          relativeTo: this.route
                        });
                      } else {
                        this.router.navigate([`../settings`], {
                          relativeTo: this.route
                        });
                      }
                    }
                  }, 1000);
                }
              })
          );
        }
      } else {
        // new business
        this.businessEdit.CreatedBy = BaseServices.UserId;
        if (BaseServices.roleId === '2') {
          // portal
          this.businessEdit.BusinessPortalId = BaseServices.PortalId;
        }
        if (this.isDinHMS) {
          localStorage.setItem(
            'detailsInfo',
            JSON.stringify(this.businessEdit)
          );
          this.router.navigate([`./../../new/license`], {
            relativeTo: this.route
          });
        } else {
          this.subscriptions.push(
            this.businessService
              .create(this.businessEdit)
              .subscribe((result: any) => {
                if (result) {
                  this.toasterComponent.callToast();
                  if (this.isHideLicenseInformation) {
                    this.bindDefaultLicenseAgreementDetails(result.Id, true);
                  } else {
                    setTimeout(() => {
                      this.loading = false;
                      this.router.navigate(
                        [`./../../edit/${result.Id}/settings`],
                        { relativeTo: this.route }
                      );
                    }, 1000);
                  }
                }
              })
          );
        }
      }
    }
  }

  /**
   * get back location
   */
  gotoBack() {
    this._location.back();
  }

  editCategory(category: Category) {
    this.categoryId = category.Id;
    let result = {
      Name: category.Name
    };
    (<FormGroup>this.categoryPopup).setValue(result, { onlySelf: true });
  }

  deleteCategory(categoryId: any) {
    this.confirmationService.confirm({
      message: this.translate.instant('DELETE_THIS_RECORD'),
      accept: () => {
        this.subscriptions.push(
          this.businessCategoryService
            .deleteCategory(categoryId)
            .subscribe(category => {
              if (category) {
                this.resetCategory();
                this.toasterComponent.callToastDlt();
              } else {
                this.isAleadyUse = true;
              }
            })
        );
      }
    });
  }

  /**
   * add and update category
   */
  saveCategory() {
    this.category.Name = this.categoryPopup.value.Name;
    this.category.Status = 1;
    this.category.ApplicationId = BaseServices.ApplicationId;
    if (this.categoryId) {
      this.category.Id = this.categoryId;
      this.subscriptions.push(
        this.businessCategoryService
          .update(this.category.Id, this.category)
          .subscribe((updatedCategory: any) => {
            if (updatedCategory) {
              this.toasterComponent.callToast();
              this.resetCategory();
              this.businessDetailForm
                .get('category')!
                .patchValue(updatedCategory.Id);
              this.clearCategory();
            } else {
              this.existsAlerttext = this.translate.instant(
                `CATEGORY_ALREADY_EXIST`
              );
              this.isNameExists = true;
              setTimeout(
                function () {
                  this.isNameExists = false;
                }.bind(this),
                3000
              );
            }
          })
      );
    } else {
      this.subscriptions.push(
        this.businessCategoryService
          .create(this.category)
          .subscribe((addedCategory: any) => {
            if (addedCategory) {
              this.toasterComponent.callToast();
              this.resetCategory();
              this.businessDetailForm
                .get('category')!
                .patchValue(addedCategory.Id);
              this.clearCategory();
            } else {
              this.existsAlerttext = this.translate.instant(
                `CATEGORY_ALREADY_EXIST`
              );
              this.isNameExists = true;
              setTimeout(
                function () {
                  this.isNameExists = false;
                }.bind(this),
                3000
              );
            }
          })
      );
    }
  }

  /**
   * clear category
   */
  clearCategory() {
    this.bindCategory();
    this.selectedCategory = new Category();
    this.addCategory = false;
    this.categoryId = 0;
    this.category.Id = 0;
    this.categoryPopup = this._fb.group({
      Name: ['', [Validators.required, ValidationService.noWhitespaceValidator]]
    });
  }
  /**
   * organization number change
   */
  onOrgNumberChange(e: any, limitNumber: any) {
    const limit = limitNumber;
    if (e.target.value.length === limit) {
      this.subscriptions.push(
        this.businessService
          .getOrganizationNumber(e.target.value)
          .subscribe(x => {
            if (!x) {
              this.isNumberExists = true;
              this.isNumberNotExists = false;
            }
          })
      );
    } else if (e.target.value.length > limit || e.target.value.length < limit) {
      this.isNumberNotExists = true;
      this.isNumberExists = false;
    } else if (e.target.value.length === 0) {
      this.isNumberNotExists = false;
      this.isNumberExists = false;
    } else if (e.code === 'Backspace') {
      this.isNumberExists = false;
    } else {
      this.isNumberExists = false;
      this.isNumberNotExists = true;
    }
  }

  /**
   * mobilenumber keypress event restrict number greater than 20 and restrict text
   */
  onMobileNumber(e: any, limitNumber: any) {
    const limit = limitNumber;
    let charCode = e.which ? e.which : e.keyCode;
    if (
      e.target.value.length === limit ||
      (charCode > 31 && (charCode < 48 || charCode > 57))
    ) {
      e.preventDefault();
    }
  }

  /**
   * print this page event
   */
  exportPdf() {
    this.loadingReport = true;
    let fileName = this.translate.instant('BUSINESS_DETAIL');
    let filetype = 'pdf';
    let type = 1;
    let position = 0;
    let cultureInfo = sessionStorage.getItem('languageMode')!;
    let ReportJson = this.route.parent!.snapshot.data['detail'];
    ReportJson.EmployeeCount = this.businessDetailForm.value.employeeCount;
    this.subscriptions.push(
      this.businessService
        .reportGenerate(
          +this.route.snapshot.parent.params['bid'],
          type,
          cultureInfo,
          ReportJson,
          BaseServices.ApplicationId
        )
        .subscribe(
          (blob: any) => {
            this.loadingReport = false;
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${fileName}.${filetype.toLowerCase()}`;
            link.click();
          },
          (error: any) => {
            this.loadingReport = false;
            alert('Export not downloaded');
          }
        )
    );
  }
  /**
   * print all page event
   */
  exportallPdf() {
    this.loadingReport = true;
    let fileName = this.translate.instant('BUSINESS_DETAIL');
    let filetype = 'pdf';
    let type = 3;
    let position = 0;
    let cultureInfo = sessionStorage.getItem('languageMode')!;
    let ReportJson = this.route.parent!.snapshot.data['detail'];
    ReportJson.EmployeeCount = this.businessDetailForm.value.employeeCount;
    ReportJson.BusinessId = +this.route.snapshot.parent.params['bid'];
    this.subscriptions.push(
      this.businessService
        .reportGenerate(
          +this.route.snapshot.parent.params['bid'],
          type,
          cultureInfo,
          ReportJson,
          BaseServices.ApplicationId
        )
        .subscribe(
          (blob: any) => {
            this.loadingReport = false;
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${fileName}.${filetype.toLowerCase()}`;
            link.click();
          },
          (error: any) => {
            this.loadingReport = false;
            alert('Export not downloaded');
          }
        )
    );
  }
  /**
   * popup hide
   */
  informationHide() {
    this.isAleadyUse = false;
  }

  /**
   * Clear pop up controls
   */
  onBeforeCategoryDialogHide() {
    this.categoryPopup.reset();
    this.categoryId = 0;
    this.category.Id = 0;
    this.categoryTable.reset();
  }

  flattenFind(data: any, key: any) {
    let checkFeature = <Feature>_(data)
      .thru(parent => {
        return _.union(parent, _.map(parent, 'children'));
      })
      .flatten()
      .find({ id: +key });
    if (checkFeature !== undefined) {
      return checkFeature.checked;
    } else {
      return false;
    }
  }

  bindDefaultLicenseAgreementDetails(bId: any, isNavigate: any) {
    this.licenseAgreementDetail = new BusinessAgreement();
    this.licenseAgreementDetail.LicenseCategoryId = 7;
    this.licenseAgreementDetail.PurchaseDate = HelperService.getCurrentData();
    this.licenseAgreementDetail.ApplicationId = BaseServices.ApplicationId;
    this.licenseAgreementDetail.BusinessId = bId;
    this.licenseAgreementDetail.Status = '1';
    this.licenseAgreementDetail.PricePerMonth = 0;
    this.subscriptions.push(
      this.businessagrremnt
        .add(this.licenseAgreementDetail)
        .subscribe(result => {
          if (result) {
            if (isNavigate) {
              setTimeout(() => {
                this.loading = false;
                this.router.navigate([`./../../edit/${bId}/features`], {
                  relativeTo: this.route
                });
              }, 1000);
            } else {
              this.loading = false;
            }
          }
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}
