import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpHeaders, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/_services/authentication.service';
@Injectable()
export class Interceptor implements HttpInterceptor {


    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let token: any;
        let session = JSON.parse(sessionStorage.getItem('session')!);
        if (session !== null && session.AccessToken) {
            token = session.AccessToken;
            const tokenParts = token.split('.');
            tokenParts[1] = tokenParts[1].substring(0, 5) + "W" + tokenParts[1].substring(5);
            // token = tokenParts.join('.'); commented for token issue fix
        }
        const httpRequest = req.clone({
            headers: new HttpHeaders({
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + token
            })
        });
        if (token) {
            return next.handle(httpRequest);
        } else {
            return next.handle(httpRequest);
        }
    }
}
