import { BaseServices } from './../../kuba/kuba.services';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from 'src/app/_services';
@Injectable()
export class KubaArticleViewerService {
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) {}
  // Generate pdf report for article
  generateArticleFile(
    reportData: any,
    businessId: number,
    cultureInfo: string,
    portalId: number,
    projectId: number
  ) {
    let dynamicType = 'application/pdf';
    let myData = JSON.stringify({ data: reportData });
    let crfToken = this.authenticationService.getCsrfToken();
    let url = `${environment.BASE_URL}/export-article/${businessId}/${cultureInfo}/${portalId}/${projectId}`;
    return Observable.create((observer: any) => {
      let xhr = new XMLHttpRequest();
      xhr.open('POST', url, true);
      xhr.withCredentials = true;
      let type = 'Bearer';
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('Logo-Id', 'ab064fd4-9b4a-4e70-972d-dafdaceb2aba');
      xhr.setRequestHeader('X-XSRF-TOKEN', crfToken);
      xhr.responseType = 'blob';
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            let contentType = dynamicType;
            let blob = new Blob([xhr.response], { type: contentType });
            observer.next(blob);
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };
      xhr.send(myData);
    });
  }

  getCKEditorImageFolderList(
    applicationId: number,
    portalId: number,
    businessId: number
  ) {
    return this.http
      .get(
        environment.BASE_URL +
          '/manual/0/article/ck-editor/getfolder/' +
          applicationId +
          '/' +
          portalId +
          '/' +
          businessId
      )
      .pipe(map(result => result));
  }

  SaveCKEditorImageFolder(folderDetails: any) {
    return this.http
      .post(
        environment.BASE_URL + '/manual/0/article/ck-editor/savefolder',
        folderDetails
      )
      .pipe(map(result => result));
  }

  DeleteCKEditorImageFolder(folderId: number, userId: number) {
    return this.http
      .delete(
        environment.BASE_URL +
          '/manual/0/article/ck-editor/deletefolder/' +
          folderId +
          '/' +
          userId
      )
      .pipe(map(result => result));
  }

  getCKEditorImageList(
    applicationId: number,
    businessId: number,
    portalId: number,
    folderId: number
  ) {
    return this.http
      .get(
        environment.BASE_URL +
          '/manual/0/article/ck-editor/getfile/' +
          applicationId +
          '/' +
          businessId +
          '/' +
          portalId +
          '/' +
          folderId
      )
      .pipe(map(result => result));
  }

  RenameCKEditorImage(fileDetails: any) {
    return this.http
      .put(
        environment.BASE_URL + '/manual/0/article/ck-editor/renameImageFile',
        fileDetails
      )
      .pipe(map(result => result));
  }

  DeleteCKEditorImage(fileDetails: any) {
    return this.http
      .put(
        environment.BASE_URL + '/manual/0/article/ck-editor/deleteImageFile',
        fileDetails
      )
      .pipe(map(result => result));
  }

  SaveCKEditorImage(fileDetails: any) {
    return this.http
      .post(
        environment.BASE_URL + '/manual/0/article/ck-editor/saveImage',
        fileDetails
      )
      .pipe(map(result => result));
  }
}
