import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  Input,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { IMyDateModel, IMyDpOptions } from 'mydatepicker';
import { TaskService } from 'src/app/kuba/task-manager/services/task.services';
import { ConfirmationService } from 'primeng/api';
import { ToasterComponent } from './../../_directives/toaster.component';
import { BaseServices } from './../kuba.services';
import { HelperService } from 'src/app/_services/helper.service';
import { TimetableServices } from './services/timetable.service';
import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { TypeOfWork } from './models/timetable';
import { Rights, FeatureKey } from './../../_models/feature';
import { DateSetting } from './../../_helpers/date-setting';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
@Component({
  selector: 'timetable-form',
  templateUrl: 'timetable-form.component.html',
})
export class TimetableFormComponent
  extends DateSetting
  implements OnInit, OnChanges
{
  //#region variables
  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  @Input() TimetableForm: FormGroup;
  @Input() readOnly: boolean = false;

  workingModule: any;
  TypeofWorkPopup: FormGroup;
  isStatusDisable = true;
  projectDropDown: any[];
  parentKey: any;
  isEmployee: any;
  employeeId: any = this.route.snapshot.params['eid'];
  editTypes: any[];
  typeOfWorkId: number;
  featureId: any;
  StartDate: Date;
  EndDate: Date;
  StartTime: Date;
  EndTime: Date;
  BreakTime: Date;
  addType = false;
  isHidden = false;
  employeeDropDown: SelectItem[];
  typeOfWorkDropDown: SelectItem[];
  approvalPersons: SelectItem[];
  parentId: any;
  hdnProject = false;
  hdnApprovalUser = false;
  viewType = false;
  workTypeId: number;
  existsAlerttext: string;
  isNameExists: boolean;
  isEmployeeName = true;
  isParentEmployee = false;
  // enabled end date for all the modules -- suggested by sathish
  hdnEndDate = true;
  myPageParent: string;
  isEmployeeParent: string;
  isReadOnly = false;
  isProject = false;
  isHideButton = true;
  loading = false;
  readonly = false;
  isSubContractorAndContacts = false;
  isNotSubContractorAndContacts = false;
  name: string;
  startTime: Date;
  endTime: Date;
  breakTime: Date;
  ApprovalUserId: any = [];
  TimeTableApproverId: any = [];
  isEmployeeTimetable: boolean = false;
  /**
   * Date picker configuration option
   */
  public override defaultDateOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
    editableDateField: false,
    openSelectorOnInputClick: true,
    showClearDateBtn: false, // to avoid selecting invalid date
    firstDayOfWeek: 'su',
    satHighlight: true,
    height: '32px',
    selectionTxtFontSize: '14px',
    todayBtnTxt: this.translate.instant('TODAY'),
    dayLabels: {
      su: this.translate.instant('SUN'),
      mo: this.translate.instant('MON'),
      tu: this.translate.instant('TUE'),
      we: this.translate.instant('WED'),
      th: this.translate.instant('THU'),
      fr: this.translate.instant('FRI'),
      sa: this.translate.instant('SAT'),
    },
    monthLabels: {
      1: this.translate.instant('JANUARY'),
      2: this.translate.instant('FEBRUARY'),
      3: this.translate.instant('MARCH'),
      4: this.translate.instant('APRIL'),
      5: this.translate.instant('MAY'),
      6: this.translate.instant('JUNE'),
      7: this.translate.instant('JULY'),
      8: this.translate.instant('AUGUST'),
      9: this.translate.instant('SEPTEMBER'),
      10: this.translate.instant('OCTOBER'),
      11: this.translate.instant('NOVEMBER'),
      12: this.translate.instant('DECEMBER'),
    },
  };

  /**
   * date picker configuration option
   */
  public startDateOptions: IMyDpOptions = this.defaultDateOptions;
  public endDateOptions: IMyDpOptions = this.startDateOptions;
  private subscriptions: Subscription[] = [];

  // #endregion

  //#region constructor

  /**
   * constructor
   * @param timetableService {TimetableServices}
   * @param _fb {FormBuilder}
   * @param route {ActivatedRoute}
   */
  constructor(
    private timetableService: TimetableServices,
    private _fb: FormBuilder,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private router: Router,
    private taskService: TaskService
  ) {
    super();
    this.parentId = route.snapshot.parent.parent.data['id'];
    this.parentKey = route.snapshot.parent.parent.data['parent'];
    this.isEmployee = <boolean>route.snapshot.data['employee'];
    this.myPageParent = route.snapshot.parent.parent.parent.data['parent'];
    if (+BaseServices.roleId === 4 || +BaseServices.roleId === 5) {
      this.isHideButton = false;
    }
  }

  ngOnChanges(changes: SimpleChanges) {}

  // #endregion

  //#region page-events

  /**
   * init page
   */
  ngOnInit() {
    let workingModule = JSON.parse(sessionStorage.getItem('workingModule')).id;
    if (workingModule != null) {
      this.featureId = workingModule;
    }
    document.querySelector('body').classList.remove('opened');
    this.isEmployeeParent = this.route.snapshot.data['parent'];
    this.onTimetableWorkType();
    if (this.myPageParent) {
      this.isEmployeeName = false;
      this.isParentEmployee = true;
    }
    const currentUrl = this.router.url;
    if (currentUrl.includes('/employee/timetable')) {
      this.isEmployeeTimetable = true;
    } else {
      this.isEmployeeTimetable = false;
    }
    this.subscriptions.push(
      this.timetableService.getReadOnly$.subscribe((status) => {
        this.isReadOnly = status;
      })
    );
    this.subscriptions.push(
      this.timetableService.getParent$.subscribe((parent) => {
        this.isParentEmployee = parent ? parent : null;
      })
    );
    this.parentId = this.route.snapshot.parent.parent.params['id'];
    if (this.TimetableForm.value.Id > 0) {
      this.StartDate = this.TimetableForm.value.StartDate;
      let startdate: Date = null;
      this.endDateOptions = HelperService.setDateRange(
        this.endDateOptions,
        startdate,
        'startDate'
      );
      this.EndDate = this.TimetableForm.value.EndDate;
      let enddate: Date = null;
      this.startDateOptions = HelperService.setDateRange(
        this.startDateOptions,
        enddate,
        'endDate'
      );
      this.StartTime = this.TimetableForm.value.StartDate
        ? new Date(
            `${this.TimetableForm.value.StartDate.formatted} ${this.TimetableForm.value.StartTime}`
          )
        : null;
      this.EndTime = this.TimetableForm.value.StartDate
        ? new Date(
            `${this.TimetableForm.value.StartDate.formatted} ${this.TimetableForm.value.EndTime}`
          )
        : null;
      this.TimetableForm.value.ApprovalUserId.forEach((x) => {
        this.ApprovalUserId.push(x);
      });
      this.TimetableForm.value.TimeTableApproverId.forEach((x) => {
        this.TimeTableApproverId.push(x);
      });

      this.TypeofWorkPopup = this._fb.group({
        typeofWork: [
          '',
          [Validators.required, ValidationService.noWhitespaceValidator],
        ],
      });
    } else {
      if (BaseServices.roleId === '5') {
        this.TimetableForm.patchValue({
          EmployeeId: BaseServices.UserFeatureKey,
        });
        this.readonly = true;
      }
      this.StartDate = this.TimetableForm.value.StartDate
        ? new Date(this.TimetableForm.value.StartDate.formatted)
        : null;
      let startdate: Date = HelperService.formatDate(
        this.TimetableForm.value.StartDate
      )
        ? new Date(
            HelperService.formatDate(
              this.TimetableForm.value.StartDate.formatted
            )
          )
        : null;
      this.endDateOptions = HelperService.setDateRange(
        this.endDateOptions,
        startdate,
        'startDate'
      );
      this.EndDate = this.TimetableForm.value.EndDate
        ? new Date(this.TimetableForm.value.EndDate.formatted)
        : null;
      let enddate: Date = HelperService.formatDate(
        this.TimetableForm.value.EndDate
      )
        ? new Date(
            HelperService.formatDate(this.TimetableForm.value.EndDate.formatted)
          )
        : null;
      this.startDateOptions = HelperService.setDateRange(
        this.startDateOptions,
        enddate,
        'endDate'
      );
      this.StartTime = new Date(
        `1970-01-01 ${this.TimetableForm.value.StartTime}`
      );
      this.EndTime = new Date(`1970-01-01 ${this.TimetableForm.value.EndTime}`);
      this.TypeofWorkPopup = this._fb.group({
        typeofWork: [
          '',
          [Validators.required, ValidationService.noWhitespaceValidator],
        ],
      });
    }
    this.bindDropdowns();
    if (this.isEmployeeParent === 'EMPLOYEE') {
      this.isParentEmployee = true;
      this.isEmployeeName = false;
    }
    // enabled end date for all the modules -- suggested by sathish
    this.hiddenFieldHandler();

    // Project Name hide/show based on Project Module existence
    let leftNav = JSON.parse(sessionStorage.getItem('leftNav'));
    HelperService.filterTree(
      'Project',
      leftNav.filter((x) => {
        if (x.name === 'Project') {
          if (x.children[3].checked === true) {
            this.isProject = true;
          }
        }
      }),
      'IsProject'
    );

    if (BaseServices.FeatureId === FeatureKey.SUB_CONTRACTOR) {
      this.isSubContractorAndContacts = true;
      this.name = BaseServices.Name;
    } else if (BaseServices.FeatureId === FeatureKey.CONTRACTOR_CONTACT) {
      this.isSubContractorAndContacts = true;
      this.name = BaseServices.Name;
    } else if (BaseServices.FeatureId === FeatureKey.CLIENT) {
      this.isSubContractorAndContacts = true;
      this.name = BaseServices.Name;
    } else if (BaseServices.FeatureId == FeatureKey.CLIENT_CONTACT) {
      this.isSubContractorAndContacts = true;
      this.name = BaseServices.Name;
    } else {
      this.isNotSubContractorAndContacts = true;
    }
    this.startTime = new Date('1970-01-01 8:00:00');
    this.endTime = new Date('1970-01-01 16:00:00');
    this.breakTime = new Date('1970-01-01 0:30:00');
    this.workingModule = JSON.parse(sessionStorage.getItem('workingModule'));
  }

  // #endregion

  //#region control-events

  /**
   * Editing TypeOfWork list
   * @param timetableType {any}
   */
  editType(timetableType: any) {
    this.typeOfWorkId = timetableType.Id;
    this.TypeofWorkPopup = this._fb.group({
      typeofWork: timetableType.Title,
    });
  }
  /**
   * save Type of Work
   */
  saveType() {
    this.loading = true;
    let timetableType = new TypeOfWork();
    timetableType.Id = this.typeOfWorkId ? this.typeOfWorkId : 0;
    timetableType.Title = this.TypeofWorkPopup.value.typeofWork;
    timetableType.BusinessId = BaseServices.BusinessId;
    timetableType.Status = '1';
    if (timetableType.Id > 0) {
      this.subscriptions.push(
        this.timetableService
          .updateType(timetableType, this.parentKey)
          .subscribe((typeEdit: any) => {
            if (typeEdit) {
              this.bindTypeOfWorkDropdown();
              this.TimetableForm.get('TypeOfWorkId').patchValue(typeEdit.Id);
              this.toasterComponent.callToast();
              this.loading = false;
              this.typeOfWorkId = 0;
            } else {
              this.loading = false;
              this.existsAlerttext = this.translate.instant(`TOPIC_EXIST`);
              this.isNameExists = true;
              setTimeout(() => {
                this.isNameExists = false;
              }, 3000);
            }
          })
      );
    } else {
      this.subscriptions.push(
        this.timetableService
          .createType(timetableType, this.parentKey)
          .subscribe((addedType: any) => {
            if (addedType) {
              this.loading = false;
              this.bindTypeOfWorkDropdown();
              this.TimetableForm.get('TypeOfWorkId').patchValue(addedType.Id);
              this.toasterComponent.callToast();
              this.addType = false;
            } else {
              this.loading = false;
              this.existsAlerttext = this.translate.instant(`TOPIC_EXIST`);
              this.isNameExists = true;
              setTimeout(() => {
                this.isNameExists = false;
              }, 3000);
            }
          })
      );
    }
  }
  /**
   * bind type of work after event trigger
   * @param typeofWork {any}
   */
  bindTypeofWork(typeofWork: any) {
    if (typeofWork) {
      this.bindTypeOfWorkDropdown();
      this.TimetableForm.get('TypeOfWorkId').patchValue(typeofWork.Id);
      this.toasterComponent.callToast();
      this.addType = false;
    } else {
      this.existsAlerttext = this.translate.instant(`TOPIC_EXIST`);
      this.isNameExists = true;
      setTimeout(() => {
        this.isNameExists = false;
      }, 3000);
    }
  }

  clear() {
    this.typeOfWorkId = 0;
    this.TypeofWorkPopup = this._fb.group({
      typeofWork: [''],
    });
  }
  /**
   * delete TypeOfWork based on Id
   * @param timetableType {number}
   */
  deleteType(timetableTypeId: number) {
    this.confirmationService.confirm({
      message: this.translate.instant('ARE_SURE_TO_PERFORM_ACTION'),
      accept: () => {
        let deleteTypeId = timetableTypeId;
        this.subscriptions.push(
          this.timetableService
            .deleteType(deleteTypeId)
            .subscribe((typeDelete) => {
              if (typeDelete) {
                this.bindTypeOfWorkDropdown();
                this.toasterComponent.callToastDlt();
              }
            })
        );
      },
    });
  }
  /**
   * TimeTable Start Date Changed
   * @param date  {IMyDateModel}
   */
  onNewTimeTableStartDateChanged(event: IMyDateModel) {
    this.StartDate = event.jsdate;
    let date: Date = event.jsdate ? new Date(event.jsdate.getTime()) : null;
    this.endDateOptions = this.setDateInput(
      this.endDateOptions,
      event,
      'startDate'
    );
    if (!date) {
      let copyConfig: IMyDpOptions = HelperService.getCopyOfDatePickerOptions(
        this.endDateOptions
      );
      this.startDateOptions = copyConfig;
    }
    if (
      event.jsdate &&
      this.TimetableForm.value.EndDate &&
      this.TimetableForm.value.StartDate &&
      this.TimetableForm.value.StartTime &&
      this.TimetableForm.value.EndTime
    ) {
      this.setCalculatedHours();
    } else if (
      event.jsdate &&
      this.TimetableForm.value.EndDate &&
      this.TimetableForm.value.StartTime &&
      this.TimetableForm.value.EndTime
    ) {
      this.setCalculatedHours();
    }
  }
  /**
   * timeTable EndDate Changed
   * @param date {IMyDateModel}
   */
  onNewTimeTableEndDateChanged(event: IMyDateModel) {
    this.EndDate = event.jsdate;
    let date: Date = event.jsdate ? new Date(event.jsdate.getTime()) : null;
    this.startDateOptions = this.setDateInput(
      this.startDateOptions,
      event,
      'endDate'
    );
    if (!date) {
      let copyConfig: IMyDpOptions = HelperService.getCopyOfDatePickerOptions(
        this.startDateOptions
      );
      this.startDateOptions = copyConfig;
    }
    if (
      event.jsdate &&
      this.TimetableForm.value.EndDate &&
      this.TimetableForm.value.StartDate &&
      this.TimetableForm.value.StartTime &&
      this.TimetableForm.value.EndTime
    ) {
      if (
        this.TimetableForm.value.Id !== null &&
        this.TimetableForm.value.Id > 0
      ) {
        this.StartDate = this.TimetableForm.value.StartDate.jsDate;
        this.StartTime = new Date(
          `1970-01-01 ${this.TimetableForm.value.StartTime}`
        );
        if (Number.isNaN(this.EndTime.valueOf())) {
          this.EndTime = new Date(
            `1970-01-01 ${this.TimetableForm.value.EndTime}`
          );
        }
      }
      this.setCalculatedHours();
    } else if (
      event.jsdate &&
      this.TimetableForm.value.StartDate &&
      this.TimetableForm.value.StartTime &&
      this.TimetableForm.value.EndTime
    ) {
      this.setCalculatedHours();
    }
  }

  /**
   * start Time Changed
   * @param date{any}
   */
  startTimeChanged(date: any) {
    this.StartTime = date;
    if (
      date &&
      this.TimetableForm.value.EndDate &&
      this.TimetableForm.value.StartDate &&
      this.TimetableForm.value.EndTime
    ) {
      this.setCalculatedHours();
    }
  }
  /**
   * end Time Changed
   * @param date {any}
   */
  endTimeChanged(date: any) {
    let time: any;
    this.EndTime = date;
    if (
      this.TimetableForm.value.Id !== null &&
      this.TimetableForm.value.Id > 0
    ) {
      this.EndDate = this.TimetableForm.value.EndDate.jsdate;
      this.StartDate = this.TimetableForm.value.StartDate.jsdate;
      this.StartTime = new Date(
        `1970-01-01 ${this.TimetableForm.value.StartTime}`
      );
    }
    if (
      date &&
      this.TimetableForm.value.EndDate &&
      this.TimetableForm.value.StartDate &&
      this.TimetableForm.value.StartTime
    ) {
      this.setCalculatedHours();
    }
  }
  /**
   * end Time Changed
   * @param date {any}
   */
  breakTimeChanged(breakTime: any, hoursSpent: any) {
    let bhour: any = new Date(breakTime).getHours();
    let bmin: any = new Date(breakTime).getMinutes();
    let btime = `${bhour}:${bmin}`;
    let htime = hoursSpent.value.split(':');
    let htimemints = parseInt(htime[0], NaN) * 60 + parseInt(htime[1], NaN);
    let btimemints = parseInt(bhour, NaN) * 60 + parseInt(bmin, NaN);
  }
  /**
   * To clear type of work contrils
   */
  onBeforeTypeofWorkDialogHide() {
    this.TypeofWorkPopup.reset();
    this.viewType = false;
    this.typeOfWorkId = 0;
  }

  // #endregion

  //#region methods

  /**
   * This method fetches all information needed for the different dropdowns within the "new timetable dropdown"
   */
  bindDropdowns() {
    this.isHidden = true;
    this.employeeDropDown = [];
    this.projectDropDown = [];
    this.typeOfWorkDropDown = [];
    this.editTypes = [];
    this.approvalPersons = [];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.projectDropDown = [];
        this.projectDropDown.push({ label: val.SELECT, value: null });
        let languageMode = this.translate.currentLang;
        this.subscriptions.push(
          this.timetableService
            .getMyPageOrigin(BaseServices.BusinessId, languageMode)
            .subscribe((result: any) => {
              if (result) {
                result.forEach((projectNames: any) => {
                  this.projectDropDown.push({
                    label: projectNames.label,
                    value: projectNames.value,
                  });
                });
              }
            })
        );
      })
    );

    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.employeeDropDown = [];
        this.employeeDropDown.push({ label: val.SELECT, value: null });
        this.subscriptions.push(
          this.taskService
            .getAssignedUsers(
              BaseServices.BusinessId,
              this.featureId,
              this.parentId,
              BaseServices.ApplicationId
            )
            .subscribe((users: any) => {
              users.forEach((element: any) => {
                this.employeeDropDown.push({
                  label: element.Name,
                  value: element.EmployeeId,
                });
              });
            })
        );
      })
    );
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.typeOfWorkDropDown = [];
        this.typeOfWorkDropDown.push({ label: val.SELECT, value: null });
      })
    );
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.approvalPersons = [];
        let approvalPerson = this.route.snapshot.data['approvalPerson'];
        if (approvalPerson) {
          approvalPerson.forEach((approvaluser: any) => {
            let rights = HelperService.validateJson(approvaluser.Rights);
            if (rights) {
              let hasAccess = HelperService.ApprovalPersonRightsCheck(
                rights,
                Rights.APPROVE_TIMETABLE
              );
              if (hasAccess) {
                this.approvalPersons.push({
                  label: approvaluser.Name,
                  value: approvaluser.Id,
                });
              }
            }
          });
        }
      })
    );

    this.typeOfWorkDropDown = [];
    this.editTypes = [];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.typeOfWorkDropDown = [];
        this.typeOfWorkDropDown.push({ label: val.SELECT, value: null });
        let typeOfWork = this.route.snapshot.data['typeOfWork'];
        if (typeOfWork) {
          typeOfWork.forEach((typeOfWorks: any) => {
            this.typeOfWorkDropDown.push({
              label: typeOfWorks.Title,
              value: typeOfWorks.Id,
            });
            this.editTypes.push({
              Title: typeOfWorks.Title,
              Id: typeOfWorks.Id,
            });
          });
        }
      })
    );
  }
  /**
   * bind TypeOfWork dropdown
   */
  bindTypeOfWorkDropdown() {
    this.subscriptions.push(
      this.timetableService
        .getTypeofworkbyBusinessId(BaseServices.BusinessId)
        .subscribe((workType: any) => {
          if (workType) {
            this.typeOfWorkDropDown = [];
            this.typeOfWorkDropDown.push({
              label: 'Select Type of Work',
              value: null,
            });
            workType.forEach((typeOfWorkList: any) => {
              this.typeOfWorkDropDown.push({
                label: typeOfWorkList.Title,
                value: typeOfWorkList.Id,
              });
            });
            this.editTypes = workType;
          }
        })
    );
  }
  /**
   * method for setting date range
   * @param dateConfig {IMyDpOptions}
   * @param event {IMyDateModel}
   * @param type {string}
   */
  setDateInput(
    dateConfig: IMyDpOptions,
    event: IMyDateModel,
    type: string
  ): IMyDpOptions {
    let date: Date = event.jsdate ? new Date(event.jsdate.getTime()) : null;
    let dateOptions = HelperService.setDateRange(dateConfig, date, type);
    return dateOptions;
  }
  /**
   * set Calculated Hours based on module
   */
  setCalculatedHours() {
    this.TimetableForm.get('HoursSpent').patchValue(null);
    this.TimetableForm.get('BreakTime').patchValue(null);
    let spentHours;
    if (
      this.parentKey === 'IC' ||
      this.parentKey === 'OS' ||
      this.parentKey === 'QS' ||
      this.parentKey === 'VEHICLE' ||
      this.parentKey === 'FDV'
    ) {
      if (this.StartDate && this.StartTime && this.EndTime && this.EndDate) {
        spentHours = HelperService.calculateHourSpent(
          this.StartDate,
          this.EndDate,
          this.StartTime,
          this.EndTime
        );
        this.TimetableForm.get('HoursSpent').patchValue(spentHours);
        this.TimetableForm.get('ActualSpend').patchValue(spentHours);
      }
    } else {
      if (this.StartDate && this.EndDate && this.StartTime && this.EndTime) {
        spentHours = HelperService.calculateHourSpent(
          this.StartDate,
          this.EndDate,
          this.StartTime,
          this.EndTime
        );
        this.TimetableForm.get('HoursSpent').patchValue(spentHours);
        this.TimetableForm.get('ActualSpend').patchValue(spentHours);
      }
    }
  }
  /**
   * to handler hidden Field
   */
  hiddenFieldHandler() {
    // as per disussion with tester enabled both start and end time for all modules
    switch (this.parentKey) {
      case 'IC':
      case 'FDV':
      case 'VEHICLE':
      case 'OS':
      case 'QS':
        this.hdnEndDate = true;
        this.hdnApprovalUser = true;
        // this.TimetableForm.get('TimeTableApproverId').setValidators(
        //     Validators.required
        // );
        break;
      case 'FS':
      case 'PROJECT':
      case 'EMPLOYEE':
      case 'KUBA_CONTROL':
        this.hdnEndDate = true;
        this.hdnApprovalUser = false;
        this.isEmployeeName = true;
        this.TimetableForm.get('EndDate').setValidators(Validators.required);
        // this.TimetableForm.get('TimeTableApproverId').setValidators(
        //     Validators.required
        // );
        // this.TimetableForm.get('ApprovalUserId').updateValueAndValidity();
        // this.TimetableForm.get('TimeTableApproverId').updateValueAndValidity();
        break;
      case 'TIMETABLE':
        this.hdnEndDate = true;
        this.TimetableForm.get('EndDate').setValidators(Validators.required);
        this.TimetableForm.get('EndDate').updateValueAndValidity();
        this.hdnApprovalUser = true;
        break;
      default:
        break;
    }
  }

  /**
   * disable new work type based upon the rights
   */
  onTimetableWorkType() {
    let userRightsId = Rights.ADD_NEW_EMPLOYEE;
    let role = BaseServices.UserRole;
    if (role !== 'Editor') {
      this.isHideButton = false;
    }
    if (role === 'User') {
      this.isHideButton = this.hideTypeOfcase(this.parentKey);
    }
  }

  /**
   * get current date
   */
  getCurrentDate() {
    let dateFormat = new Date();
    return {
      year: dateFormat.getFullYear(),
      month: dateFormat.getMonth() + 1,
      day: dateFormat.getDate() - 1,
    };
  }
  onApprovalNoticeChanged(e: any) {
    if (e.value) {
    }
  }
  // #endregion

  /**
   *
   * @param featureName
   */
  hideTypeOfcase(featureName: string): boolean {
    if (featureName === 'PROJECT') {
      return this.isUserRights(Rights.ADMINISTRATE_PROJECTS);
    } else if (featureName === 'FDV') {
      return this.isUserRights(Rights.ADMINISTRATE_FDV);
    } else if (featureName === 'IC') {
      return this.isUserRights(Rights.ADMINISTRATE_INTERNAL_CONTROL);
    } else if (featureName === 'OS') {
      return this.isUserRights(Rights.ADMINISTRATE_OTHER_SYSTEMS);
    } else if (featureName === 'QS') {
      return this.isUserRights(Rights.ADMINISTRATE_QUALITY_SYSTEM);
    } else if (featureName === 'VEHICLE') {
      return false;
    } else if (featureName === 'FS') {
      return false;
    } else if (featureName === 'KUNDE_EL') {
      return this.isUserRights(Rights.IK_KUNDE_EL_RIGHTS);
    } else if (featureName === 'FRAMEWORK') {
      return this.isUserRights(Rights.FRAMEWORK_AGREEMENT_RIGHTS);
    } else if (featureName === 'SA') {
      return this.isUserRights(Rights.SERVICE_AGREEMENT_RIGHTS);
    } else if (featureName == undefined) {
      return this.isUserRights(Rights.APPROVE_TIMETABLE);
    }
    return true;
  }

  isUserRights(rightsId: number): boolean {
    return BaseServices.checkUserRights(+rightsId, BaseServices.UserRole);
  }

  /**
   * Validates if a string is a valid JSON. Also has handling for invalid JSON-strings
   * @param input String validate for JSON
   * @returns JSON-object if string is valid JSON. Null if invalid JSON
   */
  validateJson(input: string): JSON | null {
    let result: JSON;
    try {
      result = JSON.parse(input);
      return result;
    } catch {
      return null;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}
