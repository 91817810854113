import { BaseServices } from 'src/app/kuba/kuba.services';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OnInit, Component, ViewChild, ElementRef, Input } from '@angular/core';
import { ArticleDataEntityModel } from 'src/app/shared/ecpl-article-viewer/interfaces';
import * as d3 from 'd3-selection';
import * as d3Scale from 'd3-scale';
import * as d3Shape from 'd3-shape';
import * as d3Axis from 'd3-axis';
import * as d3Array from 'd3-array';
import { DeviationList } from 'src/app/kuba/deviation/models';
import { DeviationServices } from 'src/app/kuba/deviation/services/deviation.service';
import { FormBuilder } from '@angular/forms';
import { ProjectServices } from 'src/app/kuba/projects/services/project.service';
import { FDVServices } from 'src/app/kuba/FDV/services/fdv.service';
import { ElectroService } from '../../services/electro.services';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { HttpClient } from '@angular/common/http';
import { InspectionChartModel } from '../../models/electroinspection';
import { HelperService } from 'src/app/_services/helper.service';
import { Rights } from 'src/app/_models';
import { Table } from 'primeng/table';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
export interface Margin {
    top: number;
    right: number;
    bottom: number;
    left: number;
}

@Component({
    selector: 'inspection-chart-overview',
    templateUrl: 'inspection-chart-overview.component.html'
})


export class InspectionChartOverview implements OnInit {

    @ViewChild('inspectionTable',{static: false}) inspectionTable: Table;
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    inspectionList: any;
    statuses: SelectItem[];
    parentKey: number;
    title = 'Inspection Overview and Planning';
    year: number;
    timeRemaining: any;
    overdeadline: any;
    noticePeriod: number;
    notifyBeforeDeadline: any;
    Completed: number;
    inspection: number;
    date: Date;
    private margin: Margin;
    private width: number;
    private height: number;
    private svg: any;     // TODO replace all `any` by the right type
    private x: any;
    private y: any;
    private z: any;
    private g: any;
    data: any;
    rowsPerPageOptions: number[];
    isGuestHideButton = true;
    enableLeftArrow: boolean;
    enableRightArrow: boolean;
    startIndex: number;
    windowData = [];
    resperson: SelectItem[];
    openInspRows = [];
    private subscriptions: Subscription[] = [];
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private translate: TranslateService,
        private confirmationService: ConfirmationService,
        private electroService: ElectroService,
        private http: HttpClient
    ) {
        if (+BaseServices.roleId === 4) {
            this.isGuestHideButton = false;
        }
        let currentUserRole = BaseServices.UserRole;
        let userRightsId = Rights.SERVICE_LEADER_ELECTRO;
        if (+BaseServices.roleId === 5) {
            this.isGuestHideButton = BaseServices.checkUserRights(
                userRightsId,
                currentUserRole
            );
        }
    }

    ngOnInit() {
        this.inspectionList = this.route.snapshot.data['list'];
        this.bindDropdown();
        this.bindStatusIcon(this.inspectionList);
        setTimeout(() => {
            this.initSvg();
        }, 1000);
        this.data = this.route.snapshot.data['chart'];
        this.windowData = [];
        this.startIndex = 0;
        if (this.data.length > 10) {
            this.data.forEach(i => {
                if (i.IndexCurrentMonth) {
                    this.startIndex = this.data.indexOf(i);
                    let splicer = this.data;
                    // if data after cuurent month is less than 10 endindex is last index of data
                    let endIndex = this.data.length < this.startIndex + 10 ? this.data.length - 1 : this.startIndex + 10;
                    this.windowData = splicer.slice(this.startIndex, endIndex);
                }
            });
        }
        else {
            this.windowData = this.data;
        }
        if (this.windowData.length == 0) {
            this.startIndex = 0;
            this.windowData = this.data.slice(this.startIndex, 10);
        }
        this.enableLeftArrow = this.startIndex > 0;
        this.enableRightArrow = this.startIndex + this.windowData.length < this.data.length;
        setTimeout(() => {
            this.drawChart(this.windowData);
        }, 1000);
    }
    //#region Chart

    /**
     * Initialize svg design for the chart
     */
    private initSvg() {
        this.svg = d3.select('svg');
        this.margin = { top: 20, right: 20, bottom: 30, left: 40 };
        this.width = +this.svg.attr('width') - this.margin.left - this.margin.right;
        this.height = +this.svg.attr('height') - this.margin.top - this.margin.bottom;
        this.g = this.svg.append('g').attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');
        // constructing y-axis and z-axis(color of bar in stack)
        this.y = d3Scale.scaleLinear().rangeRound([this.height, 0]);
        this.z = d3Scale.scaleOrdinal().range(['#339933', '#4f49b2', '#f7941d', '#e34a31']);
    }

    /**
     * Draw chart.
     * @param data
     */
    private drawChart(data: any[]) {
        let keys = ["CompletedCount", "TimeremainingCount", "NoticePeriodCount", "OverDeadlineCount"];
        // constructing new scale band for x-axis, set scale values depending on data length.
        this.x = d3Scale.scaleBand().rangeRound([0, data.length * 60]);

        data = data.map(v => {
            v.total = keys.map(key => v[key]).reduce((a, b) => a + b, 0);
            return v;
        });

        //mapping data to axis
        this.x.domain(data.map((d: any) => d.MonthYear));
        this.y.domain([0, d3Array.max(data, d => d.total) + 5]);
        this.z.domain(keys);

        this.drawChartGrid(data);
        this.addLegend(keys);
        let tooltip = this.addTooltip(keys);

        //append graph data to graph element
        this.g.append('g').selectAll('g')
            .data(d3Shape.stack().keys(keys)(data))//populate graph with stack keys and values
            .enter().append('g').attr('fill', d => this.z(d.key))//fill color for data on key basis
            .selectAll('rect').data(d => d).enter().append('rect')
            .attr('x', d => this.x(d.data.MonthYear)).attr('y', d => this.y(d[1]))
            .attr('height', d => this.y(d[0]) - this.y(d[1]))//set height of each data in a stack bar
            .attr('width', 50)//set width of each bar
            .attr('transform', 'translate(5,0)')
            .on("mouseover", function () { tooltip.style("display", null); })
            .on("mouseout", function () { tooltip.style("display", "none"); })
            .on("mousemove", function (event, d) {
              var xPosition = d3.pointer(event)[0] + 40;
              var yPosition = d3.pointer(event)[1];
              // set tooltip position with respect to mouse position
              tooltip.attr("transform", "translate(" + xPosition + "," + yPosition + ")");
              tooltip.select("text").text(d[1] - d[0]);
            });
        //append x-axis and scale values.
        this.g.append('g')
            .attr('class', 'axis').attr('transform', 'translate(0,' + this.height + ')')
            .call(d3Axis.axisBottom(this.x))
            .attr('class', 'tick').attr('transform', 'translate(0,' + this.height + ')');

        let x = d3Array.max(data, d => d.total)

        //append y-axis and scale values.
        this.g.append('g').attr('class', 'axis').call(d3Axis.axisLeft(this.y));

    }

    /**
     * Draws gridlines for chart.
     * @param data
     */
    drawChartGrid(data: any) {

        // append Y gridlines and tranform the position of grid with respect to margin
        this.svg.append("g").attr("class", "grid").call(d3Axis.axisLeft(this.y)
            .tickSize(-data.length * 60))
            .attr('class', 'tick')
            .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')')
            .attr('opacity', 0.1);

        // append X gridlines and tranform the position of grid with respect to margin
        this.svg.append('g').attr('class', 'grid')
            .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.bottom + ')')
            .call(d3Axis.axisBottom(this.x).tickSize(-this.height))//define tick-line size
            .attr('class', 'tick')
            .attr('transform', 'translate(' + this.margin.left + ',' + (this.margin.top + this.height) + ')')
            .attr('opacity', 0.1);
    }

    /**
     * Adds legend for chart.
     * @param keys
     */
    addLegend(keys: any) {
        //append legend to graph.
        let legend = this.g.append('g')
            .attr('font-family', 'sans-serif').attr('font-size', 10).attr('text-anchor', 'end')
            .selectAll('g').data(keys.slice().reverse()).enter().append('g');

        // design legend rectangle
        legend.append('rect').attr('x', this.width - 20) // x-axis positioning for rectangle
            .attr('y', (d, i) => i * 20) // y-axis positioning for rectangle
            .attr('width', 19).attr('height', 19)
            .attr('fill', this.z);// fill colour for specific data

        //design legend text
        legend.append('text').attr('x', this.width - 25)// x-axis positioning
            .attr('y', (d, i) => i * 20 + 9.5)// y-axis positioning for each category 20=>spacing, 9.5 =>centre of rectange
            .text(d => this.translate.instant(d));

    }

    /**
     * Adds tooltip to svg data.
     * @param keys
     */
    addTooltip(keys: any) {

        let tooltip = this.svg.append('g')
            .attr('font-family', 'sans-serif').attr('font-size', 10).attr('text-anchor', 'end')
            .selectAll('g')
            .data(keys.slice().reverse()).enter().append('g').style("display", "none");

        // design legend rectangle
        tooltip.append('rect')
            .attr('width', 20).attr('height', 15)
            .attr('fill', "white").style("opacity", 0.2);

        //design legend text
        tooltip.append('text').attr("x", 15).attr("dy", "1.2em");

        return tooltip;
    }
    //#endregion

    /**
     * bind status dropdown
     */
    bindDropdown() {
        this.subscriptions.push(this.translate.stream('SELECT_INSPECTION_STATUS_DROP').subscribe(val => {
            this.statuses = [];
            this.statuses.push(
                { label: val.SELECT, value: null },
                { label: val.BOOK_INSPECTION, value: 13 },
                { label: val.INSPECTION_PLANNED, value: 14 },
                { label: val.INSPECTION_STARTED, value: 15 },
                { label: val.COMPLETED, value: 16 },
            );
        }));
        let users = this.route.snapshot.data['user'];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.resperson = [];
            this.resperson.push(
                { label: val.SELECT, value: null }
            );
            if (users) {
                users.forEach((followupPerson: any) => {
                    this.resperson.push({
                        label: followupPerson.Name,
                        value: followupPerson.Id
                    });
                });
            }
        }));
    }

    onResponsiblePersonChange(e: any, responseDropdown: any) {
        if (responseDropdown.selectedOption.value !== null) {
            this.inspectionTable.filter(
                responseDropdown.selectedOption.label,
                'ResponsibleUserName',
                'contains'
            );
        } else {
            this.inspectionTable.reset();
        }
    }
    /**
     *
     * bind status icon by status
     * @param inspection {any}
     */
    bindStatusIcon(inspection: any) {
        this.inspectionList = [];
        if (!(BaseServices.roleId == '3' || HelperService.ApprovalPersonRightsCheck(
            JSON.parse(BaseServices.getUserRights()), Rights.SERVICE_LEADER_ELECTRO))) {
            inspection = inspection.filter(x => (x.InspectionExecutionUsers ?
                (x.InspectionExecutionUsers.indexOf(BaseServices.UserId) > -1) : false) || (x.InspectionFollowupUsers ?
                    (x.InspectionFollowupUsers.indexOf(BaseServices.UserId) > -1) : false));
        }
        if (inspection) {
            inspection.forEach((inspectionInfo: any) => {
                let daysDiff = 0;
                let statusIcon = '';
                let currentdate = new Date();
                let deadLine = new Date(inspectionInfo.Deadline);
                if (inspectionInfo.Deadline != null) {
                    daysDiff = HelperService.getDaysDiff(currentdate, deadLine);
                }
                if (inspectionInfo.Status === 16) {
                    statusIcon = 'icon ic-sm icon-clear-deadline';
                } else if (inspectionInfo.Deadline) {
                    if (daysDiff > 3) {
                        statusIcon = 'icon ic-sm icon-far-deadline';
                    } else if (daysDiff > 0 && daysDiff <= 3) {
                        statusIcon = 'icon ic-sm icon-near-deadline';
                    } else {
                        statusIcon = 'icon ic-sm icon-over-deadline';
                    }
                } else {
                    statusIcon = 'icon ic-sm icon-over-deadline';
                }
                inspectionInfo.StatusIcon = statusIcon;
                this.inspectionList.push(inspectionInfo);
                this.rowsPerPageOptions = [10, 20, 50, 100];
                if (this.inspectionList.length > 100) {
                    this.rowsPerPageOptions.push(this.inspectionList.length);
                }
            });
        }

    }

    /**
     * status filter for datatable
     * @param e
     * @param statusDropdown
     */
    onStatusChange(e: any, statusDropdown: any) {
        if (e.value) {
            this.inspectionTable.filter(
                +statusDropdown.value,
                'Status',
                'equals'
            );
        } else {
            this.inspectionTable.reset();
        }
    }
    /**
     * confirmation for deleting an inspection.
     * @param id
     */
    confirm(id: number) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.electroService.deleteInspection(id).subscribe(result => {
                    this.subscriptions.push(this.electroService.getInspectionsbybusiness().subscribe(result => {
                        this.inspectionList = result;
                        this.bindStatusIcon(this.inspectionList);
                    }));
                    this.toasterComponent.callToastDlt();
                }));
            }
        });
    }

    /**
     * chart window data change on left arrow click.
     */
    shiftLeft() {
        d3.selectAll('svg > g > *').remove();
        let splicer = [];
        splicer = this.route.snapshot.data['chart'];
        this.windowData = [];
        this.windowData = splicer.slice(--this.startIndex, this.startIndex + 10);
        this.initSvg();
        this.drawChart(this.windowData);
        this.enableLeftArrow = this.startIndex > 0;
        this.enableRightArrow = this.startIndex + this.windowData.length < this.data.length;
    }

    /**
     * chart window data change on right arrow click.
     */
    shiftRight() {
        d3.selectAll('svg > g > *').remove();
        let splicer = [];
        splicer = this.route.snapshot.data['chart'];
        this.windowData = [];
        this.windowData = splicer.slice(++this.startIndex, this.startIndex + 10);
        this.initSvg();
        this.drawChart(this.windowData);
        this.enableLeftArrow = this.startIndex > 0;
        this.enableRightArrow = this.startIndex + this.windowData.length < this.data.length;
    }

    public setCollapse(i: number): boolean {
        if (this.openInspRows.indexOf(i) > -1)
            return false;
        return true;
    }

    collapseRow(col, InsId) {
        if (col) {
            this.openInspRows.splice(this.openInspRows.indexOf(InsId), 1);
        } else {
            this.openInspRows.push(InsId);
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
}

