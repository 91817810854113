<div class="page-title">
  <a [routerLink]="['./../../../../list/own']" class="btn btn-icon" title="{{'BACK_PROJECTS'|translate}}">
    <span class="icon ic-xs icon-back"></span>
  </a>
  <span translate>TASK_MANAGER</span>
  <span class="page-actions">
    <button type="submit" class="btn btn-success" (click)="showDialogToAdd()" *ngIf="isNewButtonEnable || projectcheck">
      <span class="icon ic-sm icon-add"></span>
      <span translate>NEW</span>
    </button>
  </span>
</div>
<div class="table-view mbtm-30">
  <!-- Table starts -->
  <p-table #taskTable [value]="tasklist" exportFilename="TaskManager list" selectionMode="multiple"
  [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}"
    [(selection)]="selectedTasklist" [rows]="10" [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]">
    <!-- Caption -->
    <ng-template pTemplate="caption">
      <div class="ui-helper-clearfix">
        <div class="table-options">
          <div class="pull-right">
            <kuba-export [reportData]="taskTable.filteredValue || taskTable.value" [additionalData]="additionalData">
            </kuba-export>
          </div>
        </div>
      </div>
    </ng-template>
    <!-- Header -->
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 55px;">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th width="35px"></th>
        <th width="85px" pSortableColumn="No">{{'NUMBER_NO'|translate}} <p-sortIcon field="No"></p-sortIcon></th>
        <th pSortableColumn="Title">{{'TITLE'|translate}} <p-sortIcon field="Title"></p-sortIcon></th>
        <th width="90px">{{'STATUS'|translate}}</th>
        <th width="100px" pSortableColumn="EndDate">{{'DEADLINE'|translate}} <p-sortIcon field="EndDate"></p-sortIcon></th>
        <th pSortableColumn="ExecutionUserName">{{'RESPONSIBLE_PERSON'|translate}} <p-sortIcon field="ExecutionUserName"></p-sortIcon></th>
        <th width="100px" pSortableColumn="CreatedOn">{{'CREATED_ON'|translate}} <p-sortIcon field="CreatedOn"></p-sortIcon></th>
        <th width="100px" pSortableColumn="CompletedDate">{{'DONE_ON'|translate}} <p-sortIcon field="CompletedDate"></p-sortIcon></th>
        <th pSortableColumn="CreatorName">{{'CREATED_BY'|translate}} <p-sortIcon field="CreatorName"></p-sortIcon></th>
        <th pSortableColumn="ModifierName">{{'EDITED_BY'|translate}} <p-sortIcon field="ModifierName"></p-sortIcon></th>
        <th width="78px">{{'OPTIONS'| translate}}</th>
      </tr>
      <tr>
        <th></th>
        <th></th>
        <th>
          <input pInputText type="text" (input)="taskTable.filter($any($event.target)?.value, 'No', 'contains')"
            placeholder="" class="p-column-filter">
        </th>
        <th>
          <input pInputText type="text" (input)="taskTable.filter($any($event.target)?.value, 'Title', 'contains')"
            placeholder="" class="p-column-filter">
        </th>
        <th>
          <p-dropdown [options]="statuses" (onChange)="onStatusChanged($event, ed)" #ed styleClass="p-column-filter"
            placeholder="Select" [showClear]="true" appendTo="body">
            <ng-template let-status pTemplate="item"> {{ status.label | translate }}
            </ng-template>
          </p-dropdown>
        </th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
      </tr>
    </ng-template>
    <!-- Body Content -->
    <ng-template pTemplate="body" let-task>
      <tr>
        <td style="width: 55px;">
          <p-tableCheckbox id="selectedOrders" [value]="task">
          </p-tableCheckbox>
        </td>
        <td>
          <span *ngIf="task.StatusId == 10" class="{{task.StatusIcon}}" style="color:red"></span>
          <span *ngIf="task.StatusId != 10" class="{{task.StatusIcon}}"></span>
        </td>
        <td>
          <span>{{task.No}}</span>
        </td>
        <td>
          <ng-container
            *ngIf="
              (task.Responsible || isAdministrateActivity) &&
                task.RoleId !== '1' &&
                task.RoleId !== '2';
              else plainTitle
            "
          >
            <a
              [routerLink]="['./../edit', task.Id]"
              pTooltip="{{ task.Title }}"
              tooltipPosition="right"
            >
              {{ task.Title }}
            </a>
          </ng-container>

          <ng-template #plainTitle>
            <span
              pTooltip="{{ task.Title }}"
              tooltipPosition="right"

            >
              {{ task.Title }}
            </span>
          </ng-template>
        </td>
        <td>
          <span>{{task.Status | translate}}</span>
        </td>
        <td>
          <span>{{task.EndDate | date : 'dd/MM/yyyy'}}</span>
        </td>
        <td>
          <span>{{task.ExecutionUserName}}</span>
        </td>
        <td>
          <span>{{task.CreatedOn | date : 'dd/MM/yyyy'}}</span>
        </td>
        <td>
          <span>{{task.CompletedDate | date : 'dd/MM/yyyy'}}</span>
        </td>
        <td>
          <span>{{task.CreatorName}}</span>
        </td>
        <td>
          <span>{{task.ModifierName}}</span>
        </td>
        <td class="col-button">
          <a (click)="task.StatusId!==9?confirm(task.Id):false;" *ngIf="task.StatusId !==9 && task.RepetitionId <=1"
            class="btn btn-icon" title="{{'DELETE'|translate}}" routerLinkActive="active">
            <i class="icon ic-sm icon-trash"></i>
          </a>
          <a (click)="showDialogForStopFutureRecurring(task)"
            *ngIf="task.StatusId !==9 && task.RepetitionId > 1 && roleId ==='3'" class="btn btn-icon"
            title="{{'DELETE'|translate}}" routerLinkActive="active">
            <i class="icon ic-sm icon-032"></i>
          </a>
        </td>
      </tr>
    </ng-template>
    <!-- Empty message -->
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td colspan="12">
          {{'NO_RECORDS_FOUND'|translate}}
        </td>
      </tr>
    </ng-template>
    <!-- Footer -->
    <ng-template pTemplate="summary">
      <div class="footer-data">
        <div class="symbol-descriptions">
          <div class="symbol-desc-title">
            <span translate>DESCRIPTION_SYMBOLS</span>
          </div>
          <div class="symbol-desc-items">
            <span class="new-deadline">
              <i class="icon ic-md icon-new-deadline"></i>
              <span translate>NEW_ISSUE</span>
            </span>
            <span class="far-deadline">
              <i class="icon ic-md icon-far-deadline"></i>
              <span>
                <span translate>MORE_DEADLINE</span>
              </span>
            </span>
            <span class="over-deadline">
              <i class="icon ic-md icon-over-deadline"></i>
              <span>
                <span translate>OVERDEADLINE</span>
              </span>
            </span>
            <span class="near-deadline">
              <i class="icon ic-md icon-near-deadline"></i>
              <span>
                <span translate>LESS_DEADLINE</span>
              </span>
            </span>
            <span class="clear-deadline">
              <i class="icon ic-md icon-clear-deadline"></i>
              <span>
                <span translate>DONE_STATUS</span>
              </span>
            </span>
            <span class="clear-deadline" style="color:red">
              <i class="icon ic-md icon-cancel"></i>
              <span translate>REJECTED</span>
            </span>
          </div>
        </div>
      </div>
    </ng-template>
  </p-table>
  <!-- Table ends -->
</div>

<p-dialog header="{{'NEW_TASK' | translate}}" [(visible)]="displayDialog" 
[style]="{width: '300px'}" [resizable]="false" [draggable]="false"
  showEffect="fade" [modal]="true">
  <form [formGroup]="newTaskForm">
    <div class="form">
      <div class="form-group" *ngIf="newTaskForm.controls['Title']">
        <label for="title">
          <span translate>TITLE</span>
          <span class="required">*</span>
          <control-messages [control]="newTaskForm.controls['Title']"></control-messages>
        </label>
        <div>
          <input pInputText formControlName="Title" />
        </div>
      </div>
      <div class="form-group">
        <label for="description">
          <span translate>DESCRIPTION</span>
        </label>
        <div>
          <textarea pInputText formControlName="Description"></textarea>
        </div>
      </div>
      <div class="action-btns-wrapper">
        <button type="submit" class="btn btn-primary" icon="pi-check" label="Save" (click)="onSubmitTemplateBased()"
          [disabled]="!newTaskForm.valid || saveButtonClicked">
          <span translate>SAVE</span>
        </button>
        <button type="button" class="btn btn-outline-secondary" icon="pi-times" (click)="onCancel()">
          <span translate>CANCEL</span>
        </button>
      </div>
    </div>
  </form>
</p-dialog>

<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
  acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
<p-dialog header="{{'CONFIRMATION' | translate}}"
  [(visible)]="displayDialogForRecurring" [style]="{width: '350px'}" [resizable]="false" [draggable]="false" showEffect="fade" [modal]="true" [closable]="true">
  <div class="confirmation-content">
    <div class="pi pi-exclamation-triangle">
     <span style="font-family: 'Arial', sans-serif;"> {{ 'STOP_DELETE_FUTURE_RECURRING_TASKMANAGER' | translate }}</span>
    </div>
  </div>
  <div class="action-btns-wrapper">
    <button type="submit" class="btn btn-primary" icon="pi-check" [disabled]="IsFutureRecurring" label="Save"
      (click)="onClickStopFutureRecurring()">
      <span translate>STOP_FUTURE_RECURRING</span>
    </button>
    <button type="button" class="btn btn-primary" icon="pi-check" (click)="onClickDeleteRecurringActivity()">
      <span translate>DELETE</span>
    </button>
  </div>
</p-dialog>
<toaster-component></toaster-component>
<div class="panel-loader" *ngIf="loading">
  <span>
    <span class="loader-icon"></span>
    <span translate>PROCESSING</span>
  </span>
</div>