import { Injectable } from '@angular/core';

@Injectable()

export class EcplConfirmViewerService {
    constructor() {

    }
    
    getFileExtension(filename: string) {
        let fileExtension = null;
        if (filename != null) {
        fileExtension = filename.substr(filename.lastIndexOf('.') + 1).toLowerCase();
        }
        return fileExtension;
    }

    humanizeType(mimetype: string) {
        let type = '';

        switch (mimetype) {
        case 'xlsx':
        case 'xls':
        case 'csv':
        case 'docx':
        case 'doc':
        case 'rtf':
        case 'ppt':
        case 'pptx':
        case 'csv':
            type = 'Document';
            break;
        case 'png':
        case 'jpg':
        case 'JPG':
        case 'jpeg':
        case 'bmp':
        case 'gif':
        case 'tiff':
            type = 'Image';
            break;
        case 'mp3':
        case 'wav':
            type = 'Audio';
            break;
        case 'avi':
        case 'mp4':
        case '3gpp':
        case 'webm':
        case 'flv':
            type = 'Video';
            break;
        case 'txt':
            type = 'Text';
            break;
        case 'PDF':
        case 'pdf':
            type = 'Pdf';
            break;
        case 'zip':
        case 'rar':
        case '7z':
            type = 'Archive file';
            break;
        default:
            type = 'Article';
            break;
        }

        return type;
    }
}
