import { ProjectEditResolver } from './../projects/services/project.resolver';
import {
  TimeTableEditResolver,
  TimeTableListResolver,
  EmployeeDropdownResolver,
  TypeOfWorkDropdownResolver,
  ApprovalPersonDropdownResolver,
  ProjectDropdownResolver,
} from './services/timetable.resolver.service';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TimetableListComponent } from './components/timetable-list/timetable-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { CreateTimetableComponent } from './components/new-timetable/new-timetable.component';
import { EditTimetableComponent } from './components/edit-timetable/edit-timetable.component';
import { TimetableComponent } from './timetable.component';
import { TimetableFormComponent } from './timetable-form.component';
import { SharedModule } from 'primeng/api';
import { TimetableServices } from './services/timetable.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppSharedModule } from './../shared/appShared.module';
import { RouterModule } from '@angular/router';
import { KubaExportModule } from './../shared/kuba-exports/kuba-export.module';
import { HttpClientModule } from '@angular/common/http';
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { EditorModule } from 'primeng/editor';
import { ContextMenuModule } from 'primeng/contextmenu';
import { MenuModule } from 'primeng/menu';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { MyDatePickerModule } from 'mydatepicker';
import { ApprovalPersonResolver } from '../employees/services/employeeleave.resolver.service';
@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    TableModule,
    SharedModule,
    FormsModule,
    CalendarModule,
    EditorModule,
    ContextMenuModule,
    MenuModule,
    DialogModule,
    ReactiveFormsModule,
    DropdownModule,
    InputTextareaModule,
    InputTextModule,
    TranslateModule,
    AppSharedModule,
    RouterModule,
    ButtonModule,
    MyDatePickerModule,
    KubaExportModule,
  ],
  declarations: [TimetableComponent, TimetableListComponent],
  exports: [TimetableComponent, TimetableListComponent, KubaExportModule],
  providers: [
    TimetableServices,
    TimeTableListResolver,
    TimeTableEditResolver,
    TypeOfWorkDropdownResolver,
    EmployeeDropdownResolver,
    ApprovalPersonDropdownResolver,
    ProjectDropdownResolver,
    ApprovalPersonResolver,
  ],
})
export class TimetableModule {}
