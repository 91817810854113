import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
  HttpEvent,
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from '../_services';
import { IdentityToken } from '../_models/identityToken';

@Injectable()
export class Interceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<IdentityToken | null> = new BehaviorSubject<
    IdentityToken | null
  >(null);

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}
  /**
   * Intercepts HTTP requests to add with credentials, CSRF tokens and handle 401 Unauthorized errors.
   *
   * @param {HttpRequest<any>} req - The outgoing HTTP request.
   * @param {HttpHandler} next - The next interceptor in the chain.
   * @returns {Observable<HttpEvent<any>>} - An observable that emits the HTTP event of the handled request.
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const csrfToken = this.authenticationService.getCsrfToken();
    const stateChangingMethods = ['POST', 'PUT', 'DELETE', 'PATCH'];
    const shouldAddCsrfToken = stateChangingMethods.includes(req.method.toUpperCase());

    const isFormData = req.body instanceof FormData || req.body instanceof Blob;

    const clonedRequest = req.clone({
      withCredentials: true,
      setHeaders: {
        ...(shouldAddCsrfToken && csrfToken ? { 'X-XSRF-TOKEN': csrfToken } : {'Logo-Id': 'ab064fd4-9b4a-4e70-972d-dafdaceb2aba'}),
        ...(isFormData
          ? {}
          : { 'Content-Type': 'application/json'}),
      },
    });

    return next.handle(clonedRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          return this.handle401Error(clonedRequest, next);
        }
        return throwError(() => error);
      })
    );
  }
  /**
   * Handles HTTP 401 Unauthorized errors by attempting to refresh the access token.
   * If the token is successfully refreshed, the original request is retried.
   * If the token refresh fails, the user is logged out and the error is propagated.
   *
   * @param {HttpRequest<any>} req - The original HTTP request that resulted in a 401 error.
   * @param {HttpHandler} next - The next interceptor in the chain, used to retry the request.
   * @returns {Observable<HttpEvent<any>>} - An observable that emits the HTTP event of the retried request or an error.
   */
  private handle401Error(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authenticationService.refreshToken().pipe(
        switchMap((newToken: IdentityToken) => {
          this.refreshTokenSubject.next(newToken);
          this.isRefreshing = false;

          const updatedRequest = req.clone({
            withCredentials: true,
            setHeaders: { 'X-XSRF-TOKEN': newToken.CsrfToken, 'Logo-Id': 'ab064fd4-9b4a-4e70-972d-dafdaceb2aba' },
          });
          return next.handle(updatedRequest);
        }),
        catchError((err) => {
          this.isRefreshing = false;
          this.authenticationService.logout();
          console.error('Token refresh failed, logging out.', err);
          return throwError(() => err);
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token: IdentityToken) => token != null),
        take(1),
        switchMap((identityToken: IdentityToken) => {
          const updatedRequest = req.clone({
            withCredentials: true,
            setHeaders: { 'X-XSRF-TOKEN': identityToken.CsrfToken, 'Logo-Id': 'ab064fd4-9b4a-4e70-972d-dafdaceb2aba' },
          });
          return next.handle(updatedRequest);
        }),
        catchError((err) => {
          console.error('Error while waiting for a token refresh, logging out.', err);
          this.authenticationService.logout();
          return throwError(() => err);
        })
      );
    }
  }

  private reloadCurrentRoute() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
}
