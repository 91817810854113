import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';

import { ToasterComponent } from '../../../_directives/toaster.component';
import { BaseServices } from '../../kuba.services';
import { HelperService, PdfSetting } from './../../../_services/helper.service';
import { BusinessServices } from './../../businesses/services/business.services';
import { AdminUserService } from './../services/adminuser.service';
import { AdminUser } from '../models/adminuser';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'adminuser-list',
    templateUrl: 'adminuser-list.component.html'
})
export class AdminUserListComponent implements OnInit {
    //#region variables
    @ViewChild(ToasterComponent,{static:false}) toasterComponent: ToasterComponent;
    users: AdminUser[];
    AdminPortal = [1]; // Admin
    private subscriptions: Subscription[] = [];
    additionalData: any;
    //#endregion

    //#region constructor
    /**
     * Constructor
     * @param route {ActivatedRoute}
     * @param confirmationService {ConfirmationService}
     * @param adminUserService {AdminUserService}
     * @param businessService {BusinessServices}
     */
    constructor(
        private route: ActivatedRoute,
        private confirmationService: ConfirmationService,
        private adminUserService: AdminUserService,
        private businessService: BusinessServices,
        private translate: TranslateService
    ) {

        this.subscriptions.push(
            this.translate.stream('FILENAME').subscribe((val) => {
              this.additionalData = {
                fileName: val.ADMIN_USER_LIST,
                header: 'Admin Users List',
                businessId: BaseServices.BusinessId,
                cultureInfo: BaseServices.userCultureInfo(),
                columnNames: [
                    { title: 'Name', dataKey: 'Name' },
                    { title: 'User Name', dataKey: 'Username' },
                    { title: 'Role', dataKey: 'Role' },
                    { title: 'Account Owner', dataKey: 'Owner.' },
                    { title: 'Mobile', dataKey: 'Mobile' },
                    { title: 'Email', dataKey: 'Email' },
                    { title: 'Creator Name', dataKey: 'CreatorName' }
                ],
              };
            })
          );
    }
    //#endregion

    //#region page-event
    /**
     * initialization
     */
    ngOnInit(): void {
        document.querySelector("body").classList.remove("opened");
        let list = this.route.snapshot.data['list'];
       
        this.users = list;
    }
    //#endregion

    //#region methods
    /**
     * Referesh the table in data
     */
    fetchData() {
        this.subscriptions.push(this.businessService
            .getUsersByBusiness(BaseServices.BusinessId)
            .subscribe((list: any) => {
                list = list.filter(
                    (x: any) => this.AdminPortal.indexOf(x.RoleId) > -1
                );
                this.users = list;
            }));
    }
    /**
     * delete the user
     * @param id {number}
     */
    delete(id: number) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.adminUserService
                    .deleteAdminUser(id)
                    .subscribe((list: any) => {
                        if (list) {
                            this.toasterComponent.callToastDlt();
                            this.fetchData();
                        }
                    }));
            }
        });
    }

    exportPdf() {
        let pdfSetting = new PdfSetting();
        pdfSetting.date = 'Date' + HelperService.formatDate(new Date());
        pdfSetting.businessName = BaseServices.BusinessName;
        pdfSetting.pageHeader = 'User List';
        let columns: any = [
            { title: 'Name', dataKey: 'Name' },
            { title: 'User Name', dataKey: 'Username' },
            { title: 'Role', dataKey: 'Role' },
            { title: 'Account Owner', dataKey: 'Owner.' },
            { title: 'Mobile', dataKey: 'Mobile' },
            { title: 'Email', dataKey: 'Email' },
            { title: 'Creator Name', dataKey: 'CreatorName' }
        ];
        HelperService.generatePdf(
            this.users,
            columns,
            'User list',
            pdfSetting,
            'l'
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
    //#endregion
}
