import { UserRole } from './../../../follow-ups/models/deviation';
import { TranslateService } from '@ngx-translate/core';
import { Module } from 'src/app/kuba/follow-ups/models/deviation';
import { element } from 'protractor';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ConfirmationService } from 'primeng/api';

import { ToasterComponent } from './../../../../_directives/toaster.component';
import { HelperService, PdfSetting } from 'src/app/_services/helper.service';
import { Options } from './../../../../_models/options';
import { Timetable } from '../../models/timetable';
import { TimetableServices } from '../../services/timetable.service';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { EmployeeServices } from './../../../employees/services/employee.services';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { time } from 'console';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Rights, Status } from 'src/app/_models';

@Component({
  selector: 'edit-timetable',
  templateUrl: 'edit-timetable.component.html',
})
export class EditTimetableComponent implements OnInit {
  //#region variables

  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  parentId: number;
  parentKey: any;
  isGuest = false;
  options = [
    new Options(8, 'Open'),
    new Options(6, 'Approved'),
    new Options(10, 'Rejected'),
  ];
  timetableId: any;
  EditTimetableForm: FormGroup;
  timetable: any;
  EmployeeTimeTable: Timetable[];
  employeeId: number;
  approvalPersonId: number;
  employeeTimetableId: any;
  isHideStatus = false;
  isVisibleTimetable = false;
  newTimeTable = 'NEW_TIMETABLE';
  isEditTimeTable = false;
  eid: number;
  isParentEmployee: boolean;
  employeeParent: string;
  createdBy: number;
  isReadOnly = false;
  startDate: Date;
  endDate: Date;
  showNotification = false;
  isProject = false;
  additionalData: any;
  isNotUser: boolean;
  workingModule: any;
  userAccess: any;
  allowedEdit: boolean = true;
  allowedStatusChange: boolean = true;

  /**
   * Date picker configuration option
   */
  public dateOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
    editableDateField: false,
    openSelectorOnInputClick: true,
    firstDayOfWeek: 'su',
    satHighlight: true,
    height: '32px',
    selectionTxtFontSize: '14px',
    todayBtnTxt: this.translate.instant('TODAY'),
    dayLabels: {
      su: this.translate.instant('SUN'),
      mo: this.translate.instant('MON'),
      tu: this.translate.instant('TUE'),
      we: this.translate.instant('WED'),
      th: this.translate.instant('THU'),
      fr: this.translate.instant('FRI'),
      sa: this.translate.instant('SAT'),
    },
    monthLabels: {
      1: this.translate.instant('JANUARY'),
      2: this.translate.instant('FEBRUARY'),
      3: this.translate.instant('MARCH'),
      4: this.translate.instant('APRIL'),
      5: this.translate.instant('MAY'),
      6: this.translate.instant('JUNE'),
      7: this.translate.instant('JULY'),
      8: this.translate.instant('AUGUST'),
      9: this.translate.instant('SEPTEMBER'),
      10: this.translate.instant('OCTOBER'),
      11: this.translate.instant('NOVEMBER'),
      12: this.translate.instant('DECEMBER'),
    },
  };

  private subscriptions: Subscription[] = [];

  // #endregion

  //#region constructor
  /**
   * Constructor
   * @param timetableService {TimetableServices}
   * @param _fb {FormBuilder}
   * @param route {ActivatedRoute}
   * @param location {Location}
   */
  constructor(
    private timetableService: TimetableServices,
    private _fb: FormBuilder,
    private route: ActivatedRoute,
    private location: Location,
    private confirmationDialog: ConfirmationService,
    private employeeServices: EmployeeServices,
    private translate: TranslateService
  ) {
    this.parentId = this.route.snapshot.params['eid'];
    this.parentKey = this.route.snapshot.data['parent'];
    this.employeeParent = this.route.snapshot.data['parent'];
    this.EditTimetableForm = this._fb.group({
      EmployeeId: ['', Validators.required],
      ProjectName: [''],
      TypeOfWorkId: ['', Validators.required],
      StartDate: ['', Validators.required],
      EndDate: [''],
      StartTime: ['', Validators.required],
      EndTime: ['', Validators.required],
      ActualSpend: [''],
      HoursSpent: [''],
      BreakTime: ['00:00', [], this.breaktime.bind(this)],
      ApprovalUserId: ['', Validators.required],
      TimeTableApproverId: ['', Validators.required],
      Comments: [''],
      Status: [''],
      Id: 0,
      ApprovalDate: null,
    });
  }

  // #endregion

  //#region page-events

  /**
   * page Init
   */
  ngOnInit() {
    document.querySelector('body').classList.remove('opened');
    this.isNotUser = +BaseServices.roleId === UserRole.USER ? false : true;

    if (this.parentKey === 'EMPLOYEE') {
      this.isParentEmployee = true;
    } else {
      this.isParentEmployee = false;
    }
    this.timetableService.getParent(this.isParentEmployee);
    if (BaseServices.roleId === '4') {
      this.isGuest = true;
    }
    if (this.route.snapshot.parent.parent.data['parent'] === 'TIMETABLE') {
      this.parentKey = this.route.snapshot.data['parent'];
    } else {
      this.parentKey = this.route.snapshot.parent.parent.data['parent'];
    }
    if (
      this.parentKey === 'IC' ||
      this.parentKey === 'VEHICLE' ||
      this.parentKey === 'OS' ||
      this.parentKey === 'QS'
    ) {
      this.isHideStatus = true;
    } else {
      this.isHideStatus = false;
    }
    this.eid = this.route.snapshot.params['eid'];
    this.employeeTimetableId = this.route.snapshot.params['tid'];
    if (this.eid) {
      this.isVisibleTimetable = true;
      this.clearTimetableControls();
      this.employeeId = +this.eid
        ? +this.eid
        : this.EditTimetableForm.value.EmployeeId;
      if (this.employeeId) {
        this.EmployeeTimeTable = [];
        let list = <Timetable[]>this.route.snapshot.data['timetable'];
        this.bindTimeTableGrid(list);
      }
      this.timetable = {
        EmployeeId: 0,
        ProjectName: '',
        TypeOfWorkId: '',
        StartDate: null,
        EndDate: null,
        EndTime: null,
        BreakTime: '00:00',
        HoursSpent: null,
        ActualSpend: null,
        Comments: '',
        StartTime: null,
        ApprovalUserId: '',
        ApprovalDate: null,
        TimeTableApproverId: [''],
        Status: 0,
        Id: this.employeeTimetableId ? this.employeeTimetableId : 0,
      };
      (<FormGroup>this.EditTimetableForm).setValue(this.timetable, {
        onlySelf: true,
      });
    } else {
      this.isEditTimeTable = true;
      this.newTimeTable = 'EDIT_TIMETABLE';
      let timetableInfo = this.route.snapshot.data['edit'];
      timetableInfo.Status =
        timetableInfo.Status && timetableInfo.Status === Status.New
          ? Status.Open
          : timetableInfo.Status;
      timetableInfo.Status = timetableInfo.Status.toString();
      if (timetableInfo.ModuleFeatureId) {
        this.isReadOnly = true;
      }
      this.createdBy = timetableInfo.CreatedBy;
      if (timetableInfo.EndDate === null) {
        timetableInfo.EndDate = timetableInfo.StartDate;
      }
      let approver = [];
      if (timetableInfo.TimeTableApproverId) {
        timetableInfo.TimeTableApproverId.forEach((element) => {
          approver.push(element);
        });
      }
      this.isVisibleTimetable = false;
      this.timetableId = timetableInfo.Id;
      if (timetableInfo) {
        timetableInfo.EmployeeId =
          timetableInfo.EmployeeId === null ? '' : timetableInfo.EmployeeId;
        this.approvalPersonId = timetableInfo.ApprovalUserId;
        this.EditTimetableForm = this._fb.group({
          EmployeeId: new FormControl(
            timetableInfo.EmployeeId,
            Validators.required
          ),
          ProjectName: timetableInfo.ModuleFeatureId,
          TypeOfWorkId: new FormControl(
            timetableInfo.TypeOfWorkId,
            Validators.required
          ),
          StartDate: timetableInfo.StartDate
            ? HelperService.formatInputDate(timetableInfo.StartDate)
            : null,
          EndDate: timetableInfo.EndDate
            ? HelperService.formatInputDate(timetableInfo.EndDate)
            : null,
          EndTime: timetableInfo.EndTime
            ? HelperService.formatTime(timetableInfo.EndTime)
            : null,
          HoursSpent: timetableInfo.HoursSpent,
          Comments: timetableInfo.Comments,
          StartTime: timetableInfo.StartTime
            ? HelperService.formatTime(timetableInfo.StartTime)
            : null,
          ApprovalUserId: new FormControl(approver, Validators.required),
          TimeTableApproverId: new FormControl(approver),
          Status: new FormControl({
            value:
              timetableInfo.Status === Status.New
                ? Status.Open
                : timetableInfo.Status,
            disabled:
              timetableInfo.ApprovalUserId === BaseServices.UserId
                ? false
                : false,
          }),
          Id: timetableInfo.Id,
          BreakTime: timetableInfo.BreakTime
            ? timetableInfo.BreakTime
            : '00:00',
          ActualSpend: null,
          ApprovalDate: timetableInfo.ApprovalDate,
        });
      }
      this.workingModule = JSON.parse(sessionStorage.getItem('workingModule'));
      let role = BaseServices.UserRole;
      if (role === 'User') {
        // Cannot figure out what this magic number (8) is? Is it Employees module id?
        if (this.workingModule.id == 8) {
          if (
            timetableInfo.TimeTableApproverId.some(
              (e) => e === BaseServices.UserId
            )
          ) {
            this.userAccess = false;
          } else {
            this.userAccess = true;
          }
        }
      }
    }

    // Project Name hide/show based on Project Module existence
    let leftNav = JSON.parse(sessionStorage.getItem('leftNav'));
    let filteredTemplate = HelperService.filterTree(
      'Project',
      leftNav.filter((x) => {
        if (x.name === 'Project') {
          let childLength = x.children.length;
          if (x.children[3].checked === true) {
            this.isProject = true;
          }
        }
      }),
      'IsProject'
    );
    this.subscriptions.push(
      this.translate.stream('FILENAME').subscribe((val) => {
        this.additionalData = {
          fileName: val.TIMETABLE_LIST,
          header: 'Timetable List',
          businessId: BaseServices.BusinessId,
          cultureInfo: BaseServices.userCultureInfo(),
          columnNames: [
            { title: 'Employee Name', dataKey: 'EmployeeName' },
            { title: 'Type of work', datakey: 'ProjectWorkTypeName' }, // added column typeofwork
            { title: 'Comments', dataKey: 'Comments' },
            { title: 'Start Date', dataKey: 'StartDate' },
            { title: 'End Date', dataKey: 'EndDate' },
            { title: 'Start Time', dataKey: 'StartTime' },
            { title: 'End Time', dataKey: 'EndTime' },
            { title: 'Break', dataKey: 'BreakTime' },
            { title: 'Hours Spent', dataKey: 'HoursSpent' },
            { title: 'Approver', dataKey: 'ApprovalUser' },
            { title: 'Approved On', dataKey: 'ApprovalDate' },
          ],
        };
      })
    );
  }

  /**
   * TimeTable DateRange Filter
   * @param StartDate {any}
   * @param EndDate {any}
   */
  TimeTableDateRangeFilter(StartDate: any, EndDate: any) {
    let startDate = StartDate ? StartDate : new Date('1800-01-01');
    let endDate = EndDate ? EndDate : new Date();
    let list = <Timetable[]>this.route.snapshot.data['timetable'];
    if (list) {
      list = list.filter((x) => {
        return (
          new Date(x.StartDate) >= startDate && new Date(x.EndDate) <= endDate
        );
      });
      this.bindTimeTableGrid(list);
    }
  }
  // #endregion

  //#region Control-events

  /**
   * Save TimeTable
   */
  saveChanges() {
    this.timetableId = this.employeeTimetableId ? this.employeeTimetableId : 0;
    let timetable =
      this.EditTimetableForm.value.Id > 0
        ? this.EditTimetableForm.value
        : new Timetable();
    timetable = this.EditTimetableForm.value;
    timetable.Status = this.EditTimetableForm.value.Status
      ? this.EditTimetableForm.value.Status
      : Status.Open;
    if (
      timetable.Status == Status.Done &&
      this.EditTimetableForm.value.ApprovalDate == null
    ) {
      timetable.ApprovalDate = HelperService.formatDate(new Date());
    } else {
      timetable.ApprovalDate = this.EditTimetableForm.value.ApprovalDate;
    }
    timetable.StartDate = this.EditTimetableForm.value.StartDate
      ? HelperService.formatDateFilter(
          this.EditTimetableForm.value.StartDate.formatted
        )
      : null;
    timetable.EndDate = this.EditTimetableForm.value.EndDate
      ? HelperService.formatDateFilter(
          this.EditTimetableForm.value.EndDate.formatted
        )
      : null;
    timetable.StartTime = HelperService.formatTime(
      this.EditTimetableForm.value.StartTime
    );
    timetable.EndTime = HelperService.formatTime(
      this.EditTimetableForm.value.EndTime
    );
    timetable.HoursSpent = this.EditTimetableForm.value.HoursSpent;
    timetable.BreakTime = HelperService.formatTime(
      this.EditTimetableForm.value.BreakTime
    );
    if (timetable.BreakTime !== null && timetable.BreakTime !== '') {
      let splitBreakTime = timetable.BreakTime.split(':')[1];
      if (splitBreakTime.length === 1) {
        timetable.BreakTime =
          timetable.BreakTime.split(':')[0] +
          ':' +
          '0' +
          timetable.BreakTime.split(':')[1];
      }
    }
    let TimeTable = [];
    TimeTable = timetable.ApprovalUserId;
    timetable.TimeTableApproverId = [];
    let approvalPerson = this.route.snapshot.data['approvalPerson'];
    if (TimeTable != null) {
      TimeTable.forEach((element) => {
        if (approvalPerson.filter((x) => x.Id === element).length > 0) {
          timetable.TimeTableApproverId.push(
            approvalPerson.filter((x) => x.Id === element)[0].Id
          );
        }
      });
    }
    timetable.BusinessId = BaseServices.BusinessId;
    timetable.ApprovalUserId = null;
    let val: any;
    if (this.EditTimetableForm.value.ProjectName) {
      val = this.EditTimetableForm.value.ProjectName.split('_');
    }
    if (this.employeeParent === 'EMPLOYEE') {
      let featureId: number;
      if (val) {
        if (val[0] === 'PROJECT') {
          featureId = Module.PROJECT;
        } else if (val[0] === 'FOODSAFETY') {
          featureId = Module.FOODSAFETY;
        } else if (val[0] === 'INTERNALCONTROL') {
          featureId = Module.INTERNALCONTROL;
        } else if (val[0] === 'VEHICLE') {
          featureId = Module.VEHICLE;
        } else if (val[0] === 'FIREDEVIATION') {
          featureId = Module.FIREDEVIATION;
        } else if (val[0] === 'SERVICE') {
          featureId = Module.SERVICEAGREEMENT;
        } else if (val[0] === 'FRAMEWORK') {
          featureId = Module.FRAMEWORKAGREEMENT;
        } else if (val[0] === 'KUNDE') {
          featureId = Module.Kunde_EL;
        }
        if (val[0] === 'SERVICE' || val[0] === 'KUNDE') {
          timetable.Featurekey = val[2] !== null ? +val[2] : 0;
          timetable.FeatureId = featureId;
        } else {
          timetable.Featurekey = val[1] ? +val[1] : this.parentId; // project or zone feature key
          timetable.FeatureId = featureId ? featureId : this.employeeParent; // // project or zone feature Id
        }
      } else {
        timetable.Featurekey = this.parentId;
        timetable.FeatureId = Module.EMPLOYEES;
      }
    } else {
      timetable.Featurekey = this.parentId;
    }
    timetable.ProjectId = val ? +val[1] : 0;
    if (timetable) {
      if (timetable.Id > 0 || this.timetableId > 0) {
        if (this.parentKey === 'TIMETABLE') {
          timetable.Id = this.timetableId;
          timetable.EmployeeId = this.eid;
          this.employeeId = this.eid;
        } else {
          timetable.Id = this.timetableId ? this.timetableId : 0;
          timetable.EmployeeId = this.EditTimetableForm.value.EmployeeId;
          this.employeeId = this.EditTimetableForm.value.EmployeeId;
        }
        timetable.CreatedBy = this.createdBy;
        timetable.ModifiedBy = BaseServices.UserId;
        this.subscriptions.push(
          this.timetableService
            .updateTimetable(timetable.Id, timetable)
            .pipe(finalize(() => this.getTimetableByEmpId(this.employeeId)))
            .subscribe((isSaved) => {
              if (isSaved) {
                this.clearTimetableControls();
                this.timetableId = 0;
                this.toasterComponent.callToast();
              }
            })
        );
      } else {
        timetable.CreatedBy = BaseServices.UserId;
        timetable.Status = this.EditTimetableForm.value.Status;
        timetable.EmployeeId = this.employeeId;
        timetable.Id = this.timetableId;
        this.subscriptions.push(
          this.timetableService
            .addTimetable(timetable, this.parentKey)
            .subscribe((IsTimeTableSaved) => {
              if (IsTimeTableSaved) {
                this.clearTimetableControls();
                this.toasterComponent.callToast();
                this.getTimetableByEmpId(this.employeeId);
                this.ngOnInit();
              }
            })
        );
      }
    }
  }
  resetTimeTable() {
    this.employeeTimetableId = 0;
    this.EditTimetableForm.reset();
  }
  getTimetableByEmpId(empId: number) {
    this.subscriptions.push(
      this.employeeServices
        .getTimeTableByEmployeeId(this.employeeId)
        .subscribe((list) => {
          this.bindTimeTableGrid(list);
        })
    );
  }
  /**
   * To go TimeTable List
   */
  gotoBack() {
    this.location.back();
  }

  /**
   * Print list
   */
  print() {
    this.confirmationDialog.confirm({
      message: 'Are you sure that you want to generate report?',
      accept: () => {
        let pdfSetting = new PdfSetting();
        pdfSetting.date = 'Date:' + HelperService.formatDate(new Date());
        pdfSetting.businessName = BaseServices.BusinessName;
        pdfSetting.pageHeader = 'Timetable';
        let data = this.EmployeeTimeTable;
        if (data.length > 0) {
          let columns = [
            { title: 'Type of work', datakey: 'ProjectWorkTypeName' },
            { title: 'Project Name', dataKey: 'ProjectName' },
            { title: 'Name', dataKey: 'EmployeeName' },
            { title: 'Comments', dataKey: 'Comments' },
            { title: 'Start Date', dataKey: 'StartDate' },
            { title: 'End Date', dataKey: 'EndDate' },
            { title: 'End Time', dataKey: 'EndTime' },
            { title: 'Break', dataKey: 'BreakTime' },
            { title: 'Total Hours Spent', dataKey: 'HoursSpent' },
            { title: 'Approver', dataKey: 'ApprovalUser' },
            { title: 'Approved On', dataKey: 'ApprovalDate' },
          ];
          HelperService.generatePdf(
            data,
            columns,
            'Timetable',
            pdfSetting,
            'l'
          );
        } else {
          this.showNotification = true;
        }
      },
    });
  }

  /**
   * Gets a time table from the grid, and then fills the time table form.
   * It also checks if the user is allowed to edit the time table
   * (Is checked for approved time tables)
   * @param employeeTimeTableId time table from grid
   */
  editEmployeeTimeTable(TimeTable: any) {
    this.isEditTimeTable = true;
    this.newTimeTable = 'EDIT_TIMETABLE';
    this.subscriptions.push(
      this.timetableService
        .getTimeTableByTimeTableId(
          TimeTable.Id,
          TimeTable.FeatureId,
          TimeTable.FeatureKey
        )
        .subscribe((employeeTimeTable: any) => {
          this.isReadOnly = employeeTimeTable.ModuleFeatureId !== null;
          this.timetableService.getReadOnly(this.isReadOnly);
          this.employeeTimetableId = employeeTimeTable.Id;
          if (employeeTimeTable) {
            employeeTimeTable.BreakTime =
              employeeTimeTable.BreakTime === '00:00:00'
                ? null
                : employeeTimeTable.BreakTime;
            let timetable;
            let ApprovalUserId;
            if (employeeTimeTable['ApprovalUserId']) {
              ApprovalUserId = employeeTimeTable['ApprovalUserId'];
            } else {
              ApprovalUserId = TimeTable['ApprovalUserIdList'];
            }
            timetable = {
              Id: employeeTimeTable.Id,
              EmployeeId: employeeTimeTable.EmployeeId,
              ProjectName: employeeTimeTable.ModuleFeatureId,
              TypeOfWorkId: employeeTimeTable.TypeOfWorkId,
              StartDate: employeeTimeTable.StartDate
                ? HelperService.formatInputDate(employeeTimeTable.StartDate)
                : null,
              EndDate: employeeTimeTable.EndDate
                ? HelperService.formatInputDate(employeeTimeTable.EndDate)
                : null,
              EndTime: employeeTimeTable.EndTime
                ? HelperService.formatTime(employeeTimeTable.EndTime)
                : null,
              ApprovalDate: employeeTimeTable.ApprovalDate,
              HoursSpent: employeeTimeTable.HoursSpent,
              ActualSpend:
                employeeTimeTable.BreakTime !== null &&
                employeeTimeTable.BreakTime !== ''
                  ? this.calculateHourSpent(
                      employeeTimeTable.BreakTime,
                      employeeTimeTable.HoursSpent
                    )
                  : employeeTimeTable.HoursSpent,
              BreakTime: employeeTimeTable.BreakTime
                ? HelperService.formatTime(employeeTimeTable.BreakTime)
                : null,
              Comments: employeeTimeTable.Comments,
              StartTime: employeeTimeTable.StartTime
                ? HelperService.formatTime(employeeTimeTable.StartTime)
                : null,
              TimeTableApproverId: employeeTimeTable.TimeTableApproverId,
              ApprovalUserId: employeeTimeTable.TimeTableApproverId,
              Status:
                employeeTimeTable.Status === Status.New
                  ? Status.Open
                  : employeeTimeTable.Status,
            };
            this.validateEditRights(employeeTimeTable);
            (<FormGroup>this.EditTimetableForm).setValue(timetable, {
              onlySelf: true,
            });
          }
        })
    );
  }

  deleteEmployeeTimeTable(employeeTimetableId: any) {
    this.confirmationDialog.confirm({
      message: this.translate.instant('DELETE_THIS_RECORD'),
      accept: () => {
        this.subscriptions.push(
          this.timetableService
            .DeleteTimetable(employeeTimetableId)
            .subscribe((isTimetableDeleted) => {
              if (isTimetableDeleted) {
                this.toasterComponent.callToastDlt();
                this.refreshList();
              }
            })
        );
      },
    });
  }

  refreshList() {
    this.subscriptions.push(
      this.employeeServices
        .getTimeTableByEmployeeId(this.employeeId)
        .subscribe((timetableInfo) => {
          if (timetableInfo) {
            this.EmployeeTimeTable = timetableInfo;
          }
        })
    );
  }

  /**
   * Clearing the time table form and reenables write mode for fields if
   * neccesary.
   */
  clearTimetableControls() {
    this.isEditTimeTable = false;
    this.allowedEdit = true;
    this.allowedStatusChange = true;
    this.newTimeTable = 'NEW_TIMETABLE';
    this.employeeTimetableId = 0;
    this.EditTimetableForm.reset();
  }

  // #endregion

  //#region methods

  /**
   * checking the retrieved data
   * @param data {any}
   */
  checkNullorEmpty(data: any) {
    return data ? data : '';
  }

  /**
   * sets month and year in the timetable model
   * @param timeTableList {any}
   */
  bindTimeTableGrid(timeTableList: any) {
    if (timeTableList) {
      this.EmployeeTimeTable = [];
      timeTableList.forEach((timeTable: any) => {
        if (timeTable && timeTable.StartDate) {
          timeTable.Month = `${new Date(timeTable.StartDate).getMonth() + 1}`;
          timeTable.Year = `${new Date(timeTable.StartDate).getFullYear()}`;
        }
        this.EmployeeTimeTable.push(timeTable);
      });
    }
  }
  /**
   * start date change event
   * @param event {IMyDateModel}
   */
  onTimeTableStartDateChanged(event: IMyDateModel) {
    this.startDate = event.jsdate;
    let date: Date = event.jsdate ? new Date(event.jsdate.getTime()) : null;
    if (event.jsdate) {
      this.TimeTableDateRangeFilter(this.startDate, this.endDate);
    } else {
      let list = <Timetable[]>this.route.snapshot.data['timetable'];
      this.bindTimeTableGrid(list);
    }
  }

  /**
   * End date change event
   * @param event {IMyDateModel}
   */
  onTimeTableEndDateChanged(event: IMyDateModel) {
    this.endDate = event.jsdate;
    if (event.jsdate) {
      this.TimeTableDateRangeFilter(this.startDate, this.endDate);
    } else {
      let list = <Timetable[]>this.route.snapshot.data['timetable'];
      this.bindTimeTableGrid(list);
    }
  }
  // #endregion

  /**
   * go to list
   */
  gotoList() {
    this.location.back();
  }
  /**
   * convert hours to minutes
   */
  timeConvert(n) {
    let num = n;
    let hours = num / 60;
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);
    return rhours + ':' + rminutes;
  }
  /** */
  breaktime(breakTime: FormControl) {
    let isShowValidation = false;
    let btimemints = null;
    let htimemints;
    let bhour: any = null;
    let bmin: any = null;
    if (this.EditTimetableForm) {
      if (
        breakTime.value != null &&
        breakTime.value !== '00:00' &&
        breakTime.value !== ''
      ) {
        bhour = new Date(breakTime.value).getHours();
        bmin = new Date(breakTime.value).getMinutes();
        btimemints = parseInt(bhour, 0) * 60 + parseInt(bmin, 0);
        btimemints = isNaN(btimemints) ? null : btimemints;
      }
      let hourSpent: any =
        this.EditTimetableForm.value.ActualSpend === ''
          ? null
          : this.EditTimetableForm.value.ActualSpend;
      if (hourSpent !== null) {
        htimemints =
          parseInt(hourSpent.split(':')[0], NaN) * 60 +
          parseInt(hourSpent.split(':')[1], NaN);
        if (
          btimemints !== null &&
          breakTime.value !== '00:00' &&
          breakTime.value !== ''
        ) {
          if (btimemints < htimemints) {
            let newHoursSpent: any =
              parseInt(htimemints, 0) - parseInt(btimemints, NaN);
            this.EditTimetableForm.get('HoursSpent').patchValue(
              this.timeConvert(newHoursSpent)
            );
          } else {
            isShowValidation = true;
          }
        }
      }
    }
    const q = new Promise((resolve) => {
      if (isShowValidation === true) {
        resolve({ break_time: true });
      } else {
        resolve(null);
      }
    });
    return q;
  }

  /**
   *
   */
  calculateHourSpent(bteakTime: string, hourSpent: string) {
    let btimemints;
    let htimemints;
    let newHoursSpent: any;
    btimemints =
      parseInt(bteakTime.split(':')[0], 0) * 60 +
      parseInt(bteakTime.split(':')[1], 0);
    htimemints =
      parseInt(hourSpent.split(':')[0], 0) * 60 +
      parseInt(hourSpent.split(':')[1], 0);
    newHoursSpent = parseInt(htimemints, 0) + parseInt(btimemints, NaN);
    return this.timeConvert(newHoursSpent);
  }

  /**
   * Checks if an user is allowed to edit a time table / has the rights to edit
   * a time table, and what the parts the of the form the user is allowed to edit.
   * @param timeTable Time table to check
   */
  validateEditRights(timeTable: Timetable) {
    // Editing non-approved time tables are allways allowed.
    const approveTimeTableRights = BaseServices.checkUserRights(
      Rights.APPROVE_TIMETABLE,
      BaseServices.UserRole
    );
    if (approveTimeTableRights) {
      this.allowedEdit = true;
      this.allowedStatusChange = true;
    } else if (
      !approveTimeTableRights &&
      timeTable.Status !== Status.Approved
    ) {
      this.allowedEdit = true;
      this.allowedStatusChange = false;
    } else {
      this.allowedEdit = false;
      this.allowedStatusChange = false;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}
