import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { BaseServices } from './../../kuba.services';
import { SubContractorServices } from './contractor.service';
import {ModuleList} from '../models/contractor';


@Injectable()
export class ContractorListByBusinessResolver implements Resolve<any[]> {
    constructor(private backend: SubContractorServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
        return this.backend.getContractorByBusiness(BaseServices.BusinessId);
    }
}

@Injectable()
export class ContractorEditByResolver implements Resolve<any[]> {
    constructor(private backend: SubContractorServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
        return this.backend.get(+route.params['cid']);
    }
}

@Injectable()
export class ModuleListResolver implements Resolve<ModuleList[]> {
    constructor(private backend: SubContractorServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<ModuleList[]> {
        return this.backend.getProjectByUser(BaseServices.UserId,BaseServices.BusinessId);
    }
}
