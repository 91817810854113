<div class="tab-content" *ngIf="!isMultiDocument">
  <div>
    <ecpl-document-explorer
      [uploaderConfig]="upConfig"
      [uploadedFiles]="uploadedFiles"
      (saveChanges)="saveUploaded($event)"
      (toogleEditDisplayMode)="toggleEditMode($event)"
    >
    </ecpl-document-explorer>
    <div class="action-btns-wrapper">
      <button
        class="btn btn-primary"
        [ngClass]="{ loading: loading }"
        [disabled]="loading || newDocumentAdded?.length <= 0"
        type="button"
        (click)="saveDoc()"
      >
        <span translate>SAVE</span>
      </button>
      <button
        class="btn btn-primary"
        *ngIf="isEditor"
        type="button"
        [disabled]="
          loading || (newDocumentAdded?.length <= 0 && isFileSeclected())
        "
        (click)="Approve(1)"
      >
        <span translate>SEND_APPROVAL_NOTICE</span>
      </button>
      <button
        class="btn btn-primary"
        *ngIf="isUser"
        type="button"
        [disabled]="
          loading || newDocumentAdded?.length > 0 || isFileSeclected()
        "
        (click)="Approve(3)"
      >
        <span translate>APPROVE </span>
      </button>
    </div>
  </div>
  <div class="saving-loader overlay" *ngIf="showSavingLoader">
    <div class="saving-loader-message text-center">
      <img src="/assets/icons/blocks.gif" alt="" />
      <p translate>SAVING</p>
    </div>
  </div>
</div>

<div class="tab-content" *ngIf="isMultiDocument">
  <div class="page-title">
    <span translate>{{ title }}</span>
    <span class="page-actions pull-right"> </span>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <label for="documents" class="col-form-label" translate>DOCUMENTS</label>
      <!-- Table starts -->
      <p-table
        #employee
        [value]="emp"
        dataKey="EmployeeId"
        [rows]="10"
        [paginator]="true"
        [pageLinks]="3"
        [rowsPerPageOptions]="[5, 10, 20]"
        expandableRows="true"
        (onHeaderCheckboxToggle)="onTableHeaderCheckboxToggle()"
        [(selection)]="selectedEmployeesForApproval"
      >
        <!-- Caption -->
        <ng-template pTemplate="caption">
          <div class="ui-helper-clearfix">
            <div class="filter-controls-wrapper" style="display: show">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <label for="documentname" class="col-form-label" translate
                      >SEARCH</label
                    >
                  </div>
                  <div class="col-sm-6">
                    <input
                      type="text"
                      pInputText
                      size="50"
                      (input)="filterResults($any($event.target)?.value)"
                      style="width: auto"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
        <!-- Header -->
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 55px">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th style="width: 55px"></th>
            <th pSortableColumn="EmployeeName">
              {{ "NAME" | translate }}
              <p-sortIcon field="EmployeeName"></p-sortIcon>
            </th>
            <th pSortableColumn="BusinessName" *ngIf="isPortal">
              {{ "BUSINESS_NAME" | translate }}
              <p-sortIcon field="BusinessName"></p-sortIcon>
            </th>
            <th pSortableColumn="ApprovedStatus">
              {{ "STATUS" | translate }}
              <p-sortIcon field="ApprovedStatus"></p-sortIcon>
            </th>
            <th pSortableColumn="TypeOfEmployee">
              {{ "TYPE_OF_EMPLOYEE" | translate }}
              <p-sortIcon field="TypeOfEmployee"></p-sortIcon>
            </th>
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th>
              <input
                pInputText
                type="text"
                (input)="
                  employee.filter(
                    $any($event.target)?.value,
                    'EmployeeName',
                    'contains'
                  )
                "
                placeholder=""
                class="p-column-filter"
              />
            </th>
            <th *ngIf="isPortal">
              <input
                  pInputText
                  type="text"
                  (input)="
                    employee.filter(
                      $any($event.target)?.value,
                      'BusinessName',
                      'contains'
                    )
                  "
                  placeholder=""
                  class="p-column-filter"
                />
              </th>
            <th></th>
            <th>
              <p-dropdown
                width="118px"
                [options]="typeList"
                (onChange)="
                  employee.filter($event.value, 'TypeOfEmployee', 'equals')
                "
                styleClass="p-column-filter"
                placeholder=""
                [showClear]="true"
                appendTo="body"
              >
              </p-dropdown>
              <ng-template let-document pTemplate="item">
                {{ document.label | translate }}
              </ng-template>
            </th>
          </tr>
        </ng-template>
        <!-- Body Content -->
        <ng-template
          pTemplate="body"
          let-employees
          let-expanded="expanded"
          let-i="rowIndex"
        >
          <tr>
            <td style="width: 55px">
              <p-tableCheckbox id="selectedOrders" [value]="employees">
              </p-tableCheckbox>
            </td>
            <td
            style="display: flex; justify-content: center; align-items: center"
            >
              <a (click)="employee.toggleRow(employees)" class="btn btn-icon">
                <span
                  class="icon ic-sm icon-expand-arrow"
                  aria-hidden="true"
                  *ngIf="setCollapse(i)"
                  (click)="toggleClick(i)"
                ></span>
                <span
                  class="icon ic-sm icon-collapse-arrow"
                  aria-hidden="true"
                  *ngIf="!setCollapse(i)"
                  (click)="toggleClickHide(i)"
                ></span>
              </a>
            </td>
            <td>{{ employees.EmployeeName }}</td>
            <td *ngIf="isPortal">{{ employees.BusinessName }}</td>
            <td>{{ employees.ApprovedStatus }}</td>
            <td>{{ employees.TypeOfEmployee }}</td>
          </tr>
        </ng-template>
        <!-- Empty message -->
        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td colspan="5">
              {{ "NO_RECORDS_FOUND" | translate }}
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="rowexpansion" let-docs>
          <tr>
            <td colspan="5">
              <div>
                <!-- Table starts -->
                <p-table [value]="docs.EmployeeDocuments" [rows]="5">
                  <!-- Header -->
                  <ng-template pTemplate="header">
                    <tr>
                      <th pSortableColumn="Filename">
                        {{ "DOCUMENT_NAME" | translate }}
                        <p-sortIcon field="Filename"></p-sortIcon>
                      </th>
                      <th pSortableColumn="ModifiedOn">
                        {{ "EDITED_DATE" | translate }}
                        <p-sortIcon field="ModifiedOn"></p-sortIcon>
                      </th>
                      <th pSortableColumn="ApprovedOn">
                        {{ "DATE_APPROVE" | translate }}
                        <p-sortIcon field="ApprovedOn"></p-sortIcon>
                      </th>
                      <th pSortableColumn="CreatedBy">
                        {{ "UPLOADED_BY" | translate }}
                        <p-sortIcon field="CreatedBy"></p-sortIcon>
                      </th>
                      <th></th>
                    </tr>
                  </ng-template>
                  <!-- Body Content -->
                  <ng-template pTemplate="body" let-document>
                    <tr>
                      <td>
                        <div
                          class="truncate-data"
                          pTooltip="{{ document.Filename | translate }}"
                          tooltipPosition="top"
                        >
                          <img
                            src="../../../../assets/file-icons/kb-article.png"
                            width="20px"
                            *ngIf="document.IsArticle == true"
                          />
                          <img
                            src="../../../../assets/file-icons/kb-file-docx.png"
                            width="20px"
                            *ngIf="
                              (document.IsArticle == false ||
                                document.IsArticle == null) &&
                              (getFileExtension(document.Filename) == 'doc' ||
                                getFileExtension(document.Filename) == 'docx')
                            "
                          />
                          <img
                            src="../../../../assets/file-icons/kb-file-xls.png"
                            width="20px"
                            *ngIf="
                              (document.IsArticle == false ||
                                document.IsArticle == null) &&
                              (getFileExtension(document.Filename) == 'xls' ||
                                getFileExtension(document.Filename) == 'xlsx')
                            "
                          />
                          <img
                            src="../../../../assets/file-icons/kb-file-pdf.png"
                            width="20px"
                            *ngIf="
                              (document.IsArticle == false ||
                                document.IsArticle == null) &&
                              getFileExtension(document.Filename) == 'pdf'
                            "
                          />
                          <img
                            src="../../../../assets/file-icons/kb-file-ppt.png"
                            width="20px"
                            *ngIf="
                              (document.IsArticle == false ||
                                document.IsArticle == null) &&
                              (getFileExtension(document.Filename) == 'ppt' ||
                                getFileExtension(document.Filename) == 'pptx')
                            "
                          />
                          <img
                            src="../../../../assets/file-icons/kb-file-general.png"
                            width="20px"
                            *ngIf="
                              (document.IsArticle == false ||
                                document.IsArticle == null) &&
                              getFileExtension(document.Filename) == 'txt'
                            "
                          />
                          <img
                            src="../../../../assets/file-icons/kb-file-image.png"
                            width="20px"
                            *ngIf="
                              (document.IsArticle == false ||
                                document.IsArticle == null) &&
                              (getFileExtension(document.Filename) == 'jpg' ||
                                getFileExtension(document.Filename) == 'png' ||
                                getFileExtension(document.Filename) == 'jpeg' ||
                                getFileExtension(document.Filename) == 'JPEG')
                            "
                          />
                          <img
                            src="../../../../assets/file-icons/kb-article.png"
                            width="20px"
                            *ngIf="document.DocumentType == 'article'"
                          />
                          <img
                            src="../../../../assets/file-icons/kb-file-video.png"
                            width="20px"
                            *ngIf="document.DocumentType == 'videos'"
                          />
                          {{ document.Filename }}
                        </div>
                      </td>
                      <td>
                        <span>{{
                          document.ModifiedOn | date : "dd/MM/yyyy"
                        }}</span>
                      </td>
                      <td>
                        <span>{{
                          document.ApprovedOn | date : "dd/MM/yyyy"
                        }}</span>
                      </td>
                      <td>
                        <span>{{ document.CreatedBy }}</span>
                      </td>
                      <td class="col-button">
                        <button
                          (click)="deleteDoc(document.Id)"
                          type="button"
                          class="btn btn-icon"
                          title="{{ 'DELETE' | translate }}"
                        >
                          <i class="icon ic-sm icon-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
                <!-- Table ends -->
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <!-- Table ends -->
    </div>
  </div>

  <div class="row">
    <div class="form-group"></div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <div class="form-group">
        <ecpl-document-explorer
          [uploaderConfig]="upConfigApp"
          [uploadedFiles]="uploadedFiles"
          (saveChanges)="saveUploaded($event)"
        >
        </ecpl-document-explorer>
      </div>
    </div>
  </div>
  <div class="action-btns-wrapper">
    <button
      class="btn btn-primary"
      [ngClass]="{ loading: loading }"
      [disabled]="
        loading ||
        newDocumentAdded?.length <= 0 ||
        selectedEmployeesForApproval?.length <= 0
      "
      type="button"
      (click)="saveDoc()"
    >
      <span translate>SAVE</span>
    </button>
    <button
      class="btn btn-primary"
      *ngIf="isEditor"
      type="button"
      [disabled]="loading || selectedEmployeesForApproval <= 0"
      (click)="Approve(1)"
    >
      <span translate>SEND_APPROVAL_NOTICE</span>
    </button>
  </div>
</div>
<toaster-component></toaster-component>
<div class="panel-loader" *ngIf="loading">
  <span>
    <span class="loader-icon"></span>
    <span translate>PROCESSING</span>
  </span>
</div>
