import { Rights } from './../../../_models/feature';
import { BaseServices } from './../../kuba.services';
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { VehicleServices } from '../services/vehicle.service';

@Component({
    selector: 'vehicle-header',
    templateUrl: 'vehicle-header.component.html'
})

export class VehicleHeaderListComponent implements OnInit {
    isVehicleRights = true;
    isHideVehicleRights = true;

    constructor(
        private router: Router,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
        document.querySelector("body").classList.remove("opened");
        if (+BaseServices.roleId === 4) {
            this.isVehicleRights = false;
            this.router.navigate(['own'], { relativeTo: this.route })
        }
        else if (BaseServices.UserRole === 'User') {
            this.onAdministrateProject();
        }
        else{
           this.OnAdmin();
        }
    }


    /**
   * hide the AllProjectList based upon the rights
   */
         onAdministrateProject() {
            let currentUserRole = BaseServices.UserRole;
            let userRightsId = Rights.ADMINISTRATE_PROJECTS;
            if (currentUserRole === 'User') {
                this.isVehicleRights = BaseServices.checkUserRights(userRightsId, currentUserRole);
                this.router.navigate(['own'], { relativeTo: this.route });
            } else {
                this.isVehicleRights = true;
                this.router.navigate(['all'], { relativeTo: this.route });
            }
        }


    OnAdmin(){
        let curSession = JSON.parse(sessionStorage.getItem('RModeBusinessId'));
        if(curSession > 0)
        {
            this.isVehicleRights=true;
            this.isHideVehicleRights = false;
            this.router.navigate(['all'], { relativeTo: this.route });
        }
        else{
            this.isVehicleRights = true;
            this.router.navigate(['all'], { relativeTo: this.route });
        }
    }

}
