<div id='declorationOfConformity'>
  <div class="page-title">
    <div>
      <a [routerLink]="['./../../../../list/own']" class="btn btn-icon">
        <span translate class="icon ic-xs icon-back"></span>
      </a>
      <span translate>PROJ_DOCS</span> <span translate>DECLARATION_CONFORMITY</span>
    </div>
  </div>
  <div #dc>
    <div class="table-view">
      <form [formGroup]="conformityForm">
        <div class="row">
          <div class="col-sm-12">
            <h4>
              <span translate>CONDITIONS_ACC</span>
              <span style="color:blue">
                                <span translate>REGULATIONS_ELECTRICAL</span>
                            </span>
            </h4>
          </div>
          <div class="col-sm-6">
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                <tr>
                  <th>
                    <span translate>EVALUATION</span>
                  </th>
                  <th>
                    <span translate>SELECT</span>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>
                    <span translate>CAUSES_SHUTDOWN</span>
                  </td>
                  <td>
                    <p-inputSwitch formControlName="IsInConvenience"
                                   onLabel="'SWITCH_YES'|translate" offLabel="'SWITCH_NO'|translate">
                    </p-inputSwitch>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span translate>SATISFACTORILY</span>
                  </td>
                  <td>
                    <p-inputSwitch formControlName="IsTasksatisfactorily"
                                   onLabel="'SWITCH_YES'|translate" offLabel="'SWITCH_NO'|translate">
                    </p-inputSwitch>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                <tr>
                  <th>
                    <span translate>EVALUATION</span>
                  </th>
                  <th>
                    <span translate>SELECT</span>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>
                    <span translate>STAFF_QUALIFICATION</span>
                  </td>
                  <td>
                    <p-inputSwitch formControlName="IsAdequatequalifications"
                                   onLabel="'SWITCH_YES'|translate" offLabel="'SWITCH_NO'|translate">
                    </p-inputSwitch>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span translate>PROPER_TOOLS</span>
                  </td>
                  <td>
                    <p-inputSwitch formControlName="IsToolsAvailable"
                                   onLabel="'SWITCH_YES'|translate" offLabel="'SWITCH_NO'|translat}">
                    </p-inputSwitch>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-sm-12">
            <h4>
              <span translate>WORKING_METHOD</span>
            </h4>
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                <tr>
                  <th colspan="2">
                    <span translate>DISCONNECTED_FACILITY</span>
                  </th>
                  <th colspan="2">
                    <span translate>UNDISCONNECTED_FACILITY</span>
                  </th>
                  <th colspan="2">
                    <span translate>CLOSE_FACILITY</span>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>
                    <input type="checkbox" formControlName="IsRefprocedure1" />
                  </td>
                  <td>
                    <span translate>REF_PROCEDURE</span>
                  </td>
                  <td>
                    <input type="checkbox" formControlName="IsRefprocedure2" />
                  </td>
                  <td>
                    <span translate>REF_PROCEDURE</span>
                  </td>
                  <td>
                    <input type="checkbox" formControlName="IsRefprocedure3" />
                  </td>
                  <td>
                    <span translate>REF_PROCEDURE</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <input type="checkbox" formControlName="IsMinutecheckdisconnected" />
                  </td>
                  <td>
                    <span translate>MINUTE_DISCONNECTED</span>
                  </td>
                  <td>
                    <input type="checkbox" formControlName="IsMinutecheckundisconnected" />
                  </td>
                  <td>
                    <span translate>MINUTE_UNDISCONNECTED</span>
                  </td>
                  <td>
                    <input type="checkbox"
                           formControlName="IsMinutecheckclosetoundisconnected" />
                  </td>
                  <td>
                    <span translate>CLOSE_UNDISCONNECTED</span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-sm-12">
            <h4>
              <span translate>RISK_COMPLETED</span>
            </h4>
            <table class="table table-bordered">
              <tbody>
              <tr>
                <td>
                  <span translate>DATE</span>
                  <div class="date-picker-container-conformity">
                    <my-date-picker name="startdate" [options]="startDateOptions"
                                    formControlName="RiskAnalysisDate"
                                    placeholder="{{'SELECT_END_DATE' | translate}}">
                    </my-date-picker>
                  </div>
                </td>
                <td>
                  <span translate>ENTER_YOUR_SIGN</span>
                  <div class="signature-area-conformity form-group" style="Border:1px solid black">
                    <canvas #canvas> </canvas>
                  </div>
                  <button (click)="clearSign()" class="btn btn-outline-secondary">
                    <span translate>CLEAR_SIGN</span>
                  </button>
                  <strong class="pull-right">
                    {{
                      this.conformityForm.get('RiskAnalysisSignature').value
                    }}
                  </strong>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="col-sm-12">
            <h4>
              <span translate>FINAL_INSPECTION</span>
            </h4>
            <div class="table-responsive">
              <table class="table table-bordered">
                <tbody>
                <tr>
                  <td>
                    <input type="checkbox" formControlName="IsCheckFinalInspection">
                  </td>
                  <td>
                    <span translate>CHECK_ATTACHED</span>
                  </td>
                  <td>
                    <input type="checkbox" formControlName="IsCheckFinalInspectionBelow">
                  </td>
                  <td>
                    <span translate>CHECK_INSPECTION</span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                <tr>
                  <th>
                    <span translate>CONTROL_POINTS</span>
                  </th>
                  <th>
                    <span translate>SELECT</span>
                  </th>
                  <th>
                    <span translate>ACC_MEASUREMNETS</span>
                  </th>
                  <th>
                    <span translate>ACC_COMMENTS</span>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>
                    <span translate>CONTACT_PROTECTION</span>
                  </td>
                  <td>
                    <p-inputSwitch formControlName="IsContactProtection"
                                   onLabel="'SWITCH_YES'|translate" offLabel="'SWITCH_NO'|translate">
                    </p-inputSwitch>
                  </td>
                  <td>
                    <input type="text" class="form-control" formControlName="ContactProAcc">
                  </td>
                  <td>
                    <input type="text" class="form-control"
                           formControlName="ContactProComments">
                  </td>
                </tr>
                <tr>
                  <td>
                    <span translate>GROUNDING</span>
                  </td>
                  <td>
                    <p-inputSwitch formControlName="ISGrounding"
                                   onLabel="'SWITCH_YES'|translate" offLabel="'SWITCH_NO'|translate">
                    </p-inputSwitch>
                  </td>
                  <td>
                    <input type="text" class="form-control" formControlName="GroundingAcc">
                  </td>
                  <td>
                    <input type="text" class="form-control" formControlName="GroundingComments">
                  </td>
                </tr>
                <tr>
                  <td>
                    <span translate>MARKING</span>
                  </td>
                  <td>
                    <p-inputSwitch formControlName="IsMarking" onLabel="'SWITCH_YES'|translate"
                                   offLabel="'SWITCH_NO'|translate"></p-inputSwitch>
                  </td>
                  <td>
                    <input type="text" class="form-control" formControlName="MarkingAcc">
                  </td>
                  <td>
                    <input type="text" class="form-control" formControlName="MarkingCommnets">
                  </td>
                </tr>
                <tr>
                  <td>
                    <span translate>OPTIONS_PROTECTION</span>
                  </td>
                  <td>
                    <p-inputSwitch formControlName="IsOptionsAndSettings"
                                   onLabel="'SWITCH_YES'|translate" offLabel="'SWITCH_NO'|translate">
                    </p-inputSwitch>
                  </td>
                  <td>
                    <input type="text" class="form-control"
                           formControlName="OptionsAndSettingsAcc">
                  </td>
                  <td>
                    <input type="text" class="form-control"
                           formControlName="OptionsAndSettingsComments">
                  </td>
                </tr>
                <tr>
                  <td>
                    <span translate>ATTACHED_CONDUCTORS</span>
                  </td>
                  <td>
                    <p-inputSwitch formControlName="ISAttachedConductor"
                                   onLabel="'SWITCH_YES'|translate" offLabel="'SWITCH_NO'|translate">
                    </p-inputSwitch>
                  </td>
                  <td>
                    <input type="text" class="form-control"
                           formControlName="AttachedConductorAcc">
                  </td>
                  <td>
                    <input type="text" class="form-control"
                           formControlName="AttachedConductorComments">
                  </td>
                </tr>
                <tr>
                  <td>
                    <span translate>FUNCTIONAL_TESTS</span>
                  </td>
                  <td>
                    <p-inputSwitch formControlName="IsMeasurementsAndTests"
                                   onLabel="'SWITCH_YES'|translate" offLabel="'SWITCH_NO'|translat}">
                    </p-inputSwitch>
                  </td>
                  <td>
                    <input type="text" class="form-control"
                           formControlName="MeasurementsAndTestsAcc">
                  </td>
                  <td>
                    <input type="text" class="form-control"
                           formControlName="MeasurementsAndTestsComments">
                  </td>
                </tr>
                <tr>
                  <td>
                    <span translate>INSULATION_MEASURE</span>
                  </td>
                  <td>
                    <p-inputSwitch formControlName="IsInsulationMeasurement"
                                   onLabel="'SWITCH_YES'|translate" offLabel="'SWITCH_NO'|translate">
                    </p-inputSwitch>
                  </td>
                  <td>
                    <input type="text" class="form-control"
                           formControlName="InsulationMeasurementAcc">
                  </td>
                  <td>
                    <input type="text" class="form-control"
                           formControlName="InsulationMeasurementCommnets">
                  </td>
                </tr>
                <tr>
                  <td>
                    <span translate>EARTH_CIRCUIT</span>
                  </td>
                  <td>
                    <p-inputSwitch formControlName="IsEarthleakCircuit"
                                   onLabel="'SWITCH_YES'|translate" offLabel="'SWITCH_NO'|translate">
                    </p-inputSwitch>
                  </td>
                  <td>
                    <input type="text" class="form-control"
                           formControlName="EarthleakCircuitAcc">
                  </td>
                  <td>
                    <input type="text" class="form-control"
                           formControlName="EarthleakCircuitComments">
                  </td>
                </tr>
                <tr>
                  <td>
                    <span translate>SAFETY_CIRCUITS</span>
                  </td>
                  <td>
                    <p-inputSwitch formControlName="IsSafetyCircuits"
                                   onLabel="'SWITCH_YES'|translate" offLabel="'SWITCH_NO'|translate">
                    </p-inputSwitch>
                  </td>
                  <td>
                    <input type="text" class="form-control" formControlName="SafetyCircuitsAcc">
                  </td>
                  <td>
                    <input type="text" class="form-control"
                           formControlName="SafetyCircuitsComments">
                  </td>
                </tr>
                <tr>
                  <td>
                    <span translate>CABLE_ROUTINGS</span>
                  </td>
                  <td>
                    <p-inputSwitch formControlName="IsCableRoutings"
                                   onLabel="'SWITCH_YES'|translate" offLabel="'SWITCH_NO'|translate">
                    </p-inputSwitch>
                  </td>
                  <td>
                    <input type="text" class="form-control" formControlName="CableRoutingsAcc">
                  </td>
                  <td>
                    <input type="text" class="form-control"
                           formControlName="CableRoutingsCommnets">
                  </td>
                </tr>
                <tr>
                  <td>
                    <span translate>DOCUMENTATION_ACC</span>
                  </td>
                  <td>
                    <p-inputSwitch formControlName="IsDocumentation"
                                   onLabel="'SWITCH_YES'|translate" offLabel="'SWITCH_NO'|translate">
                    </p-inputSwitch>
                  </td>
                  <td>
                    <input type="text" class="form-control" formControlName="DocumentationAcc">
                  </td>
                  <td>
                    <input type="text" class="form-control"
                           formControlName="DocumentationCommnets">
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-sm-12">
            <h4>
              <span translate>INSTALLATION_READY</span>
            </h4>
            <table class="table table-bordered">
              <tbody>
              <tr>
                <td>
                  <span translate>DATE</span>
                  <div class="date-picker-container-conformity">
                    <my-date-picker name="startdate" [options]="startDateOptions"
                                    formControlName="InstallationDate"
                                    placeholder="{{'SELECT_END_DATE'|translate}}">
                    </my-date-picker>
                  </div>
                </td>
                <td>
                  <span translate>ENTER_YOUR_SIGN</span>
                  <div class="signature-area-conformity form-group" style="Border:1px solid black">
                    <canvas #canvas1> </canvas>
                  </div>
                  <button (click)="clearSign1()" class="btn btn-outline-secondary">
                    <span translate>CLEAR_SIGN</span>
                  </button>
                  <strong class="pull-right">
                    {{
                      this.conformityForm.get('InstallationSignature').value
                    }}
                  </strong>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="col-sm-12">
            <h4>
              <span translate>DECLARATION_CONFORMITY</span>
            </h4>
            <div class="table-responsive">
              <table class="table table-bordered">
                <tbody>
                <tr>
                  <td>
                    <div class="checkbox">
                      <label>
                        <input type="checkbox" formControlName="IsNewFacilities">
                        <span translate>NEW_FACILITIES</span>
                      </label>
                    </div>
                  </td>
                  <td>
                    <div class="checkbox">
                      <label>
                        <input type="checkbox" formControlName="IsExtension">
                      </label>
                      <span translate>EXTENSION</span>
                    </div>
                  </td>
                  <td>
                    <div class="checkbox">
                      <label>
                        <input type="checkbox" formControlName="IsChange">
                      </label>
                      <span translate>CHANGE</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="checkbox">
                      <label>
                        <input type="checkbox" formControlName="ISUsednorms">
                      </label>
                      <span translate>USED_NORMS</span>
                    </div>
                  </td>
                  <td>
                    <div class="checkbox">
                      <label>
                        <input type="checkbox" formControlName="IsNEK">
                      </label>
                      <span translate>NEK 400 - 2022</span>
                    </div>
                  </td>
                  <td>
                    <div class="checkbox">
                      <label>
                        <input type="checkbox" formControlName="IsOthers" #custom>
                      </label>
                      <span translate>CUSTOM_MESSAGE</span>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="row">
              <div class="col-sm-6">
                <div *ngIf="custom.checked">
                  <h4>
                    <span translate>CUSTOM_MESSAGE</span>
                  </h4>
                  <textarea class="form-control" rows="3" formControlName="Others"></textarea>
                </div>
                <div>
                  <h4>
                    <span translate>DESCRIPTION_FACILITIES</span>
                  </h4>
                  <textarea class="form-control" rows="3"
                            formControlName="ShortDescription"></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12">
            <h4>
              <span translate>DECLARATION_COMPLETED</span>
            </h4>
            <table class="table table-bordered">
              <tbody>
              <tr>
                <td>
                  <span translate>DATE</span>
                  <div class="date-picker-container-conformity">
                    <my-date-picker name="startdate" [options]="startDateOptions"
                                    formControlName="ConformityCompletedDate"
                                    placeholder="{{'SELECT_END_DATE'|translate}}"></my-date-picker>
                  </div>
                </td>
                <td>
                  <span translate>ENTER_YOUR_SIGN</span>
                  <div class="signature-area-conformity form-group" style="Border:1px solid black">
                    <canvas #canvas2> </canvas>
                  </div>
                  <button (click)="clearSign2()" class="btn btn-outline-secondary">
                    <span translate>CLEAR_SIGN</span>
                  </button>
                  <strong class="pull-right">
                    {{
                      this.conformityForm.get('ConformityCompletedSignature').value
                    }}
                  </strong>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="col-sm-6">
            <h4>
              <span translate>FURTHER_DESCRIPTION</span>
            </h4>
            <textarea class="form-control" rows="3" formControlName="CommentsToFacilities"></textarea>
          </div>
        </div>
      </form>
    </div>
    <div class="action-btns-wrapper">
      <button [disabled]="!isGuest" class="btn btn-primary" type="submit" (click)="saveChanges()">
        <span translate>SAVE_GENERATE</span>
      </button>
      <button class="btn btn-primary" [disabled]="pdfButton" (click)="exportPdf()">PDF</button>
    </div>
  </div>
  <toaster-component></toaster-component>
</div>

<p-dialog header="{{'SIGNED' | translate}}" [(visible)]="signList1"
          [style]="{width: '300px'}" [resizable]="false" [draggable]="false"
          (onHide)="onCloseSignEvent1()">
  <div class="table-view">
    <!-- Table starts -->
    <p-table [value]="signData1" [rows]="10" [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]"
             [responsive]="true">
      <!-- Header -->
      <ng-template pTemplate="header">
        <tr>
          <th>{{'USER' | translate}}</th>
          <th>{{'SIGNED' | translate}}</th>
        </tr>
      </ng-template>
      <!-- Body Content -->
      <ng-template pTemplate="body" let-sign>
        <tr>
          <td>
            <a href="javascript:void(0)" (click)="selectSign1(sign)">
              <span translate>{{sign.Name}}</span>
            </a>
          </td>
          <td>
            <img src="{{sign.SignatureImg}}" alt="" width="100" height="100" class="thumbnail">
          </td>
        </tr>
      </ng-template>
      <!-- Empty message -->
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td colspan="2">
            {{'NO_RECORDS_FOUND'|translate}}
          </td>
        </tr>
      </ng-template>
    </p-table>
    <!-- Table Ends -->
  </div>
</p-dialog>
<p-dialog header="{{'SIGNED' | translate}}" [(visible)]="signList2"
          [style]="{width: '300px'}" [resizable]="false" [draggable]="false"
          (onHide)="onCloseSignEvent2()">
  <div class="table-view">
    <!-- Table starts -->
    <p-table [value]="signData2" [rows]="10" [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]"
             [responsive]="true">
      <!-- Header -->
      <ng-template pTemplate="header">
        <tr>
          <th>{{'USER' | translate}}</th>
          <th>{{'SIGNED' | translate}}</th>
        </tr>
      </ng-template>
      <!-- Body Content -->
      <ng-template pTemplate="body" let-sign>
        <tr>
          <td>
            <a href="javascript:void(0)" (click)="selectSign2(sign)">
              <span translate>{{sign.Name}}</span>
            </a>
          </td>
          <td>
            <img src="{{sign.SignatureImg}}" alt="" width="100" height="100" class="thumbnail">
          </td>
        </tr>
      </ng-template>
      <!-- Empty message -->
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td colspan="2">
            {{'NO_RECORDS_FOUND'|translate}}
          </td>
        </tr>
      </ng-template>
    </p-table>
    <!-- Table Ends -->
  </div>
</p-dialog>
<p-dialog header="{{'SIGNED' | translate}}" [(visible)]="signList3"
          [style]="{width: '300px'}" [resizable]="false" [draggable]="false"
          (onHide)="onCloseSignEvent3()">
  <div class="table-view">
    <!-- Table starts -->
    <p-table [value]="signData3" [rows]="10" [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]"
             [responsive]="true">
      <!-- Header -->
      <ng-template pTemplate="header">
        <tr>
          <th>{{'USER' | translate}}</th>
          <th>{{'SIGNED' | translate}}</th>
        </tr>
      </ng-template>
      <!-- Body Content -->
      <ng-template pTemplate="body" let-sign>
        <tr>
          <td>
            <a href="javascript:void(0)" (click)="selectSign3(sign)">
              <span translate>{{sign.Name}}</span>
            </a>
          </td>
          <td>
            <img src="{{sign.SignatureImg}}" alt="" width="100" height="100" class="thumbnail">
          </td>
        </tr>
      </ng-template>
      <!-- Empty message -->
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td colspan="2">
            {{'NO_RECORDS_FOUND'|translate}}
          </td>
        </tr>
      </ng-template>
    </p-table>
    <!-- Table Ends -->
  </div>
</p-dialog>
