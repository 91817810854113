import { APP_BASE_HREF } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { InitialServices } from 'src/app/_services/initial.services';
import * as _ from 'lodash';
import { forkJoin, Subscription } from 'rxjs';
import * as Cookies from 'es-cookie';
import * as $ from 'jquery';
import { ThemeId, ThemeService } from '../theme.service';
import { HelperService } from '../_services/helper.service';
import { Rights } from '../_models/feature';
import { EmployeeServices } from './../kuba/employees/services/employee.services';
import { forgetPasswordRoutes } from '../forget-password/forget-password.routing';
import { CacheHandlerService } from './../kuba/cached.services';
import { NetworkConnection, ConnectionStatusEnum } from './connection.check';
import { BaseServices, KubaServices } from '../kuba/kuba.services';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../_services/authentication.service';
import { AlertService } from '../_services/alert.service';
import { AccessLog } from '../kuba/accesslog/models/accesslog';
import { AccessLogServices } from '../kuba/accesslog/services/accesslog.services';

@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.css'],
})
export class LoginComponent implements OnInit {
  accesslog: any;
  model: any = {};
  loading = false;
  returnUrl: any;
  baseUrl: any;
  userSettings: any;
  deviceInfo = null;
  acessLog: AccessLog[] | any;
  isInvalid = false;
  userName: any;
  password: any;
  isPhp = false;
  displayDialog = false;
  IsTOCApproved: any;
  userRole: any;
  assetloc: string = environment.BASE_STYLES_PATH;
  isOnline = true;
  isMedi3 = false;
  termsAndCondition: any;
  csession: any;
  private subscriptions: Subscription[] = [];
  ipAddress: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2,
    private themeService: ThemeService,
    private alertService: AlertService,
    private authenticationService: AuthenticationService,
    private accessLogServices: AccessLogServices,
    private kubaServices: KubaServices,
    private initialServices: InitialServices,
    private employeeServices: EmployeeServices,
    private cacheHandlerService: CacheHandlerService,
    @Inject(APP_BASE_HREF) private baseHref: string,
    @Inject(DOCUMENT) private document: any,
    private http: HttpClient
  ) {
    this.isPhp = <boolean>this.route.snapshot.data['phpLogin'];
    this.getIPAddress();
  }

  ngOnInit() {
    // reset login status
    sessionStorage.clear();
    this.resetTheme();
    this.initialServices.appInitializing();
    // get return url from route parameters or default to '/app'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/app';
    if (this.isPhp) {
      this.CheckingCookies();
      this.userName = '';
      this.password = '';
      this.decryptCredentialsAsync();
    }
    this.renderer.addClass(document.body.parentElement, 'loginView');
    this.renderer.addClass(document.body, 'loginView');
    let medi3 = window.location.pathname.substring(
      window.location.pathname.indexOf('medi3') + 0,
      window.location.pathname.indexOf('medi3') + 5
    );
    if (medi3 == 'medi3') {
      this.isMedi3 = true;
      this.termsAndCondition = 'Medi3 - Vilkår og betingelser';
    } else {
      this.isMedi3 = false;
      this.termsAndCondition = 'Kuba - Vilkår og betingelser';
    }
  }

  getIPAddress() {
    //   this.http.get("http://api.ipify.org/?format=json").subscribe((res:any)=>{
    //     this.ipAddress = res.ip;
    //   });
    this.http.get('https://jsonip.com').subscribe((value: any) => {
      this.ipAddress = value.ip;
    });
  }
  resetTheme(): void {
    const themePath = ''; // or themePath = null;
    this.themeService.setTheme(ThemeId.DEFAULT);
  }

  decryptCredentialsAsync() {
    this.authenticationService;

    this.subscriptions.push(
      this.authenticationService
        .getDecryptedLoginCredentials(
          this.route.snapshot.params['username'],
          this.route.snapshot.params['password']
        )
        .subscribe(
          (res: any) => {
            if (res) {
              this.userName = res.Username;
              this.password = res.Password;
              if (
                this.userName !== '' &&
                this.userName !== 'Invalid' &&
                this.password !== ''
              ) {
                this.model.username = this.userName;
                this.model.password = this.password;
                this.verifyGDPRandLogin();
              } else {
                this.selfRedirect('Invalid User or Password');
              }
            }
          },
          (error) => this.selfRedirect('Invalid User or Password')
        )
    );
  }

  selfRedirect(msg: string) {
    this.alertService.error(msg);
    this.loading = false;
    this.router.navigate(['login']);
  }

  // close pop-up
  decline() {
    this.displayDialog = false;
    this.selfRedirect('GDPR Declined');
  }

  // Accept GDPR agreement
  accept() {
    this.displayDialog = false;
    this.loading = true;
    this.login();
  }

  verifyGDPRandLogin(): void {
    this.loading = true;
    if (NetworkConnection.status === ConnectionStatusEnum.Offline) {
      this.isOnline = false;
    } else if (NetworkConnection.status === ConnectionStatusEnum.Online) {
      this.isOnline = true;
    }
    this.isPhp = false;

    this.subscriptions.push(
      this.authenticationService
        .login(this.model.username, this.model.password, this.ipAddress)
        .subscribe(
          (res: any) => {
            if (res && sessionStorage.getItem('session')) {
              if (res.gdprStatus === false) {
                this.loading = false;
                this.displayDialog = true;
              } else {
                if (sessionStorage.getItem('session')) {
                  // cache clear
                  this.cacheHandlerService.clearCachedData();
                  // set userSetting for apploication when is not set
                  if (sessionStorage.getItem('userSettings') == null) {
                    let observables = [];

                    // Retrives all available languages
                    observables.push(this.kubaServices.getAllLanguages());

                    // Retrive current user language , UserProfile & UserTheme
                    observables.push(this.kubaServices.getUserCustomSetting());

                    // get and set UserBase
                    observables.push(this.kubaServices.getUserBase());

                    // get and set UserFeatures
                    observables.push(this.kubaServices.getUserFeatures());

                    // Observe above call for completion
                    this.subscriptions.push(
                      forkJoin(observables).subscribe(
                        (result) => {
                          if (result) {
                            result.forEach((data: any) => {
                              if (data['userLanguageTheme'] != null) {
                                const userLanguageTheme =
                                  data['userLanguageTheme'];
                                const userTheme = userLanguageTheme?.userTheme;
                                console.log(userLanguageTheme);

                                if (userTheme && userTheme.Stylepath) {
                                  console.log(userTheme);
                                  const themePath =
                                    this.assetloc + userTheme.Stylepath;
                                  //   const themeId =
                                  //     BaseServices.userTheme.ThemeId;
                                  this.themeService.setTheme(userTheme.id);
                                  this.themeService.applyTheme();
                                }
                                this.userSettings = _.assign(
                                  this.userSettings,
                                  data['userLanguageTheme']
                                );
                                this.userRole = _.assign(
                                  this.userSettings,
                                  data['userBase']
                                );
                              } else {
                                this.userSettings = _.assign(
                                  this.userSettings,
                                  data
                                );
                              }
                            });
                          }
                        },
                        (error) =>
                          console.error('Error at App Init services : ', error),
                        () => {
                          this.loading = false;
                          // setting Up Session Storage and listen to it
                          sessionStorage.setItem(
                            'userSettings',
                            JSON.stringify(this.userSettings)
                          );

                          if (sessionStorage.getItem('appSettings') == null) {
                            this.loading = false;
                            window.location.href =
                              window.location.href.replace(
                                window.location.pathname,
                                ''
                              ) +
                              '/' +
                              this.userSettings.userBase.BaseUrl;
                            this.router.navigate([this.returnUrl]);
                          } else {
                            this.router.navigate([this.returnUrl], {
                              relativeTo: this.route,
                            });
                          }
                        }
                      )
                    );
                  }
                } else {
                  this.loading = false;
                  this.alertService.error('Invalid User or Password');
                }
              }
            } else {
              this.loading = false;
              this.isInvalid = true;
              setTimeout((x: any) => {
                this.isInvalid = false;
              }, 4000);
            }
          },
          (error) => {
            this.isInvalid = true;
            this.displayDialog = false;
            this.loading = false;
            setTimeout((x: any) => {
              this.isInvalid = false;
            }, 4000);
            if (error.status === 0) {
              this.alertService.error('User may not available.');
            }
            if (error.status !== 0) {
              this.alertService.error(error._body);
            }
          }
        )
    );
  }

  login(): void {
    this.subscriptions.push(
      this.authenticationService
        .login(this.model.username, this.model.password, this.ipAddress)
        .subscribe(
          (res) => {
            if (res) {
              if (sessionStorage.getItem('session')) {
                // cache clear
                this.cacheHandlerService.clearCachedData();
                // set userSetting for apploication when is not set
                if (sessionStorage.getItem('userSettings') == null) {
                  let observables = [];

                  // Retrives all available languages
                  observables.push(this.kubaServices.getAllLanguages());

                  // Retrive current user language , UserProfile & UserTheme
                  observables.push(this.kubaServices.getUserCustomSetting());

                  // get and set UserBase
                  observables.push(this.kubaServices.getUserBase());

                  // get and set UserFeatures
                  observables.push(this.kubaServices.getUserFeatures());
                  // Observe above call for completion
                  this.subscriptions.push(
                    forkJoin(observables).subscribe(
                      (result) => {
                        if (result) {
                          result.forEach((data: any) => {
                            if (data['userLanguageTheme'] != null) {
                              this.userSettings = _.assign(
                                this.userSettings,
                                data['userLanguageTheme']
                              );
                              this.userRole = _.assign(
                                this.userSettings,
                                data['userBase']
                              );
                            } else {
                              this.userSettings = _.assign(
                                this.userSettings,
                                data
                              );
                            }
                          });

                          this.loading = false;
                        }
                      },
                      (error) =>
                        console.error('Error at App Init services : ', error),
                      () => {
                        this.loading = false;
                        // setting Up Session Storage and listen to it
                        sessionStorage.setItem(
                          'userSettings',
                          JSON.stringify(this.userSettings)
                        );

                        // update GDPR for user
                        this.employeeServices
                          .currentUserTOCStatus(BaseServices.UserId, true)
                          .subscribe((result: any) => {});

                        if (sessionStorage.getItem('appSettings') == null) {
                          window.location.href =
                            window.location.href.replace(
                              window.location.pathname,
                              ''
                            ) +
                            '/' +
                            this.userSettings.userBase.BaseUrl;
                          this.router.navigate([this.returnUrl]);
                        } else {
                          this.loading = false;
                          this.router.navigate([this.returnUrl], {
                            relativeTo: this.route,
                          });
                        }
                      }
                    )
                  );
                }
              } else {
                this.loading = false;
                this.alertService.error('Invalid User or Password');
              }
            } else {
              this.loading = false;
            }
          },
          (error) => {
            this.loading = false;
            this.isInvalid = true;
            setTimeout((x: any) => {
              this.isInvalid = false;
            }, 2000);
            if (error.status === 0) {
              this.alertService.error('User may not available.');
            }
            if (error.status !== 0) {
              this.alertService.error(error._body);
            }
          }
        )
    );
  }
  /**
   * access log for hit counts
   */
  addAccessLog() {
    let user = new AccessLog();
    user.UserId = BaseServices.UserId;
    user.BusinessId = BaseServices.BusinessId;
    user.IP = '';
    user.Browser = '';
    user.OS = '';
    user.ApplicationId = BaseServices.ApplicationId;
    user.Type = 'Web';
    user.LogIn = new Date();
    this.subscriptions.push(
      this.accessLogServices.addAccessLog(user).subscribe(
        (accsess: any) => {
          if (accsess) {
            this.loading = false;
          }
        },
        (error: any) => {
          this.loading = false;
          if (error.status === 0) {
          }
        }
      )
    );
  }
  goToForgetPassword() {
    this.router.navigate(['./../forget-password'], { relativeTo: this.route });
  }

  RememberMeFunction() {
    if (this.model.rememberMe === true) {
      Cookies.set('UserName', this.model.username, { expires: 60 });
      Cookies.set('Password', this.model.password, { expires: 60 });
    } else {
      Cookies.set('UserName', this.model.username, { expires: -60 });
      Cookies.set('Password', this.model.password, { expires: -60 });
    }
  }
  CheckingCookies() {
    if (Cookies.get('UserName') && Cookies.get('Password')) {
      this.model.rememberMe = true;
      this.model.username = Cookies.get('UserName');
      this.model.password = Cookies.get('Password');
    }
  }

  /**
   * Author : Mohamed Idhris Sheik Dawood
   * Purpose : Get time of expiration for the current session
   */
  getTimeToExpire(): Number {
    return +document.getElementById('timeToExpire')!.innerText;
  }

  /**
   * Author : Mohamed Idhris Sheik Dawood
   * Purpose : Set time of expiration for the current session
   */
  setTimeToExpire(newExpiryTime: any) {
    // var element = document.getElementById('lblId');
    // var id = element.innerText || element.textContent;
    document.getElementById('timeToExpire')!.innerText =
      newExpiryTime.toString();
  }

  closeSession() {
    let modal = document.getElementById('myModal')!;
    modal.style.display = 'none';
    window.location.href = environment.BASE_FRONT_URL + 'ik/login';
  }

  keepSessionAlive() {
    this.setTimeToExpire(Date.now() + 3300000); // Allow session for next 55 minutes
    setTimeout(() => {
      if (Date.now() >= this.getTimeToExpire().valueOf()) {
        if (sessionStorage.getItem('session')) {
          if (
            Date.now() - +document.getElementById('lastClick')!.innerText >
            3000000
          ) {
            let modal = document.getElementById('myModal')!;
            modal.style.display = 'block';
            document.getElementById('timerValue')!.innerText = '140';
            let timerId = setInterval(() => {
              document.getElementById('timerValue')!.innerText = (
                +document.getElementById('timerValue')!.innerText - 1
              ).toString();
            }, 1000);
            setTimeout(() => {
              clearInterval(timerId);
              if (modal.style.display === 'block') {
                this.closeSession();
              }
            }, 140000);
          } else {
            this.csession = sessionStorage.getItem('session');
            let curSession = JSON.parse(this.csession);
            let putdata = JSON.stringify({
              RefreshToken: curSession.RefreshToken,
            });
            $.ajax({
              url: environment.BASE_URL + '/authentication/refresh-token',
              type: 'PUT',
              data: putdata,
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              success: (response) => {
                if (response && response.AccessToken) {
                  sessionStorage.setItem('session', JSON.stringify(response));
                  this.keepSessionAlive();
                } else {
                  this.closeSession();
                }
              },
              error: () => {
                this.closeSession();
              },
            });
          }
        }
      }
    }, 3300000); // Show alert at 55th minute
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body.parentElement, 'loginView');
    this.renderer.removeClass(document.body, 'loginView');
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}
