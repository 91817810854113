import { CommonModule } from '@angular/common';
import { AccessLogModule } from './accesslog/accesslog.module';
import { ActivityLogModule } from './activitylog/activitylog.module';
import { AdminUserModule } from './adminuser/adminuser.module';
import { ApplicationModule } from './application/application.module';
import { BusinessDepartmentService } from './business-department.services';
import { CacheHandlerService } from './cached.services';
import { DeveloperModule } from './developer/developer.module';
import { EditorContactsModule } from './editorcontacts/editorcontacts.module';
import { EmployeeAdminMainComponent } from './employee-admin/employee-admin.component';
import { EmployeeAdminModule } from './employee-admin/employee-admin.module';
import { FirstKinListComponent } from './employees/components/employee-firstofkin-list.component';
import { KubaServices } from './kuba.services';
import { FeaturesResolver } from './kuba.services.resolver';

import { UserEditResolver } from './users/services/user.resolver.service';
import { UserService } from './users/services/user.service';
import { UserModule } from './users/user.module';
// Vehicle Components
import { DialogModule } from 'primeng/dialog';
import { TooltipModule } from 'primeng/tooltip';
import { KubaComponent } from './kuba.component';
import { VehicleChecklistComponent } from './vehicle-checklist/vehicleChecklist.component';
import { VehicleConsumptionComponent } from './vehicle-consumption/vehicle-consumption.component';
import { VehicleControlComponent } from './vehicle-control/vehicle-control.component';
import { VehicleTaskComponent } from './vehicle-task/vehicleTask.component';
import { VehicleComponent } from './vehicle/vehicle.component';
// import {GrowlModule} from 'primeng/primeng';
import { TableModule } from 'primeng/table';
// Framework Component

import { SubContractorModule } from './contractor/contractor.module';
import { CrewListModule } from './crew-list/crew-list.module';
import { CrewComponent } from './crew-list/crew.component';
import { FirstKinEditComponent } from './employees/components/employee-firstofkin-edit.component';
import { FirstKinComponent } from './employees/components/firstKin.component';
import { LeaveComponent } from './employees/components/leave.component';
import { SicknessComponent } from './employees/components/sickness.component';
import { FoodSafetyModule } from './foodsafety/foodsafety.module';
import { KubaControlModule } from './kuba-control/kuba-control.module';
import { ManualControlModule } from './manual-control/manual-control.module';
import { MyPageComponent } from './my-page/my-page.component';
import { MyPageModule } from './my-page/my-page.module';

import { ReceptionControlModule } from './reception-control/reception-control.module';
import { EditTimetableComponent } from './time-table/components/edit-timetable/edit-timetable.component';
import { CreateTimetableComponent } from './time-table/components/new-timetable/new-timetable.component';
import { TimetableFormComponent } from './time-table/timetable-form.component';
// Vehicle Modules
import { FdvDocComponent } from './fdv-Document/components/fdv-doc.component';
import { FdvDocumentComponent } from './fdv-Document/fdv-doc.component';
import { FdvDocumentModule } from './fdv-Document/fdv-doc.module';

import { VehicleChecklistModule } from './vehicle-checklist/vehicleChecklist.module';
import { VehicleConsumptionModule } from './vehicle-consumption/vehicle-consumption.module';
import { VehicleControlModule } from './vehicle-control/vehicle-control.module';
import { VehicleTaskModule } from './vehicle-task/vehicleTask.module';
import { VehicleModule } from './vehicle/vehicle.module';
// Framework Module
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SupplierModule } from './Suppliers/supplier.module';
import { AppSharedModule } from './shared/appShared.module';
// Application Modules
import { EcplDocumentExplorerModule } from '../shared/ecpl-document-explorer/ecpl-document-explorer.module';
import { ForgetPasswordModule } from './../forget-password/forget-password.module';
import { FdvModule } from './FDV/fdv.module';
import { RegistrationModule } from './Registration/registration.module';
import { ContactModule } from './contact/contact.module';
import { CustomizeHomepageModule } from './customize-homepage/customize-homepage.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { DeviationModule } from './deviation/deviation.module';
import { EmployeesModule } from './employees/employees.module';
import { FollowUpsModule } from './follow-ups/followups.module';
import { HelpSupportModule } from './help-support/help-support.module';
import { InternalControlModule } from './internal-control/internal-control.module';
import { ManualModule } from './manuals/index';
import { MeasureProtocolModule } from './measure-protocol/measure-protocol.module';
import { ModuleOverviewModule } from './module-overview/module-overview.module';
import { MoveBusinessModule } from './move-business/move-business.module';
import { MyFeaturesModule } from './my-features/my-features.module';
import { NewsModule } from './news/news.module';
import { PortalModule } from './portals/portals.module';
import { ProductionModule } from './production/production.module';
import { ProjectModule } from './projects/project.module';
import { JobAnalysisModule } from './safety-job-analysis/jobanalysis.module';
import { TimetableModule } from './time-table/timetable.module';
import { TrashModule } from './trash/trash.module';
// Shared Components/Directives/Services
import { TreeDraggedElement } from '@circlon/angular-tree-component';
import { MobxAngularModule } from 'mobx-angular';
import { BusinessSignComponent } from '../../app/kuba/businesses/components/business-sign.component';
import { BrandingComponent } from '../_directives/branding.component';
import { HeaderTopComponent } from '../_directives/headerTop.component';
import { HelpLinksComponent } from '../_directives/helplinks.component';
import { NavigationComponent } from '../_directives/navigation.component';
import { NotificationComponent } from '../_directives/notification.component';
import { QuickHelpComponent } from '../_directives/quick-help.component';
import { UserProfileComponent } from '../_directives/userProfile.component';
import { AuthGuard } from '../_guards';
import { FileUploadService } from '../_services/fileUpload.service';
import { SearchResultModule } from './SearchResults/searchresults.module';
import { AboutModule } from './about/about.module';
import { ArchiveModule } from './archive/archive.module';
import { BusinessesModule } from './businesses/businesses.module';
import { CompleteCheckListModule } from './checklist-complete/checklist-complete.module';
import { ClientModule } from './client/client.module';
import { DashboardComponent } from './dashboard';
import { DeclarationOfConformityModule } from './declaration-of-conformity/declaration-of-conformity.module';
import { ElChecklistComponent } from './el-checklist/elChecklist.component';
import { ElChecklistModule } from './el-checklist/elChecklist.module';
import { ElectroModule } from './electro/electro.module';
import { FeatureDocumentModule } from './feature-documents/feature-document.module';
import { FeaturesBaseServices } from './features/services/features-base.services';
import { FlexiModule } from './flexi/flexi.module';
import { FoodSafetyCleaningModule } from './foodsafety-cleaning/foodsafety-cleaning.module';
import { KubaGeneralService } from './kuba-general.services';
import { KubaSubComponent } from './kuba.sub.component';
import { ELModule } from './kunde-EL/el.module';
import { LicenseAgreementModule } from './license-agreement/license-agreement.module';
import { LinksModule } from './links/links.module';
import { LogListModule } from './log/log.module';
import { NotificationUserModule } from './notificationuser/notificationuser.module';
import { OtherSystemModule } from './other-system/other-system.module';
import { QualitySystemModule } from './quality-system/quality-system.module';
import { KubaCompleteChecklistModule } from './shared/kuba-complete-checklist/kuba-complete-checklist.module';
import { KubaFeatureDetailsModule } from './shared/kuba-feature-details/kuba-feature-details.module';
import { ValidationService } from './shared/services/validation.service';
import { TaskManagerNoRecordsModule } from './shared/taskmanagernorecords/taskmanagernorecords.module';
import { SjaFormModule } from './sja-form/sja-form.module';
import { TaskManagerModule } from './task-manager/task-manager.module';

@NgModule({
  declarations: [
    FdvDocumentComponent,
    FdvDocComponent,
    VehicleTaskComponent,
    VehicleControlComponent,
    VehicleConsumptionComponent,
    VehicleChecklistComponent,
    VehicleComponent,
    ElChecklistComponent,
    MyPageComponent,
    HelpLinksComponent,
    NotificationComponent,
    QuickHelpComponent,
    UserProfileComponent,
    HeaderTopComponent,
    BrandingComponent,
    NavigationComponent,
    SicknessComponent,
    LeaveComponent,
    EditTimetableComponent,
    CreateTimetableComponent,
    TimetableFormComponent,
    FirstKinComponent,
    FirstKinListComponent,
    FirstKinEditComponent,
    KubaSubComponent,

    EmployeeAdminMainComponent,
    KubaComponent,
  ],
  imports: [
    MobxAngularModule,
    TableModule,
    DialogModule,
    TooltipModule,
    SearchResultModule,
    VehicleTaskModule,
    VehicleControlModule,

    VehicleConsumptionModule,
    VehicleChecklistModule,
    VehicleModule,
    AppSharedModule,
    BrowserModule,
    RouterModule,
    CommonModule,
    DashboardModule,
    UserModule,
    BusinessesModule,
    ManualModule,
    NewsModule,
    HelpSupportModule,
    ModuleOverviewModule,
    MyFeaturesModule,
    CustomizeHomepageModule,
    FollowUpsModule,
    ProjectModule,
    EmployeesModule,
    ContactModule,
    PortalModule,
    TaskManagerModule,
    DeclarationOfConformityModule,
    RegistrationModule,
    JobAnalysisModule,
    SjaFormModule,
    DeviationModule,
    MeasureProtocolModule,
    ManualModule,
    TimetableModule,
    TranslateModule,
    FdvModule,
    ClientModule,
    FoodSafetyModule,
    KubaControlModule,
    SupplierModule,
    InternalControlModule,
    OtherSystemModule,
    QualitySystemModule,
    SubContractorModule,
    MyPageModule,
    CrewListModule,
    TrashModule,
    DeveloperModule,
    ApplicationModule,
    ManualControlModule,
    ProductionModule,
    ReceptionControlModule,
    AccessLogModule,
    ActivityLogModule,
    EmployeeAdminModule,
    AdminUserModule,
    EditorContactsModule,
    FdvDocumentModule,
    CompleteCheckListModule,
    ForgetPasswordModule,
    EcplDocumentExplorerModule,
    FeatureDocumentModule,
    ELModule,
    MoveBusinessModule,
    ArchiveModule,
    LogListModule,
    LinksModule,
    AboutModule,
    NotificationUserModule,
    TaskManagerNoRecordsModule,
    KubaCompleteChecklistModule,
    KubaFeatureDetailsModule,
    FoodSafetyCleaningModule,
    ElChecklistModule,
    ElectroModule,
    LicenseAgreementModule,
    SjaFormModule,
    FlexiModule,
  ],
  exports: [
    RouterModule,
    VehicleTaskComponent,
    VehicleControlComponent,
    VehicleConsumptionComponent,
    VehicleChecklistComponent,
    VehicleComponent,
    ElChecklistComponent,
    FdvDocumentComponent,
    FdvDocComponent,

    MyPageComponent,
    HelpLinksComponent,
    NotificationComponent,
    QuickHelpComponent,
    UserProfileComponent,
    HeaderTopComponent,
    BrandingComponent,
    NavigationComponent,
    KubaComponent,
    TranslateModule,
    SicknessComponent,
    LeaveComponent,
    EditTimetableComponent,
    CreateTimetableComponent,
    TimetableFormComponent,
    FirstKinComponent,
    FirstKinListComponent,
    FirstKinEditComponent,
    CrewComponent,
    EmployeeAdminMainComponent,
    BusinessSignComponent,
    DashboardComponent,
  ],
  providers: [
    AuthGuard,
    ValidationService,
    KubaServices,
    KubaGeneralService,
    UserEditResolver,
    UserService,
    FileUploadService,
    FeaturesResolver,
    CacheHandlerService,
    BusinessDepartmentService,
    FeaturesBaseServices,
    TreeDraggedElement,
  ],
  bootstrap: [KubaComponent],
})
export class KubaModule {}
